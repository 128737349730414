import React from 'react';
import { Tabs,Row } from 'antd';
import ResourceManagement from './ResourceManagement';
import Sync from './Sync';

const { TabPane } = Tabs;

const onChange = (key: string) => {
  // console.log(key);
};
const onClose = () => {
  window.history.back();
};

const ReportingUser: React.FC = () => (
  <Tabs defaultActiveKey="1" onChange={onChange}  className=''>
     <TabPane tab="Sync Activity" key="1">
       <Sync />
    </TabPane>
    <TabPane tab="Resource Management" key="2">
    <div className="non-absolute">
    <ResourceManagement />
    </div>
    </TabPane>
  </Tabs>
);

export default ReportingUser;
