import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
  Modal,
  message,
  Checkbox,
  Dropdown,
  Space,
  Tabs,
  Flex
} from "antd";
import { StopOutlined } from "@ant-design/icons";
import { ArrowRightOutlined, ArrowLeftOutlined ,DownOutlined,UpOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
import { log } from "console";
import type { MenuProps } from "antd";
import Item from "antd/es/list/Item";

const { Option } = Select;

const { TabPane } = Tabs;

const onChange = (key: string) => {

};
interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: number;
  ImageUrl: string;

}
interface Audittrails {
  id:string;
  modelname:string;
  newdata:string;
  olddata:string;
  status:number;
  createdat:string;
  toid:string[];
  oldtoidstring:string[];
  remark:string;
}


interface OptionItem {
  value: string;
  label: string;
  image: string;
}
interface Objective {
  name: string;
  objective: string;
}

interface objectiveModel {
  objectivelist: () => void;
  objectiveId: string | null;
  objectivestatus: string | null;
  objectiveName: string;
  type: boolean;
  objectivetype: string;
  assigneesmembers: string | undefined;
  objectivecode:string;
  orgobjectivelist: () => void;
}

interface OptionQuarter {
  value: string;
  label: string;
}


const EditParentObj: React.FC<objectiveModel> = ({ objectivelist, objectiveId, objectivestatus, objectiveName, type, objectivetype, assigneesmembers ,objectivecode,orgobjectivelist}) => {

  // =================Roles And Permission==============================
  const [permission_orgobjview, setPermission_orgobjview] = useState('');
  const [permission_orgobjedit, setPermission_orgobjedit] = useState('');
  const [permission_orgobjdelete, setPermission_orgobjdelete] = useState('');
  const [objAudit,setObjAudit]=useState<Boolean[]>([]);
  const [memberAudit,setMemberAudit]=useState<Boolean[]>([]);
  const [audittrails,setaudittrails] = useState<Audittrails[]>([]);

  // =================Roles And Permission==============================

  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [cycleselectedOption, setCycleSelectedOption] = useState<string | null>(
    null
  );
  const [VisibilityselectedOption, setVisibilitySelectedOption] = useState<
    string | null
  >(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWeightageButton, setIsWeightageButton] = useState(false);

  const handleObjAudit = (index: number) => {
    setObjAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };
  const handleMebAudit = (index: number) => {
    setMemberAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };
  const handleCycleSelect = (value: string) => {
    setCycleSelectedOption(value);
  };

  const handleCycleRadio = (e: RadioChangeEvent) => {
    setCycleSelectedOption(e.target.value);
  };

  const handleVisibilitySelect = (value: string) => {
    setVisibilitySelectedOption(value);
  };

  const handleVisibilityRadio = (e: RadioChangeEvent) => {
    setVisibilitySelectedOption(e.target.value);
  };
  const [checkboxCheckededit, setCheckboxCheckededit] = useState(false);
  const [mydetails, setmydetails] = useState<OptionItem>();
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const handlecheckboxeditObj = () => {
    const checkboxElement = document.getElementById('includeMyselfCheckboxedit') as HTMLInputElement;

    setisSearchMem(true);

    let checkingUser = false;
    MemberValues.map((user) => {
      // return user.value !== localStorage.loginId;+
      if (user.value === localStorage.getItem('loginId')) {
        checkingUser = true;
      } else if (user.value === localStorage.getItem('loginId')) {
        checkingUser = false;
      }
    });

    if (checkingUser === false) {
      setCheckboxCheckededit(!checkboxCheckededit);
      if (checkboxCheckededit === false) {
        const localStorageOption: OptionItem = {
          value: localStorage.loginId,
          label: localStorage.name,
          image: profilePhoto
        };
        const userid: string[] = [];

        selectedMemValues.forEach((user) => {
          userid.push(user.value)
        })
        MemberValues.forEach((user) => {
          userid.push(user.value)
        })
        assignedusers.forEach((user) => {
          userid.push(user.value)
        })
        userid.push(localStorageOption.value)
        setmydetails(localStorageOption);
        const updatedShowCustomWeightage = showCustomWeightage.filter(
          (value) => value.id !== localStorageOption.value
        );
        setShowCustomWeightage(updatedShowCustomWeightage)
        userOrganizationList(userid, parentObjective.individualweightage);

      } else {
        setmydetails(undefined)
        // handleTagClose(localStorage.loginId)
        handleMemTagClose(localStorage.loginId);
      }

    }
  }
  // const handleAssignWeightage = (e: any, index: number) => {
  //   setShowCustomWeightage((prevUserDetails) =>
  //     prevUserDetails.map((item, i) =>
  //       i === index ? { ...item, assignWeightage: e.target.value } : item
  //     )
  //   );
  //   setParentObjective({
  //     ...parentObjective,
  //     individualweightage: "customized"
  //   });
  // };

  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { value } = e.target;

    // Find the item in the array based on its id
    const updatedCustomWeightage = showCustomWeightage.map(item => {
      if (item.id === itemId) {
        // Update the assignWeightage for the specific item
        return { ...item, assignWeightage: parseInt(value) || 0 };
      }
      return item;
    });
    setShowCustomWeightage(updatedCustomWeightage);
    setParentObjective({
      ...parentObjective,
      individualweightage: "customized"
    });
  };


  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };


  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (text: string, record: any) => (
        <span className="align-center">
          <img src={record.profilePath ? FileUrl + record.profilePath : "assets/images/Default_user.svg"} onError={handleImageError} alt="" className="table_user" />
          <span className="pl-1 mr-2">{text}</span>
          {/* {record.id === localStorage.getItem("loginId") ? <span className="c_32B7D9"> (You)</span> :<><img src="assets/images/tick_check_all.svg" alt="" /></>} */}
        </span>
      ),
    },
    // {
    // title: "Weightage Available",
    // dataIndex: "avaiableWeightage",
    // key: "Available",
    // render: (text: string, record: any) => (
    //   <span className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "c_FF4747" : ""}>{record.avaiableWeightage < 0 ? (0) : (record.avaiableWeightage)}%</span>
    // ),
    // },
    {
      title: "Assigned",
      dataIndex: "assignWeightage",
      key: "Assigned",
      render: (text: string, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            // type="number"
            name="assignWeightage"
            value={record.assignWeightage || ''}
            // onChange={(e) => handleAssignWeightage(e, index)} />
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                handleAssignWeightage(e, record.id)
              };
            }
            }
            disabled={record.id == localStorage.loginId && objectivetype != "orgobjective"||Memoptions.every(user => user.value !== record.id) }
            // className="assigned_bg" 
            readOnly={isCustomVisible ? (false) : (true)}
            // className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "assigned_bg_error" : "assigned_bg"}
            className="assigned_bg"
          />
          <span className="suffix">%</span>
        </div>
        // <span className={parseInt(record.assignWeightage) < record.assignWeightage ? "c_FF4747" : ""}>{record.assignWeightage < 0 ? (0) : (record.assignWeightage)}%</span>
      ),
    },
  ];

  interface customWeightage {
    "id": string,
    "name": string,
    "username": string,
    "avaiableWeightage": number,
    "assignWeightage": number,
    "cftstatus": number,
    "profilePath": string,
    "objectivestatus": string
  }

  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);

  const custom_filteredData = showCustomWeightage.filter((record: customWeightage) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedFeedbackMemValues, setSelectedFeedbackMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
  const [feedback, setFeedback] = useState("");
  // membersn cant delete usestate
  const [MemberValues, setMemberValues] = useState<OptionItem[]>([]);
  var localStorageOptionsss: OptionItem;
  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    if (checkboxCheckededit) {
      localStorageOptionsss = {
        value: localStorage.loginId,
        label: localStorage.name,
        image: profilePhoto
      };
    }
    // setmydetails(localStorageOptionsss)
    const userid: string[] = selectedMemValues.map((item) => String(item.value));
    userid.push(...MemberValues.map(Item=>String(Item.value)));
    editmemberdetails.push(...userid);
    // if(parentObjective.organizationStatus!='1'){
    //   userid.push(...editmemberdetails)
    // }
    // if (mydetails) {
    //   userid.push(mydetails.value);
    // }
    if (localStorageOptionsss != undefined) {
      // userid.push(localStorageOptionsss.value)
    }
    // MemberValues.map(data=>userid.push(data.value))
    userOrganizationList(userid, parentObjective.individualweightage);
    // assigneduserslist(editmemberdetails)
    setSelectedMemValues(selectedMemValues);

  };

  const handleFeedbackMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );

    setSelectedFeedbackMemValues(selectedMemValues);

  };

  const handleMemTagClose = (valueToRemove: string) => {
    // if (valueToRemove.charAt(valueToRemove.length - 1) !== '*') {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );

    // editmemberdetails.filter((value) =>
    //   value != valueToRemove
    // );
    const updatedShowCustomWeightage = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    const userid: string[] = updatedShowCustomWeightage.map((user) => {
      return String(user.id)
    })
    // seteditedmemberdetails(userid)
    setShowCustomWeightage(updatedShowCustomWeightage)
    setSelectedMemValues(updatedMemValues);
    // }
  };
  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const handleGrpSelectChange = (selected: OptionItems[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    const filteredEditMemberDetails = editmemberdetails.filter(val =>
      !selectedUserIds.includes(val)
    );
    seteditedmemberdetails(filteredEditMemberDetails)
    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues)
    editmemberdetails.push(...selectedUserIds);
    selectedUserIds.push(mydetails?.value)
    const selectedgrpids = selectedGrpValues.map(user => user.value)
    setParentObjective({
      ...parentObjective,
      groupid: selectedgrpids// Using another value
    });
    // editmemberdetails.push(mydetails?.value)
    userOrganizationList(editmemberdetails, parentObjective.individualweightage)
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };


  const handleTagClose = (valueToRemove: string) => {
    // if(mydetails?.value==valueToRemove){
    //   const checkboxElement = document.getElementById('includeMyselfCheckbox') as HTMLInputElement;
    //   checkboxElement.removeAttribute('checked');
    //   setmydetails(undefined)
    //   setCheckboxChecked(false);
    // }    


    MemberValues.filter(
      (value) => value.value !== valueToRemove
    );


    MemberValues.map((user) => {
      // return user.value !== localStorage.loginId;+

      if (user.value === localStorage.getItem('loginId')) {
        setCheckboxCheckededit(false);
      }
    })
    // setSelectedMemValues(MemberValues);
    // handleMemTagClose(valueToRemove);

    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    setAssignedUsers(updatedGrpValues);
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    const userid: string[] = updatedMemValues1.map((user) => {
      return String(user.id)
    })
    seteditedmemberdetails(userid)
    handleGrpSelectChange(updatedGrpValues)
    // setShowCustomWeightage(updatedMemValues1);
  };
  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );

  const [Memoptions, setMemOptions] = useState<OptionItem[]>([]);

  // Memoptions.forEach((option) => {
  //   if (!option.image || option.image.trim() === "") {
  //     option.image = "assets/images/Default_user.svg";
  //   }
  // });

  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([]);

  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage hide and show

  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [isSearchMem, setisSearchMem] = useState(false);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    if (isSearchMem == false) {
      setAssignedUsers([]);
      const filteredUsers = showCustomWeightage.filter(user => {
        // Checking if any username value in assignedusers matches the user's id
        return !assignedusers.some(assignedUser => {
          // Assuming assignedUser.username is an array of objects with a value property
          return assignedUser.username.some(username => username.id === user.id);
        });
      });
      // setShowCustomWeightage([]);
      setShowCustomWeightage(filteredUsers)

    }
    else {
      setSelectedMemValues([]);
      setSelectedGrpValues([]);
      setCheckboxCheckededit(false);
      setmydetails(undefined);
      const filteredUsersmembers = showCustomWeightage.filter(user => {
        // Checking if the user's ID is not present in assignedusers
        return !selectedMemValues.some(assignedUser => assignedUser.value === user.id);
      });
      setShowCustomWeightage(filteredUsersmembers);
    }
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
  };

  const [open, setOpen] = useState(false);
  const { TextArea } = Input;

  const showDrawer = () => {
    if (assigneesmembers != localStorage.loginId) {
      setReadOnly(true);
      setassigneesstatus(true);
    }
    else {
      setReadOnly(false);
      setassigneesstatus(false);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setTextClicked(false);
    setSelectedMemValues([]);
    setMemberValues([]);
    // window.location.reload();
  };
  // work count start
  const [objectiveText, setObjectiveText] = useState();
  const [descriptionText, setDescriptionText] = useState();
  const [remarkText, setRemarkText] = useState();

  const [parentObjective, setParentObjective] = useState({
    parentobjective: "",
    objectivename: "",
    description: "",
    cycleselectionoption: cycleselectedOption,
    individualweightage: "",
    orgObjId: "",
    objectiveid: "",
    revertStatus: 0,
    remark: "",
    status: "",
    organizationStatus: "",
    year: "",
    createdby: "",
    createdByEmpName: "",
    createdByEmpProfilePath: "",
    restrictDelete: false,
    groupid: [] as string[],
    signOffStatus: "",
  })
  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };

  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  });

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Objective");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  }

  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    if (newText.trim().split(/\s+/).join("").length <= maxWords) {

      if (Object.keys(parentObjective).includes(event.target.name)) {
        setParentObjective({
          ...parentObjective,
          [event.target.name]: event.target.value
        });
      }
      if (event.target.name == "individualweightage")
        userOrganizationList(editmemberdetails, event.target.value);
    }

  };
  const addorgobjective = (text: string, stat: boolean) => {
    var usersweightage;
    if (objectivetype == "orgobjective") {
      usersweightage = showCustomWeightage
    }
    else {
      usersweightage = showCustomWeightage.filter(user => user.id != localStorage.loginId)
    }
    if (text != "Save") {
      if (fhuserslists.length == 0 && selectedMemValues.length == 0 && assignedusers.length == 0 && MemberValues.length === 0 && mydetails == undefined) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
              <p className="fs-18"><b>Note : </b>AssignMembers Or CFT is Mandatory</p>
            </>
          ),
          centered: true,
        })
      }
      // else if (usersweightage.some(user => Number.isNaN(user.assignWeightage)) || usersweightage.some(user => user.assignWeightage == 0)) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      else {
        Modal.confirm({
          title: 'Do you want to ' + text + ' this objective ?',
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Objective Updated successfully.
                  </span>
                </div>
              ),
              duration: 2,
              icon: null,
            });

            text === "Save" ? updateParentObjective("1",text) :
              updateParentObjective("3",text)
            if (stat == false) {
              onClose();
              setTimeout(() => {
                objectivelist();
                // window.location.reload();
              }, 1000);
            }
            else {
              toggleCustomVisibility()
            }
            // console.log('Cascade action confirmed');
          },
          onCancel() {
            // console.log('Cascade action cancelled');
          },
        });
      }
    }
    // else {
    //   Modal.confirm({
    //     title: 'Do you want to ' + text + ' this objective ?',
    //     content: (
    //       <>
    //         <p className="fs-16 mb-3">{parentObjective.objectivename}</p>
    //         <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
    //       </>
    //     ),
    //     okText: text,
    //     cancelText: 'Back',
    //     okButtonProps: { className: 'C_btn' },
    //     cancelButtonProps: { className: 'outline_btn' },
    //     centered: true,
    //    })
    // }
    else {
      // if (usersweightage.some(user => user.avaiableWeightage == 0)) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Zero Please Check.</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      // else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
      Modal.confirm({
        title: 'Do you want to ' + text + ' this objective ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
            {/* {greaterWeightageUser.length === 0 ? ( */}
            <>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
            {/* ) : greaterWeightageUser.length === 1 ? (
                <>
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
                      <div className='text-center'>
                        <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
                        <p>{greaterWeightageUser[0].value}</p>
                      </div>
                    </Col>
                    <Col xl={18} lg={18} md={18} sm={16} xs={24}>
                      <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                      <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                  <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                </>)} */}
          </>
        ),
        okText: text,
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Objective Updated successfully.
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });

          text === "Save" ? updateParentObjective("1",text) :
            updateParentObjective("3",text)
          if (stat == false) {
            onClose();
            setTimeout(() => {
              objectivelist();
              // window.location.reload();
            }, 1000);
          }
          else {
            toggleCustomVisibility()
          }

          // saveobj()
          // console.log('Cascade action confirmed');
        },
        onCancel() {
          setCasClicked(false);
          // console.log('Cascade action cancelled');
        },
      });
      // }
    }
  }
  // else{
  //   Modal.confirm({
  //     title: 'Do you want to ' +text+ ' this objective ?',
  //     content: (
  //       <>
  //         <p className="fs-16 mb-3">{parentObjective.objectivename}</p>
  //         <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
  //       </>
  //     ),
  //     okText: text,
  //     cancelText: 'Back',
  //     okButtonProps: { className: 'C_btn' },
  //     cancelButtonProps: { className: 'outline_btn' },
  //     centered: true,
  //     onOk() {
  //       message.success({
  //         content: (
  //           <div className="success-message justify-center align-center p-1">
  //             <img src="assets/images/check-circle.svg" alt="Success Icon" />
  //             <span className="c_32C6A0 fs-16">
  //               Objective Created successfully.
  //             </span>
  //           </div>
  //         ),
  //         duration: 1,
  //         icon: null,
  //       });
  //       onClose(); 
  //       text === "Save" ? updateParentObjective("1") :
  //       updateParentObjective("2")
  //       // saveobj()
  //       console.log('Cascade action confirmed');
  //     },
  //     onCancel() {
  //       setClicked(false);
  //       console.log('Cascade action cancelled');
  //     },
  //   });
  // }

  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const addLoginUser = {
        id: localStorage.getItem("loginId") ?? '',
        name: localStorage.getItem('name') ?? '',
        email: localStorage.getItem('email') ?? '',
        departmentname: "",
        rolename: "",
        profilePath: localStorage.getItem('profilePath') ?? '',
      }
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
      users.push(addLoginUser);
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? FileUrl + id.profilePath : "assets/images/Default_user.svg",
        };
      });
      setMemOptions(newOptions);
    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  const [customWeightageList, setCustomWeightageList] = useState<customWeightage[]>([]);
  const userOrganizationList = async (userid: string[], individualweightage: string) => {
    try {
      const apiUrl = BaseUrl + `api/users/edit_objective_users`;
      const userObjectIdList: string[] = [];
      selectedMemValues.map((member) => {
        userObjectIdList.push(member.value);
      })


      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        userlist: userid,
        objective: objectiveId,
        obj_type: "obj"
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {

          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const existingUser = showCustomWeightage.find(user => user.id === res.id);
            if (existingUser) {
              addedCustomWeightage.push(existingUser);
            } else {
              const customWeights = {
                id: res.id,
                name: res.name,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: parseInt(res.assignWeightage),
                cftstatus: parseInt(res.cftstatus),
                profilePath: res.profilePath,
                objectivestatus: res.objectivestatus
              }
              addedCustomWeightage.push(customWeights);
            }

          })
          setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }

  interface userStoreDetails {
    assignedusername: string;
    userId: string;
    weightage: number;
  }
  interface group {
    id: string,
    groupname: string,
    assignedusers: string[],
    cftstatus: number
  }
  interface OptionItems {
    value: string;
    label: string;
    image: string;
    username: { name: any; id: any }[];
    weightage: number;
  }

  const [parentorganizationName, setOrganizationName] = useState();
  const [organizationDescription, setOrganizationDescription] = useState();
  const [editmemberdetails, seteditedmemberdetails] = useState<string[]>([]);
  const [signOffRequest, setSignOffRequest] = useState<number>(0);
  const editParentObjective = async () => {
    userslist();
    try {

      const apiUrl = BaseUrl + `api/objectives/view/${objectiveId}`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {

        const viewParentObjective = response.data.data;
        const viewOrgObjName = response.data.organizationObjective;
        setParentObjective({
          ...parentObjective,
          "parentobjective": viewOrgObjName,
          "objectivename": viewParentObjective.objectivesdetails.objectivename,
          "description": viewParentObjective.objectivesdetails.description,
          "individualweightage": viewParentObjective.objectivesdetails.individualweightage,
          "orgObjId": viewParentObjective.objectivesdetails.orgobjectiveid,
          "objectiveid": viewParentObjective.objectivesdetails.objectiveid,
          "revertStatus": viewParentObjective.objectivesdetails.revokestatus,
          "remark": viewParentObjective.objectivesdetails.remark,
          "status": viewParentObjective.objectivesdetails.organizationStatus,
          "organizationStatus": viewParentObjective.objectivesdetails.organizationStatus,
          "year": viewParentObjective.objectivesdetails.year,
          "createdby": viewParentObjective.objectivesdetails.createdBy,
          "createdByEmpName": viewParentObjective.objectivesdetails.createdByEmpName,
          "createdByEmpProfilePath": viewParentObjective.objectivesdetails.createdByEmpProfilePath,
          "restrictDelete": viewParentObjective.objectivesdetails.restrictDelete,
        })
        if (viewParentObjective.objectivesuserlist !== 0) {
          setisSearchMem(true);
        }

        // get the sign off status
        response.data.data.objectivesuserlist.map((userSignOff: any) => {
          if (userSignOff.userId === localStorage.getItem("loginId")) {
            // console.log(userSignOff.signOffStatus);
            setSignOffRequest(userSignOff.signOffStatus);
          }
        })

        setOrganizationName(response.data.organizationObjective);
        setOrganizationDescription(response.data.organizationDescription);
        setCycleSelectedOption(viewParentObjective.objectivesdetails.quarter + ' ' + viewParentObjective.objectivesdetails.year);
        const userlist: string[] = [];
        var existingMemberList: OptionItem[] = [];
        const fhuserslist: OptionItem[] = [];
        const assignedforme: OptionItem[] = [];
        var user: any;
        var user1: any;
        var userme: any;
        viewParentObjective.objectivesuserlist.map((member: any) => {

          if (member.cftstatus == 0) {
            user = {
              label:
                "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? FileUrl + member.profilePath : "assets/images/Default_user.svg",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            user1 = ""
          }
          else if (member.cftstatus == 1) {

            user1 = {
              label: "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.svg",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            user = "";
          }
          if (member.userId == localStorage.loginId) {
            userme = {
              label: member.assignedusername,
              value: member.userId,
              image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.svg",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            assignedforme.push(userme)
            // setCheckboxChecked(true)
          }
          else {
            // setCheckboxChecked(checkboxChecked);
          }

          if (user !== "") {
            existingMemberList.push(user);
          }

          if (user1 !== "") {
            fhuserslist.push(user1);
          }
        });

        // after cascading user in the member and group should not removed.
        // if (viewParentObjective.objectivesdetails.organizationStatus === "1") {
        //  setSelectedMemValues(existingMemberList);
        //   existingMemberList.map((user) => {
        //     userlist.push(user.value)
        //     // return user.value !== localStorage.loginId;+
        //     if (user.value === localStorage.getItem('loginId')) {
        //       setCheckboxChecked(true);
        //     }           
        //   })
        // } else {
        //   setMemberValues(existingMemberList);
        //   existingMemberList.map((resp) => {
        //     setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
        //     userlist.push(resp.value)
        //   })

        // existingMemberList.map((user) => {
        //   if (user.value === localStorage.getItem('loginId')) {
        //     setCheckboxCheckededit(true);
        //   }
        // })


        existingMemberList.map((resp) => {
          userlist.push(resp.value)
          if (resp.value === localStorage.getItem('loginId')) {
            setCheckboxCheckededit(true);
          }
        })

        if (viewParentObjective.objectivesdetails.groupid && viewParentObjective.objectivesdetails.groupid.length > 0) {
          const assigneduserdetails1: OptionItems[] = GrpOptions.filter(option => {
            // const match = viewParentObjective.objectivesdetails.groupid.every((grp:string) => grp == option.value);
            const match = viewParentObjective.objectivesdetails.groupid.includes(option.value);
            // Log each option value and its match result
            return match;
          });
          setisSearchMem(false);
          setSelectedGrpValues(assigneduserdetails1)
          setAssignedUsers(assigneduserdetails1)
        }
        else {
          if (viewParentObjective.objectivesdetails.createdBy != localStorage.loginId) {
            setMemberValues(existingMemberList)
            existingMemberList.map((resp) => {
              setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
            })
          }
          else {
            setSelectedMemValues((previousData) => {
              const newMembers =
                existingMemberList.filter((member) => Memoptions.some((memUser) => memUser.value === member.value)
                )
                ;
              return [...previousData, ...newMembers];
            });
            setMemberValues((previousData) => {
              const newMembers =
                existingMemberList.filter(
                  (members) => Memoptions.every((memUser) => memUser.value !== members.value))
              return [...previousData, ...newMembers];
            });
          }

        }
        // setmydetails(assignedforme[0])
        // fhuserslist.map((resp) => {
        //   setfhusersoption(res => res.filter(notselect => notselect.value !== resp.value));
        // })
        // handleMemSelectChange(existingMemberList);


        // seteditedmemberdetails(userlist);
        // selectedmemdetails(existingMemberList);
        setSelectedFhusers(fhuserslist);
        userOrganizationList(userlist, viewParentObjective.objectivesdetails.individualweightage)
      })
    } catch (error) {
      // console.log('Edit the Organization Objective', error);
      throw error;
    }
  }
  const getaudittrails = async () => {
    const datas:Boolean[]=[];
    try {
      if(objectivecode!=""){
        const apiUrl = BaseUrl + `api/auditrailremarks/getaudittrails/${objectivecode}`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        response.data.data.forEach((element:any) => {
          datas.push(false);
        });
        setObjAudit(datas);
        setMemberAudit(datas);
       setaudittrails(response.data.data);
      })
      }  
    } catch (error) {
      // console.log('Edit the Organization Objective', error);
      throw error;
    }
  }
  const ObjectivesSchemacascade = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    // description: z.string().min(1, { message: 'description field is required' }), 
    // individualweightage: z.string().min(1, { message: 'Individual weightage field is required' }),
  });
  const objectivesave = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
  });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [customweightagedetails, setCustomWeightageDetails] = useState<customWeightage[]>([]);


  let weightagePopupCount = 0;
  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < showCustomWeightage.length; index++) {

      if (showCustomWeightage[index].avaiableWeightage < showCustomWeightage[index].assignWeightage) {
        const popupUser = {
          value: showCustomWeightage[index].username,
          label: showCustomWeightage[index].name,
          image: showCustomWeightage[index].profilePath !== null ? showCustomWeightage[index].profilePath : "",
        }
        weightagePopupCount++;
        greaterWeightageUser.push(popupUser);
      }
    }
    return greaterWeightageUser;
  }
  type showCustomWeightageModel = {
    modelid: string,
    modelname: string,
    createdAt: string,
    status: number,
  }

  const [showObjectiveWeightage, setShowObjectiveWeightage] = useState<showCustomWeightageModel>();

  const assignWeightObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/weightagemasterobjective/${1}`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setShowObjectiveWeightage(response.data);
      })
    } catch (error) {
      
    }
   
  }

  const [showAlertWeightage, setShowAlertWeightage] = useState<boolean>(true);
  const handleSubmit = (status: string,text : string, sub: boolean) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }
    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = ObjectivesSchemacascade.safeParse(parentObjective);
    const validationsave = objectivesave.safeParse(parentObjective);
    if (status == "3") {
      if (validationResult.success) {
        // Data is valid, proceed with your logic
        setErrors(null);
        setCasClicked(true);
        // display the pop up weightage less than available 
        addorgobjective(text, sub)
        // const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
        // if(showObjectiveWeightage?.status === 0){
        //   if (weightagePopupCount === 0 || showAlertWeightage === false) {
        //     addorgobjective("Update", sub)
        //   } else {
        //     setShowAlertWeightage(false);
        //     weightagePopupCount === 1 ?
        //     handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, parentObjective.objectivename, "Objective") :
        //     handleproceed(greaterWeightageUser, parentObjective.objectivename, "Objective");
        //   }
        // }else{
        //   if (weightagePopupCount === 0 || showAlertWeightage === false) {
        //     addorgobjective("Update", sub)
        //   } else {
        //     weightagePopupCount === 1 ?
        //     handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, parentObjective.objectivename, "Objective") :
        //     handleproceed(greaterWeightageUser, parentObjective.objectivename, "Objective");
        //   }
        // }
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
        setClicked(false);
        setCasClicked(false);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic
        setErrors(null);
        addorgobjective("Save", sub)
        setClicked(true);
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
        setClicked(false);
        setCasClicked(false);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };
  const handlesubmitcustomweightage = (status: string) => {
    const usersweightage = showCustomWeightage.filter(user => user.id != localStorage.loginId)
    // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }
    // Disable the button
    setIsWeightageButton(true);
    if (parentObjective.objectivename == "") {
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              Objective And ObjectiveName Name Is Mandatory
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      toggleCustomVisibility()
    }
    else {

      // if (usersweightage.some(user => user.avaiableWeightage == 0)) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // } else {
      let emptyAssignedValue = 0;
      usersweightage.map(weightage => {
        if (weightage.assignWeightage === 0) {
          emptyAssignedValue++;
        }
      })
      Modal.confirm({
        title: 'Do you want to save the weightage this objective ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{parentObjective.objectivename}</p>
            {0 < emptyAssignedValue ? <p className="fs-14">Please ensure it should be equal to 100% weightage</p> : (<></>)}
            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          </>
        ),
        okText: 'Save',
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Weightage Saved successfully.
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });
          parentObjective.organizationStatus === "1" ? updateParentObjective("1","Update") : updateParentObjective("3","Update")
          toggleCustomVisibility();
        },
        onCancel() {
          setCasClicked(false);
          // console.log('Cascade action cancelled');
        },
      });

      // }
      // if (parentObjective.status == "2") {
      //   handleSubmit("2", true)
      // }
      // else {
      //   handleSubmit("1", true)
      // }
    }
    setTimeout(() => {
      // Enable the button
      setIsWeightageButton(false);
    }, 2000);
  }
  const updateParentObjective = async (objectiveStatus: string,text:string) => {
    try {
      const apiUrl = BaseUrl + `api/objectives/update/${objectiveId}`;
      const selectedMemberList: userStoreDetails[] = [];
      fhuserslists.map((group, index) => {
        const fhusers = {
          assignedusername: group.label,
          userId: group.value,
          weightage: 0,
          cftstatus: 1,
          objectiveStatus: objectiveStatus
          // empId : group.
        }
        selectedMemberList.push(fhusers);
      })

      showCustomWeightage.map((member) => {
        var objstatus;

        //console.log("object_status"+member.objectivestatus);
        if(text=="Update" && (member.objectivestatus=="1" || member.objectivestatus=="3"))
        {
          objstatus= member.objectivestatus;
        }else{
          objstatus= "3";
        }
       // console.log("org_status",objstatus);
        const user = {
          userId: member.id,
          assignedusername: member.name,
          weightage: member.assignWeightage,
          cftstatus: member.cftstatus,
          objectiveStatus:objstatus
        }
        selectedMemberList.push(user);
      })
      if (selectedMemValues.length !== 0) {
        setisSearchMem(true);
      } else {
        setisSearchMem(false);
      }
      const objective = {
        ...parentObjective,
        createdByEmpName: null // Or null, depending on how you want to handle it
      };
      await axios.put(apiUrl, {
        objectivesdetails: objective,
        objectivesuserlist: selectedMemberList,
        saveOrCascade: objectiveStatus
      },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      ).then((response) => {
        objectivelist();
        orgobjectivelist();
        // const viewParentObjective = response.data.data;
        // const viewOrgObjName = response.data.organizationObjective;
        // setParentObjective({
        //   ...parentObjective,
        //   "parentobjective": viewOrgObjName,
        //   "objectivename": viewParentObjective.objectivesdetails.objectivename,
        //   "description": viewParentObjective.objectivesdetails.description,
        //   "individualweightage": viewParentObjective.objectivesdetails.individualweightage,
        //   "remark": viewParentObjective.objectivesdetails.remark,
        //   "status": viewParentObjective.objectivesdetails.organizationStatus,
        //   "organizationStatus": viewParentObjective.objectivesdetails.organizationStatus,
        //   "year": viewParentObjective.objectivesdetails.year,
        //   "createdby": viewParentObjective.objectivesdetails.createdBy,
        //   "restrictDelete": viewParentObjective.objectivesdetails.restrictDelete
        // })

        // if (viewParentObjective.objectivesuserlist !== 0) {
        //   setisSearchMem(true);
        // }

        // setOrganizationName(response.data.organizationObjective);
        // setOrganizationDescription(response.data.organizationDescription);
        // setCycleSelectedOption(viewParentObjective.objectivesdetails.quarter + ' ' + viewParentObjective.objectivesdetails.year);
        // const userlist: string[] = [];
        // var existingMemberList: OptionItem[] = [];
        // const fhuserslist: OptionItem[] = [];
        // const assignedforme: OptionItem[] = [];
        // var user: any;
        // var user1: any;
        // var userme: any;
        // viewParentObjective.objectivesuserlist.map((member: any) => {
        //   if (member.cftstatus == 0) {
        //     user = {
        //       label:
        //         "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
        //       value: member.userId,
        //       image: member.profilePath ? FileUrl + member.profilePath : "assets/images/Default_user.svg",//"assets/images/member.svg",
        //       cftstatus: member.cftstatus
        //     }
        //     user1 = ""
        //   }
        //   else if (member.cftstatus == 1) {

        //     user1 = {
        //       label: "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
        //       value: member.userId,
        //       image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.svg",//"assets/images/member.svg",
        //       cftstatus: member.cftstatus
        //     }
        //     user = "";
        //   }

        //   if (user !== "") {
        //     existingMemberList.push(user);
        //   }

        //   if (user1 !== "") {
        //     fhuserslist.push(user1);
        //   }
        // });


        // seteditedmemberdetails(userlist);
        // setSelectedMemValues(existingMemberList);
        // window.location.reload();
      })
    } catch (error) {
      // console.log("ERROR::::::::::" + error);
    }
  }

  const [quarteropt, setQuarterOpt] = useState<OptionQuarter[]>([]);
  const [fhuserslists, setSelectedFhusers] = useState<OptionItem[]>([]);
  const [fhuseroption, setfhusersoption] = useState<OptionItem[]>([]);
  fhuseroption.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  const handleMemFHChange = (selected: OptionItem[]) => {
    const fhmemvalues = fhuseroption.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const membersdetails: string[] = []
    fhmemvalues.forEach((values) => {
      membersdetails.push(values.value)
    })
    setSelectedFhusers(fhmemvalues);
    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    // selectedmemdetails(userid);
  };
  const fhuserslist = async () => {
    try {
      const fhusers = await axios.get(BaseUrl + "api/users/FHuserslist", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });

      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = fhusers.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        return {
          value: id.id,
          label: id.rolename + ":" + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? (FileUrl + id.profilePath) : "assets/images/Default_user.svg", // Assuming you have an image property for each user
          weightage: 0
        };
      });
      setfhusersoption(newOptions);
    } catch (error) {
      // console.error(error)
    }

  }
  const getcycleselectedOption = async () => {
    try {
      const list = await axios.get(BaseUrl + "api/organization/getCycle");
      const listdata: string[] = list.data;
      setQuarterOpt(
        listdata.map((quarter: string) => ({
          value: quarter,
          label: quarter,
        }))
      );
    } catch (error) {
      // Handle the error
      // console.error('Error fetching data:', error);
    }
  }


  function openModalAndEditObjective() {
    GetUserAccess();
    getWordCount();
    groupuserslist();
    getcycleselectedOption();
    fhuserslist();
    showDrawer();
    editParentObjective();
    getaudittrails();
    setTextClicked(true)
  }

  function customWeightageAndOpenToggle() {
    toggleCustomVisibility();

  }

  // word count end
  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const groupuserslist = async () => {
    try {
      const groupslist = await axios.get(BaseUrl + "api/group/users_group",
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`
          }
        });
      const groupusersdatas: group[] = groupslist.data;
      const newoptions = groupusersdatas.map((id) => ({
        value: id.id,
        label: id.groupname,
        image: "",
        weightage: 0,
        username: id.assignedusers.map((userString: any) => {
          return {
            name: userString.name,
            id: userString.userid,
          };
        }), // Assuming you have an image property for each user
      }));
      setGrpOptions(newoptions);
    } catch (error) {
      
    }
   
  }
  const handleFHusersTagClose = (valueToRemove: string) => {
    const updatedMemValues = fhuserslists.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedFhusers(updatedMemValues);
  };

  const GetUserAccess = async () => {

    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "Edit") {
          setPermission_orgobjedit(permission.org_objective)
        }
        if (permission.menu === "Delete") {
          setPermission_orgobjdelete(permission.org_objective)
        }
        if (permission.menu === "View") {
          setPermission_orgobjview(permission.org_objective)
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };


  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>);


  // Custom Weightage and View Weightage  hide and show
  const [ViewWeightage, setViewWeightage] = useState(false);

  const toggleViewWeightage = () => {
    // alert(1)
    setViewWeightage(!ViewWeightage);
  };
  const ViewWeightageBack = () => {
    setViewWeightage(false);
  }


  const [readOnly, setReadOnly] = useState(true);
  const [assigneesstaus, setassigneesstatus] = useState(false);
  const handleClickEdit = () => {
    setReadOnly(false);
    // remove readonly
    // editkeyresults()
  };
  const krdec_cancel = () => {
    setReadOnly(true);
  };
  //declare const localStorage: Storage;
  useEffect(() => {
    // assignWeightObjective();
    userslist();
    groupuserslist();
    //userRequestSignOffStatus();
    // userOrganizationList(editmemberdetails,parentObjective.individualweightage);
  }, [readOnly])
  const handleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {objectiveName}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
            "objectiveid": objectiveId,
            "status": revertstatus,
            "orgObjectiveId": parentObjective.orgObjId.toString(),
            "masterobjid": parentObjective.objectiveid.toString(),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">
                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });

            }
            onClose()
            setTimeout(() => {
              objectivelist();
              // window.location.reload();
            }, 1000);
            //window.location.reload();
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          // Handle network errors or other exceptions
          // console.error("Error:", error);
          // For example, show an error message
          message.error("An error occurred");
        }
      },
    });
  };


  const items: MenuProps["items"] = [
    {
      label: (
        <>

          {parentObjective?.revertStatus !== 1 ?
            (<Row onClick={handleClickEdit} className="p-0">
              <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
              Edit
            </Row>) : null}

        </>
      ),
      key: "0",
    },
    parentObjective?.revertStatus !== 1
      && parentObjective?.organizationStatus === "3"
      ?
      {
        label: (
          <Row onClick={() => { handleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    parentObjective?.revertStatus == 1 ?
      {
        label: (
          <Row onClick={() => { handleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // parentObjective.status === "1" && 
    parentObjective.restrictDelete === false
      // &&  permission_orgobjedit
      ?
      {
        label: (

          <Row
            className="c_FF4747 fs-14 p-0"
            // onClick={() => handleDelete(keyResultsValuesedit.keytitle)}
            onClick={() => handleDeleteObject(parentObjective.parentobjective)}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      } : null,
  ];
  const handleDeleteObject = (kr: any) => {
    Modal.confirm({
      title: "Do you want to delete this key result ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr}
            {/* “ Achieve a 20% reduction in average server response time. ” */}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteParentObjective();
        onClose();
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        setTimeout(() => {
          objectivelist();
          // window.location.reload();
        }, 1000);
      },
      onCancel() {
        // console.log("Delete action cancelled");
      },
    });
  };

  const deleteParentObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/delete/${objectiveId}`;

      await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        //objectivelist();
        // window.location.reload();
      })
    } catch (error) {
      // console.log("ERROR::::::::::::::" + error);

    }

  }
  const [reject, setReject] = useState(false);
  const [agree, setAgree] = useState(false);
  const [writeFB, setWriteFB] = useState(false);

  const handleReject = () => {
    setReject(true);
  }
  const handleCancel = () => {
    setReject(false)
  }
  const handleAgree = () => {
    setAgree(true);
  }
  const handleFBSubmit = () => {
    setAgree(false);
  }
  const handleWriteFB = () => {
    setWriteFB(true);
  }
  const handleWriteBack = () => {
    setWriteFB(false);
  }


  const [textClicked, setTextClicked] = useState(false);

  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }


  return (
    <div className="">
      <Row onClick={openModalAndEditObjective} className={`p-0 fs-14 ${textClicked ? 'c_32B7D9' : ''}`}>
        {objectiveName === "" ?
          (<>
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
            Edit
          </>) : (<>
            {/* {permission_orgobjview ? ( */}
            <p className={`p-0 fs-16 threeline_text_overflow  ${textClicked ? 'clickText_active' : 'clickText'}`}  >
              {objectiveName}
            </p>
            {/* ) : ''} */}
          </>)}
        {/* <button className="border-0 bg-transparent fs-14 align-center" onClick={openModalAndEditObjective} > */}
        {/* <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
          Edit */}
        {/* </button> */}
      </Row>
      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Weightage
              <p></p>
            </div>

          ) : (
            <>
              <div className="justify-between">
                {   
                  readOnly && objectiveName !== "" ? (!writeFB ? (objectivetype == "orgobjective" ?  objectivecode: objectivecode) : "Write feedback") : (objectivetype == "orgobjective" ? " Edit Parent Objective" : "Edit Child Objective")
                }
                <div className="mr-5 cur-pointer">
                  {readOnly && objectiveName !== "" && localStorage.loginId == parentObjective.createdby ? (
                    <>
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <a onClick={(e) => { e.preventDefault() }}>
                          {userSignOff.signOffStatus === 0 &&
                            <Space>
                              <img src="assets/images/menu.svg" alt="" />
                            </Space>
                          } 
                        </a>
                      </Dropdown>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
            // type != false ?
            //   ("Edit Objective") : ("View Objective")

          )
        }
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={open}
        className="objective-canvas mb-5 position-relative"
      >


        <div>
          {!writeFB ? (
            <>
              {readOnly == true ?
                (
                  <>
                    {ViewWeightage ? (
                      <>
                        <div className="cw_table">
                          {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}

                          {/* <p className="fs-16 c_32B7D9 fs-14">Product Members (18) 0</p> */}
                          <Row justify={"space-between"} className="mb-2">
                            <Col
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                              className="position-relative"
                            >
                              <input
                                type="search"
                                placeholder="Search Member"
                                className="w-100 table_search_inputbox pl-2"
                                value={searchTextValue}
                                // onSearch={handleSearch}
                                onChange={(e) => setSearchTextValue(e.target.value)}
                              />
                              <img
                                src="assets/images/search.svg"
                                className="table_search_img"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Table
                            dataSource={custom_filteredData}
                            columns={columns}
                            scroll={{ x: true }}
                            pagination={{ pageSize: 10 }}
                          />
                          <button className="cancel-btn float-right" onClick={ViewWeightageBack}>
                            Back
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="obj_tab">
                          <Tabs defaultActiveKey="1" onChange={onChange} className="p-0"  >
                            <TabPane tab="General" key="1" className="p-0">
                              <div>
                                <div className="mb-3">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    {objectivetype == "orgobjective" ? "Organization Objective" : "Parent Objective Name"}
                                  </label>{" "}
                                  <br />
                                  {/* <p className="fs-16">{objname}</p> */}
                                  <p className="fs-16">{parentObjective.parentobjective}</p>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    {objectivetype == "orgobjective" ? "Organization Objective Description " : "Parent Objective Description"}
                                    {/* Parent Objective Description */}
                                  </label>{" "}
                                  <br />
                                  {/* <p className="fs-16">{objname}</p> */}
                                  <p className="fs-16">{organizationDescription}</p>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Objective Name
                                  </label>{" "}
                                  <br />
                                  {/* <p className="fs-16">{objname}</p> */}
                                  <p className="fs-16">{parentObjective.objectivename}</p>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Objective Description
                                  </label>{" "}
                                  <br />
                                  <p className="fs-16">
                                    {parentObjective.description}
                                  </p>
                                </div>
                                <div className="mb-3">
                                  <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                      <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                        Cycle
                                      </label>
                                      {/* <p className="fs-16">{keyResultsValues.quarter} 2024 - 2025 </p> */}
                                      <p className="fs-16">{cycleselectedOption}</p>
                                      {/* <p className="fs-16">2024</p> */}
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                      <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                        Created By
                                      </label>
                                      <div className="align-center mt-1">
                                        <img
                                          src={parentObjective.createdByEmpProfilePath ? FileUrl + parentObjective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                          onError={handleImageError}
                                          alt=""
                                          className="user"
                                        />
                                        <span className="pl-1">{parentObjective.createdByEmpName}</span>
                                      </div>
                                    </Col>
                                    {/* <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <label htmlFor="" className="fw-600 fs-14">
                              Visibility Upto
                            </label>
                            <p className="fs-16">{VisibilityselectedOption}</p>
                          </Col> */}

                                  </Row>
                                </div>
                                {assigneesstaus == true && type == true ? (
                                  <div className="assign-section mb-3">
                                    <label htmlFor="" className="fw-600 c_32B7D9">
                                      Assign Members <sup className="c_32B7D9">*</sup>
                                    </label>
                                    {Memoptions.length == 0 &&
                                      <span className="danger ml-3 c_32B7D9">Assignees Not Available</span>
                                    }
                                    <span
                                      className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                                      onClick={handleSearchMember}
                                    >
                                      {isSearchMem ? "Search Groups" : "Search Members"}
                                    </span>
                                    {/* {isSearchMem ?
                                    (<span className="float-right c_32B7D9 text_line fs-14 cur-pointer">
                                      <Checkbox className="c_32B7D9 fs-14" onClick={handlecheckboxeditObj}
                                        id="includeMyselfCheckboxedit"
                                        checked={checkboxCheckededit}>Include Myself</Checkbox>
                                    </span>) : (<></>)} */}

                                    <br />
                                    <div className="mt-1">
                                      {isSearchMem ? (
                                        <Select
                                          mode="multiple"
                                          placeholder={
                                            <div
                                              style={{ display: "flex", alignItems: "center" }}
                                            >
                                              <img
                                                src="assets/images/member.svg"
                                                alt="Placeholder"
                                                style={{ marginRight: "8px" }}
                                              />
                                              <span>Select Member</span>
                                            </div>
                                          }
                                          onChange={handleMemSelectChange}
                                          value={selectedMemValues}
                                          className="w-100 form-control"
                                          labelInValue
                                          optionLabelProp="label"
                                          dropdownRender={(menu) => <div>{menu}</div>}
                                          notFoundContent={<CustomEmptyMemberText />}
                                          // options={Memoptions}
                                          // filterOption={(input, option) =>
                                          //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                          // }
                                          filterOption={(input, option) =>
                                            option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                          }
                                          disabled={Memoptions.length == 0}
                                        >
                                          {Memoptions.map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                              label={option.label}
                                            >
                                              {renderOption(option)}
                                            </Option>
                                          ))}
                                        </Select>
                                      ) : (
                                        <Select
                                          mode="multiple"
                                          placeholder={
                                            <div
                                              style={{ display: "flex", alignItems: "center" }}
                                            >
                                              <img
                                                src="assets/images/people.svg"
                                                alt="Placeholder"
                                                style={{ marginRight: "8px" }}
                                              />
                                              <span>Select Group</span>
                                            </div>
                                          }
                                          onChange={handleGrpSelectChange}
                                          value={selectedGrpValues}
                                          className="w-100 form-control"
                                          labelInValue
                                          optionLabelProp="label"
                                          dropdownRender={(menu) => <div>{menu}</div>}
                                          notFoundContent={<CustomEmptyGroupText />}
                                          // options={GrpOptions}
                                          // filterOption={(input, option) =>
                                          //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                          // }
                                          filterOption={(input, option) =>
                                            option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                          }
                                          disabled={Memoptions.length == 0}
                                        >
                                          {GrpOptions.map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                              label={option.label}
                                            >
                                              {renderOption(option)}
                                            </Option>
                                          ))}
                                        </Select>
                                      )}
                                      <div
                                        className={
                                          checkboxCheckededit
                                            ? "assgin_selected_val"
                                            : "" ||
                                              selectedMemValues
                                              ? "assgin_selected_val"
                                              : "" || selectedGrpValues
                                                ? "assgin_selected_val"
                                                : "" || mydetails
                                                  ? "assgin_selected_val"
                                                  : ""

                                        }
                                      >
                                        {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)})</p></Row> : ''}
                                        {MemberValues.map((value) => (
                                          <Tag
                                            key={value.value}
                                            // closable
                                            // onClose={() => handleMemTagClose(value.value)}
                                            style={{
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              color: "#000",
                                              borderRadius: 15,
                                              padding: "5px 10px",
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={value.image}
                                              onError={handleImageError}
                                              alt=""
                                              style={{ marginRight: "8px" }}
                                              className="select_user"
                                            />
                                            {value.label}
                                          </Tag>
                                        ))}
                                        {mydetails != undefined
                                          &&
                                          <Tag
                                            key={mydetails?.value}
                                            // closable
                                            onClose={() => handleTagClose(mydetails?.value)}
                                            style={{
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              color: "#000",
                                              borderRadius: 15,
                                              padding: "5px 10px",
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={mydetails?.image}
                                              onError={handleImageError}
                                              alt=""
                                              style={{ marginRight: "8px" }}
                                              className="select_user"
                                            />
                                            {mydetails?.label}
                                          </Tag>
                                        }
                                        {selectedMemValues.map((value) => (
                                          <Tag
                                            key={value.value}
                                            closable={value.value === localStorage.getItem("loginId") ? false : true}
                                            onClose={() => handleMemTagClose(value.value)}
                                            style={{
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              color: "#000",
                                              borderRadius: 15,
                                              padding: "5px 10px",
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={value.image}
                                              onError={handleImageError}
                                              alt=""
                                              style={{ marginRight: "8px" }}
                                              className="select_user"
                                            />
                                            {value.label}
                                          </Tag>
                                        ))}
                                        {assignedusers.map((value) => (
                                          <Tag
                                            key={value.value}
                                            closable
                                            onClose={() => handleTagClose(value.value)}
                                            style={{
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              color: "#000",
                                              borderRadius: 15,
                                              padding: "5px 10px",
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src="assets/images/Default_user.svg"
                                              alt=""
                                              style={{ marginRight: "8px" }}
                                              className="select_user"
                                            />
                                            {value.label}
                                          </Tag>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mb-3">
                                    <div className="justify-between mb-1">
                                      {assignedusers.length > 0 ? (
                                        <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                          Assign Groups
                                        </label>) : (
                                        <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                          Assign Members
                                        </label>
                                      )
                                      }
                                      <span
                                        className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                                        onClick={toggleViewWeightage}
                                      >
                                        View Weightage <ArrowRightOutlined />
                                      </span>
                                      {/* <span
                                  className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                                  onClick={customWeightageAndOpenToggle}
                                >
                                  Click here to Update Weightage <ArrowRightOutlined />
                                </span> */}
                                    </div>
                                    {assignedusers.length > 0 ? (
                                      assignedusers.map(option => (
                                        <span className="">
                                          <span className="am_view">
                                            <img
                                              src={option.image}
                                              onError={handleImageError}
                                              className="select_user"
                                              style={{ position: "relative", top: "5px" }}
                                              alt=""
                                            />
                                            <span className="pl-1">{option.label}</span>
                                          </span>
                                        </span>
                                      )
                                      )
                                    ) :
                                      <>

                                        {selectedMemValues.map(option => (
                                          <span className="" key={option.label}>
                                            <span className="am_view">
                                              <img
                                                src={option.image}
                                                onError={handleImageError}
                                                className="select_user"
                                                style={{ position: "relative", top: "5px" }}
                                                alt=""
                                              />
                                              <span className="pl-1">{option.label}</span>
                                            </span>
                                          </span>
                                        ))}
                                        {MemberValues.map(option => (
                                          <span className="" key={option.label}>
                                            <span className="am_view">
                                              <img
                                                src={option.image}
                                                onError={handleImageError}
                                                className="select_user"
                                                style={{ position: "relative", top: "5px" }}
                                                alt=""
                                              />
                                              <span className="pl-1">{option.label}</span>
                                            </span>
                                          </span>
                                        ))}

                                      </>
                                    }

                                    {selectedMemValues.length == 0 && assignedusers.length == 0 && MemberValues.length == 0 &&
                                      <p> Assigness Not Available</p>
                                    }
                                  </div>)
                                }
                                <div className="d-flex" style={{ flexDirection: "column" }}>
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    CFT
                                  </label>
                                  {fhuserslists.map(option => (
                                    <span className="align-center am_view" style={{ margin: "2px" }}>
                                      <img
                                        src={option.image}
                                        onError={handleImageError}
                                        className="select_user"
                                        alt=""
                                      />
                                      <span className="pl-1">{option.label}</span>
                                    </span>
                                  )
                                  )}
                                  {fhuserslists.length == 0 &&
                                    <p>CFT Not Available</p>
                                  }
                                </div>
                                {assigneesstaus == true && type == true ? (
                                  <>
                                    {/* <Row className="align-center">
                                <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                                  <label htmlFor="" className="fw-600">
                                    Individual Weightage <sup className="c_32B7D9">*</sup>
                                  </label>
                                  <Input
                                    placeholder=""
                                    className="canvas-objective-input mt-1"
                                    value={parentObjective.individualweightage}
                                    // onChange={(e) => {
                                    //   handleTextChange(e,
                                    //     setDescriptionText,
                                    //     maxWords.description
                                    //   )
                                    // }
                                    // }
                                    // disabled={objectiveName !== "" || type == false}
                                    onChange={(e) => {
                                      const inputVal = e.target.value;
                                      const numericRegex = /^[0-9]*$/;
                                      if (inputVal === 'customized' ||inputVal === ''|| (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                        handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                      }
                                    }}
                                    name="individualweightage"

                                  />
                                  {errors && errors.find(error => error.path[0] === 'individualweightage') && (
                                    <p className="danger">{errors.find(error => error.path[0] === 'individualweightage')?.message}</p>
                                  )}
                                </Col>
                                <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                                  <p className="pl-2 pt-2">
                                    i.e, Weightage only be a Percentage
                                  </p>
                                </Col>
                              </Row>
                                <Col xl={5} lg={5} md={6} sm={12} xs={24}>
                                  <span
                                    className="c_32B7D9 fs-14 cur-pointer text_line float-right"
                                    onClick={closeDrawerAndUserList}
                                  >
                                    Click here to Update Weightage <ArrowRightOutlined />
                                  </span>
                                </Col> */}


                                    <div className="justify-between">
                                      <label className="canvas-objective-title">Remarks (Optional)</label>
                                      <span className="c-0050 fs-12 mt-1">
                                        {parentObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                                        {maxWords.remark_max_word_count}
                                      </span>
                                    </div>
                                    <div className="objective-title-inner">
                                      <TextArea
                                        rows={3}
                                        placeholder=""
                                        className="canvas-objective-input w-100"
                                        value={parentObjective.remark}
                                        // disabled={objectiveName !== ""}
                                        // onChange={(e) =>
                                        //   setParentObjective({
                                        //     ...parentObjective,
                                        //     remark: e.target.value
                                        //   })
                                        // }
                                        onChange={(e) =>
                                          handleTextChange(
                                            e,
                                            setParentObjective,
                                            maxWords.remark_max_word_count
                                          )
                                        }
                                        name="remark"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="mb-3">
                                    <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                      Remarks
                                    </label>{" "}
                                    <br />
                                    <p className="fs-16">
                                      {parentObjective.remark}
                                    </p>
                                  </div>
                                )}
                              </div>
                              {assigneesstaus === true && type === true ? (
                                <div className="Submit-section">
                                  {/* {parentObjective.status == "1" && (
                                        <button
                                          className="cancel-btn"
                                          onClick={() => {
                                            handleSubmit("1", false);
                                          }}
                                          disabled={isButtonDisabled}
                                          // disabled={clicked}
                                        >
                                          {isCustomVisible ? "Back" : "Save"}
                                        </button>
                                      )}
                                      {parentObjective.status != "1" &&
                                        <button className="cancel-btn" onClick={onClose}>Cancel</button>
                                      }
                                      <button
                                        className={
                                          parentObjective.objectivename === "" ||
                                          // parentObjective.description === "" || 
                                          cycleselectedOption === ""
                                          // ||
                                          // parentObjective.individualweightage === ""
                                          ? "cascade_button"
                                          : "cyan_button"
                                        }
                                        onClick={() => {
                                          handleSubmit("2", false);
                                        }}
                                        disabled={isButtonDisabled}
                                        //  disabled={casclicked}
                                      >
                                        {parentObjective.status === "1" ? "Cascade" : "Update"}
                                      </button> */}
                                  {/* {parentObjective.status == "1" ? ( */}
                                  <>
                                    <button className="cancel-btn" onClick={onClose}> Cancel </button>
                                    <button
                                      className={
                                        parentObjective.objectivename === "" ||
                                          cycleselectedOption === ""
                                          ? "cascade_button"
                                          : "cyan_button"
                                      }
                                      onClick={() => { handleSubmit("3","Cascade", false) }}
                                      disabled={isButtonDisabled}
                                    >
                                      Cascade
                                      {/* {parentObjective.status == "1"? "Save" : "Save"} */}
                                    </button>
                                    <button className="cyan_button ml-1"
                                      onClick={() => { parentObjective.status == "1" ? handleSubmit("1","Update", false) : handleSubmit("3","Update", false) }}
                                      disabled={isButtonDisabled}
                                    >
                                      Update
                                      {/* {isCustomVisible ? "Back" : "Save"} */}
                                    </button>
                                  </>
                                  {/* ):( */}
                                  <>
                                    {/* <button className="cancel-btn" onClick={onClose}>Cannbdfjkbnkjdcel</button> */}
                                  </>
                                  {/* )} */}
                                </div>
                              ) : (
                                <></>
                              )}
                            </TabPane>
                            {/* <TabPane tab="Feedback" key="2" className="p-0"> */}
                            <TabPane tab="Audit Trail" key="2" className="p-0">
                            {/* <TabPane tab="" key="2" className="p-0"> */}
                              <div>
                                <div className="mb-3 border_bottom">
                                  <label htmlFor="" className="fw-600 fs-14">
                                   Objective Name
                                  </label>{" "}
                                  <br />
                                  {/* <p className="fs-16">{objname}</p> */}
                                  <p className="fs-16">{parentObjective.objectivename}</p>
                                </div>
                                {/* <div className="border_bottom">
                                  <div className="align-center">
                                    <img src="assets/images/connect_b.svg" alt="" /><span className="pl-1 c_32B7D9 text_line fs-14">Connect</span>
                                  </div>
                                </div> */}
                                
                                
                                {audittrails.map((audits,index)=>(
                                  <div>
                                <ul className="Obj_list">
                                  {audits.status==0 && (
                                  <li>
                                    <div>
                                      <div className="d-flex align-start">
                                        <div className="icon"></div>
                                        <div>
                                        <p className="fs-14 fw-700">Objective {audits.remark} </p>
                                        <p className="fs-12">{audits.createdat}</p>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </li>
                                  )
                                  }
                                  {audits.status == 2 && (
                                    <li className="mt-1">
                                    <div className="d-flex justify-between align-start">
                                        <div className="d-flex align-start">
                                            <div className="icon"></div>
                                          <div>
                                            <p className="fs-14 fw-700">{audits.modelname=="Objectivename"?"Objective Name Edited":"Objective "+audits.modelname+" Edited"}   </p>
                                            <p className="fs-12">{audits.createdat}</p>
                                            {
                                      objAudit[index] &&
                                      (
                                        <div>
                                        <label htmlFor="" className="c_29ABE2 fs-12">New name</label>
                                        <p className="fs-16 m-0">{audits.newdata}</p>
                                        <label htmlFor="" className="c_aaa fs-12">Old name</label>
                                        <p className="fs-16 m-0">{audits.olddata}</p>
                                      </div>
                                      )
                                    }
                                          </div>
                                      </div>
                                      <div>
                                        {
                                          objAudit[index] ? (
                                            <UpOutlined onClick={()=>handleObjAudit(index)} style={{width:"10px"}} />
                                          ):(
                                           <DownOutlined  onClick={()=>handleObjAudit(index)} style={{width:"10px"}}/>
                                          )
                                        }
                                      </div>
                                    </div>
                                    
                                  
                                  </li>
                                  )}
                                  {audits.status == 1 && (
                                  <li className="mt-1">
                                    <div>
                                      <div  className="d-flex justify-between align-start">
                                        <div className="d-flex align-start">
                                        <div className="icon"></div>
                                        <div>
                                          <p className="fs-14 fw-700">{audits.modelname} </p>
                                          <p className="fs-12">{audits.createdat}</p>
                                        </div>
                                        </div>
                                      
                                        <div>
                                          {
                                            memberAudit[index] ? (
                                              <UpOutlined onClick={()=>handleMebAudit(index)} style={{width:"10px"}} />
                                            ):(
                                          <DownOutlined  onClick={()=>handleMebAudit(index)} style={{width:"10px"}}/>
                                            )
                                          }
                                        </div>
                                      </div>
                                      {
                                        memberAudit[index] && (
                                          <div className="objective_overall d-flex">
                                          {/* <span className="every_am_view mr-1 cur-pointer"><span className="pl-1">Everyone</span></span> */}
                                          {/* <span className="connect_view  mr-1 cur-pointer p-2"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun </span><span className="error_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan </span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan</span><span className="success_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">priya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Riya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">DIvya Priya</span></span> */}
                                          {JSON.parse(audits.newdata).map((user:any, idx:number) => (
                                            <span key={idx} className="connect_view mr-1 cur-pointer p-2">
                                              <img src={user.profilpath || "assets/images/Default_user.svg"} className="select_user" alt="" />
                                              <span className="pl-1 nowrap">{user.username}</span>
                                            </span>
                                          ))}
                                        </div>
                                        )
                                      }
                                    
                                    </div>
                                  </li>
                                  )}
                                </ul>
                                </div>
                                ))
                              }
                                
                                
                              </div>
                            </TabPane>
                            <TabPane tab="" key="3" className="p-0">
                              <div>
                                <div className="mb-3">
                                  <label htmlFor="" className="fw-600 fs-14">
                                    Objective Name
                                  </label>{" "}
                                  <br />
                                  {/* <p className="fs-16">{objname}</p> */}
                                  <p className="fs-16">{parentObjective.objectivename}</p>
                                </div>
                                <div className="border_bottom">
                                  <div className="align-center">
                                    <img src="assets/images/connect_b.svg" alt="" /><span className="pl-1 c_32B7D9 text_line fs-14">Connect</span>
                                  </div>
                                </div>
                                {localStorage.getItem("loginId") !== parentObjective.createdby &&
                                  <div>
                                    <div>


                                      <div>
                                        <Flex justify="space-between" align="center">
                                          <div>
                                            <p className="c-000 fw-600">Feedback Agreed on</p>
                                            <p className="fs-12">01 Jan 2024, 11:02 AM</p>
                                          </div>
                                          <div className="align-center">
                                            <img src="assets/images/user.svg" alt="" className="user" />
                                            <span className="ml-1 fs-14">Daison</span>
                                          </div>
                                        </Flex>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <Flex justify="space-between" align="center">
                                        <div>
                                          <p className="c-000 fw-600">Feedback received</p>
                                          <p className="fs-12">01 Jan 2024, 11:02 AM</p>
                                        </div>
                                        <div className="align-center">
                                          <img src="assets/images/user.svg" alt="" className="user" />
                                          <span className="ml-1 fs-14">Arjun</span>
                                        </div>
                                      </Flex>
                                      <div>
                                        <p className="fs-16 org_obj_name">Your exceptional dedication and initiative towards achieving this objective have been instrumental in our team's success.</p>
                                      </div>

                                      {reject ? (
                                        <div>
                                          <div className="title-section mt-2 mb-3">
                                            <div className="justify-between">
                                              <label className="fw-600 fs-14">Write Reason</label><span className="c-000 fs-12 mt-1">17 / 500</span>
                                            </div>
                                            <div className="objective-title-inner">
                                              <textarea id="objective" placeholder="Write here...." name="objectivename" className="w-100 canvas-objective-input">testing obj name  nbv</textarea>
                                            </div>
                                          </div>
                                        </div>) : ("")
                                      }

                                      {agree ? (
                                        <div>
                                          <Flex justify="space-between" align="center">
                                            <div>
                                              <p className="c-000 fw-600">Feedback Agreed</p>
                                              <p className="fs-12">01 Jan 2024, 11:02 AM</p>
                                            </div>
                                            <div className="align-center">
                                              <img src="assets/images/user.svg" alt="" className="user" />
                                              <span className="ml-1 fs-14">Daison</span>
                                            </div>
                                          </Flex>
                                          <div>
                                          </div>
                                        </div>) : ("")
                                      }

                                      {agree ? ("") : (
                                        <div className="float-right">
                                          {!reject ?
                                            <button className="reject-btn mr-1" onClick={handleReject}>Reject</button>
                                            : <button className="reject-btn mr-1" onClick={handleCancel}>Cancel</button>}
                                          {!reject && !agree ?
                                            <button className="agree_button" onClick={handleAgree}>Agree</button>
                                            : <button className="agree_button" onClick={handleFBSubmit}>Submit</button>}
                                        </div>
                                      )}
                                    </div>
                                  </div>}
                                {localStorage.getItem("loginId") === parentObjective.createdby &&
                                  <div>
                                    <div className="border_bottom  d-flex justify-between w-100">
                                      <p>Feedbacks Given<span>0/{showCustomWeightage.length}</span></p>
                                      <p className="text_line c_32B7D9 cur-pointer" onClick={handleWriteFB}>Write Feedback</p>
                                    </div>
                                    <div className="mt-2 ">
                                      <label htmlFor="" className="fs-14 fw-600">Feedback given to</label>

                                      <div className="objective_overall d-flex">
                                        {showCustomWeightage.map(user => (
                                          <span className="connect_view mr-1 cur-pointer p-2">
                                            <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="select_user" alt="" />
                                            <span className="pl-1">{user.name}</span>
                                            <span className="c_32B7D9 pl-1">(03)</span>
                                          </span>
                                        ))}

                                        {/* <span className="every_am_view mr-1 cur-pointer"><span className="pl-1">Everyone</span></span> */}
                                        {/* <span className="connect_view  mr-1 cur-pointer p-2"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Arjun </span><span className="c_32B7D9 pl-1">(03)</span></span>
                                      <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Thomsan </span><span className="c_32B7D9 pl-1">(03)</span></span>
                                      <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Thomsan</span><span className="c_32B7D9 pl-1">(03)</span></span>
                                      <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Everyone</span><span className="c_32B7D9 pl-1">(03)</span></span>
                                      <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Everyone</span><span className="c_32B7D9 pl-1">(03)</span></span>
                                      <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1">Everyone</span><span className="c_32B7D9 pl-1">(03)</span></span> */}
                                      </div>
                                    </div>
                                    <Flex justify="space-between" align="center">
                                      <div>
                                        <p className="c-000 fw-600">Feedback Given on</p>
                                        <p className="fs-12">01 Jan 2024, 11:02 AM</p>
                                      </div>
                                      <div className="align-center">
                                        <img src="assets/images/user.svg" alt="" className="user" />
                                        <span className="ml-1 fs-14">Arjun</span>
                                      </div>
                                    </Flex>
                                    {/* view feedback of */}
                                    <div className="mt-2 ">
                                      <label htmlFor="" className="fs-14 fw-600">View Feedback of</label>
                                      <div className="objective_overall d-flex">
                                        {/* <span className="every_am_view mr-1 cur-pointer"><span className="pl-1">Everyone</span></span> */}
                                        <span className="connect_view  mr-1 cur-pointer p-2"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun </span><span className="error_cricle ml-1"></span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan </span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan</span><span className="success_cricle ml-1"></span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">priya</span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Riya</span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun</span></span>
                                        <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">DIvya Priya</span></span>
                                      </div>
                                    </div>
                                    <Flex justify="space-between" align="center">
                                      <div>
                                        <p className="c-000 fw-600">Feedback Given on</p>
                                        <p className="fs-12">01 Jan 2024, 11:02 AM</p>
                                      </div>
                                      <div className="align-center">
                                        <img src="assets/images/user.svg" alt="" className="user" />
                                        <span className="ml-1 fs-14">Arjun</span>
                                      </div>
                                    </Flex>
                                    <div>
                                      <p className="fs-16 org_obj_name">These initiatives to be completed by Mar 2024. Coordinate with concerned departments and get feedback</p>
                                    </div>
                                  </div>}
                              </div>

                            </TabPane>
                          </Tabs>
                        </div>
                      </>
                    )}

                  </>
                )
                // :
                //     (
                //       <>
                //         <div className="mb-3">
                //           <label htmlFor="" className="fw-600 fs-14">
                //             Parent Objective
                //           </label>{" "}
                //           <br />
                //           {/* <p className="fs-16">{objname}</p> */}
                //           <p className="fs-16">{parentObjective.parentobjective}</p>
                //         </div>
                //         <div className="mb-3">
                //           <label htmlFor="" className="fw-600 fs-14">
                //           Objective Description
                //           </label>{" "}
                //           <br />
                //           {/* <p className="fs-16">{objname}</p> */}
                //           <p className="fs-16">{organizationDescription}</p>
                //         </div>
                //         <div className="mb-3">
                //           <label htmlFor="" className="fw-600 fs-14">
                //             Objective Name
                //           </label>{" "}
                //           <br />
                //           {/* <p className="fs-16">{objname}</p> */}
                //           <p className="fs-16">{parentObjective.objectivename}</p>
                //         </div>
                //         <div className="mb-3">
                //           <label htmlFor="" className="fw-600 fs-14">
                //             Description
                //           </label>{" "}
                //           <br />
                //           <p className="fs-16">
                //             {parentObjective.description}
                //           </p>
                //         </div>
                //         <div className="mb-3">
                //           <Row>
                //             <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                //               <label htmlFor="" className="fw-600 fs-14">
                //                 Cycle
                //               </label>
                //               {/* <p className="fs-16">{keyResultsValues.quarter} 2024 - 2025 </p> */}
                //               <p className="fs-16">{cycleselectedOption}</p>
                //               {/* <p className="fs-16">2024</p> */}
                //             </Col>
                //             <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                //               <label htmlFor="" className="fw-600 fs-14">
                //                 Created By
                //               </label>
                //               <div className="align-center mt-1">
                //                 <img
                //                   src={parentObjective.createdByEmpProfilePath ? FileUrl + parentObjective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                //                   onError={handleImageError}
                //                   alt=""
                //                   className="user"
                //                 />
                //                 <span className="pl-1">{parentObjective.createdByEmpName}</span>
                //               </div>
                //             </Col>
                //             {/* <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                //               <label htmlFor="" className="fw-600 fs-14">
                //                 Visibility Upto
                //               </label>
                //               <p className="fs-16">{VisibilityselectedOption}</p>
                //             </Col> */}

                //           </Row>
                //         </div>
                //         <div className="mb-3">
                //           <div className="justify-between mb-1">
                //             <label htmlFor="" className="fw-600 fs-14">
                //               Assign Members
                //             </label>
                //             <span
                //               className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                //               onClick={toggleViewWeightage}
                //             >
                //               View Weightage <ArrowRightOutlined />
                //             </span>
                //           </div>

                //           {selectedMemValues.map(option => (
                //             <span className="">
                //               <span className="am_view">
                //                 <img
                //                   src={option.image}
                //                   onError={handleImageError}
                //                   className="select_user"
                //                   style={{ position: "relative", top: "5px" }}
                //                   alt=""
                //                 />
                //                 <span className="pl-1">{option.label}</span>
                //               </span>
                //             </span>
                //           )
                //           )}
                //           {selectedMemValues.length == 0 &&
                //             <p> Assigness Not Available</p>
                //           }
                //         </div>
                //         <div className="d-flex" style={{ flexDirection: "column" }}>
                //           <label htmlFor="" className="fw-600 fs-14">
                //             CFT
                //           </label>
                //           {fhuserslists.map(option => (
                //             <span className="align-center am_view" style={{ margin: "2px" }}>
                //               <img
                //                 src={option.image}
                //                 onError={handleImageError}
                //                 className="select_user"
                //                 alt=""
                //               />
                //               <span className="pl-1">{option.label}</span>
                //             </span>
                //           )
                //           )}
                //           {fhuserslists.length == 0 &&
                //             <p>CFT Not Available</p>
                //           }
                //         </div>
                //         <div className="mb-3">
                //           <label htmlFor="" className="fw-600 fs-14">
                //             Remarks
                //           </label>{" "}
                //           <br />
                //           <p className="fs-16">
                //             {parentObjective.remark}
                //           </p>
                //         </div>
                //       </>
                //     )}

                //   </>
                // )
                :
                (
                  <>
                    <Form className="mb-5">
                      {isCustomVisible ? (
                        <div className="cw_table">
                          {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)})</p></Row> : ''}
                          <Row justify={"space-between"} className="mb-2">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="position-relative">
                              <input
                                type="search"
                                placeholder="Search Member"
                                className="w-100 table_search_inputbox pl-2"
                                value={searchTextValue}
                                // onSearch={handleSearch}
                                onChange={(e) => setSearchTextValue(e.target.value)}
                              />
                              <img src="assets/images/search.svg" className="table_search_img" alt="" />
                            </Col>
                          </Row>
                          {/* <Table dataSource={showCustomWeightage} columns={columns} pagination={{ pageSize: 5 }} /> */}
                          <Table dataSource={custom_filteredData} columns={columns} pagination={{ pageSize: 10 }} />
                        </div>
                      ) : (
                        <>
                          <div>
                            <div className="mb-3">
                              <Row>
                                <Col xl={17} lg={17} md={17} sm={17} xs={24}>
                                  <label htmlFor="" className="fw-600">
                                    {objectivetype == "orgobjective" ? "Organization Objective" : "Parent Objective"}
                                  </label>
                                  <br />
                                  <p className="mt-3">
                                    {parentorganizationName}
                                  </p>
                                  {/* <Select
                              className="custom-select mt-1"
                              style={{ width: 200 }}
                              placeholder="Select an option"
                              value=
                              disabled
                            disabled={objectivestatus === "2"}
                            > */}
                                  {/* <Option value="obj1">
                                obj1
                              </Option>
                              <Option value="obj2">
                                obj2
                              </Option>
                              <Option value="obj3">
                                obj3
                              </Option> */}
                                  {/* </Select> */}
                                </Col>
                                <Col xl={7} lg={7} md={7} sm={7} xs={24}>
                                  <label htmlFor="" className="fw-600">
                                    Cycle
                                  </label>
                                  <br />

                                  <p className="mt-3">
                                    {cycleselectedOption}
                                  </p>
                                  {/* <Select
                              className="custom-select mt-1"
                              style={{ width: 200 }}
                              placeholder="Select an option"
                              onChange={handleCycleSelect}
                              value={cycleselectedOption}
                              disabled
                              // disabled={objectivestatus === "2"}
                            >
                              {quarteropt.map((option) => (
                                <Option value={option.value.split(' ')[0]}>
                                  <Radio
                                    value={option.value.split(' ')[0]}
                                    checked={cycleselectedOption === option.value.split(' ')[0]}
                                    onChange={handleCycleRadio}
                                  >
                                    {option.label}
                                  </Radio>
                                </Option>
                              ))}
                              
                            </Select> */}
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <label htmlFor="" className="fw-600">{objectivetype == "orgobjective" ? "Organization Objective Description" : "Parent Description"}</label> <br />
                                <p className="org_obj_name">{organizationDescription}</p>
                              </Col>
                            </Row>
                            <div className="title-section mb-3">
                              <div className="justify-between">
                                <label htmlFor="objective" className="fw-600">
                                  Objective Name <sup className="c_32B7D9">*</sup>
                                </label>
                                <span className="c-0050 fs-12 mt-1">
                                  {parentObjective.objectivename.trim().split(/\s+/).join("").length} /{" "}
                                  {maxWords.name_max_word_count}
                                </span>
                              </div>
                              <div className="objective-title-inner">
                                <TextArea
                                  id="objective"
                                  rows={3}
                                  placeholder="Write here...."
                                  className="canvas-objective-input"
                                  //disabled={objectivestatus === "2"}
                                  value={parentObjective.objectivename}
                                  onChange={(e) =>
                                    handleTextChange(
                                      e,
                                      setObjectiveText,
                                      maxWords.name_max_word_count
                                    )
                                  }
                                  name="objectivename"
                                // disabled={type == false || objectivestatus === "2"}
                                />
                                {errors && errors.find(error => error.path[0] === 'objectivename') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'objectivename')?.message}</p>
                                )}
                              </div>
                            </div>
                            <div className="desc-section mb-3">
                              <div className="justify-between">
                                <label htmlFor="" className="fw-600">
                                  Description (Optional)
                                  {/* <sup className="c_32B7D9">*</sup> */}
                                </label>
                                <span className="c-0050 fs-12 mt-1">
                                  {parentObjective.description.trim().split(/\s+/).join("").length} /{" "}
                                  {maxWords.description_max_word_count}
                                </span>
                              </div>
                              <div className="objective-title-inner">
                                <TextArea
                                  rows={3}
                                  placeholder="Write here...."
                                  className="canvas-objective-input"
                                  value={parentObjective.description}
                                  onChange={(e) =>
                                    handleTextChange(
                                      e,
                                      setParentObjective,
                                      maxWords.description_max_word_count
                                    )
                                  }
                                  // disabled={type == false}
                                  // onChange={(e) =>
                                  //   setParentObjective({
                                  //     ...parentObjective,
                                  //     description: e.target.value
                                  //   })
                                  // }
                                  name="description"
                                />
                                {/* {errors && errors.find(error => error.path[0] === 'description') && (
                          <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                          )} */}
                              </div>
                            </div>
                            <div className="assign-section mb-3">
                              <label htmlFor="" className="fw-600">
                                Assign Members <sup className="c_32B7D9">*</sup>
                              </label>
                              {Memoptions.length == 0 &&
                                <span className="danger ml-3">Assignees Not Available</span>
                              }
                              <span
                                className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                                onClick={handleSearchMember}
                              >
                                {isSearchMem ? "Search Groups" : "Search Members"}
                              </span>
                              {/* {isSearchMem ?
                                (<span className="float-right c_32B7D9 text_line fs-14 cur-pointer">
                                  <Checkbox className="c_32B7D9 fs-14" onClick={handlecheckboxeditObj}
                                    id="includeMyselfCheckboxedit"
                                    checked={checkboxCheckededit}>Include Myself</Checkbox>
                                </span>) : (<></>)} */}
                              <br />
                              <div className="mt-1">
                                {isSearchMem ? (
                                  <Select
                                    mode="multiple"
                                    placeholder={
                                      <div
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <img
                                          src="assets/images/member.svg"
                                          alt="Placeholder"
                                          style={{ marginRight: "8px" }}
                                        />
                                        <span>Select Member</span>
                                      </div>
                                    }
                                    onChange={handleMemSelectChange}
                                    value={selectedMemValues}
                                    className="w-100 form-control"
                                    labelInValue
                                    optionLabelProp="label"
                                    dropdownRender={(menu) => <div>{menu}</div>}
                                    notFoundContent={<CustomEmptyMemberText />}
                                    // options={Memoptions}
                                    // filterOption={(input, option) =>
                                    //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    // }
                                    filterOption={(input, option) =>
                                      option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    }
                                    disabled={Memoptions.length == 0}
                                  >
                                    {Memoptions.map((option) => (
                                      <Option
                                        key={option.value}
                                        value={option.value}
                                        label={option.label}
                                      >
                                        {renderOption(option)}
                                      </Option>
                                    ))}
                                  </Select>
                                ) : (
                                  <Select
                                    mode="multiple"
                                    placeholder={
                                      <div
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <img
                                          src="assets/images/people.svg"
                                          alt="Placeholder"
                                          style={{ marginRight: "8px" }}
                                        />
                                        <span>Select Group</span>
                                      </div>
                                    }
                                    onChange={handleGrpSelectChange}
                                    value={selectedGrpValues}
                                    className="w-100 form-control"
                                    labelInValue
                                    optionLabelProp="label"
                                    dropdownRender={(menu) => <div>{menu}</div>}
                                    notFoundContent={<CustomEmptyGroupText />}
                                    // options={GrpOptions}
                                    // filterOption={(input, option) =>
                                    //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    // }
                                    filterOption={(input, option) =>
                                      option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    }
                                    disabled={Memoptions.length == 0}
                                  >
                                    {GrpOptions.map((option) => (
                                      <Option
                                        key={option.value}
                                        value={option.value}
                                        label={option.label}
                                      >
                                        {renderOption(option)}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                                <div
                                  className={
                                    checkboxCheckededit
                                      ? "assgin_selected_val"
                                      : "" ||
                                        selectedMemValues
                                        ? "assgin_selected_val"
                                        : "" || selectedGrpValues
                                          ? "assgin_selected_val"
                                          : "" || mydetails
                                            ? "assgin_selected_val"
                                            : ""

                                  }
                                >
                                  {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)})</p></Row> : ''}
                                  {MemberValues.map((value) => (
                                    <Tag
                                      key={value.value}
                                      // closable
                                      // onClose={() => handleMemTagClose(value.value)}
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={value.image}
                                        onError={handleImageError}
                                        alt=""
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                      />
                                      {value.label}
                                    </Tag>
                                  ))}
                                  {mydetails != undefined
                                    &&
                                    <Tag
                                      key={mydetails?.value}
                                      // closable
                                      onClose={() => handleTagClose(mydetails?.value)}
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={mydetails?.image}
                                        onError={handleImageError}
                                        alt=""
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                      />
                                      {mydetails?.label}
                                    </Tag>
                                  }
                                  {selectedMemValues.map((value) => (
                                    <Tag
                                      key={value.value}
                                      closable={value.value === localStorage.getItem("loginId") ? false : true}
                                      onClose={() => handleMemTagClose(value.value)}
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={value.image}
                                        onError={handleImageError}
                                        alt=""
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                      />
                                      {value.label}
                                    </Tag>
                                  ))}
                                  {assignedusers.map((value) => (
                                    <Tag
                                      key={value.value}
                                      closable
                                      onClose={() => handleTagClose(value.value)}
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src="assets/images/Default_user.svg"
                                        alt=""
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                      />
                                      {value.label}
                                    </Tag>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="assign-section mb-3">
                              <label htmlFor="" className="fw-600">
                                Assign CFT (Optional)
                              </label>
                              <div className="mt-1">
                                <Select
                                  mode="multiple"
                                  placeholder={
                                    <div
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <img
                                        src="assets/images/member.svg"
                                        alt="Placeholder"
                                        style={{ marginRight: "8px" }}
                                      />
                                      <span>Select Member</span>
                                    </div>
                                  }
                                  onChange={handleMemFHChange}
                                  value={fhuserslists}
                                  className="w-100 form-control"
                                  labelInValue
                                  optionLabelProp="label"
                                  dropdownRender={(menu) => <div>{menu}</div>}
                                  notFoundContent={<CustomEmptyMemberText />}
                                  //   options={fhuseroption}
                                  //   filterOption={(input, option) =>
                                  //     option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                  // }
                                  filterOption={(input, option) =>
                                    option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                  }

                                >
                                  {fhuseroption.map((option) => (
                                    <Option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {renderOption(option)}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="assgin_selected_val mb-3">
                              {fhuserslists.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">CFT({fhuserslists.length})</p></Row> : ''}
                              {fhuserslists.length === 0 ? (
                                <>
                                  <p className="c_AAA text-center">No CFT added</p>
                                </>
                              ) :
                                (
                                  <>
                                    {fhuserslists.map((value) => (
                                      <Tag
                                        key={value.value}
                                        closable
                                        onClose={() => handleFHusersTagClose(value.value)}
                                        style={{
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          color: "#000",
                                          borderRadius: 15,
                                          padding: "5px 10px",
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={value.image}
                                          onError={handleImageError}
                                          alt=""
                                          style={{ marginRight: "8px" }}
                                          className="select_user"
                                        />
                                        {value.label}
                                      </Tag>
                                    ))}
                                  </>
                                )}
                            </div>
                            <div className="mb-3">
                              <Row className={isCustomVisible ? "d-none" : ""}>
                                <Col xl={18} lg={18} md={18} sm={12} xs={24}>
                                  <Row className="align-center">
                                    <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                                      <label htmlFor="" className="fw-600">
                                        Individual Weightage <sup className="c_32B7D9">*</sup>
                                      </label>
                                      <Input
                                        placeholder=""
                                        className="canvas-objective-input mt-1"
                                        value={parentObjective.individualweightage}
                                        // onChange={(e) => {
                                        //   handleTextChange(e,
                                        //     setDescriptionText,
                                        //     maxWords.description
                                        //   )
                                        // }
                                        // }
                                        // disabled={objectiveName !== "" || type == false}
                                        onChange={(e) => {
                                          const inputVal = e.target.value;
                                          const numericRegex = /^[0-9]*$/;
                                          if (inputVal === 'customized' || inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                            handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                          }
                                        }}
                                        name="individualweightage"

                                      />
                                      {errors && errors.find(error => error.path[0] === 'individualweightage') && (
                                        <p className="danger">{errors.find(error => error.path[0] === 'individualweightage')?.message}</p>
                                      )}
                                    </Col>
                                    <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                                      <p className="pl-2 pt-2">
                                        i.e, Weightage only be a Percentage
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={24}>
                                  <span
                                    className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                                    onClick={customWeightageAndOpenToggle}
                                  >
                                    Custom Weightage <ArrowRightOutlined />
                                  </span>
                                </Col>
                              </Row>
                            </div>
                            <div className="remark-section mb-3">
                              <div className="justify-between">
                                <label className="canvas-objective-title">Remarks (Optional)</label>
                                <span className="c-0050 fs-12 mt-1">
                                  {parentObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                                  {maxWords.remark_max_word_count}
                                </span>
                              </div>
                              <div className="objective-title-inner">
                                <TextArea
                                  rows={3}
                                  placeholder=""
                                  className="canvas-objective-input w-100"
                                  value={parentObjective.remark}
                                  // disabled={objectiveName !== ""}
                                  // onChange={(e) =>
                                  //   setParentObjective({
                                  //     ...parentObjective,
                                  //     remark: e.target.value
                                  //   })
                                  // }
                                  onChange={(e) =>
                                    handleTextChange(
                                      e,
                                      setParentObjective,
                                      maxWords.remark_max_word_count
                                    )
                                  }
                                  name="remark"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="Submit-section">
                        {isCustomVisible ? (
                          <>
                            <button
                              className="cancel-btn"
                              onClick={toggleCustomVisibility}
                            >
                              Back
                            </button>
                            <button
                              // className="cyan_button" 
                              onClick={() => { handlesubmitcustomweightage("1") }} disabled={isWeightageButton}
                              className={"cyan_button"
                              }
                            >Submit</button>
                            {/* <button className="cyan_button">submit</button> */}
                          </>
                        ) : (
                          <>
                            {/* {parentObjective.status == "1" ? ( */}
                            <>
                              <button className="cancel-btn" onClick={onClose}>Cancel</button>
                              <button
                                className={
                                  parentObjective.objectivename === "" ||
                                    cycleselectedOption === ""
                                    ? "cascade_button"
                                    : "cyan_button"
                                }
                                onClick={() => { parentObjective.status == "1" ? handleSubmit("1","Update", false) : handleSubmit("3","Update", false) }}
                                disabled={isButtonDisabled}
                              >
                                Update
                                {/* {parentObjective.status == "1"? "Save" : "Save"} */}
                              </button>
                              {/* {parentObjective.status == "1" ? ( */}
                                <button className="cyan_button ml-1" onClick={() => { handleSubmit("3","Cascade", false) }}
                                  disabled={isButtonDisabled}
                                >
                                  Cascade
                                  {/* {isCustomVisible ? "Back" : "Save"} */}
                                </button>
                              {/* ) : (<></>)} */}
                            </>
                            {/* ):( */}
                            <>
                              {/* <button className="cancel-btn" onClick={onClose}>Cancel</button> */}

                            </>
                            {/* )} */}
                          </>
                        )}
                      </div>
                    </Form>
                  </>
                )}
            </>
          ) : (<>
            <div>
              <Flex justify="space-between">
                <span>To</span>
                <Checkbox className="c_32B7D9 fs-14">Include Everyone</Checkbox>
                <Checkbox className="c_32B7D9 fs-14">Include Remaining</Checkbox>
              </Flex>

              <div className="assign-section mb-3">
                <label htmlFor="" className="fw-600">
                  Search Assignees
                </label>
                <span
                  className="float-right c_32B7D9 text_line fs-12 cur-pointer"
                // onClick={handleSearchMember}
                >
                </span>
                <br />
                <div className="mt-1">
                  <Select
                    mode="multiple"
                    placeholder={
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src="assets/images/member.svg"
                          alt="Placeholder"
                          style={{ marginRight: "8px" }}
                        />
                        <span>Select Member</span>
                      </div>
                    }
                    onChange={handleFeedbackMemSelectChange}
                    value={selectedFeedbackMemValues}
                    className="w-100 form-control"
                    labelInValue
                    optionLabelProp="label"
                    dropdownRender={(menu) => <div>{menu}</div>}
                  >
                    {showCustomWeightage.map((option) => (
                      <Option
                        key={option.id}
                        value={option.id}
                        label={option.name}
                      >
                        {/* {renderOption(option)} */}
                        <img src={FileUrl + option.profilePath} alt="" className="member_user" style={{ marginRight: "8px" }} onError={handleImageError} />
                        {option.name}
                      </Option>

                    ))}
                  </Select>
                </div>
              </div>
              <div
                className={
                  selectedMemValues.length > 0
                    ? "assgin_selected_val"
                    : ""
                }
              >
                {selectedFeedbackMemValues.length > 0 ? <span className="c_32B7D9 text_line">Assignees</span> : ""} <br />
                {selectedFeedbackMemValues.map((value) => (
                  <Tag
                    key={value.value}
                    closable
                    onClose={() => handleMemTagClose(value.value)}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      marginTop: "5px",
                      color: "#000",
                      borderRadius: 15,
                      padding: "5px 10px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={value.image}
                      alt={value.label}
                      style={{ marginRight: "8px" }}
                      className="select_user"
                    />
                    {value.label}
                  </Tag>
                ))}

              </div>
              <div className="title-section mt-2 mb-3">
                <div className="justify-between">
                  <label className="fw-600 fs-14">Write Feedback</label><span className="c-000 fs-12 mt-1">17 / 500</span>
                </div>
                <div className="objective-title-inner">
                  <textarea id="objective" placeholder="Write here...." name="objectivename" className="w-100 canvas-objective-input" onChange={(e) => setFeedback(e.target.value)}>testing obj name  nbv</textarea>
                </div>
              </div>
              <div className="float-right">
                <button className="reject-btn mr-1" onClick={handleWriteBack}>Cancel</button>
                <button className="agree_button" >Submit</button>
              </div>
            </div>
          </>)}
        </div>
      </Drawer>
    </div>
  );
};

export default EditParentObj;
