import React, { useState, useEffect, useRef } from 'react';
import { Row, Tabs, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const Support: React.FC = () => {
    const [activeSection, setActiveSection] = useState<string>('1');
    const supportContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const supportContent = supportContentRef.current;
            if (supportContent) {
                const sections = supportContent.getElementsByClassName('support-section');
                const scrollPosition = supportContent.scrollTop + supportContent.clientHeight / 4;
                // console.log(scrollPosition);
                let activeSectionId = '1';

                for (let i = 0; i < sections.length; i++) {
                    const section = sections[i] as HTMLElement;
                    const sectionTop = section.offsetTop;
                    const sectionBottom = section.offsetTop + section.offsetHeight;

                    if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                        activeSectionId = section.id;
                        break;
                    }
                }

                setActiveSection(activeSectionId);
            }
        };

        const supportContent = supportContentRef.current;
        if (supportContent) {
            supportContent.addEventListener('scroll', handleScroll);
            return () => {
                supportContent.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const handleNavClick = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        const supportContent = supportContentRef.current;
        if (section && supportContent) {
            setActiveSection(sectionId);
            supportContent.scrollTo({
                top: section.offsetTop - supportContent.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const handleDownload = () => {
        const fileName = '../assets/images/GoalSync Support Document.pdf';
        const link = document.createElement('a');
        link.href = `./${fileName}`;
        if (typeof link.download === 'string') {
          link.download = 'GoalSync Support Document.pdf';
        } else {
          link.target = '_blank';
        }
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
      };

    return (
        <>
            <button className="support-download d-flex float-right mr-3" onClick={handleDownload}>
                <DownloadOutlined/>
            </button>
            <img src="" alt="" />
            <Tabs defaultActiveKey="1" className='support-tab mt-2'>
                <TabPane tab="Support Document" key="1">
                    <Row>
                        <Col xl={4} lg={6} md={6} sm={24} xs={24} className='support-sidebar'>
                            <nav>
                                <div className="support-nav">
                                    <ul>
                                        <li>
                                            <a className={activeSection === '1' ? 'active' : ''} onClick={() => handleNavClick('1')}> Welcome to GoalSync! </a>
                                        </li>
                                        <li>
                                            <a className={activeSection === '2' ? 'active' : ''} onClick={() => handleNavClick('2')}>Getting Started</a>
                                        </li>
                                        <li>
                                            <a className={activeSection === '3' ? 'active' : ''} onClick={() => handleNavClick('3')}>Core Features</a>
                                        </li>
                                        <li>
                                            <a className={activeSection === '4' ? 'active' : ''} onClick={() => handleNavClick('4')}>User flow</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </Col>
                        <Col xl={20} lg={18} md={18} sm={24} xs={24} className='support-content' id="support-content" ref={supportContentRef}>
                            <section id="1" className="home support-section">
                                <p className='support-title mb-4 m-0'>Welcome to GoalSync!</p>
                                <span className='support-description'>This document is designed to help the user understand the core functionality of GoalSync, an application built to streamline OKR (Objectives and Key Results) management process.</span>
                                <p className='support-subtitle'>What is OKR ?</p>
                                <span className='support-description'>OKRs is a goal-setting framework individuals and teams use to define ambitious but achievable objectives and track progress through measurable key results.</span>

                            </section>

                            <section id="2" className="support-section">
                                <p className='support-title'>Getting started:</p>
                                <p className='support-subtitle'>Accessing the System:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>To access the “Goalsync" system, open your preferred web browser and enter the provided URL.</li>
                                    <li className='support-description'>Use your credentials for Single Sign-On (SSO) based on your role for secure and role-based access.</li>
                                </ul>
                                <p className='support-subtitle'>Landing Page:</p>
                                <p className='support-mutetitle'>Upon successful login:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Access the home page displaying the organization's vision and mission.</li>
                                    <li className='support-description'>View organizational and assigned objectives.</li>
                                    <li className='support-description'>Utilize the search bar and filters for easy navigation</li>
                                </ul>
                                <p className='support-mutetitle'>Personal Zone:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Profile details, including Name, Photo, Designation, and Mail ID, are available in the right corner. Users can sign out from here.</li>
                                </ul>
                                <p className='support-mutetitle'>Selection Filters </p>
                                <ul className='support-ul'>
                                    <li className='support-description'><b>Objectives:</b> Allows users to select objectives displayed on the landing page.</li>
                                    <li className='support-description'><b>Members:</b> Enables users to select members associated with objectives. </li>
                                </ul>
                                <p className='support-subtitle'>Navigation and Views:</p>
                                <p className='support-mutetitle'>Toggle</p>
                                <span className='support-description'>Use the action button to toggle between my objectives and the organizational objectives.</span>
                                <img src="assets/images/support_document/image001.png" alt="" className='support-img'/>
                                <p className='support-mutetitle'>Members Toggle </p>
                                <span className='support-description'>Use the action button to toggle between my members and works with me.</span>
                                <img src="assets/images/support_document/image003.png" alt="" className='support-img'/>
                                {/* <ul className='support-ul'>
                                    <li className='support-description'>Use the action button to toggle between Organizational objectives and Aspirational Objectives</li>
                                </ul> */}
                            </section>
                            <section id="3" className="support-section">
                                <p className='support-title'>Core features:</p>
                                <p className='support-subtitle'>Organization objective:</p>
                                <span className='support-description'>An organizational objective in the OKR tool refers to a broad, qualitative goal that outlines the <b>desired future state</b> for the entire organization. It sets strategic direction and inspires employees to work towards a common purpose.</span>
                                <p className='support-subtitle'>Parent objective:</p>
                                <span className='support-description'>A parent objective is an objective that cascades down from a higher-level organizational objective. It serves as a <b>bridge</b> between the broader organizational goals and the specific objectives of individual teams or departments.</span>
                                <p className='support-subtitle'>Child objective:</p>
                                <span className='support-description'>A child objective is also a <b>sub-objective</b> created to break down a larger, parent objective into more manageable and measurable components which is a more specific and focused objective derived from the parent objective. It further clarifies what needs to be accomplished to contribute to the larger goal.</span>
                                <p className='support-subtitle'>Key results:</p>
                                <span className='support-description'>In Goal Sync, key results are <b>measurable outcomes</b> that define how you will achieve your objectives. They act as the quantifiable benchmarks used to track progress toward your ambitious goals.</span>
                                <p className='support-subtitle'>Lead measures:</p>
                                <span className='support-description'>In Goal Sync, lead measures act as early indicators of progress towards your key results. They are self-created by employees and function as <b>self-check points,</b> allowing individuals to monitor their progress and identify potential roadblocks before they impact key results.</span>

                            </section>
                            <section id="4" className="support-section">
                                <p className='support-title'>USER FLOW</p>
                                <p className='support-subtitle'>Login:</p>
                                <span className='support-description'>Users can initiate the journey by logging in with role-specific access.</span><br />
                                <img src="assets/images/support_document/image005.png" alt="" className='support-img' />
                                {/* <p className='support-subtitle'>Landing Page:</p> */}
                                <p className='support-mutetitle'>CREATING ORGANIZATIONAL OBJECTIVE</p>
                                <p className='support-subtitle'>Access Organizational Objectives:</p>
                                <span className='support-description'>Utilize the toggle to view the Organizational Objectives page.</span>
                                <p className='support-subtitle'>Create Objective</p>
                                <span className='support-description'>To create objectives users must click "Create Objective" to initiate the process. </span>
                                <img src="assets/images/support_document/image007.png" alt="" className='support-img' />
                                <span className='support-description'><b>Fill details:</b> Objective name, Description, Visibility, Cycle (quarter details), Assign members, Individual weightage, Remarks. Click "Save" to store the created Objective.</span><br/>
                                <span className='support-description'><b>Assign Members:</b> Users can assign objectives by selecting members or selecting groups on‘Select members’ or ‘select groups</span><br/>
                                <span className='support-description'><b>Weightage:</b> Users can customize the weightage based on the availability of individuals.</span><br/><br/>
                                <span className='support-description mt-2'><b>Note:</b> Visibility feature is available only for Organizational objectives.</span>
                                <img src="assets/images/support_document/image009.png" alt="" className='support-img' /><br />
                                {/* <div className="double-img">
                                    <img src="assets/images/support_document/image007.png" alt="" className='mr-1' /><img src="assets/images/support_document/image009.png" alt="" className='' />
                                </div> */}
                                <p className='support-subtitle'>View Objectives on Landing Page:</p>
                                <span className='support-description'>Objectives are visible on the Organizational Objective landing page. Status (Yet to Cascade, In Progress, Completed) is displayed on the Objective card.</span>
                                <img src="assets/images/support_document/image011.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Manage Assigned Members and weightage:</p>
                                <span className='support-description'>Click on the objective, user can manage members in Assigned Member field. </span>
                                <img src="assets/images/support_document/image013.png" alt="" className='support-img' />
                                <span className='support-description'>Click on Custom Weightage to view, manage and alter the allocated weightage. </span>
                                <img src="assets/images/support_document/image015.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Cascade Objective:</p>
                                <span className='support-description'>Click three dots on an Objective to cascade the Objective. A popup window will appear and then click Cascade.</span>
                                <img src="assets/images/support_document/image017.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Objective Cascade Confirmation:</p>
                                <span className='support-description'>After cascading, users will receive a toaster message "Objective cascaded successfully".</span>
                                <img src="assets/images/support_document/image019.png" alt="" className='support-img' />
                                <p className='support-mutetitle'>PARENT OBJECTIVE</p>
                                <p className='support-subtitle'>User Journey:</p>
                                <span className='support-description'>After logging in, users arrive at the Objective page.</span>
                                {/* <img src="assets/images/support_document/image021.png" alt="" className='support-img' /> */}
                                <p className='support-subtitle'>User's Personal Objectives:</p>
                                <span className='support-description'>In "My Objectives," each user can view their objectives neatly listed </span>
                                <p className='support-subtitle'>Creating Parent Objectives:</p>
                                <span className='support-description'>To create a new objective, users must click on "Create Parent Objective."</span>
                                <img src="assets/images/support_document/image021.png" alt="" className='support-img' />
                                <span className='support-description'>A Panel pops up where they fill in details like Objective Name, description, visibility, cycle, assigned members, individual weightage, and remarks. Click 'Save' when done.</span>
                                <img src="assets/images/support_document/image023.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Cascading Parent Objective:</p>
                                <span className='support-description'>After clicking on Cascade, a pop-up message will appear seeking confirmation to cascade the Objective. Once confirmed, the system will initiate the cascade process for the selected Objective.</span>
                                <img src="assets/images/support_document/image025.png" alt="" className='support-img' />
                                <span className='support-description'>Following the cascade, a pop-up message will appear confirming the successful cascading of the Objective.</span>
                                <img src="assets/images/support_document/image027.png" alt="" className='support-img' />
                                <span className='support-description'>Witness the differentiation of parent and child objective on the landing page.</span>
                                <img src="assets/images/support_document/image029.png" alt="" className='support-img' />
                                <span className='support-description'>The saved objective appears on the landing page with its status - either yet to cascade, in progress, or completed.</span>
                                <img src="assets/images/support_document/image031.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Easy Edits and Deletions:</p>
                                <span className='support-description'>To edit or delete users can click on the three dots (hamburger menu) next to an objective.</span>
                                <img src="assets/images/support_document/image033.png" alt="" className='support-img' />
                                <span className='support-description'>To revoke the objective, the user can access Revoke option. After revoking, the user will get a pop up for confirmation to revoke or cancel. </span>
                                <img src="assets/images/support_document/image035.png" alt="" className='support-img' />
                                <span className='support-description'>After revoking, users will receive a toaster message "Objective revoked”.</span>
                                <img src="assets/images/support_document/image037.png" alt="" className='support-img' />
                                {/* <p className='support-mutetitle'>CREATING CHILD OBJECTIVE:</p> */}
                                <p className='support-subtitle'>Create Child Objective:</p>
                                <span className='support-description'>Once the parent objectives are created, it will be shown on the landing page as Preview cards. To create a child objective, click on the ‘’Create Child Objective" positioned at the bottom right of the Objective preview card.</span>
                                <img src="assets/images/support_document/image039.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Child Objective Panel and provide information needed:</p>
                                <span className='support-description'>Open the child objective panel by clicking the mentioned option. Provide information such as Objective name, Description, Visibility, Cycle (quarter details), Assign members, Individual weightage, and Remarks. Click "Save" to create Child Objective.</span>
                                <img src="assets/images/support_document/image041.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Cascading Child Objective:</p>
                                <span className='support-description'>A pop-up message will appear seeking confirmation to cascade the Objective. Once confirmed, the system will initiate the cascade process for the selected Objective.</span>
                                <img src="assets/images/support_document/image043.png" alt="" className='support-img' />
                                <span className='support-description'>Following the cascade, a toaster message will appear confirming the successful cascading of the Objective</span>
                                <p className='support-subtitle'>View Child Objective Card:</p>
                                <span className='support-description'>Once saved, toaster message as ‘Child Objective created successfully’. Child objective will appear next to Parent objective with a differentiation.</span>
                                <img src="assets/images/support_document/image045.png" alt="" className='support-img' />
                                <p className='support-mutetitle'>VIEWING PARENT AND CHILD OBJECTIVE</p>
                                <span className='support-description'>To view a Parent Objective, click on the respective Objective card.</span>
                                <p className='support-subtitle'>Parent Objective Details</p>
                                <span className='support-description'>Obtain information on Objective name, Description, quarter cycle, creator, assigned members,and remarks.</span>
                                <img src="assets/images/support_document/image047.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Child Objective Details</p>
                                <span className='support-description'>Click on a preview card to view complete details.</span>
                                <img src="assets/images/support_document/image049.png" alt="" className='support-img' />
                                <p className='support-title'>Key Result</p>
                                <p className='support-mutetitle'>CREATING KEY RESULTS</p>
                                <p className='support-subtitle'>Accessing and Adding Key Results</p>
                                <span className='support-description'>Users can access key results from ‘My Objective’ landing page. To add key results for a specific objective.</span>
                                <img src="assets/images/support_document/image051.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Key Result Panel</p>
                                <span className='support-description'>Upon clicking, a panel will open, requiring users to select the Objective, and fill key result title, description, assigned members, weightage, Unit of Measurement (UOM), Target for each assignee, and Remarks.</span>
                                <img src="assets/images/support_document/image053.png" alt="" className='support-img' />
                                <p className='support-subtitle'>UOM Selection:</p>
                                <span className='support-description'>In the UOM dropdown, users can choose from options like percentage, currency, logic, and numeric based on their convenience.</span>
                                <img src="assets/images/support_document/image055.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Saving and Viewing Key Results:</p>
                                <span className='support-description'>After entering details, save the key results to confirm the inputs. Successfully saved key results will be visible in the dedicated "Key Result" column.</span>
                                <img src="assets/images/support_document/image053.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Edit or delete key results</p>
                                <span className='support-description'>The user can edit or delete the key results by clicking on the hamburger menu in the key result panel.</span>
                                <img src="assets/images/support_document/image058.png" alt="" className='support-img' />
                                <span className='support-description'>The user will receive a pop-up message to confirm the Deletion.</span>
                                <img src="assets/images/support_document/image060.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Custom weightage</p>
                                <span className='support-description'>The user can custom the weightage of key results he wants to assign to the members.</span>
                                <img src="assets/images/support_document/image062.png" alt="" className='support-img' />
                                <p className='support-mutetitle'>CREATING LEAD MEASURES</p>
                                <p className='support-subtitle'>Adding Lead Measures</p>
                                <span className='support-description'>Users can create lead measures on the Objective landing page within the key result column. To add a lead measure, click on the "Add Lead Measure" button.</span>
                                <img src="assets/images/support_document/image064.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Lead Measure Panel</p>
                                <span className='support-description'>A panel will appear, prompting users to enter details such as key result name and lead measure. After entering the required information, click "Submit" to save the lead measure.</span>
                                <img src="assets/images/support_document/image066.png" alt="" className='support-img' />
                                <p className='support-subtitle'>View Lead measure:</p>
                                <span className='support-description'>The lead measure saved can be viewed in ‘My Objective’ landing page.</span>
                                <img src="assets/images/support_document/image068.png" alt="" className='support-img' />
                                <p className='support-mutetitle'>MEMBERS - MY TEAM</p>
                                <p className='support-subtitle'>Accessing Members</p>
                                <span className='support-description'>Users can locate the "Members" tab adjacent to the Objective tab on the landing page. Users can switch between "My Team" and "Works with Me" by toggle button.</span>
                                <img src="assets/images/support_document/image070.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Active Groups</p>
                                <span className='support-description'>All active groups are visible on the left side, with the option for users to create additional groups.</span>
                                <img src="assets/images/support_document/image072.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Managing Team Members</p>
                                <span className='support-description'>Clicking on "My Team" allows users to view and manage all team members in the objective.</span>
                                <img src="assets/images/support_document/image074.png" alt="" className='support-img' />
                                <p className='support-subtitle'>Search Functionality and Quarter Access:</p>
                                <span className='support-description'>In the upper right column, users can search for member details. Users can select one among four quarters from the drop down by clicking the designated button.</span>
                                <p className='support-subtitle'>MEMBERS - WORKS WITH ME </p>
                                <span className='support-description'>Users can view the members with whom the Objective is assigned by clicking on it.</span>
                                <img src="assets/images/support_document/image076.png" alt="" className='support-img' />
                                <p className='support-mutetitle'>ACCESS AND FUNCTIONALITIES</p>
                                <p className='support-subtitle'>Business Head:</p>
                                <span className='support-description'><b>Access: </b>Business Heads have comprehensive access to all functionalities within GoalSync, enabling them to oversee and steer the organization's strategic direction effectively. </span>
                                <p className='support-subtitle'>Functionalities:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Define overarching organizational objectives aligned with the vision and mission of the organization. </li>
                                    <li className='support-description'>Cascade objectives down to functional heads and managers, ensuring alignment with the overall business strategy. </li>
                                    <li className='support-description'>Review objectives and key results set by functional heads and managers. </li>
                                </ul>    
                                <p className='support-subtitle'>Functional Heads:</p>
                                <span className='support-description'><b>Access: </b>Functional Heads have elevated access rights to create, manage, and monitor objectives within their respective departments or functional areas. </span>
                                <p className='support-subtitle'>Functionalities: </p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Define objectives aligned with organizational goals, translating high-level objectives into actionable tasks. </li>
                                    <li className='support-description'>Cascade objectives down to managers and team members, ensuring alignment and accountability at all levels.</li>
                                    <li className='support-description'>Monitor progress towards objectives and key results, providing guidance and support to managers and teams as needed.</li>
                                    <li className='support-description'>Review objectives set by managers, adjusting as necessary to align with changing priorities or business needs. </li>
                                </ul>
                                <p className='support-subtitle'>Others:</p>
                                <span className='support-description'>Under the "Others" category, Managers/Team Leads, Team Members, and Human Resource Business Partners encompass. </span>
                                <p className='support-subtitle'>Managers/Team Leads:</p>
                                <span className='support-description'><b>Access: </b>Managers have access to functionalities that enable them to set, monitor, and support team objectives and key results.</span>
                                <p className='support-subtitle'>Functionalities:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Set team objectives aligned with departmental and organizational goals, breaking down larger objectives into actionable tasks</li>
                                    <li className='support-description'>Assign key results and lead measures to team members, monitoring progress and providing feedback and support as needed. </li>
                                    <li className='support-description'>Collaborate with other managers and functional heads to ensure alignment and synergy across teams and departments.</li>
                                    <li className='support-description'>Review and approve objectives set by team members, ensuring alignment with team and departmental goals. </li>
                                </ul>
                                <p className='support-subtitle'>Team Members/Individual Contributors:</p>
                                <span className='support-description'><b>Access: </b>Team Members have access to functionalities that allow them to set personal objectives, update progress, and collaborate with team members. </span>
                                <p className='support-subtitle'>Functionalities:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>Set personal objectives aligned with team and organizational goals, defining key results and lead measures to track progress.</li>
                                    <li className='support-description'>Update progress on assigned key results and lead measures, providing regular updates to managers and team members.</li>
                                    <li className='support-description'>Collaborate with team members on shared objectives, sharing insights and resources to drive collective success. </li>
                                    <li className='support-description'>Participate in performance reviews and feedback sessions to evaluate progress and identify areas for growth and development. </li>
                                </ul>
                                <p className='support-subtitle'>Human Resource Business Partner:</p>
                                <span className='support-description'><b>Access: </b>Limited to Creating Objectives and Key Results.</span>
                                <p className='support-subtitle'>Functionalities:</p>
                                <ul className='support-ul'>
                                    <li className='support-description'>HRBPs can create objectives aligned with HR initiatives and organizational goals.</li>
                                    <li className='support-description'>They can define key results to measure the success and progress of HR-related objectives. </li>
                                    <li className='support-description'>HRBPs can assign the created objectives and key results to relevant stakeholders within the organization. </li>
                                    <li className='support-description'>They have the authority to distribute responsibilities and align them with individuals or teams capable of driving HR initiatives forward.</li>
                                    <li className='support-description'>Unlike other roles within GoalSync, HRBPs do not have access to view any other details beyond creating objectives and key results.</li>
                                </ul>
                                <p className='support-subtitle'>FAQs:</p>
                                <p className='support-subtitle'>What is weightage?</p>
                                <span className='support-description'>The weightage given to an individual for a particular objective is determined by their capacity to contribute effectively towards achieving it. For example: If an objective is assigned to an employee, the weightage should be allotted based on his/her overall weightage which should be within 100%.</span>
                                <p className='support-subtitle'>What is Target?</p>
                                <span className='support-description'>Targets are usually 100% in total, where the individuals are assigned within that 100% for a particular objective. For example: If a group of people are assigned the same objective, each of their targets for that objective will sum up to 100%.</span>
                                <p className='support-subtitle'>What is UOM (unit of measurement)?</p>
                                <span className='support-description'>The term "unit of measurement" in Goal Sync refers to the specific way you quantify progress towards an objective. It defines the scale or standard used to express the value of your chosen metric.</span>
                                <ul>
                                    <li className='support-description'><b>Yet to Cascade: </b>The objective or key result is yet to be assigned.</li>
                                    <li className='support-description'><b>In Progress: </b>Currently underway, active work is being done.</li>
                                    <li className='support-description'><b>Completed: </b>The assigned Objective or key result is completed successfully.</li>
                                </ul>
                                <p className='support-subtitle'>Cascade flow  </p>
                                <img src="assets/images/support_document/image078.png" alt="" className='support-img' />
                                <span className='support-description'>The flow explains that the organizational objectives are created by the Business leaders/functional heads from where the objectives flow as parent objective, child objective, and key results according to the hierarchy. Here the lead measures are the self-check points that an individual creates for themselves to achieve the key results.</span>
                            </section>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </>
    )
};

export default Support;
