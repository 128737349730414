import React, { useState, useEffect } from "react";
import { Tabs, Col, Row, Card, Avatar } from "antd";
import MyTeam from "../Members/MyTeam";
import WorkWithMe from "../Members/WorkWithMe";
import axios from "axios";
import { BaseUrl } from "../../../App";
import overallCheck from "../../../overallCheck";

const { TabPane } = Tabs;
interface User {
  id: number;
  name: string;
  profileImagePath: string;
  position: string;
  team: string;
  count: number;
}
interface Members {
  // id: number;
  // name: string;
  // profileImagePath: string;
  // position: string;
  // team: string;
  // reportImagePath: string;
  // weightage: string;
  name: string,
  employeeid: string,
  divisonName?: string,
  weightage?: number,
  id: string,
  // name: string,
  // profileImagePath: string,
  profilePath: string,
  position?: string,
  team?: string,
  reportImagePath: string,
  // weightage: string,

  username?: string,
  email?: string,
  supervisor_id?: string,
  rolename?: string,
}

const userData: User[] = [
  {
    id: 1,
    name: "John Doe",
    profileImagePath: "assets/images/user.svg",
    position: "Software Engineer",
    team: "Development",
    count: 10,
  },
  {
    id: 2,
    name: "Jane Smith",
    profileImagePath: "/path/to/profile2.jpg",
    position: "UI/UX Designer",
    team: "Design",
    count: 8,
  },
];
const memberObjData: Members[] = [

];
interface OptionItem {
  value: string;
  label: string;
  image: string;
}


const Members: React.FC = () => {

  const [isLoading, setIsLoading] = useState(true);

  const [Memoptions, setMemOptions] = useState<OptionItem[]>([]);
  const onChange = (key: string) => {
       
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      //const users = response.data; // Assuming response.data is an array of user objects
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string }[] = response.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH:' ? 'FH' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: "" // Assuming you have an image property for each user
        };
      });
      setMemOptions(newOptions);
      setIsLoading(false);
      // setMemOptions((prevMemOptions) => [
      //   ...prevMemOptions,
      //   { value: response.data.id, label: response.data.name, image: "" },
      // ]);

    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };
  // =================Roles And Permission==============================
const [permission_groupview, setPermission_groupview] = useState('');
// =================Roles And Permission==============================
const GetUserAccess = async () => {
  try {
    const role = localStorage.getItem('rolename');
    const userid = localStorage.getItem('username');
    const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
    const data = response.data;
    const rolePermissionData = JSON.parse(data[0].rolepermission);
    rolePermissionData.map((permission:any)=>{
      if(permission.menu==="View"){
          setPermission_groupview(permission.group)
      }
    })
    // setRolePermissions(rolePermissionData);
  } catch (error) {
    // console.error('Error fetching data:', error);
  }
};
const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
    setIntervalId(id);
    userslist();
    GetUserAccess();
    return () => clearInterval(id);
  }, [])
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)
           })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }


  //const onChange = (key: string) => { };
  const [users, setUsers] = useState<User[]>(userData);
  const [memObj, setMemObj] = useState<Members[]>(memberObjData);
  // const [users, setUsers] = useState<User[]>([]);

  // useEffect(() => {
  //   fetch("api/users")
  //     .then((response) => response.json())
  //     .then((data) => setUsers(data))
  //     .catch((error) => console.error("Error fetching user data:", error));
  // }, []);

  return (
    <div className="member_tab">
      {isLoading ? (
        <p></p>
      ) : (
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          className="objectivetabs-container sub_tab"
        >
          {Memoptions.length > 0 && permission_groupview &&
            <TabPane
              tab={
                <div>
                  <img src="assets/images/member_people.svg" alt="" />
                  <span>My Team</span>
                </div>
              }
              key="1"
            >
              <div>
                <MyTeam usersData={users} />
              </div>
            </TabPane>}
          <TabPane
            tab={
              <div>
                <img src="assets/images/memberprofile.svg" alt="" />
                <span>Works with me</span>
              </div>
            }
            key="2"
          >
            <WorkWithMe memberObjData={memObj} />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default Members;
