
import React, { useState, ChangeEvent, useTransition } from "react";
import { useEffect } from "react";
import { BaseUrl, FileUrl } from "../../../../App";
import axios from "axios";
import {
  Dropdown,
  Space,
  Modal,
  message,
  Flex,
  Avatar,
  Tooltip,
  MenuProps,
  Row,
  Col,
  Card,
  Input,
  Drawer,
  Select,
  Tag,
  Form,
  Table,
  Radio,
  Progress,
  Pagination,
  Descriptions,
  Menu,
  Spin,
  Checkbox,
  Divider,
  Button,
} from "antd";
import { StopOutlined, CheckOutlined, DownOutlined } from "@ant-design/icons";


import {
  AntDesignOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import AddObj from "../../Modal/AddObj";
import ReviewCascade from "../../Modal/ReviewCascade";
import CreateParentObj from "../../Modal/CreateParentObj";
import EditCreateParent from "../../Modal/EditParentObj";
import AddKR from "../../Modal/AddKR";
import LeadMessure from "../../Modal/LeadMeasure";
import KRDescription from "../../Modal/KRDescription";
import EditParentObj from "../../Modal/EditParentObj";
import Conversation from "../../Modal/Conversation";
import AssignObj from "../../Modal/AssignObj";
import { Tabs } from 'antd';
import MyObjWeightage from "./MyObjWeightage";
import ReviewKRWeightage from "./ReviewKRWeightage";
import EditObj from "../../Modal/EditObj";
import { truncate } from "fs/promises";
import ReviewALLKRWeightage from "./ReviewCascadeAllKr";
import { start } from "repl";
import { set, string } from "zod";
import { log } from "console";

const { TabPane } = Tabs;
const { Option } = Select;

const percent = 70;

interface CustomPaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  // imagePath: string;
  krId: string;
  leadMeasure: LeadMeasure[];
  createdByName: string,
}
interface checkincurrentdate {
  checkintype: string,
  checkinrange: string,
}
interface status {
  status: boolean
}
interface ImagePaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  // imagePath: string;
  krId: string;
  leadMeasure: LeadMeasure[];
}
interface LeadMeasure {
  id: number;
  krId: string;
  objId: string;
  orgObjId: string;
  description: string;
  createdByName: string;
  createdByProfilePath: string;
}

interface ContentData {
  id: number,

}

interface KeyResult {
  id: number;
  krId: string;
  objId: string;
  orgObjId: string;
  description: string;
  keytitile: string;
}

interface WeekOption {
  label: string;
  range: string;
  value: string;
  krId?: string;
}

interface CustomSelectProps {
  onChange: (value: string) => void;
  // selectedOption: WeekOption;
  selectedOption: string;
  krId: string;
}

interface createCheckIn {
  objectiveId: string | undefined;
  keyResultId: string | undefined;
  userId: string | undefined;
  target: number;
  checkInWeek: string,
  checkWeekCount: string,
  checkInStatus: number,
  achievedTarget: number;
  remainingTarget: number;
  uomType: string;
  amountType: string;
  completedTarget: number;
  customTarget: number;
}

const rolename = localStorage.getItem("rolename");
const CustomPagination: React.FC<CustomPaginationProps> = ({
  current,
  total,
  onChange,
  leadMeasure,
  krId,
  createdByName,
}) => {
  const filteredContentData = leadMeasure.filter(data => data.krId === krId);
  const currentItem = filteredContentData[current - 1];
  const displayText = (
    <span>
      <strong>{current}</strong> / {Math.ceil(total / 1)}
    </span>
  );
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const displayPicture = (
    <div className="d-flex align-center">
      <img
        src={profilePhoto}
        className="avatar"
        onError={handleImageError}
      />
      <span className="user_name mx-1">{createdByName}</span>
    </div>
  );


  return (
    <div className="custom-pagination mt-2">
      <div className="pagination-head d-flex justify-between mb-2">
        <div className="profile">
          <div className="d-flex align-center">
            <img
              src={currentItem.createdByProfilePath ? FileUrl + currentItem.createdByProfilePath : "assets/images/Default_user.svg"}
              className="avatar" onError={handleImageError}
            />
            <span className="user_name mx-1">{currentItem.createdByName}</span>
          </div>
        </div>
        <div className="text-end mt-1">
          <button
            className="next_pre_btn"
            disabled={current === 1}
            onClick={() => onChange(current - 1)}
            style={{ marginRight: "8px", color: "#fff" }}
          >
            <LeftOutlined />
          </button>
          <span>{displayText}</span>
          <button
            className="next_pre_btn"
            disabled={current === Math.ceil(total / 1)}
            onClick={() => onChange(current + 1)}
            style={{ marginLeft: "8px", color: "#fff" }}
          >
            <RightOutlined />
          </button>

        </div>
      </div>
      {/* <h4>Description</h4> */}
      <div className="pagination-content">
        <span>{currentItem?.description}</span>
      </div>
    </div>

  );
};
const handleImageError = (e: any) => {
  const target = e.target as HTMLImageElement;
  target.onerror = null;
  target.src = 'assets/images/Default_user.svg';
};
const ImagePagination: React.FC<ImagePaginationProps> = ({ leadMeasure, current, total, krId, onChange }) => {

  const filteredContentData = leadMeasure.filter(data => data.krId === krId);

  const currentItem = filteredContentData[current - 1];
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const displayImage = (
    <div className="d-flex align-center">
      <img
        src={currentItem.createdByProfilePath ? FileUrl + currentItem.createdByProfilePath : "assets/images/Default_user.svg"}
        className="avatar" onError={handleImageError}
      />
      <span className="user_name mx-1">{currentItem.createdByName}</span>
    </div>

  );


  return (
    <div className="image-pagination w-100">
      <div className="pagination-head mb-2">
        <div className="text-end d-flex">
          <button
            className="next_pre_btn"
            disabled={current === 1}
            onClick={() => onChange(current - 1)}
            style={{ marginRight: "8px", color: "#fff" }}
          >
            <LeftOutlined />
          </button>
          <span>{displayImage}</span>
          <button
            className="next_pre_btn"
            disabled={current === Math.ceil(total / 1)}
            onClick={() => onChange(current + 1)}
            style={{ marginLeft: "8px", color: "#fff" }}
          >
            <RightOutlined />
          </button>
          {/* <Pagination
            current={current}
            total={total}
            pageSize={1}
            onChange={onChange}
            showSizeChanger={false}
          /> */}
        </div>
      </div>
      {/* <h4>Description</h4> */}
      <div className="pagination-content">
        <Card>{currentItem?.description}</Card>
      </div>
    </div>

  );
};
interface reloadObj {
  reloadObj: boolean;
  Quarter: string;
}
const MyObjective: React.FC<reloadObj> = ({ reloadObj, Quarter }) => {
  const [leadopen, leadsetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [leadOpen, setLeadOpen] = useState(false);
  const [selectedKrId, setselectedKrId] = useState<string | null>(null);
  const [showkrModal, setShowKrModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isApi1Done, setIsApi1Done] = useState(false);
  const [isApi2Done, setIsApi2Done] = useState(false);
  const [memberActive, setMemberActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('Select The Week');
  interface SelectedOptionTeam {
    [kr: string]: string;
  }
  const [selectedOptionTeam, setSelectedOptionTeam] = useState<SelectedOptionTeam>({});

  interface SelectedOptionself {
    [kr: string]: string;
  }

  const [selectedOptionSelf, setSelectedOptionSelf] = useState<SelectedOptionself>({});

  const onChange = (key: string) => {
    setSelectedOption(key);
  };

  // =================Roles And Permission==============================
  const [permission_childobjview, setPermission_childobjview] = useState('');
  const [permission_parentobjview, setPermission_parentobjview] = useState('');
  const [permission_childobjedit, setPermission_childobjedit] = useState('');
  const [permission_objedit, setPermission_objedit] = useState('');
  const [permission_objdelete, setPermission_objdelete] = useState('');
  const [permission_childobjdelete, setPermission_childobjdelete] = useState('');
  const [permission_kredit, setPermission_kredit] = useState('');
  const [permission_krdelete, setPermission_krdelete] = useState('');
  const [permission_krview, setPermission_krview] = useState('');
  const [permission_lmview, setPermission_lmview] = useState('');
  const [permission_lmcreate, setPermission_lmcreate] = useState('');
  const [permission_lmedit, setPermission_lmedit] = useState('');
  const [permission_lmdelete, setPermission_lmdelete] = useState('');
  // =================Roles And Permission==============================


  const [selectedKrValue, setSelectedKrValue] = useState<string>('All Key Results');
  const [checkin, setCheckin] = useState("");
  const [userCheckInWeek, setUserCheckInWeek] = useState<string>('');

  const handleKrChange = (value: string, objectiveid: string, userid: string) => {
    setSelectedKrValue(value);
    if (value == "All Key Results") {
      getallkeyresults(objectiveid, userid);
      getteamkeyresult(objectiveid, localStorage.getItem("username"));
    }
    else if (value == "My Key Results") {
      getassignedkeyforobjectives(objectiveid, userid);
    }
    else {
      getteamkeyresult(objectiveid, localStorage.getItem("username"));
    }
  };



  const handleKrshow = () => {
    setShowKrModal(!showkrModal);

  };

  const handlekrClose = () => {
    setShowKrModal(false);
  };

  const showDrawer = () => {
    leadsetOpen(true);
  };

  type krdescription = {
    objname: string,
    objid: string,
    krid: string,
    krtitle: string
  }
  const [keyresultid, setkeyresultids] = useState<krdescription>({
    objname: "",
    objid: "",
    krid: "",
    krtitle: ""
  }
  );
  const [isPending, startTransition] = useTransition();
  const krshowDrawer = (objective: any) => {
    setOpen(true)
    setkeyresultids({
      ...keyresultid,
      krid: objective.id,
      krtitle: objective.keytitle,
      objname: "",
      objid: ""
    })

  };
  const setKrValues = (e: any, objective: any) => {
    e.preventDefault();
    setkeyresultids({
      ...keyresultid,
      krid: objective.id,
      krtitle: objective.keytitle,
      objname: "",
      objid: objective.objectiveid
    })

  };
  const leadshowDrawer = (assignedId: string) => {

    setselectedKrId(assignedId.toString()); // Ensure assignedId is always a string
    setLeadOpen(true);
  };

  const [selectedKeyResult, setSelectedKeyResult] = useState({
    id: "",
    objectiveid: "",
    orgobjectiveid: "",
    keytitle: "",
    keyquarter: "",
  });


  const setKeyResult = (keyResult: any) => {
    setSelectedKeyResult(keyResult);
  }


  const onClose = () => {
    setOpen(false);
  };
  const onLeadClose = () => {
    setShow(!show)
    setLeadOpen(false);
  };

  const handleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    if (showCustomWeightage.some(user => user.avaiableWeightage == 0) && text == "UnRevoke") {
      Modal.error({
        content: (
          <>
            <p className="fs-16 mb-3">{objectiveAndUser?.objname}</p>
            <p className="fs-18"><b>Note : </b>Users Available Weightage Is Zero</p>
          </>
        ),
        centered: true,
      })
    }
    else {
      Modal.confirm({
        title: "Do you want to " + text + " this objective ?",
        content: (
          <>
            <p className="fs-16 mb-3">
              {objectiveAndUser?.objname}
            </p>
            <p className="fs-14">
              <b>Note : </b> {text} objective will lead to delete all
              corresponding objectives & key results permanently.
            </p>
          </>
        ),
        okText: text,
        cancelText: "Cancel",
        okButtonProps: { className: "C_btn" },
        cancelButtonProps: { className: "outline_btn" },
        centered: true,
        async onOk() {
          try {
            const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
              "objectiveid": objectiveAndUser?.objid,
              "status": revertstatus,
              "orgObjectiveId": objectiveAndUser?.orgobjid,
              "masterobjid": objectiveAndUser?.objectiveid,
              "userId": localStorage.getItem("loginId"),
              headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
            });
            if (response.status == 200) {
              if (revertstatus == 0) {
                message.success({
                  content: (
                    <div className="success-message justify-center align-center p-1">
                      <img src="assets/images/check-circle.svg" alt="Success Icon" />
                      <span className="c_32C6A0 fs-16">

                        Objective UnRevoked
                      </span>
                    </div>
                  ),
                  duration: 1,
                  icon: null,
                });
              } else {
                message.success({
                  content: (
                    <div className="success-message justify-center align-center p-1">
                      <img src="assets/images/check-circle.svg" alt="Success Icon" />
                      <span className="c_32C6A0 fs-16">

                        Objective Revoked
                      </span>
                    </div>
                  ),
                  duration: 1,
                  icon: null,
                });

              }

              setTimeout(() => {
                objectivelist();
                // window.location.reload();
              }
                , 1000);
            } else {
              message.error("Revert failed");
            }
          } catch (error) {
            message.error("An error occurred");
          }
        },
      });
    }

  };
  const handleRevertorg = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {orgobjectiveAndUser?.objectiveName}
          </p>
          <p className="fs-14">
            <b>Note : </b> {text} objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/organization/status/orgobjrevoke`, {
            "objectiveid": "",
            "status": revertstatus,
            "orgObjectiveId": orgobjectiveAndUser?.id,
            "masterobjid": "",
            "userId": localStorage.getItem('loginId'),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });

            }
            Orgobjectivelist()
            // objectivelist();
            setTimeout(() => {
              // window.location.reload();
            }
              , 2000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          message.error("An error occurred");
        }
      },
    });
  };

  const childHandleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {childObjectiveAndUser?.obj_name}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
            "objectiveid": childObjectiveAndUser?.obj_id,
            "status": revertstatus,
            "orgObjectiveId": childObjectiveAndUser?.orgObjId,
            "masterobjid": childObjectiveAndUser?.objectiveId,
            "userId": localStorage.getItem("loginId"),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });

            }
            setTimeout(() => {
              objectivelist();
            }
              , 1000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          message.error("An error occurred");
        }
      },
    });
  };

  const subchildHandleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {childOrgObjectiveAndUser?.obj_name}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
            "objectiveid": childOrgObjectiveAndUser?.obj_id,
            "status": revertstatus,
            "orgObjectiveId": childOrgObjectiveAndUser?.orgObjId,
            "masterobjid": childOrgObjectiveAndUser?.objectiveId,
            "userId": localStorage.getItem("loginId"),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });

            }
            setTimeout(() => {
              objectivelist();
            }
              , 1000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          message.error("An error occurred");
        }
      },
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Do you want to delete this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {objectiveAndUser?.objname}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteParentObjective();
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      },

      onCancel() {
      },
    });
  };

  const [objectiveId, setObjectiveId] = useState<string | null>(null);
  const [objectivestaus, setobjectivestatus] = useState<string | null>((null));

  interface ObjectiveUser {
    objname: string;
    objcode: string;
    createdbyname: string;
    status: String;
  }
  type cftstatus = {
    cftstatus: number
  }
  type objectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectiveId: "",
    orgObjId: "",
    organizationStatus: string,
    objectivename: "",
    status: string,
    createdby: "",
    objectiveassignedusers: assigndetails[],
    createdbyusername: string,
    createdByProfilePath: string,
    revertstatus: number | null,
    objsubid: string,
    cftstatus: string,
    year: string,
    keyResultcount: number,
    signoffstatus: number,
  }
  type subobjectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectiveId: "",
    orgObjId: "",
    organizationStatus: string,
    objectivename: "",
    status: string,
    createdby: "",
    objectivesuser_list: assigndetails[],
    createdbyusername: string,
    createdByProfilePath: string,
    revokedstatusorgobj: number | null,
    revokestatus: number,
    objsubid: string,
    year: string,
    keyResultcount: number,
    cftstatus: number,
    signoffstatus: number,
    parentid: string
  }
  type objectiveuserkey = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectivename: ""
    status: string,
    objectiveassignedusers: string[],
    year: "",
    revertstatus: number | null,
    objtype: "",
    signoffstatus: number,
  }
  type objcetiveuselist = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    organizationStatus: string
    mapped_obj: objectiveuser[],
    mapped_obj_count: string,
    objid: string,
    assignedorgobj: string[]
  }
  type objcetiveuselists = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    organizationStatus: string
    mapped_obj: objectiveuser[],
    mapped_obj_count: number,
    objid: string,
    description: string,
    objectivesassignedusers: assigndetails[],
    createdby: string,
    createdByProfilePath: string,
    cftstatus: number,
    year: string;
    revertstatus: number | null,
    parentrevokestatus: number | null,
    objectiveid: string | null,
    parentid: string | null,
    restrictDelete: boolean,
    revokedorgobjstatus: number | undefined,
    revokedbyuserid: string,
    keyResultcount: number,
    signoffstatus: number,
    startdate: string,
    enddate: string,
  }
  interface orgobjectivelists {
    id: string;
    objectCode: string;
    objectiveName: string;
    description: string;
    createdBy: string;
    assignedCount: number;
    quarter: string,
    year: string,
    startdate: string,
    enddate: string,
    status: number;
    organizationStatus: string;
    createdByUserName: string;
    createdByUserProfilePath: string;
    restrictDelete: boolean;
    orgObjectiveUsersList: assigndetails[];
    revokestatus: number;
    objectiveslist: subobjectiveuser[];
    signoffstatus: number;
  }
  type assignedorgobjectivelist = {
    availableweightage: number,
    weightage: number,
    id: string,
    orgObjectiveId: string,
    userId: string,
    objectiveName: string,
    createdByUserName: string,
    createdByProfilePath: string,
    profilePath: string,
    name: string
  }
  type assigndetails = {
    name: string,
    cftstatus: number
    profilePath: string
    userid: string,
    weightage: number,
    availableweightage: number,
    signOffStatus: number,
  }
  type assigndetailssub = {
    name: string,
    cftstatus: number
    profilePath: string
    userId: string,
    weightage: number,
    availableweightage: number
  }

  interface UserDetails {
    "id": string | null;
    "name": string;
    "username": string;
    "avaiableWeightage": number;
    "index": string | null;
    "profilePath": string;
    "assignWeightage": number;
  }
  interface customWeightage {
    "id": string,
    "name": string,
    "username": string,
    "avaiableWeightage": number,
    "assignWeightage": number,
    "cftstatus": number,
    "profilePath": string,
  }
  interface ObjData {
    primaryId: string;
    objectiveId: string;
    objectiveName: string;
    objectiveDescription: string;
    weightage: string;
    organizationStatus: string;
  }
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const loginUserId = localStorage.getItem('loginId');
  const [Orgobjectiveuserslist, setorgobjectiveuserlist] = useState<orgobjectivelists[]>([]);
  const [Orgobjectiveuserslistloader, setorgobjectiveuserlistloader] = useState<orgobjectivelists[]>([]);
  const [loader, setstatus] = useState<boolean>(false);
  const [objectiveAndUser, setObjectiveAndUser] = useState<objcetiveuselists>();
  const [orgobjectiveAndUser, setorgobjectiveanduser] = useState<orgobjectivelists>();
  const [childObjectiveAndUser, setChildObjectiveAndUser] = useState<objectiveuser>();
  const [childOrgObjectiveAndUser, setChildOrgObjectiveAndUser] = useState<subobjectiveuser>();
  const [showReviewObjective, setShowReviewObjective] = useState<ObjData[]>([
    {
      primaryId: "",
      objectiveId: "",
      objectiveName: "",
      objectiveDescription: "",
      weightage: "",
      organizationStatus: "",
    }
  ]);
  const [objectiveuserslist, setobjectivesuserlist] = useState<objcetiveuselists[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      quartervalue: "",
      orgobjid: "",
      mapped_obj: [],
      mapped_obj_count: 0,
      objid: "",
      organizationStatus: "",
      description: "",
      objectivesassignedusers: [],
      createdby: "",
      createdByProfilePath: "",
      cftstatus: 0,
      year: "",
      revertstatus: null,
      parentrevokestatus: null,
      objectiveid: null,
      parentid: null,
      restrictDelete: false,
      revokedorgobjstatus: undefined,
      revokedbyuserid: "",
      keyResultcount: 0,
      signoffstatus: 0,
      startdate: "",
      enddate: "",
    }

  ]);
  const [objectiveuserslistload, setobjectivesuserlistload] = useState<objcetiveuselists[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      quartervalue: "",
      orgobjid: "",
      mapped_obj: [],
      mapped_obj_count: 0,
      objid: "",
      organizationStatus: "",
      description: "",
      objectivesassignedusers: [],
      createdby: "",
      createdByProfilePath: "",
      cftstatus: 0,
      year: "",
      revertstatus: null,
      parentrevokestatus: null,
      objectiveid: null,
      parentid: null,
      restrictDelete: false,
      revokedorgobjstatus: undefined,
      revokedbyuserid: "",
      keyResultcount: 0,
      signoffstatus: 0,
      startdate: "",
      enddate: "",
    }

  ]);
  const [permission_orgobjedit, setPermission_orgobjedit] = useState('');
  const [permission_orgobjview, setPermission_orgobjview] = useState('');
  const [permission_orgobjdelete, setPermission_orgobjdelete] = useState('');
  const GetUserAccessorg = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_orgobjview(permission.org_objective);
        }
        if (permission.menu === "Edit") {
          setPermission_orgobjedit(permission.org_objective);
        }
        if (permission.menu === "Delete") {
          setPermission_orgobjdelete(permission.org_objective);
        }
      })
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const [orgLoadingcount, setorgLoadingcount] = useState(0);
  const [objLoadingCount, setobjLoadingCount] = useState(0);

  const handleCheckInChange = (
    value: string,
    maxTarget: number
  ) => {
    console.log(value);
    const inputValue = value;
    const numericValue = Number(inputValue);

    // Ensure input is numeric and within the range
    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= maxTarget) {
      setCheckin(value);
    }

  }

  const [checkinCreate, setCheckinCreate] = useState<createCheckIn>();


  const checkincreate = async (krId: string, ObjId: string, userId: string, selectedFrequencyWeek: WeekOption) => {
    const apiUrl = BaseUrl + "api/checkin/create";
    const MySelfCheckIn: createCheckIn = {
      objectiveId: ObjId,
      keyResultId: krId,
      userId: localStorage.getItem('loginId') || '',
      target: parseInt(checkin),
      checkInWeek: selectedFrequencyWeek.value,
      checkWeekCount: selectedFrequencyWeek.range,
      customTarget: 0,
      checkInStatus: 1,
      achievedTarget: 0,
      remainingTarget: 0,
      uomType: '',
      amountType: '',
      completedTarget: 0,
      frequencyType: "",
    }
    try {
      const response = await axios.post(apiUrl, MySelfCheckIn, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(response.data.data);
      const addedCheckinCreate: createCheckIn = response.data.data;
      setCheckin("");
      krCheckInFrequncyView(userId, ObjId);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (isApi1Done && isApi2Done) {
      setLoading(false);
    }
  }, [isApi1Done, isApi2Done]);

  const[orgLength, setOrgLength] = useState<number>(0);
  const Orgobjectivelist = async () => {
    const token = localStorage.accessToken;
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;
    if (userObject) {
      useruniqueid = localStorage.loginId;
    } else {
      console.error("User information not found in localStorage");
    }
    setorgobjectiveuserlist([]);
    const formData = new FormData();
    formData.append("userid", useruniqueid);
    try {
      const response = await axios.get(
        BaseUrl + `api/organization/review/getallforfh/${localStorage.Quarter}/${localStorage.year}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      if (response.status == 200) {
        setstatus(true);
        setorgLoadingcount(1);
        setIsApi1Done(true);
      }
      setorgobjectiveuserlist((prevList) => [
        ...(response.data.data as any[]).map((item: any) => {
          isObjCheckedorg.push(false)

          const sub_obj = (item?.objectiveslist as any[])?.map((detailItem: any) => ({
            obj_name: detailItem?.objectivename,
            obj_code: detailItem?.name,
            obj_desc: detailItem?.description,
            obj_quarter: detailItem?.quarter,
            obj_id: detailItem?.id,
            year: detailItem?.year,
            startdate: detailItem?.startDate,
            enddate: detailItem?.endDate,
            objectiveId: detailItem?.objectiveid,
            orgObjId: detailItem?.orgobjectiveid,
            organizationStatus: detailItem?.organizationStatus,
            createdby: detailItem?.createdBy,
            revokestatus: detailItem?.revokestatus,
            objectivesuser_list: detailItem?.objectivesuser_list,
            createdbyusername: detailItem?.createdbyusername,
            createdByProfilePath: detailItem?.createdByProfilePath,
            objectivename: detailItem?.objectivename,
            status: detailItem?.organizationStatus,
            serprofile: detailItem?.userprofile,
            revokedstatusorgobj: detailItem?.revokedstatusorgobj,
            objsubid: detailItem?.objsubid,
            keyResultcount: detailItem?.keyResultcount,
            cftstatus: detailItem?.cftstatus,
            signoffstatus: detailItem?.signoffstatus,
            parentid: detailItem?.parentid
            // Add other properties as needed
          })) || [];
          const mapped_obj_users = (item?.orgObjectiveUsersList as any[])?.map((objusers: any) => ({
            name: objusers.name,
            userid: objusers.userId,
            cftstatus: 0,
            profilePath: objusers.profilePath,
            weightage: objusers.weightage,
            availableweightage: objusers.availableweightage,
            signOffStatus: objusers.signOffStatus
          })) || [];
          return {
            id: item.id,
            objectCode: item.objectCode,
            objectiveName: item.objectiveName,
            description: item.description,
            createdBy: item.createdBy,
            assignedCount: item.assignedCount,
            status: item.status,
            quarter: item.quarter,
            year: item.year,
            startdate: item.startDate,
            enddate: item.endDate,
            organizationStatus: item.organizationStatus,
            createdByUserName: item.createdByUserName,
            createdByUserProfilePath: item.createdByUserProfilePath,
            restrictDelete: item.restrictDelete,
            orgObjectiveUsersList: mapped_obj_users,
            revokestatus: item.revokestatus,
            objectiveslist: sub_obj,
            signoffstatus: item.signoffstatus,
          };
        }),
      ]);
      setOrgLength(Orgobjectiveuserslist.length);
      console.log(response.data.data.length, "response.data.data.length");
      var orgLength = response.data.data.length;
      var orgSubLength = response.data.data.map((item : orgobjectivelists) => item.objectiveslist.filter((subObj : subobjectiveuser) => subObj.cftstatus === 0).length).reduce((sum : number, length : number) => sum + length, 0);
      console.log(orgSubLength, "response.data.data.length");
      orgLength += orgSubLength;
      localStorage.setItem("orgLength", orgLength);

      
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const objectivelist = async () => {
    const token = localStorage.accessToken;
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;
    if (userObject) {
      useruniqueid = localStorage.loginId;
    } else {
    }
    setobjectivesuserlist([]);

    const formData = new FormData();
    formData.append("userid", useruniqueid);
    try {
      const response = await axios.get(
        BaseUrl + `api/objectives/list/objectivesusers/${localStorage.Quarter}/${localStorage.year}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.accessToken}`
          },
        }
      );
      // Visibility.push(false)

      setSelectedFieldsArray((prevList) => [
        ...(response.data as any[]).map((item: any) => {
          return item.id;

        })
      ]);
      if (response.status == 200) {
        setobjLoadingCount(1);
        setLoading(false);
        setIsApi2Done(true);
      }
      const objlist: objcetiveuselists[] = response.data.filter((data: objcetiveuselists) => data.parentid == null)
      const subobj = (response.data as any[])?.filter(data => data.parentid != null).map((detailItem: any) => ({
        obj_name: detailItem?.objectivename,
        obj_code: detailItem?.name,
        obj_desc: detailItem?.description,
        obj_quarter: detailItem?.quarter,
        obj_id: detailItem?.id,
        year: detailItem?.year,
        objectiveId: detailItem?.objectiveid,
        orgObjId: detailItem?.orgobjectiveid,
        organizationStatus: detailItem?.organizationStatus,
        createdby: detailItem?.createdBy,
        createdbyusername: detailItem?.createdbyusername,
        createdByProfilePath: detailItem?.createdByProfilePath,
        objectivename: detailItem?.objectivename,
        status: detailItem?.organizationStatus,
        objectiveassignedusers: (detailItem?.objectivesuser_list as any[])?.map((assgineduserlist: any) => ({
          cftstatus: assgineduserlist.cftstatus,
          name: assgineduserlist.name,
          profilePath: assgineduserlist.profilePath,
          userid: assgineduserlist.userId,
          weightage: assgineduserlist.weightage,
          availableweightage: assgineduserlist.availableweightage,
          signOffStatus: assgineduserlist.signOffStatus
        })),
        revertstatus: detailItem?.revokestatus,
        cftstatus: detailItem?.cftstatus,
        objsubid: detailItem?.objsubid,
        keyResultcount: detailItem?.keyResultcount,
        signoffstatus: detailItem?.signoffstatus,
        parentid: detailItem?.parentid
      }));
      const objwithoutparent = response.data.filter((data: any) => {
        return data.parentid !== null && !subobj.some((userdata) => userdata.parentid === data.objid);
      });
      setobjectivesuserlist((prevList) => [
        ...(response.data as objcetiveuselists[]).map((item: any) => {
          Visibility.push(false);
          subObj.push(false);
          isObjChecked.push(false)
          const mapped_obj = (item?.objdetails as any[])?.map((detailItem: any) => ({
            obj_name: detailItem?.objectivename,
            obj_code: detailItem?.name,
            obj_desc: detailItem?.description,
            obj_quarter: detailItem?.quarter,
            obj_id: detailItem?.id,
            year: detailItem?.year,
            objectiveId: detailItem?.objectiveid,
            orgObjId: detailItem?.orgobjectiveid,
            organizationStatus: detailItem?.organizationStatus,
            createdby: detailItem?.createdBy,
            createdbyusername: detailItem?.createdbyusername,
            createdByProfilePath: detailItem?.createdByProfilePath,
            objectivename: detailItem?.objectivename,
            status: detailItem?.organizationStatus,
            objectiveassignedusers: (detailItem?.objectivesuser_list as any[])?.map((assgineduserlist: any) => ({
              cftstatus: assgineduserlist.cftstatus,
              name: assgineduserlist.name,
              profilePath: assgineduserlist.profilePath,
              userid: assgineduserlist.userId,
              weightage: assgineduserlist.weightage,
              availableweightage: assgineduserlist.availableweightage,
              signOffStatus: assgineduserlist.signOffStatus
            })),
            revertstatus: detailItem?.revokestatus,
            cftstatus: detailItem?.cftstatus,
            objsubid: detailItem?.objsubid,
            keyResultcount: detailItem?.keyResultcount,
            signoffstatus: detailItem?.signoffstatus,
          })) || [];
          const mapped_obj_users = (item?.objectivesuser_list as any[])?.map((objusers: any) => ({
            name: objusers.name,
            cftstatus: objusers.cftstatus,
            userid: objusers.userId,
            profilePath: objusers.profilePath,
            weightage: objusers.weightage,
            availableweightage: objusers.availableweightage,
            signOffStatus: objusers.signOffStatus
          })) || [];
          return {
            objname: item?.objectivename,
            objcode: item?.name,
            createdbyname: item?.createdbyusername,
            quartervalue: item?.quarter,
            orgobjid: item?.orgobjectiveid,
            mapped_obj_count: item?.objcount,
            mapped_obj: mapped_obj,
            objid: item.id,
            organizationStatus: item?.organizationStatus,
            description: item?.description,
            createdby: item?.createdBy,
            createdByProfilePath: item?.createdByProfilePath,
            objectivesassignedusers: mapped_obj_users,
            objectiveid: item?.objectiveid,
            cftstatus: item?.cftstatus,
            year: item?.year,
            startdate: item?.startdate,
            enddate: item?.enddate,
            revertstatus: item?.revokestatus,
            parentrevokestatus: item?.parentrevokestatus,
            parentid: item?.parentid,
            restrictDelete: item?.restrictDelete,
            revokedorgobjstatus: item?.revokedstatusorgobj,
            revokedbyuserid: item?.revokedby,
            keyResultcount: item?.keyResultcount,
            signoffstatus: item?.signoffstatus,
          };
        }),
        // ...(objwithoutparent as objcetiveuselists[]).map((item: any) => {
        //   // console.log("org_response", response.data)
        //   Visibility.push(false);
        //   subObj.push(false);
        //   isObjChecked.push(false);
        //   // const mapped_obj = (item?.objdetails as any[])?.map((detailItem: any) => ({
        //   //   // Extract the specific values needed from each detailItem
        //   //   obj_name: detailItem?.objectivename,
        //   //   obj_code: detailItem?.name,
        //   //   obj_desc: detailItem?.description,
        //   //   obj_quarter: detailItem?.quarter,
        //   //   obj_id: detailItem?.id,
        //   //   year: detailItem?.year,
        //   //   objectiveId: detailItem?.objectiveid,
        //   //   orgObjId: detailItem?.orgobjectiveid,
        //   //   organizationStatus: detailItem?.organizationStatus,
        //   //   createdby: detailItem?.createdBy,
        //   //   createdbyusername: detailItem?.createdbyusername,
        //   //   createdByProfilePath: detailItem?.createdByProfilePath,
        //   //   objectivename: detailItem?.objectivename,
        //   //   status: detailItem?.organizationStatus,
        //   //   objectiveassignedusers: (detailItem?.objectivesuser_list as any[])?.map((assgineduserlist: any) => ({
        //   //     cftstatus: assgineduserlist.cftstatus,
        //   //     name: assgineduserlist.name,
        //   //     profilePath: assgineduserlist.profilePath,
        //   //     userid: assgineduserlist.userId,
        //   //     weightage: assgineduserlist.weightage,
        //   //     availableweightage: assgineduserlist.availableweightage,
        //   //     signOffStatus: assgineduserlist.signOffStatus
        //   //   })),
        //   //   // userprofile: detailItem?.userprofile,
        //   //   revertstatus: detailItem?.revokestatus,
        //   //   cftstatus: detailItem?.cftstatus,
        //   //   objsubid: detailItem?.objsubid,
        //   //   keyResultcount: detailItem?.keyResultcount,
        //   //   signoffstatus: detailItem?.signoffstatus,
        //   //   // Add other properties as needed
        //   // })) || [];
        //   const mapped_obj_users = (item?.objectivesuser_list as any[])?.map((objusers: any) => ({
        //     name: objusers.name,
        //     cftstatus: objusers.cftstatus,
        //     userid: objusers.userId,
        //     profilePath: objusers.profilePath,
        //     weightage: objusers.weightage,
        //     availableweightage: objusers.availableweightage,
        //     signOffStatus: objusers.signOffStatus
        //   })) || [];
        //   return {
        //     objname: item?.objectivename,
        //     objcode: item?.name,
        //     createdbyname: item?.createdbyusername,
        //     quartervalue: item?.quarter,
        //     orgobjid: item?.orgobjectiveid,
        //     mapped_obj_count: subobj.filter(subobj=>item?.id==subobj.parentid).length,
        //     mapped_obj: subobj.filter(subobj=>item?.id==subobj.parentid),
        //     objid: item.id,
        //     organizationStatus: item?.organizationStatus,
        //     description: item?.description,
        //     createdby: item?.createdBy,
        //     createdByProfilePath: item?.createdByProfilePath,
        //     objectivesassignedusers: mapped_obj_users,
        //     objectiveid: item?.objectiveid,
        //     // userprofile: item?.userprofile,
        //     cftstatus: item?.cftstatus,
        //     year: item?.year,
        //     revertstatus: item?.revokestatus,
        //     parentrevokestatus: item?.parentrevokestatus,
        //     parentid: item?.parentid,
        //     restrictDelete: item?.restrictDelete,
        //     revokedorgobjstatus: item?.revokedstatusorgobj,
        //     revokedbyuserid: item?.revokedby,
        //     keyResultcount: item?.keyResultcount,
        //     signoffstatus: item?.signoffstatus,
        //   };
        // }),

      ]);

      localStorage.setItem("objLength" , response.data.length.toString());
      console.log("objLength" , response.data.length.toString());
       
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const showReviewAndCascadeObjectives = async () => {

    const apiUrl = BaseUrl + `api/reviewcascade/view/objective`;

    axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
    ).then((response) => {
      setShowReviewObjective(response.data.data);
    })
  }

  const [objectivetype, setobjectivetype] = useState<string>("");
  const [objectiveassignedusersid, setobjectiveassignedusersdetails] = useState<string[]>();
  const [assignuserweightage, setassigneduserweightage] = useState<UserDetails[]>([]);
  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);
  const selectedmemdetails = async (userid: string[]) => {

    try {
      const updatedata = {
        userlist: userid,
        objective: objectiveAndUser?.objid,
        obj_type: "obj"
      }
      const userdetails = await axios.post(BaseUrl + "api/users/edit_objective_users", updatedata,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      const modifiedData: UserDetails[] =
        userdetails.data.map((item: UserDetails) => {
          const displayWeightage = {
            id: item.id,
            name: item.name,
            username: item.username,
            avaiableWeightage: item.avaiableWeightage,
            index: "",
            profilePath: item.profilePath,
            assignWeightage: item.assignWeightage,
            weightage: 0,
          }
        });
      setassigneduserweightage(modifiedData)
    } catch (e) {
    }

  }
  const userOrganizationList = async (userid: string[]) => {
    try {
      const apiUrl = BaseUrl + `api/users/edit_objective_users`;
      const userObjectIdList: string[] = [];
      const updatedata = {
        userlist: userid,
        objective: objectiveId,
        obj_type: "obj"
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {

          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const customWeights = {
              id: res.id,
              name: res.name,
              username: res.name,
              avaiableWeightage: res.avaiableWeightage,
              assignWeightage: parseInt(res.assignWeightage),
              cftstatus: parseInt(res.cftstatus),
              profilePath: res.profilePath,
            }
            addedCustomWeightage.push(customWeights);
          })
          setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
    }
  }
  const items: MenuProps["items"] = [
    objectiveAndUser?.revertstatus !== 1 && permission_objedit ?
      {
        label: (
          objectiveAndUser?.createdby === localStorage.loginId ?
            (
              <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" objectivecode="" objectivetype={objectivetype} type={true} assigneesmembers={objectiveAndUser?.createdby} />) :
            (<AssignObj
              objectivelist={objectivelist}
              objectcode={objectiveAndUser?.objcode == undefined ? "" : objectiveAndUser?.objcode}
              objectname={objectiveAndUser?.objname == undefined ? "" : objectiveAndUser?.objname}
              orgQuartervalue={objectiveAndUser?.quartervalue == undefined ? "" : objectiveAndUser?.quartervalue}
              orgobjid={objectiveAndUser?.orgobjid == undefined ? "" : objectiveAndUser?.orgobjid}
              objid={objectiveAndUser?.objid == undefined ? "" : objectiveAndUser?.objid}
              objective_id={objectiveAndUser?.objectiveid == undefined ? "" : objectiveAndUser?.objectiveid}
              year={objectiveAndUser?.year == undefined ? "" : objectiveAndUser?.year}
              startdate={objectiveAndUser?.startdate == undefined ? "" : objectiveAndUser?.startdate}
              enddate={objectiveAndUser?.enddate == undefined ? "" : objectiveAndUser?.enddate}
              orgdescription={objectiveAndUser?.year == undefined ? "" : objectiveAndUser?.description}
              objectivetype={"objective"}
              button="Edit"
              header="Assign Objective"
            />)
        ),
        key: "0",
      } : null,
    objectiveAndUser?.revertstatus !== 1 && objectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { handleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    objectiveAndUser?.revertstatus == 1 && objectiveAndUser?.organizationStatus != "1" ?
      {
        label: (
          <Row onClick={() => { handleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    objectiveAndUser?.restrictDelete === false && permission_objdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      }
      : null
  ];
  const childItems = [
    childObjectiveAndUser?.revertstatus !== 1 && permission_childobjedit ?
      {
        label: (
          // <button className="border-0 bg-transparent fs-14 align-center">
          <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" objectivecode="" type={true} objectivetype={objectivetype} assigneesmembers={childObjectiveAndUser?.createdby} />
          // </button>
        ),
        key: "0",
      } : null,
    childObjectiveAndUser?.revertstatus !== 1 && childObjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { childHandleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    childObjectiveAndUser?.revertstatus == 1 ?
      {
        label: (
          <Row onClick={() => { childHandleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // } : {
    //   label: (
    //     <Row onClick={() => { handleRevert(1) }} className="p-0">
    //       <StopOutlined className="mr-1" />
    //       Revoke
    //     </Row>
    //   ),
    //   key: "2",
    // },
    childObjectiveAndUser?.organizationStatus === "1" && permission_childobjdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      } : null
  ];


  const orgitems = [
    // selectedOrganizationObjective?.createdbyname === user ?
    permission_orgobjedit ? {

      label: (
        orgobjectiveAndUser?.createdBy == localStorage.loginId ?
          (
            <EditObj orgObjList={Orgobjectivelist} itemId={objectiveId} objectiveName="" orgobjectivecode="" />) :
          (<AssignObj
            objectivelist={Orgobjectivelist}
            objectcode={orgobjectiveAndUser?.objectCode == undefined ? "" : orgobjectiveAndUser?.objectCode}
            objectname={orgobjectiveAndUser?.objectiveName == undefined ? "" : orgobjectiveAndUser?.objectiveName}
            orgQuartervalue={orgobjectiveAndUser?.quarter == undefined ? "" : orgobjectiveAndUser?.quarter}
            orgobjid={orgobjectiveAndUser?.id == undefined ? "" : orgobjectiveAndUser?.id}
            objid={""}
            objective_id={""}
            year={orgobjectiveAndUser?.year == undefined ? "" : orgobjectiveAndUser?.year}
            startdate={orgobjectiveAndUser?.startdate == undefined ? "" : orgobjectiveAndUser?.startdate}
            enddate={orgobjectiveAndUser?.enddate == undefined ? "" : orgobjectiveAndUser?.enddate}
            orgdescription={orgobjectiveAndUser?.year == undefined ? "" : orgobjectiveAndUser?.description}
            objectivetype={"orgobjective"}
            button="Edit"
            header="Assign Objective"
          />)
      ),
      key: "0",
    } : null,
    orgobjectiveAndUser?.revokestatus !== 1 && orgobjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { handleRevertorg(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    orgobjectiveAndUser?.revokestatus == 1 && orgobjectiveAndUser?.organizationStatus != "1" ?
      {
        label: (
          <Row onClick={() => { handleRevertorg(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // selectedOrganizationObjective?.organizationStatus === "1" ? 
    permission_orgobjdelete ? {
      label: (
        <Row
          className="c_FF4747 p-0"
          onClick={handleDelete}
        // disabled={disableDelete}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: "1",
    } : null
    // : null
  ];
  const childOrgItems = [
    childOrgObjectiveAndUser?.revokedstatusorgobj !== 1 && permission_childobjedit ?
      {
        label: (
          // <button className="border-0 bg-transparent fs-14 align-center">
          <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" objectivecode="" type={true} objectivetype={objectivetype} assigneesmembers={childOrgObjectiveAndUser?.createdby} />
          // </button>
        ),
        key: "0",
      } : null,
    childOrgObjectiveAndUser?.revokedstatusorgobj !== 1 && childOrgObjectiveAndUser?.revokestatus !== 1 && childOrgObjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { subchildHandleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    childOrgObjectiveAndUser?.revokedstatusorgobj == 1 || childOrgObjectiveAndUser?.revokestatus == 1 ?
      {
        label: (
          <Row onClick={() => { subchildHandleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // } : {
    //   label: (
    //     <Row onClick={() => { handleRevert(1) }} className="p-0">
    //       <StopOutlined className="mr-1" />
    //       Revoke
    //     </Row>
    //   ),
    //   key: "2",
    // },
    childOrgObjectiveAndUser?.organizationStatus === "1" && permission_childobjdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      } : null
  ];



  const childMenu = (
    <Menu>
      {childItems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );

  const orgobjmenu = (
    <Menu>
      {orgitems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );

  const childOrgMenu = (
    <Menu>
      {childOrgItems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );


  const [selectedFieldsArray, setSelectedFieldsArray] = useState<any[]>([]);
  const allOrganizationAssignedUser = async () => {

    // if(localStorage.getItem("rolename") === "FH") {
    const apiUrl = BaseUrl + `api/organization/objective/users/${localStorage.getItem("loginId")}`;
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
    })

    // } else {
    //   console.log("ERROR :::::::::::::::::::::::::", 'Enter the valid Lognin');

    // }
  }

  const deleteParentObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/delete/${objectiveId}`;

      await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        objectivelist();
        // window.location.reload();
        //console.log(response.data);
      })
    } catch (error) {

    }
  }
  type editkeyresults = {
    keytitle: string,
    description: string,
    remarks: string,
    quarter: string,
    createdbyempname: string,
    target: string,
    Uom: string,
    amounttype: string
  }
  type editkeyresultusers = {
    id: string,
    username: string,
    Assigned: number,
    target: number,
    Available: number | string,

  }

  type keyResultUsers = {
    availableweightage: number;
    createdAt: string;
    id: string;
    individualweightage: number;
    keyid: string;
    keyuid: string;
    profilePath: string | null;
    status: number;
    target: number;
    updateAt: string | null;
    userempid: string | null;
    userid: string;
    username: string;
    signOffStatus: number;
    totaltarget: number;
    targetgiven:number;
  };
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);

  const [Visibility, setvisibility] = useState<boolean[]>([]);

  const toggleVisibility = (index: number) => {
    setIsVisible((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }
  const [objectivekeys, setobjectiveskey] = useState<objectiveuserkey>(

    {
      obj_name: "",
      obj_code: "",
      obj_desc: "",
      obj_quarter: "",
      obj_id: "",
      objectivename: "",
      status: "",
      objectiveassignedusers: [],
      year: "",
      revertstatus: null,
      objtype: "",
      signoffstatus: 0,
    }

  );
  type kr = {
    id: string,
    objectiveid: string,
    keytitle: string,
    description: string,
    remarks: string,
    target: string,
    amounttype: string,
    uom: string,
    status: number,
    keyid: string,
    objectivecode: string,
    quarter: string,
    orgobjectiveid: string,
    objectivename: string,
    keyResultUsersListAssignedTo: keyResultUsers[],
    createdbyempname: string,
    createdByEmpProfilePath: string,
    keyresultweightage: number,
    deletedstatus: number,
    deletedtime: String,
    updatedAt: string
    deleteddate: string,
    createdBy: string,
    signOffStatus: number;
    checkInFrequency: string;
    frequencyList: WeekOption[];
    checkInValue: number;
    currentmonth: string;
    currentweekiteration: string;
    currentweek: string;
  }

  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };
  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  })

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Lead Measure");
      setMaxWords(maxWords.data);
    } catch (error) {
    }
  };
  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_childobjview(permission.child_objective)
          setPermission_parentobjview(permission.parent_objective)
          setPermission_lmview(permission.lead_measures);
          setPermission_krview(permission.key_result);
          setPermission_lmview(permission.lead_measures);
        }
        if (permission.menu === "Edit") {
          setPermission_objedit(permission.parent_objective);
          setPermission_childobjedit(permission.child_objective);
          setPermission_kredit(permission.key_result);
          setPermission_lmedit(permission.lead_measures);
        }
        if (permission.menu === "Delete") {
          setPermission_objdelete(permission.parent_objective);
          setPermission_childobjdelete(permission.child_objective);
          setPermission_krdelete(permission.key_result);
          setPermission_lmdelete(permission.lead_measures);
        }
        if (permission.menu === "Create") {
          setPermission_lmcreate(permission.lead_measures);
        }

      })
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const krCheckInFrequnency = async (frequency: string) => {

    try {
      let today: Date = new Date();

      const formData = new FormData();
      formData.append("currentMonth", today.getMonth().toString());
      formData.append("year", today.getFullYear().toString());
      formData.append("frequency", frequency);


      const apiUrl = BaseUrl + `api/checkin/date/list`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        console.log(response.data.data);
        const krCheckInList: WeekOption[] = [];
        response.data.data.map((krCheckIn: string, index: number) => {
          const krCheckInUser: WeekOption = {
            label: "Week " + (index + 1),
            range: krCheckIn,
            value: (index + 1) + '/' + response.data.data.length,
          }
          krCheckInList.push(krCheckInUser);
          console.log(krCheckInUser);

        })

        setWeekOptions(krCheckInList);
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetUserAccess();
    GetUserAccessorg();
    getWordCount();
    userLists();
    Orgobjectivelist();
    objectivelist();
    // krCheckInFrequnency("Monthly");
    // showReviewAndCascadeObjectives();
    // allOrganizationAssignedUser();
  }, []);

  useEffect(() => {
   // cascadeAllObjectives();
    //userRequestSignOffStatus();
    if (reloadObj) {
      // alert(12)
      setReviewCascade(true);
    }
  }, [reloadObj]);

  interface User {
    id: string;
    name: string;
    email: string;
    profilePath: string;
    signoffStatus: number;
    designation: string;
    username: string;
  }

  interface createCheckIn {
    objectiveId: string | undefined;
    keyResultId: string | undefined;
    userId: string | undefined;
    target: number;
    checkInWeek: string,
    checkWeekCount: string,
    checkInStatus: number | 0,
    achievedTarget: number | 0;
    remainingTarget: number | 0;
    uomType: string;
    amountType: string;
    completedTarget: number | 0;
    customTarget: number;
    frequencyType: string;
  }

  interface TMSelectedOption {
    [krid: string]: string;
  }

  const [TMoptions, setTMoptions] = useState<User[]>([]);
  const [TMselectedOption, setTMSelectedOption] = useState<TMSelectedOption>({});
  const [userFrequencyListTeam, setUserFrequencyListTeam] = useState<createCheckIn[]>([]);

  const TMUserLists = async () => {
    try {
      const response = await axios.get(BaseUrl + `api/users/userListBySupervisor/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTMoptions(response.data);
      setTMSelectedOption(response.data[0].id);
    } catch (err) {
      console.error("Error fetching user list:", err);
    }
  };



  useEffect(() => {
    LeadMeasureData();
    TMUserLists();
  }, [selectedOption,keyresultid]);


  const checkInTeamList = async (userId: string, krid: string | undefined) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("krId", krid || '');

      const apiUrl = BaseUrl + `api/checkin/kr/list`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setUserFrequencyListTeam(response.data.data);
      })
    } catch (error) {
      console.log(error);
    }
  }
  const [objectivesassignedkr, setobjectiveassignedkr] = useState<kr[]>([]);
  const [objectivesteamdkr, setobjectiveteamedkr] = useState<kr[]>([]);
  const [keystatus, setkeystatus] = useState<number | null>(null);
  const [orgIndex, setOrgIndex] = useState<number | null>(null);
  const cascadeallkeyresults = async () => {  
    const keyids: string[] = []
    objectivesassignedkr.forEach((keyvals) => {
      if (keyvals.status != 3) {
        keyids.push(keyvals.id);
      }
    })

    Modal.confirm({
      title: 'Do You Want TO Cascade All The  KeyResults ?',
      content: (
        <>
          <p className="fs-14"><b>Note : </b>Cascading All The Key Results TO The Corresponding Objective</p>
        </>
      ),
      okText: "Cascade All",
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      async onOk() {
        onClose();
        cascadeallkeyresultsrobjectves();
      },
      onCancel() {
      },
    });
  }
  const [keycard, setKeyCard] = useState<objcetiveuselists>();
  const [subOrgkeycard, setSubOrgKeyCard] = useState<subobjectiveuser>();
  const [subObjkeycard, setSubObjKeyCard] = useState<objectiveuser>();
  const [keystatusIndex, setkeystatusIndex] = useState<number | undefined>(undefined);
  const [orgClass, setorgClass] = useState<string>('');
  const [objClass, setobjClass] = useState<string>('');
  const [mainorgClass, setmainorgClass] = useState<string>('');
  const [mainobjClass, setmainobjClass] = useState<string>('');

  const [krforobj, setobjforjkr] = useState<boolean>(false)
  const [objBgActive, setObjBgActive] = useState(false);

  const objectivesdetailsgets = (objectives: objcetiveuselists | undefined, index: number | undefined) => {
    setObjBgActive(!objBgActive);
    setOrgIndex(null);
    setobjClass('');
    setorgClass('');
    setmainorgClass('');
    setmainobjClass('mainobj-' + index);
    if (objectives !== undefined) {
      handleKrChange("All Key Results", objectives.objid, localStorage.getItem("loginId") || "");
      setKeyCard(objectives)
      setobjectiveskey((prevObjectives: any) => ({
        ...prevObjectives,
        obj_desc: objectives.description,
        obj_name: objectives.objname,
        obj_code: objectives.objcode,
        obj_quarter: objectives.quartervalue,
        obj_id: objectives.objid,
        status: objectives.organizationStatus,
        year: objectives.year,
        revertstatus: objectives.revertstatus,
        objtype: objectives.parentid == null ? "orgobjective" : "objective",
        signoffstatus: objectives.signoffstatus
      }));
      if (index !== undefined) {
        setkeystatus(keystatus === index ? null : index);
        setobjforjkr(true);
        setkeystatusIndex(index);
      }
      setobjforjkr(true)
      setOrgIndex(null);
      const objectkeyid = objectivekeys.obj_id;
     
    }
  };

  const orgobj_div = (index: number) => {
    let org = "org-" + index;
    setobjforjkr(false)
    setkeystatus(null);
    setobjectiveskey(
      {
        obj_name: "",
        obj_code: "",
        obj_desc: "",
        obj_quarter: "",
        obj_id: "",
        objectivename: "",
        status: "",
        objectiveassignedusers: [],
        year: "",
        revertstatus: null,
        objtype: "",
        signoffstatus: 0,
      }
    );
    setobjectiveassignedkr(objectivesassignedkr);
    setOrgIndex(orgIndex === index ? null : index);
    setobjClass('');
    setorgClass('');
    setmainobjClass('');
    setmainorgClass('mainorg-' + index);
  }

  const suborgobjectivesdetailsgets = (objectives: subobjectiveuser | undefined, orgobjIndex: number, subobjIndex: number) => {
    setObjBgActive(!objBgActive);
    setorgClass('org-' + orgobjIndex + '-' + subobjIndex);
    setobjClass('');
    setmainobjClass('');
    setmainorgClass('');
    if (objectives !== undefined) {
      setSubOrgKeyCard(objectives)
      setobjectiveskey((prevObjectives: any) => ({
        ...prevObjectives,
        obj_desc: objectives.obj_desc,
        obj_name: objectives.obj_name,
        obj_code: objectives.obj_code,
        obj_quarter: objectives.obj_quarter,
        obj_id: objectives.obj_id,
        status: objectives.organizationStatus,
        year: objectives.year,
        revokedstatusorgobj: objectives.revokedstatusorgobj,
        objtype: objectives.orgObjId == null ? "orgobjective" : "objective"
      }));
      const objectkeyid = objectivekeys.obj_id;
      handleKrChange("All Key Results", objectives.obj_id, localStorage.getItem("loginId") || "");
      setobjforjkr(true)
    }
  };
  const subobjectivesdetailsgets = (objectives: objectiveuser, objIndex: number, subobjIndex: number) => {
    setObjBgActive(!objBgActive);
    setSubObjKeyCard(objectives)
    setorgClass('');
    setmainobjClass('');
    setmainorgClass('');
    setobjClass('obj-' + objIndex + '-' + subobjIndex);
    setobjectiveskey((prevObjectives: any) => ({
      ...prevObjectives,
      obj_desc: objectives.obj_desc,
      obj_name: objectives.obj_name,
      obj_code: objectives.obj_code,
      obj_quarter: objectives.obj_quarter,
      obj_id: objectives.obj_id,
      status: objectives.organizationStatus,
      year: objectives.year,
      revertstatus: objectives.revertstatus,
      objtype: objectives.orgObjId == null ? "orgobjective" : "objective"
    }));
    const objectkeyid = objectivekeys.obj_name;
    handleKrChange("All Key Results", objectives.obj_id, localStorage.getItem("loginId") || "");
    setobjforjkr(true)
  };

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  const [availableweightagekr, setavailableweightage] = useState(0);
  const getassignedkeyforobjectives = async (objectives: string, userId: string | null) => {
    try {
      const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getkeyresult/${objectives}/${userId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setavailableweightage(assignedkeyresults.data.availableweightage)
      const assignedkr: kr[] = assignedkeyresults.data.data;
      const assignedkrdetails: kr[] = [];
      var currentdata: string;
      var currentiteration: string;
      var currentweeksrange: string;
      var assignedUsers: keyResultUsers[] = [];
      assignedkr.map((item: kr) => {
        assignedUsers=[];
        const updatedDate = item.updatedAt ? item.updatedAt.split('T')[0] : "";
        item.frequencyList.forEach((frequency, index) => {
          let parts = frequency.range.split(' ');
          let startDateParts = parts[0].split('-'); // ["Aug", "01"]
          let endDateParts = parts[1].split('-');
          let startMonth = startDateParts[0].toLowerCase();
          let startDay = parseInt(startDateParts[1]);
          let endMonth = endDateParts[0].toLowerCase();
          let endDay = parseInt(endDateParts[1]);
          // Assuming the format of currentmonth and currentdata are correct
          let currentDate = new Date();
          let currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
          let currentDay = currentDate.getDate();
          let startDate = new Date(`${startMonth} ${startDay}, ${currentDate.getFullYear()}`);
          let endDate = new Date(`${endMonth} ${endDay}, ${currentDate.getFullYear()}`);
          let today = new Date(`${currentMonth} ${currentDay}, ${currentDate.getFullYear()}`);
          if (today >= startDate && today <= endDate) {
            currentdata = "Week " + " " + (index + 1) + " " + frequency.range;
            currentiteration = String(index + 1) + "/" + item.frequencyList.length;
            currentweeksrange = frequency.range;
          }
        })
        console.log("dddddd" + currentdata);
       
        item.keyResultUsersListAssignedTo.forEach((user) => {
          var totaltarget = user.totaltarget;
            var target = user.target;
              if(item.uom=="Numeric"||item.uom=="Currency")
                {
                totaltarget=(user.totaltarget/user.target)* 100;
              }
          const assignedusersdata:keyResultUsers = {
              availableweightage: user.availableweightage,
              createdAt: user.createdAt,
              id: user.id,
              individualweightage: user.individualweightage,
              keyid: user.keyid,
              keyuid: user.keyuid,
              profilePath: user.profilePath,
              status: user.status,
              target: target,
              updateAt: user.updateAt,
              userempid: user.userempid,
              userid: user.userid,
              username: user.username,
              signOffStatus: user.signOffStatus,
              totaltarget: Math.round(totaltarget),
              targetgiven:user.totaltarget
          }
          assignedUsers.push(assignedusersdata);
        })
        
        const assignedkrforobj: kr = {
          id: item.id,
          objectiveid: item.objectiveid,
          keytitle: item.keytitle,
          description: item.description,
          remarks: item.remarks,
          target: item.target,
          amounttype: item.amounttype,
          uom: item.uom,
          status: item.status,
          keyid: item.keyid,
          objectivecode: item.objectivecode,
          quarter: item.quarter,
          orgobjectiveid: item.orgobjectiveid,
          objectivename: item.objectivename,
          keyResultUsersListAssignedTo: assignedUsers,
          createdbyempname: item.createdbyempname,
          createdBy: item.createdBy,
          createdByEmpProfilePath: item.createdByEmpProfilePath,
          keyresultweightage: item.keyresultweightage,
          deletedstatus: item.deletedstatus,
          deleteddate: item.deleteddate,
          updatedAt: item.updatedAt,
          deletedtime: item.deletedtime,
          signOffStatus: item.signOffStatus,
          checkInFrequency: item.checkInFrequency,
          frequencyList: item.frequencyList,
          checkInValue: item.checkInValue,
          currentmonth: currentdata,
          currentweekiteration: currentiteration,
          currentweek: currentweeksrange
        }
        
        
        const againID = assignedkrforobj.keyResultUsersListAssignedTo;
        
        const loginID = localStorage.getItem("loginId");
        const filteredUsers = againID.filter(user => user.id !== loginID);
        assignedkrdetails.push(assignedkrforobj);

      });
      setobjectiveassignedkr(assignedkrdetails);

      if (assignedkeyresults.data.length === 0) {
        setobjectiveassignedkr([])
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
      setLoading(true);
      Orgobjectivelist();
      //userRequestSignOffStatus();
      objectivelist();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [year, quarter]);
  // team keyResult API
  const [currentdata, setcurrentdate] = useState<checkincurrentdate[]>([])
  const getteamkeyresult = async (objectivesId: string, userId: string | null) => {
    try {
      const myteamkeyresults = await axios.get(`${BaseUrl}api/keyresult/getTeamkeyResults/${objectivesId}/${userId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      const checkincurrentdata: checkincurrentdate[] = [];
      // console.log(myteamkeyresults.data);
      const assignedteamkr: kr[] = myteamkeyresults.data;
      const krdetails: kr[] = [];
      var assignedUsers: keyResultUsers[] = [];
      var currentdata: string;
      var currentiteration: string;
      var currentweeksrange: string;
      assignedteamkr.map((item: kr) => {
        assignedUsers = [];
        item.frequencyList.forEach((frequency, index) => {
          let parts = frequency.range.split(' ');
          let startDateParts = parts[0].split('-'); // ["Aug", "01"]
          let endDateParts = parts[1].split('-');
          let startMonth = startDateParts[0].toLowerCase();
          let startDay = parseInt(startDateParts[1]);
          let endMonth = endDateParts[0].toLowerCase();
          let endDay = parseInt(endDateParts[1]);
          // Assuming the format of currentmonth and currentdata are correct
          let currentDate = new Date();
          let currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
          let currentDay = currentDate.getDate();
          let startDate = new Date(`${startMonth} ${startDay}, ${currentDate.getFullYear()}`);
          let endDate = new Date(`${endMonth} ${endDay}, ${currentDate.getFullYear()}`);
          let today = new Date(`${currentMonth} ${currentDay}, ${currentDate.getFullYear()}`);
          if (today >= startDate && today <= endDate) {
            currentdata = "Week " + " " + (index + 1) + " " + frequency.range
            currentiteration = String(index + 1) + "/" + item.frequencyList.length;
            currentweeksrange = frequency.range;
          }
        });
       
        item.keyResultUsersListAssignedTo.forEach((user) => {
          var totaltarget = user.totaltarget;
            var target = user.target;
              if(item.uom=="Numeric"||item.uom=="Currency")
                {
                totaltarget=(user.totaltarget/user.target)* 100;
                target=100;
              }
          const assignedusersdata:keyResultUsers = {
              availableweightage: user.availableweightage,
              createdAt: user.createdAt,
              id: user.id,
              individualweightage: user.individualweightage,
              keyid: user.keyid,
              keyuid: user.keyuid,
              profilePath: user.profilePath,
              status: user.status,
              target: target,
              updateAt: user.updateAt,
              userempid: user.userempid,
              userid: user.userid,
              username: user.username,
              signOffStatus: user.signOffStatus,
              totaltarget:  Math.round(totaltarget),
              targetgiven:user.totaltarget
          }
          assignedUsers.push(assignedusersdata);
        })
        const teamkrforobj: kr = {
          id: item.id,
          objectiveid: item.objectiveid,
          keytitle: item.keytitle,
          description: item.description,
          remarks: item.remarks,
          target: item.target,
          amounttype: item.amounttype,
          uom: item.uom,
          status: item.status,
          keyid: item.keyid,
          objectivecode: item.objectivecode,
          quarter: item.quarter,
          orgobjectiveid: item.orgobjectiveid,
          objectivename: item.objectivename,
          keyResultUsersListAssignedTo: assignedUsers,
          createdbyempname: item.createdbyempname,
          createdBy: item.createdBy,
          createdByEmpProfilePath: item.createdByEmpProfilePath,
          keyresultweightage: item.keyresultweightage,
          deletedstatus: item.deletedstatus,
          deleteddate: item.deleteddate,
          updatedAt: item.updatedAt,
          deletedtime: item.deletedtime,
          signOffStatus: item.signOffStatus,
          checkInFrequency: item.checkInFrequency,
          frequencyList: item.frequencyList,
          checkInValue: item.checkInValue,
          currentmonth: currentdata,
          currentweekiteration: currentiteration,
          currentweek: currentweeksrange
        }
        krdetails.push(teamkrforobj);
      });

      setobjectiveteamedkr(krdetails);
    } catch (error) {
      console.log(error);
    }
  }


  const getallkeyresults = async (objectives: string, userId: string | null) => {
    try {
      const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getallkeyresults/${objectives}/${userId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setavailableweightage(assignedkeyresults.data.availableweightage)
      const assignedkr: kr[] = assignedkeyresults.data.data;
      const assignedkrdetails: kr[] = [];
      var currentdata: string;
      var currentiteration: string;
      var currentweeksrange: string;
      var assignedUsers: keyResultUsers[] = [];
      assignedkr.map((item: kr) => {
        assignedUsers=[];
        const updatedDate = item.updatedAt ? item.updatedAt.split('T')[0] : "";
        item.frequencyList.forEach((frequency, index) => {
          let parts = frequency.range.split(' ');
          let startDateParts = parts[0].split('-'); // ["Aug", "01"]
          let endDateParts = parts[1].split('-');
          let startMonth = startDateParts[0].toLowerCase();
          let startDay = parseInt(startDateParts[1]);
          let endMonth = endDateParts[0].toLowerCase();
          let endDay = parseInt(endDateParts[1]);
          // Assuming the format of currentmonth and currentdata are correct
          let currentDate = new Date();
          let currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
          let currentDay = currentDate.getDate();
          let startDate = new Date(`${startMonth} ${startDay}, ${currentDate.getFullYear()}`);
          let endDate = new Date(`${endMonth} ${endDay}, ${currentDate.getFullYear()}`);
          let today = new Date(`${currentMonth} ${currentDay}, ${currentDate.getFullYear()}`);
          if (today >= startDate && today <= endDate) {
            currentdata = "Week " + " " + (index + 1) + " " + frequency.range;
            currentiteration = String(index + 1) + "/" + item.frequencyList.length;
            currentweeksrange = frequency.range;
          }
        })
        console.log("dddddd" + currentdata);
       
        item.keyResultUsersListAssignedTo.forEach((user) => {
          var totaltarget:number = user.totaltarget;
            var target:number = user.target;
              if(item.uom=="Numeric"||item.uom=="Currency")
                {
                totaltarget=Math.round((user.totaltarget/user.target)* 100);
              }
          const assignedusersdata:keyResultUsers = {
              availableweightage: user.availableweightage,
              createdAt: user.createdAt,
              id: user.id,
              individualweightage: user.individualweightage,
              keyid: user.keyid,
              keyuid: user.keyuid,
              profilePath: user.profilePath,
              status: user.status,
              target: target,
              updateAt: user.updateAt,
              userempid: user.userempid,
              userid: user.userid,
              username: user.username,
              signOffStatus: user.signOffStatus,
              totaltarget:  Math.round(totaltarget),
              targetgiven:user.totaltarget
          }
          assignedUsers.push(assignedusersdata);
        })
        
        const assignedkrforobj: kr = {
          id: item.id,
          objectiveid: item.objectiveid,
          keytitle: item.keytitle,
          description: item.description,
          remarks: item.remarks,
          target: item.target,
          amounttype: item.amounttype,
          uom: item.uom,
          status: item.status,
          keyid: item.keyid,
          objectivecode: item.objectivecode,
          quarter: item.quarter,
          orgobjectiveid: item.orgobjectiveid,
          objectivename: item.objectivename,
          keyResultUsersListAssignedTo: assignedUsers,
          createdbyempname: item.createdbyempname,
          createdBy: item.createdBy,
          createdByEmpProfilePath: item.createdByEmpProfilePath,
          keyresultweightage: item.keyresultweightage,
          deletedstatus: item.deletedstatus,
          deleteddate: item.deleteddate,
          updatedAt: item.updatedAt,
          deletedtime: item.deletedtime,
          signOffStatus: item.signOffStatus,
          checkInFrequency: item.checkInFrequency,
          frequencyList: item.frequencyList,
          checkInValue: item.checkInValue,
          currentmonth: currentdata,
          currentweekiteration: currentiteration,
          currentweek: currentweeksrange
        }
        
        
        const againID = assignedkrforobj.keyResultUsersListAssignedTo;
        
        const loginID = localStorage.getItem("loginId");
        const filteredUsers = againID.filter(user => user.id !== loginID);
        assignedkrdetails.push(assignedkrforobj);

      });
      setobjectiveassignedkr(assignedkrdetails);

      if (assignedkeyresults.data.length === 0) {
        setobjectiveassignedkr([])
      }
    } catch (error) {

    }
  }

  const toggleVisibilitys = (index: number) => {
    setvisibility((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const contentData: LeadMeasure[] = [];
  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPagePerObjective, setCurrentPagePerObjective] = useState<{ [key: string]: number }>({});

  const itemsPerPage: number = 1;
  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems = contentData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (page: number, objectiveId: string) => {
    setCurrentPagePerObjective(prevState => ({
      ...prevState,
      [objectiveId]: page
    }));
  };

  const { TextArea } = Input;
  const [show, setShow] = useState(false);

  const [leadMeasure, setLeadMeasure] = useState({
    orgObjId: "",
    krId: "",
    objId: "",
    description: ""
  });
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    // Check if the input exceeds the maximum word count
    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      setLeadMeasure({
        ...leadMeasure,
        [event.target.name]: event.target.value
      });
    }
  }
  const handleAddLead = () => {
    setShow(true);
  }
  const [krreviewstatus, setkrreviewstatus] = useState<boolean>(true);
  const [getAllLeadMeasure, setAllLeadMeasure] = useState<LeadMeasure[]>([]);
  const LeadMeasureData = async () => {
    try {
      const apiUrl = BaseUrl + `api/leadMeasure/listByCreatedBy`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setAllLeadMeasure(response.data);
          onClose();
        });
    } catch (error) {
    }
  }
  const deletekeyresult = async (kr: krdescription) => {
    const apiUrl = `${BaseUrl}api/keyresult/delete/${kr.krid}`;
    await axios.put(apiUrl, {
    }).then((response) => {
      handleKrChange(selectedKrValue,kr.objid,localStorage.loginId);
    })
  }
  const handleDeleteKR = (kr: krdescription) => {
    Modal.confirm({
      title: "Do you want to delete this keyresult ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr?.krtitle}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deletekeyresult(kr);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                KeyResult deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });

        // window.location.reload();
        onClose()
        getallkeyresults(kr.objid,localStorage.loginId);
        getteamkeyresult(kr.objid,localStorage.username)

        objectivelist()
        Orgobjectivelist()
        
      },
      onCancel() {
      },
    });
  };
  const [isreviewcascade, setReviewCascade] = useState<boolean>(() => {
    const savedReview = localStorage.getItem('review');
    return savedReview ? JSON.parse(savedReview) : true;
  });

  useEffect(() => {
    localStorage.setItem('review', JSON.stringify(isreviewcascade));
  }, [isreviewcascade]);

  const [isreviewKR, setReviewKR] = useState<boolean>(() => {
    const savedKRReview = localStorage.getItem('reviewKR');
    return savedKRReview ? JSON.parse(savedKRReview) : true;
  });

  useEffect(() => {
    localStorage.setItem('reviewKR', JSON.stringify(isreviewKR));
  }, [isreviewKR]);

  const ReviewCascade = () => {
    setReviewCascade(false)
  }

  const ReviewKRWeight = () => {
    setReviewKR(false)
  }

  const items1 = [
    {
      label: (
        // <button className="border-0 bg-transparent fs-14 align-center">
        <KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id, localStorage.loginId)} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} objectivelist={objectivelist} krid={keyresultid?.krid} krtitle={""} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode="" checkInFrequency="" uomType="" objquarter={objectivekeys.obj_quarter} tabActiveKey ="1" selectedkrvalue={selectedKrValue}/>
        // </button>
      ),
      key: "0",
    },
    {
      label: (
        <Row
          className="c_FF4747 fs-14 p-0"
          onClick={() => { handleDeleteKR(keyresultid) }}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: '1',
    },

  ];

  const menu = (
    <Menu>
      {items1.map(item => (
        (permission_kredit && item.key == '0') || (permission_krdelete && item.key == '1') ?
          (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ) : null
      ))}
    </Menu>
  );


  const cascadeallkeyresultsrobjectves = async () => {
    try {
      const apiUrl = BaseUrl + `api/reviewcascade/update/cascadeallkr/${localStorage.Quarter}/${localStorage.year}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });

      Modal.info({
        title: 'Cascade KeyResults',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{response.data.data} KeyResults Cascaded</p>
            {/* <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p> */}
          </>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
      });

    } catch (error) {
    }
  }
  const addLeadMeasure = async () => {
    try {
      const apiUrl = BaseUrl + `api/leadMeasure/create`;
      const formData = new FormData();

      const orgObjeId = document.getElementById('orgObjId') as HTMLInputElement;
      const krid = document.getElementById('krId') as HTMLInputElement;
      const objeid = document.getElementById('objId') as HTMLInputElement;
      formData.append("description", leadMeasure.description);
      formData.append("orgObjId", orgObjeId.value);
      formData.append("krId", krid.value);
      formData.append("objId", objeid.value);


      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })

      if (leadMeasure.description === "") {
        message.error({
          content: (
            <div className="success-message justify-center align-center p-1 d-flex">
              {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
              <span className="c_FF4747 fs-16">
                Please add the Lead Measure
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        setLeadOpen(true)
      } else {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                LeadMeasure Created successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        setLeadMeasure(
          {
            ...leadMeasure,
            orgObjId: "",
            krId: "",
            objId: "",
            description: ""
          }
        )
        setLeadOpen(false);
      }
      LeadMeasureData();

    } catch (error) {
    }
  }

  const cascadeStatus = (id: string, objectivename: string) => {
    Modal.confirm({
      title: 'Do you want to Cascade this objective ?',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">{objectivename}</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
        </>
      ),
      okText: 'Cascade',
      cancelText: 'Cancel',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective Created successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        try {
          const apiUrl = BaseUrl + `api/objectives/cascadeStatusUpdate/${id}`;
          axios.put(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
          });

        } catch (error) {
        }
        objectivelist();
        setTimeout(() => {
        }, 1000);
      },
      onCancel() {
      },
    });

  }

  type assignUserDetails = {
    name: string,
    username: string,
    email: string,
    designation: string,
    company_id: string,
    profilePath: string,
    objectivestatus: string,
  }

  const [userInformation, setUserInformation] = useState<assignUserDetails[]>([]);

  const userReporteeList = async (userReporteeList: assigndetails[], objectiveid: string | null, type: string) => {
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userid);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const userReporteeListSub = async (userReporteeList: any[], objectiveid: string | null, type: string) => {
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userId);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const [userKeyResultInformatiom, setUserKeyResultInformation] = useState<assignUserDetails[]>([]);

  const userKeyResultList = async (userKeyResultList: any[], objectiveid: string | null, type: string) => {
    const userKrIdList: string[] = userKeyResultList.map(reportee => reportee.userid);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userKrIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserKeyResultInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const [subObj, setSubObj] = useState<boolean[]>([]);
  const [subOrgObj, setSubOrgObj] = useState<boolean[]>([]);
  const sub_obj = (index: number) => {
    setSubObj(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    setobjforjkr(false);
  };
  const sub_orgobj = (index: number) => {
    setSubOrgObj(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    setobjforjkr(false);
  }
  const [isConfirmAccept, setConfirmAccept] = useState(true);

  // Accept checkbox status
  const [isObjChecked, setIsObjChecked] = useState<boolean[]>([]);
  const [isObjCheckedorg, setorgobjchecked] = useState<boolean[]>([]);

  const handleObjCheckbox = (index: number) => {

    setIsObjChecked((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  };
  const handleObjCheckboxorg = (index: number) => {

    setorgobjchecked((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  };

  const [isAccept, setAccept] = useState(false);

  const handleAcceptBtn = async (index: number, id: string | null, type: string) => {
    try {
      var formdata = new FormData();
      formdata.append("type", type)
      const response = axios.put(`${BaseUrl}api/objectives/Acceptobjective/${id}`, formdata, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then(response => {
        })
      objectivelist();
      Orgobjectivelist();
    }
    catch (e) {
      // console.error(e);
    }
  };
  const [memCountActive, setMemCountActive] = useState(false);
  const [layerActive, setLayerActive] = useState<boolean[]>();

  const handleVisibleChange = (visible: boolean) => {
    setMemCountActive(visible);
  };

  const memberMenu = (
    <Menu className="member_count_div">
      {userInformation.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div className="d-flex align-center">
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              {/* <span className="member_title ml-1">{capitalizeFirstLetter(user.name)}/{user.designation}/{user.company_id}</span> */}
              <span className="member_title ml-1">
                {user.name}/{user.designation}/{user.company_id}
              </span>
              {user.objectivestatus == "1" &&
                <img src="assets/images/single_tick.svg" alt="" />
              }
              {
                user.objectivestatus == "3" &&
                <img src="assets/images/tick_check.svg" alt="" />
              }
            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );

  const memberMenuKR = (
    <Menu className="member_count_div">
      {userKeyResultInformatiom.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div>
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              <span className="member_title ml-1">{user.name}/{user.designation}/{user.company_id}</span>
              {/* <img src="assets/images/tick_check.svg" alt="" /> */}
            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );
  const [restictionindates, setrestriction] = useState<Boolean>(false);

  const [saveAndCascadeUser, setSaveAndCascadeUser] = useState<number>();
  const userLists = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      setSaveAndCascadeUser(response.data.length);
    } catch (err) {
      // console.log(err)
    }
  }

  const cascadeAllObjectives = async () => {
    let orgIdList: string[] = [];
    let objIdList: string[] = [];
    Orgobjectiveuserslist.forEach((org) => {
      orgIdList.push(org.id);
      org.objectiveslist.forEach((obj) => {
        objIdList.push(obj.obj_id);
      })
    });

    // objectiveuserslist.map((obj) => {
    //   orgIdList.push(org.id);
    //   console.log(org.id);   
    //   obj.obj
    // })


    const apiUrl = BaseUrl + `api/reviewcascade/all/objectives`;


    await axios.post(apiUrl, {
      "orgIds" : orgIdList,
      "objIds" : objIdList,
    }, {
      headers :{
         Authorization: `Bearer ${localStorage.accessToken}`
      }
    }).then((response)=>{

      Orgobjectivelist();

    }).catch((error)=>{
       console.log(error)
    })
  }

  const cascademenu = (
    <Menu className="cascade_dropdown">
      <Menu.Item onClick={cascadeAllObjectives} >Cascade All Objective</Menu.Item>
      <Divider />
      <Menu.Item onClick={cascadeallkeyresults}>Cascade All Key Results</Menu.Item>
    </Menu>
  );

  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }

  const [weekOptions, setWeekOptions] = useState<WeekOption[]>([]);
  const [userIdTeam, setUserIdTeam] = useState('');
  const [selectedKrIdOption, setSelectedKrIdOption] = useState('');

  const handleselectChange = (value: string, krId: string, optionKrId: string | undefined) => {
    if (krId === optionKrId) {
      setSelectedOption(value);
    }
  };
  const handleChange = (value: string) => {
    const selected = weekOptions.find((option) => option.value === value);
    if (selected) {
      console.log("selected.range" + selected.range)
      let parts = selected.range.split(' ');
      let startDateParts = parts[0].split('-'); // ["Aug", "01"]
      let endDateParts = parts[1].split('-');

      let startMonth = startDateParts[0].toLowerCase();
      let startDay = parseInt(startDateParts[1]);
      let endMonth = endDateParts[0].toLowerCase();
      let endDay = parseInt(endDateParts[1]);

      // Assuming the format of currentmonth and currentdata are correct
      let currentDate = new Date();
      let currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
      let currentDay = currentDate.getDate();

      let startDate = new Date(`${startMonth} ${startDay}, ${currentDate.getFullYear()}`);
      let endDate = new Date(`${endMonth} ${endDay}, ${currentDate.getFullYear()}`);
      let today = new Date(`${currentMonth} ${currentDay}, ${currentDate.getFullYear()}`);
      if (today >= startDate && today <= endDate) {
        setrestriction(true);
      } else {
        setrestriction(false);
      }


      // if(month==currentmonth.toLowerCase()||month2==currentmonth.toLocaleLowerCase()){
      //   // console.log("oksodjfoisdjf")
      //   let intdate1 = parseInt(date1);
      //   let intdate2 = parseInt(date2);
      //   if(currentdata>=intdate1&&currentdata<=intdate2){
      //     setrestriction(true);
      //   }
      //   else{
      //     setrestriction(false);
      //   }
      // }
    }
  }
  const handleSelectChangeSelf = (value: string, objectiveId: string, primaryObjId: string) => {
    setSelectedOptionSelf((prevSelectedOptionTeam) => ({
      ...prevSelectedOptionTeam,
      [objectiveId]: value,
    }));
    handleChange(value);
    setSelectedKrIdOption(objectiveId);
    krCheckInFrequncyView(localStorage.getItem("loginId") || "", primaryObjId);
  };

  const handleSelectChangeTeam = (value: string, objectiveId: string, primaryObjId: string) => {
    setSelectedOptionTeam((prevSelectedOptionTeam) => ({
      ...prevSelectedOptionTeam,
      [objectiveId]: value,
    }));
    console.log(objectiveId);
    setSelectedKrIdOption(objectiveId);
    // krCheckInFrequncyView(userIdTeam, primaryObjId);
    // krViewTeamCheckInList(userIdTeam, primaryObjId);
  };
  // checkin kr card  

  const [checkInCard, setCheckInCard] = useState<boolean>(false);

  const handleCheckinSubmit = (krId: string, ObjId: string, userId: string, weekCount: string, frequencyList: WeekOption[]) => {
    setCheckInCard(!checkInCard)
    const selectedFrequencyWeek: WeekOption | undefined = frequencyList.find((week) => week.value === weekCount);
    checkincreate(krId, ObjId, userId, selectedFrequencyWeek!);
    window.location.reload();
    // checkInList(krId, weekRange );

  }
  const [userFrequencyList, setUserFrequencyList] = useState<createCheckIn[]>([]);
  const [teamMemberFrequencyList, setTeamMemberFrequencyList] = useState<createCheckIn>();
  const [selfUserFrequency, setSelfUserFrequency] = useState<number>(0);
  const [weekWiseCheckinValue, setWeekWiseCheckinValue] = useState<createCheckIn | undefined>();
  const checkInList = async (krid: string | undefined, weekRangeCount: string) => {
    try {
      const formData = new FormData();
      formData.append("userId", localStorage.getItem("loginId") || '');
      formData.append("krId", krid || '');
      formData.append("weekCount", weekRangeCount);

      const apiUrl = BaseUrl + `api/checkin/assign/user/kr`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setSelfUserFrequency(response.data.data);
        setWeekWiseCheckinValue(response.data.view);
        // setUserFrequencyList(response.data.data.view);
      })
    } catch (error) {
      console.log(error);
    }
  }


  const krCheckInFrequncyView = async (userId: string | undefined, primaryObjId: string) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId || "");
      formData.append("objId", primaryObjId);

      const apiUrl = BaseUrl + `api/checkin/all/checkin/objective`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setUserFrequencyList(response.data.data);
        console.log(response.data.data);
      })
    } catch (error) {
      console.log(error);
    }
  }

  // const krViewTeamCheckInList = async (userId: [] | undefined, objId: string) => {
  //   try {
  //     // const formData = new FormData();
  //     // formData.append("userId", userId);
  //     // formData.append("objId", primaryObjId);

  //     const apiUrl = BaseUrl + `api/checkin/viewall/teamcheckin`;
  //     // const apiUrl = BaseUrl + `api/checkin/kr/selected/week`;

  //     await axios.post(apiUrl, {
  //       "objId": objId,
  //       "userId": userId
  //     }, {
  //       headers: {
  //         // "Content-Type": "application/json",
  //         "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
  //       }
  //     }).then((response) => {
  //       setTeamMemberFrequencyList(response.data.data);
  //       console.log(response.data.data);
  //     })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const krViewTeamCheckInList = async (userId: string, krid: string | undefined, week: string) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId || '');
      formData.append("krId", krid || '');
      formData.append("weekCount", week);

      const apiUrl = BaseUrl + `api/checkin/assign/user/kr`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const teamkr: createCheckIn = response.data.view;
        setTeamMemberFrequencyList(response.data.view);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const Teamkroptions = [
    {
      value: 'Divya',
      label: 'Divya',
      img: 'assets/images/user.svg'
    },
    {
      value: 'Priya',
      label: 'Priya',
      img: 'assets/images/user.svg'
    },
    {
      value: 'Deepa',
      label: 'Deepa',
      img: 'assets/images/user.svg'
    },
  ]
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  const currentIndex = weekOptions.findIndex(
    (option) => option.value === selectedOption
  );

  const handleNext = (objectiveId: string) => {
    const nextIndex = (currentIndex + 1) % weekOptions.length;
    onChange(weekOptions[nextIndex].value);
    checkInTeamList(userIdTeam, objectiveId);
  };

  const handlePrev = (objectiveId: string) => {
    const prevIndex =
      (currentIndex - 1 + weekOptions.length) % weekOptions.length;
    onChange(weekOptions[prevIndex].value);
    checkInTeamList(userIdTeam, objectiveId);
  };

  const isPrevAvailable = currentIndex > 0;
  const isNextAvailable = currentIndex < weekOptions.length - 1;

  const buttonStyle = (isAvailable: boolean) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });
  const CustomSelect: React.FC<CustomSelectProps> = ({
    onChange,
    selectedOption,
    krId,
  }) => {
    const onChangeSelectedWeek: boolean = objectivesassignedkr.some((kr) => kr.id === krId);
    if (onChangeSelectedWeek) {
      const handleNext = () => {
        const nextIndex = (currentIndex + 1) % weekOptions.length;
        onChange(weekOptions[nextIndex].value);
      };

      const handlePrev = () => {
        const prevIndex =
          (currentIndex - 1 + weekOptions.length) % weekOptions.length;
        onChange(weekOptions[prevIndex].value);
      };

      const isPrevAvailable = currentIndex > 0;
      const isNextAvailable = currentIndex < weekOptions.length - 1;

      const buttonStyle = (isAvailable: boolean) => ({
        backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
        borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
        color: isAvailable ? "#1890ff" : "#bfbfbf",
      });
    }



    return (
      <>
        <div className="">
          <Space direction="horizontal" className="z-1 mr-1 checkin_user_select">
            <Button
              // onClick={handlePrev}
              style={buttonStyle(isPrevAvailable)}
              disabled={!isPrevAvailable}
              icon={<LeftOutlined />}
            />
            <Select
              className="weekoption_checkin"
              value={selectedOption}
              // style={{ width: '300px' }}
              dropdownRender={(menu) => (
                <div className="checkin_kr_select">
                  {weekOptions.map((option) => (
                    <div
                      key={option.value}
                      style={{
                        padding: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        // checked={selectedOption === option.value}
                        onChange={() => onChange(option.value)}
                      >
                        <div className="justify-between">
                          <div>{option.label}</div>
                          <div className="ml-5 pl-1">{option.value}</div>
                        </div>
                      </Radio>
                    </div>
                  ))}
                </div>
              )}
              optionLabelProp="label"
            >
              {weekOptions.map((option) => {
                const label = (
                  <div className="justify-between align-center lineheight_noraml">
                    <div className="c_8080 fs-10">
                      <div className="text-center">{option.label}</div>
                      <div>{option.range}</div>
                    </div>
                    <div className="fs-12 c_121212">{option.value}</div>
                  </div>
                );

                return (
                  <Option key={option.value} value={option.value} label={label}>
                    {label}
                  </Option>
                );
              })}
            </Select>
            <Button
              // onClick={handleNext}
              style={buttonStyle(isNextAvailable)}
              disabled={!isNextAvailable}
              icon={<RightOutlined />}
            />
          </Space>
        </div>
      </>
    );
  };

  // const handleTMPrev = (krId: string) => {
  //   const currentIndex = TMoptions.findIndex(
  //     (option) => option.id === TMselectedOption
  //   );
  //   const prevIndex = (currentIndex - 1 + TMoptions.length) % TMoptions.length;
  //   setTMSelectedOption(TMoptions[prevIndex].id);
  //   checkInTeamList(TMoptions[prevIndex].id, krId);
  // };

  // const handleTMNext = (krId: string) => {
  //   const currentIndex = TMoptions.findIndex(
  //     (option) => option.id === TMselectedOption
  //   );
  //   const nextIndex = (currentIndex + 1) % TMoptions.length;
  //   setTMSelectedOption(TMoptions[nextIndex].id);
  //   checkInTeamList(TMoptions[nextIndex].id, krId);
  // };

  // const isPrevAvailableTeam =
  //   TMoptions.findIndex((option) => option.id === TMselectedOption) > 0;
  // const isNextAvailableTeam =
  //   TMoptions.findIndex((option) => option.id === TMselectedOption) <
  //   TMoptions.length - 1;

  // const buttonStyleTeam = (isAvailable: any) => ({
  //   backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
  //   borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
  //   color: isAvailable ? "#1890ff" : "#bfbfbf",
  // });

  // const handleOptionChange = (event: any, krId: string) => {
  //   console.log(event.target.value);
  //   setTMSelectedOption((prevSelectedOptionTeam) => ({
  //     ...prevSelectedOptionTeam,
  //     [krId]: event.target.value,
  //   }));
  //   checkInTeamList(event.target.value,krId);
  // };

  const handleOptionChange = (selectedUserId: string, teamObjectiveId: string, primaryObjId: string, Weekly: string) => {
    setTMSelectedOption((prevState) => ({
      ...prevState,
      [teamObjectiveId]: selectedUserId,
    }));
    setUserIdTeam(selectedUserId);
    setSelectedKrIdOption(teamObjectiveId);
    // krCheckInFrequncyView(selectedUserId, primaryObjId);
    // const userID: any = [];
    // userIds.map((user) => {
    //   userID.push(user.userid);
    // })
    krViewTeamCheckInList(selectedUserId, teamObjectiveId, Weekly);
  };

  const handleYesClick = () => {
    Modal.confirm({
      centered: true,
      title: 'Are you sure?',
      content: 'Do you really want to proceed?',
      okButtonProps: {
        className: 'cyan_btn',
      },
      cancelButtonProps: {
        className: 'c_32B7D9 border-0 bg-transparent fw-700 p-2',
      },
      onOk() {
        console.log('Confirmed');
        // Add your logic here for what should happen when the user clicks "OK"
      },
      onCancel() {
        console.log('Cancelled');
        // Add your logic here for what should happen when the user clicks "Cancel"
      },
    });
  };
  return (
    <>
      {
        isreviewKR ? (<>
          {isreviewcascade ? (
            <>
              <div className={permission_orgobjview ? "permission_btn" : " "}>
                {/* <span className="fw-600 fs-16 c-000">My Objectives (5)</span> */}
                {/* <div className="sm_display_block xl_display_none mb-2"> */}
                {/* <div className=" z-1 add_obj_btn">
                  <AddObj objectivelist={objectivelist}/>
                </div> */}
                <div className="d-flex align-center z-1 float-right mb-2 ">
                  {/* <img
                    src="assets/images/crown.svg"
                    alt=""
                    className="pr-1"
                  /> */}
                  {/* <p className="mr-2 "><span className="c_32B7D9">{showReviewObjective.filter(data => data.organizationStatus == "3").length}</span>/{showReviewObjective.length}</p> */}
                  {/* <ReviewCascade objectivelist={objectivelist} /> */}
                  {/* {saveAndCascadeUser !== 0 ? (<> */}
                  <div className="mr-2 ">
                    <button className="Review_button z-1 position-sticky" onClick={ReviewCascade}>
                      Review OBJ Weightage
                    </button>
                  </div>
                  {krforobj === true ? (
                    <button className="Review_button mr-2 z-1 position-sticky" onClick={ReviewKRWeight}>
                      Review KR Weightage
                    </button>) : (<></>)}
                  <Dropdown overlay={cascademenu} trigger={["click"]}>
                    <a>
                      <Space className="cyan_button">
                        Cascade
                        <img src="assets/images/dropdown_Arrow.svg" alt="" />
                      </Space>
                    </a>
                  </Dropdown>
                  {/* <button className="cyan_button " onClick={()=>{setkrreviewstatus(false);setReviewKR(false)}}>
                        Review KR
                    </button> */}
                  {/* </>) : (<></>)} */}
                </div>
                <div className={loading ? "mt-5 " : "m-0 "}>
                  {loading ? (<Spin className='justify-center align-center mt-5' tip="Loading" size="large">
                    <div className="content" />
                  </Spin>) : (

                    <Flex className={objectiveuserslist.length == 0 && Orgobjectiveuserslist.length == 0 ? "p-0" : "objective_overall"}>
                      <Flex
                        align="center"
                      >
                        {Orgobjectiveuserslist.map((item, index) => (
                          <>
                            {item.organizationStatus !== "1" ? (<>
                              <div className="d-flex">
                                <div
                                  key={index}
                                  onClick={(e) => orgobj_div(index)}
                                  className={` ${mainorgClass === `mainorg-${index}` ? 'objective_box_active p-2 mr-1' : 'objective_box p-2 '} `}
                                >
                                  <div className="">
                                    <Row className="justify-between">
                                      <div className="container_label">
                                        <div className="wrapper_label align-center mb-1">
                                          <span className={`${mainorgClass === `mainorg-${index}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}`}>
                                            <img
                                              src="assets/images/cup.svg"
                                              alt=""
                                              className="pr-1"
                                            />{" "}
                                            {item.objectCode}
                                          </span>
                                          <span className={` ${mainorgClass === `mainorg-${index}` ? "triangle_active" : "triangle"}`}></span>
                                        </div>
                                      </div>
                                      <div className="align-center mb-1">
                                        <button className={` align-center border-0 p-1 mr-1 ${item.organizationStatus === "3" ? 'Progressing_btn' : (Visibility[index] ? 'cascade_btn' : 'cascade_btn')}`}>
                                          <span className={` mr-1 ${item.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_cricle')}`}></span>
                                          {item.revokestatus != 1 ? (item.organizationStatus == "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}

                                        </button>
                                        <Dropdown overlay={orgobjmenu} trigger={["click"]}>
                                          <a onClick={(e) => {
                                            e.preventDefault();
                                            setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                            setobjectivestatus(e.currentTarget.getAttribute('data-status'))
                                            setorgobjectiveanduser(item)
                                            setobjectivetype("orgobjective")
                                            // setObjectiveId(itemId);                            
                                          }} href="#hgj"
                                            data-value={item.id}
                                            data-status={item.organizationStatus}
                                          >
                                            {item.createdBy == localStorage.loginId &&
                                              userSignOff.signOffStatus === 0 &&
                                              <Space>
                                                <img src="assets/images/menu.svg" alt="" />
                                                <input type="hidden" />
                                              </Space>
                                            }
                                          </a>
                                        </Dropdown>
                                      </div>
                                    </Row>
                                    <Flex>
                                      <p className="fs-16 c-000 m-0 cur-pointer mb-2" onClick={showDrawer}>
                                        <EditObj orgObjList={Orgobjectivelist} itemId={item.id} objectiveName={item.objectiveName} orgobjectivecode={item.objectCode} />
                                      </p>
                                    </Flex>
                                    <Flex>
                                      <p className="fs-14 mb-1 m-0 c_8080 threeline_text_overflow">
                                        {item.description}
                                      </p>
                                    </Flex>
                                    {/*Accept checkbox */}
                                    {item.organizationStatus == "2" && item.createdBy != localStorage.loginId && (<>
                                      <Flex justify="space-between" className="accept_obj" align="center">
                                        <Checkbox
                                          className="fs-12 fw-600"
                                          id=""
                                          checked={isObjCheckedorg[index]}
                                          onChange={() => handleObjCheckboxorg(index)}
                                        >I confirm my commitment to this objective </Checkbox>
                                        <button className={isObjCheckedorg[index] ? 'cyan_button' : 'disable_btn'} disabled={!isObjCheckedorg[index]} onClick={() => { handleAcceptBtn(index, item.id, "orgobjective") }}>
                                          Accept
                                        </button>
                                      </Flex></>)}

                                    {(item.organizationStatus == "3" || item.organizationStatus == "4" || item.createdBy == localStorage.loginId) &&
                                      <Flex className="member_count_box" justify="space-between" align="center">
                                        <Dropdown overlay={memberMenu} trigger={['click']}>
                                          <a className="ant-dropdown-link" onClick={() => userReporteeList(item.orgObjectiveUsersList, item.id, "orgobjective")}>
                                            <Space>
                                              <div className="align-center">
                                                {
                                                  memberActive ?
                                                    <img src="assets/images/memcount_active.svg" alt="" />
                                                    : <img src="assets/images/membercount.svg" alt="" />
                                                }

                                                <span className="fw-700 c-000 fs-14 ml-1">
                                                  {item.orgObjectiveUsersList.length}
                                                  <span className="ml-1">
                                                    {item.orgObjectiveUsersList.length > 1 ? "Members" : "Member"}
                                                  </span>
                                                </span>
                                              </div>
                                            </Space>
                                          </a>
                                        </Dropdown>
                                        <Flex onClick={() => sub_orgobj(index)} className="cur-pointer align-center ">
                                          <img src="assets/images/layer_b.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                                          <span className="c_32B7D9 fs-14 fw-700 text_line">{item.objectiveslist.filter(userdata => userdata.cftstatus != 1).length}  Child-Obj</span>
                                        </Flex>
                                        <AssignObj
                                          objectivelist={Orgobjectivelist}
                                          objectcode={item.objectCode}
                                          objectname={item.objectiveName}
                                          orgQuartervalue={item.quarter}
                                          orgobjid={item.id}
                                          objid={""}
                                          objective_id={""}
                                          year={item.year}
                                          startdate={item.startdate}
                                          enddate={item.enddate}
                                          orgdescription={item.description}
                                          objectivetype={"orgobjective"}
                                          button={userSignOff.signOffStatus === 0 ? "+ Assign Objective" : ""}
                                          header={userSignOff.signOffStatus === 0 ? "Assign Objective" : ""}
                                        />
                                      </Flex>
                                    }
                                  </div>
                                </div>
                                <Flex align="center" className={subOrgObj[index] ? "objective_bg_active" : ""} style={subOrgObj ? { right: "24px" } : {}}>
                                  {subOrgObj[index] && (item.objectiveslist.map((objectives, values) =>
                                    objectives.cftstatus != 1 && (
                                      <>
                                        <Flex>
                                          <img src="assets/images/connect_obj.svg" alt="" />
                                          <div className={orgClass === `org-${index}-${values}` ? "subobjective_box_active" : "subobjective_box"} onClick={(event) => { suborgobjectivesdetailsgets(objectives, index, values); }} >
                                            <Flex className="" justify="space-between" align="center">
                                              <Flex align="center">
                                                <div className="container_label">
                                                  <div className="wrapper_label align-center mb-1">
                                                    <span className={orgClass === `org-${index}-${values}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}>
                                                      <img
                                                        src="assets/images/crown.svg"
                                                        alt=""
                                                        className="pr-1"
                                                      />{" "}
                                                      {objectives.obj_code}
                                                    </span>
                                                    <span className={orgClass === `org-${index}-${values}` ? "triangle_active" : "triangle"}></span>
                                                  </div>
                                                </div>
                                              </Flex>
                                              <div className="align-center mb-1">
                                                <div>
                                                  <button className={` align-center border-0 p-1 mr-1 ${objectives.organizationStatus === "3" ? 'Progressing_btn' : (Visibility[index] ? 'cascade_btn' : 'cascade_btn')}`}>
                                                    <span className={` mr-1 ${objectives.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_cricle')}`}></span>
                                                    {objectives.revokedstatusorgobj != 1 && objectives.revokestatus != 1 ? (objectives.organizationStatus === "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}
                                                    {/* {objectives.organizationStatus === "3" ? " Progressing" : "Yet To Cascade"}{objectives.organizationStatus} */}
                                                  </button>
                                                </div>
                                                <Dropdown overlay={childOrgMenu} trigger={["click"]}>
                                                  <a onClick={(e) => {
                                                    e.preventDefault();
                                                    setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                                    setChildOrgObjectiveAndUser(objectives)
                                                    setObjectiveAndUser((prevObjectiveAndUser) => {
                                                      if (!prevObjectiveAndUser) {
                                                        return prevObjectiveAndUser;
                                                      }
                                                      return {
                                                        ...prevObjectiveAndUser,
                                                        revertstatus: objectives?.revokedstatusorgobj ?? prevObjectiveAndUser.revertstatus,
                                                      };
                                                    });
                                                  }} href="#hgj"
                                                    data-value={objectives.obj_id}
                                                  >
                                                    {
                                                      objectives.createdby === localStorage.loginId && userSignOff.signOffStatus === 0 &&
                                                      // objectives.signoffstatus === 0 && 
                                                      <Space>
                                                        <img src="assets/images/menu.svg" alt="" />
                                                        <input type="hidden" />
                                                      </Space>
                                                    }
                                                  </a>
                                                </Dropdown>
                                              </div>
                                            </Flex>
                                            <Flex className=" fs-16 c-000 twoline_text_overflow">
                                              {permission_childobjview ? (<EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectives.obj_id} objectivecode={objectives.obj_code} objectivestatus={objectives.organizationStatus} objectiveName={objectives.obj_name} type={false} objectivetype={objectives.objectiveId == null ? "orgobjective" : "objective"} assigneesmembers={""} />) : (<><p className="fs-16 c-000 cur-pointer oneline_text_overflow mb-2">{objectives.obj_name}fmkd</p></>)}
                                            </Flex>
                                            <Flex>
                                              <p className="fs-16  mt-1 m-0  twoline_text_overflow">{objectives.obj_desc} </p>
                                            </Flex>
                                            <Row className="d-flex align-center justify-between mt-3">
                                              <Dropdown overlay={memberMenu} trigger={['click']}>
                                                <a className="ant-dropdown-link" onClick={() => userReporteeListSub(objectives.objectivesuser_list, objectives.objectiveId, "objective")}>
                                                  <Space>
                                                    <div className="align-center">
                                                      <img src="assets/images/membercount.svg" alt="" />
                                                      <span className="fw-700 c-000 fs-14 ml-1">{objectives.objectivesuser_list.length}<span className="ml-1">{objectives.objectivesuser_list.length > 1 ? "Members" : "Member"}</span> </span>
                                                    </div>
                                                  </Space>
                                                </a>
                                              </Dropdown>
                                              <div className="align-center">
                                                <img src="assets/images/KRA_Icon20.svg" alt="" />
                                                <span className="fw-700 c-000 fs-14">
                                                  {objectives.keyResultcount} KR’s
                                                </span>
                                              </div>
                                            </Row>
                                          </div>
                                        </Flex>
                                      </>)
                                  ))}
                                </Flex>
                              </div>
                            </>) : (<></>)}
                          </>
                        ))}
                        {objectiveuserslist.map((item, index) => (
                          <> {(item.organizationStatus !== "1" && item.cftstatus != 1 && item.objectivesassignedusers.every(objUser => objUser.cftstatus != 1)) && item.mapped_obj.filter(data => data.objectiveId != item.parentid) && item.quartervalue == localStorage.Quarter && item.createdby != localStorage.loginId ? (<>
                            <div className="d-flex">
                              <div
                                key={index}
                                onClick={(event) => { objectivesdetailsgets(item, index); }}
                                className={`${mainobjClass === `mainobj-${index}` ? `objective_box_active p-2 mr-1` : `objective_box p-2 ${Visibility[index] ? 'mr-0' : ''}`}`}>
                                <div className="">
                                  <Row className="justify-between">
                                    <div className="container_label">
                                      <div className="wrapper_label align-center mb-1">
                                        <span className={`${mainobjClass === `mainobj-${index}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}`}>
                                          <img
                                            src="assets/images/crown.svg"
                                            alt=""
                                            className="pr-1"
                                          />{" "}
                                          {item.objcode}
                                        </span>
                                        <span className={` ${mainobjClass === `mainobj-${index}` ? "triangle_active" : "triangle"}`}></span>
                                      </div>
                                    </div>
                                    <div className="align-center mb-1">
                                      {
                                        ((item.cftstatus == 1 || item.objectivesassignedusers.some(objUser => objUser.cftstatus == 1))) &&
                                        <button className="CFT_btn mr-1">CFT</button>
                                      }
                                      <button className={` align-center border-0 p-1 mr-1 ${item.organizationStatus === "3" || item.organizationStatus === null ? 'Progressing_btn' : (Visibility[index] ? 'Progressing_btn_active' : ' cascade_btn')}`}>
                                        <span className={` mr-1 ${item.organizationStatus === "3" || item.organizationStatus === null ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : ' sm_cricle')}`}></span>
                                        {item.revertstatus != 1 ? (item.organizationStatus == null || item.organizationStatus == "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}
                                      </button>
                                      <Dropdown menu={{ items }} trigger={["click"]}>
                                        <a onClick={(e) => {
                                          e.preventDefault();
                                          setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                          setobjectivestatus(e.currentTarget.getAttribute('data-status'))
                                          setObjectiveAndUser(item)
                                          setobjectivetype(item.parentid == null ? ("orgobjective") : ("objective"))
                                          userOrganizationList(item.objectivesassignedusers.map(user => user.userid));
                                          // setObjectiveId(itemId);                            
                                        }} href="#hgj"
                                          data-value={item.objid}
                                          data-status={item.organizationStatus}
                                        >
                                          {item.createdby === localStorage.loginId && userSignOff.signOffStatus === 0 &&

                                            <Space>
                                              <img src="assets/images/menu.svg" alt="" />
                                              <input type="hidden" />
                                            </Space>

                                          }
                                        </a>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                  <Flex>
                                    {permission_parentobjview ? (<p className="fs-16 c-000 m-0 cur-pointer mb-2">
                                      <EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectivecode={item.objcode} objectiveId={item.objid} objectivestatus={item.organizationStatus} objectiveName={item.objname} type={false} objectivetype={item.parentid == null ? "orgobjective" : "objective"} assigneesmembers={""} />
                                    </p>) : (<>
                                      <p className="fs-16 c-000 cur-pointer mb-2">{item.objname}</p>
                                    </>)}
                                  </Flex>
                                  <Flex>
                                    <p className="fs-14 mb-1 m-0 c_8080 threeline_text_overflow">
                                      {item.description}
                                    </p>
                                  </Flex>
                                  {/*Accept checkbox */}
                                  {item.organizationStatus == "2" && (<>
                                    <Flex justify="space-between" className="accept_obj" align="center">
                                      <Checkbox
                                        className="fs-12 fw-600"
                                        id=""
                                        checked={isObjChecked[index]}
                                        onChange={() => handleObjCheckbox(index)}
                                      >I confirm my commitment to this objective </Checkbox>
                                      <button className={isObjChecked[index] ? 'cyan_button' : 'disable_btn'} disabled={!isObjChecked[index]} onClick={() => { handleAcceptBtn(index, item.objid, "objective") }}>
                                        Accept
                                      </button>
                                    </Flex></>)}

                                  {(item.organizationStatus == "3" || item.organizationStatus == "5") &&
                                    <Flex className="member_count_box" justify="space-between" align="center">
                                      <Dropdown overlay={memberMenu} trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={() => userReporteeList(item.objectivesassignedusers, item.objectiveid, "objective")}>
                                          <Space>
                                            <div className="align-center">
                                              <img src="assets/images/membercount.svg" alt="" />
                                              <span className="fw-700 c-000 fs-14 ml-1">{item.objectivesassignedusers.length}<span className="ml-1">{item.objectivesassignedusers.length > 1 ? "Members" : "Member"}</span> </span>
                                            </div>
                                          </Space>
                                        </a>
                                      </Dropdown>
                                      <div className="align-center">
                                        <img src="assets/images/KRA_Icon20.svg" alt="" />
                                        <span className="fw-700 c-000 fs-14">
                                          {item.keyResultcount} KR’s
                                        </span>
                                      </div>
                                      <Flex onClick={() => sub_obj(index)} className="cur-pointer  align-center ">
                                        <img src="assets/images/layer_b.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                                        <span className=" c_32B7D9 fs-14 fw-700 text_line">{item.mapped_obj_count} Child-Obj</span>
                                      </Flex>
                                      <AssignObj
                                        objectivelist={objectivelist}
                                        objectcode={item.objcode}
                                        objectname={item.objname}
                                        orgQuartervalue={item.quartervalue}
                                        orgobjid={item.orgobjid}
                                        objid={item.objid}
                                        objective_id={item.objectiveid}
                                        year={item.year}
                                        startdate={item.startdate}
                                        enddate={item.enddate}
                                        orgdescription={item.description}
                                        objectivetype={"objective"}
                                        button={userSignOff.signOffStatus === 0 ? "+ Assign Objective" : ""}
                                        header={userSignOff.signOffStatus === 0 ? "Assign Objective" : ""}
                                      />
                                    </Flex>
                                  }
                                </div>
                              </div>
                              <Flex align="center" className={subObj[index] ? "objective_bg_active mr-0" : ""} style={subObj ? { right: "24px" } : {}}>
                                {subObj[index] && (item.mapped_obj.map((objectives, values) =>
                                  <>
                                    <Flex>
                                      <img src="assets/images/connect_obj.svg" alt="" />
                                      <div className={objClass === `obj-${index}-${values}` ? "subobjective_box_active" : "subobjective_box"} onClick={(event) => { subobjectivesdetailsgets(objectives, index, values); }}>
                                        <Flex className="" justify="space-between" align="center">
                                          <Flex align="center">
                                            <div className="container_label">
                                              <div className="wrapper_label align-center mb-1">
                                                <span
                                                  className={objClass === `obj-${index}-${values}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}>
                                                  <img
                                                    src="assets/images/crown.svg"
                                                    alt=""
                                                    className="pr-1"
                                                  />{" "}
                                                  {objectives.obj_code}
                                                </span>
                                                <span className={objClass === `obj-${index}-${values}` ? "triangle_active" : "triangle"}></span>
                                              </div>
                                            </div>
                                          </Flex>
                                          <div className="align-center mb-1">
                                            <div>
                                              <button className={` align-center border-0 p-1 mr-1 ${objectives.organizationStatus === "5" ? 'cascade_btn' : (Visibility[index] ? 'Progressing_btn_active' : 'Progressing_btn')}`}>
                                                <span className={` mr-1 ${objectives.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_pro_cricle')}`}></span>
                                                {objectives.revertstatus != 1 ? (objectives.organizationStatus === "3" || objectives.organizationStatus == "4" ? "Progressing" : "Yet To Cascade") : ("Revoked")}
                                              </button>
                                            </div>
                                            <Dropdown overlay={childMenu} trigger={["click"]}>
                                              <a onClick={(e) => {
                                                e.preventDefault();
                                                setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                                setChildObjectiveAndUser(objectives)
                                                setObjectiveAndUser((prevObjectiveAndUser) => {
                                                  if (!prevObjectiveAndUser) {
                                                    return prevObjectiveAndUser;
                                                  }
                                                  return {
                                                    ...prevObjectiveAndUser,
                                                    revertstatus: objectives?.revertstatus ?? prevObjectiveAndUser.revertstatus,
                                                  };
                                                });
                                              }} href="#hgj"
                                                data-value={objectives.obj_id}
                                              >
                                                {objectives.createdby === localStorage.loginId && userSignOff.signOffStatus === 0 &&
                                                  <Space>
                                                    <img src="assets/images/menu.svg" alt="" />
                                                    <input type="hidden" />
                                                  </Space>
                                                }
                                              </a>
                                            </Dropdown>
                                          </div>
                                        </Flex>
                                        <Flex className=" fs-16 c-000 twoline_text_overflow">
                                          {permission_childobjview ? (<EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectives.obj_id} objectivestatus={objectives.organizationStatus} objectiveName={objectives.obj_name} objectivecode={objectives.obj_code} type={false} objectivetype={objectives.objectiveId == null ? "orgobjective" : "objective"} assigneesmembers={""} />) : (<><p className="fs-16 c-000 cur-pointer threeline_text_overflow mb-2">{objectives.obj_name}fmkd</p></>)}
                                        </Flex>
                                        <Flex>
                                          <p className="fs-16 twoline_text_overflow mt-1 m-0 ">{objectives.obj_desc}</p>
                                        </Flex>
                                        <Row className="d-flex align-center justify-between mt-3">
                                          <Dropdown overlay={memberMenu} trigger={['click']}>
                                            <a className="ant-dropdown-link" onClick={() => userReporteeList(objectives.objectiveassignedusers, objectives.objectiveId, "objective")}>
                                              <Space>
                                                <div className="align-center">
                                                  <img src="assets/images/membercount.svg" alt="" />
                                                  <span className="fw-700 c-000 fs-14 ml-1">{objectives.objectiveassignedusers.length}<span className="ml-1">{objectives.objectiveassignedusers.length > 1 ? "Members" : "Member"}</span> </span>
                                                </div>
                                              </Space>
                                            </a>
                                          </Dropdown>
                                          <div className="align-center">
                                            <img src="assets/images/KRA_Icon20.svg" alt="" />
                                            <span className="fw-700 c-000 fs-14">
                                              {objectives.keyResultcount} KR’s
                                            </span>
                                          </div>
                                        </Row>
                                      </div>
                                    </Flex>
                                  </>
                                ))}
                              </Flex>
                            </div>
                          </>) : ""}
                          </>
                        ))
                        }
                      </Flex>
                    </Flex>
                  )}
                </div>
                <div className="">
                </div>
                {Orgobjectiveuserslist.length == 0 && objectiveuserslist.length == 0 ? (
                  <>
                    <Card className="Asp_nc_card w-100 ">
                      <Row className="text-center obj_textbox">
                        <span className="c_AAA fs-14 fw-600 ">Assigned objectives not created yet.</span>
                      </Row>
                    </Card>
                    {userSignOff?.signOffStatus === 0 &&
                      <Row className="justify-between mt-2 mb-2">
                        <span className="fs-16 fw-600">0 Key Results </span>
                        <button className="disable_btn">+ Add Key Result</button>
                      </Row>
                    }
                    <Card className="Aso_obj_nc_card mt-2 mb-3">
                      <Row className="text-center obj_textbox ">
                        <span className="c_AAA fs-14 fw-600">Once objectives created, you can create your Key results.</span>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <>
                    {(objectivesassignedkr.length === 0 && objectivesteamdkr.length === 0) ? (
                      krforobj === true ? (
                        <>
                          <Col className="">
                            {objectivesassignedkr.some((data) => data.status == 3) &&
                              <span className="pl-1" onClick={handleKrshow}><img src="assets/images/KR_available.svg" className="cur-pointer" alt="" width={25} height={25} /></span>
                            }
                          </Col>
                          <Row className=" justify-between align-center mt-2" >
                            <Col >
                              <span className="fs-16 fw-600 keyresult_title ">0 Key Result for <b className="">{objectivekeys.obj_name}</b></span>
                            </Col>
                            <Col className="d-flex align-center">
                              {objectivekeys.revertstatus !== 1 && objectivekeys.obj_id != null &&
                                <Select
                                  className="custom-select addkr_results_dropdown mr-1"
                                  style={{ width: '200px' }}
                                  placeholder="Select currency"
                                  onChange={(e) => handleKrChange(e, objectivekeys.obj_id, localStorage.getItem('loginId') || "")}
                                  value={selectedKrValue}
                                >
                                  <Option value="All Key Results">
                                    <Radio
                                      value="All Key Results"
                                      checked={selectedKrValue === "All Key Results"}
                                    >
                                      All Key Results
                                    </Radio>
                                    <img src="assets/images/all_kr.svg" alt="" />
                                  </Option>
                                  <Option value="My Key Results">
                                    <Radio
                                      value="My Key Results"
                                      checked={selectedKrValue === "My Key Results"}
                                    >
                                      My Key Results
                                    </Radio>
                                    <img src="assets/images/my_kr.svg" alt="" />

                                  </Option>
                                  <Option value="Team Key Results">
                                    <Radio
                                      value="Team Key Results"
                                      checked={selectedKrValue === "Team Key Results"}
                                    >
                                      Team Key Results
                                    </Radio>
                                    <img src="assets/images/team_kr.svg" alt="" />

                                  </Option>
                                </Select>
                              }
                              {userSignOff.signOffStatus === 0 &&
                                (
                                  <span><AddKR objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectivesdetailsgets={() => { handleKrChange(selectedKrValue, objectivekeys.obj_id, localStorage.getItem('loginId') || "") }} krassignforme={() => { getassignedkeyforobjectives(objectivekeys.obj_id, localStorage.loginid) }} objdesc={objectivekeys.obj_desc} objquarter={objectivekeys.obj_quarter} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} year={objectivekeys.year} availableweightagekr={availableweightagekr} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} selectedkrvalue={selectedKrValue} /></span>
                                )
                              }
                            </Col>
                          </Row>
                        </>
                      ) : (<></>)
                    ) : (
                      krforobj === true &&
                      <>
                        <Row className=" justify-between align-center mt-2" >
                          <Col>
                            <span className="fs-16 fw-600 keyresult_title">
                              {selectedKrValue === "All Key Results" && (objectivesassignedkr.filter((data) => data.status != 3).length + objectivesteamdkr.filter(teamkr => !objectivesassignedkr.some(data => data.id === teamkr.id && data.status != 3)).length)}
                              {selectedKrValue === "My Key Results" && objectivesassignedkr.filter((data) => data.status != 3).length}
                              {selectedKrValue === "Team Key Results" && objectivesteamdkr.filter((data) => data.status != 3).length}
                              &nbsp;Key&nbsp;
                              {(selectedKrValue === "All Key Results" && (objectivesassignedkr.length) > 1) || (selectedKrValue === "My Key Results" && objectivesassignedkr.length > 1) || (selectedKrValue === "Team Key Results" && objectivesteamdkr.length > 1) ? " Results" : " Result"}
                              &nbsp;for&nbsp; <b>{objectivekeys.obj_name}</b>
                            </span>

                          </Col>
                          <Col className="d-flex align-center">
                            {objectivekeys.revertstatus !== 1 && objectivekeys.obj_id != null &&
                              <>
                                <Select
                                  className="custom-select addkr_results_dropdown mr-1"
                                  style={{ width: '200px' }}
                                  placeholder="Select currency"
                                  onChange={(e) => handleKrChange(e, objectivekeys.obj_id, localStorage.getItem('loginId') || "")}
                                  value={selectedKrValue}
                                >
                                  <Option value="All Key Results">
                                    <Radio
                                      value="All Key Results"
                                      checked={selectedKrValue === "All Key Results"}
                                    >
                                      All Key Results
                                    </Radio>
                                    <img src="assets/images/all_kr.svg" alt="" />
                                  </Option>
                                  <Option value="My Key Results">
                                    <Radio
                                      value="My Key Results"
                                      checked={selectedKrValue === "My Key Results"}
                                    >
                                      My Key Results
                                    </Radio>
                                    <img src="assets/images/my_kr.svg" alt="" />

                                  </Option>
                                  <Option value="Team Key Results">
                                    <Radio
                                      value="Team Key Results"
                                      checked={selectedKrValue === "Team Key Results"}
                                    >
                                      Team Key Results
                                    </Radio>
                                    <img src="assets/images/team_kr.svg" alt="" />

                                  </Option>
                                </Select>
                                <span className="nowrap"><AddKR objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectivesdetailsgets={() => { handleKrChange(selectedKrValue, objectivekeys.obj_id, localStorage.getItem('loginId') || "") }} krassignforme={() => { getassignedkeyforobjectives(objectivekeys.obj_id, localStorage.loginid) }} objdesc={objectivekeys.obj_desc} objquarter={objectivekeys.obj_quarter} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} year={objectivekeys.year} availableweightagekr={availableweightagekr} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} selectedkrvalue={selectedKrValue} /></span>
                              </>
                            }
                          </Col>
                        </Row>
                        <div>

                        </div>
                        <div className="obj_tab" >
                          {objectivesteamdkr.length === 0 && objectivesassignedkr.length === 0 ? (<>
                            <Card className="Aso_obj_nc_card mt-2 mb-3">
                              <Row className="text-center obj_textbox ">
                                <span className="c_AAA fs-14 fw-600">No Key Results Available.</span>
                              </Row>
                            </Card>
                          </>) :
                            (
                              <>
                                <div className="keyresult_div">
                                  {selectedKrValue === "All Key Results" && (
                                    <>
                                      {objectivesassignedkr.filter(teamkr => !objectivesteamdkr.some(data => data.id === teamkr.id)).map((objective, index) => (
                                        objective.status != 3 && (
                                          <Row className="kr_card mt-1 mb-2 mr-1 ">
                                            <Col xl={16} lg={15} md={12} sm={12} xs={24} className="pt-1 pb-1 pl-1 pr-5">
                                              <Row justify={"space-between"} className="align-start">
                                                <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                                  <Row>
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/crown.svg" alt="" />
                                                          {objectivekeys.obj_code}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="triangle_left"></span>
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/KRA-Icon.svg" alt="" />
                                                          {objective.keyid}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                  <div className="">
                                                    {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue} objectivelist={objectivelist} krid={objective.id} krtitle={objective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid} checkInFrequency={objective.checkInFrequency} uomType={objective.uom} objquarter={objectivekeys.obj_quarter} tabActiveKey="1"  />) : (<p className="c-000 fs-16 c_32B7D9">{objective.keytitle}</p>)}
                                                  </div>

                                                  <div className="fs-14 c_8080  threeline_text_overflow">
                                                    {objective.description}
                                                  </div>
                                                </Col>
                                                <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                                  <Row className=" m-0 p-0 justify-end align-center">
                                                    {objectivekeys.revertstatus !== 1 ? (

                                                      objective.status == 2 && getAllLeadMeasure.filter(data => data.krId === objective.id).length == 0 ?
                                                        (<>
                                                          <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                            <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                          </button>
                                                        </>) :
                                                        getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ?
                                                          (<>
                                                            <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                            </button>
                                                          </>
                                                          ) :
                                                          (<>
                                                            <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                            </button>
                                                          </>
                                                          )


                                                    ) : (
                                                      <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                      </button>
                                                    )}
                                                    {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                      <a onClick={(e) => setKrValues(e, objective)} href="#hgj"
                                                      >
                                                        {localStorage.loginId == objective.createdBy && userSignOff.signOffStatus === 0
                                                          &&
                                                          <Space>
                                                            <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                            <input type="hidden" />
                                                          </Space>
                                                        }
                                                      </a>
                                                    </Dropdown>) : (<></>)}
                                                  </Row>
                                                  {objective.keyResultUsersListAssignedTo.some(user => user.userid === loginUserId) ? (
                                                    objective.keyResultUsersListAssignedTo
                                                      .filter(user => user.userid === loginUserId)
                                                      .map(user => (
                                                        <div key={user.userid}>
                                                          <Row className="obj-progress mt-2">
                                                            <Col xl={22} lg={22} md={22} sm={22}>
                                                              <div className="custom-progress-bar">
                                                                <div className="progress-content">
                                                                  <Progress
                                                                    type="line"
                                                                    percent={Math.round((user.totaltarget / user.target) * 100)}
                                                                    strokeColor="#00CFFF"
                                                                    trailColor="#E0E0E0"
                                                                    showInfo={false}
                                                                  // strokeWidth={10}

                                                                  />
                                                                  <div className="progress-dots">
                                                                    <Tooltip title="0%" placement="bottom">
                                                                      <span className="dot" style={{ left: '0%' }}></span>
                                                                    </Tooltip>
                                                                    {user.totaltarget > 0 &&
                                                                      user.totaltarget <
                                                                      user.target && (
                                                                        <Tooltip title={`${user.totaltarget}%`} placement="bottom">
                                                                          <span className="dot" style={{
                                                                            left: `calc(${(user.totaltarget / user.target) * 100}% - 4px)`
                                                                          }}></span>
                                                                        </Tooltip>
                                                                      )}
                                                                    <Tooltip title={`${objective.uom === "Percentage" ? objective.target : 100}%`} placement="bottom">
                                                                      <span className="dot" style={{ left: '100%' }}></span>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                            <Col xl={2} lg={2} md={2} sm={2}>
                                                            <span className="progress-percentage fs-16">
                                                            {Math.round(user.totaltarget)}% 
                                                            </span>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      ))) : (
                                                    <Row className="obj-progress mt-2">
                                                      <Col xl={22} lg={22} md={22} sm={22}>
                                                        <div className="custom-progress-bar">
                                                          <div className="progress-content">
                                                            <Progress
                                                              type="line"
                                                              percent={Math.round(objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0)}
                                                              strokeColor="#00CFFF"
                                                              trailColor="#E0E0E0"
                                                              showInfo={false}
                                                            // strokeWidth={10}
                                                            />
                                                            <div className="progress-dots">
                                                              <Tooltip title="Start value: 0%" placement="bottom">
                                                                <span className="dot" style={{ left: '0%' }}></span>
                                                              </Tooltip>
                                                              {/* <span>{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget}</span> */}
                                                              {(objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0 > 0) && (objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0 <= 100) ? (
                                                                <>
                                                                  <Tooltip title={`Current value: ${objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}%`} placement="bottom">
                                                                    {objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget != 0 &&
                                                                      <>
                                                                        <span className="dot" style={{ left: `calc(${objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}% - 4px)` }}></span>
                                                                      </>
                                                                    }
                                                                  </Tooltip>
                                                                </>
                                                              ) : ""}
                                                              <Tooltip title={`${objective.uom === "Percentage" ? objective.target : 100}%`} placement="bottom">
                                                                <span className="dot" style={{ left: '100%' }}></span>
                                                              </Tooltip>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                      <Col xl={2} lg={2} md={2} sm={2}>
                                                        <span className="progress-percentage fs-16">{objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}%</span>
                                                      </Col>
                                                    </Row>
                                                  )
                                                  }
                                                  <Row className="d-flex align-center justify-end mt-5">
                                                    <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                      <a className="ant-dropdown-link" onClick={() => userKeyResultList(objective.keyResultUsersListAssignedTo, objective.id, objectivetype)}>
                                                        <Space>
                                                          <div className="align-center">
                                                            <img src="assets/images/membercount.svg" alt="" />
                                                            <span className="fw-700 c-000 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}<span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                          </div>
                                                        </Space>
                                                      </a>
                                                    </Dropdown>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                            {/* <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col> */}
                                            <Col
                                              xl={8}
                                              lg={9}
                                              md={12}
                                              sm={12}
                                              xs={24}
                                              className="bg_FAFAFA br_top_bottom p-2 pb-1"
                                            >
                                              <Row className="align-center justify-between">
                                                <Col
                                                  xl={8}
                                                  lg={12}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  {objective.keyResultUsersListAssignedTo.some(data => data.userid == localStorage.getItem('loginId')) &&
                                                    <div className="d-flex  align-center" key={objective.id}>
                                                      <img src="assets/images/user.svg" alt="" className="img_24" />
                                                      <span className="pl-1 fs-14 c_000">{localStorage.getItem('name')}</span>
                                                    </div>
                                                  }
                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                  {objective.keyResultUsersListAssignedTo.some(data => data.userid == localStorage.getItem('loginId')) &&
                                                    <div className="weekoption_checkin c_8080 fw-600 float-right fs-12">
                                                      <p className="m-0">{objective.currentmonth}</p>
                                                      <p className="p-0 m-0 float-right">{objective.currentweekiteration}</p>
                                                    </div>
                                                  }
                                                </Col>
                                              </Row>
                                              <>
                                                <Row className="mt-1">
                                                  <Col
                                                    xl={12}
                                                    lg={12}
                                                    md={24}
                                                    sm={24}
                                                    xs={24}
                                                  >
                                                    <label htmlFor="" className="fs-12 c_8080 fw-300" >
                                                      {objective.uom !== "Custom" ? "Target Balance" : "Target Status"}
                                                    </label>
                                                    {objective.keyResultUsersListAssignedTo
                                                    .filter(user => user.userid === loginUserId)
                                                    .map(user => (
                                                    <p className="fs-14 fw-700 m-0">
                                                      {objective.uom === "Percentage" ?
                                                        (user.target - user.targetgiven) + " %" :
                                                        (objective.uom === "Currency" ? (objective.amounttype === "Thousands" ?
                                                          " ₹ " + (user.target - user.targetgiven) + " T " :
                                                          (objective.amounttype === "Lakhs" ?
                                                            " ₹ " + (user.target - user.targetgiven) + " L " :
                                                            " ₹ " + (user.target - user.targetgiven) + " C")) : (objective.uom === "Numeric" ? (user.target - user.targetgiven) :
                                                              (objective.checkInValue === 0 ? "Not Completed" : "Completed")
                                                        ))}
                                                    </ p>
                                                    ))}
                                                  </Col>
                                                  <Col
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    className=""
                                                  >
                                                    <p className="fs-12 c_8080 p-0 m-0 mt-1 float-right">Checkin for Week {objective.currentweekiteration} is Open</p>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col
                                                    xl={24}
                                                    lg={24}
                                                    md={24}
                                                    sm={24}
                                                    className=" kr_new_card justify-end"
                                                  >
                                                   {objective.status==2&& 
                                                    <KRDescription
                                                      objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                      getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                      getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                      objectivelist={objectivelist}
                                                      krid={objective.id}
                                                      krtitle="Checkin"
                                                      objname={objectivekeys.obj_name}
                                                      objid={objectivekeys.obj_id}
                                                      obj_code={objectivekeys.obj_code}
                                                      availableweightagekr={availableweightagekr}
                                                      year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                      objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid}
                                                      checkInFrequency={objective.checkInFrequency} uomType={objective.uom} 
                                                      selectedkrvalue={selectedKrValue}
                                                      objquarter={objectivekeys.obj_quarter} tabActiveKey="2"/>
                                                   }
                                                    {/* <p className="text_line c_32B7D9 fs-12 fw-700 p-0 m-0 mt-1">View Overview</p> */}
                                                  </Col>
                                                </Row>
                                              </>
                                            </Col>
                                          </Row>
                                        )
                                      ))}
                                      {objectivesteamdkr.map((teamobjective, index) => (
                                        teamobjective.status != 3 && (
                                          <Row className="kr_card mt-1 mb-2 mr-1 ">
                                            <Col xl={16} lg={15} md={12} sm={12} xs={24} className="pt-1 pb-1 pl-1 pr-5">
                                              <Row justify={"space-between"} className="align-start">
                                                <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                                  <Row>
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/crown.svg" alt="" />
                                                          {objectivekeys.obj_code}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="triangle_left"></span>
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/KRA-Icon.svg" alt="" />
                                                          {teamobjective.keyid}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                  <div className="">
                                                    {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} objectivelist={objectivelist} krid={teamobjective.id} krtitle={teamobjective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.id} checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom} objquarter={objectivekeys.obj_quarter} tabActiveKey="1" />) : (<p className="c-000 fs-16 c_32B7D9">{teamobjective.keytitle}</p>)}
                                                  </div>
                                                  <div className="fs-14 c_8080 threeline_text_overflow">
                                                    {teamobjective.description}
                                                  </div>
                                                </Col>
                                                <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                                  <Row className=" m-0 p-0 justify-end align-center">
                                                    {objectivekeys.revertstatus !== 1 ? (
                                                      teamobjective.status == 2 && getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length == 0 ?
                                                        (<>
                                                          <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                            <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                          </button>
                                                        </>) :
                                                        getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length > 0 ?
                                                          (<>
                                                            <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                            </button>
                                                          </>
                                                          ) :
                                                          (<>
                                                            <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                            </button>
                                                          </>
                                                          )
                                                    ) : (
                                                      <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                      </button>
                                                    )}
                                                    {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                      <a onClick={(e) => setKrValues(e, teamobjective)} href="#hgj"
                                                      >
                                                        {localStorage.loginId == teamobjective.createdBy && userSignOff.signOffStatus === 0
                                                          &&
                                                          <Space>
                                                            <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                            <input type="hidden" />
                                                          </Space>
                                                        }
                                                      </a>
                                                    </Dropdown>) : (<></>)}
                                                  </Row>
                                                  <Row className="obj-progress mt-2">
                                                    <Col xl={22} lg={22} md={22} sm={22}>
                                                      <div className="custom-progress-bar">
                                                        <div className="progress-content">
                                                          <Progress
                                                            type="line"
                                                            percent={teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}
                                                            strokeColor="#00CFFF"
                                                            trailColor="#E0E0E0"
                                                            showInfo={false}
                                                          // strokeWidth={10}
                                                          />
                                                          <div className="progress-dots">
                                                            <Tooltip title="Start value: 0%" placement="bottom">
                                                              <span className="dot" style={{ left: '0%' }}></span>
                                                            </Tooltip>
                                                            {/* <span>{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget}</span> */}
                                                            {(teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0 > 0) && (teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0 <= 100) ? (
                                                              <>
                                                                <Tooltip title={`Current value: ${teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}%`} placement="bottom">
                                                                  {teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget != 0 &&
                                                                    <>
                                                                      <span className="dot" style={{ left: `calc(${teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}% - 4px)` }}></span>
                                                                    </>
                                                                  }
                                                                </Tooltip>
                                                              </>
                                                            ) : ""}
                                                            <Tooltip title={`${teamobjective.uom === "Percentage" ? teamobjective.target : 100}%`} placement="bottom">
                                                              <span className="dot" style={{ left: '100%' }}></span>
                                                            </Tooltip>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2}>
                                                      <span className="progress-percentage fs-16">{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}%</span>
                                                    </Col>
                                                  </Row>
                                                  <Row className="d-flex align-center justify-end mt-5">
                                                    <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                      <a className="ant-dropdown-link" onClick={() => userKeyResultList(teamobjective.keyResultUsersListAssignedTo, teamobjective.id, objectivetype)}>
                                                        <Space>
                                                          <div className="align-center">
                                                            <img src="assets/images/membercount.svg" alt="" />
                                                            <span className="fw-700 c-000 fs-14 ml-1">{teamobjective.keyResultUsersListAssignedTo.length}<span className="ml-1">{teamobjective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                          </div>
                                                        </Space>
                                                      </a>
                                                    </Dropdown>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                            {/* <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col> */}
                                            <Col
                                              xl={8}
                                              lg={9}
                                              md={12}
                                              sm={12}
                                              xs={24}
                                              className="bg_FAFAFA br_top_bottom p-2 pb-1"
                                            >
                                              <Row>
                                                <Col
                                                  xl={12}
                                                  lg={12}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Space
                                                    direction="horizontal"
                                                    className="z-1 mr-1 checkin_user_select_kr"
                                                  >
                                                    {/* <Button
                                                      icon={<LeftOutlined />}
                                                      onClick={() => handleTMPrev(teamobjective.id)}
                                                      style={buttonStyleTeam(isPrevAvailableTeam)}
                                                      disabled={!isPrevAvailableTeam}
                                                    /> */}

                                                    {/* <Select
                                                      className="custom-select w-300"
                                                      value={TMselectedOption?.[teamobjective.id] || ''}
                                                      onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                    >
                                                      {teamobjective.keyResultUsersListAssignedTo.map((TMoption) => (
                                                        <label
                                                          key={TMoption.userid}
                                                          className="d-flex align-center"
                                                        >
                                                          <Radio
                                                            value={TMoption.userid}
                                                            checked={TMselectedOption?.[teamobjective.id] === TMoption.userid}
                                                            onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                          >
                                                            <div className="align-center justify-between status_signoff_user ">
                                                              <img
                                                                // src={TMoption.profilePath?FileUrl+TMoption.profilePath:"assets/images/Default_user.svg"}
                                                                src={"assets/images/Default_user.svg"}
                                                                alt={TMoption.username}
                                                                className="mr-1 img_36"
                                                              />
                                                              <span className="mr-1">
                                                                {TMoption.username}
                                                              </span>
                                                            </div>
                                                          </Radio>
                                                        </label>
                                                      ))}
                                                    </Select> */}
                                                    <Select className="custom-select"
                                                      value={TMselectedOption[teamobjective.id]}
                                                    // onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                    >
                                                      {teamobjective.keyResultUsersListAssignedTo.map((TMoption) => (
                                                        <label key={TMoption.userid} className="d-flex align-center">
                                                          <Radio value={TMoption.userid}
                                                            // checked={TMselectedOption[teamobjective.id] === TMoption.userid}
                                                            checked={TMselectedOption[teamobjective.id] === TMoption.userid}
                                                            onChange={() => handleOptionChange(TMoption.userid, teamobjective.id, teamobjective.objectiveid, teamobjective.uom !== "Custom" ? teamobjective.currentweekiteration : "Custom")}
                                                          >
                                                            <div className="align-center justify-between status_signoff_user">
                                                              <img
                                                                src={"assets/images/Default_user.svg"}
                                                                alt={TMoption.username}
                                                                className="mr-1 img_24"
                                                              />
                                                              <span className="mr-1">
                                                                {TMoption.username}
                                                              </span>
                                                            </div>
                                                          </Radio>
                                                        </label>
                                                      ))}
                                                    </Select>
                                                  </Space>
                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                  <div className="weekoption_checkin c_8080 fw-600 float-right fs-12">
                                                    <p className="m-0">{teamobjective.currentmonth}</p>
                                                    <p className="p-0 m-0 float-right">{teamobjective.currentweekiteration}</p>
                                                  </div>
                                                </Col>
                                              </Row>
                                              {teamobjective.uom !== "Custom" ?
                                                (
                                                  <>
                                                    {teamMemberFrequencyList?.keyResultId === teamobjective.id && teamMemberFrequencyList.userId === userIdTeam && teamMemberFrequencyList.checkInWeek === teamobjective.currentweekiteration &&
                                                      <>
                                                        <Row className="mt-1">
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Achieved</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.achievedTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.achievedTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.achievedTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.achievedTarget + " C")) : (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.achievedTarget : teamMemberFrequencyList?.achievedTarget))}

                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Completed</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.completedTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.completedTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.completedTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.completedTarget + " C")) : 
                                                                    (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.completedTarget : teamMemberFrequencyList?.completedTarget))}

                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Balance</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.remainingTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.remainingTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.remainingTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.remainingTarget + " C")) : (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.remainingTarget : teamMemberFrequencyList?.remainingTarget))}
                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={24}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            className="kr_new_card justify-end"
                                                          >
                                                            <KRDescription
                                                              objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                              getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                              getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                              objectivelist={objectivelist}
                                                              krid={teamobjective.id}
                                                              krtitle="View Overview"
                                                              objname={objectivekeys.obj_name}
                                                              objid={objectivekeys.obj_id}
                                                              obj_code={objectivekeys.obj_code}
                                                              availableweightagekr={availableweightagekr}
                                                              year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                              objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.keyid}
                                                              checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom}
                                                              selectedkrvalue={selectedKrValue} 
                                                              objquarter={objectivekeys.obj_quarter} tabActiveKey="2" />
                                                            {/* <p className="text_line c_32B7D9 fs-12 fw-700 p-0 m-0 mt-1">View Overview</p> */}
                                                          </Col>
                                                        </Row>
                                                      </>}
                                                  </>) : (
                                                  <>
                                                    {teamMemberFrequencyList?.keyResultId === teamobjective.id && teamMemberFrequencyList.userId === userIdTeam &&
                                                      <>
                                                        <Row className="mt-1">
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Status</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.customTarget === 0 ? "Not Completed" : "Completed"}
                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={24}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            className="kr_new_card justify-end"
                                                          >
                                                            <KRDescription
                                                              objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                              getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                              getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                              objectivelist={objectivelist}
                                                              krid={teamobjective.id}
                                                              krtitle="View Overview"
                                                              selectedkrvalue={selectedKrValue}
                                                              objname={objectivekeys.obj_name}
                                                              objid={objectivekeys.obj_id}
                                                              obj_code={objectivekeys.obj_code}
                                                              availableweightagekr={availableweightagekr}
                                                              year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                              objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.keyid}
                                                              checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom} objquarter={objectivekeys.obj_quarter}
                                                              tabActiveKey = "2" />
                                                          </Col>
                                                        </Row>
                                                      </>}
                                                  </>
                                                )}
                                            </Col>
                                          </Row>
                                        )
                                      ))}
                                    </>
                                  )}
                                  {selectedKrValue === "Team Key Results" && (
                                    <>
                                      {objectivesteamdkr.map((teamobjective, index) => (
                                        teamobjective.status != 3 && (
                                          <Row className="kr_card mt-1 mb-2 mr-1 ">
                                            <Col xl={16} lg={15} md={12} sm={12} xs={24} className="pt-1 pb-1 pl-1 pr-5">
                                              <Row justify={"space-between"} className="align-start">
                                                <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                                  <Row>
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/crown.svg" alt="" />
                                                          {objectivekeys.obj_code}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="triangle_left"></span>
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/KRA-Icon.svg" alt="" />
                                                          {teamobjective.keyid}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                  <div className="">
                                                    {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} objectivelist={objectivelist} krid={teamobjective.id} krtitle={teamobjective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.id} checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom} objquarter={objectivekeys.obj_quarter} tabActiveKey="1" />) : (<p className="c-000 fs-16 c_32B7D9">{teamobjective.keytitle}</p>)}
                                                  </div>
                                                  <div className="fs-14 c_8080 threeline_text_overflow">
                                                    {teamobjective.description}
                                                  </div>
                                                </Col>
                                                <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                                  <Row className=" m-0 p-0 justify-end align-center">
                                                    {objectivekeys.revertstatus !== 1 ? (
                                                      teamobjective.status == 2 && getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length == 0 ?
                                                        (<>
                                                          <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                            <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                          </button>
                                                        </>) :
                                                        getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length > 0 ?
                                                          (<>
                                                            <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                            </button>
                                                          </>
                                                          ) :
                                                          (<>
                                                            <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                            </button>
                                                          </>
                                                          )
                                                    ) : (
                                                      <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                      </button>
                                                    )}
                                                    {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                      <a onClick={(e) => setKrValues(e, teamobjective)} href="#hgj"
                                                      >
                                                        {localStorage.loginId == teamobjective.createdBy && userSignOff.signOffStatus === 0
                                                          &&
                                                          <Space>
                                                            <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                            <input type="hidden" />
                                                          </Space>
                                                        }
                                                      </a>
                                                    </Dropdown>) : (<></>)}
                                                  </Row>
                                                  <Row className="obj-progress mt-2">
                                                    <Col xl={22} lg={22} md={22} sm={22}>
                                                      <div className="custom-progress-bar">
                                                        <div className="progress-content">
                                                          <Progress
                                                            type="line"
                                                            percent={teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}
                                                            strokeColor="#00CFFF"
                                                            trailColor="#E0E0E0"
                                                            showInfo={false}
                                                          // strokeWidth={10}
                                                          />
                                                          <div className="progress-dots">
                                                            <Tooltip title="Start value: 0%" placement="bottom">
                                                              <span className="dot" style={{ left: '0%' }}></span>
                                                            </Tooltip>
                                                            {/* <span>{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget}</span> */}
                                                            {(teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0 > 0) && (teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0 <= 100) ? (
                                                              <>
                                                                <Tooltip title={`Current value: ${teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}%`} placement="bottom">
                                                                  {teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget != 0 &&
                                                                    <>
                                                                      <span className="dot" style={{ left: `calc(${teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}% - 4px)` }}></span>
                                                                    </>
                                                                  }
                                                                </Tooltip>
                                                              </>
                                                            ) : ""}
                                                            <Tooltip title={`${teamobjective.uom === "Percentage" ? teamobjective.target : 100}%`} placement="bottom">
                                                              <span className="dot" style={{ left: '100%' }}></span>
                                                            </Tooltip>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={2}>
                                                      <span className="progress-percentage fs-16">{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget ?? 0}%</span>
                                                    </Col>
                                                  </Row>
                                                  <Row className="d-flex align-center justify-end mt-5">
                                                    <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                      <a className="ant-dropdown-link" onClick={() => userKeyResultList(teamobjective.keyResultUsersListAssignedTo, teamobjective.id, objectivetype)}>
                                                        <Space>
                                                          <div className="align-center">
                                                            <img src="assets/images/membercount.svg" alt="" />
                                                            <span className="fw-700 c-000 fs-14 ml-1">{teamobjective.keyResultUsersListAssignedTo.length}<span className="ml-1">{teamobjective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                          </div>
                                                        </Space>
                                                      </a>
                                                    </Dropdown>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                            {/* <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col> */}
                                            <Col
                                              xl={8}
                                              lg={9}
                                              md={12}
                                              sm={12}
                                              xs={24}
                                              className="bg_FAFAFA br_top_bottom p-2 pb-1"
                                            >
                                              <Row>
                                                <Col
                                                  xl={12}
                                                  lg={12}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Space
                                                    direction="horizontal"
                                                    className="z-1 mr-1 checkin_user_select_kr"
                                                  >
                                                    {/* <Button
                                                      icon={<LeftOutlined />}
                                                      onClick={() => handleTMPrev(teamobjective.id)}
                                                      style={buttonStyleTeam(isPrevAvailableTeam)}
                                                      disabled={!isPrevAvailableTeam}
                                                    /> */}

                                                    {/* <Select
                                                      className="custom-select w-300"
                                                      value={TMselectedOption?.[teamobjective.id] || ''}
                                                      onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                    >
                                                      {teamobjective.keyResultUsersListAssignedTo.map((TMoption) => (
                                                        <label
                                                          key={TMoption.userid}
                                                          className="d-flex align-center"
                                                        >
                                                          <Radio
                                                            value={TMoption.userid}
                                                            checked={TMselectedOption?.[teamobjective.id] === TMoption.userid}
                                                            onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                          >
                                                            <div className="align-center justify-between status_signoff_user ">
                                                              <img
                                                                // src={TMoption.profilePath?FileUrl+TMoption.profilePath:"assets/images/Default_user.svg"}
                                                                src={"assets/images/Default_user.svg"}
                                                                alt={TMoption.username}
                                                                className="mr-1 img_36"
                                                              />
                                                              <span className="mr-1">
                                                                {TMoption.username}
                                                              </span>
                                                            </div>
                                                          </Radio>
                                                        </label>
                                                      ))}
                                                    </Select> */}
                                                    <Select className="custom-select"
                                                      value={TMselectedOption[teamobjective.id]}
                                                    // onChange={(e) => handleOptionChange(e, teamobjective.id)}
                                                    >
                                                      {teamobjective.keyResultUsersListAssignedTo.map((TMoption) => (
                                                        <label key={TMoption.userid} className="d-flex align-center">
                                                          <Radio value={TMoption.userid}
                                                            // checked={TMselectedOption[teamobjective.id] === TMoption.userid}
                                                            checked={TMselectedOption[teamobjective.id] === TMoption.userid}
                                                            onChange={() => handleOptionChange(TMoption.userid, teamobjective.id, teamobjective.objectiveid, teamobjective.uom !== "Custom" ? teamobjective.currentweekiteration : "Custom")}
                                                          >
                                                            <div className="align-center justify-between status_signoff_user">
                                                              <img
                                                                src={"assets/images/Default_user.svg"}
                                                                alt={TMoption.username}
                                                                className="mr-1 img_24"
                                                              />
                                                              <span className="mr-1">
                                                                {TMoption.username}
                                                              </span>
                                                            </div>
                                                          </Radio>
                                                        </label>
                                                      ))}
                                                    </Select>
                                                  </Space>
                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                  <div className="weekoption_checkin c_8080 fw-600 float-right fs-12">
                                                    <p className="m-0">{teamobjective.currentmonth}</p>
                                                    <p className="p-0 m-0 float-right">{teamobjective.currentweekiteration}</p>
                                                  </div>
                                                </Col>
                                              </Row>
                                              {teamobjective.uom !== "Custom" ?
                                                (
                                                  <>
                                                    {teamMemberFrequencyList?.keyResultId === teamobjective.id && teamMemberFrequencyList.userId === userIdTeam && teamMemberFrequencyList.checkInWeek === teamobjective.currentweekiteration &&
                                                      <>
                                                        <Row className="mt-1">
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Achieved</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.achievedTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.achievedTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.achievedTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.achievedTarget + " C")) : (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.achievedTarget : teamMemberFrequencyList?.achievedTarget))}

                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Completed</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.completedTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.completedTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.completedTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.completedTarget + " C")) : (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.completedTarget : teamMemberFrequencyList?.completedTarget))}

                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Balance</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.uomType === "Percentage" ?
                                                                teamMemberFrequencyList?.remainingTarget + " %" :
                                                                (teamMemberFrequencyList?.uomType === "Currency" ? (teamMemberFrequencyList?.amountType === "Thousands" ?
                                                                  " ₹ " + teamMemberFrequencyList?.remainingTarget + " T " :
                                                                  (teamMemberFrequencyList?.amountType === "Lakhs" ?
                                                                    " ₹ " + teamMemberFrequencyList?.remainingTarget + " L " :
                                                                    " ₹ " + teamMemberFrequencyList?.remainingTarget + " C")) : (teamMemberFrequencyList?.amountType === "Numeric" ? teamMemberFrequencyList?.remainingTarget : teamMemberFrequencyList?.remainingTarget))}
                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={24}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            className="kr_new_card justify-end"
                                                          >
                                                            <KRDescription
                                                              objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                              getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                              getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                              objectivelist={objectivelist}
                                                              krid={teamobjective.id}
                                                              krtitle="View Overview"
                                                              objname={objectivekeys.obj_name}
                                                              objid={objectivekeys.obj_id}
                                                              obj_code={objectivekeys.obj_code}
                                                              availableweightagekr={availableweightagekr}
                                                              year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                              objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.keyid}
                                                              checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom} 
                                                              objquarter={objectivekeys.obj_quarter} tabActiveKey="2" selectedkrvalue={selectedKrValue} />
                                                            {/* <p className="text_line c_32B7D9 fs-12 fw-700 p-0 m-0 mt-1">View Overview</p> */}
                                                          </Col>
                                                        </Row>
                                                      </>}
                                                  </>) : (
                                                  <>
                                                    {teamMemberFrequencyList?.keyResultId === teamobjective.id && teamMemberFrequencyList.userId === userIdTeam &&
                                                      <>
                                                        <Row className="mt-1">
                                                          <Col
                                                            xl={8}
                                                            lg={8}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                          >
                                                            <label htmlFor="" className="fs-12 c_8080 fw-300" >Target Status</label>
                                                            <p className="fs-14 fw-700 m-0">
                                                              {teamMemberFrequencyList?.customTarget === 0 ? "Not Completed" : "Completed"}
                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xl={24}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            className="kr_new_card justify-end"
                                                          >
                                                            <KRDescription
                                                              objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                              getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                              getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                              objectivelist={objectivelist}
                                                              krid={teamobjective.id}
                                                              krtitle="View Overview"
                                                              objname={objectivekeys.obj_name}
                                                              objid={objectivekeys.obj_id}
                                                              obj_code={objectivekeys.obj_code}
                                                              availableweightagekr={availableweightagekr}
                                                              year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                              objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.keyid}
                                                              checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom}
                                                              selectedkrvalue={selectedKrValue} 
                                                              objquarter={objectivekeys.obj_quarter}  tabActiveKey="2"/>
                                                          </Col>
                                                        </Row>
                                                      </>}
                                                  </>
                                                )}
                                            </Col>
                                          </Row>
                                        )
                                      ))}
                                    </>
                                  )}
                                  {selectedKrValue === "My Key Results" && (
                                    <>
                                      {objectivesassignedkr.map((objective, index) => (
                                        objective.status != 3 && (
                                          <Row className="kr_card mt-1 mb-2 mr-1 ">
                                            <Col xl={16} lg={15} md={12} sm={12} xs={24} className="pt-1 pb-1 pl-1 pr-5">
                                              <Row justify={"space-between"} className="align-start">
                                                <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                                  <Row>
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/crown.svg" alt="" />
                                                          {objectivekeys.obj_code}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="triangle_left"></span>
                                                        <span className="cup_org_bg align-center">
                                                          <img src="assets/images/KRA-Icon.svg" alt="" />
                                                          {objective.keyid}
                                                        </span>
                                                        <span className="triangle"></span>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                  <div className="">
                                                    {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue} objectivelist={objectivelist} krid={objective.id} krtitle={objective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid} checkInFrequency={objective.checkInFrequency} uomType={objective.uom} objquarter={objectivekeys.obj_quarter} tabActiveKey="1"  />) : (<p className="c-000 fs-16 c_32B7D9">{objective.keytitle}</p>)}
                                                  </div>

                                                  <div className="fs-14 c_8080  threeline_text_overflow">
                                                    {objective.description}
                                                  </div>
                                                </Col>
                                                <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                                  <Row className=" m-0 p-0 justify-end align-center">
                                                    {objectivekeys.revertstatus !== 1 ? (

                                                      objective.status == 2 && getAllLeadMeasure.filter(data => data.krId === objective.id).length == 0 ?
                                                        (<>
                                                          <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                            <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                          </button>
                                                        </>) :
                                                        getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ?
                                                          (<>
                                                            <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                            </button>
                                                          </>
                                                          ) :
                                                          (<>
                                                            <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                              <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                            </button>
                                                          </>
                                                          )


                                                    ) : (
                                                      <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                      </button>
                                                    )}
                                                    {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                      <a onClick={(e) => setKrValues(e, objective)} href="#hgj"
                                                      >
                                                        {localStorage.loginId == objective.createdBy && userSignOff.signOffStatus === 0
                                                          &&
                                                          <Space>
                                                            <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                            <input type="hidden" />
                                                          </Space>
                                                        }
                                                      </a>
                                                    </Dropdown>) : (<></>)}
                                                  </Row>
                                                  {objective.keyResultUsersListAssignedTo.some(user => user.userid === loginUserId) ? (
                                                    objective.keyResultUsersListAssignedTo
                                                      .filter(user => user.userid === loginUserId)
                                                      .map(user => (
                                                        <div key={user.userid}>
                                                          <Row className="obj-progress mt-2">
                                                            <Col xl={22} lg={22} md={22} sm={22}>
                                                              <div className="custom-progress-bar">
                                                                <div className="progress-content">
                                                                  <Progress
                                                                    type="line"
                                                                    percent={(user.totaltarget / user.target) * 100}
                                                                    strokeColor="#00CFFF"
                                                                    trailColor="#E0E0E0"
                                                                    showInfo={false}
                                                                  // strokeWidth={10}

                                                                  />
                                                                  <div className="progress-dots">
                                                                    <Tooltip title="0%" placement="bottom">
                                                                      <span className="dot" style={{ left: '0%' }}></span>
                                                                    </Tooltip>
                                                                    {user.totaltarget > 0 &&
                                                                      user.totaltarget <
                                                                      user.target && (
                                                                        <Tooltip title={`${user.totaltarget}%`} placement="bottom">
                                                                          <span className="dot" style={{
                                                                            left: `calc(${(user.totaltarget / user.target) * 100}% - 4px)`
                                                                          }}></span>
                                                                        </Tooltip>
                                                                      )}
                                                                    <Tooltip title={`${objective.uom === "Percentage" ? user.target : 100}%`} placement="bottom">
                                                                      <span className="dot" style={{ left: '100%' }}></span>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                            <Col xl={2} lg={2} md={2} sm={2}>
                                                            <span className="progress-percentage fs-16">
                                                            {user.totaltarget}%
                                                            </span>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      ))) : (
                                                    <Row className="obj-progress mt-2">
                                                      <Col xl={22} lg={22} md={22} sm={22}>
                                                        <div className="custom-progress-bar">
                                                          <div className="progress-content">
                                                            <Progress
                                                              type="line"
                                                              percent={objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}
                                                              strokeColor="#00CFFF"
                                                              trailColor="#E0E0E0"
                                                              showInfo={false}
                                                            // strokeWidth={10}
                                                            />
                                                            <div className="progress-dots">
                                                              <Tooltip title="Start value: 0%" placement="bottom">
                                                                <span className="dot" style={{ left: '0%' }}></span>
                                                              </Tooltip>
                                                              {/* <span>{teamobjective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[teamobjective.id])?.totaltarget}</span> */}
                                                              {(objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0 > 0) && (objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0 <= 100) ? (
                                                                <>
                                                                  <Tooltip title={`Current value: ${objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}%`} placement="bottom">
                                                                    {objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget != 0 &&
                                                                      <>
                                                                        <span className="dot" style={{ left: `calc(${objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}% - 4px)` }}></span>
                                                                      </>
                                                                    }
                                                                  </Tooltip>
                                                                </>
                                                              ) : ""}
                                                              <Tooltip title={`${objective.uom === "Percentage" ? objective.target : 100}%`} placement="bottom">
                                                                <span className="dot" style={{ left: '100%' }}></span>
                                                              </Tooltip>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                      <Col xl={2} lg={2} md={2} sm={2}>
                                                        <span className="progress-percentage fs-16">{objective.keyResultUsersListAssignedTo.find(data => data.userid == TMselectedOption[objective.id])?.totaltarget ?? 0}%</span>
                                                      </Col>
                                                    </Row>
                                                  )
                                                  }
                                                  <Row className="d-flex align-center justify-end mt-5">
                                                    <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                      <a className="ant-dropdown-link" onClick={() => userKeyResultList(objective.keyResultUsersListAssignedTo, objective.id, objectivetype)}>
                                                        <Space>
                                                          <div className="align-center">
                                                            <img src="assets/images/membercount.svg" alt="" />
                                                            <span className="fw-700 c-000 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}<span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                          </div>
                                                        </Space>
                                                      </a>
                                                    </Dropdown>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                            {/* <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col> */}
                                            <Col
                                              xl={8}
                                              lg={9}
                                              md={12}
                                              sm={12}
                                              xs={24}
                                              className="bg_FAFAFA br_top_bottom p-2 pb-1"
                                            >
                                              <Row className="align-center justify-between">
                                                <Col
                                                  xl={8}
                                                  lg={12}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  {objective.keyResultUsersListAssignedTo.some(data => data.userid == localStorage.getItem('loginId')) &&
                                                    <div className="d-flex  align-center" key={objective.id}>
                                                      <img src="assets/images/user.svg" alt="" className="img_24" />
                                                      <span className="pl-1 fs-14 c_000">{localStorage.getItem('name')}</span>
                                                    </div>
                                                  }
                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                  {objective.keyResultUsersListAssignedTo.some(data => data.userid == localStorage.getItem('loginId')) &&
                                                    <div className="weekoption_checkin c_8080 fw-600 float-right fs-12">
                                                      <p className="m-0">{objective.currentmonth}</p>
                                                      <p className="p-0 m-0 float-right">{objective.currentweekiteration}</p>
                                                    </div>
                                                  }
                                                </Col>
                                              </Row>
                                              <>
                                                <Row className="mt-1">
                                                  <Col
                                                    xl={12}
                                                    lg={12}
                                                    md={24}
                                                    sm={24}
                                                    xs={24}
                                                  >
                                                    <label htmlFor="" className="fs-12 c_8080 fw-300" >
                                                      {objective.uom !== "Custom" ? "Target Balance" : "Target Status"}
                                                    </label>
                                                    {objective.keyResultUsersListAssignedTo
                                                    .filter(user => user.userid === loginUserId)
                                                    .map(user => (
                                                    <p className="fs-14 fw-700 m-0">
                                                      {objective.uom === "Percentage" ?
                                                        (user.target - user.totaltarget) + " %" :
                                                        (objective.uom === "Currency" ? (objective.amounttype === "Thousands" ?
                                                          " ₹ " + (user.target - user.targetgiven) + " T " :
                                                          (objective.amounttype === "Lakhs" ?
                                                            " ₹ " + (user.target - user.targetgiven) + " L " :
                                                            " ₹ " + (user.target - user.targetgiven) + " C")) : (objective.uom === "Numeric" ? (user.targetgiven) :
                                                              (objective.checkInValue === 0 ? "Not Completed" : "Completed")
                                                        ))}
                                                    </ p>
                                                    ))}
                                                  </Col>
                                                  <Col
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    className=""
                                                  >
                                                    <p className="fs-12 c_8080 p-0 m-0 mt-1 float-right">Checkin for Week {objective.currentweekiteration} is Open</p>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col
                                                    xl={24}
                                                    lg={24}
                                                    md={24}
                                                    sm={24}
                                                    className=" kr_new_card justify-end"
                                                  >
                                                   {objective.status==2&& 
                                                    <KRDescription
                                                      objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }}
                                                      getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)}
                                                      getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)}
                                                      objectivelist={objectivelist}
                                                      krid={objective.id}
                                                      krtitle="Checkin"
                                                      objname={objectivekeys.obj_name}
                                                      objid={objectivekeys.obj_id}
                                                      obj_code={objectivekeys.obj_code}
                                                      availableweightagekr={availableweightagekr}
                                                      year={objectivekeys.year} orgdescription={objectivekeys.obj_desc}
                                                      objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid}
                                                      checkInFrequency={objective.checkInFrequency} uomType={objective.uom} 
                                                      selectedkrvalue={selectedKrValue}
                                                      objquarter={objectivekeys.obj_quarter} tabActiveKey="2"/>
                                                   }
                                                    {/* <p className="text_line c_32B7D9 fs-12 fw-700 p-0 m-0 mt-1">View Overview</p> */}
                                                  </Col>
                                                </Row>
                                              </>
                                            </Col>
                                          </Row>
                                        )
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                        </div>
                      </>
                    )}
                  </>
                )}
                <Modal
                  title=""
                  centered
                  open={showkrModal}
                  // onClose={false}
                  closeIcon={<CloseOutlined style={{ display: 'none' }} />} // Hide the close button
                  footer={null}
                  className="p-0 modal-close-out"
                >
                  <p id="modal-modal-title m-0" >
                    <button className="btn btn-close" onClick={() => handlekrClose()}>
                      <CloseOutlined />
                    </button>
                  </p>
                  <div className="krmodal_scroll">
                    {objectivesassignedkr.map((objective, index) => (
                      objective.status == 3 && (

                        <Row className="p-1 border_ddd mb-2 br_5">
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row>
                              <Col xl={18} lg={18} md={18} sm={18} xs={24}>
                                <Flex>
                                  <div className="container_label">
                                    <div className="wrapper_label align-center mb-1">
                                      <span className="cup_org_bg align-center">
                                        <img src="assets/images/KRA-Icon.svg" alt="" />
                                        {objective.keyid}
                                      </span>
                                      <span className="triangle"></span>
                                    </div>
                                  </div>
                                </Flex>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={24}
                                className="align-center justify-end"
                              >
                                <img src="assets/images/crown.svg" alt="" />
                                <span className="ml-1">{objective.objectivecode}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <p className="fs-16 c-000 m-0 cur-pointer keytitle_scroll" onClick={() => { krshowDrawer(objective) }}>
                                  {objective.keytitle}
                                </p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={24}
                                    className="align-center mt-1 "
                                  >
                                    <span className="mr-1">Created by</span>
                                    <Tooltip placement="top" title={objective.createdbyempname} arrow={true}>
                                      <img
                                        src={objective.createdByEmpProfilePath ? FileUrl + objective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                        onError={handleImageError}
                                        // src="assets/images/user.svg"
                                        className="kr_user"
                                        alt=""
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={24}
                                    className="align-center mt-1 justify-end"
                                  >
                                    <span className="mr-1">Cascaded to</span>
                                    {objective.keyResultUsersListAssignedTo.length > 2 ? (
                                      <>
                                        <Avatar.Group
                                          maxCount={2}
                                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                        >
                                          {objective.keyResultUsersListAssignedTo.slice(0, objective.keyResultUsersListAssignedTo.length).map((user: keyResultUsers, index) => (
                                            <>
                                              <Tooltip placement="top" title={user.username} arrow={true}>
                                                <Avatar src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} />
                                              </Tooltip>
                                            </>
                                          ))}
                                        </Avatar.Group>
                                      </>
                                    ) : (
                                      objective.keyResultUsersListAssignedTo.map((user: keyResultUsers, index) => (
                                        <Tooltip placement="top" title={user.username} arrow={true}>
                                          <Avatar
                                            src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                            className="avatar"
                                            key={index}
                                          />
                                        </Tooltip>
                                      ))
                                    )
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="align-center">
                              <Col
                                xl={7}
                                lg={7}
                                md={8}
                                sm={8}
                                xs={24}
                                className="align-center mt-1"
                              >
                                <img src="assets/images/date-picker1.svg" alt="" />
                                <span className="ml-1">{objective.quarter} {objectivekeys.year}</span>
                              </Col>
                              <Col
                                xl={10}
                                lg={10}
                                md={8}
                                sm={8}
                                xs={24} className="mt-1">
                                <DeleteOutlined className="c_FF4747 pr-1 fs-18" />
                                <span className="c_FF4747 mt-1">{objective.deleteddate}</span> &nbsp;&nbsp;&nbsp;
                                <span className="c_FF4747 mt-1">{objective.deletedtime}</span>
                              </Col>
                              <Col
                                xl={7}
                                lg={7}
                                md={8}
                                sm={8}
                                xs={24}
                                className="mt-1 justify-end"
                              >
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    ))}
                  </div>
                </Modal>
              </div >
            </>
          ) : (<MyObjWeightage setReviewCascade={setReviewCascade} />)
          }
        </>) : !krreviewstatus ? (<>
          <ReviewALLKRWeightage setReviewKR={setkrreviewstatus} objid={objectivekeys.obj_id} />
        </>) :
          (<>
            <ReviewKRWeightage setReviewKR={setReviewKR} objid={objectivekeys.obj_id} />
          </>)}
    </>
  )
}
export default MyObjective