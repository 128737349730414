import { Tabs } from 'antd';
import React from 'react'
import EmployeeObjectiveAssignedBy from './EmployeeObjectiveAssignedBy';
import EmployeeObjectiveAssignedTo from './EmployeeObjectiveAssignedTo';

const { TabPane } = Tabs;

const EmployeeObjective: React.FC = () => {





  return (
    <>
      <div className='mt-3'>
        <Tabs>
          <TabPane tab="Employee Objective Assigned By" key="1">
            <div className="non-absolute">
              <EmployeeObjectiveAssignedBy />
            </div>
          </TabPane>
          <TabPane tab="Employee Objective Assigned To" key="2">
            <div className="non-absolute">
              <EmployeeObjectiveAssignedTo />
            </div>
          </TabPane>

        </Tabs>
      </div>
    </>
  )
}

export default EmployeeObjective;