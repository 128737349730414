import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { Row, Col, Form, Input,message } from "antd";
import axios from 'axios';
import { BaseUrl } from "../../App";
import "./LoginPage.css";
import Password from "antd/es/input/Password";
import { Link } from 'react-router-dom';

const onFinish = (values: any) => {
  // console.log("Success:", values);
};

const onFinishFailed = (errorInfo: any) => {
  // console.log("Failed:", errorInfo);
};

interface LoginPageProps {
  onLogin: (useremail: string, password: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [authData, setAuthData] = useState({ useremail: "", password: "" });
  const [errors, setErrors] = useState({ useremail: "", password: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const adminLoginString = localStorage.getItem("authenticated");
  const adminLogin = adminLoginString ? JSON.parse(adminLoginString) : null;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  
  const navigate = useNavigate();
  let   history = useNavigate();

  const users = [
    { useremail: "user1@hepl.com", password: "pass1" },
    { useremail: "user2@hepl.com", password: "pass2" },
    { useremail: "admin@cavininfo.com", password: "123" },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAuthData((prevAuthData) => ({ ...prevAuthData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleLogin = async() => {
    const newErrors = { useremail: "", password: "" };

    if (!authData.useremail) {
      newErrors.useremail = "useremail is required";
    }

    if (!authData.password) {
      newErrors.password = "Password is required";
    }

    if (!newErrors.useremail && !newErrors.password) {
      try {
        const formData = new FormData();
        if(authData.useremail.endsWith(".com")) {
          formData.append("email",authData.useremail);
        } else {
          formData.append("username",authData.useremail);
        }
        formData.append("password",authData.password);        
        const response = await axios.post(BaseUrl + "api/auth/signin", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if(response.data.rolename=="Admin")
            {
            navigate("/userlist");
            }else{
              if(response.data.firstLoggedStatus===0||response.data.firstLoggedStatus==="0"){
                navigate("/change_password");
              }else{
                navigate("/objective");
              }
            }
            onLogin(authData.useremail, authData.password);
            // navigate("/objective");
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("loginId", (response.data.id));
            localStorage.setItem("rolename", (response.data.rolename));
            localStorage.setItem("email", (response.data.email));
            localStorage.setItem("userid",response.data.uniquenumericId);
            localStorage.setItem("name",response.data.name);
            localStorage.setItem("profilePath",response.data.profilePath);
            localStorage.setItem("designation",response.data.designation);
            localStorage.setItem("adminstatus",response.data.adminstatus);
            localStorage.setItem("refreshtoken",response.data.refreshToken);
            localStorage.setItem("firstLoggedStatus",response.data.firstLoggedStatus);
            return response;
          })
        // Handle the response and other logic
      } catch (e) {
        newErrors.password = "Invalid username or password. Please retry !";
        // console.log("errors",errors)
      }
    }
    setErrors(newErrors);
  };
  const handlemicrosoftLogin = async() => {
    try {
      const response = await axios.get(BaseUrl + "api/auth/login", {
      })
      .then(function (response) {
        const url = response.data;
        window.location.href = url;
      });
      // Handle the response and other logic
    } catch (e) {
      // console.log("errors",errors)
    }
  };
  const[quarter,setquarter] = useState('')
  const [year,setyear] = useState();
  const getcurrentyear = async () => {
    try {
      const apiUrl = BaseUrl + `api/organization/getCycle`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          localStorage.setItem("year",response.data)
          setyear(response.data);
          window.dispatchEvent(new Event('storage'));
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  const getcurrentQuarter = async () => {
    try {
      const apiUrl = BaseUrl + `api/organization/getcurrentquarter`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          if(localStorage.getItem("Quarter")==null){
            localStorage.setItem("Quarter",response.data.data)
            setquarter("Q1")
            window.dispatchEvent(new Event('storage'));
          }
          
          // localStorage.setItem("Quarter",value);
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  useEffect(() => {
    try{
     
    const url = window.location.href;
    const urlParams:any = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const username = urlParams.get('username');
    const Message = urlParams.get('message');
    const accessToken = urlParams.get('accessToken');
    if(localStorage.getItem("code")=="500"){
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              Mail Not Available
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      localStorage.removeItem("code");
    }
    // console.log('code',code);
    getcurrentQuarter();
    getcurrentyear();
    if(code!== null&&code!=500){ 
      if(urlParams.get('firstLoggedStatus') === null){
        navigate("/")
      }else{
      onLogin(username, accessToken);
      window.location.href ="/";
      localStorage.setItem("accessToken", urlParams.get('accessToken'));
      localStorage.setItem("username", urlParams.get('username'));
      localStorage.setItem("loginId", urlParams.get('id'));
      localStorage.setItem("rolename", urlParams.get('rolename'));
      localStorage.setItem("email", urlParams.get('email'));
      localStorage.setItem("userid",urlParams.get('uniquenumericId'));
      localStorage.setItem("name",urlParams.get('name'));
      localStorage.setItem("profilePath",urlParams.get('profilePath'));
      localStorage.setItem("designation",urlParams.get('designation'));
      localStorage.setItem("adminstatus",urlParams.get('adminstatus'));
      localStorage.setItem("refreshtoken",urlParams.get('refreshToken'));
      localStorage.setItem("firstLoggedStatus",urlParams.get('firstLoggedStatus'));
      }
    }
    else if(code==500){
          localStorage.setItem('code',"500");
          const urlObject = new URL(window.location.href);
          urlObject.searchParams.delete('code');
          urlObject.searchParams.delete('message');
          const cleanedUrl = urlObject.toString();
          window.location.href = cleanedUrl;
          
          
            
    }
    }catch(e){
      // console.log(e,'error');
    }
    if (adminLogin === true) {
      history("/objective");
    }
  }
    
 
  ,[navigate,adminLogin,history]);

  return (
    <Row className="login_bg">
      <Col
        xl={15}
        lg={14}
        md={12}
        sm={24}
        xs={24}
        className="d-sm-none left-bg"
      >
      </Col>
      <Col
        xl={9}
        lg={10}
        md={12}
        sm={24}
        xs={24}
        className="right-bg-container"
      >
        <div className="right-bg" >
          <div className="mt-5">
          <div className="text-center ">
          {/* <span className="c_47E0FF fs-48 fw-700 text-center"> Goal <span className="c-000">Sync</span></span> */}
          <img src="assets/images/GS_full_logo.svg" alt="goalsync" /> <br />
          {/* <span className="c_47E0FF fs-48 fw-700 text-center">Welcome</span> */}
          <p className="c-000 font_ReadexPro fw-300 fs-20 text-center mb-3">
            Please Sign In to continue
          </p>
          </div>
          <Form
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className=" mt-5 pt-2 mb-1 ml-5 mr-5 pl-5 pr-5 pm-0 mm-0"
          >
            <div className="">
              <div>
              {/* <label htmlFor="" className="fw-600 fs-14">Email / Mobile Number</label> */}
              <label htmlFor="" className="fw-600 fs-14">Email</label>
              <Input
                type="text"
                name="useremail"
                placeholder="Enter Email/Mobile Number"
                value={authData.useremail.trim()}
                onChange={handleInputChange}
                className="mt-1"
              />
              {errors.useremail && (
                <p className="m-0 text-start mt-1" style={{ color: "red" }}>
                  {errors.useremail}
                </p>
              )}
              </div>
              
              <div className="position-relative mt-3">
              <label htmlFor="" className="fw-600 fs-14">Password</label>
              <Input
                type={passwordVisible ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={authData.password.trim()}
                onChange={handleInputChange}
                className="mt-1"
              />
               <img
                  src={passwordVisible ? 'assets/images/visible.svg' : 'assets/images/invisible.svg'}
                  alt={passwordVisible ? 'Invisible' : 'Visible'}
                  onClick={togglePasswordVisibility}
                  className="visible_icon"
                />
              {errors.password && (
                <p className="m-0 text-start mt-1" style={{ color: "red" }}>
                  {errors.password}
                </p>
              )}
              </div>
              <p> <Link className="fw-600 c_47E0FF float-right text_line fs-14" to="/forgetPassword">Forgot Password</Link></p>
            </div>

            <Form.Item className="text-center mt-5">
              <button className="cyan_button w-80 mt-2 mb-1" onClick={handleLogin}>
                Sign In
              </button>
              <p className="fw-600 m-0">Or</p>
              <button className="micro_btn w-80 mt-1 cur-pointer" onClick={handlemicrosoftLogin}>
                <img
                  src="assets/images/logos_microsoft-icon.svg"
                  alt=""
                  className="mr-2"
                />{" "}
                <span className="fw-600">Sign In with Microsoft</span>
              </button>
            </Form.Item>
          </Form>
        
          </div>
          <div className="text-center">
          <p className="Copyright text-center fs-12 m-0 mb-1">
            Copyright © 2024 CITPL. All right reserved.
          </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
