import React, { useEffect, useState } from "react";
import { Table, Tag, Space, Menu, Divider, Dropdown, message, Spin } from "antd";
import TeamApprasisalEdit from "./TeamAppraisalEdit";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";

interface DataType {
  key: string;
  userId: string;
  avatar: string;
  employeeRatingCount: number | null | undefined;
  employeeRating:string  | null| undefined;
  managerRating: string | null| undefined;
  reviewerRating: string| null | undefined;
  employeeStatus: number | null| undefined;
  managerStatus:number | null| undefined;
  reviewerStatus:number | null | undefined;
  action: number | null | undefined;
  employeeid:string;
}
interface User {
  id: string;
  name: string;
  email: string;
  profilePath: string;
  signoffStatus: number;
  designation: string;
}
interface ApiResponse {
  divisonName: string;
  id: string;
  username: string;
  name: string;
  email: string;
  supervisor_id: string;
  rolename: string;
  profilePath: string | null;
  employeeid: string;
  designation: string;
  payrollStatus: string;
  signoffStatus: number;
  self_status?: number | null| undefined;
  supervisor_status?: number| null | undefined;
  reviewer_status?: number| null | undefined;
  selfRating?: string | null| undefined;
  supervisorRating?: string | null| undefined;
  reviewerRating?: string | null | undefined;
}

const TeamAppraisal: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<DataType[]>([]);
  const [selected, setEditData] = useState<DataType | null>(null);

  const handleEdit = (record: DataType) => {
    setEditData(record);

    setEdit(!edit);
  };
  const handleView = (record: DataType) => {
    setEditData(record);
    setEdit(!edit);
  };
  const [signoffdropdown, setSignOffDropDown] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const userLists = async () => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem('loginId')}/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem('loginId')}/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const transformedData = transformData(response.data);
      setSelectedData(transformedData);
      setLoading(false);
      // console.log("API Response:", response.data);
      // console.log(response.data.length);
    } catch (err) {
      // console.error("Error fetching user list:", err);
    }
  };


  const transformData = (data: ApiResponse[]): DataType[] => {
    return data.map((item, index) => ({
      key: (index + 1).toString(),
      userId: `${item.name} / ${item.divisonName.split('-').slice(0, 2).join(' ')} / ${item.payrollStatus}`,
      avatar: item.profilePath
      ? FileUrl + item.profilePath
      : "assets/images/Default_user.svg",
      employeeRatingCount: item.self_status,
      employeeRating: item.selfRating,
      managerRating: item.supervisorRating,
      reviewerRating: item.reviewerRating,
      employeeStatus: item.self_status,
      managerStatus: item.supervisor_status,
      reviewerStatus: item.reviewer_status,
      action: item.self_status,
      employeeid:item.employeeid
    }));
  };
  const columns = [
    {
      title: "Team Members",
      dataIndex: "userId",
      key: "userId",
      render: (text: any, record: any) => (
        <Space>
          <img src={record.avatar} alt="avatar" style={{ width: 30, borderRadius: '50%' }} />
          {/* <img
            src="assets/images/user.svg"
            alt="avatar"
            style={{ width: 30, borderRadius: "50%" }}
          /> */}
          <span>{record.userId}</span>
        </Space>
      ),
    },
    {
      title: "Employee Rating",
      dataIndex: "employeeRating",
      key: "employeeRating",
      render: (text: any, record: any) => (
        <span>
          {record.employeeStatus <= 1 ? (
            <>
              <span className="emp_not_Submitted">NOT SUBMITTED</span>{" "}
            </>
          ) : (
            <>
                <p className="c_000 fs-14">
                {record.employeeRating}
                </p>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Manager Rating",
      dataIndex: "managerRating",
      key: "managerRating",
      render: (text: any, record: any) => (
        <span
          className={
            record.employeeStatus === 2 &&  record.managerStatus <= 1
              ? "appraisal_pending"
              : ""
          }
        >
          { record.employeeStatus === 2 &&  record.managerStatus <= 1 ? (
            "Appraisal Pending"
          ) : (
            <>
              {record.managerStatus === 2 ? (
                <>{record.managerRating} </>
              ) : (
                <>
                    <p className="c_000 fs-14">
                      -
                    </p>
                </>
              )}
            </>
          )}
        </span>
      ),
    },
    {
      title: "Reviewer Rating",
      dataIndex: "reviewerRating",
      key: "reviewerRating",
      render: (text: any, record: any) => (
        <span
          className={
            record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus <= 1
              ? "appraisal_pending"
              : ""
          }
        >
          {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus <= 1? (
            "Appraisal Pending"
          ) : (
            <>
              {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus === 2 ? (
                <>{record.reviewerRating} </>
              ) : (
                <>
                    <p className="c_000 fs-14">
                      -
                    </p>
                </>
              )}
            </>
          )}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.employeeStatus === 2 && (record.managerStatus === 0 || record.managerStatus === 1)  && (
            <>
              <div onClick={() => handleEdit(record)}>
                <img
                  src="assets/images/edit.svg"
                  alt=""
                  className="cur-pointer"
                />
              </div>
            </>
          )}
          {record.employeeStatus === 2 && record.managerStatus === 2 && (
            <>
             <img
              src="assets/images/view.svg"
              alt=""
              onClick={() => handleView(record)}
              className="cur-pointer"
            />
            </>
          )}
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      userId: "Anup Nandhan / Product / CITPL",
      avatar: "url-to-avatar1",
      employeeRatingCount: "75",
      employeeRating: "Strong Performer 💪🏻",
      managerRating: "APPRAISAL PENDING",
      reviewerRating: "",
      action: "APPRAISAL PENDING",
    },
    {
      key: "2",
      userId: "divya / Product / CITPL",
      avatar: "url-to-avatar2",
      employeeRatingCount: "75",
      employeeRating: "Exceed Expectations 🎖️",
      managerRating: "Exceeds Expectation",
      reviewerRating: "",
      action: "",
    },
    {
      key: "3",
      userId: "Vennila / Product / CITPL",
      avatar: "url-to-avatar3",
      employeeRatingCount: "1",
      employeeRating: "Unsatisfactory Performance😔",
      managerRating: "5 - Exceeds Expectation",
      reviewerRating: "5 - Exceeds Expectation",
      action: "",
    },
    {
      key: "4",
      userId: "Harish / Product / CITPL",
      avatar: "url-to-avatar4",
      employeeRatingCount: "",
      employeeRating: "",
      managerRating: "",
      reviewerRating: "",
      action: "",
    },
  ];

  const appraisalItems = (
    <Menu className="SignOff_dropdown">
      <Menu.Item>
        <div className="d-flex align-center w-100">
        <img
            src="assets/images/RequestPending.svg"
            alt=""
            className="WH_16"
          />
        <span className="fs-14 pl-1"> Appraisal Pending</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
        <img
          src="assets/images/Signoff_Completed.svg"
          alt=""
          className="WH_16"
        />
       <span className="fs-14 pl-1">Appraisal Completed</span> 
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
        <img
          src="assets/images/NotRequested.svg"
          alt=""
          className="WH_16"
        />
        <span className="fs-14 pl-1"> Not Submitted</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year,setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    userLists();
  }, []);
  useEffect(() => {
    
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
      userLists();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  

  return (
    <>
      {!edit ? (
        <>
          <div className="permission_btn ">
            <div className="justify-end align-center z-1">
            <div className=" appraisal_search z-1">
              <img src="assets/images/search.svg" alt="" className="appraisal_search" />
              <input type="search" name="" id="" placeholder="Search Team Members" />
            </div>
              <Dropdown
                overlay={appraisalItems}
                trigger={["click"]}
                className="info_sign_off"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div className="p-1 ">
                      <img
                        src="assets/images/Non-categorized.svg"
                        alt=""
                        className="cur-pointer"
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div
              className="appraisal team_appraisal_table"
              style={{ textAlign: "center" }}
            >
            {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
              <div className="content" />
            </Spin>}
              <Table
                columns={columns}
                dataSource={selectedData}
                pagination={false}
                className=""
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <TeamApprasisalEdit
            userId={selected?.userId || ""}
            employeeRating={selected?.employeeRating?.toString() || "NOT SUBMITTED"}
            employeeRatingCount={selected?.employeeRatingCount?.toString() || ""}
            employeeId={selected?.employeeid || ""}
          />
        </>
      )}
    </>
  );
};

export default TeamAppraisal;
