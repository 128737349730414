import isBearerTokenExpired from './isBearerTokenExpired';
import { BaseUrl, FileUrl } from "./App";
import axios from "axios";


const handleLogout = async() => {
    
    // alert(12)
   // localStorage.clear();
    localStorage.removeItem('authenticated');
    localStorage.clear();
    localStorage.setItem("authenticated", "false");
   // localStorage.removeItem('authenticated');
    try {
     await axios.get(BaseUrl + "api/auth/signout");
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
    window.location.href = "/";
    // window.location.reload();
  };
const overallCheck = ():void => {
    const tokenExpired = isBearerTokenExpired();
    if (tokenExpired) {

        handleLogout();
      //console.log();
      // Token is expired and not on the login page, redirect to login page
      // You can use Link to navigate instead of history.push
      //onLogout();
    }
  };

//   RefreshToken();
  
  //const id = setInterval(overallCheck, 10000);
//   setIntervalId(id);
//   return () => clearInterval(id);

  export default overallCheck;