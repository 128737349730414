import React, {useEffect,useState,MouseEventHandler } from 'react';
import { Tabs } from 'antd';
import MyAppraisal from './MyAppraisal';
import TeamAppraisal from './TeamAppraisal';
import Review from './Review';
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
import overallCheck from "../../../overallCheck";
import { any } from 'zod';
const { TabPane } = Tabs;


const Appraisal: React.FC = () => {

  const rolename = localStorage.getItem("rolename");

  let viewButton = true;

  if(rolename === "BH") {
    viewButton = false;
  } 

  const [showAddObjButton, setShowAddObjButton] = useState(viewButton);
  const [showOrgObjButton, setShowOrgObjButton] = useState(false);
  const [reloadCFT, setReloadCFT] = useState(false); 
  const [reloadObj, setReloadObj] = useState(false);
  const [reviewstatus,setreviewstatus]  = useState<boolean>()
  const [activeKeyObj, setActiveKeyObj] = useState<string>(() => localStorage.getItem('activeObjTab') || '1');
  const [reviewdata,setreviewdatasize] = useState<Number>(0);
  const [isReview, setIsReview] = useState(localStorage.getItem('review') == "true");
  const onChange = (key: string) => {
    setActiveKeyObj(key);
    localStorage.setItem('activeTab', key);
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
    
  };

  
  // const onChange = (key: string) => {
  //   // console.log('Tab Changed to:', key);
  //   setActiveKey(key);
    
  // };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    RefreshToken();
  }, []);
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)  
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }

  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [hasNonEmptyReportees, setHasNonEmptyReportees] = useState<boolean>(false);
  const userLists = async () => {
    try {
      const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem('loginId')}/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

     // const transformedData = transformData(response.data);
      setSelectedData(response.data);
      const someResult = response.data.some((item : any) => item.reportee.length !== 0);
    setHasNonEmptyReportees(someResult);
      // console.log("API Response:", response.data);
      // console.log(response.data.length);
    } catch (err) {
      // console.error("Error fetching user list:", err);
    }
  };

  useEffect(() => {
    userLists();
  }, []);
  
  return (
    <div className='relative objective_tab'>
    
      <Tabs 
      activeKey={activeKeyObj}
      onChange={onChange} 
      className='objectivetabs-container sub_tab team_appraisal'>
      
        <TabPane
          tab={
            <div>
              <img src="assets/images/crown.svg" alt="Crown" />
              <span>My Appraisal</span>
            </div>
          }
          key="1"
        >
          <div>
            <MyAppraisal  />
          </div>
        </TabPane>
        {selectedData.length !== 0 ? (<>
          <TabPane
          tab={
            <div >
            <img src="assets/images/people.svg" alt="CFT" />
            <span>Team Appraisal</span>
          </div>
          }
          key="2"
        >
          <TeamAppraisal />
        </TabPane>
        </>) : (<></>)}
        {/* <TabPane
          tab={
            <div >
              <img src="assets/images/people.svg" alt="CFT" />
              <span>Team Appraisal</span>
            </div>
          }
          key="2"
        >
          <TeamAppraisal />
        </TabPane> */}
        {selectedData.length !== 0 && hasNonEmptyReportees ? (<>
        <TabPane
          tab={
            <div>
              <img src="assets/images/review_appraisal.svg" alt="review_appraisal" />
              <span>Review</span>
            </div>
          }
          key="3"
        >
         <Review /> 
        </TabPane>
        </>) : (<></>)}
       </Tabs>
      </div>
      
  );
};


export default Appraisal;
