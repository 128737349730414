import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Space, Modal, message, Flex, Spin, Avatar, Tooltip, MenuProps, Row, Col, Card, Input ,Menu} from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import CreateObj from "../../Modal/CreateObj";
import EditObj from "../../Modal/EditObj";
import axios from "axios";
import { BaseUrl } from "../../../../App";
import { FileUrl } from "../../../../App";
import CreateParentObj from "../../Modal/CreateParentObj";
import overallCheck from "../../../../overallCheck";
import { count } from "console";
import { StopOutlined } from "@ant-design/icons";
const OrgObjectives: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };

  interface orgGroup {
    fieldName: string;
    id: string;
  } 
  // =================Roles And Permission==============================
  const [permission_orgobjview, setPermission_orgobjview] = useState('');
  const [permission_orgobjedit, setPermission_orgobjedit] = useState('');
  const [permission_orgobjcreate, setPermission_orgobjcreate] = useState('');
  const [permission_orgobjdelete, setPermission_orgobjdelete] = useState('');
  const [orgGroupList, setOrgGroupList] = useState<orgGroup[]>([]);
  const [orgGroupselectedOption, setOrgGroupSelectedOption] = useState<string>("");

  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_orgobjview(permission.org_objective);
        }
        if (permission.menu === "Edit") {
          setPermission_orgobjedit(permission.org_objective);
        }
        if (permission.menu === "Delete") {
          setPermission_orgobjdelete(permission.org_objective);
        }
        if(permission.menu === "Create"){
          setPermission_orgobjcreate(permission.org_objective);
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // =================Roles And Permission==============================
  const [orgobjid, setorgobjid] = useState<String | null>(null);

  const onClose = () => {
    setOpen(false);
  };
  const style: React.CSSProperties = { background: "#fff" };
  const handleDelete = () => {
    Modal.confirm({
      title: "Do you want to delete this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {selectedOrganizationObjective?.objname}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteOrganizationObjective();
        // console.log("Delete action confirmed");
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        Orgobjectivelist();
      },
      onCancel() {
        // console.log("Delete action cancelled");
      },
    });
  };

  const profilePath = localStorage.getItem("profilePath");
  const user = localStorage.getItem("name");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const [orgobjidnew, setorgobjidnew] = useState<String | null>(null);

  const [selectedOrganizationObjective, setSelectedOrganizationObjective] = useState<objcetiveuselist>();



  // const items: MenuProps["items"] = [
  //   {
  //     label: (
  //       <button className="border-0 bg-transparent fs-14 align-center">
  //         <EditObj itemId={orgobjid} objectiveName={""} />
  //       </button>
  //     ),
  //     key: "0",
  //   },
  //   {
  //     label: (
  //       <button
  //         className="border-0 bg-transparent c_FF4747 fs-14 align-center"
  //         onClick={handleDelete}
  //       >
  //         <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
  //         Delete
  //       </button>
  //     ),
  //     key: "1",
  //   },
  // ];
  const [editable, setEditable] = useState(false);
  const [editSave, setEditSave] = useState(false);

  const [text, setText] = useState(
    "“We shall create a sustainable global business and consistently deliver outstanding value to all stakeholders“"
  );

  const handleEdit = () => {
    setEditable(true);
  };
  const handleEditSave = () => {
    setEditSave(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };
  const handleSubmit = () => {
    setEditable(false);
  };

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const handleCancel = () => {
    setEditable(false);
  };
  type getallassignedlist = {
    id: string,
    name: string,
    uid: number,
    year: string,
    enddate: string
    quarter: string,
    description: string,
    remark: string,
    organizationStatus: string,
    createdBy: string,
    createdByEmpId: string,
    createdAt: string,
    updatedAt: string | null,
    startDate: string | null,
    objectivename: string,
    orgObjectiveUsersListList: string[],
  }
  type objectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: ""

  }
  type objcetiveuselist = {
    objname: string,
    objcode: string,
    createdbyname: string,
    createdByProfile: string,
    quartervalue: string,
    orgobjid: string,
    // mapped_obj: objectiveuser[],
    // mapped_obj_count: string,
    objid: string,
    description: string,
    organizationStatus: string,
    assignedorgobj: BHAssignObjectiveUserList[],
    year: string,
    userIdsList: string[],
    revertstatus: number | null
  }

  type assigndetails = {
    name: string,
    cftstatus: number
    profilePath: string
    userid: string,
    weightage: number,
    availableweightage: number
  }

  type BHAssignObjectiveUserList = {
    id: string,
    orgObjectiveId: string,
    orgObjectiveUId: string,
    userUId: string,
    empId: string,
    userId: string,
    weightage: string,
    objectiveName: string,
    createdByUserName: string,
  }

  const [Orgobjectiveuserslist, setorgobjectiveuserlist] = useState<objcetiveuselist[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      createdByProfile: "",
      quartervalue: "",
      orgobjid: "",
      // mapped_obj: [],
      //mapped_obj_count: "",
      objid: "",
      description: "",
      organizationStatus: "",
      assignedorgobj: [],
      year: "",
      userIdsList: [],
      revertstatus: null
    }
  ]);

  const rolename = localStorage.getItem("rolename");
  let viewButton = true;
  if (rolename === "BH" || rolename === "FH") {
    viewButton = false;
  }

  const handleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {selectedOrganizationObjective?.objname}
          </p>
          <p className="fs-14">
            <b>Note : </b> {text} objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/organization/status/orgobjrevoke`, {
            "objectiveid": "",
            "status": revertstatus,
            "orgObjectiveId": selectedOrganizationObjective?.orgobjid,
            "masterobjid": "",
            "userId": localStorage.getItem('loginId'),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });

            }
            Orgobjectivelist()
            bhCreateOrganization()
            // objectivelist();
            setTimeout(() => {
              // window.location.reload();
            }
              , 2000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          // Handle network errors or other exceptions
          // console.error("Error:", error);
          // For example, show an error message
          message.error("An error occurred");
        }
      },
    });


  };
  const [missiontext, setMissionText] = useState();

  const [allorgobjectivelist, setobjectivelist] = useState<getallassignedlist[]>([]);
  const orgobjectiveslist = async () => {
    const apiurl = BaseUrl + `api/organization/getall`;
    await axios.get(apiurl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      setobjectivelist(response.data);
    }).catch((error) => {
      // console.log('Error', error);
    });
  }


  const deleteOrganizationObjective = async () => {

    const apiUrl = BaseUrl + `api/organization/delete/${orgobjidnew}`;

    await axios.delete(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`

      }
    }).then((response) => {
      Orgobjectivelist();

      // console.log(response.data.data);
    })
  }
  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year,setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const Orgobjectivelist = async () => {
    const token = localStorage.accessToken;
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;

    // Check if userObject is not null before accessing its properties
    if (userObject) {
      useruniqueid = localStorage.loginId;
    } else {
      // console.error("User information not found in localStorage");
    }
    setorgobjectiveuserlist([]);
    // const formData = new FormData();
    // formData.append("userid", useruniqueid);
    // try {
    //   const response = await axios.post(
    //     BaseUrl + "api/organization/getassignedobjectivelist",
    //     formData
    //   );
    //const apiUrl = BaseUrl + `api/organization/bh/objectives/${roleId}`;
    const apiUrl = BaseUrl + `api/organization/getall`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {

      setorgobjectiveuserlist((prevList) => [
        ...(response.data.data as any[]).map((item: any) => {
          isVisible.push(false);

          // const mapped_obj = (item.objDetails as any[]).map((detailItem: any) => ({
          //   // Extract the specific values needed from each detailItem
          //   obj_name: detailItem.name,
          //   obj_code: detailItem.objectivecode,
          //   obj_desc: detailItem.description,
          //   obj_quarter: detailItem.quarter,

          //   // Add other properties as needed
          // }));

          return {
            objname: item.objectiveName,
            objcode: item.objectCode,
            createdbyname: item.createdByUserName,
            createdByProfile: item.createdByUserProfilePath,
            status: item.organizationStatus,
            quartervalue: item.quarter,
            orgobjid: item.id,
            //  mapped_obj_count: item.objcount,
            //  mapped_obj: mapped_obj,
            objid: "",
            description: item.description,
            organizationStatus: item.organizationStatus,
            assignedorgobj: item.orgObjectiveUsersList,
            year: item.year,
            userIdsList: item.loginUserId,
            revertstatus: item.revokestatus
          };
        }),
      ]);
      //  setBhOrganizationList(response.data.data);
      setLoading(false)
    }).catch((error) => {
      // console.log('Error :::::::::::::::::', error);

    })


    // setobjectiveuserlist(response.data.body); 

    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const items: MenuProps["items"] = [
    // selectedOrganizationObjective?.createdbyname === user ?
    permission_orgobjedit ? {

      label: (
        <EditObj orgObjList={Orgobjectivelist} itemId={orgobjidnew} objectiveName="" orgobjectivecode=""/>
      ),
      key: "0",
    } : null,
    selectedOrganizationObjective?.revertstatus !== 1 && selectedOrganizationObjective?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { handleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    selectedOrganizationObjective?.revertstatus == 1 && selectedOrganizationObjective?.organizationStatus != "1" ?
      {
        label: (
          <Row onClick={() => { handleRevert(0) }} className="p-0">
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // selectedOrganizationObjective?.organizationStatus === "1" ? 
    permission_orgobjdelete ? {
      label: (
        <Row
          className="c_FF4747 p-0"
          onClick={handleDelete}
        // disabled={disableDelete}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: "1",
    } : null
    // : null
  ];

  // const toggleVisibility = (index:number) => {
  //   setIsVisible((prevVisibility) =>
  //   prevVisibility.map((value, i) => (i === index ? !value : value))
  // );
  // }
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);
  const orgObjIdRef = useRef(null);
  const handleClick = (e: any, item: any) => {
    const orgObjId = e.currentTarget.getAttribute('data-value');

    orgObjIdRef.current = orgObjId;
    setorgobjidnew(orgObjIdRef.current);
    setSelectedOrganizationObjective(item);
    e.preventDefault();
  };

  type bhList = {
    id: string,
    name: string,
    objectCode: string
    organizationStatus: string,
    description: string,
    assignedCount: number,
    objectiveName: string,
    createdbyname: string,
    orgObjectiveUsersList: string[],
  }

  const [bhOrganizationList, setBhOrganizationList] = useState<bhList[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const bhCreateOrganization = async () => {

    const roleId = localStorage.getItem("loginId");
    const apiUrl = BaseUrl + `api/organization/bh/objectives/${roleId}`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      response.data.data.map((value: any) => value.organizationStatus === "2");
      setBhOrganizationList(response.data);
    }).catch((error) => {
      // console.log('Error :::::::::::::::::', error);

    })

  }
  const editAbout = async () => {
    const apiUrl = BaseUrl + `api/about/edit/1`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`

      }
    }).then((response) => {
      const values = response.data;
      if (values.ourVision === "") {
        setText("Write your Vision Statement here....");
      } else {
        setText(values.ourVision);
      }
      setMissionText(values.ourMission);
    })
  }
  // view  upto visible it change the api.
  const FhObjectiveAssigned = async () => {

    const roleId = localStorage.getItem("loginId");
    const apiUrl = BaseUrl + `api/organization/objective/users/${roleId}`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      response.data.data.map((e: any) => { console.log(e) });
    })
  }
  const [intervalId, setIntervalId] = useState<any>(null);

  const getOrgGroupList = async () => {
    try {
      const orgLists = await axios.get(BaseUrl + "api/orgGroupMaster/getAll");
      setOrgGroupList(orgLists.data);
      setOrgGroupSelectedOption(orgLists.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //  bhCreateOrganization();
    //orgobjectiveslist(); 
    RefreshToken();
    editAbout();
    GetUserAccess();
    overallCheck();
    Orgobjectivelist();
    getOrgGroupList();
    const id = setInterval(overallCheck, 10000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);

  const RefreshToken = async () => {
    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  type assignUserDetails = {
    name: string,
    username: string,
    email: string,
    designation: string,
    company_id: string,
    profilePath: string,
    objectivestatus: string,
  }

  const [userInformation, setUserInformation] = useState<assignUserDetails[]>([]);

  const userReporteeList = async (userReporteeList: BHAssignObjectiveUserList[], objectiveid: string | null, type: string) => {
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userId);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
  const [memberActive, setMemberActive] = useState(false);
  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const memberMenu = (
    <Menu className="member_count_div">
      {userInformation.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div className="d-flex align-center">
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              <span className="member_title ml-1">{user.name}/{user.designation}/{user.company_id}</span>
              {user.objectivestatus == "1" || user.objectivestatus == "2" &&
                <img src="assets/images/single_tick.svg" alt="" />
              }
              {
                user.objectivestatus == "3" &&
                <img src="assets/images/tick_check.svg" alt="" />
              }


            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );

  const orgCascadetome = (id: string, objectivename: string) => {
    Modal.confirm({
      title: 'Do you want to Cascade this objective ?',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">{objectivename}</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
        </>
      ),
      okText: 'Cascade',
      cancelText: 'Cancel',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective Update successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        try {
          console.log("id", id);
          const apiUrl = BaseUrl + `api/organization/myself/${id}/${localStorage.getItem('loginId')}`;
          axios.put(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
          });
          bhCreateOrganization();
        } catch (error) {
          // console.log("ERROR :::::::::" + error);
        }
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
        // console.log('Cascade action confirmed');
      },
      onCancel() {
        // console.log('Cascade action cancelled');
      },
    });
  }

  const handleChangeOrgGroup = async (id: string) => {
    setOrgGroupSelectedOption(id);
    setSelectedFilter(id);
    const roleId = localStorage.getItem("loginId");
    const apiUrl = BaseUrl + `api/organization/getall`;
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      const filteredData = response.data.data.filter((item: any) => item.organizationGroup === id );
      console.log("filteredData", filteredData);
      setorgobjectiveuserlist((prevList) => [
        ...(filteredData as any[]).map((item: any) => {
          isVisible.push(false);
          return {
            objname: item.objectiveName,
            objcode: item.objectCode,
            createdbyname: item.createdByUserName,
            createdByProfile: item.createdByUserProfilePath,
            status: item.organizationStatus,
            quartervalue: item.quarter,
            orgobjid: item.id,
            objid: "",
            description: item.description,
            organizationStatus: item.organizationStatus,
            assignedorgobj: item.orgObjectiveUsersList,
            year: item.year,
            userIdsList: item.loginUserId,
            revertstatus: item.revokestatus
          };
        }),
      ]);
    }).catch((error) => {
      // console.log('Error :::::::::::::::::', error);
    })  
  }
 
  return (
    <div className={viewButton === false  && permission_orgobjcreate ? "permission_btn" : " " }>
      {/* {permission_orgobjview ? */}
        {/* (
          // <Row className="" justify="end"> */}
          <div className="float-right mb-2"><CreateObj bhCreateOrganization={Orgobjectivelist} /></div>
          {/* // </Row>
        ) : ''} */}
      <Row className="gutter-row w-100 mt-2 " style={{ justifyContent: 'space-between' }}>
        <Col className="obj_card mb-2" xl={12} lg={12} md={12} sm={24} xs={24}>

          <Row justify="space-between">
            <p className="fs-24 m-0 c_47E0FF fw-600 ">
              Our <span className="c-000">Vision</span>
            </p>
            {localStorage.rolename === "BH" ? (
              <>
                {editable ? (
                  <img
                    src="assets/images/text_edit.svg"
                    onClick={handleEditSave}
                    alt=""
                  />
                ) : (
                  <img
                    src="assets/images/message-edit.svg"
                    onClick={handleEdit}
                    className="cur-pointer"
                    alt=""
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </Row>
          <Row className="text-center obj_textbox justify-end">
            {editable ? (
              <>
                <Input.TextArea
                  value={text}
                  className="fs-16 fs_italic m-0 mt-2 vision_scroll"
                  onChange={handleChange}
                  placeholder="“We shall grow significantly better than the industry by fostering innovation and building preferred brands, through passionate and delighted employees”"
                />
                <Row className="mt-2">
                  <button onClick={handleCancel} className="cancel-btn mr-2">
                    Cancel
                  </button>
                  <button onClick={handleSubmit} className="C_btn">
                    Submit
                  </button>
                </Row>
              </>
            ) : (
              <p className="fs-16 fs_italic mt-2 vision_scroll">{text}</p>
            )}
          </Row>
        </Col>
        <Col className="obj_card mb-2" xl={12} lg={12} md={12} sm={24} xs={24}>

          <Row justify="space-between">
            <p className="fs-24 m-0 c_47E0FF fw-600">
              Our <span className="c-000">Mission</span>
            </p>
            {/* <img
                src="assets/images/message-edit.svg"
                onClick={handleEdit}
                className="cur-pointer"
                alt=""
              /> */}
          </Row>
          <Row className="text-center obj_textbox">
            <p className="fs-16 fs_italic m-0 mt-2 vision_scroll">
              {missiontext}
            </p>
          </Row>
        </Col>
      </Row>
      <div className="d-flex mb-2">
          <div className="d-flex align-center mr-1">
            <img src="assets/images/filter-funnel.svg" alt="" />
            <span className="fs-12 pl-1">Filter By</span>
          </div>
          <span className={`${selectedFilter === 'All' ? 'org_obj_filter_btn_Active' : 'org_obj_filter_btn'}`}
          onClick={()=> {
            Orgobjectivelist();
            setSelectedFilter('All');
            }}>All</span>
          {orgGroupList.map((orgGroup) => (
            <span
              key={orgGroup.id} // Ensure each element has a unique key prop
              className={`${selectedFilter ===  orgGroup.id ? 'org_obj_filter_btn_Active' : 'org_obj_filter_btn'}`}
              onClick={() => handleChangeOrgGroup(orgGroup.id)}
            >
              {orgGroup.fieldName}
            </span>
          ))}
            {/* <span className="org_obj_filter_btn">All</span>
            <span className="org_obj_filter_btn">Sales</span>
            <span className="org_obj_filter_btn">Employees or People</span>
            <span className="org_obj_filter_btn">Learning and Innovation</span>
            <span className="org_obj_filter_btn">R&D</span> */}
        </div>
      {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
        <div className="content" />
      </Spin>}
      {/* <Flex className="org_obj_overall pb-2"> */}
      <div className="">
        {/* <Flex> */}
        <Row>
          {bhOrganizationList.length > 0 && bhOrganizationList.map((item) => (
            // {bhOrganizationList &&bhOrganizationList.length>0 &&bhOrganizationList.map((item) => (
            item.organizationStatus === "2" ? (
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <div className="org_obj_card mb-2">
                <div style={style} className="p-1">
                  <Row className="justify-between">
                    <div className="align-center mb-1">
                      <span className="cup_org_bg align-center">
                        <img src="assets/images/cup.svg" alt="" /> {item.objectCode}
                      </span>
                      <span className="triangle"></span>
                    </div>
                    <div className="align-center mb-1">
                      <button className="cascade_btn align-center border-0 p-1 mr-1">
                        <span className="sm_cricle mr-1"></span>
                        Progressing
                      </button>


                    </div>
                  </Row>
                  <Flex>
                  {(permission_orgobjview) ? (
                    <p className="fs-16 c-000 m-0 cur-pointer twoline_text_overflow" onClick={showDrawer}>
                      {/* call the api in edit organization file */}
                      <EditObj orgObjList={Orgobjectivelist} itemId={item.id} objectiveName={item.objectiveName} orgobjectivecode={item.objectCode}/>
                    </p>
                    ):(
                      <>
                      <p  className="fs-16 c-000 cur-pointer twoline_text_overflow mb-2" >{ item.objectiveName}</p>
                      </>
                    )
                    }
                  </Flex>
                  <Flex>
                    <p className="fs-16 threeline_text_overflow m-0">
                      {item.description}
                    </p>
                  </Flex>
                  <Row className="justify-between">
                    <div className="align-center mt-2 mb-2">
                      <span className="mr-1 fs-14">Created by</span>
                      <img
                        src="assets/images/user.svg"
                        // src={profilePhoto}
                        className="user" alt="" />
                    </div>
                    {/* <div className="align-center mt-2 mb-2">
                      <span className="mr-1 fs-14">Assigned to</span>
                      <Avatar.Group
                        maxCount={2}
                        // size="large"
                        maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                      >
                        <Avatar
                          src="assets/images/Avatar1.png"
                          className="avatar"
                        />
                        <Avatar
                          src="assets/images/Avatar2.png"
                          className="avatar"
                        ></Avatar>
                        <Tooltip
                          title="Ant User"
                          placement="top"
                          className="avatar"
                        >
                          <Avatar
                            style={{ backgroundColor: "#fff" }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Avatar
                          className="avatar"
                          style={{ backgroundColor: "#fff" }}
                          icon={<AntDesignOutlined />}
                        />
                      </Avatar.Group>
                    </div> */}
                    <div className="align-center mt-2 mb-2">
                      <span className="mr-1 fs-14">Assigned to</span>

                      {item.orgObjectiveUsersList.length > 2 ? (
                        <>
                          <Avatar.Group
                            maxCount={2}
                            maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                          >

                            {item.orgObjectiveUsersList.slice(0, item.orgObjectiveUsersList.length).map((user, index) => (
                              <>
                                <Tooltip placement="top" title={"titile"} arrow={true}>
                                  <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} />
                                </Tooltip>
                              </>

                            ))}
                          </Avatar.Group>
                        </>
                      ) : (
                        item.orgObjectiveUsersList.map((user, index) => (
                          <Avatar
                            src="assets/images/Avatar1.png"
                            className="avatar"
                            key={index}
                          />
                        ))
                      )
                      }
                    </div>
                  </Row>
                </div>
              </div>
              </Col>
            ) : (<></>)
           
          ))}
          </Row>


          <Row>

          {Orgobjectiveuserslist.map((item, index) => (
            (item.quartervalue==quarter&&item.year==year&&
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>

            <div
              key={index}
              className="org_obj_card mb-2"
            >
              <div className="p-1">
                <Row className="justify-between">
                  <div className="container_label">
                    <div className="wrapper_label align-center mb-1">
                      <span className="cup_org_bg align-center">
                        <img
                          src="assets/images/cup.svg"
                          alt=""
                          className="pr-1"
                        />{" "}
                        {item.objcode}
                      </span>
                      <span className="triangle"></span>
                    </div>
                  </div>
                  <div className="align-center mb-1">
                    {item.organizationStatus === "3" && item.revertstatus == 0 ? (
                      <>
                        <button className="Progressing_btn align-center border-0 p-1 mr-1">
                          <span className="sm_pro_cricle mr-1"></span>
                          Progressing
                        </button>
                      </>

                    ) : ((item.organizationStatus === "1" || item.organizationStatus === "2") && item.revertstatus == 0 ? (<>
                      <button className="cascade_btn align-center border-0 p-1 mr-1">
                        <span className="sm_cricle mr-1"></span>
                        Yet to Cascade
                      </button>
                    </>) :
                      (
                        <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                          <span className="sm_pro_cricle  mr-1 "></span>Revoked
                        </button>
                      )
                    )}

                    {/* <img
                      src="assets/images/menu.svg"
                      alt=""
                      className="pl-2"
                    /> */}
                    {item.createdbyname === user && (permission_orgobjedit || (permission_orgobjdelete)) ? (


                      <Dropdown menu={{ items }} trigger={["click"]} >
                        <a onClick={(e) => handleClick(e, item)}
                          data-value={item.orgobjid}>
                            {localStorage.name === item.createdbyname ? (
                          <Space onClick={(e) => {
                            // console.log(orgObjIdRef.current);
                            setSelectedOrganizationObjective(item);
                          }}>
                            <img src="assets/images/menu.svg" alt="" />
                            <input type="hidden" />
                          </Space>
                            ):(<></>)}
                        </a>

                      </Dropdown>
                    ) : (<></>)}
                  </div>
                </Row>


                <Flex>
                  {/* call the api in edit organization file */}
                  {(permission_orgobjview) ? (
                    <p className="fs-16 c-000 m-0 cur-pointer twoline_text_overflow  " onClick={showDrawer}>
                      {/* call the api in edit organization file */}
                      <EditObj orgObjList={Orgobjectivelist} itemId={item.orgobjid} objectiveName={item.objname} orgobjectivecode={item.objcode} />
                    </p>
                    ):(
                      <>
                      <p  className="fs-16 c-000 cur-pointer twoline_text_overflow mb-2" >{ item.objname}</p>
                      </>
                      )
                    }
                  {/* <EditObj itemId={item.orgobjid} objectiveName={item.objname} /> */}
                </Flex>
                <Flex>
                  <p className="fs-16 threeline_text_overflow mt-2 m-0">{item.description}</p>
                </Flex>
                <Row className="justify-between">
                  <div className="align-center mt-3">
                    <span className="mr-1 fs-14">Created by</span>
                    <Tooltip placement="top" title={item.createdbyname} arrow={true}>
                      <img
                        src={item.createdByProfile ? BaseUrl + item.createdByProfile : "assets/images/Default_user.svg"}
                        onError={handleImageError}
                        // src="assets/images/user.svg"
                        className="user"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                  <div className="align-center mt-2 ">
                    <Dropdown overlay={memberMenu} trigger={['click']}>
                      <a className="ant-dropdown-link" onClick={() => userReporteeList(item.assignedorgobj, item.orgobjid, "orgobjective")}>
                        <Space>
                          <div className="align-center">
                            {
                              memberActive ?
                                <img src="assets/images/memcount_active.svg" alt="" />
                                : <img src="assets/images/membercount.svg" alt="" />
                            }
                            <span className="fw-700 c-000 fs-14 ml-1">
                              {item.assignedorgobj.length}
                              <span className="ml-1">
                                {item.assignedorgobj.length > 1 ? "Members" : "Member"}
                              </span>
                            </span>
                          </div>
                        </Space>
                      </a>
                    </Dropdown>
                    {/* <span className="mr-1 fs-14">Assigned to1</span> */}
                    {/* {item.assignedorgobj.length > 2 ? (
                      <>
                        <Avatar.Group 
                          maxCount={2}
                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        >
                          {item.assignedorgobj.slice(0, item.assignedorgobj.length).map((user :any, index) => (
                            <>
                            <Tooltip placement="top" title={user.name} arrow={true}>
                             
                              <img src={user.profilePath ? FileUrl +user.profilePath :"assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError}  />
                            </Tooltip> 
                            </>
                          ))}
                        </Avatar.Group>
                      </>
                    ) : (
                      item.assignedorgobj.map((user :any, index) => (
                        <>    
                        <Tooltip placement="top" title={user.name} arrow={true}>                
                        <img src={user.profilePath ? FileUrl +user.profilePath :"assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError}  />
                        </Tooltip>    
                        </>
                      ))
                    )
                    } */}
                  </div>
                </Row>
                {/* <Flex justify="center" align="center" className="cur-pointer">
                  {item.assignedorgobj.every(user => (user.userId !== localStorage.getItem("loginId"))) &&(<>
                    <img src="assets/images/Version_control.svg" alt="" />
                    <span className="c_32B7D9 ml-1 fw-300" onClick={() => orgCascadetome(item.orgobjid ,item.objname)}>Cascade me</span>
                  </>)}
                </Flex> */}
                <>
                  {/* {item.assignedorgobj.map((tes : BHAssignObjectiveUserList) => (
                  tes.userId === localStorage.getItem("loginId") ? ( */}
                  {/* <Flex  className="mt-1" justify="space-between" align="center">
                      <Flex className="cur-pointer">
                        <img src="assets/images/Network.svg" alt="" />
                        <span className="p-1 c-000 fs-12">Network</span>
                      </Flex>
                      <Flex>
                        <img src="assets/images/message-chat.svg" alt="" />
                        <span className="p-1 c-000 fs-12">Discussion</span>
                      </Flex>
                    
                      <Flex className="cur-pointer">
                        <CreateParentObj
                          objectcode={item.objcode}
                          objectname={item.objname}
                          orgQuartervalue={item.quartervalue}
                          orgobjid={item.orgobjid}
                          objid={item.objid}
                          year={item.year}
                          objective_id={null}
                          button="+ Create Parent Objective"
                          header="Create Parent Objective"
                        />
                      </Flex>
                    </Flex> */}
                  {/* ) : (<></>)
               ))} */}
                </>
              </div>

              {/* <Flex align="center" className={isVisible[index] ? "objective_bg_active" : ""}>
                {isVisible[index] && (item.mapped_obj.map((objectives, values) =>
                  <Flex>

                    <div className="subobjective_box">
                      <Flex className="p-2" justify="space-between" align="center">
                        <Flex align="center">
                          <img src="assets/images/crown.svg" alt="" />
                          <span className="fs-14 pl-1 c-000">{objectives.obj_name}</span>
                        </Flex>
                        <div>
                          <span className="progress_btn">
                            {" "}
                            <img
                              src="assets/images/progress-Idicator.svg"
                              alt=""
                            />{" "}
                            Progressing
                          </span>
                          <img
                            src="assets/images/menu.svg"
                            alt=""
                            className="pl-2"
                          />
                        </div>
                      </Flex>
                      <Flex className="p-2 fs-16 c-000">
                        {objectives.obj_desc}
                      </Flex>
                      <Flex className="p-2" justify="space-between" align="center">
                        <img src="assets/images/user.svg" className="user" alt="" />
                        <Avatar.Group
                          maxCount={2}
                          // size="large"
                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        >
                          <Avatar
                            src="assets/images/Avatar1.png"
                            className="avatar"
                          />
                          <Avatar
                            src="assets/images/Avatar2.png"
                            className="avatar"
                          ></Avatar>
                          <Tooltip
                            title="Ant User"
                            placement="top"
                            className="avatar"
                          >
                            <Avatar
                              style={{ backgroundColor: "#fff" }}
                              icon={<UserOutlined />}
                            />
                          </Tooltip>
                          <Avatar
                            className="avatar"
                            style={{ backgroundColor: "#fff" }}
                            icon={<AntDesignOutlined />}
                          />
                        </Avatar.Group>
                      </Flex>
                      <Flex className="p-2" justify="space-between" align="center">
                        <Flex className="cur-pointer">
                          <img src="assets/images/Network.svg" alt="" />
                          <span className="p-1 c-000 fs-12">Network</span>
                        </Flex>
                        <Flex>
                          <img src="assets/images/message-chat.svg" alt="" />
                          <span className="p-1 c-000 fs-12">Discussion</span>
                        </Flex>
                      </Flex>
                    </div>

                  </Flex>
                )
                )}
              </Flex> */}


            </div>
            </Col>
            )
              

          ))
                    }
                    </Row>
        {/* </Flex> */}
      {/* </Flex> */}
      </div>
      {/* <Row className="gutter-row mt-2" >
      {allorgobjectivelist.map(item=>(
        <Col className="org_obj_card mb-2" xl={8} lg={8} md={12} sm={24} xs={24}>
          <div style={style}>
            <Row className="justify-between">
              <div className="align-center mb-1">
                <span className="cup_org_bg align-center">
                  <img src="assets/images/cup.svg" alt="" /> {item.name}
                </span>
                <span className="triangle"></span>
              </div>
              <div className="align-center mb-1">
                <button className="cascade_btn align-center border-0 p-1 mr-1">
                  <span className="sm_cricle mr-1"></span>Yet to Cascade
                </button>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <a onClick={(e) =>{ e.preventDefault();setorgobjid(e.currentTarget.getAttribute('data-value'))}} href="#hgj" data-value={item.id.toString()}>
                    <Space>
                      <img src="assets/images/menu.svg" alt="" />
                      <input type="hidden" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </Row>
            <Flex>
              <p className="fs-20">
               {item.objectiveName}
              </p>
            </Flex>
            <Flex>
              <p className="fs-16">
                {item.description}
              </p>
            </Flex>
            <Row className="justify-between">
              <div className="align-center mt-2 mb-2">
                <span className="mr-1 fs-14">Created by </span>
                <img src="assets/images/user.svg" className="user" alt="" />
              </div>
              <div className="align-center mt-2 mb-2">
                <span className="mr-1 fs-14">Assigned to</span>
                <Avatar.Group
                  maxCount={2}
                  // size="large"
                  maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                >
                  <Avatar src="assets/images/Avatar1.png" className="avatar" />
                  <Avatar
                    src="assets/images/Avatar2.png"
                    className="avatar"
                  ></Avatar>
                  <Tooltip title="Ant User" placement="top" className="avatar">
                    <Avatar
                      style={{ backgroundColor: "#fff" }}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                  <Avatar
                    className="avatar"
                    style={{ backgroundColor: "#fff" }}
                    icon={<AntDesignOutlined />}
                  />
                </Avatar.Group>
              </div>
            </Row>
            <Row className="justify-center">
              <button className="c_47E0FF border-0 bg-transparent fs-14">
                + Add Objective
              </button>
            </Row>
          </div>
        </Col>
    ))}
     </Row> */}
      {Orgobjectiveuserslist.length === 0 &&
        <Card className="obj_nc_card">
          <Row className="text-center obj_textbox">
            <span className="c_AAA fs-14 fw-600">
              Organisation objectives not Created yet.
            </span>
          </Row>
        </Card>
      }
      <br />
    </div>
  );
};

export default OrgObjectives;
