import { Tabs } from 'antd';
import React from 'react'
import EmployeeKeyResultAssignedBy from './EmployeeKeyResultAssignedBy';
import EmployeeKeyResultAssignedTo from './EmployeeKeyResultAssignedTo';

const { TabPane } = Tabs;

const EmployeeKeyResult: React.FC = () => {


  return (
    <>
      <div className='mt-3'>
        <Tabs>
          <TabPane tab="Employee KeyResult Assigned By" key="1">
            <div className="non-absolute">
              <EmployeeKeyResultAssignedBy />
            </div>
          </TabPane>
          <TabPane tab="Employee KeyResult Assigned To" key="2">
            <div className="non-absolute">
              <EmployeeKeyResultAssignedTo />
            </div>
          </TabPane>

        </Tabs>
      </div>
    </>
  )
}

export default EmployeeKeyResult;