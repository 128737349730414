import React, { useState, useEffect } from "react";
import { Row, Table, Checkbox, Select, Space, Col, Switch,message} from "antd";
import { BaseUrl } from "../../../App";
import axios from "axios";

import { CloseCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";

interface RolePermission {
  id: string;
  role: string;
  parent_objective: boolean;
  key_result: boolean;
  child_objective: boolean;
  org_objective: boolean;
  lead_measures: boolean;
  menu: string;
  userId: string;
  assigned_members: boolean;
  group: boolean;
}
const User_Settings: React.FC = () => {
  const [rolePermissions, setRolePermissions] = useState<RolePermission[]>([]);
  const [tableData, setTableData] = useState([])
  const [selectedRole, setSelectedRole] = useState("BH");
  const [permission_hnetwork, setPermission_hnetwork] = useState(false);
  const [permission_objnetwork, setPermission_objnetwork] = useState(false);
  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
    setSelectedRole(value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  // Define permissions based on roles
  const permissionsByRole: Record<string, string[]> = {
    "BH": ["Edit", "View", "Delete", "Show CFT", "Show Assigned Members"],
    "FH": ["Create", "Edit", "View", "Delete", "Show CFT", "Show Assigned Members"],
    // "HRBP": ["Create", "Edit", "View", "Delete", "Show CFT", "Show Assigned Members"],
    "TM": ["Create", "Edit", "View", "Delete", "Show CFT", "Show Assigned Members"],
  };

  // useEffect(()=>{
  //   const fetchData=()=>{

  //     fetch('http://localhost:8000/rolepermission')
  //     .then(response => response.json())
  //     .then(json => setTableData(json))
  //   }
  //   fetchData()
  // }, [])
  useEffect(() =>{
    getUserEmailStatus();
  })

  const fetchData = async () => {
    try {
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + selectedRole);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission) as RolePermission[];
      setRolePermissions(rolePermissionData);
      setPermission_hnetwork(data[0].hnetwork);
      setPermission_objnetwork(data[0].objnetwork);
      
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // axios.get('http://localhost:8015/okr/api/menupermission/rolepermissionlist?role='+selectedRole)
    //   .then(response => {
    //     const data = response.data;
    //     const rolePermissionData = JSON.parse(data[0].rolepermission) as RolePermission[];
    //     setRolePermissions(rolePermissionData);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error);
    //   });
    fetchData();
  }, [selectedRole, permission_hnetwork, permission_objnetwork]);

  


  const onChangeCheckBoxEvent = async (e: any, index: any, menu: any) => {
    let res: any = [...rolePermissions]
    let newValue;
    let newValue1;
    try {
      if (menu === 'isorgobj') {
        res[index].org_objective = e.target.checked;
      } else if (menu === 'isparentobj') {
        res[index].parent_objective = e.target.checked
      } else if (menu === 'ischildobj') {
        res[index].child_objective = e.target.checked
      } else if (menu === 'iskr') {
        res[index].key_result = e.target.checked
      } else if (menu === 'islm') {
        res[index].lead_measures = e.target.checked
      } else if (menu === 'isassmem') {
        res[index].assigned_members = e.target.checked
      } else if (menu === 'isgroup') {
        res[index].group = e.target.checked
      }
      if (menu === 'hnetwork') {
        newValue = e.target.checked;
        newValue1 = permission_objnetwork;
        setPermission_hnetwork(newValue);

      }
      if (menu === 'objnetwork') {
        newValue1 = e.target.checked;
        newValue = permission_hnetwork;
        setPermission_objnetwork(newValue1);
      }
      await axios.post(BaseUrl + "api/menupermission/create", { "rolepermission": JSON.stringify({ "todos": res }), "hnetwork": newValue, "objnetwork": newValue1 }, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.accessToken}`
        },
      });
      setRolePermissions(res);
      message.success({
        content: (
          <div className="success-message justify-center align-center p-1">
            <img src="assets/images/check-circle.svg" alt="Success Icon" />
            <span className="c_32C6A0 fs-16">
              Permission Given Succesfully
            </span>
          </div>
        ),
        duration: 2,
        icon: null,
      });
      // console.log(res);

    } catch (error) {
      // console.log(error);
    }



  }

  const rolePermissionsColumns = [
    {
      title: "Permissions",
      render: (record: any) => record.menu

    },
    {
      title: "Org Objective",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.org_objective} onChange={(e) => onChangeCheckBoxEvent(e, index, 'isorgobj')} />

    },
    {
      title: "Parent Objective",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.parent_objective} onChange={(e) => onChangeCheckBoxEvent(e, index, 'isparentobj')} />


    },
    {
      title: "Child Objective",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.child_objective} onChange={(e) => onChangeCheckBoxEvent(e, index, 'ischildobj')} />


    },
    {
      title: "KR",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.key_result} onChange={(e) => onChangeCheckBoxEvent(e, index, 'iskr')} />

    },
    {
      title: "LM",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.lead_measures} onChange={(e) => onChangeCheckBoxEvent(e, index, 'islm')} />
    },
    {
      title: "Assigned_Members",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.assigned_members} onChange={(e) => onChangeCheckBoxEvent(e, index, 'isassmem')} />

    },
    {
      title: "Groups",
      render: (record: any, index: any) => <input type="checkbox" key={index} checked={record.group} onChange={(e) => onChangeCheckBoxEvent(e, index, 'isgroup')} />
    }
  ];

  const [mailStatus, setMailStatus] = useState<boolean>();
  const mailActiveStatusChange = (checked: boolean) => {
    setMailStatus(checked);
    updateMailStatus(checked);
    
  };

  const updateMailStatus = async (active: boolean) => {
    try {
      const apiUrl = BaseUrl + `api/notification/mail/status/${active}`;
      axios.put(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        
        setMailStatus(response.data.data.mailStatus);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const getUserEmailStatus = async () => {
    try {
      const apiUrl = BaseUrl + `api/notification/view/mail/status`;
      axios.put(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setMailStatus(response.data.data.mailStatus);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  return (
    <>
      <div className="admin_panel">
        <Row>
          <Col xl={20} lg={20} md={14} sm={14} xs={24}></Col>
          <Col xl={4} lg={4} md={10} sm={10} xs={24}>
            <Select
              defaultValue="Business Head"
              style={{ width: 120 }}
              onChange={handleChange}
              className="float-right admin_panel_dropdown"
              options={[
                { value: "BH", label: "Business Head" },
                { value: "FH", label: "Functional Head" },
                // { value: "HRBP", label: "HRBP" },
                { value: "TM", label: "Others" },
              ]}
            />
          </Col>
        </Row>
        {/* <Table
          className=""
          dataSource={rolePermissions}
          scroll={{ x: true }}
          columns={rolePermissionsColumns}
          pagination={false}
        /> */}
        <Table dataSource={rolePermissions} pagination={false}>
          {/* <Table.Column title="Id" dataIndex="id" key="id" /> */}
          <Table.Column title="Menu" dataIndex="menu" key="menu" />
          <Table.Column
            title="org_objective"
            dataIndex="org_objective"
            key="org_objective"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'isorgobj')}
              />
            )}
          />
          <Table.Column
            title="parent Objective"
            dataIndex="parent_objective"
            key="parent_objective"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'isparentobj')}
              />
            )}
          />
          <Table.Column
            title="child Objective"
            dataIndex="child_objective"
            key="child_objective"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'ischildobj')}
              />
            )}
          />
          <Table.Column
            title="KR"
            dataIndex="key_result"
            key="key_result"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'iskr')}
              />
            )}
          />
          <Table.Column
            title="LM"
            dataIndex="lead_measures"
            key="lead_measures"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'islm')}
              />
            )}
          />
          <Table.Column
            title="Assigned Memebers"
            dataIndex="assigned_members"
            key="assigned_members"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'isassmem')}
              />
            )}
          />
          <Table.Column
            title="Group"
            dataIndex="group"
            key="group"
            render={(text, record, index) => (
              <Checkbox
                checked={text}
                onChange={(e) => onChangeCheckBoxEvent(e, index, 'isgroup')}
              />
            )}
          />
        </Table>


        <hr className="border_ddd" />
        <div>
          <p className="fs-18"><b>Access</b></p>
          <Row className="d-flex">
            <Checkbox
              checked={permission_hnetwork}
              onChange={(e) => onChangeCheckBoxEvent(e, 'hnetwork', 'hnetwork')} className="fs-16 pr-5">Hierarchy  Network</Checkbox>
            <Checkbox checked={permission_objnetwork} className="fs-16 pr-5" onChange={(e) => onChangeCheckBoxEvent(e, 'objnetwork', 'objnetwork')}>Objective Network</Checkbox>

          </Row>
        </div>
        <hr className="border_ddd " />
        <div>
          <p className="fs-18"><b>Mail Active Status</b></p>
          <div className="d-flex">
              <Switch checked={mailStatus} onChange={(e) => mailActiveStatusChange(e)} />
              <span className="fs-16 mx-3"><b className={ mailStatus ? "": "c_FF4747"}>{mailStatus === true ? "Active" : "In-Active"}</b></span>
          </div>

        </div>

      </div>

    </>
  );
};

export default User_Settings;
