import React, { useState,useEffect } from "react";
import { Table, Button, Dropdown, Menu, Input, Space, Spin,message } from "antd";
import {
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import type { ColumnType } from "antd/es/table";
import type { MenuInfo } from "rc-menu/lib/interface";
import type { FilterDropdownProps } from "antd/es/table/interface";
import axios from "axios";
import { BaseUrl} from "../../../App";

interface DataType {
  key: string;
  employeeId: string;
  name: string;
  companyName: string;
  divisionName: string;
  cycle: string;
  mailId: string;
  assignedObjectiveCount: number;
  availObjWeightage: number;
  assignedKeyResultCount: number;
  availKrWeightage: number;
  managerId: number;
  managerName: string;
  hrBP: string;
  reviewerName: string;
  fh:string;
  bh:string;
}

// const dataSource: DataType[] = [
//   {
//     key: "1",
//     employeeId: "12213123",
//     name: "Emanual John",
//     companyName: "CITPL",
//     department: "Product",
//     cycle: "Q1-2024",
//     mailId: "Pandiyan@citpl.com",
//     assignedObjectiveCount: 12,
//     availObjWeightage: "85%",
//     noOfAssignedKr: 13,
//     availKrWeightage: "85%",
//     managerId: 323,
//     managerName: "Rajan",
//     hrBP: "Anbu",
//     reviewerName: "Divya",
//   },
//   {
//     key: "2",
//     employeeId: "12213123",
//     name: "Pandiyan",
//     companyName: "CITPL",
//     department: "Sales",
//     cycle: "Q1-2024",
//     mailId: "aravindh@citpl.com",
//     assignedObjectiveCount: 12,
//     availObjWeightage: "85%",
//     noOfAssignedKr: 13,
//     availKrWeightage: "85%",
//     managerId: 323,
//     managerName: "Rajan",
//     hrBP: "Anbu",
//     reviewerName: "Divya",
//   },
//   {
//     key: "3",
//     employeeId: "12213123",
//     name: "Aravindh",
//     companyName: "CITPL",
//     department: "Sales",
//     cycle: "Q1-2024",
//     mailId: "david@citpl.com",
//     noOfAssignedObj: 12,
//     availObjWeightage: "85%",
//     noOfAssignedKr: 13,
//     availKrWeightage: "85%",
//     managerId: 323,
//     managerName: "Rajan",
//     hrBP: "Anbu",
//     reviewerName: "Divya",
//   },
// ];
  
const getColumnSearchProps = (
  dataIndex: keyof DataType
): ColumnType<DataType> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        style={{ marginBottom: 8, display: "block" }}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <img
      src="assets/images/filter_alt.svg"
      style={{ color: filtered ? "#1890ff" : undefined }}
    />
  ),
  onFilter: (value, record) =>{
    const recordValue = record[dataIndex];
    if (recordValue === undefined || recordValue === null) {
      return false; // If the record value is undefined or null, do not include it in the filter results
    }
   // record[dataIndex]
  return recordValue
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase())
  }
  // onFilter: (value, record) =>
  //   record[dataIndex]
  //     .toString()
  //     .toLowerCase()
  //     .includes((value as string).toLowerCase()),
});




const columns: Array<ColumnType<DataType>> = [
  {
    title: "Employee ID",
    dataIndex: "employeeId",
    key: "employeeId",
    sorter: (a, b) => {
      const employeeIdA = a.employeeId || ''; // Default to empty string if null or undefined
      const employeeIdB = b.employeeId || ''; // Default to empty string if null or undefined
      return employeeIdA.localeCompare(employeeIdB);
    },
    ...getColumnSearchProps("employeeId")
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => {
      const nameA = a.name || ''; // Default to empty string if null or undefined
      const nameB = b.name || ''; // Default to empty string if null or undefined
      return nameA.localeCompare(nameB);
    },
    ...getColumnSearchProps("name"),
  },
  {
      title: "FH",
      dataIndex: "fh",
      key: "fh",
      ...getColumnSearchProps("fh"),
  },
  {
      title: "BH",
      dataIndex: "bh",
      key: "bh",
      ...getColumnSearchProps("bh"),
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
    ...getColumnSearchProps("companyName"),
  },
  {
    title: "Department",
    dataIndex: "divisionName",
    key: "divisionName",
    ...getColumnSearchProps("divisionName"),
  },
  {
    title: "Cycle",
    dataIndex: "cycle",
    key: "cycle",
    ...getColumnSearchProps("cycle"),
  },
  {
    title: "Email ID",
    dataIndex: "mailId",
    key: "mailId",
    ...getColumnSearchProps("mailId"),
  },
 
  {
    title: "Manager ID",
    dataIndex: "managerId",
    key: "managerId",
    ...getColumnSearchProps("managerId"),
  },
  {
    title: "Manager Name",
    dataIndex: "managerName",
    key: "managerName",
    ...getColumnSearchProps("managerName"),
  },
  {
    title: "Reviewer Name",
    dataIndex: "reviewerName",
    key: "reviewerName",
    ...getColumnSearchProps("reviewerName"),
  },
  {
    title: "HRBP",
    dataIndex: "hrBP",
    key: "hrBP",
    ...getColumnSearchProps("hrBP"),
  },
  {
    title: "No.Of.Assigned OBJs",
    dataIndex: "assignedObjectiveCount",
    key: "assignedObjectiveCount",
    sorter: (a, b) =>
      a.assignedObjectiveCount - b.assignedObjectiveCount,
    ...getColumnSearchProps("assignedObjectiveCount"),
  },
  {
    title: "Avail Obj Weightage",
    dataIndex: "availObjWeightage",
    key: "availObjWeightage",
    sorter: (a, b) =>
      a.availObjWeightage - b.availObjWeightage,
    ...getColumnSearchProps("availObjWeightage"),
  },
  {
    title: "No.Of.Assigned KRs",
    dataIndex: "assignedKeyResultCount",
    key: "assignedKeyResultCount",
    sorter: (a, b) =>
      a.assignedKeyResultCount - b.assignedKeyResultCount,
    ...getColumnSearchProps("assignedKeyResultCount"),
  },
  {
    title: "Avail KR Weightage",
    dataIndex: "availKrWeightage",
    key: "availKrWeightage",
    sorter: (a, b) =>
      a.availKrWeightage-b.availKrWeightage,
    ...getColumnSearchProps("availKrWeightage"),
  },
];

interface parent{
  usersObjectiveReport:DataType[],
  error: boolean,
  loading:boolean,
}

const ObjectiveWeightageTable: React.FC<parent> = ({usersObjectiveReport,error,loading}) => {

  // const [usersObjectiveReport,setUsersObjectiveReport]=useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>(usersObjectiveReport);
const handleMenuClick = (e: MenuInfo) => {
  if (e.key === "xls") {
    downloadXLS();
  } else if (e.key === "pdf") {
    downloadPDF();
  }
};

// const [loading, setLoading] = useState(true);



// const userObjectiveData = async () => {

//   try {
//       const apiUrl = BaseUrl + `api/reports/getUsersObjective`;
//       const response = await axios.get(apiUrl, {
//           headers: {
//               "Content-Type": "application/json",
//               "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
//           }
//       })
//           .then((response) => {
//             setUsersObjectiveReport(response.data);
//             setLoading(false);

//           });
//   } catch (error) {
//       console.log("ERROR :::::::::" + error);
//   }

// };

// useEffect(()=>{
//   // userObjectiveData();
//  // setFilteredData(usersObjectiveReport)
//  console.log("user objective record "+usersObjectiveReport)
// },[])

const downloadXLS = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Report");

  worksheet.columns = columns.map((column) => ({
    header: column.title as string,
    key: column.key as string, // Explicitly cast key to string
    width: 20,
  }));

  filteredData.forEach((data) => {
    worksheet.addRow(data);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "report.xlsx");
};

const downloadPDF = () => {
  const doc = new jsPDF("landscape", "mm", "a4"); // Set the orientation to landscape and format to A4
  doc.text("Employee Report", 10, 10);
  (doc as any).autoTable({
    head: [columns.map((column) => column.title as string)],
    body:  filteredData.map((item) =>
      columns.map((column) => item[column.key as keyof DataType])
    ),
    startY: 20, // Optional: Start the table below the title
    theme: "striped", // Optional: Table theme
    headStyles: { fillColor: [22, 160, 133] },
    styles: { fontSize: 8 },
  });
  doc.save("report.pdf");
};

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="xls">
      <div className="d-flex align-center">
      <img src="assets/images/text_line_xls.svg" alt="" /><span className="ml-1">XLS  </span>
      </div>
    </Menu.Item>
    <Menu.Item key="pdf">
    <div className="d-flex align-center">
      <img src="assets/images/text_line_pdf.svg" alt="" /><span className="ml-1">PDF</span>
      </div>
    </Menu.Item>
  </Menu>
);



return (
  <>

  {error ? (
    message.error({
      content: (
          <div className="success-message justify-center align-center p-1 d-flex">
              Something Went Wrong 
          </div>
      ),
      duration: 5,
      icon: null,
  })
  ) :null}

  <div className="report_table">
    <Dropdown overlay={menu} className=" mb-2 mt-1 mr-1">
      <Button className="float-right">
        Download as <DownOutlined />
      </Button>
    </Dropdown>
        <Spin spinning={loading}>
            <Table 
            dataSource={usersObjectiveReport}
            columns={columns} scroll={{ x: 2000 }}  
            pagination={{ pageSize: 15 }}   onChange={(pagination, filters, sorter, extra) => {
                       setFilteredData(extra.currentDataSource);
                        }} 
            />
        </Spin>

  </div>
  </>
);


}


// export const objectiveColumns =columns;

export default ObjectiveWeightageTable;




