import React, { useEffect, useState } from "react";
import { Drawer, Row, Badge } from "antd";
import { BaseUrl } from "../../../App";
import axios from "axios";
import moment from "moment";
import EditObj from "./EditObj";
import EditParentObj from "./EditParentObj";
import KRDescription from "./KRDescription";
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import useSound from 'use-sound'

import { NotificationContext } from "../../context/NotificationContext";
type notificationResponse = {
  createdDate: string,
  notificationList: createNotification[],
}

type createNotification = {
  userId: string;
  empId: string;
  objectivename: string;
  name: string;
  createdAt: Date;
  createdDate: string;
  createdTime: string;
}

const Notification: React.FC = () => {
  const stompClient: any = React.useContext(NotificationContext);
  const mySound: any = require('./Audio/NotificationSound.mp3');
  const [playSound] = useSound(mySound)

  // let today : Date = new Date();
  // const [currentDate, setCurrentDate] = useState(moment(today).format("DD-MM-YYYY"));
  // const [notification, setNotification] = useState<notificationResponse[]>([]);
  // const [notificationInfo, setNotificationInfo] = useState<createNotification[]>([]);


  const [showProfile, setShowProfile] = useState(false);

  const [messagescount, setmessagescount] = useState<number>();


  const handleDrawerClose = () => {
    setShowProfile(false);
    setmessagescount(0)    
  };

  type notificationResponse = {
    createdDate: string,
    notificationList: createNotification[],
  }

  type createNotification = {
    id: string;
    userId: string;
    empId: string;
    objectivename: string;
    name: string;
    createdAt: Date;
    createdDate: string;
    createdTime: string;
    deleteStatus: string;
    revertStatus: string;
    organizationStatus: string;
    organizationContent: string;
    objectiveContent: string;
    keyResultContent: string;
    creatorOrganizationContent: string,
    creatorObjectiveContent: string,
    creatorKeyResultContent: string,
    notificationStatus : boolean;
  }

  let today: Date = new Date();
  const [currentDate, setCurrentDate] = useState(moment(today).format("DD-MM-YYYY"));
  const [existNotificationCount, setExistNotificationCount] = useState<number>();
  const [notification, setNotification] = useState<notificationResponse[]>([]);

  const handleProfileClick = () => {
    // getUserNotifications();
    // getNotificationcount();
    setShowProfile(!showProfile);
    seenNotificationCount();
    setExistNotificationCount(0);
  };

  const getUserNotifications = async () => {
    try {
      const apiUrl = BaseUrl + `api/notification/all/notify`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setNotification(response.data.data); 
      // const seenNotificationStatus = response.data.data.map((value : any)  => value.notificationStatus === true);
      let count : number = 0;
     notification.map((response) => {
       let result =  response.notificationList.filter((value : createNotification)  => value.notificationStatus === true).length;
        count += result;
      })
      setmessagescount(count);     
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
  const getNotificationcount = async () => {
    try {
      const apiUrl = BaseUrl + `api/notification/all/notify`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setExistNotificationCount(response.data.data.length);
      var total_notification_array = response.data.data;
      var notificationList_array: any = [];
      total_notification_array.map((notify_data: any) => (
        notificationList_array.push(notify_data.notificationList)
      ))
      var notificationList_array_length = notificationList_array.flat().length;
      // let count  = 0;
      // setmessagescount(notificationList_array_length);
      // setExistNotificationCount(moment(today).format("HH:mm"));
      // response.data.data.map((date :  notificationResponse) => {
      //   date.notificationList.map((oldnotification : createNotification ) => {
      //     if(existNotificationCount < oldnotification.createdTime.substring(0,5) ) {
      //       console.log(true,"....................................");  
      //       count++;
      //     }
      //   })
      // })
      setmessagescount(notificationList_array_length);
      
      
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const getNotification = async () => {
    try {
      stompClient.connect({}, () => {
        stompClient.subscribe('/topic/messages', (messagedata: any) => {
          const receivedMessage = JSON.parse(messagedata.body);
          // getNotificationcount();
          // setmessagescount(prevCount => prevCount + 1);
        });
      });

    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const seenNotificationCount = async () => {
    try {
      const apiUrl = BaseUrl + `api/notification/status`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      // setmessagescount(0);
    } catch (error) {
      // console.log("ERROR", error);
    }
  }


  // useEffect(() => {
  //   getNotificationcount();
  // }, [])

  useEffect(() => {
    // getNotificationcount();
    // seenNotificationCount();
    getUserNotifications();
  }, [stompClient, messagescount])

  return (
    <div style={{ position: "relative", right: "22px" }}>
      <Badge count={messagescount}>
        <img
          onClick={handleProfileClick}
          src="assets/images/notification.svg"
          alt=""
          className="pl-3  z-1 position-relative cur-pointer"
        />
      </Badge>
      {/* <input type="text" value={messagescount}/> */}
      <Drawer
        title="Notifications"
        size="large"
        className="objective-canvas position-relative"
        visible={showProfile} 
        onClose={handleDrawerClose} 
        maskClosable={false}
      >
        {notification && notification.map((newNotification: notificationResponse) => (
          <>
            <Row>
              <label htmlFor="" className="fs-14 fw-700 c-000 my-1">{currentDate === newNotification.createdDate ? "Today" : newNotification.createdDate}</label>
            </Row>
            {newNotification.notificationList && newNotification.notificationList.map((details: createNotification) => (
              <> 
              {/* {details.createdTime.substring(0, 5) < existNotificationCount ?  ( */}
                <div>
                  <Row className="notification_border_bottom">
                    <div className="notification_box w-100">
                      <div className="justify-between">
                        <div className="align-center">
                          {details.name.substring(0, 2) === "KR" ? <img src="assets/images/KRA_notifi.svg" alt="" /> : <img src="assets/images/crown_notifi.svg" alt="" />}
                          <span className="c_32B7D9 pl-1">{details.name}</span>
                        </div>
                        <div>
                          <span>{details.createdTime}</span>
                        </div>
                      </div>
                      {details.creatorOrganizationContent != null || details.creatorObjectiveContent || details.creatorKeyResultContent ?
                        (
                          <> <p>{details.name.substring(0, 5) === "ORGOB" ? details.creatorOrganizationContent :
                            details.name.substring(0, 2) === "OB" ? details.creatorObjectiveContent : details.creatorKeyResultContent}</p>
                            <p className="fs-16 notification_text" > {details.objectivename} </p> </>) : (

                          <>
                            <p >{details.name.substring(0, 5) === "ORGOB" ? details.organizationContent :
                              details.name.substring(0, 2) === "OB" ? details.objectiveContent : details.keyResultContent}</p>
                            <p className="fs-16 notification_text" > {details.objectivename} </p>
                          </>
                        )
                      }
                      {/* <p className="fs-16 notification_text" onClick={() => handleDrawerClose()}> {details.objectivename}
                         {
                          details.name.substring(0, 5) === "ORGOB" ? <EditObj itemId={details.id} objectiveName={details.objectivename} />
                            : details.name.substring(0, 2) === "OB" ? <EditParentObj objectiveId={details.id} objectivestatus={details.organizationStatus} objectiveName={details.name} type={false} objectivetype={"objective"} />
                              : <KRDescription krid={details.id} krtitle={details.name} objname={""} objid={""} availableweightagekr={0} year={""} orgdescription={""} />
                        }
                      </p> */}
                    </div>
                  </Row>
                </div>
                {/* ) : (<></>)} */}
              </>
            ))}
          </>
        ))}
      </Drawer>
    </div>
  );
};

export default Notification;
