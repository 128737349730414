import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import "./LoginPage.css";
import { useLocation } from 'react-router-dom';
import { BaseUrl } from "../../App";
import axios from "axios";

const ConfirmPassword: React.FC = () => {
  const [passwordData, setPasswordData] = useState({ newPassword: "", confirmPassword: "" });
  const [errors, setErrors] = useState({ newPassword: "", confirmPassword: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [CpasswordVisible, setCPasswordVisible] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(!CpasswordVisible);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleConfirmPassword = async () => {
    const newErrors = { newPassword: "", confirmPassword: "" };

    if (!passwordData.newPassword) {
      newErrors.newPassword = "New password is required";
    }

    if (!passwordData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      // console.log("Passwords matched, proceed with password change logic");

      const apiUrl = BaseUrl + `api/auth/changePassword`;
      const formData = new FormData();
      // console.log("email : " + decryptedEmail);
      // console.log("password : " + passwordData.confirmPassword);
      formData.append("email", decryptedEmail);
      formData.append("password", passwordData.confirmPassword);
      // "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",

        }
      })
        .then((response) => {
          // console.log(response.data);
          // console.log("Password changed successfully.");
          setSuccessMessageVisible(true);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 2000);
          setPasswordData({ newPassword: "", confirmPassword: "" });
          window.location.href = "/";
        }).catch((errors) => {
          if (errors.response.status === 404) {
            setErrors({ ...errors, useremail: "Sorry! Your email is not linked with the portal." });
            return;
          }
          // console.error("Error occurred during password change:", errors);
        })

    }
  };

  ///get email and  created time from url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailParam = searchParams.get('email');
  const timeFromParams = searchParams.get('time');

  const decrypt = (encryptedValue: any) => {
    return atob(encryptedValue); // Basic Base64 decoding
  };

  const decryptedEmail = decrypt(emailParam);
  const decryptedTime = decrypt(timeFromParams);


  const time = new Date(Date.UTC(
    parseInt(decryptedTime.substring(0, 4), 10), // Year
    parseInt(decryptedTime.substring(5, 7), 10) - 1, // Month (0-indexed)
    parseInt(decryptedTime.substring(8, 10), 10), // Day
    parseInt(decryptedTime.substring(11, 13), 10), // Hours
    parseInt(decryptedTime.substring(14, 16), 10), // Minutes
    parseInt(decryptedTime.substring(17, 19), 10), // Seconds
  ));

  const [currentTime, setCurrentTime] = useState(new Date());
  const expirationTime = new Date(time.getTime() + 30 * 60 * 1000);//set expire time
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second
    return () => clearInterval(intervalId);
  }, []);

  if (currentTime.getTime() > expirationTime.getTime()) {
    alert('Link expired');
    return null;
  }


  return (
    <Row className="login_bg">
      <Col
        xl={16}
        lg={15}
        md={12}
        sm={24}
        xs={24}
        className="d-sm-none left-bg"
      >
        {/* <img src="assets/images/login-left.svg" className="" alt="" /> */}
      </Col>
      <Col xl={8} lg={9} md={12} sm={24} xs={24} className="right-bg-container">
        <div className="right-bg">
          <div className="">
            <div className="text-center">
              {successMessageVisible && (
                <div className="mb-5">
                  <Row className="success-message email_msg justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">
                      Password Changed Successfull
                    </span>
                  </Row>
                </div>
              )}
              <span className="c_47E0FF fs-48 fw-700 mb-2 text-center">Confirm Password</span>
              <p className="c-000 font_ReadexPro fw-300 fs-20 text-center">
                Please Enter your password.
              </p>
            </div>
            <Form
              initialValues={{ remember: true }}
              autoComplete="off"
              className="m-5 p-5 pm-0 mm-0"
            >
              <div className="">
                <div className="position-relative mt-5">
                  <label htmlFor="" className="fw-600 fs-14">
                    New Password{" "}
                  </label>
                  <Input
                    type={passwordVisible ? "text" : "password"}
                    name="newPassword"
                    placeholder="Enter New Password"
                    value={passwordData.newPassword}
                    onChange={handleInputChange}
                    className="mt-1"
                    required
                  />
                  <img
                    src={passwordVisible ? 'assets/images/visible.svg' : 'assets/images/invisible.svg'}
                    alt={passwordVisible ? 'Invisible' : 'Visible'}
                    onClick={togglePasswordVisibility}
                    className="visible_icon"
                  />
                  {errors.newPassword && (
                    <p className="m-0 text-start mt-1" style={{ color: "red" }}>
                      {errors.newPassword}
                    </p>
                  )}
                </div>
                <div className="position-relative mt-5">
                  <label htmlFor="" className="fw-600 fs-14">
                    Confirm Password
                  </label>
                  <Input
                    type={CpasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={passwordData.confirmPassword}
                    onChange={handleInputChange}
                    className="mt-1"
                    required
                  />
                  <img
                    src={CpasswordVisible ? 'assets/images/visible.svg' : 'assets/images/invisible.svg'}
                    alt={CpasswordVisible ? 'Invisible' : 'Visible'}
                    onClick={toggleCPasswordVisibility}
                    className="visible_icon"
                  />
                  {errors.confirmPassword && (
                    <p className="m-0 text-start mt-1" style={{ color: "red" }}>
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>

              <Form.Item className="text-center mt-5">
                <button className="cyan_button w-80 mt-2 mb-1" onClick={handleConfirmPassword}>
                  Confirm Password
                </button>
              </Form.Item>
            </Form>
            <div className="text-center">
              <p className="Copyright text-center fs-12">
                Copyright © 2024 CITPL. All right reserved.
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ConfirmPassword;
