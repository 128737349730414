import React from 'react';
import { message, Modal, Flex,Row,Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FileUrl } from '../../App';


interface OptionItem {
  value: string;
  label: string;
  image: string;
  // weightage: number;
}

// const Alerts: React.FC<singleUser> = ({userName, image} ) => {
const handleDelete = (userName: string, profilePath: string , objectiveName: string, contentType : string) => {
  Modal.confirm({
    title: "Do you want to Cascade this "  +contentType+ " ?",
    content: (
      <>
        <Row  className="align-center">
        <Col xl={6} lg={6}md={6} sm={24} xs={24}>
          <div className='text-center'>
            <img src={profilePath !== "" ? FileUrl + profilePath : "assets/images/Default_user.png"} className='D_user text-center' alt="" />
            <p>{userName}</p>
          </div>
        </Col>
        <Col xl={18} lg={18}md={18} sm={24} xs={24}>
        <div className='pl-4'>
            <p>{objectiveName}</p>
            <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
            <p><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            <p className='c_EC174F'>
              <ExclamationCircleOutlined style={{ color: '#FFA500', marginRight: '8px' }} />
              {/* Since the assigned assignee's weightage has been exceeded */}
              {/* Since the assigned assignee's weightage has been exceeded, would you wish to continue? */}
            </p>
          </div>
        </Col>
          
          
        </Row>
      </>
    ),
    okText: "Ok",
    // cancelText: "Cancel",
    okButtonProps: { className: "PW_btn" },
    cancelButtonProps: { style: { display: "none" } },
    centered: true,
    // onOk() {
    // console.log("Delete action confirmed");
    // message.success({
    //   content: (
    //     <div className="success-message justify-center align-center p-1">
    //       <img src="assets/images/check-circle.svg" alt="Success Icon" />
    //       <span className="c_32C6A0 fs-16">
    //         Objective deleted successfully......
    //       </span>
    //     </div>
    //   ),
    //   duration: 1,
    //   icon: null,
    // });
    // },
    // onCancel() {
    //   console.log("Delete action cancelled");
    // },
  });
};

const handleproceed = (moreAssignedWeightageUser: OptionItem[], objectiveName: string, contentType : string) => {
  Modal.confirm({
    title: "Do you want to Cascade this "+contentType +" ?",
    content: (
      <>
        {/* <div className='text-center'>
          {moreAssignedWeightageUser.map((assignWeightageUser) => (
            <>
              <img src={assignWeightageUser ?  + assignWeightageUser.image : "assets/images/Default_user.png"} className='D_user text-center' alt="" />
              <p>{assignWeightageUser.value}</p>
            </>
          ))}
        </div> */}
        <div className='pl-4'>
          <p>{objectiveName}</p>
          <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
          <p><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          <p className='c_EC174F'>
            <ExclamationCircleOutlined style={{ color: '#FFA500', marginRight: '8px' }} />
            {/* Since the assigned assignee's weightage has been exceeded */}
            {/* Since the assigned assignee's weightage has been exceeded, would you wish to continue? */}
          </p>
        </div>
      </>
    ),
    okText: "Ok",
    // cancelText: "Cancel",
    okButtonProps: { className: "PW_btn" },
    cancelButtonProps: { style: { display: "none" } },
    centered: true,
    onOk() {
      // console.log("Delete action confirmed");
      // message.success({
      //   content: (
      //     <div className="success-message justify-center align-center p-1">
      //       <img src="assets/images/check-circle.svg" alt="Success Icon" />
      //       <span className="c_32C6A0 fs-16">
      //         Objective deleted successfully.
      //       </span>
      //     </div>
      //   ),
      //   duration: 1,
      //   icon: null,
      // });
    },
    // onCancel() {
    //   console.log("Delete action cancelled");
    // },
  });
};
// return (
//   <>
//     <button
//       className="border-0 bg-transparent c_FF4747 fs-14 align-center"
//       onClick={handleDelete}
//     >
//       profile
//     </button>
//     <button
//       className="border-0 bg-transparent c_FF4747 fs-14 align-center"
//       onClick={handleproceed}
//     >
//       without profile
//     </button>
//   </>
// );
// };

// export  {Alerts, handleDelete, handleproceed };
export { handleDelete, handleproceed };
