// layouts/AuthLayout.tsx
import React from 'react';

interface AuthLayoutProps {
  component: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ component }) => {
  return <div>{component}</div>;
};

export default AuthLayout;
