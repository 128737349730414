import React, { useState, ChangeEvent } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Option } = Select;

interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: number;
  ImageUrl: string;
}

interface OptionItem {
  value: string;
  label: string;
  image: string;
}
const handleChange = (value: string) => {
  
};

const CusWeightage: React.FC = () => {
  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const dataSource: DataSourceType[] = [
    {
      key: 1,
      Name: "Arav",
      Available: "21%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 2,
      Name: "priya",
      Available: "12%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 3,
      Name: "riya",
      Available: "12%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 4,
      Name: "Nancy",
      Available: "21%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 5,
      Name: "vicky",
      Available: "21%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 6,
      Name: "mariya",
      Available: "21%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
    {
      key: 7,
      Name: "Renna",
      Available: "21%",
      Assigned: 23,
      ImageUrl: "assets/images/user.svg",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (text: string, record: any) => (
        <span className="align-center">
          <img src={record.ImageUrl} alt="" className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    {
      title: "Available",
      dataIndex: "Available",
      key: "Available",
      render: (text: string, record: any) => <span>24%</span>,
    },
    {
      title: "Assigned",
      dataIndex: "Assigned",
      key: "Assigned",
      render: (text: string, record: any) => (
        <div className="input-with-suffix">
        <input 
        // type="text" 
        value={"6"} 
        onChange={(e) => {
          const inputVal = e.target.value;
          const numericRegex = /^[0-9]*$/;
          if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
           
          }}}
        className="assigned_bg"
         />
        <span className="suffix">%</span>
        </div>
      ),
    },
  ];

  const custom_filteredData = dataSource.filter((record: DataSourceType) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItem[]>([]);

  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedMemValues(selectedMemValues);
  };
  const handleMemTagClose = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
  };

  const handleGrpSelectChange = (selected: OptionItem[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedGrpValues(selectedGrpValues);
  };
  const handleTagClose = (valueToRemove: string) => {
    const updatedGrpValues = selectedGrpValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedGrpValues(updatedGrpValues);
  };
  const handleImageError = (e:any)=>{
    const target = e.target as HTMLImageElement; 
    target.onerror = null; 
    target.src = 'assets/images/Default_user.svg'; 
};

  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );

  const Memoptions: OptionItem[] = [
    { value: "value1", label: "renu", image: "assets/images/Avatar1.png" },
    { value: "value2", label: "rose", image: "assets/images/user.svg" },
    { value: "value3", label: "harini", image: "" },
    { value: "value4", label: "tamil", image: "assets/images/user1.svg" },
    { value: "value5", label: "vicky", image: "assets/images/Avatar1.png" },
    { value: "value6", label: "priya", image: "assets/images/user2.svg" },
    { value: "value7", label: "divya", image: "assets/images/Avatar1.png" },
  ];

  Memoptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });

  const GrpOptions: OptionItem[] = [
    { value: "value1", label: "vennila", image: "assets/images/Avatar1.png" },
    { value: "value2", label: "revathi", image: "assets/images/user1.svg" },
    { value: "value3", label: "kumar", image: "" },
    { value: "value4", label: "jeeva", image: "assets/images/user3.svg" },
    { value: "value5", label: "poja", image: "assets/images/Avatar1.png" },
    { value: "value6", label: "leela", image: "assets/images/user.svg" },
    { value: "value7", label: "deepa", image: "assets/images/Avatar1.png" },
    { value: "value8", label: "reena", image: "assets/images/Avatar1.png" },
  ];

  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage hide and show
  
  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [isSearchMem , setisSearchMem] = useState(false);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
  };

  const [customopen, setCustomOpen] = useState(false);
  const { TextArea } = Input;

  const showCustomDrawer = () => {
    setCustomOpen(true);
  };

  const onClose = () => {
    setCustomOpen(false);
  };

  const [text, setText] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
  );

  const maxWords = 250;

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setText(newText);
  };

  const wordCount = text.trim().split(/\s+/).length;
  return (
    <div className="">
      <Row>
      <span className="c_32B7D9 fs-12 cur-pointer float-right" onClick={showCustomDrawer}>
          Custom Weightage <ArrowRightOutlined />  
        </span>
      </Row>
      <Drawer
        title="Add New Objective"
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={customopen}
        className="objective-canvas mb-5"
      >
        <Form className="mb-5">
          <div className="title-section mb-3">
            <label htmlFor="" className="fw-600">
              Objective Name
            </label>
            <div className="objective-title-inner">
              <TextArea
                rows={3}
                placeholder=""
                className="canvas-objective-input"
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
              />
            </div>
          </div>
          <div className="desc-section mb-3">
            <label htmlFor="" className="fw-600">
              Description
            </label>
            <div className="objective-title-inner">
              <TextArea
                rows={3}
                placeholder=""
                className="canvas-objective-input"
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
              />
            </div>
          </div>
          <div className=" mb-3">
            <Row className="my_obj">
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <label htmlFor="" className="fw-600">
                  Cycle
                </label>
                <br />
                <Select
                  className="form-control mt-1"
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  onChange={handleChange}
                >
                  <Option value="jack">
                    <Radio value={1}>{"Jack"}</Radio>
                  </Option>
                  <Option value="lucy">
                    <Radio value={2}>{"Lucy"}</Radio>
                  </Option>
                  <Option value="Yiminghe">
                    <Radio value={3}>{"Yiminghe"}</Radio>
                  </Option>
                </Select>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <label htmlFor="" className="fw-600">
                  Visibility Upto
                </label>
                <br />
                <Select
                  className="form-control w-100 mt-1"
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="assign-section mb-3">
            <label htmlFor="" className="fw-600">
              Assign Members
            </label>
            <span className="float-right c_32B7D9 text_line fs-12 cur-pointer" onClick={handleSearchMember}>
              {isSearchMem ? "Search Group" : "Search Members"}
            </span>
            <br />
            <div className="mt-1">
                {isSearchMem ? (<Select
                mode="multiple"
                placeholder={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="assets/images/member.svg"
                      alt="Placeholder"
                      style={{ marginRight: "8px" }}
                    />
                    <span>Select Member</span>
                  </div>
                }
                onChange={handleMemSelectChange}
                value={selectedMemValues}
                className="w-100 form-control"
                labelInValue
                optionLabelProp="label"
                dropdownRender={(menu) => <div>{menu}</div>}
                // options={Memoptions}
                // filterOption={(input, option) =>
                //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                // }
                filterOption={(input, option) =>
                  option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                }
              >
                {Memoptions.map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  >
                    {renderOption(option)}
                  </Option>
                ))}
              </Select>):
                 <Select
                 mode="multiple"
                 placeholder={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <img
                       src="assets/images/people.svg"
                       alt="Placeholder"
                       style={{ marginRight: "8px" }}
                     />
                     <span>Select Group</span>
                   </div>
                 }
                 onChange={handleGrpSelectChange}
                 value={selectedGrpValues}
                 className="w-100 form-control"
                 labelInValue
                 optionLabelProp="label"
                 dropdownRender={(menu) => <div>{menu}</div>}
                //  options={GrpOptions}
                //  filterOption={(input, option) =>
                //    option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                //  }
                filterOption={(input, option) =>
                  option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                }
               >
                 {GrpOptions.map((option) => (
                   <Option
                     key={option.value}
                     value={option.value}
                     label={option.label}
                   >
                     {renderOption(option)}
                   </Option>
                 ))}
               </Select>
              }
              <div className={selectedMemValues.length>0 ? "assgin_selected_val" :''}>
                {selectedMemValues.map((value) => (
                  <Tag
                    key={value.value}
                    closable
                    onClose={() => handleMemTagClose(value.value)}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      color: "#000",
                      borderRadius: 15,
                      padding: "5px 10px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={value.image}
                      alt=""
                      style={{ marginRight: "8px" }}
                      className="select_user"
                    />
                    {value.label}
                  </Tag>
                ))}
                {selectedGrpValues.map((value) => (
                  <Tag
                    key={value.value}
                    closable
                    onClose={() => handleTagClose(value.value)}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      color: "#000",
                      borderRadius: 15,
                      padding: "5px 10px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={value.image}
                      alt=""
                      style={{ marginRight: "8px" }}
                      className="select_user"
                    />
                    {value.label}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Row className={isCustomVisible ? "d-none" : ""}>
              <Col xl={19} lg={19} md={18} sm={12} xs={24}>
                <Row className="align-center">
                  <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                    <label htmlFor="" className="fw-600">
                      Individual Weightage
                    </label>
                    <Input
                      placeholder=""
                      className="canvas-objective-input mt-1"
                      value="80 %"
                    />
                  </Col>
                  <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                    <p className="pl-2 pt-2">
                      i.e, Weightage only be a Percentage
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xl={5} lg={5} md={6} sm={12} xs={24}>
                <span
                  className="c_32B7D9 fs-12 cur-pointer text_line float-right"
                  onClick={toggleCustomVisibility}
                >
                  Custom Weightage <ArrowRightOutlined />
                </span>
              </Col>
            </Row>
          </div>
          <div>
            {isCustomVisible && (
              <div className="cw_table">
                <Row justify={"space-between"} className="mb-2">
                  <Col xl={6} lg={5} md={6} sm={6} xs={24} className="mb-2">
                    <span
                      className="c_32B7D9 fs-12 cur-pointer"
                      onClick={toggleCustomVisibility}
                    >
                      <ArrowLeftOutlined /> Custom Weightage
                    </span>
                  </Col>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="position-relative"
                  >
                    <input
                      type="search"
                      placeholder="Search Member"
                      className="w-100 table_search_inputbox pl-2"
                      value={searchTextValue}
                      // onSearch={handleSearch}
                      onChange={(e) => setSearchTextValue(e.target.value)}
                    />
                    <img
                      src="assets/images/search.svg"
                      className="table_search_img"
                      alt=""
                    />
                  </Col>
                </Row>
                <Table
                  dataSource={custom_filteredData}
                  columns={columns}
                  pagination={{ pageSize: 5 }}
                />
              </div>
            )}
          </div>
          <div className="remark-section mb-3">
            <div className="justify-between">
              <label className="canvas-objective-title">Remarks</label>
              <span className="c-0050 fs-12 mt-1">
                {wordCount} / {maxWords}
              </span>
            </div>
            <div className="objective-title-inner">
              <textarea
                rows={3}
                placeholder=""
                className="canvas-objective-input w-100"
                value={text}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className="Submit-section mb-5">
            <button className="cancel-btn">Cancel</button>
            {/* <button className="cyan_button">submit</button> */}
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default CusWeightage;
