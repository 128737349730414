import React, { useState, ChangeEvent, useRef } from "react";
import { Drawer, Form, Row, Col, Progress, Upload, Button, Tooltip, Modal, Flex, message, Input } from "antd";
import { BaseUrl, FileUrl } from "../../../App";
import { useEffect } from "react";
import axios from "axios";
import { upload } from "@testing-library/user-event/dist/upload";
import { error } from "console";


interface ProfileProps {
  percent: number;
}

interface Members {

  id: string,
  username: string,
  name: string,
  email: string,
  supervisor_id: string,
  rolename: string,
  employeeid: string,
  profilePath?: string,
  divisonName: string,
  designation?: string,
}

const Profile: React.FC<ProfileProps> = ({ percent }) => {

  const [profile, setProfile] = React.useState<Members>();

  let assignedPercent = percent;
  let completedPercent = 0;
  let unassignedPercent = 100 - percent;

  if (percent >= 100) {
    assignedPercent = 100;
    completedPercent = percent - 100;
    unassignedPercent = 0;
  } else if (percent <= 0) {
    assignedPercent = 0;
    completedPercent = 0;
    unassignedPercent = 100;
  }

  let strokeColor: string;
  if (percent === 100) {
    strokeColor = "#32B7D9"; // Completed
    completedPercent = 100;
  } else if (percent < 100) {
    strokeColor = "#C2FBFF"; // Assigned
  } else {
    strokeColor = "#EFEFEE"; // Unassigned
  }

  const [showProfile, setShowProfile] = useState(false);
  const handleProfileClick = () => {
    setShowProfile(!showProfile);
  };

  const [profilePhoto, setProfilePhoto] = useState<File | null>(null);

  function handleFileInputChange(event: any) {//React.ChangeEvent<HTMLInputElement>

    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setProfilePhoto(file); // Update profilePhoto state with the selected file
      updateProfile(file);
    }

    event.target.value = null;

  }
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleImageError = (e:any)=>{
    const target = e.target as HTMLImageElement; 
    target.onerror = null; 
    target.src = 'assets/images/Default_user.svg'; 
};

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    // Handle file upload logic here
  };
  useEffect(() => {
    MembersData();
  }, []);

  const MembersData = async () => {

    try {
      const apiUrl = BaseUrl + `api/users/getCurrentUser`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setProfile(response.data[0]);

        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }
  }

  const updateProfile = async (file: File) => {
    
    const formData = new FormData();
    formData.append('profile', file);


    try {
      const apiUrl = BaseUrl + `api/users/updateProfilePhoto`;

      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {

          localStorage.setItem("profilePath", response.data);

          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Profile Photo Updated successfully
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });
          MembersData();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }).catch((error) => {
          if (error.response.status === 415) {
            message.error({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <span className="c_FF4747 fs-16">
                    UnSupported Media type
                  </span>
                </div>
              ),
              duration: 1,
              icon: null,
            });
            // console.log("UnSupported Media type : " + error.response.data);
          } else {
            // console.log("something went wrong : " + error.response.data);
          }

        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }
  }

  const [visible, setVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      message.error('New passwords do not match');
      return;
    }

    try {
      const apiUrl = BaseUrl + `api/users/changePassword`;
      const formData = new FormData();
      formData.append('oldPassword', oldPassword);
      formData.append('newPassword', newPassword);

      const response = await axios.put(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (response.data === 'Incorrect Password') {
        setOldPasswordError(response.data);
        return;
      } else if (response.data === 'Password is Empty') {
        setOldPasswordError(response.data);
        return;
      }

      // if (response.data === 'Incorrect Password') {
      //   setOldPasswordError(response.data);
      //   return;
      // }

      message.success({
        content: (
          <div className="success-message justify-center align-center p-1">
            <img src="assets/images/check-circle.svg" alt="Success Icon" />
            <span className="c_32C6A0 fs-16">
              Password changed successfully
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setVisible(false);
      setOldPasswordError('');
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };


  return (
    <div>
      <Tooltip placement="top" title="profile" arrow={true}>
        <img
          src="assets/images/export.svg"
          alt=""
          className="d-flex float-right cur-pointer"
          onClick={handleProfileClick}
        />
      </Tooltip>

      <Drawer
        title={"Your Profile"}
        size="large"
        className="objective-canvas  position-relative"
        open={showProfile}
        onClose={handleProfileClick}
        maskClosable={false}
      >
        <div className="border_bottom">
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={24}>
              <div className="position-relative">
                <img
                  // src="assets/images/Default_user.svg"
                  src={profile?.profilePath ? `${FileUrl}${profile?.profilePath}` : "assets/images/Default_user.svg"}
                  onError={handleImageError}
                  className="profile_img"
                  alt=""
                />

                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleFileInputChange(e)}
                  style={{ display: 'none' }}
                />
                <button className="profile-upload position-absolute" id="profile" onClick={handleUploadClick}>
                  <img src="assets/images/camera.svg" onClick={(e) => handleFileInputChange(e)} />
                </button>

              </div>
            </Col>

            <Col xl={18} lg={18} md={18} sm={12} xs={24}>
              <div className="pl-3">
                <div className="d-flex justify-between">
                  <p className="m-0 fs-28 fw-800 mb-3">{profile?.name}</p>
                  <p className="c_32B7D9 text_line fs-16 cur-pointer" onClick={() => setVisible(true)}>Change Password</p>
                </div>
                <p className="m-0 fw-500 fs-18 mb-3">{profile?.designation}</p>
                <p className="mt-2 fs-18 c_aaa mb-3">{profile?.divisonName}</p>
                <p className="m-0 fw-500 fs-18 d-flex">
                  <img src="assets/images/sms.svg" alt="" className="pr-2" />{" "}
                  {profile?.email}
                </p>
              </div>
            </Col>
          </Row>

          <Modal
            title={<p className="c_32B7D9 fs-16 fw-600">Change Password</p>}
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            closable={false}
            width={400}
          >
            <div className="password-bg">
              <label htmlFor="" className="fw-700 fs-14 c_000">
                Old Password
              </label>
              <Input
                type="password"
                name="oldPassword"
                placeholder="Enter Old Password"
                className="mt-1 mb-3"
                required={true}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />

              <label htmlFor="" className="fw-700 fs-14 c_000">
                New Password
              </label>
              <Input
                type="password"
                name="newPassword"
                placeholder="Enter New Password"
                className="mt-1 mb-3"
                required={true}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label htmlFor="" className="fw-700 fs-14 c_000">
                Confirm Password
              </label>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Confirm New Password"
                className="mt-1 mb-3"
                required={true}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {oldPasswordError && <span className="c_FF4747">{oldPasswordError}</span>} <br />
              {newPassword !== confirmPassword && (
                <span className="c_FF4747">Passwords do not match</span>
              )}
            </div>
            <div className="modal-footer justify-end mt-3">
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
              <button className="cyan_button" onClick={handlePasswordChange}>
                Change Password
              </button>
            </div>
          </Modal>
          {/* <div className=" mt-2 mb-5">
            <Row className="justify-between">
                <p className="fs-14 fw-600">Your Weightage</p>
                <p className="align-center dm-block">
                    <span className="align-center pr-1 c_AAA"><img src="assets/images/assigned.svg" className="pr-1" alt="" /> Assigned<b className="pl-1 c_000">{assignedPercent}%</b></span>
                    <span className="align-center pr-1 c_AAA"><img src="assets/images/completed.svg" className="pr-1" alt="" />Completed<b className="pl-1 c_000">{completedPercent}%</b></span>
                    <span className="align-center pr-1 c_AAA"><img src="assets/images/unassigned.svg" className="pr-1" alt="" />Unassigned<b className="pl-1 c_000">{unassignedPercent}%</b></span>
                </p>
            </Row>
            <div className=" member-progress">
              <Progress
                percent={percent}
                className=""
                strokeColor={{ from: strokeColor, to: strokeColor }}
                size="small"
              />
            </div>
          </div> */}
        </div>
      </Drawer>
    </div>
  );
};

export default Profile;
