import React, {useEffect,useState } from 'react';
import { Tabs } from 'antd';
import Hierarchy from '../Explorer/Hierarchy';
import Explorer_Objectives  from '../Explorer/Explorer_Objectives';
import { BaseUrl } from "../../../App";
import axios from "axios";
import overallCheck from "../../../overallCheck";
const { TabPane } = Tabs;



const Objective: React.FC = () => {

// =================Roles And Permission==============================
  /*** Role and permission section start **/
  const [permission_hnetwork, setPermission_hnetwork] = useState('');
  const [permission_objnetwork, setPermission_objnetwork] = useState('');
  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
      const data = response.data;
      setPermission_hnetwork(data[0].hnetwork);
      setPermission_objnetwork(data[0].objnetwork);
        // if(permission.menu==="Edit"){
        //   setPermission_objedit(permission.parent_objective);
        //   setPermission_childobjedit(permission.child_objective);
        // }
        // if(permission.menu==="Delete"){
        //   setPermission_objdelete(permission.parent_objective);
        //   setPermission_childobjdelete(permission.parent_objective)
        // }
        
      //})
  
      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // =================Roles And Permission==============================
  const onChange = (key: string) => {
       
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    GetUserAccess();
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)
          //return () => clearInterval(intervalId);
          //setIntervalId(null);
          //setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
      console.log("ERROR::::::::", error);
    }
  }
  return (
    <div className=''>
      <Tabs defaultActiveKey="1" className='objectivetabs-container sub_tab explorer_tab' onChange={onChange}>
      {permission_hnetwork ?(
      <TabPane
          tab={
            <div>
              <img src="assets/images/people.svg" alt="people" />
              <span>Hierarchy</span>
            </div>
          }
          key="1"
        >
          <div>
            <Hierarchy />
          </div>
        </TabPane>
      ):(<></>)}
        {permission_objnetwork ?(<TabPane
          tab={
            <div>
              <img src="assets/images/crown.svg" alt="crown" />
              <span> Objectives</span>
            </div>
          }
          key="2"
        >
         <Explorer_Objectives /> 
        </TabPane>):(<></>)}
       
      </Tabs>
    </div>
  );
};

export default Objective;
