import React, { useState } from "react";
import { Drawer, Select, Input, Form, Tag, Avatar, message } from "antd";
// import { Option } from "antd/lib/mentions";
import { BaseUrl ,FileUrl } from "../../../App";
import { useEffect } from "react";
import axios from "axios";

const { Option } = Select;

interface OptionItem {
    value: string;
    label: string;
    image: string;
}



const Memoptions1: OptionItem[] = [
    { value: "value1", label: "renu", image: "assets/images/Avatar1.png" },
    { value: "value2", label: "rose", image: "assets/images/user.svg" },
    { value: "value3", label: "harini", image: "" },
    { value: "value4", label: "tamil", image: "assets/images/user1.svg" },
    { value: "value5", label: "vicky", image: "assets/images/Avatar1.png" },
    { value: "value6", label: "priya", image: "assets/images/user2.svg" },
    { value: "value7", label: "divya", image: "assets/images/Avatar1.png" },
];

interface group {
    id: string,
    groupname: string,
    assignedusers: any[]
}
interface groups {
    groupValue: group,
    GroupList:()=>void,
    MembersList:()=>void
}

const EditGroup: React.FC<groups> = ({ groupValue,GroupList,MembersList}) => {

    const [selectedMemValues, setSelectedMemValues] = useState<Members[]>([]);
    const [Memberoption, setMemberOption] = useState<Members[]>([]);
    const handleMemSelectChange = (selected: any[]) => {

        //     const selectedIds = selected.map(item => item.value);
        //     const selectedMembers = Memberoption.filter(option => selectedIds.includes(option.id));
        // //     const selectedMemValues = Memberoption.filter((member) =>
        // //   selected.some((option) => option.id === member.id)
        // // );
        //     // setSelectedMemValues(selectedMemValues);
        //       setSelectedMemValues(prevSelectedMemValues => [...prevSelectedMemValues, ...selectedMembers]);

        const selectedIds = selected.map(item => item.value);

        // Filter out the selected members that are already present in selectedMemValues
        const newSelectedMembers = selectedIds.filter(id => !selectedMemValues.some(member => member.id === id));

        // Find the member objects corresponding to the newly selected ids
        const selectedMembers = Memberoption.filter(option => selectedIds.includes(option.id));

        // Update selectedMemValues state by merging the existing state with the newly selected members
        setSelectedMemValues(selectedMembers);
       // setSelectedMemValues(prevSelectedMemValues => [...prevSelectedMemValues, ...selectedMembers]);

    };

    const handleMemTagClose = (valueToRemove: string) => {
        const updatedMemValues = selectedMemValues.filter(
            (value) => value.id !== valueToRemove
        );
        setSelectedMemValues(updatedMemValues);
    };

    const handleImageError = (e:any)=>{
        const target = e.target as HTMLImageElement; 
        target.onerror = null; 
        target.src = 'assets/images/Default_user.svg'; 
    };

    const renderOption = (option: Members) => (
        <div>
            <Avatar size="small" src={"assets/images/Avatar1.png"} style={{ marginRight: "8px" }} />
            {option.name}
        </div>
    );

    // Memoptions.forEach((option) => {
    //     if (!option.image || option.image.trim() === "") {
    //         option.image = "assets/images/Default_user.svg";
    //     }
    // });

    const [isSearchMem, setisSearchMem] = useState(false);
    const handleSearchMember = () => {
        setisSearchMem(!isSearchMem);
    };

    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };

    interface Members {

        id: string,
        username: string,
        name: string,
        email: string,
        supervisor_id: string,
        employeeid: string,
        profilePath: string,
    }

    interface maxWords {
        name_max_word_count: number;
        description_max_word_count: number;
        remark_max_word_count: number;
    };
    const [maxWords, setMaxWords] = useState<maxWords>({
        name_max_word_count: 0,
        description_max_word_count: 0,
        remark_max_word_count: 0,
    })

    const getWordCount = async () => {
        try {
            const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Groups");
            setMaxWords(maxWords.data);
        } catch (error) {
            // console.log(error);
        }
    };

    // useEffect(() => {
    //     MembersList();
    //     setGroupNameFromGroupValue();
    //     setAssignedMembersFromGroupValue();
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            await MembersList1();
            setGroupNameFromGroupValue();
            // await setAssignedMembersFromGroupValue();
        };
        getWordCount();
        fetchData();
    }, []);




    const setGroupNameFromGroupValue = () => {
        setGroup({
            ...group,
            groupname: groupValue.groupname,

        });
    };

    const setAssignedMembersFromGroupValue = async () => {

        const selectedIds = groupValue.assignedusers.map(item => item.id);
        const selectedMembers = Memberoption.filter(option => selectedIds.includes(option.id));
        // const selectedMembers = Memberoption.filter((member) =>
        // groupValue.assignedusers.some((option) => option.id === member.id)
        // );
        setSelectedMemValues(selectedMembers);
    }





    const MembersList1 = async () => {

        try {
            const apiUrl = BaseUrl + `api/users/userListBySupervisor/${localStorage.year}/${localStorage.Quarter}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    setMemberOption(response.data);
                    // onClose();
                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }
    }



    const [group, setGroup] = useState({
        groupname: "",
        assignedusers: []
    });


    // const handlegroupInputChange = (event: any) => {
    //     const { name, value } = event.target;

    //     setGroup({
    //         ...group,
    //         [name]: value
    //     });
    // };
    const handlegroupInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setGroup: Function,
        maxWords: number
    ) => {
        const newText = event.target.value;
        const inputName = event.target.name;
        // Check if the input exceeds the maximum word count
        if (newText.trim().split(/\s+/).join("").length <= maxWords) {
            // Update the state based on the input name
            if (Object.keys(group).includes(inputName)) {
                setGroup({
                    ...group,
                    [inputName]: newText
                });
            }
        }
    }


    const editGroup = async () => {

        const formData = new FormData();

        const userObjectIdList: any[] = [];

        selectedMemValues.forEach((member) => {
            if (member.id !== null) {
                userObjectIdList.push({ id: member.id, employeeId: member.employeeid });
            }
        });

        userObjectIdList.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                formData.append(`assignedusers[${index}].${key}`, String(value));
            });
        });


        formData.append("groupname", group.groupname);

        if (userObjectIdList.length === 0 || group.groupname === "") {
            message.error({
                content: (
                    <div className="success-message justify-center align-center p-1 d-flex">
                        {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
                        <span className="c_FF4747 fs-16">
                            {group.groupname === "" ?
                                ("Please Enter the group name") : ("No members is Added")}
                        </span>
                    </div>
                ),
                duration: 2,
                icon: null,
            });
            return;
        }


        try {
            const apiUrl = BaseUrl + `api/group/` + groupValue.id;

            const response = await axios.put(apiUrl, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        message.success({
                            content: (
                                <div className="success-message justify-center align-center p-1">
                                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                                    <span className="c_32C6A0 fs-16">
                                        Group Updated successfully
                                    </span>
                                </div>
                            ),
                            duration: 2,
                            icon: null,
                        });
                        onClose();
                      
                        setTimeout(() => {
                            GroupList();
                            MembersList();
                            // window.location.reload();
                          }, 1000);
                       // window.location.reload();
                    }else if(response.status===226){

                        message.error({
                            content: (
                                <div className="success-message justify-center align-center p-1">
                                    {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
                                    <span className="c_FF4747 fs-16">
                                        Group Name Exist
                                    </span>
                                </div>
                            ),
                            duration: 2,
                            icon: null,
                        });
                      
                    }else{
                        // console.log("something went wrong ");
                    }

                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }


    }



    return (
        <div className="">
             <button className="border-0 bg-transparent fs-14 align-center"  onClick={() => { setOpen(true); setAssignedMembersFromGroupValue() }} >
                <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
                Edit
            </button>
                
            <Drawer title={"Edit Group"} size="large"  maskClosable={false} onClose={onClose} open={open} className="objective-canvas position-relative" >
                <Form className="mb-5">
                    <div className="title-section mb-3">
                        <div className="justify-between">
                            <label htmlFor="" className="fw-600">
                                Group Name
                            </label>
                            <span className="c-0050 fs-12 mt-1">
                                {group.groupname.trim().split(/\s+/).join("").length}{" "}/
                                {maxWords.name_max_word_count}
                        </span>
                        </div>
                        <Input
                            type="text"
                            name="groupname"
                            value={group.groupname}
                            // onChange={handlegroupInputChange}
                            onChange={(e) =>
                                handlegroupInputChange(
                                    e,
                                    setGroup,
                                    maxWords.name_max_word_count
                                )
                            }
                            className="canvas-objective-input mt-1"
                        />
                    </div>
                    <div className="assign-section mb-3">
                        <label htmlFor="" className="fw-600">
                            Assign Members
                        </label>
                        <br />
                        <div className="mt-1">
                            <Select
                                mode="multiple"
                                placeholder={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                            src="assets/images/member.svg"
                                            alt="Placeholder"
                                            style={{ marginRight: "8px" }}
                                        />
                                        <span>Select Member</span>
                                    </div>
                                }
                                onChange={handleMemSelectChange}
                                // value={selectedMemValues}
                                value={selectedMemValues.map(item => ({ label: item.name, value: item.id }))}
                                className="w-100 form-control"
                                labelInValue
                                optionLabelProp="value" // Change optionLabelProp to "value"
                                dropdownRender={(menu) => <div>{menu}</div>}
                            // options={Memberoption}
                            // filterOption={(input, option) =>
                            //   option ? option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            // }
                            filterOption={(input, option) =>
                                option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                              }
                            >
                                {Memberoption.length > 0 && Memberoption.map((option, index) => (
                                    <Option
                                         key={option.id}
                                        // key={option.id + '_' + index}
                                        value={option.id}
                                        label={option.name}

                                    // Use children prop to define label content
                                    >
                                        <div>
                                            <img className="member_user" src={option.profilePath?FileUrl+option.profilePath:"assets/images/Default_user.svg"} style={{ marginRight: "8px" }} onError={handleImageError} />
                                            {option.name}
                                        </div>
                                    </Option>
                                ))}
                            </Select>

                        </div>
                        <div
                            className={
                                selectedMemValues.length > 0
                                    ? "assgin_selected_val add-members"
                                    : ""
                            }
                        >
                            <span className={selectedMemValues.length > 0 ? "c_32B7D9 fs-12 fw-600" : "d-none"}>Members &#40;{selectedMemValues.length}&#41;</span><br />
                            {selectedMemValues.map((value) => (
                                <Tag
                                    key={value.id}
                                    closable
                                    onClose={() => handleMemTagClose(value.id)}
                                    style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                    className="mt-1"
                                >
                                    <img
                                        // src={"assets/images/Avatar1.png"}
                                        src={value.profilePath?FileUrl+value.profilePath:"assets/images/Default_user.svg"}
                                        onError={handleImageError}
                                        alt=""
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                    />
                                    {value.name}
                                </Tag>
                            ))}
                        </div>
                    </div>

                    <div className="Submit-section">
                        <button className="cancel-btn" onClick={onClose}>Back </button>
                        <button className="cyan_button" onClick={editGroup}>Update</button>
                    </div>
                </Form>
            </Drawer>
        </div>
    )
}

export default EditGroup;
