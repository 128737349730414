import React, { useEffect,useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { Flex, Menu, Row, Col, Tooltip } from "antd";
import "./Header.css";
import type { MenuProps } from 'antd';
import { Dropdown, Space,Select } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import isBearerTokenExpired from '../../isBearerTokenExpired';
import Profile from '../Pages/Modal/profile';
import Notification from '../Pages/Modal/Notification';
import { FileUrl,BaseUrl } from "../../App";
import axios from "axios";
import MyObjective from "../Pages/Objective/SubObjectiveTab/MyObjective";
import Appraisal from "../Pages/Appraisal/Appraisal";


interface HeaderProps {
  onLogout: () => void;
  toggleSidebar: () => void;
  sidebarOpen: boolean;
}

const Header: React.FC<HeaderProps> = ({ onLogout, toggleSidebar, sidebarOpen }) => {
  const location = useLocation();
  const userRole: string | null = localStorage.getItem("rolename");
  const adminStatus: string | null = localStorage.getItem("adminstatus");
  const [intervalId, setIntervalId] = useState<any>(null);
  
  const checkTokenExpiration = () => {
    const tokenExpired = isBearerTokenExpired();
    if (tokenExpired) {
      onLogout();
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    onLogout();
  };

  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const [year,setyear] = useState(localStorage.getItem('year')); 
  useEffect(() => {
    RefreshToken();
    checkTokenExpiration();
    const id = setInterval(checkTokenExpiration, 10000);
    setIntervalId(id);
    // getcurrentyear()
    // getcurrentQuarter();
    return () => clearInterval(id);
  }, [location]);
  const[quarter,setquarter] = useState(localStorage.getItem('Quarter'))
  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
    setquarter(value)
    localStorage.setItem("Quarter",value);
    window.dispatchEvent(new Event('storage'));
    // <MyObjective reloadObj={false} Quarter={value}/>
  };
  const RefreshToken = async () => {
    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  
  const getcurrentyear = async () => {
    try {
      const apiUrl = BaseUrl + `api/organization/getCycle`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          localStorage.setItem("year",response.data)
          setyear(response.data);
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  const getcurrentQuarter = async () => {
    try {
      const apiUrl = BaseUrl + `api/organization/getcurrentquarter`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          if(localStorage.getItem("Quarter")==null){
            localStorage.setItem("Quarter",response.data.data)
            setquarter("Q1")
            window.dispatchEvent(new Event('storage'));
          }
          
          // localStorage.setItem("Quarter",value);
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  const [appraisalQuarter , setAppraisalQuarter] =useState(true);
  return (
    <div className={sidebarOpen ? "header-open" : ""}>
      <Flex className="p-2" justify="space-between" align="center">
        <div><MenuOutlined onClick={toggleSidebar} className="toggle-menu-icon" /></div>
        <div className="align-center justify-end">
        <div className="header_select mr-1">
        {location.pathname !== '/SignOff' && (
          <Select
            className="Qselect_bg"
            onChange={handleChange}
            value={quarter}
            options={[
              { value: 'Q1', label: 'Q1'+" "+year },
              { value: 'Q2', label: 'Q2'+" "+year },
              { value: 'Q3', label: 'Q3'+" "+year },
              { value: 'Q4', label: 'Q4'+" "+year },
            ]}
          /> )}
          </div>
            <div>
              <Notification />
            </div>          
          <Dropdown className="z-1" trigger={['click']} overlay={
            <Menu className="profile_card">
              <Menu.Item key="0">
                <Row className="align-center">
                  <Col xl={16} lg={16} md={16} sm={16} xs={14}>
                    <Tooltip placement="top" title="Cavinkare Private Limited" arrow={true}>
                      <p className="fs-14 cmy_name m-0 mt-1">Cavinkare Pvt limited</p>
                    </Tooltip>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={10} className="align-center" onClick={handleLogout} >
                    <img src="assets/images/ph-sign-out.svg" alt="" />  <span className="c_EC174F fs-12 pl-1">Sign Out</span>
                  </Col>
                </Row>
              </Menu.Item>
              <Menu.Item key="0" className="mt-1 pointer-auto">
                <Row className="align-center">
                  <Col xl={6} lg={6} md={6} sm={6} xs={6} >
                    <img src={profilePhoto} className="sign_user" alt=""
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = 'assets/images/Default_user.svg';
                      }} />

                  </Col>
                  <Col xl={14} lg={14} md={14} sm={14} xs={14}>
                    <p className="c_000 fs-16 fw-700 m-0">{localStorage.name}</p>
                    <p className="c_000 fs-12 m-0">{localStorage.designation}</p>
                    <p className="c_AAA fs-12 m-0">{localStorage.email}</p>
                    {adminStatus === "1" ? (<Link to="/admin_panel" className="c_47E0FF text_line fs-14 m-0 mt-1">Admin panel</Link>) : ("")}<br></br>
                    {adminStatus === "1" ? (<Link to="/userlist" className="c_47E0FF text_line fs-14 m-0 mt-1">User List</Link>) : ("")}
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    {/* <img src="assets/images/export.svg" alt="" className="d-flex float-right cur-pointer"/> */}
                    <Profile percent={50} />
                  </Col>
                </Row>
              </Menu.Item>
            </Menu>
          }>
            <a onClick={(e) => e.preventDefault()} href="#hgj">
              <Space>
                <img src={profilePhoto} className="user" alt=""
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = 'assets/images/Default_user.svg';
                  }}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      </Flex>
    </div>
  );
};

export default Header;
