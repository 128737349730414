
import React, { useState, ChangeEvent, useTransition } from "react";
import { useEffect } from "react";
import { BaseUrl, FileUrl } from "../../../../App";
import axios from "axios";
import {
  Dropdown,
  Space,
  Modal,
  message,
  Flex,
  Avatar,
  Tooltip,
  MenuProps,
  Row,
  Col,
  Card,
  Input,
  Drawer,
  Select,
  Tag,
  Form,
  Table,
  Radio,
  Progress,
  Pagination,
  Descriptions,
  Menu,
  Spin,
  Checkbox,
  Divider,
} from "antd";
import { StopOutlined, CheckOutlined, DownOutlined } from "@ant-design/icons";


import {
  AntDesignOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import AddObj from "../../Modal/AddObj";
import ReviewCascade from "../../Modal/ReviewCascade";
import CreateParentObj from "../../Modal/CreateParentObj";
import EditCreateParent from "../../Modal/EditParentObj";
import AddKR from "../../Modal/AddKR";
import LeadMessure from "../../Modal/LeadMeasure";
import KRDescription from "../../Modal/KRDescription";
import EditParentObj from "../../Modal/EditParentObj";
import Conversation from "../../Modal/Conversation";
import AssignObj from "../../Modal/AssignObj";
import { Tabs } from 'antd';
import MyObjWeightage from "./MyObjWeightage";
import ReviewKRWeightage from "./ReviewKRWeightage";
import EditObj from "../../Modal/EditObj";
import { truncate } from "fs/promises";
import ReviewALLKRWeightage from "./ReviewCascadeAllKr";

const { TabPane } = Tabs;
const { Option } = Select;
const onChange = (key: string) => {
};
const percent = 70;

interface CustomPaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  // imagePath: string;
  krId: string;
  leadMeasure: LeadMeasure[];
  createdByName: string,
}
interface status {
  status: boolean
}
interface ImagePaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  // imagePath: string;
  krId: string;
  leadMeasure: LeadMeasure[];
}
interface LeadMeasure {
  id: number;
  krId: string;
  objId: string;
  orgObjId: string;
  description: string;
  createdByName: string;
  createdByProfilePath: string;
}

interface ContentData {
  id: number,

}

interface KeyResult {
  id: number;
  krId: string;
  objId: string;
  orgObjId: string;
  description: string;
  keytitile: string;
}

const rolename = localStorage.getItem("rolename");
const CustomPagination: React.FC<CustomPaginationProps> = ({
  current,
  total,
  onChange,
  leadMeasure,
  krId,
  createdByName,
}) => {
  const filteredContentData = leadMeasure.filter(data => data.krId === krId);
  const currentItem = filteredContentData[current - 1];
  const displayText = (
    <span>
      <strong>{current}</strong> / {Math.ceil(total / 1)}
    </span>
  );
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const displayPicture = (
    <div className="d-flex align-center">
      <img
        src={profilePhoto}
        className="avatar"
        onError={handleImageError}
      />
      <span className="user_name mx-1">{createdByName}</span>
    </div>
  );


  return (
    <div className="custom-pagination mt-2">
      <div className="pagination-head d-flex justify-between mb-2">
        <div className="profile">
          <div className="d-flex align-center">
            <img
              src={currentItem.createdByProfilePath ? FileUrl + currentItem.createdByProfilePath : "assets/images/Default_user.svg"}
              className="avatar" onError={handleImageError}
            />
            <span className="user_name mx-1">{currentItem.createdByName}</span>
          </div>
        </div>
        <div className="text-end mt-1">
          <button
            className="next_pre_btn"
            disabled={current === 1}
            onClick={() => onChange(current - 1)}
            style={{ marginRight: "8px", color: "#fff" }}
          >
            <LeftOutlined />
          </button>
          <span>{displayText}</span>
          <button
            className="next_pre_btn"
            disabled={current === Math.ceil(total / 1)}
            onClick={() => onChange(current + 1)}
            style={{ marginLeft: "8px", color: "#fff" }}
          >
            <RightOutlined />
          </button>

        </div>
      </div>
      {/* <h4>Description</h4> */}
      <div className="pagination-content">
        <span>{currentItem?.description}</span>
      </div>
    </div>

  );
};
const handleImageError = (e: any) => {
  const target = e.target as HTMLImageElement;
  target.onerror = null;
  target.src = 'assets/images/Default_user.svg';
};
const ImagePagination: React.FC<ImagePaginationProps> = ({ leadMeasure, current, total, krId, onChange }) => {

  const filteredContentData = leadMeasure.filter(data => data.krId === krId);

  const currentItem = filteredContentData[current - 1];
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const displayImage = (
    <div className="d-flex align-center">
      <img
        src={currentItem.createdByProfilePath ? FileUrl + currentItem.createdByProfilePath : "assets/images/Default_user.svg"}
        className="avatar" onError={handleImageError}
      />
      <span className="user_name mx-1">{currentItem.createdByName}</span>
    </div>

  );


  return (
    <div className="image-pagination w-100">
      <div className="pagination-head mb-2">
        <div className="text-end d-flex">
          <button
            className="next_pre_btn"
            disabled={current === 1}
            onClick={() => onChange(current - 1)}
            style={{ marginRight: "8px", color: "#fff" }}
          >
            <LeftOutlined />
          </button>
          <span>{displayImage}</span>
          <button
            className="next_pre_btn"
            disabled={current === Math.ceil(total / 1)}
            onClick={() => onChange(current + 1)}
            style={{ marginLeft: "8px", color: "#fff" }}
          >
            <RightOutlined />
          </button>
          {/* <Pagination
            current={current}
            total={total}
            pageSize={1}
            onChange={onChange}
            showSizeChanger={false}
          /> */}
        </div>
      </div>
      {/* <h4>Description</h4> */}
      <div className="pagination-content">
        <Card>{currentItem?.description}</Card>
      </div>
    </div>

  );
};
interface reloadObjectivesList {
  reloadObjectivesList: () => void
}
const CFT: React.FC<reloadObjectivesList> = ({ reloadObjectivesList }) => {
  const [leadopen, leadsetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [leadOpen, setLeadOpen] = useState(false);
  const [selectedKrId, setselectedKrId] = useState<string | null>(null);
  const [showkrModal, setShowKrModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [memberActive, setMemberActive] = useState(false);


  // =================Roles And Permission==============================
  const [permission_childobjview, setPermission_childobjview] = useState('');
  const [permission_parentobjview, setPermission_parentobjview] = useState('');
  const [permission_childobjedit, setPermission_childobjedit] = useState('');
  const [permission_objedit, setPermission_objedit] = useState('');
  const [permission_objdelete, setPermission_objdelete] = useState('');
  const [permission_childobjdelete, setPermission_childobjdelete] = useState('');
  const [permission_kredit, setPermission_kredit] = useState('');
  const [permission_krdelete, setPermission_krdelete] = useState('');
  const [permission_krview, setPermission_krview] = useState('');
  const [permission_lmview, setPermission_lmview] = useState('');
  const [permission_lmcreate, setPermission_lmcreate] = useState('');
  const [permission_lmedit, setPermission_lmedit] = useState('');
  const [permission_lmdelete, setPermission_lmdelete] = useState('');
  // =================Roles And Permission==============================


  const [selectedKrValue, setSelectedKrValue] = useState<string>('All Key Results');

  const handleKrChange = (value: string,objectiveid:string,userid:string) => {
    
    setSelectedKrValue(value);
    if(value=="All Key Results"){
      getallkeyresults(objectiveid,userid);
    }
    else if(value=="My Key Results"){
      getassignedkeyforobjectives(objectiveid,userid);
    }
    else{
      getteamkeyresult(objectiveid,localStorage.getItem("username"));
    }
  };
 


  const handleKrshow = () => {
    setShowKrModal(!showkrModal);

  };

  const handlekrClose = () => {
    setShowKrModal(false);
  };

  const showDrawer = () => {
    leadsetOpen(true);
  };

  type krdescription = {
    objname: string,
    objid: string,
    krid: string,
    krtitle: string
  }
  const [keyresultid, setkeyresultids] = useState<krdescription>({
    objname: "",
    objid: "",
    krid: "",
    krtitle: ""
  }
  );
  const [isPending, startTransition] = useTransition();
  const krshowDrawer = (objective: any) => {
    setOpen(true)
    setkeyresultids({
      ...keyresultid,
      krid: objective.id,
      krtitle: objective.keytitle,
      objname: "",
      objid: ""
    })

  };
  const setKrValues = (e: any, objective: any) => {
    e.preventDefault();
    setkeyresultids({
      ...keyresultid,
      krid: objective.id,
      krtitle: objective.keytitle,
      objname: "",
      objid: ""
    })

  };
  const leadshowDrawer = (assignedId: string) => {

    setselectedKrId(assignedId.toString()); // Ensure assignedId is always a string
    setLeadOpen(true);
  };

  const [selectedKeyResult, setSelectedKeyResult] = useState({
    id: "",
    objectiveid: "",
    orgobjectiveid: "",
    keytitle: "",
    keyquarter: "",
  });

  // const [selectedKeyResult,setSelectedKeyResult]=useState({});

  const setKeyResult = (keyResult: any) => {
    //console.log("selected key result : ",keyResult);
    setSelectedKeyResult(keyResult);
  }


  const onClose = () => {
    setOpen(false);
  };
  const onLeadClose = () => {
    setShow(!show)
    setLeadOpen(false);
  };

  const handleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    if (showCustomWeightage.some(user => user.avaiableWeightage == 0) && text == "UnRevoke") {
      Modal.error({
        content: (
          <>
            <p className="fs-16 mb-3">{objectiveAndUser?.objname}</p>
            <p className="fs-18"><b>Note : </b>Users Available Weightage Is Zero</p>
          </>
        ),
        centered: true,
      })
    }
    else {
      Modal.confirm({
        title: "Do you want to " + text + " this objective ?",
        content: (
          <>
            <p className="fs-16 mb-3">
              {objectiveAndUser?.objname}
            </p>
            <p className="fs-14">
              <b>Note : </b> {text} objective will lead to delete all
              corresponding objectives & key results permanently.
            </p>
          </>
        ),
        okText: text,
        cancelText: "Cancel",
        okButtonProps: { className: "C_btn" },
        cancelButtonProps: { className: "outline_btn" },
        centered: true,
        async onOk() {
          try {
            const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
              "objectiveid": objectiveAndUser?.objid,
              "status": revertstatus,
              "orgObjectiveId": objectiveAndUser?.orgobjid,
              "masterobjid": objectiveAndUser?.objectiveid,
              "userId": localStorage.getItem("loginId"),
              headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
            });
            if (response.status == 200) {
              if (revertstatus == 0) {
                message.success({
                  content: (
                    <div className="success-message justify-center align-center p-1">
                      <img src="assets/images/check-circle.svg" alt="Success Icon" />
                      <span className="c_32C6A0 fs-16">

                        Objective UnRevoked
                      </span>
                    </div>
                  ),
                  duration: 1,
                  icon: null,
                });
              } else {
                message.success({
                  content: (
                    <div className="success-message justify-center align-center p-1">
                      <img src="assets/images/check-circle.svg" alt="Success Icon" />
                      <span className="c_32C6A0 fs-16">

                        Objective Revoked
                      </span>
                    </div>
                  ),
                  duration: 1,
                  icon: null,
                });

              }

              setTimeout(() => {
                objectivelist();
                // window.location.reload();
              }
                , 1000);
            } else {
              message.error("Revert failed");
            }
          } catch (error) {
            // Handle network errors or other exceptions
            // console.error("Error:", error);
            // For example, show an error message
            message.error("An error occurred");
          }
        },
      });
    }

  };
  const handleRevertorg = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {orgobjectiveAndUser?.objectiveName}
          </p>
          <p className="fs-14">
            <b>Note : </b> {text} objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/organization/status/orgobjrevoke`, {
            "objectiveid": "",
            "status": revertstatus,
            "orgObjectiveId": orgobjectiveAndUser?.id,
            "masterobjid": "",
            "userId": localStorage.getItem('loginId'),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 4,
                icon: null,
              });

            }
            Orgobjectivelist()
            // objectivelist();
            setTimeout(() => {
              // window.location.reload();
            }
              , 2000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          // Handle network errors or other exceptions
          // console.error("Error:", error);
          // For example, show an error message
          message.error("An error occurred");
        }
      },
    });


  };

  const childHandleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {childObjectiveAndUser?.obj_name}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
            "objectiveid": childObjectiveAndUser?.obj_id,
            "status": revertstatus,
            "orgObjectiveId": childObjectiveAndUser?.orgObjId,
            "masterobjid": childObjectiveAndUser?.objectiveId,
            "userId": localStorage.getItem("loginId"),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });

            }
            setTimeout(() => {
              objectivelist();
              // window.location.reload();
            }
              , 1000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          // Handle network errors or other exceptions
          // console.error("Error:", error);
          // For example, show an error message
          message.error("An error occurred");
        }
      },
    });
  };

  const subchildHandleRevert = (revertstatus: number) => {
    var text;
    if (revertstatus != 0) {
      text = "Revoke"
    }
    else {
      text = "UnRevoke"
    }
    Modal.confirm({
      title: "Do you want to " + text + " this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {childOrgObjectiveAndUser?.obj_name}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: text,
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      async onOk() {
        try {
          const response = await axios.post(`${BaseUrl}api/objectives/status/revoke`, {
            "objectiveid": childOrgObjectiveAndUser?.obj_id,
            "status": revertstatus,
            "orgObjectiveId": childOrgObjectiveAndUser?.orgObjId,
            "masterobjid": childOrgObjectiveAndUser?.objectiveId,
            "userId": localStorage.getItem("loginId"),
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
          });
          if (response.status == 200) {
            if (revertstatus == 0) {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective UnRevoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });
            } else {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">

                      Objective Revoked
                    </span>
                  </div>
                ),
                duration: 1,
                icon: null,
              });

            }
            setTimeout(() => {
              objectivelist();
              // window.location.reload();
            }
              , 1000);
          } else {
            message.error("Revert failed");
          }
        } catch (error) {
          // Handle network errors or other exceptions
          // console.error("Error:", error);
          // For example, show an error message
          message.error("An error occurred");
        }
      },
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Do you want to delete this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3">
            {objectiveAndUser?.objname}
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteParentObjective();
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      },

      onCancel() {
      },
      // objectivelist();
    });
  };

  const [objectiveId, setObjectiveId] = useState<string | null>(null);
  const [objectivestaus, setobjectivestatus] = useState<string | null>((null));

  // objective hide and show
  //const [staticValue, setStaticValue] = useState<boolean>(false);
  interface ObjectiveUser {
    objname: string;
    objcode: string;
    createdbyname: string;
    status: String;
  }
  // const [objectiveuserslist,setobjectiveuserlist] = useState([
  //   {objname:"Object 5",
  //   objcode:"ob001",
  //   createdbyname:"usha",
  //   quartervalue:"Q3",
  //   orgobjid:"65c4ab755b916b705e15f5c2"
  // },
  // {objname:"Object 5",
  //   objcode:"ob002",
  //   createdbyname:"usha",
  //   quartervalue:"Q3",
  //   orgobjid:"65c4ab755b916b705e15f5c2"
  // }
  // ]);
  type cftstatus = {
    cftstatus: number
  }
  type objectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectiveId: "",
    orgObjId: "",
    organizationStatus: string,
    objectivename: "",
    status: string,
    createdby: "",
    objectiveassignedusers: assigndetails[],
    createdbyusername: string,
    createdByProfilePath: string,
    revertstatus: number | null,
    objsubid: string,
    cftstatus: string,
    year: string,
    keyResultcount: number,
    signoffstatus: number,
    parentid:string
  }
  type subobjectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectiveId: "",
    orgObjId: "",
    organizationStatus: string,
    objectivename: "",
    status: string,
    createdby: "",
    objectivesuser_list: assigndetails[],
    createdbyusername: string,
    createdByProfilePath: string,
    revokedstatusorgobj: number | null,
    objsubid: string,
    year: string,
    keyResultcount: number,
    cftstatus: number,
    signoffstatus: number | undefined,
    revokestatus:number,
    parentid:string
  }
  type objectiveuserkey = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: "",
    obj_id: "",
    objectivename: ""
    status: string,
    objectiveassignedusers: string[],
    year: "",
    revertstatus: number | null,
    objtype: "",
    signoffstatus: number,
  }
  type objcetiveuselist = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    organizationStatus: string
    mapped_obj: objectiveuser[],
    mapped_obj_count: string,
    objid: string,
    assignedorgobj: string[]
  }
  type objcetiveuselists = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    organizationStatus: string
    mapped_obj: objectiveuser[],
    mapped_obj_count: number,
    objid: string,
    description: string,
    objectivesassignedusers: assigndetails[],
    // userprofile: string[],
    createdby: string,
    createdByProfilePath: string,
    cftstatus: number,
    year: string;
    revertstatus: number | null,
    parentrevokestatus: number | null,
    objectiveid: string | null,
    parentid: string | null,
    restrictDelete: boolean,
    revokedorgobjstatus: number | undefined,
    revokedbyuserid: string,
    keyResultcount: number,
    signoffstatus: number | undefined,
    startdate: string,
    enddate: string,
  }
  interface orgobjectivelists {
    id: string;
    objectCode: string;
    objectiveName: string;
    description: string;
    createdBy: string;
    assignedCount: number;
    quarter: string,
    year: string,
    startdate:string,
    enddate:string,
    status: number;
    organizationStatus: string;
    createdByUserName: string;
    createdByUserProfilePath: string;
    restrictDelete: boolean;
    orgObjectiveUsersList: assigndetails[];
    revokestatus: number;
    objectiveslist: subobjectiveuser[];
    signoffstatus: number;
  }
  type assignedorgobjectivelist = {
    availableweightage: number,
    weightage: number,
    id: string,
    orgObjectiveId: string,
    userId: string,
    objectiveName: string,
    createdByUserName: string,
    createdByProfilePath: string,
    profilePath: string,
    name: string
  }
  type assigndetails = {
    name: string,
    cftstatus: number
    profilePath: string
    userid: string,
    weightage: number,
    availableweightage: number,
    signOffStatus: number,
  }
  type assigndetailssub = {
    name: string,
    cftstatus: number
    profilePath: string
    userId: string,
    weightage: number,
    availableweightage: number
  }

  interface UserDetails {
    "id": string | null;
    "name": string;
    "username": string;
    "avaiableWeightage": number;
    "index": string | null;
    //  assigned: number;
    "profilePath": string;
    "assignWeightage": number;
    // Add other properties as needed
  }
  interface customWeightage {
    "id": string,
    "name": string,
    "username": string,
    "avaiableWeightage": number,
    "assignWeightage": number,
    "cftstatus": number,
    "profilePath": string,
  }
  interface ObjData {
    // id: number;
    // objname: string;
    // desc: string;
    // objValue: string;
    // status: string;
    // parentobj: string | null;


    primaryId: string;
    objectiveId: string;
    objectiveName: string;
    objectiveDescription: string;
    weightage: string;
    organizationStatus: string;
  }
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const [Orgobjectiveuserslist, setorgobjectiveuserlist] = useState<orgobjectivelists[]>([]);
  const [Orgobjectiveuserslistloader, setorgobjectiveuserlistloader] = useState<orgobjectivelists[]>([]);
  const [loader, setstatus] = useState<boolean>(false);
  const [objectiveAndUser, setObjectiveAndUser] = useState<objcetiveuselists>();
  const [orgobjectiveAndUser, setorgobjectiveanduser] = useState<orgobjectivelists>();
  const [childObjectiveAndUser, setChildObjectiveAndUser] = useState<objectiveuser>();
  const [childOrgObjectiveAndUser, setChildOrgObjectiveAndUser] = useState<subobjectiveuser>();
  const [showReviewObjective, setShowReviewObjective] = useState<ObjData[]>([
    {
      primaryId: "",
      objectiveId: "",
      objectiveName: "",
      objectiveDescription: "",
      weightage: "",
      organizationStatus: "",
      // subObjectiveList: [],
      // assigneUserWeightage : [],
    }
  ]);
  const [objectiveuserslist, setobjectivesuserlist] = useState<objcetiveuselists[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      quartervalue: "",
      orgobjid: "",
      mapped_obj: [],
      mapped_obj_count: 0,
      objid: "",
      organizationStatus: "",
      description: "",
      objectivesassignedusers: [],
      // userprofile: [],
      createdby: "",
      createdByProfilePath: "",
      cftstatus: 0,
      year: "",
      revertstatus: null,
      parentrevokestatus: null,
      objectiveid: null,
      parentid: null,
      restrictDelete: false,
      revokedorgobjstatus: undefined,
      revokedbyuserid: "",
      keyResultcount: 0,
      signoffstatus: 0,
      startdate: "",
      enddate: "",
    }

  ]);
  const [objectiveuserslistload, setobjectivesuserlistload] = useState<objcetiveuselists[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      quartervalue: "",
      orgobjid: "",
      mapped_obj: [],
      mapped_obj_count: 0,
      objid: "",
      organizationStatus: "",
      description: "",
      objectivesassignedusers: [],
      // userprofile: [],
      createdby: "",
      createdByProfilePath: "",
      cftstatus: 0,
      year: "",
      revertstatus: null,
      parentrevokestatus: null,
      objectiveid: null,
      parentid: null,
      restrictDelete: false,
      revokedorgobjstatus: undefined,
      revokedbyuserid: "",
      keyResultcount: 0,
      signoffstatus: 0,
      startdate: "",
      enddate: "",
    }

  ]);
  const [permission_orgobjedit, setPermission_orgobjedit] = useState('');
  const [permission_orgobjview, setPermission_orgobjview] = useState('');
  const [permission_orgobjdelete, setPermission_orgobjdelete] = useState('');
  const GetUserAccessorg = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_orgobjview(permission.org_objective);
        }
        if (permission.menu === "Edit") {
          setPermission_orgobjedit(permission.org_objective);
        }
        if (permission.menu === "Delete") {
          setPermission_orgobjdelete(permission.org_objective);
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const [orgLoadingcount, setorgLoadingcount] = useState(0);
  const [objLoadingCount, setobjLoadingCount] = useState(0);

  const[orgLength, setOrgLength] = useState<number>(0);
  const Orgobjectivelist = async () => {
    const token = localStorage.accessToken;
    // console.log("localstorages",localStorage)
    // console.log("")
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;
    if (userObject) {
      useruniqueid = localStorage.loginId;
    } else {
      console.error("User information not found in localStorage");
    }
    setorgobjectiveuserlist([]);
    const formData = new FormData();
    formData.append("userid", useruniqueid);
    try {
      const response = await axios.get(
        BaseUrl + `api/organization/review/getallforfh/${localStorage.Quarter}/${localStorage.year}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      // setorgobjectiveuserlist(response.data);
      // console.log(response.data[6].objectiveslist);
      //console.log("datasize"+response.data.data.length)
      if (response.status == 200) {
        setstatus(true);
        setorgLoadingcount(1);
        setLoading(false);
      }
      setorgobjectiveuserlist((prevList) => [
        ...(response.data.data as any[]).map((item: any) => {
          isObjCheckedorg.push(false)

          const sub_obj = (item?.objectiveslist as any[])?.map((detailItem: any) => ({
            obj_name: detailItem?.objectivename,
            obj_code: detailItem?.name,
            obj_desc: detailItem?.description,
            obj_quarter: detailItem?.quarter,
            obj_id: detailItem?.id,
            year: detailItem?.year,
            startdate:detailItem?.startDate,
            enddate:detailItem?.endDate,
            objectiveId: detailItem?.objectiveid,
            orgObjId: detailItem?.orgobjectiveid,
            organizationStatus: detailItem?.organizationStatus,
            createdby: detailItem?.createdBy,
            objectivesuser_list: detailItem?.objectivesuser_list,
            createdbyusername: detailItem?.createdbyusername,
            createdByProfilePath: detailItem?.createdByProfilePath,
            objectivename: detailItem?.objectivename,
            status: detailItem?.organizationStatus,
            serprofile: detailItem?.userprofile,
            revokedstatusorgobj: detailItem?.revokedstatusorgobj,
            revokestatus: detailItem?.revokestatus,
            objsubid: detailItem?.objsubid,
            keyResultcount: detailItem?.keyResultcount,
            cftstatus: detailItem?.cftstatus,
            signoffstatus: detailItem?.signoffstatus,
            parentid: detailItem?.parentid
            // Add other properties as needed
          })) || [];
          const mapped_obj_users = (item?.orgObjectiveUsersList as any[])?.map((objusers: any) => ({
            name: objusers.name,
            userid: objusers.userId,
            cftstatus: 0,
            profilePath: objusers.profilePath,
            weightage: objusers.weightage,
            availableweightage: objusers.availableweightage,
            signOffStatus: objusers.signOffStatus
          })) || [];
          return {
            id: item.id,
            objectCode: item.objectCode,
            objectiveName: item.objectiveName,
            description: item.description,
            createdBy: item.createdBy,
            assignedCount: item.assignedCount,
            status: item.status,
            quarter: item.quarter,
            year: item.year,
            startdate:item.startDate,
            enddate:item.endDate,
            organizationStatus: item.organizationStatus,
            createdByUserName: item.createdByUserName,
            createdByUserProfilePath: item.createdByUserProfilePath,
            restrictDelete: item.restrictDelete,
            orgObjectiveUsersList: mapped_obj_users,
            revokestatus: item.revokestatus,
            objectiveslist: sub_obj,
            signoffstatus: item.signoffstatus,
          };
        }),
      ]);
      setOrgLength(response.data.data.length);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const objectivelist = async () => {
    const token = localStorage.accessToken;
    //console.log("localstorages",localStorage.userid)
    // console.log("")
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;

    // Check if userObject is not null before accessing its properties
    if (userObject) {
      useruniqueid = localStorage.loginId;


      // Now you can use the useruniqueid variable as needed
      //console.log("User Unique ID:", useruniqueid);
    } else {
      // console.error("User information not found in localStorage");
    }
    setobjectivesuserlist([]);

    const formData = new FormData();
    formData.append("userid", useruniqueid);
    try {
      const response = await axios.get(
        BaseUrl + `api/objectives/list/objectivesusers/${localStorage.Quarter}/${localStorage.year}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.accessToken}`
          },
        }
      );
      // Visibility.push(false)

      setSelectedFieldsArray((prevList) => [
        ...(response.data as any[]).map((item: any) => {
          return item.id;

        })
      ]);
      if (response.status == 200) {
        setobjLoadingCount(1);
        setLoading(false);
      }
      //console.log("listdetails"+response.data.length)
      const objlist:objcetiveuselists[]=response.data.filter((data:objcetiveuselists)=>data.parentid==null)
      const subobj = (response.data as any[])?.filter(data=>data.parentid!=null).map((detailItem: any) => ({
        // Extract the specific values needed from each detailItem
        obj_name: detailItem?.objectivename,
        obj_code: detailItem?.name,
        obj_desc: detailItem?.description,
        obj_quarter: detailItem?.quarter,
        obj_id: detailItem?.id,
        year: detailItem?.year,
        objectiveId: detailItem?.objectiveid,
        orgObjId: detailItem?.orgobjectiveid,
        organizationStatus: detailItem?.organizationStatus,
        createdby: detailItem?.createdBy,
        createdbyusername: detailItem?.createdbyusername,
        createdByProfilePath: detailItem?.createdByProfilePath,
        objectivename: detailItem?.objectivename,
        status: detailItem?.organizationStatus,
        objectiveassignedusers: (detailItem?.objectivesuser_list as any[])?.map((assgineduserlist: any) => ({
          cftstatus: assgineduserlist.cftstatus,
          name: assgineduserlist.name,
          profilePath: assgineduserlist.profilePath,
          userid: assgineduserlist.userId,
          weightage: assgineduserlist.weightage,
          availableweightage: assgineduserlist.availableweightage,
          signOffStatus: assgineduserlist.signOffStatus
        })),
        // userprofile: detailItem?.userprofile,
        revertstatus: detailItem?.revokestatus,
        cftstatus: detailItem?.cftstatus,
        objsubid: detailItem?.objsubid,
        keyResultcount: detailItem?.keyResultcount,
        signoffstatus: detailItem?.signoffstatus,
        parentid: detailItem?.parentid
        // Add other properties as needed
      }))
      console.log("subobj", subobj);
      setobjectivesuserlist((prevList) => [
        ...(objlist as objcetiveuselists[]).map((item: any) => {
          // console.log("org_response", response.data)
          Visibility.push(false);
          subObj.push(false);
          isObjChecked.push(false)
          const mapped_obj = (item?.objdetails as any[])?.map((detailItem: any) => ({
            // Extract the specific values needed from each detailItem
            obj_name: detailItem?.objectivename,
            obj_code: detailItem?.name,
            obj_desc: detailItem?.description,
            obj_quarter: detailItem?.quarter,
            obj_id: detailItem?.id,
            year: detailItem?.year,
            startdate:detailItem?.startdate,
            enddate:detailItem?.enddate,
            objectiveId: detailItem?.objectiveid,
            orgObjId: detailItem?.orgobjectiveid,
            organizationStatus: detailItem?.organizationStatus,
            createdby: detailItem?.createdBy,
            createdbyusername: detailItem?.createdbyusername,
            createdByProfilePath: detailItem?.createdByProfilePath,
            objectivename: detailItem?.objectivename,
            status: detailItem?.organizationStatus,
            objectiveassignedusers: (detailItem?.objectivesuser_list as any[])?.map((assgineduserlist: any) => ({
              cftstatus: assgineduserlist.cftstatus,
              name: assgineduserlist.name,
              profilePath: assgineduserlist.profilePath,
              userid: assgineduserlist.userId,
              weightage: assgineduserlist.weightage,
              availableweightage: assgineduserlist.availableweightage
            })),
            // userprofile: detailItem?.userprofile,
            revertstatus: detailItem?.revokestatus,
            cftstatus: detailItem?.cftstatus,
            objsubid: detailItem?.objsubid,
            keyResultcount: detailItem?.keyResultcount,
            signoffstatus: detailItem?.signoffstatus,
            // Add other properties as needed
          })) || [];
          const mapped_obj_users = (item?.objectivesuser_list as any[])?.map((objusers: any) => ({
            name: objusers.name,
            cftstatus: objusers.cftstatus,
            userid: objusers.userId,
            profilePath: objusers.profilePath,
            weightage: objusers.weightage,
            availableweightage: objusers.availableweightage,
            signOffStatus: objusers.signOffStatus
          })) || [];
          return {
            objname: item?.objectivename,
            objcode: item?.name,
            createdbyname: item?.createdbyusername,
            quartervalue: item?.quarter,
            orgobjid: item?.orgobjectiveid,
            mapped_obj_count: subobj.filter(subobj=>item?.id==subobj.parentid).length,
            mapped_obj: subobj.filter(subobj=>item?.id==subobj.parentid),
            objid: item.id,
            organizationStatus: item?.organizationStatus,
            description: item?.description,
            createdby: item?.createdBy,
            createdByProfilePath: item?.createdByProfilePath,
            objectivesassignedusers: mapped_obj_users,
            objectiveid: item?.objectiveid,
            // userprofile: item?.userprofile,
            cftstatus: item?.cftstatus,
            year: item?.year,
            startdate:item.startdate,
            enddate:item.enddate,
            revertstatus: item?.revokestatus,
            parentrevokestatus: item?.parentrevokestatus,
            parentid: item?.parentid,
            restrictDelete: item?.restrictDelete,
            revokedorgobjstatus: item?.revokedstatusorgobj,
            revokedbyuserid: item?.revokedby,
            keyResultcount: item?.keyResultcount,
            signoffstatus: item?.signoffstatus,
          };
        }),
      ]);
      // if(loader&&response.status==200){
      //   setLoading(false);
      //   setobjectivesuserlist(objectiveuserslistload);
      //   setorgobjectiveuserlist(Orgobjectiveuserslistloader);
      // }
      //console.log();
      // setobjectiveuserlist(response.data.body); 
      var objLength : number = objectiveuserslist.map((item, index) => (
        item.objectivesassignedusers.some(objUser => objUser.cftstatus == 1) 
        && item.mapped_obj.filter(data => data.objectiveId != item.parentid))).length;
        objLength += orgLength;
        localStorage.setItem("cftLength" , objLength.toString());
        console.log("objLengthhhhhhhhhhhCFFTCFTTThh", objLength );
        

    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const showReviewAndCascadeObjectives = async () => {

    const apiUrl = BaseUrl + `api/reviewcascade/view/objective`;

    axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
    ).then((response) => {

      setShowReviewObjective(response.data.data);
      // response.data.data.map((userWeightage : ObjData) => {        
      //   setShowObjectiveUser(userWeightage.assigneUserWeightage);
      // })

    })
  }
  // const objectiveuserslist = [
  //   {
  //     objcode: "",
  //     objname: "",
  //     objectivecreatedbyusename: ""
  //   }
  // ]

  const [objectivetype, setobjectivetype] = useState<string>("");
  const [objectiveassignedusersid, setobjectiveassignedusersdetails] = useState<string[]>();
  const [assignuserweightage, setassigneduserweightage] = useState<UserDetails[]>([]);
  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);
  const selectedmemdetails = async (userid: string[]) => {

    try {
      const updatedata = {
        userlist: userid,
        objective: objectiveAndUser?.objid,
        obj_type: "obj"
      }
      const userdetails = await axios.post(BaseUrl + "api/users/edit_objective_users", updatedata,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      const modifiedData: UserDetails[] =
        userdetails.data.map((item: UserDetails) => {
          const displayWeightage = {
            // ...item,
            id: item.id,
            name: item.name,
            username: item.username,
            avaiableWeightage: item.avaiableWeightage,
            index: "",
            // assigned: parseInt(OrgObjectives.individualweightage),
            profilePath: item.profilePath,
            assignWeightage: item.assignWeightage,
            weightage: 0,
          }
        });
      setassigneduserweightage(modifiedData)
    } catch (e) {
    }

  }
  const userOrganizationList = async (userid: string[]) => {
    try {
      const apiUrl = BaseUrl + `api/users/edit_objective_users`;
      const userObjectIdList: string[] = [];


      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        userlist: userid,
        objective: objectiveId,
        obj_type: "obj"
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {

          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const customWeights = {
              id: res.id,
              name: res.name,
              username: res.name,
              avaiableWeightage: res.avaiableWeightage,
              assignWeightage: parseInt(res.assignWeightage),
              cftstatus: parseInt(res.cftstatus),
              profilePath: res.profilePath,
            }
            addedCustomWeightage.push(customWeights);
          })
          setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
    }
  }
  const items: MenuProps["items"] = [
    objectiveAndUser?.revertstatus !== 1 && permission_objedit ?
      {
        label: (
          // <button className="border-0 bg-transparent fs-14 align-center">
          objectiveAndUser?.createdby === localStorage.loginId ?
            (
              <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" objectivecode=""type={true} objectivetype={objectivetype} assigneesmembers={objectiveAndUser?.createdby} />) :
            (<AssignObj
              objectivelist={objectivelist}
              objectcode={objectiveAndUser?.objcode == undefined ? "" : objectiveAndUser?.objcode}
              objectname={objectiveAndUser?.objname == undefined ? "" : objectiveAndUser?.objname}
              orgQuartervalue={objectiveAndUser?.quartervalue == undefined ? "" : objectiveAndUser?.quartervalue}
              orgobjid={objectiveAndUser?.orgobjid == undefined ? "" : objectiveAndUser?.orgobjid}
              objid={objectiveAndUser?.objid == undefined ? "" : objectiveAndUser?.objid}
              objective_id={objectiveAndUser?.objectiveid == undefined ? "" : objectiveAndUser?.objectiveid}
              year={objectiveAndUser?.year == undefined ? "" : objectiveAndUser?.year}
              startdate={objectiveAndUser?.startdate == undefined ? "" : objectiveAndUser?.startdate}
              enddate={objectiveAndUser?.enddate == undefined ? "" : objectiveAndUser?.enddate}
              orgdescription={objectiveAndUser?.year == undefined ? "" : objectiveAndUser?.description}
              objectivetype={"objective"}
              button="Edit"
              header="Assign Objective"
            />)
          // </button>
        ),
        key: "0",
      } : null,
    objectiveAndUser?.revertstatus !== 1 && objectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { handleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    objectiveAndUser?.revertstatus == 1 && objectiveAndUser?.organizationStatus != "1" ?
      {
        label: (
          <Row onClick={() => { handleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // } : {
    //   label: (
    //     <Row onClick={() => { handleRevert(1) }} className="p-0">
    //       <StopOutlined className="mr-1" />
    //       Revoke
    //     </Row>
    //   ),
    //   key: "2",
    // },
    // objectiveAndUser?.organizationStatus === "1" ?
    objectiveAndUser?.restrictDelete === false && permission_objdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      }
      : null
  ];
  const childItems = [
    childObjectiveAndUser?.revertstatus !== 1 && permission_childobjedit ?
      {
        label: (
          // <button className="border-0 bg-transparent fs-14 align-center">
          <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" type={true} objectivecode="" objectivetype={objectivetype} assigneesmembers={childObjectiveAndUser?.createdby} />
          // </button>
        ),
        key: "0",
      } : null,
    childObjectiveAndUser?.revertstatus !== 1 && childObjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { childHandleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    childObjectiveAndUser?.revertstatus == 1 ?
      {
        label: (
          <Row onClick={() => { childHandleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // } : {
    //   label: (
    //     <Row onClick={() => { handleRevert(1) }} className="p-0">
    //       <StopOutlined className="mr-1" />
    //       Revoke
    //     </Row>
    //   ),
    //   key: "2",
    // },
    childObjectiveAndUser?.organizationStatus === "1" && permission_childobjdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      } : null
  ];


  const orgitems = [
    // selectedOrganizationObjective?.createdbyname === user ?
    permission_orgobjedit ? {

      label: (
        orgobjectiveAndUser?.createdBy == localStorage.loginId ?
          (
            <EditObj orgObjList={Orgobjectivelist} itemId={objectiveId} objectiveName="" orgobjectivecode="" />) :
          (<AssignObj
            objectivelist={Orgobjectivelist}
            objectcode={orgobjectiveAndUser?.objectCode == undefined ? "" : orgobjectiveAndUser?.objectCode}
            objectname={orgobjectiveAndUser?.objectiveName == undefined ? "" : orgobjectiveAndUser?.objectiveName}
            orgQuartervalue={orgobjectiveAndUser?.quarter == undefined ? "" : orgobjectiveAndUser?.quarter}
            orgobjid={orgobjectiveAndUser?.id == undefined ? "" : orgobjectiveAndUser?.id}
            objid={""}
            objective_id={""}
            year={orgobjectiveAndUser?.year == undefined ? "" : orgobjectiveAndUser?.year}
            startdate={objectiveAndUser?.startdate == undefined ? "" : objectiveAndUser?.startdate}
            enddate={objectiveAndUser?.enddate == undefined ? "" : objectiveAndUser?.enddate}
            orgdescription={orgobjectiveAndUser?.year == undefined ? "" : orgobjectiveAndUser?.description}
            objectivetype={"orgobjective"}
            button="Edit"
            header="Assign Objective"
          />)
      ),
      key: "0",
    } : null,
    orgobjectiveAndUser?.revokestatus !== 1 && orgobjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { handleRevertorg(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    orgobjectiveAndUser?.revokestatus == 1 && orgobjectiveAndUser?.organizationStatus != "1" ?
      {
        label: (
          <Row onClick={() => { handleRevertorg(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // selectedOrganizationObjective?.organizationStatus === "1" ? 
    permission_orgobjdelete ? {
      label: (
        <Row
          className="c_FF4747 p-0"
          onClick={handleDelete}
        // disabled={disableDelete}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: "1",
    } : null
    // : null
  ];
  const childOrgItems = [
    childOrgObjectiveAndUser?.revokedstatusorgobj !== 1 && permission_childobjedit ?
      {
        label: (
          // <button className="border-0 bg-transparent fs-14 align-center">
          <EditCreateParent objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectiveId} objectivestatus={objectivestaus} objectiveName="" type={true} objectivecode="" objectivetype={objectivetype} assigneesmembers={childOrgObjectiveAndUser?.createdby} />
          // </button>
        ),
        key: "0",
      } : null,
    childOrgObjectiveAndUser?.revokedstatusorgobj !== 1 && childOrgObjectiveAndUser?.revokestatus !==1 && childOrgObjectiveAndUser?.organizationStatus != "1"
      // && objectiveAndUser?.organizationStatus === "2" 
      ?
      {
        label: (
          <Row onClick={() => { subchildHandleRevert(1) }} className="p-0">
            <StopOutlined className="mr-1" />
            Revoke
          </Row>
        ),
        key: "2",
      } : null,
    childOrgObjectiveAndUser?.revokedstatusorgobj == 1 || childOrgObjectiveAndUser?.revokestatus ==1 ?
      {
        label: (
          <Row onClick={() => { subchildHandleRevert(0) }} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Revert" />
            UnRevoke
          </Row>
        ),
        key: "2",
      } : null,
    // } : {
    //   label: (
    //     <Row onClick={() => { handleRevert(1) }} className="p-0">
    //       <StopOutlined className="mr-1" />
    //       Revoke
    //     </Row>
    //   ),
    //   key: "2",
    // },
    childOrgObjectiveAndUser?.organizationStatus === "1" && permission_childobjdelete ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            onClick={handleDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      } : null
  ];



  const childMenu = (
    <Menu>
      {childItems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );

  const orgobjmenu = (
    <Menu>
      {orgitems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );

  const childOrgMenu = (
    <Menu>
      {childOrgItems.map(item => (
        // Check if item is not null before rendering Menu.Item
        item && (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        )
      ))}
    </Menu>
  );


  const [selectedFieldsArray, setSelectedFieldsArray] = useState<any[]>([]);
  const allOrganizationAssignedUser = async () => {

    // if(localStorage.getItem("rolename") === "FH") {
    const apiUrl = BaseUrl + `api/organization/objective/users/${localStorage.getItem("loginId")}`;
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
    })

    // } else {
    //   console.log("ERROR :::::::::::::::::::::::::", 'Enter the valid Lognin');

    // }
  }

  const deleteParentObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/delete/${objectiveId}`;

      await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        objectivelist();
        // window.location.reload();
        //console.log(response.data);
      })
    } catch (error) {

    }
  }
  type editkeyresults = {
    keytitle: string,
    description: string,
    remarks: string,
    quarter: string,
    createdbyempname: string,
    target: string,
    Uom: string,
    amounttype: string
  }
  type editkeyresultusers = {
    id: string,
    username: string,
    Assigned: number,
    target: number,
    Available: number | string,

  }

  type keyResultUsers = {
    availableweightage: number;
    createdAt: string;
    id: string;
    individualweightage: number;
    keyid: string;
    keyuid: string;
    profilePath: string | null;
    status: number;
    target: number;
    updateAt: string | null;
    userempid: string | null;
    userid: string;
    username: string;
    signOffStatus: number;
  };
  // const [keyresultsvalues,setkeyresultvalues] = useState<editkeyresults>()
  // const[keyresultsassigneduser,setkeyresultassignedusers] = useState<editkeyresultusers[]>([])
  // const viewkeyresults = async(krid:string)=>{
  //   try {
  //     const response = await axios.get(`${BaseUrl}api/keyresult/editkeyresult/${krid}`) ;
  //     const responsevalues = response.data.data;
  //     const keyvaluedatas:any = responsevalues.keyvalues;

  //     const editKeyResults: editkeyresults = {
  //       keytitle:keyvaluedatas.keytitle,
  //       description:keyvaluedatas.description,
  //       remarks:keyvaluedatas.remarks,
  //       quarter:keyvaluedatas.quarter,
  //       createdbyempname:keyvaluedatas.createdbyempname,
  //       target:keyvaluedatas.target,
  //       Uom:keyvaluedatas.Uom,
  //       amounttype:keyvaluedatas.amountype
  //     }
  //     const editkeysassignedusers: editkeyresultusers[] = responsevalues.keysassigned.map((item:any) => ({
  //       id:item.userid,
  //       username: item.username,
  //       Assigned: item.individualweightage,
  //       target: item.target,
  //       Available: ""
  //   }));
  //   setkeyresultassignedusers(editkeysassignedusers);
  //     setkeyresultvalues(editKeyResults)
  //   } catch (error) {
  //     console.error(error)
  //   }

  // }
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);

  const [Visibility, setvisibility] = useState<boolean[]>([]);

  const toggleVisibility = (index: number) => {
    setIsVisible((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }
  const [objectivekeys, setobjectiveskey] = useState<objectiveuserkey>(

    {
      obj_name: "",
      obj_code: "",
      obj_desc: "",
      obj_quarter: "",
      obj_id: "",
      objectivename: "",
      status: "",
      objectiveassignedusers: [],
      year: "",
      revertstatus: null,
      objtype: "",
      signoffstatus: 0,
    }

  );
  type kr = {
    id: string,
    objectiveid: string,
    keytitle: string,
    description: string,
    remarks: string,
    target: string,
    amounttype: string,
    uom: string,
    status: number,
    keyid: string,
    objectivecode: string,
    quarter: string,
    orgobjectiveid: string,
    objectivename: string,
    keyResultUsersListAssignedTo: keyResultUsers[],
    createdbyempname: string,
    createdByEmpProfilePath: string,
    keyresultweightage: number,
    deletedstatus: number,
    deletedtime: String,
    updatedAt: string
    deleteddate: string,
    createdBy: string,
    signOffStatus: number;
    checkInFrequency:string;
  }

  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };
  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  })

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Lead Measure");
      setMaxWords(maxWords.data);
    } catch (error) {
    }
  };
  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_childobjview(permission.child_objective)
          setPermission_parentobjview(permission.parent_objective)
          setPermission_lmview(permission.lead_measures);
          setPermission_krview(permission.key_result);
          setPermission_lmview(permission.lead_measures);
        }
        if (permission.menu === "Edit") {
          setPermission_objedit(permission.parent_objective);
          setPermission_childobjedit(permission.child_objective);
          setPermission_kredit(permission.key_result);
          setPermission_lmedit(permission.lead_measures);
        }
        if (permission.menu === "Delete") {
          setPermission_objdelete(permission.parent_objective);
          setPermission_childobjdelete(permission.child_objective);
          setPermission_krdelete(permission.key_result);
          setPermission_lmdelete(permission.lead_measures);
        }
        if (permission.menu === "Create") {
          setPermission_lmcreate(permission.lead_measures);
        }

      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    GetUserAccess();
    GetUserAccessorg();
    getWordCount();
    userLists();
    Orgobjectivelist();
    objectivelist();
    // showReviewAndCascadeObjectives();
    // allOrganizationAssignedUser();
  }, []);

  
  useEffect(() => {
    LeadMeasureData();
  }, []);


  const [objectivesassignedkr, setobjectiveassignedkr] = useState<kr[]>([]);
  const [objectivesteamdkr, setobjectiveteamedkr] = useState<kr[]>([]);
  // const [objectivesassignedkr, setObjectivesAssignedKR] = useState<kr[]>([
  //   {
  //     id: '1',
  //     keyid: "KRA-1",
  //     keytitle: "",
  //     description: "Objective 1 description",
  //     remarks: "",
  //     target: "",
  //     amounttype: "",
  //     uom: "",
  //     objectivecode: "Code 1",
  //     quarter: "Q1",
  //     objectiveid: "1",
  //     orgobjectiveid: "org1"
  //   },
  //   {
  //     id: '2',
  //     keyid: "KRA-2",
  //     keytitle: "",
  //     description: "Objective 2 description",
  //     remarks: "",
  //     target: "",
  //     amounttype: "",
  //     uom: "",
  //     objectivecode: "Code 2",
  //     quarter: "Q2",
  //     objectiveid: "2",
  //     orgobj ectiveid: "org2"
  //   }
  // ]);
  // const [keystatus, setkeystatus] = useState(false)
  // const [keystatus, setkeystatus] = useState<boolean[]>([]);
  const [keystatus, setkeystatus] = useState<number | null>(null);
  const [orgIndex, setOrgIndex] = useState<number | null>(null);




  // const objectivesdetails = (objectives: objectiveuser) => {
  //   setobjectiveskey((prevObjectives) => ({
  //     ...prevObjectives,
  //     ...objectives,
  //   }));
  //   setkeystatus(true);
  //   const element = document.getElementById("keyresult");
  //   if (element) {
  //     element.classList.remove("d-none");
  //   }
  //   getassignedkeyforobjectives(objectives.obj_id)

  // };
  const cascadeallkeyresults = async () => {
    const keyids: string[] = []
    objectivesassignedkr.forEach((keyvals) => {
      if (keyvals.status != 3) {
        keyids.push(keyvals.id);
      }

    })

    Modal.confirm({
      title: 'Do You Want TO Cascade All The  KeyResults ?',
      content: (
        <>
          <p className="fs-14"><b>Note : </b>Cascading All The Key Results TO The Corresponding Objective</p>
        </>
      ),
      okText: "Cascade All",
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      async onOk() {
        onClose();
        cascadeallkeyresultsrobjectves();
      },
      onCancel() {
      },
    });
  }
  const [keycard, setKeyCard] = useState<objcetiveuselists>();
  const [subOrgkeycard, setSubOrgKeyCard] = useState<subobjectiveuser>();
  const [subObjkeycard, setSubObjKeyCard] = useState<objectiveuser>();
  const [keystatusIndex, setkeystatusIndex] = useState<number | undefined>(undefined);
  const [orgClass, setorgClass] = useState<string>('');
  const [objClass, setobjClass] = useState<string>('');
  const [mainorgClass, setmainorgClass] = useState<string>('');
  const [mainobjClass, setmainobjClass] = useState<string>('');

  const [krforobj, setobjforjkr] = useState<boolean>(false)
  const [objBgActive, setObjBgActive] = useState(false);

  const objectivesdetailsgets = (objectives: objcetiveuselists | undefined, index: number | undefined) => {
    setObjBgActive(!objBgActive);
    setOrgIndex(null);
    setobjClass('');
    setorgClass('');
    setmainorgClass('');
    setmainobjClass('mainobj-' + index);
    if (objectives !== undefined) {
      setKeyCard(objectives)
      setobjectiveskey((prevObjectives: any) => ({
        ...prevObjectives,
        obj_desc: objectives.description,
        obj_name: objectives.objname,
        obj_code: objectives.objcode,
        obj_quarter: objectives.quartervalue,
        obj_id: objectives.objid,
        status: objectives.organizationStatus,
        year: objectives.year,
        revertstatus: objectives.revertstatus,
        objtype: objectives.parentid == null ? "orgobjective" : "objective",
        signoffstatus: objectives.signoffstatus
      }));
      // setkeystatus(true)

      if (index !== undefined) {
        // setkeystatus(prevStatus => {
        //   const newStatus = [...prevStatus];
        //   newStatus[index] = !newStatus[index];
        //   return newStatus;
        // });
        setkeystatus(keystatus === index ? null : index);
        setobjforjkr(true);
        // setOrgIndex(false)
        setkeystatusIndex(index);
      }
      setobjforjkr(true)
      setOrgIndex(null);
      const objectkeyid = objectivekeys.obj_id;
      handleKrChange("All Key Results",objectives.objid, localStorage.getItem("loginId")||"");
      //localStorage.setItem('kr_obj',objectivekeys.obj_id);
    }
    
    
  };

  // const handleItemClick = (id: number) => {
  //   setActiveItem(id);
  //   setActiveSubItem(null); // Reset subitem when a new item is selected
  // };

  // const handleSubItemClick = (id: number) => {
  //   setActiveSubItem(id);
  // };

  const orgobj_div = (index: number) => {
    let org = "org-" + index;
    setobjforjkr(false)
    setkeystatus(null);
    setobjectiveskey(
      {
        obj_name: "",
        obj_code: "",
        obj_desc: "",
        obj_quarter: "",
        obj_id: "",
        objectivename: "",
        status: "",
        objectiveassignedusers: [],
        year: "",
        revertstatus: null,
        objtype: "",
        signoffstatus: 0,
      }
    );
    // setobjforjkr(false);
    setobjectiveassignedkr(objectivesassignedkr);

    // setobjectiveassignedkr(false)
    setOrgIndex(orgIndex === index ? null : index);
    setobjClass('');
    setorgClass('');
    setmainobjClass('');
    setmainorgClass('mainorg-' + index);

  }

  const suborgobjectivesdetailsgets = (objectives: subobjectiveuser | undefined, orgobjIndex: number, subobjIndex: number) => {
    setObjBgActive(!objBgActive);
    setorgClass('org-' + orgobjIndex + '-' + subobjIndex);
    // setOrgIndex(null);
    // setobjforjkr(false);
    setobjClass('');
    setmainobjClass('');
    setmainorgClass('');
    // console.log("orgindex",orgobjIndex);
    if (objectives !== undefined) {
      setSubOrgKeyCard(objectives)
      setobjectiveskey((prevObjectives: any) => ({
        ...prevObjectives,
        obj_desc: objectives.obj_desc,
        obj_name: objectives.obj_name,
        obj_code: objectives.obj_code,
        obj_quarter: objectives.obj_quarter,
        obj_id: objectives.obj_id,
        status: objectives.organizationStatus,
        year: objectives.year,
        revokedstatusorgobj: objectives.revokedstatusorgobj,
        objtype: objectives.orgObjId == null ? "orgobjective" : "objective"
      }));
      const objectkeyid = objectivekeys.obj_id;
      handleKrChange("All Key Results",objectives.obj_id, localStorage.getItem("loginId")||"");
      // getassignedkeyforobjectives(objectives.obj_id, localStorage.getItem("loginId"))
      // getteamkeyresult(objectives.obj_id, localStorage.getItem("username"));
      //localStorage.setItem('kr_obj',objectives.obj_id);
      setobjforjkr(true)
    }
  };
  // console.log("orgclass" + orgClass);
  const subobjectivesdetailsgets = (objectives: objectiveuser, objIndex: number, subobjIndex: number) => {
    setObjBgActive(!objBgActive);
    setSubObjKeyCard(objectives)
    //setOrgIndex(null);
    setorgClass('');
    setmainobjClass('');
    setmainorgClass('');
    //setobjforjkr(false);
    setobjClass('obj-' + objIndex + '-' + subobjIndex);
    setobjectiveskey((prevObjectives: any) => ({
      ...prevObjectives,
      obj_desc: objectives.obj_desc,
      obj_name: objectives.obj_name,
      obj_code: objectives.obj_code,
      obj_quarter: objectives.obj_quarter,
      obj_id: objectives.obj_id,
      status: objectives.organizationStatus,
      year: objectives.year,
      revertstatus: objectives.revertstatus,
      objtype: objectives.orgObjId == null ? "orgobjective" : "objective"
    }));
    const objectkeyid = objectivekeys.obj_name;
    handleKrChange("All Key Results",objectives.obj_id, localStorage.getItem("loginId")||"");
    // getassignedkeyforobjectives(objectives.obj_id, localStorage.getItem("loginId"))
    // getteamkeyresult(objectives.obj_id, localStorage.getItem("username"))
    setobjforjkr(true)
    // localStorage.setItem('kr_obj',objectives.obj_id);
  };

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  const [availableweightagekr, setavailableweightage] = useState(0);
  const getassignedkeyforobjectives = async (objectives: string, userId: string | null) => {
    try {
    const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getkeyresult/${objectives}/${userId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    setavailableweightage(assignedkeyresults.data.availableweightage)
    const assignedkr: kr[] = assignedkeyresults.data.data;
    assignedkr.map((item: kr) => {
      const updatedDate = item.updatedAt ? item.updatedAt.split('T')[0] : "";
      const assignedkrforobj: kr = {
        id: item.id,
        objectiveid: item.objectiveid,
        keytitle: item.keytitle,
        description: item.description,
        remarks: item.remarks,
        target: item.target,
        amounttype: item.amounttype,
        uom: item.uom,
        status: item.status,
        keyid: item.keyid,
        objectivecode: item.objectivecode,
        quarter: item.quarter,
        orgobjectiveid: item.orgobjectiveid,
        objectivename: item.objectivename,
        keyResultUsersListAssignedTo: item.keyResultUsersListAssignedTo,
        createdbyempname: item.createdbyempname,
        createdBy: item.createdBy,
        createdByEmpProfilePath: item.createdByEmpProfilePath,
        keyresultweightage: item.keyresultweightage,
        deletedstatus: item.deletedstatus,
        deleteddate: item.deleteddate,
        updatedAt: item.updatedAt,
        deletedtime: item.deletedtime,
        signOffStatus: item.signOffStatus,
        checkInFrequency:item.checkInFrequency,
      }
      const againID = assignedkrforobj.keyResultUsersListAssignedTo;
      const assignedUsers: keyResultUsers[] = [];
      const loginID = localStorage.getItem("loginId");
      const filteredUsers = againID.filter(user => user.id !== loginID);
      // console.log("filteredUsers", filteredUsers);

    });
    setobjectiveassignedkr(assignedkr);

    if (assignedkeyresults.data.length === 0) {
      setobjectiveassignedkr([])
    }
  } catch (error) {
      
  }
  }
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
      Orgobjectivelist();
      //userRequestSignOffStatus();
      objectivelist();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [year, quarter]);
  // team keyResult API

const getteamkeyresult = async (objectivesId: string, userId: string | null) => {
    try {
      const myteamkeyresults = await axios.get(`${BaseUrl}api/keyresult/getTeamkeyResults/${objectivesId}/${userId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      // console.log(myteamkeyresults.data);
      const assignedteamkr: kr[] = myteamkeyresults.data;
      assignedteamkr.map((item: kr) => {
        const teamkrforobj: kr = {
          id: item.id,
          objectiveid: item.objectiveid,
          keytitle: item.keytitle,
          description: item.description,
          remarks: item.remarks,
          target: item.target,
          amounttype: item.amounttype,
          uom: item.uom,
          status: item.status,
          keyid: item.keyid,
          objectivecode: item.objectivecode,
          quarter: item.quarter,
          orgobjectiveid: item.orgobjectiveid,
          objectivename: item.objectivename,
          keyResultUsersListAssignedTo: item.keyResultUsersListAssignedTo,
          createdbyempname: item.createdbyempname,
          createdBy: item.createdBy,
          createdByEmpProfilePath: item.createdByEmpProfilePath,
          keyresultweightage: item.keyresultweightage,
          deletedstatus: item.deletedstatus,
          deleteddate: item.deleteddate,
          updatedAt: item.updatedAt,
          deletedtime: item.deletedtime,
          signOffStatus: item.signOffStatus,
          checkInFrequency:item.checkInFrequency,
        }
      });
      setobjectiveteamedkr(assignedteamkr);
    } catch (error) {
      console.log(error);
    }
  }
 
  
  const getallkeyresults = async (objectives: string, userId: string | null) => {
    try {
    const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getallkeyresults/${objectives}/${userId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    setavailableweightage(assignedkeyresults.data.availableweightage)
    const assignedkr: kr[] = assignedkeyresults.data.data;
    assignedkr.map((item: kr) => {
      const updatedDate = item.updatedAt ? item.updatedAt.split('T')[0] : "";
      const assignedkrforobj: kr = {
        id: item.id,
        objectiveid: item.objectiveid,
        keytitle: item.keytitle,
        description: item.description,
        remarks: item.remarks,
        target: item.target,
        amounttype: item.amounttype,
        uom: item.uom,
        status: item.status,
        keyid: item.keyid,
        objectivecode: item.objectivecode,
        quarter: item.quarter,
        orgobjectiveid: item.orgobjectiveid,
        objectivename: item.objectivename,
        keyResultUsersListAssignedTo: item.keyResultUsersListAssignedTo,
        createdbyempname: item.createdbyempname,
        createdBy: item.createdBy,
        createdByEmpProfilePath: item.createdByEmpProfilePath,
        keyresultweightage: item.keyresultweightage,
        deletedstatus: item.deletedstatus,
        deleteddate: item.deleteddate,
        updatedAt: item.updatedAt,
        deletedtime: item.deletedtime,
        signOffStatus: item.signOffStatus,
        checkInFrequency:item.checkInFrequency,
      }
      const againID = assignedkrforobj.keyResultUsersListAssignedTo;
      const assignedUsers: keyResultUsers[] = [];
      const loginID = localStorage.getItem("loginId");
      const filteredUsers = againID.filter(user => user.id !== loginID);
      // console.log("filteredUsers", filteredUsers);

    });
    setobjectiveassignedkr(assignedkr);

    if (assignedkeyresults.data.length === 0) {
      setobjectiveassignedkr([])
    }
  } catch (error) {
      
  }
  }

  const toggleVisibilitys = (index: number) => {
    setvisibility((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const contentData: LeadMeasure[] = [
    // {
    //   id: 1,
    //   user: "John",
    //   message: "Track progress on infrastructure upgrade projects to ensure timely completion.",
    //   profile: "assets/images/Avatar1.png",
    //   assignedId: "1"
    // },
    // {
    //   id: 2,
    //   user: "Alice",
    //   message: "This approach is often a part of a balanced scorecard framework, which balances financial objectives with non-financial aspects.",
    //   profile: "assets/images/Avatar2.png",
    //   assignedId: "1"
    // },
    // {
    //   id: 3,
    //   user: "Bob",
    //   message: "This approach is often a part of a balanced scorecard framework, which balances financial objectives with non-financial aspects.",
    //   profile: "assets/images/Avatar1.png",
    //   assignedId: "1"
    // }
  ];


  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPagePerObjective, setCurrentPagePerObjective] = useState<{ [key: string]: number }>({});

  const itemsPerPage: number = 1;
  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems = contentData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (page: number, objectiveId: string) => {
    setCurrentPagePerObjective(prevState => ({
      ...prevState,
      [objectiveId]: page
    }));
  };

  const { TextArea } = Input;
  const [show, setShow] = useState(false);

  const [leadMeasure, setLeadMeasure] = useState({
    orgObjId: "",
    krId: "",
    objId: "",
    description: ""
  });


  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    // Check if the input exceeds the maximum word count
    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      setLeadMeasure({
        ...leadMeasure,
        [event.target.name]: event.target.value
      });
    }
  }



  //   const handleInputChange = (name: string, value: string) => {
  //     setLeadMeasure(prevState => ({
  //         ...prevState,
  //         [name]: value
  //     }));
  // };
  const handleAddLead = () => {
    setShow(true);
  }
  const [krreviewstatus, setkrreviewstatus] = useState<boolean>(true);
  const [getAllLeadMeasure, setAllLeadMeasure] = useState<LeadMeasure[]>([]);
  const LeadMeasureData = async () => {
    try {
      const apiUrl = BaseUrl + `api/leadMeasure/listByCreatedBy`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setAllLeadMeasure(response.data);

          onClose();
          //window.location.reload();
        });
    } catch (error) {
    }
  }
  const deletekeyresult = async (kr: krdescription) => {

    const apiUrl = `${BaseUrl}api/keyresult/delete/${kr.krid}`;

    await axios.put(apiUrl, {
    }).then((response) => {
    })
    //window.location.reload();
  }
  const handleDeleteKR = (kr: krdescription) => {
    Modal.confirm({
      title: "Do you want to delete this keyresult ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr?.krtitle}
            {/* “ Achieve a 20% reduction in average server response time. ” */}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deletekeyresult(kr);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                KeyResult deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        // window.location.reload();
        onClose()

        setTimeout(() => {
          objectivelist()
          objectivesdetailsgets(keycard, keystatusIndex)
          // getassignedkeyforobjectives(kr.objid ,kr.krid);
          // window.location.reload();
        }, 1000);
      },
      onCancel() {
      },
    });
  };
  // const [isreviewcascade, setReviewCascade] = useState(true);

  const [isreviewcascade, setReviewCascade] = useState<boolean>(() => {
    const savedReview = localStorage.getItem('review');
    return savedReview ? JSON.parse(savedReview) : true;
  });

  useEffect(() => {
    localStorage.setItem('review', JSON.stringify(isreviewcascade));
  }, [isreviewcascade]);

  // const [isreviewKR, setReviewKR] = useState(true);


  const [isreviewKR, setReviewKR] = useState<boolean>(() => {
    const savedKRReview = localStorage.getItem('reviewKR');
    return savedKRReview ? JSON.parse(savedKRReview) : true;
  });

  useEffect(() => {
    localStorage.setItem('reviewKR', JSON.stringify(isreviewKR));
  }, [isreviewKR]);

  const ReviewCascade = () => {
    // alert(1);
    setReviewCascade(false)
  }

  const ReviewKRWeight = () => {
    setReviewKR(false)
  }

  const items1 = [
    // {

    //    label:(
    //     <Row
    //     // className="c_FF4747 fs-14 p-0"
    //   >
    //      <KRDescription open={open} setOpen={setOpen} onClose={onClose} krid={descriptionvalues?.krid || ''} objname={descriptionvalues?.objname || ''} objid={descriptionvalues?.objid || ''} availableweightagekr={availableweightagekr} year={objectivekeys.year} /> 
    //      </Row>),
    //      key: '0',
    // },

    {
      label: (
        // <button className="border-0 bg-transparent fs-14 align-center">
        <KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue}  getteamkeyresult={()=>getteamkeyresult(objectivekeys.obj_id,localStorage.username)} objectivelist={objectivelist} krid={keyresultid?.krid} krtitle={""} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode="" checkInFrequency="" uomType="" objquarter = {objectivekeys.obj_quarter} tabActiveKey="1" />
        // </button>
      ),
      key: "0",
    },
    {
      label: (
        <Row
          className="c_FF4747 fs-14 p-0"
          onClick={() => { handleDeleteKR(keyresultid) }}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: '1',
    },

  ];

  const menu = (
    <Menu>
      {items1.map(item => (
        (permission_kredit && item.key == '0') || (permission_krdelete && item.key == '1') ?
          (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ) : null
      ))}
    </Menu>
  );


  const cascadeallkeyresultsrobjectves = async () => {
    try {
      const apiUrl = BaseUrl + `api/reviewcascade/update/cascadeallkr`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });

      Modal.info({
        title: 'Cascade KeyResults',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{response.data.data} KeyResults Cascaded</p>
            {/* <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p> */}
          </>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
      });

    } catch (error) {
    }
  }
  const addLeadMeasure = async () => {
    // event.preventDefault();
    try {
      const apiUrl = BaseUrl + `api/leadMeasure/create`;
      const formData = new FormData();

      const orgObjeId = document.getElementById('orgObjId') as HTMLInputElement;
      const krid = document.getElementById('krId') as HTMLInputElement;
      const objeid = document.getElementById('objId') as HTMLInputElement;

      // console.log("orgobjid ", orgObjeId.value);
      // console.log("krid ", krid.value);
      // console.log("objid ", objeid.value);


      formData.append("description", leadMeasure.description);
      formData.append("orgObjId", orgObjeId.value);
      formData.append("krId", krid.value);
      formData.append("objId", objeid.value);


      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })

      if (leadMeasure.description === "") {
        message.error({
          content: (
            <div className="success-message justify-center align-center p-1 d-flex">
              {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
              <span className="c_FF4747 fs-16">
                Please add the Lead Measure
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        setLeadOpen(true)
      } else {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                LeadMeasure Created successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        setLeadMeasure(
          {
            ...leadMeasure,
            orgObjId: "",
            krId: "",
            objId: "",
            description: ""
          }
        )
        setLeadOpen(false);
      }
      LeadMeasureData();

    } catch (error) {
    }
  }

  const cascadeStatus = (id: string, objectivename: string) => {
    Modal.confirm({
      title: 'Do you want to Cascade this objective ?',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">{objectivename}</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
        </>
      ),
      okText: 'Cascade',
      cancelText: 'Cancel',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective Created successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        try {
          const apiUrl = BaseUrl + `api/objectives/cascadeStatusUpdate/${id}`;
          axios.put(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
          });

        } catch (error) {
        }
        objectivelist();
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      },
      onCancel() {
      },
    });

  }

  type assignUserDetails = {
    name: string,
    username: string,
    email: string,
    designation: string,
    company_id: string,
    profilePath: string,
    objectivestatus: string,
  }

  const [userInformation, setUserInformation] = useState<assignUserDetails[]>([]);

  const userReporteeList = async (userReporteeList: assigndetails[], objectiveid: string | null, type: string) => {
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userid);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const userReporteeListSub = async (userReporteeList: any[], objectiveid: string | null, type: string) => {
    //console.log(userReporteeList);
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userId);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const [userKeyResultInformatiom, setUserKeyResultInformation] = useState<assignUserDetails[]>([]);

  const userKeyResultList = async (userKeyResultList: any[], objectiveid: string | null, type: string) => {
    const userKrIdList: string[] = userKeyResultList.map(reportee => reportee.userid);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userKrIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserKeyResultInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  // const[subObj,setSubObj]=useState("");
  const [subObj, setSubObj] = useState<boolean[]>([]);
  const [subOrgObj, setSubOrgObj] = useState<boolean[]>([]);
  const sub_obj = (index: number) => {
    setSubObj(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    setobjforjkr(false);
    // setLayerActive(prevState => !prevState);
  };
  const sub_orgobj = (index: number) => {
    setSubOrgObj(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    setobjforjkr(false);
  }
  const [isConfirmAccept, setConfirmAccept] = useState(true);

  // Accept checkbox status
  const [isObjChecked, setIsObjChecked] = useState<boolean[]>([]);
  const [isObjCheckedorg, setorgobjchecked] = useState<boolean[]>([]);

  const handleObjCheckbox = (index: number) => {

    setIsObjChecked((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  };
  const handleObjCheckboxorg = (index: number) => {

    setorgobjchecked((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  };

  const [isAccept, setAccept] = useState(false);

  const handleAcceptBtn = async (index: number, id: string | null, type: string) => {
    try {
      var formdata = new FormData();
      formdata.append("type", type)
      const response = axios.put(`${BaseUrl}api/objectives/Acceptobjective/${id}`, formdata, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then(response => {
        })
      objectivelist();
      Orgobjectivelist();
    }
    catch (e) {
      // console.error(e);
    }

    // setConfirmAccept(false)
  };

  // const memberMenu = (

  const [memCountActive, setMemCountActive] = useState(false);
  const [layerActive, setLayerActive] = useState<boolean[]>();

  const handleVisibleChange = (visible: boolean) => {
    setMemCountActive(visible);
  };

  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const memberMenu = (
    <Menu className="member_count_div">
      {userInformation.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div className="d-flex align-center">
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              {/* <span className="member_title ml-1">{capitalizeFirstLetter(user.name)}/{user.designation}/{user.company_id}</span> */}
              <span className="member_title ml-1">
                {user.name}/{user.designation}/{user.company_id}
              </span>
              {user.objectivestatus == "1" &&
                <img src="assets/images/single_tick.svg" alt="" />
              }
              {
                user.objectivestatus == "3" &&
                <img src="assets/images/tick_check.svg" alt="" />
              }


            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}


      {/* <Menu.Item key="1">
        <div>
          <img src="assets/images/user.svg" alt="" className="user" />
          <span>Vikram/Product/CITPL</span>
          <img src="assets/images/tick_check_all.svg" alt="" />
        </div>
      </Menu.Item> */}
    </Menu>
  );

  const memberMenuKR = (
    <Menu className="member_count_div">
      {userKeyResultInformatiom.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div>
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              <span className="member_title ml-1">{user.name}/{user.designation}/{user.company_id}</span>
              {/* <img src="assets/images/tick_check.svg" alt="" /> */}
            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );

  const [saveAndCascadeUser, setSaveAndCascadeUser] = useState<number>();
  const userLists = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      // console.log(response.data);
      setSaveAndCascadeUser(response.data.length);
      // console.log(response.data.length);

    } catch (err) {
      // console.log(err)
    }
  }

  const cascadeAllObjectives = async () => {
    console.log("hgiiiiiiiiiiiiiii");

    let orgIdList: string[] = [];
    let objIdList: string[] = [];
    Orgobjectiveuserslist.forEach((org) => {
      orgIdList.push(org.id);
      console.log(org.id);
      org.objectiveslist.forEach((obj) => {
        objIdList.push(obj.obj_id);
        console.log(obj.obj_id);
      })
    });

    // objectiveuserslist.map((obj) => {
    //   orgIdList.push(org.id);
    //   console.log(org.id);   
    //   obj.objd
    // })
    console.log(orgIdList);
    console.log("orgIdListorgIdListorgIdListorgIdListorgIdListorgIdListorgIdList");
    console.log(objIdList);



    // const apiUrl = BaseUrl + `api/reviewcascade/all/objectives`;


    // await axios.post("apiUrl", {
    //   "orgIds" : orgIdList,
    //   "objIds" : objIdList,
    // }, {
    //   headers :{
    //      Authorization: `Bearer ${localStorage.accessToken}`
    //   }
    // })
  }

  const cascademenu = (
    <Menu className="cascade_dropdown">
      <Menu.Item>Cascade All Objective</Menu.Item>
      <Divider />
      <Menu.Item onClick={cascadeallkeyresults}>Cascade All Key Results</Menu.Item>
    </Menu>
  );

  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  return (
    <>
      {
        isreviewKR ? (<>
          {isreviewcascade ? (
            <>
              <div className={permission_orgobjview ? "permission_btn" : " "}>
                {/* <span className="fw-600 fs-16 c-000">My Objectives (5)</span> */}
                {/* <div className="sm_display_block xl_display_none mb-2"> */}
                {/* <div className=" z-1 add_obj_btn">
            <AddObj objectivelist={objectivelist}/>
          </div> */}
                <div className="d-flex align-center z-1 float-right mb-2 ">
                  {/* <img
                    src="assets/images/crown.svg"
                    alt=""
                    className="pr-1"
                  /> */}
                  {/* <p className="mr-2 "><span className="c_32B7D9">{showReviewObjective.filter(data => data.organizationStatus == "3").length}</span>/{showReviewObjective.length}</p> */}
                  {/* <ReviewCascade objectivelist={objectivelist} /> */}
                  {/* {saveAndCascadeUser !== 0 ? (<> */}
                  <div className="mr-2 ">
                    <button className="Review_button z-1 position-sticky" onClick={ReviewCascade}>
                      Review OBJ Weightage
                    </button>
                  </div>
                  {krforobj === true ? (
                    <button className="Review_button mr-2 z-1 position-sticky" onClick={ReviewKRWeight}>
                      Review KR Weightage
                    </button>) : (<></>)}
                  <Dropdown overlay={cascademenu} trigger={["click"]}>
                    <a>
                      <Space className="cyan_button">
                        Cascade
                        <img src="assets/images/dropdown_Arrow.svg" alt="" />
                      </Space>
                    </a>
                  </Dropdown>
                  {/* <button className="cyan_button " onClick={()=>{setkrreviewstatus(false);setReviewKR(false)}}>
                        Review KR
                    </button> */}
                  {/* </>) : (<></>)} */}
                </div>
                <div className={loading ? "mt-5 " : "m-0 "}>
                  {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
                    <div className="content" />
                  </Spin>}

                  <Flex className={objectiveuserslist.length == 0 && Orgobjectiveuserslist.length == 0 ? "p-0" : "objective_overall"}>

                    <Flex
                      // className={isVisible ? "objective_bg_active" : ""}
                      align="center"
                    >

                      {Orgobjectiveuserslist.map((item, index) => (
                        <>
                          {item.organizationStatus !== "1" && item.quarter == quarter && item.year == year && item.objectiveslist.some(userdata => userdata.cftstatus == 1) ? (<>
                            <div className="d-flex">
                              {/* <div className={`d-flex ${Visibility[index] ? 'add_border' : ''} ${objBgActive ? 'objBgActive' : ''}`} key={index} onClick={(event) => { objectivesdetailsgets(item); }}> */}
                              <div
                                key={index}
                                onClick={(e) => orgobj_div(index)}
                                className={` ${mainorgClass === `mainorg-${index}` ? 'objective_box_active p-2 mr-1' : 'objective_box p-2 '} `}
                              >
                                {/* <div className={`${keystatus[index] ? `objective_box_active p-2 ${Visibility[index] ? 'mr-0' : ''}`: `objective_box p-2 ${Visibility[index] ? 'mr-0' : ''}` }`}> */}
                                <div className="">
                                  <Row className="justify-between">
                                    <div className="container_label">
                                      <div className="wrapper_label align-center mb-1">
                                        <span className={`${mainorgClass === `mainorg-${index}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}`}>
                                          <img
                                            src="assets/images/cup.svg"
                                            alt=""
                                            className="pr-1"
                                          />{" "}
                                          {item.objectCode}
                                        </span>
                                        <span className={` ${mainorgClass === `mainorg-${index}` ? "triangle_active" : "triangle"}`}></span>
                                      </div>
                                    </div>
                                    <div className="align-center mb-1">
                                      <button className={` align-center border-0 p-1 mr-1 ${item.organizationStatus === "3" ? 'Progressing_btn' : (Visibility[index] ? 'cascade_btn' : 'cascade_btn')}`}>
                                        <span className={` mr-1 ${item.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_cricle')}`}></span>
                                        {item.revokestatus != 1 ? (item.organizationStatus == "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}

                                      </button>
                                      <Dropdown overlay={orgobjmenu} trigger={["click"]}>
                                        <a onClick={(e) => {
                                          e.preventDefault();
                                          setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                          setobjectivestatus(e.currentTarget.getAttribute('data-status'))
                                          setorgobjectiveanduser(item)
                                          setobjectivetype("orgobjective")
                                          // setObjectiveId(itemId);                            
                                        }} href="#hgj"
                                          data-value={item.id}
                                          data-status={item.organizationStatus}
                                        >
                                          {item.createdBy == localStorage.loginId &&
                                            userSignOff.signOffStatus === 0 &&
                                            <Space>
                                              <img src="assets/images/menu.svg" alt="" />
                                              <input type="hidden" />
                                            </Space>

                                          }
                                          {/* <input type="text" value={item.createdby} />
                             <input type="text" value={localStorage.loginid} /> */}
                                        </a>
                                      </Dropdown>
                                    </div>

                                  </Row>
                                  <Flex>
                                    <p className="fs-16 c-000 m-0 cur-pointer mb-2" onClick={showDrawer}>
                                      <EditObj orgObjList={Orgobjectivelist} itemId={item.id} objectiveName={item.objectiveName} orgobjectivecode={item.objectCode}/>
                                    </p>
                                  </Flex>
                                  <Flex>
                                    <p className="fs-14 mb-1 m-0 c_8080 threeline_text_overflow">
                                      {item.description}
                                    </p>
                                  </Flex>
                                  {/*Accept checkbox */}
                                  {item.organizationStatus == "2" && item.createdBy != localStorage.loginId && (<>
                                    <Flex justify="space-between" className="accept_obj" align="center">
                                      <Checkbox
                                        className="fs-12 fw-600"
                                        id=""
                                        checked={isObjCheckedorg[index]}
                                        onChange={() => handleObjCheckboxorg(index)}
                                      >I confirm my commitment to this objective </Checkbox>
                                      <button className={isObjCheckedorg[index] ? 'cyan_button' : 'disable_btn'} disabled={!isObjCheckedorg[index]} onClick={() => { handleAcceptBtn(index, item.id, "orgobjective") }}>
                                        Accept
                                      </button>
                                    </Flex></>)}

                                  {(item.organizationStatus == "3" || item.organizationStatus == "4" || item.createdBy == localStorage.loginId) &&
                                    <Flex className="member_count_box" justify="space-between" align="center">
                                      <Dropdown overlay={memberMenu} trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={() => userReporteeList(item.orgObjectiveUsersList, item.id, "orgobjective")}>
                                          <Space>
                                            <div className="align-center">
                                              {
                                                memberActive ?
                                                  <img src="assets/images/memcount_active.svg" alt="" />
                                                  : <img src="assets/images/membercount.svg" alt="" />
                                              }

                                              <span className="fw-700 c-000 fs-14 ml-1">
                                                {item.orgObjectiveUsersList.length}
                                                <span className="ml-1">
                                                  {item.orgObjectiveUsersList.length > 1 ? "Members" : "Member"}
                                                </span>
                                              </span>
                                            </div>
                                          </Space>
                                        </a>
                                      </Dropdown>
                                      <Flex onClick={() => sub_orgobj(index)} className="cur-pointer align-center ">
                                        <img src="assets/images/layer_b.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                                        <span className="c_32B7D9 fs-14 fw-700 text_line">{item.objectiveslist.filter(userdata => userdata.cftstatus != 1).length}  Child-Obj</span>
                                      </Flex>
                                      <AssignObj
                                        objectivelist={Orgobjectivelist}
                                        objectcode={item.objectCode}
                                        objectname={item.objectiveName}
                                        orgQuartervalue={item.quarter}
                                        orgobjid={item.id}
                                        objid={""}
                                        objective_id={""}
                                        year={item.year}
                                        startdate={item.startdate}
                                        enddate={item.enddate}
                                        orgdescription={item.description}
                                        objectivetype={"orgobjective"}
                                        button={userSignOff.signOffStatus === 0 ? "+ Assign Objective" : ""}
                                        header={userSignOff.signOffStatus === 0 ? "Assign Objective" : ""}
                                      />
                                    </Flex>
                                  }
                                </div>
                              </div>
                              <Flex align="center" className={subOrgObj[index] ? "objective_bg_active" : ""} style={subOrgObj ? { right: "24px" } : {}}>
                                {subOrgObj[index] && (item.objectiveslist.map((objectives, values) =>
                                  objectives.cftstatus == 1 && (
                                    <>
                                      <Flex>
                                        <img src="assets/images/connect_obj.svg" alt="" />
                                        <div className={orgClass === `org-${index}-${values}` ? "subobjective_box_active" : "subobjective_box"} onClick={(event) => { suborgobjectivesdetailsgets(objectives, index, values); }} >
                                          <Flex className="" justify="space-between" align="center">
                                            <Flex align="center">
                                              <div className="container_label">
                                                <div className="wrapper_label align-center mb-1">
                                                  <span className={orgClass === `org-${index}-${values}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}>
                                                    <img
                                                      src="assets/images/crown.svg"
                                                      alt=""
                                                      className="pr-1"
                                                    />{" "}
                                                    {objectives.obj_code}
                                                  </span>
                                                  <span className={orgClass === `org-${index}-${values}` ? "triangle_active" : "triangle"}></span>
                                                </div>
                                              </div>
                                            </Flex>
                                            <div className="align-center mb-1">
                                              <div>
                                                <button className={` align-center border-0 p-1 mr-1 ${objectives.organizationStatus === "3" ? 'Progressing_btn' : (Visibility[index] ? 'cascade_btn' : 'cascade_btn')}`}>
                                                  <span className={` mr-1 ${objectives.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_cricle')}`}></span>
                                                  {objectives.revokedstatusorgobj != 1 && objectives.revokestatus !=1? (objectives.organizationStatus == "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}
                                                  {/* {objectives.organizationStatus === "3" ? " Progressing" : "Yet To Cascade"}{objectives.organizationStatus} */}
                                                </button>
                                              </div>
                                              <Dropdown overlay={childOrgMenu} trigger={["click"]}>
                                                <a onClick={(e) => {
                                                  e.preventDefault();
                                                  setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                                  setChildOrgObjectiveAndUser(objectives)
                                                  setObjectiveAndUser((prevObjectiveAndUser) => {
                                                    if (!prevObjectiveAndUser) {
                                                      return prevObjectiveAndUser;
                                                    }
                                                    return {
                                                      ...prevObjectiveAndUser,
                                                      revertstatus: objectives?.revokedstatusorgobj ?? prevObjectiveAndUser.revertstatus,
                                                    };
                                                  });
                                                }} href="#hgj"
                                                  data-value={objectives.obj_id}
                                                >
                                                  {
                                                    objectives.createdby === localStorage.loginId && userSignOff.signOffStatus === 0 &&
                                                    // objectives.signoffstatus === 0 && 
                                                    <Space>
                                                      <img src="assets/images/menu.svg" alt="" />
                                                      <input type="hidden" />
                                                    </Space>
                                                  }
                                                </a>
                                              </Dropdown>
                                            </div>
                                          </Flex>
                                          <Flex className=" fs-16 c-000 twoline_text_overflow">
                                            {permission_childobjview ? (<EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectives.obj_id} objectivestatus={objectives.organizationStatus} objectivecode={objectives.obj_code} objectiveName={objectives.obj_name} type={false} objectivetype={objectives.objectiveId == null ? "orgobjective" : "objective"} assigneesmembers={""} />) : (<><p className="fs-16 c-000 cur-pointer oneline_text_overflow mb-2">{objectives.obj_name}fmkd</p></>)}
                                          </Flex>
                                          <Flex>
                                            <p className="fs-16  mt-1 m-0  twoline_text_overflow">{objectives.obj_desc} </p>
                                          </Flex>
                                          <Row className="d-flex align-center justify-between mt-3">
                                            <Dropdown overlay={memberMenu} trigger={['click']}>
                                              <a className="ant-dropdown-link" onClick={() => userReporteeListSub(objectives.objectivesuser_list, objectives.objectiveId, "objective")}>
                                                <Space>
                                                  <div className="align-center">
                                                    <img src="assets/images/membercount.svg" alt="" />
                                                    <span className="fw-700 c-000 fs-14 ml-1">{objectives.objectivesuser_list.length}<span className="ml-1">{objectives.objectivesuser_list.length > 1 ? "Members" : "Member"}</span> </span>
                                                  </div>
                                                </Space>
                                              </a>
                                            </Dropdown>
                                            <div className="align-center">
                                              <img src="assets/images/KRA_Icon20.svg" alt="" />
                                              <span className="fw-700 c-000 fs-14">
                                                {objectives.keyResultcount} KR’s
                                              </span>
                                            </div>
                                            {/* <img src="assets/images/membercount.svg" alt="" />
                                    <span className="fw-700 fs-14 ml-1">{0}<span className="ml-1">Member</span></span> */}
                                          </Row>
                                        </div>
                                      </Flex>
                                    </>)
                                ))}
                              </Flex>
                            </div>
                          </>) : (<></>)}
                        </>

                      ))}
                      {objectiveuserslist.map((item, index) => (
                        // {status?()}
                        <>
                        {item.objectivesassignedusers.some(objUser => objUser.cftstatus == 1) && item.mapped_obj.filter(data => data.objectiveId != item.parentid) ? (<>
                          <div className="d-flex">
                            {/* <div className={`d-flex ${Visibility[index] ? 'add_border' : ''} ${objBgActive ? 'objBgActive' : ''}`} key={index} onClick={(event) => { objectivesdetailsgets(item); }}> */}
                            {/* <div
                             key={index}
                              className={` ${subObj ? ' objective_box p-2' : 'objective_box p-2 mr-0'}  ${keystatus ? 'objective_box_active' : ''}`}
                            > */}
                            <div
                              key={index}
                              onClick={(event) => { objectivesdetailsgets(item, index); }}
                              className={`${mainobjClass === `mainobj-${index}` ? `objective_box_active p-2 mr-1` : `objective_box p-2 ${Visibility[index] ? 'mr-0' : ''}`}`}>

                              {/* <div className={`${keystatus[index] ? `objective_box_active p-2 ${Visibility[index] ? 'mr-0' : ''}`: `objective_box p-2 ${Visibility[index] ? 'mr-0' : ''}` }`}> */}
                              <div className="">
                                <Row className="justify-between">
                                  <div className="container_label">
                                    <div className="wrapper_label align-center mb-1">
                                      <span className={`${mainobjClass === `mainobj-${index}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}`}>
                                        <img
                                          src="assets/images/crown.svg"
                                          alt=""
                                          className="pr-1"
                                        />{" "}
                                        {item.objcode}
                                      </span>
                                      <span className={` ${mainobjClass === `mainobj-${index}` ? "triangle_active" : "triangle"}`}></span>
                                    </div>
                                  </div>
                                  <div className="align-center mb-1">
                                    {
                                      ((item.cftstatus == 1 || item.objectivesassignedusers.some(objUser => objUser.cftstatus == 1))) &&
                                      <button className="CFT_btn mr-1">CFT</button>
                                    }
                                    <button className={` align-center border-0 p-1 mr-1 ${item.organizationStatus === "3" || item.organizationStatus === null ? 'Progressing_btn' : (Visibility[index] ? 'Progressing_btn_active' : ' cascade_btn')}`}>
                                      <span className={` mr-1 ${item.organizationStatus === "3" || item.organizationStatus === null ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : ' sm_cricle')}`}></span>
                                      {item.revertstatus != 1 ? (item.organizationStatus == null || item.organizationStatus == "3" ? "Progressing" : "Yet to Cascade") : ("Revoked")}
                                    </button>

                                    {/* { item.organizationStatus === "1" ?
                          ( */}
                                    <Dropdown menu={{ items }} trigger={["click"]}>
                                      <a onClick={(e) => {
                                        e.preventDefault();
                                        setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                        setobjectivestatus(e.currentTarget.getAttribute('data-status'))
                                        setObjectiveAndUser(item)
                                        setobjectivetype(item.parentid == null ? ("orgobjective") : ("objective"))
                                        userOrganizationList(item.objectivesassignedusers.map(user => user.userid));
                                        // setObjectiveId(itemId);                            
                                      }} href="#hgj"
                                        data-value={item.objid}
                                        data-status={item.organizationStatus}
                                      >
                                        {item.createdby === localStorage.loginId && userSignOff.signOffStatus === 0  &&

                                          <Space>
                                            <img src="assets/images/menu.svg" alt="" />
                                            <input type="hidden" />
                                          </Space>

                                        }
                                        {/* <input type="text" value={item.createdby} />
                             <input type="text" value={localStorage.loginid} /> */}
                                      </a>
                                    </Dropdown>
                                    {/* ) : (<></>)
                       }   */}
                                  </div>
                                </Row>
                                <Flex>
                                  {permission_parentobjview ? (<p className="fs-16 c-000 m-0 cur-pointer mb-2">
                                    <EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={item.objid} objectivestatus={item.organizationStatus} objectiveName={item.objname} objectivecode={item.objcode} type={false} objectivetype={item.parentid == null ? "orgobjective" : "objective"} assigneesmembers={""} />
                                  </p>) : (<>
                                    <p className="fs-16 c-000 cur-pointer mb-2">{item.objname}</p>
                                  </>)}

                                  {/* <p className="fs-16 c-000 mt-3 my_obj_desc">{item.description}</p> */}
                                </Flex>
                                <Flex>
                                  <p className="fs-14 mb-1 m-0 c_8080 threeline_text_overflow">
                                    {/* <p className="fs-16  m-0 org_obj_desc mb-2"> */}
                                    {item.description}
                                  </p>
                                </Flex>
                                {/*Accept checkbox */}
                                {item.organizationStatus == "2" && (<>
                                  <Flex justify="space-between" className="accept_obj" align="center">
                                    <Checkbox
                                      className="fs-12 fw-600"
                                      id=""
                                      checked={isObjChecked[index]}
                                      onChange={() => handleObjCheckbox(index)}
                                    >I confirm my commitment to this objective </Checkbox>
                                    <button className={isObjChecked[index] ? 'cyan_button' : 'disable_btn'} disabled={!isObjChecked[index]} onClick={() => { handleAcceptBtn(index, item.objid, "objective") }}>
                                      Accept
                                    </button>
                                  </Flex></>)}

                                {(item.organizationStatus == "3" || item.organizationStatus == "5") &&
                                  <Flex className="member_count_box" justify="space-between" align="center">
                                    <Dropdown overlay={memberMenu} trigger={['click']}>
                                      <a className="ant-dropdown-link" onClick={() => userReporteeList(item.objectivesassignedusers, item.objectiveid, "objective")}>
                                        <Space>
                                          <div className="align-center">
                                            <img src="assets/images/membercount.svg" alt="" />
                                            <span className="fw-700 c-000 fs-14 ml-1">{item.objectivesassignedusers.length}<span className="ml-1">{item.objectivesassignedusers.length > 1 ? "Members" : "Member"}</span> </span>
                                          </div>
                                        </Space>
                                      </a>
                                    </Dropdown>
                                    <div className="align-center">
                                      <img src="assets/images/KRA_Icon20.svg" alt="" />
                                      <span className="fw-700 c-000 fs-14">
                                        {item.keyResultcount} KR’s
                                      </span>
                                    </div>
                                    <Flex onClick={() => sub_obj(index)} className="cur-pointer  align-center ">
                                      <img src="assets/images/layer_b.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                                      <span className=" c_32B7D9 fs-14 fw-700 text_line">{item.mapped_obj_count} Child-Obj</span>
                                    </Flex>
                                    <AssignObj
                                      objectivelist={objectivelist}
                                      objectcode={item.objcode}
                                      objectname={item.objname}
                                      orgQuartervalue={item.quartervalue}
                                      orgobjid={item.orgobjid}
                                      objid={item.objid}
                                      objective_id={item.objectiveid}
                                      year={item.year}
                                      startdate={item.startdate}
                                      enddate={item.enddate}
                                      orgdescription={item.description}
                                      objectivetype={"objective"}
                                      button="+ Assign Objective"
                                      header="Assign Objective"
                                    />
                                  </Flex>
                                }
                                {/* <Flex className="" justify="space-between" align="center"> */}
                                {/* <div className="align-center mb-2">
                          <span className="mr-1 fs-14">Created by</span>
                          <Tooltip placement="top" title={item.createdbyname} arrow={true}>
                            <img
                              // src="assets/images/user.svg"
                              src={item.createdByProfilePath ? FileUrl + item.createdByProfilePath : "assets/images/Default_user.svg"}
                              onError={handleImageError}
                              className="user"
                              alt=""
                            />
                          </Tooltip>
                        </div> */}
                                {/* <div className="align-center  mb-2"> */}
                                {/* <span className="mr-1 fs-14">Assigned to</span> */}

                                {/* {item.objectivesassignedusers.length > 2 ? ( */}
                                {/* <>
                              <Avatar.Group
                                maxCount={2}
                                maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                              >
                                {item.objectivesassignedusers.slice(0, item.objectivesassignedusers.length).map((user: any, index) => (
                                  <>
                                    {/* <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} /> */}
                                {/* <Tooltip placement="top" title={user.name} arrow={true}> */}
                                {/* <p>{user.profilepath}</p> */}
                                {/* <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError} />
                                    </Tooltip> */}
                                {/* </> */}
                                {/* ))} */}
                                {/* </Avatar.Group> */}
                                {/* </> */}
                                {/* ) : (
                            item.objectivesassignedusers.map((user: any, index) => (
                              <Tooltip placement="top" title={user.name} arrow={true}>
                                <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError} />
                              </Tooltip>
                            ))
                          ) */}
                                {/* } */}
                                {/* </div>
                      </Flex> */}
                              </div>
                              {/* <Flex className="" justify="space-between" align="center"> */}
                              {/* <Flex onClick={item.mapped_obj.length > 0 ? () => toggleVisibilitys(index) : undefined} className="cur-pointer"> */}
                              {/* <Flex onClick={sub_obj} className="cur-pointer">
                        <img src="assets/images/layer.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                        <span className="p-1 c-000 fs-12">{item.mapped_obj_count}</span>
                      </Flex> */}
                              {/* </Flex> */}
                              {/* <Flex className="" justify="space-between" align="center">
                      <Flex className="cur-pointer">
                        <img src="assets/images/Network.svg" alt="" />
                        <span className="p-1 c-000 fs-12">Network</span>
                      </Flex>
                      <Flex>
                        
                        <Row>
                          <img src="assets/images/message-chat.svg" alt="" />
                          <span className="p-1 c-000 fs-12">Conversation</span>
                        </Row>
                      </Flex>
                      <Flex onClick={item.mapped_obj.length > 0 ? () => toggleVisibilitys(index) : undefined} className="cur-pointer">
                        <img src="assets/images/layer.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                        <span className="p-1 c-000 fs-12">{item.mapped_obj_count}</span>
                      </Flex>
                      {item.organizationStatus === '1' ? (
                        <></>
                      ) : (
                        <>
                          <Flex className="cur-pointer">
                            {item.revertstatus != 1}
                            {item.revertstatus !== 1 &&
                              <CreateParentObj
                                objectcode={item.objcode}
                                objectname={item.objname}
                                orgQuartervalue={item.quartervalue}
                                orgobjid={item.orgobjid}
                                objid={item.objid}
                                objective_id={item.objectiveid}
                                year={item.year}
                                button="+ Create Child Objective"
                                header="Create Child Objective"
                              />
                            }
                          </Flex>
                        </>
                      )}
                    </Flex> */}
                              {/* </div> */}
                              {/* <Flex className="" justify="space-between" align="center">
                    <Flex className="cur-pointer">
                      <img src="assets/images/Network.svg" alt="" />
                      <span className="p-1 c-000 fs-12">Network</span>
                    </Flex>
                    <Flex> */}
                              {/* <Conversation/> */}
                              {/* <Row>
                        <img src="assets/images/message-chat.svg" alt="" />
                          <span className="p-1 c-000 fs-12">Conversation</span>
                      </Row>
                    </Flex>
                    <Flex justify="space-between" align="center">
                          {item.organizationStatus === "1" && (
                            item.objectivesassignedusers.every(user => (user.weightage !== 0) && (user.availableweightage >= user.weightage)) && (item.objectivesassignedusers.length != 0) && (<>
                              <img src="assets/images/Version_control.svg" alt="" />
                             <span className="c_32B7D9 ml-1 fw-300" onClick={() => cascadeStatus(item.objid,item.objname)}>Cascade</span>
                             </>
                            )
                          )}
                          </Flex>
                    <Flex onClick={item.mapped_obj.length > 0 ? () => toggleVisibilitys(index) : undefined} className="cur-pointer">
                      <img src="assets/images/layer.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                      <span className="p-1 c-000 fs-12">{item.mapped_obj_count}</span>
                    </Flex>
                    {item.organizationStatus === '1' ? (
                      <></>
                    ) : (
                      <> */}

                              {/* {permission_childobjview ?

                        ( */}
                              {/* <Flex className="cur-pointer">
                          {item.revertstatus != 1}
                          {item.revertstatus !== 1 &&
                            <CreateParentObj
                              objectivelist={objectivelist}
                              objectcode={item.objcode}
                              objectname={item.objname}
                              orgQuartervalue={item.quartervalue}
                              orgobjid={item.orgobjid}
                              objid={item.objid}
                              objective_id={item.objectiveid}
                              year={item.year}
                              orgdescription={item.description}
                              button="+ Create Child Objective"
                              header="Create Child Objective"
                            />
                          }
                        </Flex> */}
                              {/* ):''} */}
                              {/* </>
                    )}
                  </Flex> */}
                            </div>
                            <Flex align="center" className={subObj[index] ? "objective_bg_active mr-0" : ""} style={subObj ? { right: "24px" } : {}}>

                              {subObj[index] && (item.mapped_obj.map((objectives, values) =>
                                <>
                                  {/* {Visibility[index] && (item.mapped_obj.map((objectives, values) => */}

                                  <Flex>
                                    <img src="assets/images/connect_obj.svg" alt="" />
                                    <div className={objClass === `obj-${index}-${values}` ? "subobjective_box_active" : "subobjective_box"} onClick={(event) => { subobjectivesdetailsgets(objectives, index, values); }}>
                                      <Flex className="" justify="space-between" align="center">
                                        <Flex align="center">
                                          {/* <img src="assets/images/crown.svg" alt="" />
                                <span className="fs-14 pl-1 c-000">{objectives.obj_name} </span> */}
                                          <div className="container_label">
                                            <div className="wrapper_label align-center mb-1">
                                              <span
                                                className={objClass === `obj-${index}-${values}` ? "cup_org_bg_active align-center" : "cup_org_bg align-center"}>
                                                <img
                                                  src="assets/images/crown.svg"
                                                  alt=""
                                                  className="pr-1"
                                                />{" "}
                                                {objectives.obj_code}
                                                {/* dbcvhdgv */}
                                              </span>
                                              <span className={objClass === `obj-${index}-${values}` ? "triangle_active" : "triangle"}></span>

                                            </div>
                                          </div>

                                        </Flex>
                                        <div className="align-center mb-1">
                                          {/* <div>
                            <span className="progress_btn">
                            {objectives.status === "1" ? "Yet To Cascade" : "Progressing"}
                           </span>
                          </div> */}
                                          {/* {
                                ((objectives.cftstatus == "1" || objectives.objectiveassignedusers.some(objUser => objUser.cftstatus == 1))) &&
                                <button className="CFT_btn mr-1">CFT</button>
                              }
                              <button className={` align-center border-0 p-1 mr-1 ${objectives.status === "1" ? 'cascade_btn' : 'Progressing_btn'}`}>
                                <span className={` mr-1 ${objectives.status === "1" ? 'sm_cricle' : 'sm_pro_cricle'}`}></span>
                                {objectives.revertstatus !== 1 ?
                                  (objectives.status === "1" ? "Yet To Cascade" : "Progressing") : "Revoked"}
                              </button> */}
                                          {/* { item.organizationStatus === "1" ?
                          ( */}

                                          <div>
                                            <button className={` align-center border-0 p-1 mr-1 ${objectives.organizationStatus === "5" ? 'cascade_btn' : (Visibility[index] ? 'Progressing_btn_active' : 'Progressing_btn')}`}>
                                              <span className={` mr-1 ${objectives.organizationStatus === "3" ? 'sm_pro_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_pro_cricle')}`}></span>
                                              {objectives.revertstatus != 1 ? (objectives.organizationStatus === "3" || objectives.organizationStatus == "4" ? "Progressing" : "Yet To Cascade") : ("Revoked")}
                                            </button>
                                          </div>
                                          <Dropdown overlay={childMenu} trigger={["click"]}>
                                            <a onClick={(e) => {
                                              e.preventDefault();
                                              setObjectiveId(e.currentTarget.getAttribute('data-value'));
                                              setChildObjectiveAndUser(objectives)
                                              setObjectiveAndUser((prevObjectiveAndUser) => {
                                                if (!prevObjectiveAndUser) {
                                                  return prevObjectiveAndUser;
                                                }
                                                return {
                                                  ...prevObjectiveAndUser,
                                                  revertstatus: objectives?.revertstatus ?? prevObjectiveAndUser.revertstatus,
                                                };
                                              });
                                            }} href="#hgj"
                                              data-value={objectives.obj_id}
                                            >

                                              {objectives.createdby === localStorage.loginId && userSignOff.signOffStatus === 0 &&
                                                // objectives.objectiveassignedusers.some((usersignoff) => usersignoff.signOffStatus === 0 && usersignoff.userid === localStorage.loginId) ||
                                                <Space>
                                                  <img src="assets/images/menu.svg" alt="" />
                                                  <input type="hidden" />
                                                </Space>
                                              }

                                              {/* <input type="text" value={item.createdby} />
                             <input type="text" value={localStorage.loginid} /> */}
                                            </a>
                                          </Dropdown>
                                        </div>
                                      </Flex>
                                      <Flex className=" fs-16 c-000 twoline_text_overflow">
                                        {permission_childobjview ? (<EditParentObj objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectiveId={objectives.obj_id} objectivecode={objectives.obj_code} objectivestatus={objectives.organizationStatus} objectiveName={objectives.obj_name} type={false} objectivetype={objectives.objectiveId == null ? "orgobjective" : "objective"} assigneesmembers={""} />) : (<><p className="fs-16 c-000 cur-pointer threeline_text_overflow mb-2">{objectives.obj_name}fmkd</p></>)}
                                      </Flex>
                                      <Flex>
                                        <p className="fs-16 twoline_text_overflow mt-1 m-0 ">{objectives.obj_desc}</p>
                                      </Flex>
                                      <Row className="d-flex align-center justify-between mt-3">
                                        <Dropdown overlay={memberMenu} trigger={['click']}>
                                          <a className="ant-dropdown-link" onClick={() => userReporteeList(objectives.objectiveassignedusers, objectives.objectiveId, "objective")}>
                                            <Space>
                                              <div className="align-center">
                                                <img src="assets/images/membercount.svg" alt="" />
                                                <span className="fw-700 c-000 fs-14 ml-1">{objectives.objectiveassignedusers.length}<span className="ml-1">{objectives.objectiveassignedusers.length > 1 ? "Members" : "Member"}</span> </span>
                                              </div>
                                            </Space>
                                          </a>
                                        </Dropdown>
                                        <div className="align-center">
                                          <img src="assets/images/KRA_Icon20.svg" alt="" />
                                          <span className="fw-700 c-000 fs-14">
                                            {objectives.keyResultcount} KR’s
                                          </span>
                                        </div>

                                        {/* <img src="assets/images/membercount.svg" alt="" />
                                          <span className="fw-700 fs-14 ml-1">{objectives.objectiveassignedusers.length}<span className="ml-1">Member</span></span> */}
                                      </Row>
                                      {/* <Flex className="p-2" justify="space-between" align="center"> */}
                                      {/* <div className="align-center mt-2 mb-2">
                              <span className="mr-1 fs-14">Created by</span>

                              <Tooltip placement="top" title="bn1" arrow={true}>
                                <img
                                  src="assets/images/user.svg"
                                  // src={profilePhoto}
                                  // src={objectives.createdByProfilePath ? FileUrl + objectives.createdByProfilePath : "assets/images/Default_user.svg"}
                                  onError={handleImageError}
                                  className="user"
                                  alt=""
                                />
                              </Tooltip>

                            </div> */}
                                      {/* <div className="align-center mt-2 mb-2">
                              <span className="mr-1 fs-14">Assigned to</span>
                              {objectives.objectiveassignedusers.length > 2 ? (//{objectives.objectiveassignedusers.length > 2 ? (
                                <>
                                  <Avatar.Group
                                    maxCount={2}
                                    maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                  >
                                    {objectives.objectiveassignedusers.slice(0, objectives.objectiveassignedusers.length).map((user: any, index) => (//objectives.objectiveassignedusers.
                                      <>
                                        <Tooltip placement="top" title={user.name} arrow={true}>
                                          {/* <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} /> */}
                                      {/* <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError} /> */}
                                      {/* </Tooltip> */}
                                      {/* </> */}
                                      {/* ))}
                                  </Avatar.Group>
                                </>
                              ) : (
                                objectives.objectiveassignedusers.map((user: any, index) => (// objectives.objectiveassignedusers.map((user, index) => (
                                  <Tooltip placement="top" title={user.name} arrow={true}>
                                    <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt="" onError={handleImageError} />
                                  </Tooltip>
                                ))
                              )
                              }
                            </div> */}
                                      {/* </Flex> */}
                                      {/* <Flex className="p-2" justify="space-between" align="center">
                          <Flex className="cur-pointer">
                            <img src="assets/images/Network.svg" alt="" />
                            <span className="p-1 c-000 fs-12">Network</span>
                          </Flex>
                          <Flex>
                            <img src="assets/images/message-chat.svg" alt="" />
                            <span className="p-1 c-000 fs-12">Discussion</span>
                          </Flex>
                        </Flex> */}
                                    </div>
                                  </Flex>
                                </>

                              ))}
                            </Flex>
                          </div>
                        </>) : ""}
                        </>
                      ))
                        // ) : (
                        //   // for The TM user
                        //   objectiveuserslist.map((item, index) => (

                        //     <div className={`d-flex${Visibility[index] ? ' add_border' : ''}`} key={index} onClick={(event) => { objectivesdetailsgets(item); }}>
                        //       <div
                        //         className={`objective_box p-2 ${Visibility[index] ? 'mr-0' : ''}`}
                        //       >
                        //         <div className="">
                        //           <Row className="justify-between">
                        //             <div className="container_label">
                        //               <div className="wrapper_label align-center mb-1">
                        //                 <span className="cup_org_bg align-center">
                        //                   <img
                        //                     src="assets/images/crown.svg"
                        //                     alt=""
                        //                     className="pr-1"
                        //                   />{" "}
                        //                   {item.objcode}
                        //                 </span>
                        //                 <span className="triangle"></span>
                        //               </div>
                        //             </div>
                        //             <div className="align-center mb-1">
                        //               {
                        //               item.cftstatus=="1" &&
                        //                <button className="CFT_btn mr-1">CFT</button>
                        //                }

                        //               <button className={` align-center border-0 p-1 mr-1 ${item.organizationStatus === "1" ? 'cascade_btn' : 'Progressing_btn'}`}>
                        //                 <span className={` mr-1 ${item.organizationStatus === "1" ? 'sm_cricle' : 'sm_pro_cricle'}`}></span>
                        //                 {item.organizationStatus === "1" ? "Yet To Cascade" : "Progressing"}
                        //               </button>

                        //               {/* { item.organizationStatus === "1" ?
                        //                 ( */}
                        //               <Dropdown menu={{ items }} trigger={["click"]}>
                        //                 <a onClick={(e) => {
                        //                   e.preventDefault();
                        //                   setObjectiveId(e.currentTarget.getAttribute('data-value'));
                        //                   setObjectiveAndUser(item)
                        //                   // setObjectiveId(itemId);                            
                        //                 }} href="#hgj"
                        //                   data-value={item.objid}
                        //                 >{localStorage.loginId == item.createdby &&
                        //                   <Space>
                        //                     <img src="assets/images/menu.svg" alt="" />
                        //                     <input type="hidden" />
                        //                   </Space>
                        //                   }
                        //                   {/* <input type="text" value={item.createdby} />
                        //                    <input type="text" value={localStorage.loginid} /> */}
                        //                 </a>
                        //               </Dropdown>
                        //               {/* ) : (<></>)
                        //              }   */}
                        //             </div>
                        //           </Row>
                        //           <Flex>
                        //             <p className="fs-16 c-000 mt-3 my_obj_desc">{item.objname}</p>
                        //             {/* <p className="fs-16 c-000 mt-3 my_obj_desc">{item.description}</p> */}
                        //           </Flex>
                        //           {/* <Flex>
                        //             <p className="fs-16 org_obj_desc">
                        //               {item.description}
                        //             </p>
                        //           </Flex> */}
                        //           <Flex className="" justify="space-between" align="center">
                        //             <div className="align-center mb-2">
                        //               <span className="mr-1 fs-14">Created by</span>
                        //               <Tooltip placement="top" title={item.createdbyname} arrow={true}>
                        //                 <img
                        //                   src="assets/images/user.svg"
                        //                   className="user"
                        //                   alt=""
                        //                 />
                        //               </Tooltip>
                        //             </div>
                        //             <div className="align-center  mb-2">
                        //               <span className="mr-1 fs-14">Assigned to</span>

                        //               {item.objectivesassignedusers.length > 2 ? (
                        //                 <>
                        //                   <Avatar.Group
                        //                     maxCount={2}
                        //                     maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        //                   >
                        //                     {item.objectivesassignedusers.slice(0, item.objectivesassignedusers.length).map((user, index) => (
                        //                       <>
                        //                         <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} />
                        //                       </>
                        //                     ))}
                        //                   </Avatar.Group>
                        //                 </>
                        //               ) : (
                        //                 item.objectivesassignedusers.map((user, index) => (
                        //                   <Avatar
                        //                     src="assets/images/Avatar1.png"
                        //                     className="avatar"
                        //                     key={index}
                        //                   />
                        //                 ))
                        //               )
                        //               }
                        //             </div>
                        //           </Flex>
                        //         </div>
                        //         <Flex className="" justify="space-between" align="center">
                        //           <Flex className="cur-pointer">
                        //             <img src="assets/images/Network.svg" alt="" />
                        //             <span className="p-1 c-000 fs-12">Network</span>
                        //           </Flex>
                        //           <Flex>
                        //             <img src="assets/images/message-chat.svg" alt="" />
                        //             <span className="p-1 c-000 fs-12">Discussion</span>
                        //           </Flex>
                        //           <Flex onClick={item.mapped_obj.length > 0 ? () => toggleVisibilitys(index) : undefined} className="cur-pointer">
                        //             <img src="assets/images/layer.svg" alt="" className={Visibility[index] ? "layer_active" : ""} />
                        //             <span className="p-1 c-000 fs-12">{item.mapped_obj_count}</span>
                        //           </Flex>
                        //           {item.organizationStatus === '1' ? (
                        //             <></>
                        //           ) : (
                        //             <>
                        //             <Flex className="cur-pointer">
                        //             <CreateParentObj
                        //               objectcode={item.objcode}
                        //               objectname={item.objname}
                        //               orgQuartervalue={item.quartervalue}
                        //               orgobjid={item.orgobjid}
                        //               objid={item.objid}
                        //               button="+ Create Child Objective"
                        //               header = "Create Child Objective"
                        //                />
                        //           </Flex>
                        //             </>
                        //           )}
                        //         </Flex>
                        //       </div>
                        //       <Flex align="center" className={Visibility[index] ? "objective_bg_active" : ""}>
                        //         {Visibility[index] && (item.mapped_obj.map((objectives, values) =>
                        //           <Flex>

                        //             <div className="subobjective_box" >
                        //               <Flex className="p-2" justify="space-between" align="center">
                        //                 <Flex align="center">
                        //                   {/* <img src="assets/images/crown.svg" alt="" />
                        //                       <span className="fs-14 pl-1 c-000">{objectives.obj_name} </span> */}
                        //                   <div className="container_label">
                        //                     <div className="wrapper_label align-center mb-1">
                        //                       <span className="cup_org_bg align-center">
                        //                         <img
                        //                           src="assets/images/crown.svg"
                        //                           alt=""
                        //                           className="pr-1"
                        //                         />{" "}
                        //                         {objectives.obj_code}
                        //                       </span>
                        //                       <span className="triangle"></span>
                        //                     </div>
                        //                   </div>
                        //                 </Flex>
                        //                 <div className="align-center mb-1">
                        //                   {/* <div>
                        //                   <span className="progress_btn">
                        //                   {objectives.status === "1" ? "Yet To Cascade" : "Progressing"}
                        //                  </span>
                        //                 </div> */}
                        //                  {/* <button className="CFT_btn mr-1">CFT</button>
                        //                   <button className={` align-center border-0 p-1 mr-1 ${objectives.status === "1" ? 'cascade_btn' : 'Progressing_btn'}`}>
                        //                     <span className={` mr-1 ${objectives.status === "1" ? 'sm_cricle' : 'sm_pro_cricle'}`}></span>{objectives.status === "1" ? "Yet To Cascade" : "Progressing"}
                        //                   </button> */}

                        //                   {/* { item.organizationStatus === "1" ?
                        //                 ( */}
                        //                   <Dropdown menu={{ items }} trigger={["click"]}>
                        //                     <a onClick={(e) => {
                        //                       e.preventDefault();
                        //                       setObjectiveId(e.currentTarget.getAttribute('data-value'));
                        //                       setObjectiveAndUser(item)
                        //                       // setObjectiveId(itemId);                            
                        //                     }} href="#hgj"
                        //                       data-value={objectives.obj_id}
                        //                     >
                        //                       <Space>
                        //                         <img src="assets/images/menu.svg" alt="" />
                        //                         <input type="hidden" />
                        //                       </Space>

                        //                       {/* <input type="text" value={item.createdby} />
                        //                    <input type="text" value={localStorage.loginid} /> */}
                        //                     </a>
                        //                   </Dropdown>
                        //                 </div>
                        //               </Flex>
                        //               <Flex className="p-2 fs-16 c-000">
                        //                 {objectives.obj_name}
                        //               </Flex>
                        //               <Flex className="p-2" justify="space-between" align="center">
                        //                 <div className="align-center mt-2 mb-2">
                        //                   <span className="mr-1 fs-14">Created by</span>
                        //                   <img
                        //                     src="assets/images/user.svg"
                        //                     className="user"
                        //                     alt=""
                        //                   />
                        //                 </div>
                        //                 <div className="align-center mt-2 mb-2">
                        //                   <span className="mr-1 fs-14">Assigned to</span>

                        //                   {objectives.objectiveassignedusers.length > 2 ? (
                        //                     <>
                        //                       <Avatar.Group
                        //                         maxCount={2}
                        //                         maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        //                       >
                        //                         {objectives.objectiveassignedusers.slice(0, objectives.objectiveassignedusers.length).map((user, index) => (
                        //                           <>
                        //                             <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} />
                        //                           </>
                        //                         ))}
                        //                       </Avatar.Group>
                        //                     </>
                        //                   ) : (
                        //                     objectives.objectiveassignedusers.map((user, index) => (
                        //                       <Avatar
                        //                         src="assets/images/Avatar1.png"
                        //                         className="avatar"
                        //                         key={index}
                        //                       />
                        //                     ))
                        //                   )
                        //                   }
                        //                 </div>
                        //               </Flex>
                        //               <Flex className="p-2" justify="space-between" align="center">
                        //                 <Flex className="cur-pointer">
                        //                   <img src="assets/images/Network.svg" alt="" />
                        //                   <span className="p-1 c-000 fs-12">Network</span>
                        //                 </Flex>
                        //                 <Flex>
                        //                   <img src="assets/images/message-chat.svg" alt="" />
                        //                   <span className="p-1 c-000 fs-12">Discussion</span>
                        //                 </Flex>
                        //               </Flex>
                        //             </div>

                        //           </Flex>
                        //         )
                        //         )}
                        //       </Flex>
                        //     </div>
                        //   ))
                        // )
                      }

                    </Flex>
                    {/* className={isVisible ? "objective_bg_active" : ""} */}

                  </Flex>
                  {/* className={isVisible ? "objective_bg_active" : ""} */}
                </div>

                <div className="">
                  {/* <Row className=" mt-2 mb-1" justify="space-between">
          <span className="fs-16 c-000 mb-1">
            5 Key Results for{" "}
            <b>
              Update IT infrastructure to improve performance, security and
              scalability
            </b>
          </span>
          <button className="cyan_button ">+ Add Key Result</button>
        </Row>
        <Row className="card p-2 mt-2 mb-2">
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Row>
              <img src="assets/images/KRA-Icon.svg" alt="" />
              <span className="fs-14 c_0040">#kr01001</span>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <p className="fs-16 c-000 m-0">
                  Achieve a 20%reduction in average server response time.
                </p>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row className="obj-progress">
                  <Progress className="" percent={65} />
                </Row>
              </Col>
            </Row>
            <Row className="align-end">
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="align-center mt-3"
                  >
                    <span className="mr-1">Cascaded by</span>
                    <img src="assets/images/user.svg" alt="" />
                  </Col>
                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="align-center mt-3"
                  >
                    <span className="mr-1">Cascaded by</span>
                    <img src="assets/images/user.svg" alt="" />
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="align-center mt-2"
                  >
                    <img src="assets/images/crown.svg" alt="" />
                    <span className="ml-1">#ob0001</span>
                  </Col>
                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="align-center mt-2"
                  >
                    <img src="assets/images/date-picker1.svg" alt="" />
                    <span className="ml-1">Q3 2023-2024</span>
                  </Col>
                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="align-center mt-2"
                  >
                    <img src="assets/images/message-chat.svg" alt="" />
                    <span className="ml-1">Discussion</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={24}
            className="obj-pagination pl-2"
          >
            <Row className="justify-between">
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <p className="fw-600">Lead Measures</p>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2">
                <CustomPagination
                  current={currentPage}
                  total={contentData.length}
                  onChange={handlePageChange}
                />
              </Col>
            </Row>
            <Row>
              {currentItems.map((item, index) => (
                <Col xs={24}>
                  <Card>{item}</Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>  */}
                </div>
                {Orgobjectiveuserslist.length == 0 && objectiveuserslist.length == 0 ? (
                  <>
                    <Card className="Asp_nc_card w-100 ">
                      <Row className="text-center obj_textbox">
                        <span className="c_AAA fs-14 fw-600 ">Assigned objectives not created yet.</span>
                      </Row>
                    </Card>
                    {0 === userSignOff?.signOffStatus &&
                      <Row className="justify-between mt-2 mb-2">
                        <span className="fs-16 fw-600">0 Key Results </span>
                        <button className="disable_btn">+ Add Key Result</button>
                      </Row>
                    }

                    <Card className="Aso_obj_nc_card mt-2 mb-3">
                      <Row className="text-center obj_textbox ">
                        <span className="c_AAA fs-14 fw-600">Once objectives created, you can create your Key results.</span>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <>
                    {(objectivesassignedkr.length === 0 && objectivesteamdkr.length === 0) ? (
                      krforobj === true ? (
                        <>
                          <Col className="">
                            {objectivesassignedkr.some((data) => data.status == 3) &&
                              <span className="pl-1" onClick={handleKrshow}><img src="assets/images/KR_available.svg" className="cur-pointer" alt="" width={25} height={25} /></span>
                            }
                          </Col>
                          <Row className=" justify-between align-center mt-2" >
                            <Col >
                              {/* <span className="fs-16 fw-600 keyresult_title ">{objectivesassignedkr.filter((data) => data.status != 3).length} My Key {objectivesassignedkr.filter((data) => data.status != 3).length > 1 ? "Results" : "Result"}  for <b className="">{objectivekeys.obj_name} </b></span> */}
                              <span className="fs-16 fw-600 keyresult_title "> Key Result for <b className="">{objectivekeys.obj_name} </b></span>
                            </Col>
                            <Col className="d-flex align-center">
                              {/* {saveAndCascadeUser !== 0 ? (<> */}
                              {/* <p className="c_32B7D9 text_line cur-pointer mr-1" onClick={ReviewKRWeight} >Review KR Weightage</p> */}
                              {/* </>) : (<></>)} */}
                              <span><AddKR objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectivesdetailsgets={() => { handleKrChange(selectedKrValue, objectivekeys.obj_id, localStorage.getItem('loginId') || "") }} krassignforme={() => { getassignedkeyforobjectives(objectivekeys.obj_id,localStorage.loginid) }} objdesc={objectivekeys.obj_desc} objquarter={objectivekeys.obj_quarter} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} year={objectivekeys.year} availableweightagekr={availableweightagekr} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} selectedkrvalue={selectedKrValue}                   /></span>
                            </Col>
                          </Row>
                        </>
                      ) : (<></>)
                    ) : (
                      krforobj === true &&
                      <>
                        <Row className=" justify-between align-center mt-2" >
                          <Col>
                          <span className="fs-16 fw-600 keyresult_title">
                            {selectedKrValue === "All Key Results" && (objectivesassignedkr.filter((data) => data.status != 3).length)}
                            {selectedKrValue === "My Key Results" && objectivesassignedkr.filter((data) => data.status != 3).length}
                            {selectedKrValue === "Team Key Results" && objectivesteamdkr.length}
                            &nbsp;Key&nbsp;
                            {(selectedKrValue === "All Key Results" && (objectivesassignedkr.length) > 1 ) || (selectedKrValue === "My Key Results" && objectivesassignedkr.length > 1)||(selectedKrValue === "Team Key Results" && objectivesteamdkr.length > 1)  ? " Results" : " Result"} 
                            &nbsp;for&nbsp; <b>{objectivekeys.obj_name}</b>
                          </span>

                          </Col>
                          <Col className="d-flex align-center">
                            {objectivekeys.revertstatus !== 1 && objectivekeys.obj_id != null &&
                              <>
                                {/* {availableweightagekr!=0? */}
                                {/* <Col className="mb-2 mt-2 mr-3">
                          <Row className="align-center">
                            <label>Available Weightage: &nbsp;</label>
                            <span className={availableweightagekr < 0 ? "danger" : "c_32B7D9"}>{availableweightagekr}%</span>
                            {objectivesassignedkr.some((data) => data.status == 3) &&
                              <span className="pl-1" onClick={handleKrshow}><img src="assets/images/KR_available.svg" className="cur-pointer mr-3" alt="" width={25} height={25} /></span>
                            }
                          </Row>
                        </Col> */}
                                {/* {availableweightagekr==0&&
                    (objectivesassignedkr.some((data=>data.status==1))?(
                    <div className="mr-1"><button className="border-btn" onClick={cascadeallkeyresults}>Cascade All</button></div>)
                    :(
                      <div className="mr-1"><button className="border-btn">Cascaded</button></div>)
                    )
                      
                    } */}
                                {/* {saveAndCascadeUser !== 0 ? (<> */}
                                {/* <p className="c_32B7D9 text_line cur-pointer mr-1" onClick={ReviewKRWeight} >Review KR Weightage</p> */}
                                {/* </>) : (<></>)} */}

                                {/* {availableweightagekr != 0 && */}
                                
                              <Select
                              className="custom-select addkr_results_dropdown mr-1"
                              style={{ width: '200px' }}
                            placeholder="Select currency"
                              onChange={(e)=>handleKrChange(e,objectivekeys.obj_id,localStorage.getItem('loginId')||"")}
                              value={selectedKrValue}
                            >
                              <Option value="All Key Results">
                                <Radio
                                  value="All Key Results"
                                  checked={selectedKrValue === "All Key Results"}
                                >
                                  All Key Results
                                </Radio>
                                <img src="assets/images/all_kr.svg" alt="" />
                              </Option>
                              <Option value="My Key Results">
                                <Radio
                                  value="My Key Results"
                                  checked={selectedKrValue === "My Key Results"}
                                >
                                  My Key Results
                                </Radio>
                                <img src="assets/images/my_kr.svg" alt="" />

                              </Option>
                              <Option value="Team Key Results">
                                <Radio
                                  value="Team Key Results"
                                  checked={selectedKrValue === "Team Key Results"}
                                >
                                  Team Key Results 
                                </Radio>
                                <img src="assets/images/team_kr.svg" alt="" />

                              </Option>
                            </Select>
                                <span className="nowrap"><AddKR objectivelist={objectivelist} orgobjectivelist={Orgobjectivelist} objectivesdetailsgets={() => { handleKrChange(selectedKrValue, objectivekeys.obj_id, localStorage.getItem('loginId') || "") }} krassignforme={() => { getassignedkeyforobjectives(objectivekeys.obj_id,localStorage.loginid) }} objdesc={objectivekeys.obj_desc} objquarter={objectivekeys.obj_quarter} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} year={objectivekeys.year} availableweightagekr={availableweightagekr} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} selectedkrvalue={selectedKrValue} /></span>
                                {/* } */}
                              </>

                            }
                          </Col>
                        </Row>
                        <div>
                          
                        </div>
                        <div className="obj_tab" >
                          {/* <Tabs defaultActiveKey="1" onChange={onChange} className='p-0 m-0' > */}
                            {/* <TabPane tab={<>Team Key {objectivesteamdkr.filter((data) => data.status != 3).length > 1 ? "Results" : "Result"} ({objectivesteamdkr.length})</>} key="2"> */}
                              {objectivesteamdkr.length === 0 && objectivesassignedkr.length === 0  ? (<>
                                <Card className="Aso_obj_nc_card mt-2 mb-3">
                                  <Row className="text-center obj_textbox ">
                                    <span className="c_AAA fs-14 fw-600">No Key Results Available.</span>
                                  </Row>
                                </Card>
                              </>) : 
                              (
                                <>
                               
                                <div className="keyresult_div">
                                {selectedKrValue === "All Key Results" && (
                                  <>  
                                     {objectivesassignedkr.map((objective, index) => (
                                    objective.status != 3 && (
                                      <Row className="kr_card p-2 mt-1 mb-2 mr-1 ">
                                        <Col xl={15} lg={15} md={13} sm={13} xs={24}>
                                          <Row justify={"space-between"} className="align-start">
                                            <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                              <Row>
                                                <div className="container_label">
                                                  <div className="wrapper_label align-center mb-1">
                                                    <span className="cup_org_bg align-center">
                                                      <img src="assets/images/crown.svg" alt="" />
                                                      {objectivekeys.obj_code}
                                                    </span>
                                                    <span className="triangle"></span>
                                                  </div>
                                                </div>
                                                {/* </div> */}
                                                <div className="second_kr_label">
                                                  <div className="wrapper_label align-center mb-1">
                                                    <span className="triangle_left"></span>
                                                    <span className="cup_org_bg align-center">
                                                      <img src="assets/images/KRA-Icon.svg" alt="" />
                                                      {objective.keyid}
                                                    </span>
                                                    <span className="triangle"></span>
                                                  </div>
                                                </div>
                                              </Row>
                                              <div className="">
                                                {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue}  getteamkeyresult={()=>getteamkeyresult(objectivekeys.obj_id,localStorage.username)} objectivelist={objectivelist} krid={objective.id} krtitle={objective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid} checkInFrequency={objective.checkInFrequency} uomType={objective.uom} objquarter = {objectivekeys.obj_quarter} tabActiveKey="1" />) : (<p className="c-000 fs-16 c_32B7D9">{objective.keytitle}</p>)}
                                              </div>

                                              <div className="fs-14 c_8080  threeline_text_overflow">
                                                {objective.description}
                                              </div>
                                            </Col>
                                            <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                              <Row className=" m-0 p-0 justify-end align-center">
                                                {objectivekeys.revertstatus !== 1 ? (

                                                  objective.status == 2 && getAllLeadMeasure.filter(data => data.krId === objective.id).length == 0 ?
                                                    (<>
                                                      <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                      </button>
                                                    </>) :
                                                    getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ?
                                                      (<>
                                                        <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                          <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                        </button>
                                                      </>
                                                      ) :
                                                      (<>
                                                        <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                          <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                        </button>
                                                      </>
                                                      )


                                                ) : (
                                                  <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                    <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                  </button>
                                                )}
                                                {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                  <a onClick={(e) => setKrValues(e, objective)} href="#hgj"
                                                  >
                                                    {localStorage.loginId == objective.createdBy && userSignOff.signOffStatus === 0
                                                      // || (objective.keyResultUsersListAssignedTo.map((krSignOff) => krSignOff.keyid === objective.id && krSignOff.signOffStatus === 0) && userSignOff.signOffStatus === 0) 
                                                      &&
                                                      <Space>
                                                        <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                        <input type="hidden" />
                                                      </Space>
                                                    }
                                                  </a>
                                                </Dropdown>) : (<></>)}
                                              </Row>
                                              <Row className="obj-progress mt-2">
                                                {/* <Progress className="" percent={100} /> */}
                                                <Col xl={22} lg={22} md={22} sm={22}>
                                                  <div className="custom-progress-bar">
                                                    <div className="progress-content">
                                                      <Progress
                                                        type="line"
                                                        percent={percent}
                                                        strokeColor="#00CFFF"
                                                        trailColor="#E0E0E0"
                                                        showInfo={false}
                                                      // strokeWidth={10}
                                                      />
                                                      <div className="progress-dots">
                                                        <Tooltip title="Start value: 0%" placement="bottom">
                                                          <span className="dot" style={{ left: '0%' }}></span>
                                                        </Tooltip>
                                                        {percent > 0 && percent < 100 && (
                                                          <Tooltip title={`Current value: ${percent}%`} placement="bottom">
                                                            <span className="dot" style={{ left: `calc(${percent}% - 4px)` }}></span>
                                                          </Tooltip>
                                                        )}
                                                        <Tooltip title="End value: 100%" placement="bottom">
                                                          <span className="dot" style={{ left: '100%' }}></span>
                                                        </Tooltip>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2}>
                                                  <span className="progress-percentage fs-16">{percent}%</span>
                                                </Col>

                                              </Row>
                                              <Row className="d-flex align-center justify-end mt-5">
                                                <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                  <a className="ant-dropdown-link" onClick={() => userKeyResultList(objective.keyResultUsersListAssignedTo, objective.id, objectivetype)}>
                                                    <Space>
                                                      <div className="align-center">
                                                        <img src="assets/images/membercount.svg" alt="" />
                                                        <span className="fw-700 c-000 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}<span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                      </div>
                                                    </Space>
                                                  </a>
                                                </Dropdown>
                                                {/* <img src="assets/images/membercount.svg" alt="" />
                                      <span className="fw-700 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}
                                      <span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span> */}
                                              </Row>
                                            </Col>

                                            {/* <button className="completed_btn  align-center border-0 p-1 mr-1">
                                      <span className="sm_com_cricle  mr-1 "></span>{"Completed"}
                                    </button> */}
                                            {/* </Row> */}
                                            {/* <Row className="align-end"> */}
                                            {/* <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                                        <Row>
                                          <Col
                                            xl={10}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-3"
                                          >
                                            <span style={{ marginRight: "3px" }}>Created by</span>
                                            <Tooltip placement="top" title={objective.createdbyempname} arrow={true}>
                                              <img
                                                src={objective.createdByEmpProfilePath ? FileUrl + objective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                                onError={handleImageError}
                                                // src="assets/images/user.svg"
                                                className="kr_user"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </Col>
                                          <Col
                                            xl={14}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-3"
                                          >
                                            <span style={{ marginRight: "3px" }}>Cascaded to vn</span>
                                            {objective.keyResultUsersListAssignedTo.length > 2 ? (
                                              <>
                                                <Avatar.Group
                                                  maxCount={2}
                                                  maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                                >
                                                  {objective.keyResultUsersListAssignedTo.slice(0, objective.keyResultUsersListAssignedTo.length).map((user: keyResultUsers, index) => (
                                                    <>
                                                      <Tooltip placement="top" title={user.username} arrow={true}>
                                                        <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} onError={handleImageError} />
                                                      </Tooltip>
                                                    </>
                                                  ))}
                                                </Avatar.Group>
                                              </>
                                            ) : (
                                              objective.keyResultUsersListAssignedTo.map((user: keyResultUsers, index) => (
                                                <Tooltip placement="top" title={user.username} arrow={true}>
                                                  <img
                                                    src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                                    className="avatar"
                                                    key={index}
                                                    onError={handleImageError}
                                                  />
                                                </Tooltip>
                                              ))
                                            )
                                            }
                                          </Col>
                                        </Row>
                                      </Col> */}
                                            {/* <Col xl={11} lg={11} md={11} sm={24} xs={24}>
                                        <Row className="justify-between">
                                          <Col
                                            xl={12}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-2"
                                          >
                                            <img src="assets/images/crown.svg" alt="" />
                                            <span className="ml-1">{objective.objectivecode}</span>
                                          </Col>
                                          <Col
                                            xl={12}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-2"
                                          >
                                            <img src="assets/images/date-picker1.svg" alt="" />
                                            <span className="ml-1">{objective.quarter} {objectivekeys.year}</span>
                                          </Col>
                                          {/* <Col
                                          xl={12}
                                          lg={24}
                                          md={24}
                                          sm={24}
                                          xs={24}
                                          className="align-center mt-2"
                                        >
                                          <img src="assets/images/message-chat.svg" alt="" />
                                          <span className="ml-1">Discussion</span>
                                        </Col> */}

                                            {/* </Row> */}

                                            {/* </Col> */}
                                          </Row>
                                        </Col>
                                        <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col>
                                        <Col
                                          xl={8}
                                          lg={8}
                                          md={10}
                                          sm={10}
                                          xs={24}
                                          className="obj-pagination pl-2"
                                        >
                                          {permission_lmview ? (<><Row className="justify-between">

                                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                              <p className="fw-600 m-0">Lead Measures</p>
                                            </Col>


                                            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2 justify-end">

                                              <div>
                                                {getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ? (
                                                  objectivekeys.revertstatus != 1 && objective.status !== 1 && (
                                                    <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(objective.id); setKeyResult(objective) }}>
                                                      View All <ArrowRightOutlined />
                                                    </span>)
                                                ) : (
                                                  objectivekeys.revertstatus != 1 && objective.status !== 1 && permission_lmcreate ? (

                                                    <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(objective.id); setKeyResult(objective) }}>
                                                      + Add Lead Measure
                                                    </span>
                                                  ) : (<></>)

                                                )}
                                                <Drawer title={"Lead Measures"} size="large" onClose={onLeadClose} open={leadOpen} className="objective-canvas position-relative">
                                                  <div className="lead_measure_form">
                                                    <Form>
                                                      <div className="mb-4">
                                                        <label htmlFor="" className="fw-600 form-contol-border">Key result Name</label> <br /><br />
                                                        <span className="fs-16 fw-300 mt-3">{selectedKeyResult.keytitle}</span>
                                                        {/* <span className="fs-16 fw-300 mt-3">{objective.keytitle}</span> */}
                                                      </div>
                                                      {/* {objectivekeys.revertstatus != 1 && objective.status !== 1 ? (*/}
                                                      <div className="mb-4">
                                                        <span className={show ? "add_lead_measure" : "lead_measure_visible"} onClick={handleAddLead}>+  Add Lead Measure</span> <br />
                                                      </div>
                                                      {/* ) : (<></>)} */}

                                                      {((getAllLeadMeasure.filter(data => data.krId === objective.id).length === 0 && show) || show) &&
                                                        (
                                                          <div>
                                                            <div className="mb-4">
                                                              <div className="d-flex justify-between">
                                                                <label htmlFor="" className="fw-600 form-contol-border">Lead Measure (Optional)</label> <br />
                                                                <span className="c-0050 fs-12 mt-1">
                                                                  {leadMeasure.description.trim().split(/\s+/).join("").length} /{" "}
                                                                  {maxWords.name_max_word_count}</span>
                                                              </div>
                                                              <div className="objective-title-inner">
                                                                <TextArea
                                                                  id="description"
                                                                  rows={3}
                                                                  placeholder="Write here...."
                                                                  className="canvas-objective-input"
                                                                  name="description"
                                                                  value={leadMeasure.description}
                                                                  // onChange={handleInputChange}
                                                                  onChange={(e) =>
                                                                    handleInputChange(
                                                                      e,
                                                                      setLeadMeasure,
                                                                      maxWords.name_max_word_count
                                                                    )
                                                                  }
                                                                />

                                                                {/* <input type="hidden" id="orgObjId" name="orgObjId" value={objective.orgobjectiveid} />
                                                        <input type="hidden" id="krId" name="krId" value={objective.id} />
                                                        <input type="hidden" id="objId" name="objId" value={objective.objectiveid} /> */}

                                                                <input type="hidden" id="orgObjId" name="orgObjId" value={selectedKeyResult.orgobjectiveid} />
                                                                <input type="hidden" id="krId" name="krId" value={selectedKeyResult.id} />
                                                                <input type="hidden" id="objId" name="objId" value={selectedKeyResult.objectiveid} />


                                                              </div>
                                                            </div>
                                                            <div className="d-flex justify-end mb-3">
                                                              <button className="cancel-btn mx-3" onClick={() => setShow(!show)}>Cancel</button>
                                                              <button
                                                                onClick={addLeadMeasure}
                                                                disabled={leadMeasure.description == "" ? true : false}
                                                                className={leadMeasure.description == "" ?
                                                                  "cascade_button" : "cyan_button"} >Submit</button>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </Form>
                                                  </div>
                                                  {/* {contentData.filter(data => data.assignedId === selectedAssignedId).map((data, index) => ( */}
                                                  <div key={index}>
                                                    {getAllLeadMeasure.filter(d => d.krId === selectedKrId).length > 0 ? (
                                                      <div>
                                                        <CustomPagination
                                                          current={currentPagePerObjective[selectedKrId ?? ""] || 1}
                                                          total={getAllLeadMeasure.filter(d => d.krId === selectedKrId).length}
                                                          onChange={(page: number) => handlePageChange(page, selectedKrId ?? "")}
                                                          leadMeasure={getAllLeadMeasure.filter(d => d.krId === selectedKrId)}
                                                          krId={selectedKrId ?? ""}
                                                          createdByName={objective.createdbyempname}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="no-data my-3">
                                                        <Row>
                                                          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">
                                                            <div className="text-center c_AAA fs-14 lead_measure text-center">No Lead Measures created yet.</div>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    )}
                                                  </div>
                                                  {/* ))} */}

                                                </Drawer>



                                              </div >

                                            </Col>


                                            {/* <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2"> */}
                                            {/* <span
            className="c_32B7D9 fs-12 cur-pointer float-right"
            >
            View All<ArrowRightOutlined />
            </span> */}
                                            {/* <p>org obj id :  {objective.orgobjectiveid}</p> */}
                                            {/* <LeadMessure  objid={objective.objectiveid} keyName={objective.keytitle} orgobjectiveid={objective.orgobjectiveid} krId={objective.id}/> */}
                                            {/* </Col> */}
                                          </Row>
                                            {getAllLeadMeasure.filter((data: any) => data.krId === objective.id).length > 0 ? (
                                              <div className="lead_measure">
                                                <div className="d-flex image-pagination">
                                                  <ImagePagination
                                                    current={currentPagePerObjective[objective.id] || 1}
                                                    total={getAllLeadMeasure.filter(data => data.krId === objective.id).length}
                                                    onChange={(page: number) => handlePageChange(page, objective.id)}
                                                    krId={objective.id}
                                                    leadMeasure={getAllLeadMeasure}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <Row>
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                  <Card className="text-center c_AAA fs-14 lead_measure">No Lead Measures created yet.</Card>
                                                </Col>
                                              </Row>
                                            )
                                            }</>) : (<><p>Need Permission to view</p></>)}

                                        </Col>
                                      </Row>
                                    )
                                     ))}
                                  </>
                                )}
                                  {selectedKrValue === "Team Key Results" && (<>
                                    {objectivesteamdkr.map((teamobjective, index) => (
                                      teamobjective.status != 3 && (
                                        <Row className="kr_card p-2 mt-1 mb-2 mr-1 ">
                                          <Col xl={15} lg={15} md={13} sm={13} xs={24} className="">
                                            <Row justify={"space-between"} className="align-start">
                                              <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                                <Row>
                                                  <div className="container_label">
                                                    <div className="wrapper_label align-center mb-1">
                                                      <span className="cup_org_bg align-center">
                                                        <img src="assets/images/crown.svg" alt="" />
                                                        {objectivekeys.obj_code}
                                                      </span>
                                                      <span className="triangle"></span>
                                                    </div>
                                                  </div>
                                                  <div className="second_kr_label">
                                                    <div className="wrapper_label align-center mb-1">
                                                      <span className="triangle_left"></span>
                                                      <span className="cup_org_bg align-center">
                                                        <img src="assets/images/KRA-Icon.svg" alt="" />
                                                        {teamobjective.keyid}
                                                      </span>
                                                      <span className="triangle"></span>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <div className="">
                                                  {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => handleKrChange(selectedKrValue,objectivekeys.obj_id,localStorage.loginId)} selectedkrvalue={selectedKrValue}  getteamkeyresult={()=>getteamkeyresult(objectivekeys.obj_id,localStorage.username)} objectivelist={objectivelist} krid={teamobjective.id} krtitle={teamobjective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={teamobjective.keyid} checkInFrequency={teamobjective.checkInFrequency} uomType={teamobjective.uom}  objquarter = {objectivekeys.obj_quarter} tabActiveKey="1" />) : (<p className="c-000 fs-16 c_32B7D9">{teamobjective.keytitle}</p>)}
                                                </div>
                                                <div className="fs-14 threeline_text_overflow">
                                                  {teamobjective.description}
                                                </div>
                                              </Col>
                                              <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                                <Row className=" m-0 p-0 justify-end align-center">
                                                  {objectivekeys.revertstatus !== 1 ? (

                                                    teamobjective.status == 2 && getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length == 0 ?
                                                      (<>
                                                        <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                          <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                        </button>
                                                      </>) :
                                                      getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length > 0 ?
                                                        (<>
                                                          <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                            <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                          </button>
                                                        </>
                                                        ) :
                                                        (<>
                                                          <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                            <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                          </button>
                                                        </>
                                                        )
                                                  ) : (
                                                    <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                      <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                    </button>
                                                  )}
                                                  {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                    <a onClick={(e) => setKrValues(e, teamobjective)} href="#hgj"
                                                    >
                                                      {localStorage.loginId == teamobjective.createdBy && userSignOff.signOffStatus === 0
                                                        // || (teamobjective.keyResultUsersListAssignedTo.some((krsignOff) => krsignOff.keyid === teamobjective.id && krsignOff.signOffStatus === 0)) ||
                                                        // (userSignOff.signOffStatus === 0) 
                                                        &&
                                                        <Space>
                                                          <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                          <input type="hidden" />
                                                        </Space>
                                                      }
                                                    </a>
                                                  </Dropdown>) : (<></>)}
                                                </Row>
                                                <Row className="obj-progress mt-2">
                                                  {/* <Progress className="" percent={0} /> */}
                                                  <Col xl={22} lg={22} md={22} sm={22}>
                                                    <div className="custom-progress-bar">
                                                      <div className="progress-content">
                                                        <Progress
                                                          type="line"
                                                          percent={percent}
                                                          strokeColor="#00CFFF"
                                                          trailColor="#E0E0E0"
                                                          showInfo={false}
                                                        // strokeWidth={10}
                                                        />
                                                        <div className="progress-dots">
                                                          <Tooltip title="Start value: 0%" placement="bottom">
                                                            <span className="dot" style={{ left: '0%' }}></span>
                                                          </Tooltip>
                                                          {percent > 0 && percent < 100 && (
                                                            <Tooltip title={`Current value: ${percent}%`} placement="bottom">
                                                              <span className="dot" style={{ left: `calc(${percent}% - 4px)` }}></span>
                                                            </Tooltip>
                                                          )}
                                                          <Tooltip title="End value: 100%" placement="bottom">
                                                            <span className="dot" style={{ left: '100%' }}></span>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <Col xl={2} lg={2} md={2} sm={2}>
                                                    <span className="progress-percentage fs-16">{percent}%</span>
                                                  </Col>

                                                </Row>
                                                <Row className="d-flex align-center justify-end mt-5">
                                                  <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                    <a className="ant-dropdown-link" onClick={() => userKeyResultList(teamobjective.keyResultUsersListAssignedTo, teamobjective.id, objectivetype)}>
                                                      <Space>
                                                        <div className="align-center">
                                                          <img src="assets/images/membercount.svg" alt="" />
                                                          <span className="fw-700 c-000 fs-14 ml-1">{teamobjective.keyResultUsersListAssignedTo.length}<span className="ml-1">{teamobjective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                        </div>
                                                      </Space>
                                                    </a>
                                                  </Dropdown>
                                                  {/* <img src="assets/images/membercount.svg" alt="" />
                                                  <span className="fw-700 fs-14 ml-1">{teamobjective.keyResultUsersListAssignedTo.length}<span className="ml-1">{teamobjective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span> */}
                                                </Row>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col>
                                          <Col
                                            xl={8}
                                            lg={8}
                                            md={10}
                                            sm={10}
                                            xs={24}
                                            className="obj-pagination pl-2"
                                          >
                                            {permission_lmview ? (<><Row className="justify-between">
                                              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                                <p className="fw-600 m-0">Lead Measures</p>
                                              </Col>
                                              <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2 justify-end">
                                                <div>
                                                  {getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length > 0 ? (
                                                    objectivekeys.revertstatus != 1 && teamobjective.status !== 1 && (
                                                      <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(teamobjective.id); setKeyResult(teamobjective) }}>
                                                        View All <ArrowRightOutlined />
                                                      </span>)
                                                  ) : (
                                                    objectivekeys.revertstatus != 1 && teamobjective.status !== 1 && permission_lmcreate ? (

                                                      <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(teamobjective.id); setKeyResult(teamobjective) }}>
                                                        + Add Lead Measure
                                                      </span>
                                                    ) : (<></>)
                                                  )}
                                                  <Drawer title={"Lead Measures"} size="large" onClose={onLeadClose} open={leadOpen} className="objective-canvas position-relative">
                                                    <div className="lead_measure_form">
                                                      <Form>
                                                        <div className="mb-4">
                                                          <label htmlFor="" className="fw-600 form-contol-border">Key result Name</label> <br /><br />
                                                          <span className="fs-16 fw-300 mt-3">{selectedKeyResult.keytitle}</span>
                                                        </div>
                                                        <div className="mb-4">
                                                          <span className={show ? "add_lead_measure" : "lead_measure_visible"} onClick={handleAddLead}>+  Add Lead Measure</span> <br />
                                                        </div>
                                                        {((getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length === 0 && show) || show) &&
                                                          (
                                                            <div>
                                                              <div className="mb-4">
                                                                <div className="d-flex justify-between">
                                                                  <label htmlFor="" className="fw-600 form-contol-border">Lead Measure (Optional)</label> <br />
                                                                  <span className="c-0050 fs-12 mt-1">
                                                                    {leadMeasure.description.trim().split(/\s+/).join("").length} /{" "}
                                                                    {maxWords.name_max_word_count}</span>
                                                                </div>
                                                                <div className="objective-title-inner">
                                                                  <TextArea
                                                                    id="description"
                                                                    rows={3}
                                                                    placeholder="Write here...."
                                                                    className="canvas-objective-input"
                                                                    name="description"
                                                                    value={leadMeasure.description}
                                                                    onChange={(e) =>
                                                                      handleInputChange(
                                                                        e,
                                                                        setLeadMeasure,
                                                                        maxWords.name_max_word_count
                                                                      )
                                                                    }
                                                                  />
                                                                  <input type="hidden" id="orgObjId" name="orgObjId" value={selectedKeyResult.orgobjectiveid} />
                                                                  <input type="hidden" id="krId" name="krId" value={selectedKeyResult.id} />
                                                                  <input type="hidden" id="objId" name="objId" value={selectedKeyResult.objectiveid} />
                                                                </div>
                                                              </div>
                                                              <div className="d-flex justify-end mb-3">
                                                                <button className="cancel-btn mx-3" onClick={() => setShow(!show)}>Cancel</button>
                                                                <button
                                                                  onClick={addLeadMeasure}
                                                                  disabled={leadMeasure.description == "" ? true : false}
                                                                  className={leadMeasure.description == "" ?
                                                                    "cascade_button" : "cyan_button"} >Submit</button>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </Form>
                                                    </div>
                                                    <div key={index}>
                                                      {getAllLeadMeasure.filter(d => d.krId === selectedKrId).length > 0 ? (
                                                        <div>
                                                          <CustomPagination
                                                            current={currentPagePerObjective[selectedKrId ?? ""] || 1}
                                                            total={getAllLeadMeasure.filter(d => d.krId === selectedKrId).length}
                                                            onChange={(page: number) => handlePageChange(page, selectedKrId ?? "")}
                                                            leadMeasure={getAllLeadMeasure.filter(d => d.krId === selectedKrId)}
                                                            krId={selectedKrId ?? ""}
                                                            createdByName={teamobjective.createdbyempname}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="no-data my-3">
                                                          <Row>
                                                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">
                                                              <div className="text-center c_AAA fs-14 lead_measure text-center">No Lead Measures created yet.</div>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Drawer>
                                                </div >
                                              </Col>
                                            </Row>
                                              {getAllLeadMeasure.filter((data: any) => data.krId === teamobjective.id).length > 0 ? (
                                                <div className="lead_measure">
                                                  <div className="d-flex image-pagination">
                                                    <ImagePagination
                                                      current={currentPagePerObjective[teamobjective.id] || 1}
                                                      total={getAllLeadMeasure.filter(data => data.krId === teamobjective.id).length}
                                                      onChange={(page: number) => handlePageChange(page, teamobjective.id)}
                                                      krId={teamobjective.id}
                                                      leadMeasure={getAllLeadMeasure}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <Row>
                                                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    <Card className="text-center c_AAA fs-14 lead_measure">No Lead Measures created yet.</Card>
                                                  </Col>
                                                </Row>
                                              )
                                              }</>) : (<><p>Need Permission to view</p></>)}

                                          </Col>
                                        </Row>
                                      )
                                    ))}
                                  </>)}
                                  {selectedKrValue === "My Key Results" && (
                                    <>
                                     {objectivesassignedkr.map((objective, index) => (
                                    objective.status != 3 && (
                                      <Row className="kr_card p-2 mt-1 mb-2 mr-1 ">
                                        <Col xl={15} lg={15} md={13} sm={13} xs={24}>
                                          <Row justify={"space-between"} className="align-start">
                                            <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                                              <Row>
                                                <div className="container_label">
                                                  <div className="wrapper_label align-center mb-1">
                                                    <span className="cup_org_bg align-center">
                                                      <img src="assets/images/crown.svg" alt="" />
                                                      {objectivekeys.obj_code}
                                                    </span>
                                                    <span className="triangle"></span>
                                                  </div>
                                                </div>
                                                {/* </div> */}
                                                <div className="second_kr_label">
                                                  <div className="wrapper_label align-center mb-1">
                                                    <span className="triangle_left"></span>
                                                    <span className="cup_org_bg align-center">
                                                      <img src="assets/images/KRA-Icon.svg" alt="" />
                                                      {objective.keyid}
                                                    </span>
                                                    <span className="triangle"></span>
                                                  </div>
                                                </div>
                                              </Row>
                                              <div className="">
                                                {permission_krview ? (<KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={()=>getassignedkeyforobjectives(objectivekeys.obj_id,localStorage.loginid)} getteamkeyresult={()=>getteamkeyresult(objectivekeys.obj_id,localStorage.username)} objectivelist={objectivelist} krid={objective.id} krtitle={objective.keytitle} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode={objective.keyid} checkInFrequency={objective.checkInFrequency}  uomType={objective.uom} objquarter = {objectivekeys.obj_quarter}  selectedkrvalue={selectedKrValue} tabActiveKey="1" />) : (<p className="c-000 fs-16 c_32B7D9">{objective.keytitle}</p>)}
                                              </div>

                                              <div className="fs-14 c_8080  threeline_text_overflow">
                                                {objective.description}
                                              </div>
                                            </Col>
                                            <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                                              <Row className=" m-0 p-0 justify-end align-center">
                                                {objectivekeys.revertstatus !== 1 ? (

                                                  objective.status == 2 && getAllLeadMeasure.filter(data => data.krId === objective.id).length == 0 ?
                                                    (<>
                                                      <button className="Progressing_btn align-center border-0 p-1 mr-1">
                                                        <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                                                      </button>
                                                    </>) :
                                                    getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ?
                                                      (<>
                                                        <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                          <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                                        </button>
                                                      </>
                                                      ) :
                                                      (<>
                                                        <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                                          <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                                        </button>
                                                      </>
                                                      )


                                                ) : (
                                                  <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                                    <span className="sm_pro_cricle  mr-1 "></span>Revoked
                                                  </button>
                                                )}
                                                {permission_kredit || permission_krdelete ? (<Dropdown overlay={menu} trigger={['click']}>
                                                  <a onClick={(e) => setKrValues(e, objective)} href="#hgj"
                                                  >
                                                    {localStorage.loginId == objective.createdBy && userSignOff.signOffStatus === 0
                                                      // || (objective.keyResultUsersListAssignedTo.map((krSignOff) => krSignOff.keyid === objective.id && krSignOff.signOffStatus === 0) && userSignOff.signOffStatus === 0) 
                                                      &&
                                                      <Space>
                                                        <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                        <input type="hidden" />
                                                      </Space>
                                                    }
                                                  </a>
                                                </Dropdown>) : (<></>)}
                                              </Row>
                                              <Row className="obj-progress mt-2">
                                                {/* <Progress className="" percent={100} /> */}
                                                <Col xl={22} lg={22} md={22} sm={22}>
                                                  <div className="custom-progress-bar">
                                                    <div className="progress-content">
                                                      <Progress
                                                        type="line"
                                                        percent={percent}
                                                        strokeColor="#00CFFF"
                                                        trailColor="#E0E0E0"
                                                        showInfo={false}
                                                      // strokeWidth={10}
                                                      />
                                                      <div className="progress-dots">
                                                        <Tooltip title="Start value: 0%" placement="bottom">
                                                          <span className="dot" style={{ left: '0%' }}></span>
                                                        </Tooltip>
                                                        {percent > 0 && percent < 100 && (
                                                          <Tooltip title={`Current value: ${percent}%`} placement="bottom">
                                                            <span className="dot" style={{ left: `calc(${percent}% - 4px)` }}></span>
                                                          </Tooltip>
                                                        )}
                                                        <Tooltip title="End value: 100%" placement="bottom">
                                                          <span className="dot" style={{ left: '100%' }}></span>
                                                        </Tooltip>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2}>
                                                  <span className="progress-percentage fs-16">{percent}%</span>
                                                </Col>

                                              </Row>
                                              <Row className="d-flex align-center justify-end mt-5">
                                                <Dropdown overlay={memberMenuKR} trigger={['click']}>
                                                  <a className="ant-dropdown-link" onClick={() => userKeyResultList(objective.keyResultUsersListAssignedTo, objective.id, objectivetype)}>
                                                    <Space>
                                                      <div className="align-center">
                                                        <img src="assets/images/membercount.svg" alt="" />
                                                        <span className="fw-700 c-000 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}<span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span>
                                                      </div>
                                                    </Space>
                                                  </a>
                                                </Dropdown>
                                                {/* <img src="assets/images/membercount.svg" alt="" />
                                      <span className="fw-700 fs-14 ml-1">{objective.keyResultUsersListAssignedTo.length}
                                      <span className="ml-1">{objective.keyResultUsersListAssignedTo.length > 1 ? "Members" : "Member"}</span> </span> */}
                                              </Row>
                                            </Col>

                                            {/* <button className="completed_btn  align-center border-0 p-1 mr-1">
                                      <span className="sm_com_cricle  mr-1 "></span>{"Completed"}
                                    </button> */}
                                            {/* </Row> */}
                                            {/* <Row className="align-end"> */}
                                            {/* <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                                        <Row>
                                          <Col
                                            xl={10}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-3"
                                          >
                                            <span style={{ marginRight: "3px" }}>Created by</span>
                                            <Tooltip placement="top" title={objective.createdbyempname} arrow={true}>
                                              <img
                                                src={objective.createdByEmpProfilePath ? FileUrl + objective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                                onError={handleImageError}
                                                // src="assets/images/user.svg"
                                                className="kr_user"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </Col>
                                          <Col
                                            xl={14}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-3"
                                          >
                                            <span style={{ marginRight: "3px" }}>Cascaded to vn</span>
                                            {objective.keyResultUsersListAssignedTo.length > 2 ? (
                                              <>
                                                <Avatar.Group
                                                  maxCount={2}
                                                  maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                                >
                                                  {objective.keyResultUsersListAssignedTo.slice(0, objective.keyResultUsersListAssignedTo.length).map((user: keyResultUsers, index) => (
                                                    <>
                                                      <Tooltip placement="top" title={user.username} arrow={true}>
                                                        <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} onError={handleImageError} />
                                                      </Tooltip>
                                                    </>
                                                  ))}
                                                </Avatar.Group>
                                              </>
                                            ) : (
                                              objective.keyResultUsersListAssignedTo.map((user: keyResultUsers, index) => (
                                                <Tooltip placement="top" title={user.username} arrow={true}>
                                                  <img
                                                    src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                                    className="avatar"
                                                    key={index}
                                                    onError={handleImageError}
                                                  />
                                                </Tooltip>
                                              ))
                                            )
                                            }
                                          </Col>
                                        </Row>
                                      </Col> */}
                                            {/* <Col xl={11} lg={11} md={11} sm={24} xs={24}>
                                        <Row className="justify-between">
                                          <Col
                                            xl={12}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-2"
                                          >
                                            <img src="assets/images/crown.svg" alt="" />
                                            <span className="ml-1">{objective.objectivecode}</span>
                                          </Col>
                                          <Col
                                            xl={12}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="align-center mt-2"
                                          >
                                            <img src="assets/images/date-picker1.svg" alt="" />
                                            <span className="ml-1">{objective.quarter} {objectivekeys.year}</span>
                                          </Col>
                                          {/* <Col
                                          xl={12}
                                          lg={24}
                                          md={24}
                                          sm={24}
                                          xs={24}
                                          className="align-center mt-2"
                                        >
                                          <img src="assets/images/message-chat.svg" alt="" />
                                          <span className="ml-1">Discussion</span>
                                        </Col> */}

                                            {/* </Row> */}

                                            {/* </Col> */}
                                          </Row>
                                        </Col>
                                        <Col xl={1} lg={1} md={1} sm={1} xs={24} className="br_double_line mt-3"></Col>
                                        <Col
                                          xl={8}
                                          lg={8}
                                          md={10}
                                          sm={10}
                                          xs={24}
                                          className="obj-pagination pl-2"
                                        >
                                          {permission_lmview ? (<><Row className="justify-between">

                                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                              <p className="fw-600 m-0">Lead Measures</p>
                                            </Col>


                                            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2 justify-end">

                                              <div>
                                                {getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ? (
                                                  objectivekeys.revertstatus != 1 && objective.status !== 1 && (
                                                    <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(objective.id); setKeyResult(objective) }}>
                                                      View All <ArrowRightOutlined />
                                                    </span>)
                                                ) : (
                                                  objectivekeys.revertstatus != 1 && objective.status !== 1 && permission_lmcreate ? (

                                                    <span className="c_32B7D9 fs-12 fw-600 cur-pointer " onClick={() => { leadshowDrawer(objective.id); setKeyResult(objective) }}>
                                                      + Add Lead Measure
                                                    </span>
                                                  ) : (<></>)

                                                )}
                                                <Drawer title={"Lead Measures"} size="large" onClose={onLeadClose} open={leadOpen} className="objective-canvas position-relative">
                                                  <div className="lead_measure_form">
                                                    <Form>
                                                      <div className="mb-4">
                                                        <label htmlFor="" className="fw-600 form-contol-border">Key result Name</label> <br /><br />
                                                        <span className="fs-16 fw-300 mt-3">{selectedKeyResult.keytitle}</span>
                                                        {/* <span className="fs-16 fw-300 mt-3">{objective.keytitle}</span> */}
                                                      </div>
                                                      {/* {objectivekeys.revertstatus != 1 && objective.status !== 1 ? (*/}
                                                      <div className="mb-4">
                                                        <span className={show ? "add_lead_measure" : "lead_measure_visible"} onClick={handleAddLead}>+  Add Lead Measure</span> <br />
                                                      </div>
                                                      {/* ) : (<></>)} */}

                                                      {((getAllLeadMeasure.filter(data => data.krId === objective.id).length === 0 && show) || show) &&
                                                        (
                                                          <div>
                                                            <div className="mb-4">
                                                              <div className="d-flex justify-between">
                                                                <label htmlFor="" className="fw-600 form-contol-border">Lead Measure (Optional)</label> <br />
                                                                <span className="c-0050 fs-12 mt-1">
                                                                  {leadMeasure.description.trim().split(/\s+/).join("").length} /{" "}
                                                                  {maxWords.name_max_word_count}</span>
                                                              </div>
                                                              <div className="objective-title-inner">
                                                                <TextArea
                                                                  id="description"
                                                                  rows={3}
                                                                  placeholder="Write here...."
                                                                  className="canvas-objective-input"
                                                                  name="description"
                                                                  value={leadMeasure.description}
                                                                  // onChange={handleInputChange}
                                                                  onChange={(e) =>
                                                                    handleInputChange(
                                                                      e,
                                                                      setLeadMeasure,
                                                                      maxWords.name_max_word_count
                                                                    )
                                                                  }
                                                                />

                                                                {/* <input type="hidden" id="orgObjId" name="orgObjId" value={objective.orgobjectiveid} />
                                                        <input type="hidden" id="krId" name="krId" value={objective.id} />
                                                        <input type="hidden" id="objId" name="objId" value={objective.objectiveid} /> */}

                                                                <input type="hidden" id="orgObjId" name="orgObjId" value={selectedKeyResult.orgobjectiveid} />
                                                                <input type="hidden" id="krId" name="krId" value={selectedKeyResult.id} />
                                                                <input type="hidden" id="objId" name="objId" value={selectedKeyResult.objectiveid} />


                                                              </div>
                                                            </div>
                                                            <div className="d-flex justify-end mb-3">
                                                              <button className="cancel-btn mx-3" onClick={() => setShow(!show)}>Cancel</button>
                                                              <button
                                                                onClick={addLeadMeasure}
                                                                disabled={leadMeasure.description == "" ? true : false}
                                                                className={leadMeasure.description == "" ?
                                                                  "cascade_button" : "cyan_button"} >Submit</button>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </Form>
                                                  </div>
                                                  {/* {contentData.filter(data => data.assignedId === selectedAssignedId).map((data, index) => ( */}
                                                  <div key={index}>
                                                    {getAllLeadMeasure.filter(d => d.krId === selectedKrId).length > 0 ? (
                                                      <div>
                                                        <CustomPagination
                                                          current={currentPagePerObjective[selectedKrId ?? ""] || 1}
                                                          total={getAllLeadMeasure.filter(d => d.krId === selectedKrId).length}
                                                          onChange={(page: number) => handlePageChange(page, selectedKrId ?? "")}
                                                          leadMeasure={getAllLeadMeasure.filter(d => d.krId === selectedKrId)}
                                                          krId={selectedKrId ?? ""}
                                                          createdByName={objective.createdbyempname}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="no-data my-3">
                                                        <Row>
                                                          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">
                                                            <div className="text-center c_AAA fs-14 lead_measure text-center">No Lead Measures created yet.</div>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    )}
                                                  </div>
                                                  {/* ))} */}

                                                </Drawer>



                                              </div >

                                            </Col>


                                            {/* <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2"> */}
                                            {/* <span
            className="c_32B7D9 fs-12 cur-pointer float-right"
            >
            View All<ArrowRightOutlined />
            </span> */}
                                            {/* <p>org obj id :  {objective.orgobjectiveid}</p> */}
                                            {/* <LeadMessure  objid={objective.objectiveid} keyName={objective.keytitle} orgobjectiveid={objective.orgobjectiveid} krId={objective.id}/> */}
                                            {/* </Col> */}
                                          </Row>
                                            {getAllLeadMeasure.filter((data: any) => data.krId === objective.id).length > 0 ? (
                                              <div className="lead_measure">
                                                <div className="d-flex image-pagination">
                                                  <ImagePagination
                                                    current={currentPagePerObjective[objective.id] || 1}
                                                    total={getAllLeadMeasure.filter(data => data.krId === objective.id).length}
                                                    onChange={(page: number) => handlePageChange(page, objective.id)}
                                                    krId={objective.id}
                                                    leadMeasure={getAllLeadMeasure}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <Row>
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                  <Card className="text-center c_AAA fs-14 lead_measure">No Lead Measures created yet.</Card>
                                                </Col>
                                              </Row>
                                            )
                                            }</>) : (<><p>Need Permission to view</p></>)}

                                        </Col>
                                      </Row>
                                    )
                                     ))}
                                    </>
                                  )}
                                    
                                </div>
                              
                                </>
                              )}
                            {/* </TabPane> */}
                            {/* <TabPane tab={<>My Key {objectivesassignedkr.filter((data) => data.status != 3).length > 1 ? "Results" : "Result"} ({objectivesassignedkr.length}) </>} key="1"> */}
                              {/* {objectivesassignedkr.length === 0 ? (<>
                                <Card className="Aso_obj_nc_card mt-2 mb-3">
                                  <Row className="text-center obj_textbox ">
                                    <span className="c_AAA fs-14 fw-600">No Key Results Available.</span>
                                  </Row>
                                </Card>
                              </>) : (
                                <div className="keyresult_div">
                                 
                                </div>)} */}
                            {/* </TabPane> */}
                          {/* </Tabs> */}
                        </div>
                        {/* <KRDescription open={open} setOpen={setOpen} onClose ={onClose} krid={objective.id} objname={objective.objectivename}  objid={objective.objectiveid}/>  */}
                      </>
                    )}
                  </>
                )}

                <Modal
                  title=""
                  centered
                  open={showkrModal}
                  // onClose={false}
                  closeIcon={<CloseOutlined style={{ display: 'none' }} />} // Hide the close button
                  footer={null}
                  className="p-0 modal-close-out"
                >
                  <p id="modal-modal-title m-0" >
                    <button className="btn btn-close" onClick={() => handlekrClose()}>
                      <CloseOutlined />
                    </button>
                  </p>
                  <div className="krmodal_scroll">
                    {objectivesassignedkr.map((objective, index) => (
                      objective.status == 3 && (

                        <Row className="p-1 border_ddd mb-2 br_5">
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row>
                              <Col xl={18} lg={18} md={18} sm={18} xs={24}>
                                <Flex>
                                  <div className="container_label">
                                    <div className="wrapper_label align-center mb-1">
                                      <span className="cup_org_bg align-center">
                                        <img src="assets/images/KRA-Icon.svg" alt="" />
                                        {objective.keyid}
                                      </span>
                                      <span className="triangle"></span>
                                    </div>
                                  </div>
                                </Flex>
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={24}
                                className="align-center justify-end"
                              >
                                <img src="assets/images/crown.svg" alt="" />
                                <span className="ml-1">{objective.objectivecode}</span>
                              </Col>
                              {/* <Col
                            xl={8}
                            lg={8}
                            md={8}
                            sm={8}
                            xs={24}
                            className=" mt-0 justify-end"
                          >
                            {objectivekeys.revertstatus !== 1 ? (
                              objective.status == 2 && getAllLeadMeasure.filter(data => data.krId === objective.id).length == 0 ?
                                (<>
                                  <button className="cascade_btn align-center border-0 p-1 mr-1">
                                    <span className="sm_cricle mr-1 fs-14"></span>Cascaded
                                  </button>
                                </>) :
                                getAllLeadMeasure.filter(data => data.krId === objective.id).length > 0 ?
                                  (<>
                                    <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                      <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                    </button>
                                  </>
                                  ) :
                                  (<>
                                    <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                      <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                    </button>
                                  </>
                                  )
                            ) : (
                              <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                <span className="sm_pro_cricle  mr-1 "></span>Revoked
                              </button>
                            )}
                          </Col> */}
                            </Row>
                            <Row>
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <p className="fs-16 c-000 m-0 cur-pointer keytitle_scroll" onClick={() => { krshowDrawer(objective) }}>
                                  {objective.keytitle}
                                </p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={24}
                                    className="align-center mt-1 "
                                  >
                                    {/* <label>Deleted Date:</label>
                                <span className="danger">{objective.deleteddate}</span>

                                <label>Deleted Time:</label>
                                <span className="danger">{objective.deletedtime}</span>
 */}
                                    <span className="mr-1">Created by</span>
                                    <Tooltip placement="top" title={objective.createdbyempname} arrow={true}>
                                      <img
                                        src={objective.createdByEmpProfilePath ? FileUrl + objective.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                        onError={handleImageError}
                                        // src="assets/images/user.svg"
                                        className="kr_user"
                                        alt=""
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={24}
                                    className="align-center mt-1 justify-end"
                                  >
                                    <span className="mr-1">Cascaded to</span>
                                    {objective.keyResultUsersListAssignedTo.length > 2 ? (
                                      <>
                                        <Avatar.Group
                                          maxCount={2}
                                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                        >
                                          {objective.keyResultUsersListAssignedTo.slice(0, objective.keyResultUsersListAssignedTo.length).map((user: keyResultUsers, index) => (
                                            <>
                                              <Tooltip placement="top" title={user.username} arrow={true}>
                                                <Avatar src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} />
                                              </Tooltip>
                                            </>
                                          ))}
                                        </Avatar.Group>
                                      </>
                                    ) : (
                                      objective.keyResultUsersListAssignedTo.map((user: keyResultUsers, index) => (
                                        <Tooltip placement="top" title={user.username} arrow={true}>
                                          <Avatar
                                            src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                            className="avatar"
                                            key={index}
                                          />
                                        </Tooltip>
                                      ))
                                    )
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="align-center">
                              <Col
                                xl={7}
                                lg={7}
                                md={8}
                                sm={8}
                                xs={24}
                                className="align-center mt-1"
                              >
                                <img src="assets/images/date-picker1.svg" alt="" />
                                <span className="ml-1">{objective.quarter} {objectivekeys.year}</span>
                              </Col>
                              <Col
                                xl={10}
                                lg={10}
                                md={8}
                                sm={8}
                                xs={24} className="mt-1">
                                <DeleteOutlined className="c_FF4747 pr-1 fs-18" />
                                <span className="c_FF4747 mt-1">{objective.deleteddate}</span> &nbsp;&nbsp;&nbsp;
                                <span className="c_FF4747 mt-1">{objective.deletedtime}</span>
                              </Col>

                              <Col
                                xl={7}
                                lg={7}
                                md={8}
                                sm={8}
                                xs={24}
                                className="mt-1 justify-end"
                              >
                                {/* <label className="fs-14">KR Weightage: &nbsp;</label>
                        <span className="c_32B7D9 fs-14">{objective.keyresultweightage}%</span> */}
                              </Col>
                            </Row>

                          </Col>

                        </Row>

                      )
                    ))}
                  </div>
                </Modal>

              </div>
            </>

          ) : (<MyObjWeightage setReviewCascade={setReviewCascade} />)}
        </>) : !krreviewstatus ? (<>
          <ReviewALLKRWeightage setReviewKR={setkrreviewstatus} objid={objectivekeys.obj_id} />
        </>) :
          (<>
            <ReviewKRWeightage setReviewKR={setReviewKR} objid={objectivekeys.obj_id} />
          </>)}
    </>
  );
};

export default CFT
