import React, { useState, useEffect, ChangeEvent } from 'react'
import { Row, Table, Checkbox, Col, Spin, Input, message } from "antd";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const LogReport: React.FC = () => {

    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [loginReport, setLoginReport] = useState([]);
    const [fromDate, setFromDate] = useState<string>("2024-04-10");
    // const [toDate, setToDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>(new Date().toISOString().slice(0, 10));

    const dataSource = [
        {
            employeeID: 123,
            name: "John Doe",
            mailID: "john.doe@example.com",
            managerEmployeeId: "EMP456",
            managerName: "Manager A",
            managerEmail: "manager.a@example.com",
            todayLogin: 1,
            totalLogin: 50
        },
        {
            employeeID: 456,
            name: "Jane Smith",
            mailID: "jane.smith@example.com",
            managerEmployeeId: "EMP789",
            managerName: "Manager B",
            managerEmail: "manager.b@example.com",
            todayLogin: 0,
            totalLogin: 70
        }
        // Add more objects as needed
    ];


    const handleFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newFromDate = event.target.value;
        setFromDate(newFromDate);
        // console.log('From Date Changed: ', newFromDate);
        LoginReportData(newFromDate, toDate);
    };

    const handleToDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newToDate = event.target.value;
        setToDate(newToDate);
        // console.log('To Date Changed: ', newToDate);
        LoginReportData(fromDate, newToDate);
    };

    useEffect(() => {
        LoginReportData(fromDate, toDate);
    }, []);


    const LoginReportData = async (fromDate: String, toDate: String) => {

        try {

            const apiUrl = BaseUrl + `api/okr/getLoginReport/${fromDate}/${toDate}`;
            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    setLoginReport(response.data);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            message.error({
                content: (
                    <div className="success-message justify-center align-center p-1 d-flex">
                        Something Went Wrong
                    </div>
                ),
                duration: 5,
                icon: null,
            });
            // console.log("ERROR :::::::::" + error);
        }

    }

    const handleDownloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Log Report');
        const columns = logReportTable.map(column => column.title); // Assuming permissionLMColumns is an array of column objects
        const data: any[] = loginReport; // Assuming dataSource is an array of data objects
        worksheet.columns = columns.map(column => ({ header: column, key: column }));
        const keyName = logReportTable.map(column => column.key);
        data.forEach(item => {
            const rowData = keyName.map((column) => item[column]);
            worksheet.addRow(rowData);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'User Report.xlsx');
    };



    const logReportTable = [
        {
            title: "EmployeeID",
            dataIndex: "employeeId",
            key: "employeeId"
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Mail ID",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName"
        },
        {
            title: "Division Name",
            dataIndex: "divisionName",
            key: "divisionName"
        },
        {
            title: "Manager EmployeeId",
            dataIndex: "managerEmployeeId",
            key: "managerEmployeeId"
        },
        {
            title: "Manager Name",
            dataIndex: "managerName",
            key: "managerName"
        },
        {
            title: "Manager Email",
            dataIndex: "managerEmail",
            key: "managerEmail"
        },
        {
            title: "Today Login",
            dataIndex: "todayLoginCount",
            key: "todayLoginCount"
        },
        {
            title: "Total Login",
            dataIndex: "totalLoginCount",
            key: "totalLoginCount"
        },
        {
            title: "Today Active Hourse",
            dataIndex: "todayActiveHours",
            key: "todayActiveHours"
        },
        {
            title: "Total Active Hourse",
            dataIndex: "totalActiveHours",
            key: "totalActiveHours"
        }


    ]


    const filteredData = searchText ? loginReport.filter((record: any) =>
        Object.values(record).some((value) =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        )
    ) : loginReport;



    return (
        <div className='mt-2'>

            <div>
                <Row justify={"start"} className='mb-2'>
                    <Col xl={4} lg={6} md={6} sm={12} xs={24}>
                        <label htmlFor="fromDate">From Date:</label>
                        <Input
                            type="date"
                            id="fromDate"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={24} className='ml-2'>
                        <label htmlFor="toDate">To Date:</label>
                        <Input
                            type="date"
                            id="toDate"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </Col>
                </Row>
            </div>

            <div style={{ textAlign: 'right' }} className='m-2'>
                <Row justify={"end"} className='mb-2'>
                    <Col xl={4} lg={6} md={6} sm={12} xs={24}>
                        <input
                            type="search"
                            placeholder="Search Member"
                            className="w-100 table_search_inputbox pl-2"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <img src="assets/images/search.svg" className="table_search_img" alt=""></img>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={24}>
                        <button className='cyan_button' onClick={handleDownloadExcel}>Download to Excel</button>
                    </Col>
                </Row>
            </div>
            <Spin spinning={loading}>
                <Table
                    className=""
                    dataSource={filteredData}
                    scroll={{ x: true }}
                    columns={logReportTable}
                    pagination={{ pageSize: 15 }}
                />
            </Spin>

        </div>

    )
};

export default LogReport;