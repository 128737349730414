import React, { useEffect, useState } from "react";
import { Row, Col, Progress, Flex, Spin, Avatar, Tooltip, Card } from "antd";
import MemberProfile from "./MemberProfile";
import { BaseUrl } from "../../../App";
import axios from "axios";
import TeamProfile from "./TeamProfile";
import { FileUrl } from "../../../App";
import { number } from "zod";
interface MemObj {
  id: number,
  name: string,
  profileImagePath: string,
  position: string,
  team: string,
  reportImagePath: string,
  weightage: string,
}

interface MemberCardProps {
  memberObjData: Members[];
}

type Members = {
  name: string,
  employeeid: string,
  divisonName?: string,
  weightage?: number,
  id: string,
  // name: string,
  // profileImagePath: string,
  position?: string,
  team?: string,
  reportImagePath: string,
  // weightage: string,
  profilePath: string,
  username?: string,
  email?: string,
  supervisor_id?: string,
  rolename?: string,
  designation?: string,
}

const WorkWithMe: React.FC<MemberCardProps> = ({ memberObjData }) => {

  const [profileVisible, setProfileVisible] = React.useState(false);
  const [memberProfile, setMemberProfile] = React.useState(false);
  const [selectedMember, setSelectedMember] = useState<Members | null>(null);
  const [loading, setLoading] = useState(true);


  const [childObjectiveDetails, setChildObjectiveDetails] = useState<objectiveuser[]>()
  const [showMemDetails, setShowMemDetails] = useState<workWithObjective>({
    id: "",
    name: "",
    objectivename: "",
    quarter: "",
    description: "",
    orgobjectiveid: "",
    organizationStatus: "",
    createdBy: "",
    createdByEmpId: "",
    objdetails: [],
    objcount: 0,
    objectivesuser_list: [],
    // userprofile:[],
    createdbyusername: "",
    createdByProfilePath: "",
    revokestatus: null,
    mapped_obj: [],
    mapped_obj_count: "",
    year: ""
  });
  // const [Visibility, setvisibility] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);
  const [Visibility, setvisibility] = useState<boolean[]>([false]);
  // const toggleVisibilitys = (childObjective : objectiveuser[]) => {
  //   setChildObjectiveDetails(childObjective);
  //   setvisibility(!Visibility);
  // };
  const toggleVisibility = (index: number) => {
    setIsVisible((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }

  const toggleVisibilitys = (index: number) => {
    setvisibility((prevVisibility) =>
      prevVisibility.map((value, i) => (i === index ? !value : value))
    );
  }

  const handleMemcard = (member: Members) => {
    setSelectedMember(member);
    // alert(1);
    setMemberProfile(true);
    setProfileVisible(true);
    // handleMemcard(member);
    // setSelectedMember(member);
  }


  type workWithObjective = {
    id: string,
    name: string,
    objectivename: string,
    quarter: string,
    description: string,
    orgobjectiveid: string,
    organizationStatus: string,
    createdBy: string
    createdByEmpId: string,
    objdetails: objectiveuser[],
    objcount: number,
    objectivesuser_list: string[],
    // userprofile:string[],
    createdbyusername: string,
    revokestatus: number | null,
    createdByProfilePath: string,
    mapped_obj: objectiveuser[],
    mapped_obj_count: string,
    year: string
  }

  type objectiveuser = {
    // orgObjectiveId : string,
    objectivename: string,
    objsubid: string,
    organizationStatus: string,
    objectivesuser_list: string[],
    createdbyusername: string,
    createdByProfilePath: string,
    description: string,
    quarter: string
    revokestatus: number | null,

  }
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const [assignedObjectiveList, setAssignedObjectiveList] = useState<workWithObjective[]>([
    {
      id: "",
      name: "",
      objectivename: "",
      quarter: "",
      description: "",
      orgobjectiveid: "",
      organizationStatus: "",
      createdBy: "",
      // createdbyProfilePath:"",
      createdByEmpId: "",
      objdetails: [],
      objcount: 0,
      objectivesuser_list: [],
      // userprofile:[],
      createdbyusername: "",
      createdByProfilePath: "",
      revokestatus: null,
      mapped_obj: [],
      mapped_obj_count: "",
      year: ""
    }
  ]);

  const [assignedorgobjectivelist, setassignedorgobjectivelist] = useState<workWithObjective[]>([
    // {
    //   id: "",
    //   name: "",
    //   objectivename: "",
    //   quarter: "",
    //   description: "",
    //   orgobjectiveid: "",
    //   organizationStatus: "",
    //   createdBy: "",
    //   // createdbyProfilePath:"",
    //   createdByEmpId: "",
    //   objdetails: [],
    //   objcount: 0,
    //   objectivesuser_list: [],
    //   // userprofile:[],
    //   createdbyusername: "",
    //   createdByProfilePath: "",
    //   revokestatus: null,
    //   mapped_obj: [],
    //   mapped_obj_count: "",
    // }
  ]);

  const [childObjectiveIndex, setChildObjectiveIndex] = useState();
  const objectiveListView = async () => {
    try {
      const userid: Blob | null = localStorage.getItem("loginId") as Blob | null;

      // const apiUrl = BaseUrl + `api/objectives/list/objectivesusers/${localStorage.Quarter}/${localStorage.year}`;
      const apiUrl = `${BaseUrl}api/objectives/list/objectivesusers/${localStorage.getItem("Quarter")}/${localStorage.getItem("year")}`;

      // const id  = JSON.parse(userid);
      const formData = new FormData();
      if (userid) {
        formData.append("userid", userid);
      }

      await axios.get(apiUrl,
        {
          headers: {
            // "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }).then((response) => {
          response.data.map((test: workWithObjective) => Visibility.push(false));


          setAssignedObjectiveList(
            response.data.map((objectives: workWithObjective) => ({
              ...assignedObjectiveList,
              id: objectives.id,
              name: objectives.name,
              objectivename: objectives.objectivename,
              quarter: objectives.quarter,
              description: objectives.description,
              orgobjectiveid: objectives.orgobjectiveid,
              objCount: objectives.objcount,
              organizationStatus: objectives.organizationStatus,
              createdBy: objectives.createdBy,
              createdByEmpId: objectives.createdByEmpId,
              objectivesuser_list: objectives.objectivesuser_list,
              createdbyusername: objectives.createdbyusername,
              createdByProfilePath: objectives.createdByProfilePath,
              revokestatus: objectives.revokestatus,
              mapped_obj: objectives.objdetails,
              mapped_obj_count: objectives.objcount,
              objDetails: objectives.objdetails.map((values: objectiveuser) => ({
                objectivename: values.objectivename,
                objsubid: values.objsubid,
                organizationStatus: values.organizationStatus,
                description: values.description,
                objectivesuser_list: values.objectivesuser_list,
                quarter: values.quarter,
              }))
            }))
          )

          // ObjectiveUserMember(response.data)
          //  viewObjective.objectivesuser_list.length > 0 ? setChildObjectiveDetails(true) : setChildObjectiveDetails(false);
          // setLoading(false)
        }).catch((error) => {

        })

    } catch (error) {

    }
  }
  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
      objectiveListView();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const Orgobjectivelist = async () => {
    const token = localStorage.accessToken;
    //console.log("localstorages",localStorage.userid)
    // console.log("")
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;

    // Check if userObject is not null before accessing its properties
    if (userObject) {
      useruniqueid = localStorage.loginId;

      // Now you can use the useruniqueid variable as needed
      //console.log("User Unique ID:", useruniqueid);
    } else {
      // console.error("User information not found in localStorage");
    }

    // const formData = new FormData();
    // formData.append("userid", useruniqueid);
    // try {
    //   const response = await axios.post(
    //     BaseUrl + "api/organization/getassignedobjectivelist",
    //     formData
    //   );
    //const apiUrl = BaseUrl + `api/organization/bh/objectives/${roleId}`;
    try {
      const apiUrl = BaseUrl + `api/organization/getall`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        //      console.log(response.data);
        setassignedorgobjectivelist(
          response.data.data.map((objectives: any) => ({
            ...assignedorgobjectivelist,
            id: objectives.id,
            name: objectives.objectCode,
            objectivename: objectives.objectiveName,
            quarter: objectives.quarter,
            description: objectives.description,
            orgobjectiveid: objectives.orgobjectiveid,
            objCount: objectives.assignedCount,
            organizationStatus: objectives.organizationStatus,
            createdBy: objectives.createdBy,
            createdByEmpId: objectives.createdByEmpId,
            objectivesuser_list: objectives.orgObjectiveUsersList,
            createdbyusername: objectives.createdByUserName,
            createdByProfilePath: objectives.createdByProfilePath,
            revokestatus: objectives.revokestatus,
            mapped_obj: objectives.objDetails,
            mapped_obj_count: objectives.objcount,
            year: objectives.year
          }))
        )

        //  console.log("org sdfsdf ", response.data.data)
        //  setBhOrganizationList(response.data.data);
        setLoading(false)
      }).catch((error) => {

        console.log('Error :::::::::::::::::', error);

      })

    } catch (error) {

    }

    // setobjectiveuserlist(response.data.body); 

    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };



  const [objectiveMemberList, setObjectiveMemberList] = useState<Members[]>([]);
  //  console.log("one",assignedorgobjectivelist)
  const ObjectiveUserMember = async (viewObjective: workWithObjective, type: string) => {
    try {
      const apiUrl = BaseUrl + `api/objectives/member/list/${viewObjective.id}/${type}`;
      await axios.get(apiUrl,
        {
          headers: {
            // "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }).then((response) => {
          const responseData = response.data.data;
          if (Array.isArray(responseData)) {
            setObjectiveMemberList(response.data.data);
          } else {
            setObjectiveMemberList([]);
          }
        })
    } catch (error) {

    }
  }
  const [workWithMe, setWorkWithMe] = useState(false);
  const handleworkobj = (memberDetails: workWithObjective, type: string) => {
    setShowMemDetails(memberDetails);
    setWorkWithMe(true);
    ObjectiveUserMember(memberDetails, type);
  }

  const memberCardClick = (member: Members) => {
    setProfileVisible(true);
    // handleMemcard(member);
    setSelectedMember(member);

  }
  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  useEffect(() => {
    objectiveListView();
    Orgobjectivelist();
  }, []
  )

  return (
    <>
      {!memberProfile ? (
        <>
          <div className={loading ? "mt-5" : "m-0"}>
            {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
              <div className="content" />
            </Spin>}
            {assignedObjectiveList.length == 0 ? (
              <div>
                <Card className="Asp_nc_card" >
                  <Row className="text-center obj_textbox">
                    <span className="c_AAA fs-14 fw-600">No Data Available {assignedObjectiveList.length}</span>
                  </Row>
                </Card>
              </div>
            ) : (
              <Flex className="objective_overall">
                {assignedorgobjectivelist.map((viewObjective, index) => (
                  // <div className={`d-flex${Visibility ? 'add_border' : ''}`}  key={index} onClick={(event) => {ObjectiveUserMember(viewObjective);}}>
                  viewObjective.quarter == quarter && viewObjective.year == year && (
                    <div className={`d-flex${Visibility[index] ? ' add_border' : ''}`}  >

                      <div
                        onClick={() => handleworkobj(viewObjective, "org-obj")}
                        className={`${Visibility[index] ? "mr-0 objective_box_active p-2" : "objective_box p-2 "
                          }`}
                      >
                        <Flex justify="space-between" align="center">
                          <Flex align="center">
                            <div className="container_label">
                              <div className="wrapper_label align-center mb-1">
                                <span
                                  className={` ${Visibility[index] ? "cup_org_bg_active align-center" : "cup_org_bg align-center"
                                    }`}
                                >
                                  <img
                                    src="assets/images/cup.svg"
                                    alt=""
                                    className="pr-1"
                                  />{" "}
                                  {viewObjective.name}
                                </span>
                                <span
                                  className={` ${Visibility[index] ? "triangle_active" : "triangle"
                                    }`}
                                ></span>
                              </div>
                            </div>
                          </Flex>
                          <div>
                            <button className={`align-center border-0 p-1 mr-1  ${viewObjective.organizationStatus === "1" ? 'cascade_btn' : (Visibility[index] ? 'Progressing_btn_active' : 'Progressing_btn')}`}>
                              <span className={`mr-1 ${viewObjective.organizationStatus === "1" ? 'sm_cricle' : (Visibility[index] ? 'sm_pro_cricle_active' : 'sm_pro_cricle')}`}></span>
                              {/* {viewObjective.organizationStatus === "1" ? " Yet to Cascade" : "Progressing"} */}
                              {viewObjective.revokestatus != 1 ? (viewObjective.organizationStatus === "1" ? "Yet To Cascade" : "Progressing") : ("Revoked")}
                            </button>
                            {/* <span className="progress_btn">
                          {" "}
                          <img
                            src="assets/images/progress-Idicator.svg"
                            alt=""
                          />{" "}
                          Progressing
                        </span> */}
                            {/* <img src="assets/images/menu.svg" alt="" className="pl-2" /> */}
                          </div>
                        </Flex>
                        <Flex className="fs-16 c_32B7D9 fw-600 m-0 p-0 cur-pointer mt-2 my_obj_desc">
                          {viewObjective.objectivename}
                          {/* {setObjectiveId(viewObjective.orgobjectiveid)} */}
                        </Flex>
                        <Flex className="" justify="space-between" align="center">
                          <div className="align-center mt-2 mb-2">
                            <span className="mr-1 fs-14">Created by</span>
                            <Tooltip placement="top" title={viewObjective.createdbyusername} arrow={true}>
                              <img
                                // src="assets/images/user.svg"
                                src={viewObjective.createdByProfilePath ? FileUrl + viewObjective.createdByProfilePath : "assets/images/Default_user.svg"}
                                onError={handleImageError}
                                className="user"
                                alt=""
                              />
                            </Tooltip>
                            {/* <img src="assets/images/user.svg" className="user" alt="" /> */}
                          </div>
                          <div className="align-center mt-2 mb-2">
                            <span className="mr-1 fs-14">Assigned to</span>
                            {viewObjective.objectivesuser_list.length > 2 ? (
                              <>
                                <Avatar.Group
                                  maxCount={2}
                                  maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                >
                                  {viewObjective.objectivesuser_list.slice(0, viewObjective.objectivesuser_list.length).map((user: any, index) => (
                                    <>
                                      {/* <Avatar src="assets/images/Avatar1.png" className="avatar" key={index} /> */}
                                      <Tooltip placement="top" title={user.name} arrow={true}>
                                        <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt=""
                                          onError={handleImageError}
                                        />
                                      </Tooltip>
                                    </>
                                  ))}
                                </Avatar.Group>
                              </>
                            ) : (
                              viewObjective.objectivesuser_list.map((user: any, index) => (
                                <Tooltip placement="top" title={user.name} arrow={true}>
                                  <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" alt=""
                                    onError={handleImageError} />
                                </Tooltip>
                              ))
                            )
                            }

                          </div>
                        </Flex>
                      </div>
                    </div>
                  )
                ))}
              </Flex>
            )}
          </div>
          {/* <div>
            {assignedObjectiveList.length == 0 &&
              <Card className="Asp_nc_card" >
                <Row className="text-center obj_textbox">
                  <span className="c_AAA fs-14 fw-600">No Data Available</span>
                </Row>
              </Card>
            }
          </div> */}
          {workWithMe ? (
            <div>
              <Row>
                <p className="keyresult_title">Showing <b>{objectiveMemberList.length}</b> members of objective <b> {showMemDetails?.objectivename}</b></p>
              </Row>
              <Row className="">
                {Array.isArray(objectiveMemberList) && objectiveMemberList.length > 0 && objectiveMemberList.map((member) => (
                  <Col xl={6} lg={6} md={8} sm={12} xs={24} className="members_profile_card  cur-pointer text-center mt-2"
                    onClick={() => handleMemcard(member)}
                  >
                    {/* <Avatar src={MemObj.profileImagePath} className="mem_profile_image" /> */}
                    <img src={member.profilePath ? FileUrl + member.profilePath : "assets/images/Default_user.svg"} className="mem_profile_image" alt=""
                      onError={handleImageError} />
                    <p className="mt-1 m-0 fw-600 fs-16">{member.name}</p>
                    <p className="mt-1 m-0 fs-14">{member.employeeid}</p>
                    <p className="mt-1 m-0 fs-14">{member.designation}</p>
                    <p className="mt-1 m-0 fs-14">{member.divisonName}</p>
                    <p className=" fs-10">Weightage  :<span className="fs-16 fw-600">&nbsp;{member.weightage}</span></p>

                    {/* <Row className="justify-between align-center mt-2">
                            <p className="fs-10 align-center">Reports to :<span className="pl-1"><Avatar src={MemObj.reportImagePath} className="member_user" /></span></p>
                            <p className=" fs-10 align-center">Weightage  :<span className="fs-16 fw-600">&nbsp;{MemObj.weightage}</span></p>
                          </Row> */}
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <></>
          )}

        </>

      ) : (
        <MemberProfile percent={50} memberObjData={selectedMember} onClose={() => setMemberProfile(false)} />
        // <TeamProfile percent={50} user={selectedMember} groupName={showMemDetails.createdbyusername} onClose={() => setMemberProfile(false)} />
      )}

    </>
  );
};
export default WorkWithMe;
