import React, { useState, ChangeEvent, useEffect } from "react";
import {
    Avatar,
    Row,
    Col,
    Drawer,
    Select,
    Tag,
    Input,
    Form,
    Table,
    Radio,
    Modal,
    message,
    Checkbox
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { on } from "events";
import MyObjective from "../Objective/SubObjectiveTab/MyObjective";
import AssignObj from "./AssignObj";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
import { get } from "http";
const { Option } = Select;

interface DataSourceType {
    key: React.Key;
    Name: string;
    Available: string;
    Assigned: number;
    ImageUrl: string;
}
interface RolePermission {
    id: string;
    role: string;
    parent_objective: boolean;
    key_result: boolean;
    child_objective: boolean;
    org_objective: boolean;
    lead_measures: boolean;
    menu: string;
    userId: string;
    assigned_members: boolean;
    group: boolean;
}
// type responsetypes{

// }
interface OptionItem {
    value: string;
    label: string;
    image: string;
}
type parentobjectives = {
    objectivelist: () => void;
    objectname: String,
    objectcode: String,
    orgQuartervalue: string,
    orgobjid: String,
    objid: string,
    year: string,
    startdate:string,
    enddate:string,
    objective_id: string | null,
    orgdescription: string,
}
interface group {
    id: string,
    groupname: string,
    assignedusers: string[]
}

const ChildCreateObj: React.FC<parentobjectives> = ({ objectivelist, objectcode, objectname, orgQuartervalue, orgobjid, objid, year, objective_id, orgdescription,startdate,enddate }) => {
    const [searchTextValue, setSearchTextValue] = useState<string>("");

    const [cycleselectedOption, setCycleSelectedOption] = useState<string | null>(
        null
    );
    const [VisibilityselectedOption, setVisibilitySelectedOption] = useState<
        string | null
    >(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isWeightageButton, setIsWeightageButton] = useState(false);

    // =================Roles And Permission==============================
    const [permission_parentobjview, setPermission_parentobjview] = useState('');
    const [permission_childobjcreate, setPermission_childobjcreate] = useState('');

    // =================Roles And Permission==============================

    const handleCycleSelect = (value: string) => {
        setCycleSelectedOption(value);
    };

    const handleCycleRadio = (e: RadioChangeEvent) => {
        setCycleSelectedOption(e.target.value);
    };
    const [assignuserweightage, setassigneduserweightage] = useState<UserDetails[]>([]);
    const handleVisibilitySelect = (value: string) => {
        setVisibilitySelectedOption(value);
    };

    const handleVisibilityRadio = (e: RadioChangeEvent) => {
        setVisibilitySelectedOption(e.target.value);
    };
    const modifiedData: UserDetails[] = [];

    // const handleAssignWeightage = (e: any, index: number) => {
    //   setassigneduserweightage((prevUserDetails) =>
    //     assignuserweightage.map((item, i) =>
    //       i === index ? { ...item, assignWeightage: e.target.value } : item
    //     )
    //   );
    //   setObjectivevalue({
    //     ...objectivevalue,
    //     individualweightage: "customized"
    // });

    // };

    const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
        const { value } = e.target;

        // Find the item in the array based on its id
        const updatedCustomWeightage = assignuserweightage.map(item => {
            if (item.id === itemId) {
                // Update the assignWeightage for the specific item
                return { ...item, assignWeightage: parseInt(value) || 0 };
            }
            return item;
        });
        setassigneduserweightage(updatedCustomWeightage);
        setObjectivevalue({
            ...objectivevalue,
            individualweightage: "customized"
        });
    };

    const handleImageError = (e: any) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = 'assets/images/Default_user.svg';
    };

    // const capitalizeFirstLetter = (str: string): string => {
    //     return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    // };

    const columns = [
        {
            title: "Name",
            dataIndex: "username",
            key: "Name",
            render: (text: string, record: any) => (
                <span className="align-center">
                    <img src={record.profilePath ? FileUrl + record.profilePath : "assets/images/Default_user.svg"} alt="" onError={handleImageError} className="table_user" />
                    <span className="pl-1">{text}</span>
                </span>
            ),
        },
        // {
        //     title: "Weightage Available",
        //     dataIndex: "avaiableWeightage",
        //     key: "Available",
        //     render: (text: string, record: any) => <span className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "c_FF4747" : ""}>{record.avaiableWeightage < 0 ? (0) : record.avaiableWeightage}%</span>,
        // },
        {
            title: "Weightage Assigned",
            dataIndex: "assignWeightage",
            key: "assignWeightage",
            render: (text: string, record: any, index: number) => (
                <div className="input-with-suffix">
                    <input
                        // type="number" 
                        name="assignWeightage"
                        value={record.assignWeightage || ''}
                        // onChange={(e) => {handleAssignWeightage(e, index)}}
                        onChange={(e) => {
                            const inputVal = e.target.value;
                            const numericRegex = /^[0-9]*$/;
                            if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                { handleAssignWeightage(e, record.id) }
                            }
                        }}
                        disabled={record.id == localStorage.loginId}
                        className="assigned_bg"
                    // className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "assigned_bg_error" : "assigned_bg"}
                    />
                    <span className="suffix">%</span>
                </div>
            ),
        },
    ];


    const custom_filteredData = assignuserweightage.filter((record: UserDetails) =>
        Object.values(record).some((value) =>
            String(value).toLowerCase().includes(searchTextValue.toLowerCase())
        )
    );
    const userslist = async () => {
        try {
            const response = await axios.get(BaseUrl + "api/users/user_list", {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`
                }
            });
            const addLoginUser =  {
                id: localStorage.getItem("loginId") ?? '',
                name: localStorage.getItem('name') ?? '',
                email: localStorage.getItem('email') ?? '',
                departmentname: "",
                rolename: "",
                profilePath: localStorage.getItem('profilePath') ?? '',
            }
            const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
            users.push(addLoginUser);
            const newOptions = users.map((id) => {
                const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
                return {
                    value: id.id,
                    label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
                    image: id.profilePath
                };
            });

            setMemOptions(newOptions);
            // setMemOptions((prevMemOptions) => [
            //   ...prevMemOptions,
            //   { value: response.data.id, label: response.data.name, image: "" },
            // ]);

        } catch (error) {
            // console.error("Error fetching user data:", error);
        }
    };

    // show some Json Object error
    const groupuserslist = async () => {
        try {
            const groupslist = await axios.get(BaseUrl + "api/group/users_group",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`
                    }
                });
            const groupusersdatas: group[] = groupslist.data;
            const newoptions = groupusersdatas.map((id) => ({
                value: id.id,
                label: id.groupname,
                image: "",
                weightage: 0,
                username: id.assignedusers.map((userString: any) => {
                    return {
                        name: userString.name,
                        id: userString.userid,
                    };
                }), // Assuming you have an image property for each user
            }));
            setGrpOptions(newoptions);
        } catch (error) {
            
        }
       
    }

    // useEffect(() => {

    //   groupuserslist();
    // }, []);
    interface UserDetails {
        "id": string | null;
        "name": string;
        "username": string;
        "avaiableWeightage": number;
        "index": string | null;
        "assignWeightage": number;
        "profilePath": string;
        // Add other properties as needed
    }
    interface customWeightage {
        "id": string,
        "name": string,
        "username": string,
        "avaiableWeightage": number,
        "assignWeightage": number
    }


    interface objectiveusers {
        empId: String;
        userId: String;
        orgobjectiveid: String;
    }
    interface OptionItems {
        value: string;
        label: string;
        image: string;
        username: { name: any; id: any }[];

    }

    const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
    const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
    const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
    const [checkboxChecked1, setCheckboxChecked1] = useState(false);
    const [mydetails, setmydetails] = useState<OptionItem>();
    const profilePath = localStorage.getItem("profilePath");
    const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
    const handlecheckbox = () => {
        const checkboxElement = document.getElementById('includeMyselfCheckboxObj') as HTMLInputElement;
        if (checkboxElement) {
            const includeMyself = checkboxElement.checked;
            setCheckboxChecked1(true);
            if (includeMyself) {
                const localStorageOption: OptionItem = {
                    value: localStorage.loginId,
                    label: localStorage.name,
                    image: profilePhoto
                };
                const userid: string[] = [];

                selectedMemValues.forEach((user) => {
                    userid.push(user.value)
                })

                assignedusers.forEach((user) => {
                    userid.push(user.value)
                })
                userid.push(localStorageOption.value)
                setmydetails(localStorageOption);
                selectedmemdetails(userid);

            } else {
                setmydetails(undefined)
                handleTagClose(localStorage.loginId)
                handleMemTagClose(localStorage.loginId);
            }
        }
    }

    const userdetails_1: UserDetails[] = [];
    const selectedmemdetails = async (userid: string[]) => {
        const userObjectIdList: string[] = [];
        selectedMemValues.map((member) => {
            userObjectIdList.push(member.value);
        })
        assignedusers.map((grpmembers) => {
            userObjectIdList.push(grpmembers.value)
            if (checkboxChecked1 === true) {
                userObjectIdList.push(localStorage.loginId);
            }
        })

        try {
            const userdetails = await axios.post(BaseUrl + "api/users/selected_users", userid,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            userdetails_1.push(userdetails.data);

            // const modifiedData:UserDetails[] = 
            userdetails.data.map((item: UserDetails) => {
                const existingUser = assignuserweightage.find(user => user.id === item.id);
                if (existingUser) {
                    modifiedData.push(existingUser);
                } else {
                    const displayWeightage = {
                        // ...item,
                        id: item.id,
                        name: "",
                        username: item.name,
                        avaiableWeightage: item.avaiableWeightage,
                        index: "",
                        assignWeightage: parseInt(objectivevalue.individualweightage),
                        profilePath: item.profilePath//"assets/images/user.svg"
                    }

                    modifiedData.push(displayWeightage);
                }
            });
            setassigneduserweightage(modifiedData);
        }
        catch (e) {
            // console.log(e);
        }
    }

    const handleMemSelectChange = (selected: OptionItem[]) => {
        const selectedMemValues = Memoptions.filter((opt) =>
            selected.some((item) => item.value === opt.value)
        );
        const membersdetails: string[] = []
        selectedMemValues.forEach((values) => {
            membersdetails.push(values.value)
        })

        setSelectedMemValues(selectedMemValues);
        const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
        if (mydetails) {
            userid.push(mydetails.value);
        }
        selectedmemdetails(userid);
    };

    const handleMemTagClose = (valueToRemove: string) => {
        const updatedMemValues = selectedMemValues.filter(
            (value) => value.value !== valueToRemove
        );
        setSelectedMemValues(updatedMemValues);
        const updatedMemValues1 = assignuserweightage.filter(
            (value) => value.id !== valueToRemove
        );
        setassigneduserweightage(updatedMemValues1);

    };

    const handleFHusersTagClose = (valueToRemove: string) => {
        const updatedMemValues = fhuserslists.filter(
            (value) => value.value !== valueToRemove
        );
        setSelectedFhusers(updatedMemValues);
        const updatedMemValues1 = assignuserweightage.filter(
            (value) => value.id !== valueToRemove
        );
    };

    const handleGrpSelectChange = (selected: OptionItems[]) => {
        const selectedGrpValues = GrpOptions.filter((opt) =>
            selected.some((item) => item.value === opt.value)
        );
        const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
        // const filteredEditMemberDetails = editmemberdetails.filter(val =>
        //   !selectedUserIds.includes(val)
        // );
        if (mydetails != undefined) {
            selectedUserIds.push(mydetails?.value)
        }

        setSelectedGrpValues(selectedGrpValues);
        setAssignedUsers(selectedGrpValues);
        const selectedgrpids = selectedGrpValues.map(user => user.value)
        setObjectivevalue({
            ...objectivevalue,
            groupid: selectedgrpids// Using another value
        });

        selectedmemdetails(selectedUserIds);
        // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
    };

    const handleTagClose = (valueToRemove: string) => {
        const selectedGrpValues = GrpOptions.filter((opt) =>
            valueToRemove === opt.value
        );
        const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
        if (mydetails?.value == valueToRemove) {
            // const checkboxElement = document.getElementById('includeMyselfCheckboxAdd') as HTMLInputElement;

            // checkboxElement.removeAttribute('checked');
            setmydetails(undefined)
            setCheckboxChecked1(false);
        }
        setAssignedUsers(updatedGrpValues);
        setSelectedGrpValues(updatedGrpValues);
        handleGrpSelectChange(updatedGrpValues)
        const updatedMemValues1 = assignuserweightage.filter(value =>
            assignedusers.every(obj =>
                obj.username.every(user => user.id !== value.id)
            )
        );

        // setassigneduserweightage(updatedMemValues1);
    };
    const renderOption = (option: OptionItem) => (
        <div>
            <img className="member_user" src={option.image ? FileUrl + option.image : "assets/images/Default_user.svg"} style={{ marginRight: "8px" }} onError={handleImageError} />
            {option.label}
        </div>
    );

    const [Memoptions, setMemOptions] = useState<OptionItem[]>([
    ]);


    const assignedwightageusers = (e: any, index: any) => {
        setassigneduserweightage((prevUserDetails) =>
            assignuserweightage.map((item, i) =>
                i === index ? { ...item, assigned: e.target.value } : item
            )
        );
    };
    // Memoptions.forEach((option) => {
    //   if (!option.image || option.image.trim() === "") {
    //     option.image = "assets/images/Default_user.svg";
    //   }
    // });

    const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([
    ]);

    GrpOptions.forEach((option) => {
        if (!option.image || option.image.trim() === "") {
            option.image = "assets/images/Default_user.svg";
        }
    });
    // Custom Weightage hide and show

    const [isCustomVisible, setIsCustomVisible] = useState(false);
    const [isSearchMem, setisSearchMem] = useState(true);
    const handleSearchMember = () => {
        setisSearchMem(!isSearchMem);
        if (isSearchMem == false) {
            setAssignedUsers([]);
            setassigneduserweightage([]);
        }
        else {
            setSelectedMemValues([]);
            setassigneduserweightage([]);
            setSelectedGrpValues([]);
            setCheckboxChecked1(false);
            setmydetails(undefined);
        }
    };

    const toggleCustomVisibility = () => {
        setIsCustomVisible(!isCustomVisible);
        // selectedmemdetails();
    };

    const rolename = localStorage.getItem("rolename");
    let viewButton = true;
    if (rolename === "BH") {
        viewButton = false;
    }

    const [open, setOpen] = useState(false);
    const { TextArea } = Input;

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        // console.log("print");
        
        setOpen(false);
        //window.location.reload()
        setObjectivevalue({
            objectivename: "",
            description: "",
            remark: "",
            orgobjectiveid: orgobjid,
            parentid: objid != "" ? objid : "",
            quarter: cycleselectedOption,
            assignedmembers: selectedMemValues,
            individualweightage: "",
            status: "",
            year: year,
            startdate: startdate,
            enddate: enddate,
            objectiveid: objective_id,
            groupid: []
        });
        setSelectedMemValues([]);
        setSelectedGrpValues([]);
        setAssignedUsers([]);
        setSelectedFhusers([]);
        setErrors(null);
        //window.location.reload();
    };
    // work count start
    const [objectiveText, setObjectiveText] = useState();
    const [descriptionText, setDescriptionText] = useState();
    const [remarkText, setRemarkText] = useState();

    interface maxWords {
        name_max_word_count: number;
        description_max_word_count: number;
        remark_max_word_count: number;
    };

    const [maxWords, setMaxWords] = useState<maxWords>({
        name_max_word_count: 0,
        description_max_word_count: 0,
        remark_max_word_count: 0,
    });

    const getWordCount = async () => {
        try {
            const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Objective");
            setMaxWords(maxWords.data);
        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    }

    const [objectivevalue, setObjectivevalue] = useState({
        objectivename: "",
        description: "",
        remark: "",
        orgobjectiveid: orgobjid,
        parentid: objid,
        quarter: cycleselectedOption,
        assignedmembers: selectedMemValues,
        individualweightage: "",
        status: "",
        year: year,
        startdate:startdate,
        enddate:enddate,
        objectiveid: objective_id,
        groupid: [] as string[]
    });

    const GetUserAccess = async () => {

        try {
            const role = localStorage.getItem('rolename');
            const userid = localStorage.getItem('username');
            const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
            const data = response.data;
            const rolePermissionData = JSON.parse(data[0].rolepermission) as RolePermission[];
            rolePermissionData.map((permission: any) => {
                if (permission.menu === "Create") {
                    setPermission_childobjcreate(permission.child_objective)
                }
            })

            // setRolePermissions(rolePermissionData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        GetUserAccess();
        setObjectivevalue(prevState => ({
            ...prevState,
            quarter: cycleselectedOption,
        }));
        handleCycleSelect(orgQuartervalue);
        userslist();
        fhuserslist();
        groupuserslist();
        getWordCount();
    }, [cycleselectedOption]);

    const handleTextChange = (
        event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
        setText: Function,
        maxWords: number
    ) => {
        const newText = event.target.value;
        const inputName = event.target.name;

        // Check if the input exceeds the maximum word count
        if (newText.split(/\s+/).join("").length <= maxWords) {
            const wordCount = newText.trim().split(/\s+/).join("").length;
            if (wordCount <= maxWords) {
                setText(newText);
            }
            if (Object.keys(objectivevalue).includes(event.target.name)) {
                setObjectivevalue({
                    ...objectivevalue,
                    [event.target.name]: event.target.value
                });
                if (event.target.name === 'individualweightage') {
                    const updatedUserDetails = assignuserweightage.map((item) => ({
                        ...item,
                        assignWeightage: item.username != localStorage.name
                        ? parseFloat(event.target.value)
                        : item.assignWeightage
                    }));

                    setassigneduserweightage(updatedUserDetails);
                }
            }
        }
    };

    const [fhuserslists, setSelectedFhusers] = useState<OptionItem[]>([]);
    const [fhuseroption, setfhusersoption] = useState<OptionItem[]>([]);
    // fhuseroption.forEach((option) => {
    //   if (!option.image || option.image.trim() === "") {
    //     option.image = "assets/images/Default_user.svg";
    //   }
    // });
    const handleMemFHChange = (selected: OptionItem[]) => {
        const fhmemvalues = fhuseroption.filter((opt) =>
            selected.some((item) => item.value === opt.value)
        );

        const membersdetails: string[] = []
        fhmemvalues.forEach((values) => {
            membersdetails.push(values.value)
        })
        setSelectedFhusers(fhmemvalues);
        const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
        // selectedmemdetails(userid);
    };
    const fhuserslist = async () => {
        try {
            const fhusers = await axios.get(BaseUrl + "api/users/FHuserslist", {
                headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`
                }
            });

            const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = fhusers.data;
            // Map over the array of users to create options
            const newOptions = users.map((id) => {
                return {
                    value: id.id,
                    label: id.rolename + ":" + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
                    image: id.profilePath, // Assuming you have an image property for each user
                    weightage: 0
                };
            });
            setfhusersoption(newOptions);
        } catch (error) {
            // console.error(error)
        }

    }
    const addorgobjective = (text: string, submit: boolean) => {
        const usersweightage = assignuserweightage.filter(user => user.id != localStorage.loginId)
        if (text != "Save") {
            if (fhuserslists.length == 0 && selectedMemValues.length == 0 && assignedusers.length == 0 && mydetails == undefined) {
                Modal.error({
                    content: (
                        <>
                            <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
                            <p className="fs-18"><b>Note : </b>AssignMembers Or CFT is Mandatory</p>
                        </>
                    ),
                    centered: true,
                })
            }
            // else if (usersweightage.some(user => Number.isNaN(user.assignWeightage)) || usersweightage.some(user => user.assignWeightage == 0)) {
            //     Modal.error({
            //         content: (
            //             <>
            //                 <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
            //                 <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
            //             </>
            //         ),
            //         centered: true,
            //     })
            // }
            else {
                //     if (usersweightage.some(user => Number.isNaN(user.assignWeightage)) || usersweightage.some(user => user.assignWeightage == 0)) {
                //         Modal.error({
                //             content: (
                //                 <>
                //                     <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
                //                     <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
                //                 </>
                //             ),
                //             centered: true,
                //         })
                //     }
                // else {
                Modal.confirm({
                    title: 'Do you want to ' + text + ' this objective ?',
                    content: (
                        <>
                            <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
                            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                        </>
                    ),
                    okText: text,
                    cancelText: 'Back',
                    okButtonProps: { className: 'C_btn' },
                    cancelButtonProps: { className: 'outline_btn' },
                    centered: true,
                    onOk() {
                        message.success({
                            content: (
                                <div className="success-message justify-center align-center p-1">
                                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                                    <span className="c_32C6A0 fs-16">
                                        Objective Created successfully.
                                    </span>
                                </div>
                            ),
                            duration: 1,
                            icon: null,
                        });
                        onClose();
                        text === "Save" ? saveobj("1") :
                            saveobj("3")
                        // saveobj()
                        setTimeout(() => {
                            // window.location.reload();
                            objectivelist();
                        }, 1000);

                        // console.log('Cascade action confirmed');
                    },
                    onCancel() {
                        setCasClicked(false);
                        // console.log('Cascade action cancelled');
                    },
                });
                // }
            }
        }
        else {
            const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
            // if (usersweightage.some(user => user.avaiableWeightage == 0)) {
            //     Modal.error({
            //         content: (
            //             <>
            //                 <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
            //                 <p className="fs-18"><b>Note : </b>User Weightage Is Zero Please Check.</p>
            //             </>
            //         ),
            //         centered: true,
            //     })
            // }
            // else {
            Modal.confirm({
                title: 'Do you want to ' + text + ' this objective ?',
                content: (
                    <>
                        <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
                        {/* {greaterWeightageUser.length === 0 ? ( */}
                        <>
                            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                        </>
                        {/* ) : greaterWeightageUser.length === 1 ? (
                                <>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
                                            <div className='text-center'>
                                                <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
                                                <p>{greaterWeightageUser[0].value}</p>
                                            </div>
                                        </Col>
                                        <Col xl={18} lg={18} md={18} sm={16} xs={24}>
                                            <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                                            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                                    <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                                </>)} */}
                    </>
                ),
                okText: text,
                cancelText: 'Back',
                okButtonProps: { className: 'C_btn' },
                cancelButtonProps: { className: 'outline_btn' },
                centered: true,
                onOk() {
                    message.success({
                        content: (
                            <div className="success-message justify-center align-center p-1">
                                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                                <span className="c_32C6A0 fs-16">
                                    Objective Created successfully.
                                </span>
                            </div>
                        ),
                        duration: 1,
                        icon: null,
                    });

                    text === "Save" ? saveobj("1") :
                        saveobj("3")
                    // saveobj()
                    // window.location.reload();
                    if (submit == false) {
                        onClose();
                        setTimeout(() => {
                            objectivelist();
                            // window.location.reload();
                        }, 1000);
                    }
                    else {
                        toggleCustomVisibility()
                    }

                    // console.log('Cascade action confirmed');
                },
                onCancel() {
                    setClicked(false);
                    // console.log('Cascade action cancelled');
                },
            });
            // }
        }
    }
    // interface userStoreDetails {
    //   empId: String | null;
    //   userId: string | null;
    //   avaiableWeightage: number,
    //   weightage: string | null,
    //   index: String,
    //   ImageUrl: String
    //   // weightage: number;
    // }

    interface userStoreDetails {
        empId: string;
        userId: string | null;
        // weightage: number;
    }



    const [objectiveusers, Setobjectiveuserlist] = useState<objectiveusers[]>([])
    const selectedMemberList: userStoreDetails[] = [];
    const selectedGroupList: objectiveusers[] = [];
    const ObjectivesSchemacascade = z.object({
        objectivename: z.string().min(1, { message: 'Objective name field is required' }),
        // individualweightage: z.string().min(1, { message: 'Individual weightage field is required' }),
    });
    const objectivesave = z.object({
        objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    });
    const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
    const [clicked, setClicked] = useState(false);
    const [casclicked, setCasClicked] = useState(false);
    const [customweightagedetails, setCustomWeightageDetails] = useState<UserDetails[]>([]);

    let weightagePopupCount = 0;
    function userAssignedWeightage(): OptionItem[] {
        const greaterWeightageUser: OptionItem[] = [];
        for (let index = 0; index < assignuserweightage.length; index++) {
            // if (parseInt(objectivevalue.individualweightage) > assignuserweightage[index].avaiableWeightage) {
            //   count++;
            // }
            if (assignuserweightage[index].avaiableWeightage < assignuserweightage[index].assignWeightage) {
                const popupUser = {
                    value: assignuserweightage[index].username,
                    label: assignuserweightage[index].name,
                    image: assignuserweightage[index].profilePath !== null ? assignuserweightage[index].profilePath : "",
                }
                greaterWeightageUser.push(popupUser);
                weightagePopupCount++;
            }
        }
        return greaterWeightageUser;
    }


    const handleSubmit = (status: string, submit: boolean) => {
        // Prevent double-clicking
        if (isButtonDisabled) {
            return;
        }
        // Disable the button
        setIsButtonDisabled(true);

        // Validate data against schema
        const validationResult = ObjectivesSchemacascade.safeParse(objectivevalue);
        const validationsave = objectivesave.safeParse(objectivevalue);
        if (status == "3") {
            if (validationResult.success) {
                // Data is valid, proceed with your logic

                setErrors(null);
                addorgobjective("Cascade", submit)
                // display the pop up weightage less than available 
                // const greaterWeightageUser: OptionItem[] = userAssignedWeightage();

                // if (weightagePopupCount === 0) {
                //     addorgobjective("Cascade", submit)
                // }
                // else {
                //     weightagePopupCount === 1 ?
                //         handleDelete(greaterWeightageUser[0]?.label, greaterWeightageUser[0]?.image, objectivevalue.objectivename, "Objective") :
                //         handleproceed(greaterWeightageUser, objectivevalue.objectivename, "Objective");
                // }
            } else {
                // Data is invalid, update errors state
                const fieldErrors: { [key: string]: string } = {};
                validationResult.error.errors.forEach(error => {
                    fieldErrors[error.path[0]] = error.message;
                });
                setErrors(validationResult.error.errors);
            }
        }
        else {
            if (validationsave.success) {
                // Data is valid, proceed with your logic

                setErrors(null);
                addorgobjective("Save", submit)
            } else {
                // Data is invalid, update errors state
                const fieldErrors: { [key: string]: string } = {};
                validationsave.error.errors.forEach(error => {
                    fieldErrors[error.path[0]] = error.message;
                });
                setErrors(validationsave.error.errors);
            }
        }
        setTimeout(() => {
            // Enable the button
            setIsButtonDisabled(false);
        }, 2000);
    };

    const saveobj = async (organizationStatus: string) => {

        // selectedMemValues.map((member) => {                  
        //   const user = {
        //     empId: member.label,
        //     userId: member.value,
        //     orgobjectiveid:orgobjid,
        //   }
        //   selectedMemberList.push(user);
        // })
        selectedGrpValues.map((group) => {
            const user1 = {
                empId: group.label,
                userId: group.value,
                orgobjectiveid: orgobjid,
            }
            selectedGroupList.push(user1);
        })

        fhuserslists.map((group, index) => {

            const fhusers = {
                empId: group.label,
                userId: group.value,
                weightage: 0,
                cftstatus: 1
            }

            selectedMemberList.push(fhusers);
        })

        assignuserweightage.map((member) => {
            const user = {
                empId: member.username,
                userId: member.id,
                avaiableWeightage: member.avaiableWeightage,
                weightage: member.assignWeightage,
                index: "",
                ImageUrl: ""
            }

            selectedMemberList.push(user);
        })

        console.log(startdate)
        console.log(enddate)

        Setobjectiveuserlist(selectedGroupList);
        try {
            await axios.post(BaseUrl + "api/objectives/createObjective", {
                "objectivesdetails": objectivevalue,
                "objectivesuserlist": selectedMemberList,
                "saveOrCascade": organizationStatus,
            },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.accessToken}`
                        // Replace 'yourAuthToken' with the actual authentication token
                    }
                }
            ).then((response) => {
                onClose();
                // <MyObjective />
                // window.location.reload();
            })
        } catch (error) {

        }

    }
    let objectivebutton = "";
    let objectiveheader = "";
    if (localStorage.rolename === "FH") {
        objectivebutton = "+ Create Parent Objective"
        objectiveheader = "Create Parent Objective"
    }
    else if (localStorage.rolname === "BH") {
        objectivebutton = "+ create Parent Objective"
        objectiveheader = "Create Parent Objective"
    }
    else if (localStorage.rolename === "TM") {
        objectivebutton = "+ Create Child Objective"
        objectiveheader = "Create Child Objective"
    }

    function closeDrawerAndUserList() {
        toggleCustomVisibility();
    }

    // word count end
    const CustomEmptyMemberText = () => (
        <div style={{ textAlign: 'center' }}>
            No Data
        </div>
    );
    const CustomEmptyGroupText = () => (
        <div style={{ textAlign: 'center' }}>
            No Group Data
        </div>
    );
    const handlesubmitcustomweightage = (status: string) => {
        const usersweightage = assignuserweightage.filter(user => user.id != localStorage.loginId)
        // Prevent double-clicking
        if (isWeightageButton) {
            return;
        }
        // Disable the button
        setIsWeightageButton(true);

        if (objectivevalue.objectivename == "" || objectivevalue.quarter == "") {
            message.error({
                content: (
                    <div className="c_FF4747 justify-center align-center p-1">
                        {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
                        <span className="c_FF4747 fs-16">
                            Objective And ObjectiveName Name Is Mandatory
                        </span>
                    </div>
                ),
                duration: 1,
                icon: null,
            });
            toggleCustomVisibility()
        }
        else {
            //  if (usersweightage.some(user => user.avaiableWeightage==0)){
            //   Modal.error({
            //     content: (
            //       <>
            //         <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
            //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
            //       </>
            //     ),
            //     centered: true,
            //   })
            // } else {
            //   Modal.confirm({
            //     title: 'Do you want to save the weightage this objective ?',
            //     content: (
            //       <>
            //         <p className="fs-16 mb-3 keytitle_scroll">{objectivevalue.objectivename}</p>
            //         <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            //       </>
            //     ),
            //     okText: 'Save',
            //     cancelText: 'Back',
            //     okButtonProps: { className: 'C_btn' },
            //     cancelButtonProps: { className: 'outline_btn' },
            //     centered: true,
            //     onOk() {
            //       message.success({
            //         content: (
            //           <div className="success-message justify-center align-center p-1">
            //             <img src="assets/images/check-circle.svg" alt="Success Icon" />
            //             <span className="c_32C6A0 fs-16">
            //               Weightage Saved successfully.
            //             </span>
            //           </div>
            //         ),
            //         duration: 2,
            //         icon: null,
            //       });
            //       toggleCustomVisibility();
            //     },
            //     onCancel() {
            //       setCasClicked(false);
            //       console.log('Cascade action cancelled');
            //     },
            //   });
            // }

            message.success({
                content: (
                    <div className="success-message justify-center align-center p-1">
                        <img src="assets/images/check-circle.svg" alt="Success Icon" />
                        <span className="c_32C6A0 fs-16">
                            Weightage Saved successfully.
                        </span>
                    </div>
                ),
                duration: 2,
                icon: null,
            });
            toggleCustomVisibility();
            //  handleSubmit("Save",true)


        }

        setTimeout(() => {
            // Enable the button
            setIsWeightageButton(false);
        }, 2000);
    }
    return (
        <div className="">
            {/* {
        viewButton && permission_childobjcreate ? (<Row>
          <span className="p-1 c-000 fs-12 c_32B7D9" onClick={showDrawer}> {button}</span>
        </Row>) : (<span></span>)
      } */}
            {/* <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Weightage
            </div>
          ) : (
            header
          )
        }
        size="large"
        onClose={onClose}
        open={open}
        className="objective-canvas  position-relative"
      > */}
            <Form className="mb-5">
                <div>
                    {isCustomVisible ? (
                        <div className="cw_table">
                            {isSearchMem && selectedMemValues.length > 0 && mydetails != undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + 1})</p></Row> : ''}
                            {isSearchMem && selectedMemValues.length > 0 && mydetails == undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                            {/* {isSearchMem && selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''} */}
                            <Row justify={"space-between"} className="mb-2">
                                <Col xl={6} lg={5} md={6} sm={6} xs={24} className="mb-2">
                                    {/* <span
                      className="c_32B7D9 fs-12 cur-pointer"
                      onClick={toggleCustomVisibility}
                    >
                      <ArrowLeftOutlined /> Custom Weightage
                    </span> */}
                                </Col>
                                <Col
                                    xl={24}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    className="position-relative"
                                >
                                    <input
                                        type="search"
                                        placeholder="Search Member"
                                        className="w-100 table_search_inputbox pl-2"
                                        value={searchTextValue}
                                        // onSearch={handleSearch}
                                        onChange={(e) => setSearchTextValue(e.target.value)}
                                    />
                                    <img
                                        src="assets/images/search.svg"
                                        className="table_search_img"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                            <Table
                                dataSource={assignuserweightage}
                                columns={columns}
                                scroll={{ x: true }}
                                pagination={{ pageSize: 10 }}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="title-section mb-3">
                                <Row>
                                    <Col xl={16}>
                                        <label htmlFor="" className="fw-600 c_32B7D9">Parent Objective</label> <br />
                                        <p className="fs-16 fw-300">{objectname}</p>
                                    </Col>
                                    <Col xl={8}>
                                        <label htmlFor="" className="fw-600 c_32B7D9">Cycle</label> <br />
                                        <p className="fs-16 fw-300">{orgQuartervalue} {year}</p>
                                        <input type="hidden" value={`${orgobjid}`} />
                                    </Col>


                                </Row>
                                <Row>
                                    <Col xl={24}>
                                        <label htmlFor="" className="fw-600 c_32B7D9">Parent Objective Description</label> <br />
                                        <p className="fs-16 fw-300 org_obj_name">{orgdescription}</p>
                                    </Col>
                                </Row>
                                <div className="justify-between">
                                    <label htmlFor="objective" className="fw-600 c_32B7D9">
                                        Objective Name <sup className="c_32B7D9">*</sup>
                                    </label>
                                    <span className="c-0050 fs-12 mt-1">
                                        {objectivevalue.objectivename.trim().split(/\s+/).join("").length} /{" "}
                                        {maxWords.name_max_word_count}
                                    </span>
                                </div>
                                <div className="objective-title-inner">
                                    <TextArea
                                        id="objective"
                                        rows={3}
                                        placeholder="Write here...."
                                        className="canvas-objective-input"
                                        name="objectivename"
                                        value={objectivevalue.objectivename}
                                        onChange={(e) =>
                                            handleTextChange(
                                                e,
                                                setObjectiveText,
                                                maxWords.name_max_word_count
                                            )
                                        }
                                    />
                                    {errors && errors.find(error => error.path[0] === 'objectivename') && (
                                        <p className="danger">{errors.find(error => error.path[0] === 'objectivename')?.message}</p>
                                    )}
                                </div>
                            </div>
                            <div className="desc-section mb-3">
                                <div className="justify-between">
                                    <label htmlFor="" className="fw-600 c_32B7D9">
                                        Description
                                        {/* (Optional) */}
                                        <sup className="c_32B7D9">*</sup>
                                    </label>
                                    <span className="c-0050 fs-12 mt-1">
                                        {objectivevalue.description.trim().split(/\s+/).join("").length} /{" "}
                                        {maxWords.description_max_word_count}
                                    </span>
                                </div>
                                <div className="objective-title-inner">
                                    <TextArea
                                        rows={3}
                                        placeholder="Write here...."
                                        className="canvas-objective-input"
                                        name="description"
                                        value={objectivevalue.description}
                                        onChange={(e) =>
                                            handleTextChange(
                                                e,
                                                setDescriptionText,
                                                maxWords.description_max_word_count
                                            )
                                        }
                                    />
                                    {errors && errors.find(error => error.path[0] === 'description') && (
                                        <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                                    )}
                                </div>
                            </div>

                            <div className="assign-section mb-3">
                                <label htmlFor="" className="fw-600 c_32B7D9">
                                    Assign Members<sup className="c_32B7D9">*</sup>
                                </label>
                                {Memoptions.length == 0 &&
                                    <span className="danger ml-3">Assignees Not Available</span>
                                }
                                <span
                                    className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                                    onClick={handleSearchMember}
                                >
                                    {isSearchMem ? "Search Groups" : "Search Members"}
                                </span>
                                {/* {isSearchMem ? (<span className="float-right c_32B7D9 text_line fs-14 cur-pointer">
                                    <Checkbox className="c_32B7D9 fs-14" onClick={handlecheckbox} id="includeMyselfCheckboxObj" checked={checkboxChecked1}>Include Myself</Checkbox>
                                </span>) : (<></>)} */}

                                <br />
                                <div className="mt-1">
                                    {isSearchMem ? (
                                        <>
                                            <Select
                                                mode="multiple"
                                                placeholder={
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <img
                                                            src="assets/images/member.svg"
                                                            alt="Placeholder"
                                                            style={{ marginRight: "8px" }}
                                                        />
                                                        <span>Select Member</span>
                                                    </div>
                                                }
                                                onChange={handleMemSelectChange}
                                                value={selectedMemValues}
                                                className="w-100 form-control"
                                                labelInValue
                                                optionLabelProp="label"
                                                dropdownRender={(menu) => <div>{menu}</div>}
                                                notFoundContent={<CustomEmptyMemberText />}
                                                disabled={Memoptions.length == 0}
                                                // options={Memoptions}
                                                // filterOption={(input, option) =>
                                                //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                // }
                                                filterOption={(input, option) =>
                                                    option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                }
                                            >
                                                {Memoptions.map((option) => (
                                                    <Option
                                                        key={option.value}
                                                        value={option.value}
                                                        label={option.label}
                                                    >
                                                        {renderOption(option)}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </>
                                    ) : (
                                        <Select
                                            mode="multiple"
                                            placeholder={
                                                <div
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <img
                                                        src="assets/images/people.svg"
                                                        alt="Placeholder"
                                                        style={{ marginRight: "8px" }}
                                                    />
                                                    <span>Select Group</span>
                                                </div>
                                            }
                                            onChange={handleGrpSelectChange}
                                            value={selectedGrpValues}
                                            className="w-100 form-control"
                                            labelInValue
                                            optionLabelProp="label"
                                            dropdownRender={(menu) => <div>{menu}</div>}
                                            notFoundContent={<CustomEmptyGroupText />}
                                            disabled={Memoptions.length == 0}
                                            // options={GrpOptions}
                                            // filterOption={(input, option) =>
                                            //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                            // }
                                            filterOption={(input, option) =>
                                                option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                            }

                                        >
                                            {GrpOptions.map((option) => (
                                                <Option
                                                    key={option.value}
                                                    value={option.value}
                                                    label={option.label}
                                                >
                                                    {renderOption(option)}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                    <div
                                        className={
                                            checkboxChecked1
                                                ? "assgin_selected_val"
                                                : "" ||
                                                    selectedMemValues
                                                    ? "assgin_selected_val"
                                                    : "" || selectedGrpValues
                                                        ? "assgin_selected_val"
                                                        : "" || mydetails
                                                            ? "assgin_selected_val"
                                                            : ""
                                        }
                                    >

                                        {isSearchMem && selectedMemValues.length > 0 && mydetails != undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + 1})</p></Row> : ''}
                                        {isSearchMem && selectedMemValues.length > 0 && mydetails == undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                                        {mydetails != undefined
                                            &&
                                            <Tag
                                                key={mydetails?.value}
                                                // closable
                                                onClose={() => handleTagClose(mydetails?.value)}
                                                style={{
                                                    marginRight: "5px",
                                                    marginBottom: "5px",
                                                    color: "#000",
                                                    borderRadius: 15,
                                                    padding: "5px 10px",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={mydetails?.image}
                                                    onError={handleImageError}
                                                    alt=""
                                                    style={{ marginRight: "8px" }}
                                                    className="select_user"
                                                />
                                                {mydetails?.label}
                                            </Tag>
                                        }

                                        {selectedMemValues.map((value) => (
                                            <Tag
                                                key={value.value}
                                                closable
                                                onClose={() => handleMemTagClose(value.value)}
                                                style={{
                                                    marginRight: "5px",
                                                    marginBottom: "5px",
                                                    color: "#000",
                                                    borderRadius: 15,
                                                    padding: "5px 10px",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={value.image ? FileUrl + value.image : "assets/images/Default_user.svg"}
                                                    onError={handleImageError}
                                                    alt=""
                                                    style={{ marginRight: "8px" }}
                                                    className="select_user"
                                                />
                                                {value.label}
                                            </Tag>
                                        ))}
                                        {isSearchMem && selectedMemValues.length === 0 && mydetails?.label == null ? (
                                            <>
                                                <p className="c_AAA text-center">No members added</p>
                                            </>
                                        ) :
                                            (<></>
                                            )}
                                        {isSearchMem === false && selectedGrpValues.length === 0 && mydetails?.label == null ? (
                                            <>
                                                <p className="c_AAA text-center">No groups added</p>
                                            </>
                                        ) :
                                            (
                                                <>
                                                </>
                                            )}
                                        {assignedusers.map((value) => (
                                            <Tag
                                                key={value.value}
                                                closable
                                                onClose={() => handleTagClose(value.value)}
                                                style={{
                                                    marginRight: "5px",
                                                    marginBottom: "5px",
                                                    color: "#000",
                                                    borderRadius: 15,
                                                    padding: "5px 10px",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src="assets/images/Default_user.svg"
                                                    alt=""
                                                    style={{ marginRight: "8px" }}
                                                    className="select_user"
                                                />
                                                {value.label}
                                            </Tag>

                                        ))}
                                        {/* {selectedGrpValues.map((value) => (
                        <Tag
                          key={value.value}
                          closable
                          onClose={() => handleTagClose(value.value)}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            color: "#000",
                            borderRadius: 15,
                            padding: "5px 10px",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={value.image}
                            alt={value.label}
                            style={{ marginRight: "8px" }}
                            className="select_user"
                          />
                          {value.label}
                        </Tag>
                      ))} */}
                                    </div>
                                </div>
                            </div>
                            <div className="assign-section mb-3">
                                <div className="align-center justify-between">
                                    <label htmlFor="" className="fw-600 c_32B7D9">
                                        Assign CFT
                                    </label>
                                    <span className="fs-12">i.e, CFT don’t get weightage alloted</span>
                                </div>
                                <div className="mt-1">
                                    <Select
                                        mode="multiple"
                                        placeholder={
                                            <div
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <img
                                                    src="assets/images/member.svg"
                                                    alt="Placeholder"
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Search Functional Heads</span>
                                            </div>
                                        }
                                        onChange={handleMemFHChange}
                                        value={fhuserslists}
                                        className="w-100 form-control"
                                        labelInValue
                                        optionLabelProp="label"
                                        dropdownRender={(menu) => <div>{menu}</div>}
                                        notFoundContent={<CustomEmptyMemberText />}
                                        //   options={fhuseroption}
                                        //   filterOption={(input, option) =>
                                        //     option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        // }
                                        filterOption={(input, option) =>
                                            option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        }
                                    >
                                        {fhuseroption.map((option) => (
                                            <Option
                                                key={option.value}
                                                value={option.value}
                                                label={option.label}
                                            >
                                                {renderOption(option)}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="assgin_selected_val mb-3">
                                {fhuserslists.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">CFT({fhuserslists.length})</p></Row> : ''}
                                {fhuserslists.map((value) => (
                                    <Tag
                                        key={value.value}
                                        closable
                                        onClose={() => handleFHusersTagClose(value.value)}
                                        style={{
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            color: "#000",
                                            borderRadius: 15,
                                            padding: "5px 10px",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={value.image ? FileUrl + value.image : "assets/images/Default_user.svg"}
                                            onError={handleImageError}
                                            alt=""
                                            style={{ marginRight: "8px" }}
                                            className="select_user"
                                        />
                                        {value.label}
                                    </Tag>
                                ))}
                                {fhuserslists.length === 0 ? (
                                    <>
                                        <p className="c_AAA text-center">No CFT added</p>
                                    </>
                                ) :
                                    (
                                        <>
                                        </>
                                    )}
                            </div>
                            <div className="mb-3">
                                <Row className={isCustomVisible ? "d-none" : ""}>
                                    <Col xl={18} lg={18} md={18} sm={12} xs={24}>
                                        <Row className="align-center">
                                            <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                                                <label htmlFor="" className="fw-600">
                                                    Individual Weightage<sup className="c_32B7D9">*</sup>
                                                </label>
                                                <Input
                                                    placeholder=""
                                                    // type="number"
                                                    suffix="%"
                                                    className="canvas-objective-input mt-1"
                                                    value={objectivevalue.individualweightage}
                                                    name="individualweightage"
                                                    // onChange={(e) =>
                                                    //   handleTextChange(e, setRemarkText, maxWords.remark)
                                                    // }
                                                    onChange={(e) => {
                                                        const inputVal = e.target.value;
                                                        const numericRegex = /^[0-9]*$/;
                                                        if (inputVal === 'customized' || inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
                                                            handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                                                        }
                                                    }}
                                                />
                                                {errors && errors.find(error => error.path[0] === 'individualweightage') && (
                                                    <p className="danger">{errors.find(error => error.path[0] === 'individualweightage')?.message}</p>
                                                )}
                                            </Col>
                                            <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                                                <p className="pl-2 pt-2">
                                                    i.e, Weightage only be a Percentage
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={24}>
                                        <span
                                            className="c_32B7D9 fs-14 cur-pointer text_line float-right"
                                            onClick={closeDrawerAndUserList}
                                        >
                                            Custom Weightage <ArrowRightOutlined />
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                            <div className="remark-section mb-3">
                                <div className="justify-between">
                                    <label className="canvas-objective-title c_32B7D9">Remarks (Optional)</label>
                                    <span className="c-0050 fs-12 mt-1">
                                        {objectivevalue.remark.trim().split(/\s+/).join("").length} /{" "}
                                        {maxWords.remark_max_word_count}
                                    </span>
                                </div>
                                <div className="objective-title-inner">
                                    <TextArea
                                        rows={3}
                                        placeholder="Write here...."
                                        className="canvas-objective-input w-100"
                                        name="remark"
                                        value={objectivevalue.remark}
                                        onChange={(e) =>
                                            handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="Submit-section">
                        {isCustomVisible ? (
                            <>
                                <button
                                    className="cancel-btn"
                                    onClick={toggleCustomVisibility}
                                >
                                    Back
                                </button>
                                <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }} disabled={isWeightageButton}>Submit</button>
                            </>
                        ) : (
                            <>
                                <button className="cancel_red_btn" onClick={() => onClose}
                                    // disabled={isButtonDisabled} 
                                // disabled={clicked} 
                                >
                                    {/* {isCustomVisible ? "Submit" : "Cancel"} */}
                                    Cancel
                                </button>

                                <button className={
                                    objectivevalue.objectivename === "" ||
                                        0 === selectedMemValues.length ||
                                        cycleselectedOption === ""
                                        // ||objectivevalue.individualweightage === ""
                                        ? "cascade_button"
                                        : "cyan_button"
                                } onClick={() => { handleSubmit("3", false) }}
                                    disabled={isButtonDisabled}
                                // disabled={casclicked}
                                >{isCustomVisible ? "" : "Cascade"}</button>

                                <button className={
                                    objectivevalue.objectivename === "" ||
                                        0 === selectedMemValues.length ||
                                        cycleselectedOption === ""
                                        // ||objectivevalue.individualweightage === ""
                                        ? "cascade_button ml-1"
                                        : "cyan_button ml-1"
                                } onClick={() => { handleSubmit("1", false) }}
                                    disabled={isButtonDisabled}
                                // disabled={casclicked}
                                >{isCustomVisible ? "Submit" : "Save"}</button>
                            </>
                        )}
                    </div>
                </div>
            </Form>
            {/* </Drawer> */}
        </div>
    );
};

export default ChildCreateObj;
