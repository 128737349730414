import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
  Modal,
  message,
  Checkbox,
  Button,
  Tabs,
  Progress,
  Divider,
  Tooltip
} from "antd";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, RightOutlined, LeftOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
const { Option } = Select;
const { TabPane } = Tabs;
const percent = 70;

const onTabChange = (key: string) => {
  // console.log(key);
};

interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: string;
  usertarget: string;
  ImageUrl: string;
}
interface OptionItem {
  value: string;
  label: string;
  image: string;
  usertarget?: number;
}
interface Audittrails {
  id: string;
  modelname: string;
  newdata: string;
  olddata: string;
  status: number;
  createdat: string;
  toid: string[];
  oldtoidstring: string[];
  remark: string;
}
//modal props
type editkeyresults = {
  keytitle: string;
  description: string;
  remarks: string;
  quarter: string;
  createdbyempname: string;
  createdByEmpProfilePath: string;
  target: string;
  uom: string;
  amounttype: string;
  weightage: string;
  keyid: string;
  status: number;
  createdby: string;
  keyresultweightage: number;
  groupid: string[];
  checkInFrequency: string,
};
type editkeyresultusers = {
  id: string,
  username: string,
  Assigned: number,
  usertarget: number,
  Available: number | string,
  profilePath: string,
  signOffStatus: number,
};
interface krprops {
  // open: boolean;
  // onClose: () => void;
  // setOpen: (open: boolean) => void;
  objectivelist: () => void;
  getassignedkeyforobjectives: (selectedkrvalue: string, objectives: string, userId: string) => void;
  getteamkeyresult: (objectives: string, userId: string) => Promise<void>;
  objectivesdetailsgets: (card: any) => void;
  krid: string | undefined;
  krtitle: string | undefined,
  objname: string;
  objid: string;
  availableweightagekr: number
  year: string;
  orgdescription: string;
  objectivetype: string;
  objective: any;
  obj_code: string;
  krcode: string;
  checkInFrequency: string;
  uomType: string;
  objquarter: string;
  tabActiveKey: string;
  selectedkrvalue: string;
}
interface OptionItems {
  value: string;
  label: string;
  image: string;
  username: { name: any; id: any }[];

}
interface RolePermission {
  id: string;
  role: string;
  parent_objective: boolean;
  key_result: boolean;
  child_objective: boolean;
  org_objective: boolean;
  lead_measures: boolean;
  menu: string;
  userId: string;
  assigned_members: boolean;
  group: boolean;
}
const KRDescription: React.FC<krprops> = ({ objectivelist, objectivesdetailsgets, getassignedkeyforobjectives, getteamkeyresult, krid, krtitle, obj_code, objname, objid, availableweightagekr, year, orgdescription, objectivetype, objective, krcode, checkInFrequency, uomType, objquarter, tabActiveKey, selectedkrvalue }) => {

  interface createCheckIn {
    objectiveId: string | undefined;
    keyResultId: string | undefined;
    userId: string | undefined;
    target: number;
    checkInWeek: string,
    checkWeekCount: string,
    checkInStatus: number,
    achievedTarget: number;
    remainingTarget: number;
    uomType: string;
    amountType: string;
    completedTarget: number;
    customTarget: number;
  }
  interface CheckIn {
    id: string | undefined;
    objectiveId: string | undefined;
    keyResultId: string | undefined;
    userId: string | undefined;
    target: number;
    checkInWeek: string,
    checkWeekCount: string,
    checkInStatus: number,
    achievedTarget: number;
    remainingTarget: number;
    uomType: string;
    amountType: string;
    completedTarget: number;
    customTarget: number;
  }

  interface WeekOption {
    label: string;
    range: string;
    value: string;
  }

  interface CustomSelectProps {
    onChange: (value: string) => void;
    // selectedOption: WeekOption;
    selectedOption: string;
  }

  // const weekOptions: WeekOption[] = [
  //   { label: "Week 1", range: "MAY 24 - MAY 30", value: "1/13" },
  //   { label: "Week 2", range: "MAY 31 - APR 6", value: "2/13" },
  //   { label: "Week 3", range: "APR 7 - APR 13", value: "3/13" },
  //   { label: "Week 4", range: "APR 14 - APR 20", value: "4/13" },
  // ];


  const [objAudit, setObjAudit] = useState<Boolean[]>([]);
  const [memberAudit, setMemberAudit] = useState<Boolean[]>([]);

  const handleObjAudit = (index: number) => {
    setObjAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };
  const [audittrails, setaudittrails] = useState<Audittrails[]>([]);
  const handleMebAudit = (index: number) => {
    setMemberAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };

  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [cycleselectedOption, setCycleSelectedOption] = useState<string | null>(
    null
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWeightageButton, setIsWeightageButton] = useState(false);
  const [krShowCustom, setkrShowCustom] = useState(true);

  const handleShowHideCustom = () => {
    setkrShowCustom(!krShowCustom)
  }

  const [keyResultsValues, setkeyresultvalues] = useState<editkeyresults>({
    keytitle: "",
    description: "",
    remarks: "",
    quarter: "",
    createdbyempname: "",
    createdByEmpProfilePath: "",
    weightage: "",
    target: "",
    uom: "",
    amounttype: "",
    keyid: "",
    status: 0,
    createdby: "",
    keyresultweightage: 0,
    groupid: [],
    checkInFrequency: "",
  });
  const [selectedCurrency, setSelectedCurrency] = useState<string>("Thousands");
  const [keyResultsValuesedit, setkeyresultvaluesedit] =
    useState<editkeyresults>({
      keytitle: "",
      description: "",
      remarks: "",
      quarter: "",
      createdbyempname: "",
      createdByEmpProfilePath: "",
      weightage: "",
      target: "",
      uom: "",
      amounttype: selectedCurrency,
      keyid: "",
      status: 0,
      createdby: "",
      keyresultweightage: 0,
      groupid: [],
      checkInFrequency: "",
    });

  const [uomselectedOption, setUOMSelectedOption] =
    useState<string>("Percentage");


  var krschema = z.object({
    // keytitle: z.string().min(1, { message: 'Title field is required' }),
    // keyresultweightage: z.string().min(1, { message: 'keyresultweightage field is required' }),
    // description: z.string().min(1, { message: 'description field is required' }), 
    // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
    // band: z.string().min(1, { message: 'band field is required' }),
  });

  if (keyResultsValues.status === 2) {
    if (uomselectedOption !== "Logic") {
      krschema = z.object({
        keytitle: z.string().min(1, { message: 'Title field is required' }),
        //  keyresultweightage: z.string().min(1, { message: 'keyresultweightage field is required' }),
        target: z.string().min(1, { message: 'Target field is required' })
        // description: z.string().min(1, { message: 'description field is required' }), 
        // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
        // band: z.string().min(1, { message: 'band field is required' }),
      });
    }
    else {
      krschema = z.object({
        keytitle: z.string().min(1, { message: 'Title field is required' }),
        // keyresultweightage: z.string().min(1, { message: 'keyresultweightage field is required' }),
        // target: z.string().min(1, { message: 'Target field is required' })
        // description: z.string().min(1, { message: 'description field is required' }), 
        // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
        // band: z.string().min(1, { message: 'band field is required' }),
      });
    }

  }
  if (keyResultsValues.status == 1) {
    if (uomselectedOption !== "Logic") {
      krschema = z.object({
        keytitle: z.string().min(1, { message: 'Title field is required' }),
        keyresultweightage: z.string().min(1, { message: 'keyresult weightage field is required' }),
        target: z.string().min(1, { message: 'Target field is required' })
        // description: z.string().min(1, { message: 'description field is required' }), 
        // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
        // band: z.string().min(1, { message: 'band field is required' }),
      });
    }
    else {
      krschema = z.object({
        keytitle: z.string().min(1, { message: 'Title field is required' }),
        keyresultweightage: z.string().min(1, { message: 'keyresult weightage field is required' }),
        // target: z.string().min(1, { message: 'Target field is required' })
        // description: z.string().min(1, { message: 'description field is required' }), 
        // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
        // band: z.string().min(1, { message: 'band field is required' }),
      });
    }

  }
  // if (uomselectedOption !== "Logic") {
  //    krschema = z.object({
  //     keytitle: z.string().min(1, { message: 'Title field is required' }),
  //     keyresultweightage: z.string().min(1, { message: 'keyresultweightage field is required' }),
  //     target: z.string().min(1, { message: 'Target field is required' })
  //     // description: z.string().min(1, { message: 'description field is required' }), 
  //     // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
  //     // band: z.string().min(1, { message: 'band field is required' }),
  //   });
  // }

  const krschemasave = z.object({
    keytitle: z.string().min(1, { message: 'Title field is required' }),
    // band: z.string().min(1, { message: 'band field is required' }),
  });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  // const handleSubmit = () => {

  //   // Validate data against schema
  //   const validationResult = krschema.safeParse(keyResultsValuesedit);
  //   if (validationResult.success) {
  //     console.log("Data is valid:", keyResultsValues);
  //     setErrors(null);
  //     updatekeyresult()
  //   } else {
  //     const fieldErrors: { [key: string]: string } = {};
  //     validationResult.error.errors.forEach(error => {
  //       fieldErrors[error.path[0]] = error.message;
  //     });
  //     setErrors(validationResult.error.errors);
  //     console.log(validationResult)
  //   }
  // };

  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [customweightagedetails, setCustomWeightageDetails] = useState<DataSourceType[]>([]);
  const [targetValue, setTargetValue] = useState("100");
  const [currencytargetValue, setCurrencyTargetValue] = useState("");
  const [numerictargetValue, setNumericTargetValue] = useState("");
  const [customtargetValue, setCustomTargetValue] = useState("");
  const [showAchieved, setShowAchieved] = useState(false);
  const [achieved, setAchived] = useState(0);
  const [sumTarget, setSumTarget] = useState(0);
  let storeTarget: number = 0;
  let weightagePopupCount = 0;
  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < dataSource.length; index++) {
      // if (parseInt(keyResultsValuesedit.weightage) > parseInt(dataSource[index].Available) ||
      if (parseInt(dataSource[index].Assigned) > parseInt(dataSource[index].Available)) {
        const popupUser = {
          value: dataSource[index].Name,
          label: dataSource[index].Name,
          image: dataSource[index].ImageUrl !== null ? dataSource[index].ImageUrl : "",
        }
        weightagePopupCount++;
        greaterWeightageUser.push(popupUser);

      }
    }
    return greaterWeightageUser;
  }

  const handleSubmit = (status: number, sub: boolean) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }

    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = krschema.safeParse(keyResultsValuesedit);
    const validationsave = krschemasave.safeParse(keyResultsValuesedit);
    if (status == 2) {
      if (validationResult.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        updatekeyresult(status, sub)
        // // display the pop up weightage less than available 
        // const greaterWeightageUser: OptionItem[] = userAssignedWeightage();

        // if (weightagePopupCount === 0) {
        //   updatekeyresult(status, sub)
        // } else {
        //   weightagePopupCount === 1 ? handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, keyResultsValues.keytitle, "Key result") :
        //     handleproceed(greaterWeightageUser, keyResultsValues.keytitle, "Key result");
        // }

      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        updatekeyresult(status, sub)
        // window.location.reload();
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };

  const [userFrequency, setUserFrequency] = useState<string>("Monthly");
  const handleselectedCheckin = (value: string) => {
    setUserFrequency(value);
  }

  const [editmemberdetails, seteditedmemberdetails] = useState<string[]>([]);
  // const [keyResultsValuesedit,setkeyresultvaluesedit] = useState<editkeyresults>({
  //   keytitle:"",
  //   description:"",
  //   remarks:"",
  //   quarter:"",
  //   createdbyempname:"",
  //   weightage:"",
  //   target:"",
  //   Uom:"",
  //   amounttype:""
  //   })
  const [MemberValues, setMemberValues] = useState<OptionItem[]>([]);
  const [viewdata, setviewdata] = useState<DataSourceType[]>([]);
  const [checkboxChecked3, setCheckboxChecked3] = useState(false);
  const [krUserCheckIn, setKrUserCheckIn] = useState(false);
  const [showTeamChekin, setShowTeamChekin] = useState(true);
  const viewkeyresults = async (krid: string | undefined) => {
    try {
      const response = await axios.get(`${BaseUrl}api/keyresult/editkeyresult/${krid}`);
      const responsevalues = response.data.data;

      const keyvaluedatas: any = responsevalues.keyvalues;
      const editKeyResults: editkeyresults = {
        keytitle: keyvaluedatas.keytitle,
        description: keyvaluedatas.description,
        remarks: keyvaluedatas.remarks,
        quarter: keyvaluedatas.quarter,
        createdbyempname: keyvaluedatas.createdbyempname,
        createdByEmpProfilePath: keyvaluedatas.createdByEmpProfilePath,
        target: keyvaluedatas.target,
        uom: "",
        amounttype: keyvaluedatas.amounttype,
        weightage: keyvaluedatas.weightage,
        keyid: keyvaluedatas.keyid,
        status: parseInt(keyvaluedatas.status),
        createdby: keyvaluedatas.createdBy,
        keyresultweightage: keyvaluedatas.keyresultweightage,
        groupid: keyvaluedatas.groupid,
        checkInFrequency: keyvaluedatas.checkInFrequency,
      };
      setUOMSelectedOption(keyvaluedatas.uom);
      setSelectedCurrency(keyvaluedatas.amounttype);
      if (keyvaluedatas.uom === "Percentage") {
        setTargetValue(keyvaluedatas.target);
      } else if (keyvaluedatas.uom === "Currency") {
        setCurrencyTargetValue(keyvaluedatas.target);
      } else if (keyvaluedatas.uom === "Custom") {
        setCustomTargetValue(keyvaluedatas.target);
      } else {
        setNumericTargetValue(keyvaluedatas.target);
      }


      // setTargetValue(keyvaluedatas.target);
      const editkeysassignedusers: editkeyresultusers[] = responsevalues.keysassigned.map((item: any) => ({
        id: item.userid,
        username: item.username,
        Assigned: item.individualweightage,
        usertarget: item.target,
        Available: "",
        profilePath: item.profilePath,
        signOffStatus: item.signOffStatus,
      }));
      setkeyresultassignedusers(editkeysassignedusers);

      if (editkeysassignedusers.length === 1) {
        if (editkeysassignedusers[0].id === localStorage.getItem('loginId')) {
          setShowTeamChekin(false);
        }
      }

      const assignedUserExists = assignedusersvalues.some(kruser => kruser.id === localStorage.getItem('loginId'));
      if (assignedUserExists) {
        setKrUserCheckIn(true);
      }

      const datasourcevalues: DataSourceType[] = responsevalues.keysassigned.map((item: any) => ({
        key: item.id,
        Name: item.username,
        Available: item.keyuid,
        usertarget: item.target,
        Assigned: item.individualweightage,
        ImageUrl: item.profilePath
      }));
      const userid: string[] = responsevalues.keysassigned.map((user: { userid: string }) => String(user.userid));
      assigneduserkeywightage(userid, keyvaluedatas.weightage, keyvaluedatas.target)
      // setviewdata(datasourcevalues)
      setkeyresultvalues(editKeyResults)

    } catch (error) {
      // console.error(error)
    }

  }
  const getaudittrails = async () => {
    const datas: Boolean[] = [];
    try {
      if (krcode != "") {
        const apiUrl = BaseUrl + `api/auditrailremarks/getaudittrails/${krcode}`;
        await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }).then((response) => {
          response.data.data.forEach((element: any) => {
            datas.push(false);
          });
          setObjAudit(datas);
          setMemberAudit(datas);
          setaudittrails(response.data.data);
        })
      }
    } catch (error) {
      // console.log('Edit the Organization Objective', error);
      throw error;
    }
  }
  const [mydetails, setmydetails] = useState<OptionItem>();

  const handlecheckboxKRedit = () => {
    const checkboxElement = document.getElementById('includeMyselfCheckboxKRedit') as HTMLInputElement;
    if (checkboxElement) {
      const includeMyself = checkboxElement.checked;
      setCheckboxChecked3(!checkboxChecked3);
      if (includeMyself) {
        const localStorageOption: OptionItem = {
          value: localStorage.loginId,
          label: localStorage.name,
          image: profilePhoto
        };
        const userid: string[] = [];

        selectedMemValues.forEach((user) => {
          userid.push(user.value)
        })
        MemberValues.forEach((user) => {
          userid.push(user.value)
        })

        assignedusers.forEach((user) => {
          userid.push(user.value)
        })
        userid.push(localStorageOption.value)
        setmydetails(localStorageOption);
        assigneduserkeywightage(userid, keyResultsValuesedit.weightage, keyResultsValuesedit.target)

      } else {
        setmydetails(undefined)
        handleMemTagClose(localStorage.loginId);
      }
    }
  }
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const [assignedusersvalues, setkeyresultassignedusers] = useState<editkeyresultusers[]>([]);
  const editkeyresults = async () => {

    // const memvalue = Memoptions.filter((opt) =>
    //   assignedusersvalues.some((item) => item?.id === opt.value)
    //   );
    //   setSelectedMemValues(memvalue);
    const existingMemberList: OptionItem[] = [];

    const memvalues = assignedusersvalues.map((item) => ({
      label: item.username,
      value: item.id,
      image: item.profilePath ? FileUrl + item.profilePath : "assets/images/Default_user.svg"// "assets/images/user.svg"
    }));
    existingMemberList.push(...memvalues);

    // existingMemberList.map((resp) => {
    //   // if (resp.value === localStorage.getItem('loginId')) {
    //   //   setCheckboxChecked(true);
    //   // }

    //   // if (keyResultsValuesedit.status === 1) {
    //   //   setSelectedMemValues(existingMemberList);
    //   //   existingMemberList.map((user) => {
    //   //     // return user.value !== localStorage.loginId;+
    //   //     if (user.value === localStorage.getItem('loginId')) {
    //   //       setCheckboxChecked(true);
    //   //       // handleTagClose(user.value);
    //   //     }
    //   //     // else if (user.value !== localStorage.getItem('loginId')) {
    //   //     //   setCheckboxChecked(false);
    //   //     // }
    //   //   })
    //   // }
    //   // else {
    //   //   setMemberValues(existingMemberList);
    //   //   // existingMemberList.map((resp) => {
    //   //   //   setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
    //   //   //   userlist.push(resp.value)
    //   //   // })

    //   //   existingMemberList.map((user) => {
    //   //     // return user.value !== localStorage.loginId;+
    //   //     if (user.value === localStorage.getItem('loginId')) {
    //   //       setCheckboxChecked(true);
    //   //     }
    //   //     // else {
    //   //     //   setCheckboxChecked(false);
    //   //     // }
    //   //   })
    //   //   setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
    //   //   // MemberValues.map((unremove: OptionItem) => {
    //   //   //   handleMemTagClose(unremove.label + '*');
    //   //   // })
    //   // }

    // });.

    // if (keyResultsValues.status === 1) {
    //   setSelectedMemValues(existingMemberList);
    // } else {

    //   setMemberValues(existingMemberList);
    //   MemberValues.map((unremove: OptionItem) => {
    //     handleMemTagClose(unremove.label + '*');
    //   })
    // }
    const assignedforme: OptionItem[] = [];
    existingMemberList.map((resp) => {
      if (resp.value === localStorage.getItem('loginId')) {
        setCheckboxChecked3(true);
      }
    });


    if (assignedusersvalues.length !== 0) {
      setisSearchMem(true);
    }
    const userid = existingMemberList.map(user => user.value);
    // seteditedmemberdetails(userid);
    try {
      const response = await axios.get(`${BaseUrl}api/keyresult/editkeyresult/${krid}`);

      const responsevalues = response.data.data;
      const keyvaluedatas: any = responsevalues.keyvalues;
      setSelectedCurrency(keyvaluedatas.amounttype);
      const editKeyResults: editkeyresults = {
        keytitle: keyvaluedatas.keytitle,
        description: keyvaluedatas.description,
        remarks: keyvaluedatas.remarks,
        quarter: keyvaluedatas.quarter,
        createdbyempname: keyvaluedatas.createdbyempname,
        createdByEmpProfilePath: keyvaluedatas.createdByEmpProfilePath,
        target: keyvaluedatas.target,
        uom: uomselectedOption,
        amounttype: selectedCurrency,
        weightage: keyvaluedatas.weightage,
        keyid: keyvaluedatas.keyid,
        status: parseInt(keyvaluedatas.status),
        createdby: keyvaluedatas.createdBy,
        keyresultweightage: keyvaluedatas.keyresultweightage.toString(),
        groupid: keyvaluedatas.groupid,
        checkInFrequency: keyvaluedatas.checkInFrequency,
      };
      setUOMSelectedOption(keyvaluedatas.uom);

      if (keyvaluedatas.uom === "Percentage") {
        setTargetValue(keyvaluedatas.target);
      } else if (keyvaluedatas.uom === "Currency") {
        setCurrencyTargetValue(keyvaluedatas.target);
      } else if (keyvaluedatas.uom === "Custom") {
        setCustomTargetValue(keyvaluedatas.target);
      } else {
        setNumericTargetValue(keyvaluedatas.target);
      }
      // setTargetValue(keyvaluedatas.target);
      // handleUOMSelect(keyvaluedatas.uom);
      const editkeysassignedusers: editkeyresultusers[] = responsevalues.keysassigned.map((item: any) => ({
        id: item.userid,
        username: item.username,
        Assigned: item.individualweightage,
        usertarget: item.target,
        Available: "",
        profilePath: item.profilePath,//"assets/images/user.svg",
        signOffStatus: item.signOffStatus
      }));
      setkeyresultassignedusersedit(editkeysassignedusers);

      const assignedUserExists = assignedusersvalues.some(kruser => kruser.id === localStorage.getItem('loginId'));
      console.log(assignedUserExists);
      if (assignedUserExists) {
        setKrUserCheckIn(true);
      }


      const datasourcevalues: DataSourceType[] = responsevalues.keysassigned.map((item: any) => ({
        key: item.id,
        Name: item.username,
        Available: item.keyuid,
        usertarget: item.target,
        Assigned: item.individualweightage,
        ImageUrl: item.profilePath//"assets/images/user.svg"
      }));
      const userid: string[] = responsevalues.keysassigned.map((user: { userid: string }) => String(user.userid));
      assigneduserkeywightage(userid, keyvaluedatas.weightage, keyvaluedatas.target)
      // setviewdata(datasourcevalues)
      setkeyresultvaluesedit(editKeyResults)


      if (keyvaluedatas.groupid.length > 0) {
        const assigneduserdetails1: OptionItems[] = GrpOptions.filter(option => {
          const match = keyvaluedatas.groupid.includes(option.value);
          // Log each option value and its match result
          return match;
        });

        setisSearchMem(false);
        setSelectedGrpValues(assigneduserdetails1)
        setAssignedUsers(assigneduserdetails1)
      }
      else {
        editkeysassignedusers.map((resp) => {
          existingMemberList.map((user) => {
            if (user.value === resp.id) {
              user.usertarget = resp.usertarget;
            }
          })
        })
        setSelectedMemValues(existingMemberList);
      }

      let length = existingMemberList.length;
      storeTarget = (length) * Number(keyvaluedatas.target);
      setSumTarget(storeTarget);

    } catch (error) {
      // console.error(error)
    }
    setmydetails(assignedforme[0])
    // assigneduserkeywightage(userid)
  }

  const [assignedusersvaluesedit, setkeyresultassignedusersedit] = useState<editkeyresultusers[]>([])

  const assigneduserkeywightage = async (userid: string[] | undefined, individualweightage: string, target: string) => {
    try {
    const objectiveid: String = objid;
    const userdetails = await axios.post(BaseUrl + "api/keyresult/weighatgeassinedforedit", { objectiveid, userid, keyresultid: krid },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`
        },
      })
    const assigneduserdetails = userdetails.data.body;
    const responsevalues: DataSourceType[] = assigneduserdetails.map((item: any) => {
      const existingUser = dataSource.find((user: any) => user.key === item.id);
      if (existingUser) {
        return existingUser
      } else {
        return {
          key: item.id,
          Name: item.username,
          Available: item.assignedweightage,
          usertarget: item.target == 0 ? (target) : (item.target),
          Assigned: (item.availableweightage == 0 ? "" : item.availableweightage),
          ImageUrl: item.profilePath
        }
      }

    });
    const responsevaluesview: DataSourceType[] = assigneduserdetails.map((item: any) => {
      return {
        key: item.id,
        Name: item.username,
        Available: item.assignedweightage,
        usertarget: item.target === 0 ? (0) : (item.target),
        Assigned: item.availableweightage === 0 ? (0) : (item.availableweightage),
        ImageUrl: item.profilePath
      }
    });
    // assigneduserdetails.array.forEach(() => {
    //   validation.push(false)
    // });
    setdatasource(responsevalues);
    setviewdata(responsevaluesview);     
  } catch (error) {
    console.log(error);   
  }
  };
  const [checkin, setCheckin] = useState("");
  const [userFrequencyList, setUserFrequencyList] = useState<createCheckIn[]>([]);
  const [userFrequencyListTeam, setUserFrequencyListTeam] = useState<createCheckIn[]>([]);
  const [weekwiseFrequencyCount, setWeekwiseFrequencyCount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>('select the week');
  const [selectedTeamOption, setSelectedTeamOption] = useState<string>('');
  const [userCheckInWeek, setUserCheckInWeek] = useState<string>('');
  const [checkinMode, setCheckinMode] = useState<number>(1);
  const [checkinCreate, setCheckinCreate] = useState<createCheckIn>({
    "objectiveId": objid,
    "keyResultId": krid,
    "userId": localStorage.getItem("loginId") || '',
    "target": 0,
    "checkInWeek": userCheckInWeek === "" ? "Custom" : userCheckInWeek,
    "checkWeekCount": selectedOption === "select the week" ? "Custom" : selectedOption,
    "checkInStatus": 1,
    "achievedTarget": 0,
    "remainingTarget": 0, 
    "uomType": "",
    "amountType": "",
    "completedTarget": 0,
    "customTarget": checkinMode,
  });
  const [viewCheckIn, setViewCheckIn] = useState<CheckIn>();


  // const checkincreate = async () => {
  //   const response = await axios.post(BaseUrl + "api/checkin/create",
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${localStorage.accessToken}`
  //       },
  //     })

  //     const addedCheckinCreate = response.data;
  //     setCheckinCreate([...checkinCreate, addedCheckinCreate]);
  //     console.log("addedCheckinCreate" + addedCheckinCreate)
  //   // setCheckinCreate(responsevalues);
  // }

  const handleClick = (value: number) => {
    setCheckinMode(value);
    setCheckinCreate((prevState) => ({
      ...prevState,
      customTarget: value,
    }));
  };

  const handleChangeCustom = (value: number) => {
    setCheckinMode(value);
    setCheckinCreate((prevState) => ({
      ...prevState,
      customTarget: value,
    }));
  };

  const checkincreate = async () => {
    const apiUrl = BaseUrl + "api/checkin/create";
    // setCheckinCreate(({
    //   ...checkinCreate,
    //   target: parseInt(checkin),
    //   checkInWeek: userCheckInWeek,
    //   checkWeekCount: selectedOption,
    //   customTarget:checkinMode,
    // }));
    setCheckinCreate((prevState) => ({
      ...prevState,
      target: parseInt(checkin),
      checkInWeek: userCheckInWeek,
      checkWeekCount: selectedOption,
      customTarget: checkinMode,
    }));
    try {
      const response = await axios.post(apiUrl, checkinCreate, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(response.data.data);
      const addedCheckinCreate: createCheckIn = response.data.data;
      // setUserFrequencyList([checkinCreate, addedCheckinCreate]);
      setCheckin("");
      checkInList(krid);
      weekWiseList(krid, selectedOption);

    } catch (error) {
      console.error("Error sending message:", error);
    }
  };


  const updateCheckin = async () => {
    // const value=achieved===0?checkinMode:achieved;
    let value;
    if (viewCheckIn?.uomType === "Custom") {
      value = checkinMode;
    } else {
      value = achieved;
    }
    const checkInId: string | undefined = viewCheckIn?.id
    const apiUrl = BaseUrl + "api/checkin/update/" + checkInId;
    try {
      const response = await axios.put(apiUrl, value, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      setAchived(0);
      setShowAchieved(false);
      setCheckin("");
      checkInList(krid);
      weekWiseList(krid, selectedOption);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const [weekOptions, setWeekOptions] = useState<WeekOption[]>([]);
  const [currentmonth, setcurrentmonth] = useState<String>("");
  const [currentdata, setcurrentdate] = useState<number>(0);
  const [restictionindates, setrestriction] = useState<Boolean>(false);
  const krCheckInFrequnency = async () => {

    try {
      let today: Date = new Date();
      let months = ["Jan", "feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      const formData = new FormData();
      formData.append("currentMonth", today.getMonth().toString());
      formData.append("year", today.getFullYear().toString());
      formData.append("frequency", checkInFrequency);

      const apiUrl = BaseUrl + `api/checkin/date/list`;
      // console.log("sdfdsfdsfsdfsdf"+today.getUTCMonth().toString()+today.getUTCDate());
      setcurrentmonth(months[today.getMonth()]);
      setcurrentdate(today.getUTCDate());
      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        console.log(response.data.data);
        const krCheckInList: WeekOption[] = [];
        response.data.data.map((krCheckIn: string, index: number) => {
          const krCheckInUser: WeekOption = {
            label: "Week " + (index + 1),
            range: krCheckIn,
            value: (index + 1) + '/' + response.data.data.length,
          }
          krCheckInList.push(krCheckInUser);
          console.log(krCheckInUser);

        })
        // setSelectedOption((1) + '/' + response.data.data.length);
        setSelectedTeamOption((1) + '/' + response.data.data.length);
        setWeekOptions(krCheckInList);
        setUserCheckInWeek(krCheckInList[0].range);
      })
    } catch (error) {
      console.log(error);
    }
  }




  const [dataSource, setdatasource] = useState<DataSourceType[]>([]);
  const [validation, setmaxvalidation] = useState<Boolean[]>([])
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const name = e.target.name as keyof DataSourceType;
    const value = e.target.value;
    const updatedDataSource = [...dataSource];
    updatedDataSource.forEach((item, i) => {
      if (i === index && Object.keys(item).includes(name)) {
        updatedDataSource[i][name] = value;
      }
    });
    if (name == "Assigned") {
      // if(value>dataSource[index].Available){
      //   validation[index]=true;
      // }
      setkeyresultvaluesedit({
        ...keyResultsValuesedit,
        weightage: "customized"
      });
    }
    if (name == "usertarget") {
      // if(value>keyResultsValues.target){
      //   validation[index]=true;
      // }
      // else{
      validation[index] = false;
      // }

    }

    setdatasource(updatedDataSource);
  }

  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { name, value } = e.target;

    const updatedSelectedMemValues = selectedMemValues.map(item => {
      if (item.value === itemId) {
        return { ...item, [name]: Number(value) };
      }
      return item;
    });
    setSelectedMemValues(updatedSelectedMemValues);

    // Update the assignWeightage for the specific item
    const updatedDataSource = dataSource.map(items => {
      if (items.key === itemId) {
        return { ...items, [name]: value };
      }
      return items;
    });
    setdatasource(updatedDataSource);

    if (name == "Assigned") {
      // if(value>dataSource[index].Available){
      //   validation[index]=true;
      // }
      setkeyresultvaluesedit({
        ...keyResultsValuesedit,
        weightage: "customized"
      });
    }

    //if (name === "target") {
    //     // Validate the "target" value
    //     if (parseInt(keyvalues.target) < parseInt(value)) {
    //       updatedValidation[itemId] = true;
    //     } else {
    //       updatedValidation[itemId] = false;
    //     }
    //   }
    // Update the state with the modified array

  };


  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (text: string, record: any, index: number) => (
        <span className="align-center">
          <img src={record.ImageUrl ? FileUrl + record.ImageUrl : "assets/images/Default_user.svg"} alt="" onError={handleImageError} className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    // {
    //   title: "Weightage Available",
    //   dataIndex: "Available",
    //   key: "Available",
    //   render: (text: string, record: any) => (
    //     <span className={parseInt(record.Available) < record.Assigned ? "c_FF4747" : ""}>{parseInt(record.Available) < 0 ? (0) : (record.Available)} %</span>
    //   ),
    // },
    {
      title: "Weightage Assigned",
      dataIndex: "Assigned",
      key: "Assigned",
      render: (text: string, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            // type="text" 
            // className="assigned_bg"
            className={parseInt(record.Available) < record.Assigned ? "assigned_bg_error" : "assigned_bg"}
            value={record.Assigned}
            name="Assigned"
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                // handleInputChange(e, index);
                handleAssignWeightage(e, record.key);
              }
            }}
            disabled={record.key == localStorage.loginId && objectivetype != "orgobjective"}
            // onChange={(e)=>{ handleInputChange(e,index)}}
            readOnly={isCustomVisible ? (false) : (true)}
          />
          <span className="suffix">% </span>
        </div>
      ),
    },


  ];
  if (uomselectedOption !== 'Custom' && keyResultsValuesedit.uom !== 'Custom') {
    columns.push({
      title: "Target",
      dataIndex: "usertarget",
      key: "usertarget",
      render: (text: string, record: any, index: number) => (
        <div>
          <div className="input-with-suffix">
            <input
              // type="number" 
              style={{ width: selectedCurrency === 'Thousands' ? '125px' : '75px' }}
              className={`target_bg ${keyResultsValuesedit.uom === "Currency" ? "" : ""}`}
              name="usertarget"
              value={record.usertarget}
              onChange={(e) => {
                const inputVal = e.target.value;
                const numericRegex = /^[0-9]*$/;
                if (keyResultsValuesedit.uom === "Percentage" || uomselectedOption === "Percentage") {
                  if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                    handleAssignWeightage(e, record.key);
                  }
                }
                // else if (keyResultsValuesedit.uom === "Custom" || uomselectedOption === "Custom") {
                //   handleAssignWeightage(e, record.key);
                // }
                else {
                  if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                    handleAssignWeightage(e, record.key);
                  }
                }
              }}
              readOnly={isCustomVisible ? false : true}
            />
            {keyResultsValuesedit.uom === "Percentage" || keyResultsValues.uom === "Percentage" ? <span className="suffix">%</span> : keyResultsValuesedit.uom === "Currency" || keyResultsValues.uom === "Currency" ?
              <span className="suffix " style={{ left: "30%" }}>{selectedCurrency}</span> : <span className="suffix"></span>}
          </div>
          {validation[index] && (
            <div>
              <span className="danger">must be lesser than given target</span>
            </div>
          )}
        </div>
      ),
    });
  } else {
    columns.filter(value => value.title !== 'Target')
  }

  const custom_filteredData = dataSource.filter((record: DataSourceType) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
  const [keyweighatgevalues, setkeyweightagevalues] = useState<{ weightage: number, target: number }>(
    {
      weightage: 0,
      target: 0
    }
  )

  const [TMoptions, setTMoptions] = useState<User[]>([]);

  var localStorageOptionsss: OptionItem;
  const handleMemSelectChange = (selected: OptionItem[]) => {

    if (checkboxChecked3) {
      localStorageOptionsss = {
        value: localStorage.loginId,
        label: localStorage.name,
        image: profilePhoto
      };
    }
    setmydetails(localStorageOptionsss)
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );

    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    // userid.push(...editmemberdetails);
    if (localStorageOptionsss != undefined) {
      userid.push(localStorageOptionsss.value)
    }
    assigneduserkeywightage(userid, keyResultsValuesedit.weightage, keyResultsValuesedit.target);
    setSelectedMemValues(selectedMemValues);
    selected.map(user => {
      if (user.value === localStorage.getItem("loginId")) {
        setCheckboxChecked3(true);
      }
    })
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(keyResultsValues.target);
    setSumTarget(storeTarget);

    // checkboxChecked ? setCheckboxChecked(true) : setCheckboxChecked(false);
  };



  // const handleMemTagClose = (valueToRemove: string) => {
  //   const updatedMemValues = selectedMemValues.filter(
  //     (value) => value.value !== valueToRemove
  //   );
  //   setSelectedMemValues(updatedMemValues);
  // };
  const handleMemTagClose = (valueToRemove: string) => {
    // if (valueToRemove.charAt(valueToRemove.length - 1) !== '*') {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
    // }
    const updateddatasource = dataSource.filter(
      (value) => value.key !== valueToRemove
    );
    const userid: string[] = updateddatasource.map((user) => {
      return String(user.key)
    })
    seteditedmemberdetails(userid)
    // assigneduserkeywightage(userid);
    setdatasource(updateddatasource)
    // if(keyResultsValuesedit.status==2){

    // }

    let length = updatedMemValues.length;
    if (uomselectedOption === "Percentage") {
      storeTarget = (length) * Number(targetValue);
      setSumTarget(storeTarget);
    } else if (uomselectedOption === "Currency") {
      storeTarget = (length) * Number(currencytargetValue);
      setSumTarget(storeTarget);
    } else {
      storeTarget = (length) * Number(numerictargetValue);
      setSumTarget(storeTarget);
    }
  };

  // Roles and permission 
  // =================Roles And Permission==============================
  const [permission_kredit, setPermission_kredit] = useState('');
  const [permission_krdelete, setPermission_krdelete] = useState('');
  const [permission_krview, setPermission_krview] = useState('');
  // =================Roles And Permission==============================
  const GetUserAccess = async () => {

    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission) as RolePermission[];
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "Edit") {
          setPermission_kredit(permission.key_result)
        }
        if (permission.menu === "Delete") {
          setPermission_krdelete(permission.key_result)
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const checkInList = async (krid: string | undefined) => {
    try {
      const formData = new FormData();
      formData.append("userId", localStorage.getItem("loginId") || '');
      formData.append("krId", krid || '');

      const apiUrl = BaseUrl + `api/checkin/kr/list`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setUserFrequencyList(response.data.data);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const checkInTeamList = async (userId: string, krid: string | undefined) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("krId", krid || '');

      const apiUrl = BaseUrl + `api/checkin/kr/list`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setUserFrequencyListTeam(response.data.data);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const weekWiseList = async (krid: string | undefined, week: string) => {
    try {
      const weekType = week === "select the week" ? "Custom" : week;
      console.log(weekType);
      const formData = new FormData();
      formData.append("userId", localStorage.getItem("loginId") || '');
      formData.append("krId", krid || '');
      formData.append("weekCount", weekType);

      const apiUrl = BaseUrl + `api/checkin/assign/user/kr`;

      await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setViewCheckIn(response.data.view)
        setWeekwiseFrequencyCount(response.data.data);
      })
    } catch (error) {
      console.log(error);
    }
  }
  // Roles and permission
  useEffect(() => {
    userslist();
    GetUserAccess();
    viewkeyresults(krid);
    getaudittrails();
    groupuserslist();
    getWordCount();
    //userRequestSignOffStatus();
    // krCheckInFrequnency();
    userLists();
    checkInList(krid);
  }, [krid, checkin, checkinMode, year, objquarter]);

  const handleTagClosegrp = (valueToRemove: string) => {
    const updatedGrpValues = assignedusers.map(value => ({
      ...value,
      username: value.username.filter(user => user.id !== valueToRemove)
    }));
    setAssignedUsers(updatedGrpValues);
    const updatedMemValues1 = dataSource.filter(
      (value) => value.key !== valueToRemove
    );
    const userid: string[] = updatedMemValues1.map((user) => {
      return String(user.key)
    })
    seteditedmemberdetails(userid)
    setdatasource(updatedMemValues1);

  };

  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([
  ]);
  interface group {
    id: string,
    groupname: string,
    assignedusers: string[]
  }
  const groupuserslist = async () => {
    try {
      const groupslist = await axios.get(BaseUrl + "api/group/users_group",
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`
          }
        });
      const groupusersdatas: group[] = groupslist.data;
      const newoptions = groupusersdatas.map((id) => ({
        value: id.id,
        label: id.groupname,
        image: "",
        weightage: 0,
        username: id.assignedusers.map((userString: any) => {
          return {
            name: userString.name,
            id: userString.userid,
          };
        }), // Assuming you have an image property for each user
      }));
      setGrpOptions(newoptions);
    } catch (error) {

    }

  }
  const handleGrpSelectChange = (selected: OptionItems[]) => {

    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    const filteredEditMemberDetails = editmemberdetails.filter(val =>
      !selectedUserIds.includes(val)
    );
    seteditedmemberdetails(filteredEditMemberDetails)
    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues)

    editmemberdetails.push(...selectedUserIds);

    const selectedgrpids = selectedGrpValues.map(user => user.value)
    setkeyresultvaluesedit({
      ...keyResultsValuesedit,
      groupid: selectedgrpids
    }
    )
    assigneduserkeywightage(editmemberdetails, keyResultsValuesedit.weightage, keyResultsValuesedit.target);
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };
  const handleTagClose = (valueToRemove: string) => {
    // if (mydetails?.value == valueToRemove) {
    //   const checkboxElement = document.getElementById('includeMyselfCheckbox') as HTMLInputElement;
    //   checkboxElement.removeAttribute('checked');
    //   setmydetails(undefined)
    //   // setCheckboxChecked(false);
    // }
    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    setSelectedGrpValues(updatedGrpValues);
    handleGrpSelectChange(updatedGrpValues)
  };
  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );

  const [Memoptions, setMemOptions] = useState<OptionItem[]>([
  ]);

  Memoptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });


  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage and View Weightage  hide and show
  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [ViewWeightage, setViewWeightage] = useState(false);
  const [isSearchMem, setisSearchMem] = useState(true);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    if (isSearchMem == false) {
      setAssignedUsers([]);
      const filteredUsers = dataSource.filter(user => {
        // Checking if any username value in assignedusers matches the user's id
        return !assignedusers.some(assignedUser => {
          // Assuming assignedUser.username is an array of objects with a value property
          return assignedUser.username.some(username => username.id === user.key);
        });
      });
      // setShowCustomWeightage([]);
      setdatasource(filteredUsers)

    }
    else {
      setSelectedMemValues([]);
      setSelectedGrpValues([]);
      setCheckboxChecked3(false);
      setmydetails(undefined);
      const filteredUsersmembers = dataSource.filter(user => {
        // Checking if the user's ID is not present in assignedusers
        return !selectedMemValues.some(assignedUser => assignedUser.value === user.key);
      });
      setdatasource(filteredUsersmembers);
    }
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
    // const userid: string[] = assignedusersvalues.map(items => (items?.id ?? ''));
    // assigneduserkeywightage(userid)
  };
  const [open, setOpen] = useState(false);
  const toggleCustomVisibilitys = () => {
    setIsCustomVisible(!isCustomVisible);
    setOpen(!open)
  };
  const toggleViewWeightage = () => {
    setViewWeightage(!ViewWeightage);
    // const userid: string[] = assignedusersvalues.map(items => (items?.id ?? ''));
    // assigneduserkeywightage(userid)
  };
  const ViewWeightageBack = () => {
    setViewWeightage(false);
  }

  const { TextArea } = Input;

  // work count start
  const [objectiveText, setObjectiveText] = useState(
    "Lorem ipsum dolor sit amet"
  );
  const [descriptionText, setDescriptionText] = useState(
    "Lorem ipsum dolor sit amet"
  );
  const [remarkText, setRemarkText] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
  );
  const [OrgObjectives, setorgobjectivetext] = useState({
    objective: "dhjfdsbdjshbfds dshfjbdsjhfb jhdsfbj",
    description:
      "This approach is often a part of a balanced scorecard framework, which balances financial objectives with non-financial aspects.",
    cycleselectionoption: cycleselectedOption,
    assignedmembers: selectedMemValues,
    individualweightage: 80,
    remarks: "",
  });
  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };

  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  });
  const [keyTitle, setKeyTitle] = useState('');
  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Key Result");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  }
  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    // Check if the input exceeds the maximum word count
    if (newText.split(/\s+/).join("").length <= maxWords) {
      if (keyResultsValuesedit && Object.keys(keyResultsValuesedit).includes(event.target.name)) {
        setkeyresultvaluesedit(prevState => ({
          ...prevState,
          [event.target.name]: event.target.value,
        }));
      }
    }
    // if (event.target.name == "weightage") {
    //   assigneduserkeywightage(editmemberdetails, event.target.value, keyResultsValuesedit.target);
    // }
    if (event.target.name === 'target' && keyResultsValuesedit.uom === 'Percentage') {
      setTargetValue(event.target.value);
      const updatedSelectedMemValues = selectedMemValues.map((items) => ({
        ...items,
        usertarget: Number(event.target.value),
      }));
      setSelectedMemValues(updatedSelectedMemValues);
      const updatedUserDetails = dataSource.map((item) => ({
        ...item,
        usertarget: event.target.value,
      }));
      setdatasource(updatedUserDetails);
      setkeyresultvaluesedit({
        ...keyResultsValuesedit,
        target: event.target.value
      });
    }
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  };

  const addorgobjective = () => {
    // alert("hello")
    // onClose();
    Modal.confirm({
      title: "Do you want to Cascade this keyresult ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">
            “ Drive Automation, Digital & Execution Excellence ”
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Post cascading, you can modify the description, update
            remarks, add members, and customize weightage
          </p>
        </>
      ),
      okText: "Cascade",
      cancelText: "Back",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        // console.log("Cascade action confirmed");
      },
      onCancel() {
        // console.log("Cascade action cancelled");
      },
    });
  };
  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      //const users = response.data; // Assuming response.data is an array of user objects
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? FileUrl + id.profilePath : "assets/images/Default_user.svg"
        };
      });
      setMemOptions(newOptions);
      // setMemOptions((prevMemOptions) => [
      //   ...prevMemOptions,
      //   { value: response.data.id, label: response.data.name, image: "" },
      // ]);

    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  // work count end
  const deletekeyresult = async () => {
    try {
      const apiUrl = `${BaseUrl}api/keyresult/delete/${krid}`;
      await axios.put(apiUrl, {
      }).then((response) => {
        getassignedkeyforobjectives(selectedkrvalue, objid, localStorage.loginId);
        // console.log(response.data.data);

      })
    } catch (error) {

    }


    //window.location.reload();
  }
  const handleDeleteKR = (kr: any) => {
    Modal.confirm({
      title: "Do you want to delete this keyresult ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr}
            {/* “ Achieve a 20% reduction in average server response time. ” */}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deletekeyresult();
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                KeyResult deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        // window.location.reload();
        onClose()
        setTimeout(() => {
          //  objectivelist();
          objectivesdetailsgets(objective);
          // window.location.reload();
        }, 1000);
      },
      onCancel() {
        // console.log("Delete action cancelled");
      },
    });
  };


  const [readOnly, setReadOnly] = useState(true);
  const handleClickEdit = () => {
    setReadOnly(false); // remove readonly
    editkeyresults()
    viewkeyresults(krid);
    getaudittrails();
    checkInList(krid);
  };
  const openAndEditKr = () => {
    setOpen(true);

    setReadOnly(false); // remove readonly
    editkeyresults()
  };
  const krdec_cancel = () => {
    setReadOnly(true);
  };

  const items: MenuProps["items"] = [

    permission_kredit ? {
      label: (
        <Row onClick={handleClickEdit} className="p-0">
          {/* <button
          className="border-0 bg-transparent fs-14 align-center"
          onClick={handleClickEdit}
        > */}
          {krtitle !== "" ?
            (<>
              <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
              Edit
            </>) : (<>
              <p className="fs-16 c-000 fw-600 m-0 p-0 cur-pointer mt-2 c_32B7D9" >
                {krtitle}
              </p>
            </>)}
          {/* <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
          Edit */}
          {/* </button> */}
        </Row>
      ),
      key: "0",
    } : null,

    permission_krdelete ? {
      label: (
        <Row
          className="c_FF4747 fs-14 p-0"
          onClick={() => handleDeleteKR(keyResultsValuesedit.keytitle)}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: "1",
    } : null
  ];



  const [selectedValues, setSelectedValues] = useState<OptionItem[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const onClose = () => {
    getassignedkeyforobjectives(selectedkrvalue, objid, localStorage.loginId);
    setOpen(false);
    setTextClicked(false);
    if (dataSource.some(user => user.key == localStorage.loginId)) {
      setmydetails(undefined)
      setCheckboxChecked3(false)

    }
    // window.location.reload();
  };
  const handleCurrencySelect = (value: string) => {
    setSelectedCurrency(value);
    setkeyresultvaluesedit(prevState => ({
      ...prevState,
      amounttype: value
    }));
  };
  const showDrawer = () => {
    setOpen(true);
    krCheckInFrequnency();
    weekWiseList(krid, selectedOption);
  };
  function openModalAndEditObjective() {
    showDrawer();
    setTextClicked(true);
    editkeyresults();
    if (krtitle == "") {
      setReadOnly(false)
    }
    else {
      setReadOnly(true)
    }

  }
  const handleUOMSelect = (value: string) => {
    if (value === "Currency") {
      setUOMSelectedOption(value);
      currencytargetValue === "" ? setCurrencyTargetValue("") : setCurrencyTargetValue(currencytargetValue);
      setkeyresultvaluesedit((prevState) => ({
        ...prevState,
        target: currencytargetValue,
        uom: value
      }));
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: currencytargetValue,
        }))
      );
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: currencytargetValue,
        }))
      );
    } else if (value === "Numeric") {
      setUOMSelectedOption(value);
      numerictargetValue === "" ? setNumericTargetValue("") : setNumericTargetValue(numerictargetValue);
      setkeyresultvaluesedit((prevState) => ({
        ...prevState,
        target: numerictargetValue,
        uom: value
      }));
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: numerictargetValue,
        }))
      );
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: numerictargetValue,
        }))
      );
    } else if (value === "Custom") {
      setUOMSelectedOption(value);
      customtargetValue === "" ? setCustomTargetValue("") : setCustomTargetValue(customtargetValue);
      setkeyresultvaluesedit((prevState) => ({
        ...prevState,
        target: customtargetValue,
        uom: value
      }));
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: customtargetValue,
        }))
      );
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: customtargetValue,
        }))
      );
    } else {
      setUOMSelectedOption(value);
      targetValue === "" ? setTargetValue("100") : setTargetValue(targetValue);
      setkeyresultvaluesedit((prevState) => ({
        ...prevState,
        target: targetValue,
        uom: value
      }));
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: targetValue,
        }))
      );
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: targetValue,
        }))
      );
    }


    // if (value === "Logic") {
    //   setkeyresultvaluesedit(prevState => ({
    //     ...prevState,
    //     uom: value,
    //     target: "" // Set target to empty string if uom is "Login"
    //   }));
    // }
    // else {
    //   setkeyresultvaluesedit(prevState => ({
    //     ...prevState,
    //     uom: value,
    //   }));
    //   setUOMSelectedOption(value);
    // }
  };

  const handleNumeric = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setNumericTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      usertarget: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    const updatedSelectedMemValues = selectedMemValues.map((data) => ({
      ...data,
      usertarget: Number(event.target.value),
    }));
    setSelectedMemValues(updatedSelectedMemValues);
    setkeyresultvaluesedit((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleCustom = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setCustomTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      target: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    setkeyresultvaluesedit((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleCurrency = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setCurrencyTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      usertarget: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    const updatedSelectedMemValues = selectedMemValues.map((data) => ({
      ...data,
      usertarget: Number(event.target.value),
    }));
    setSelectedMemValues(updatedSelectedMemValues);
    setkeyresultvaluesedit((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleUOMRadio = (e: RadioChangeEvent) => {
    // setUOMSelectedOption(e.target.value);
    setkeyresultvalues(prevState => ({
      ...prevState,
      uom: e.target.value
    }));
  };
  type keyassigned = {
    userid: string,
    username: string,
    individualweightage: number,
    target: number,
  }

  const updatekeyresult = async (status: number, submitmodify: boolean) => {
    const usersweightage = dataSource.filter(user => user.key != localStorage.loginId)
    // if (selectedMemValues.length == 0 && assignedusers.length == 0 && mydetails==undefined) {
    //   Modal.error({
    //     content: (
    //       <>
    //         <p className="fs-16 mb-3">{keyResultsValuesedit.keytitle}</p>
    //         <p className="fs-18"><b>Error : </b>Asingees Or CFT Is Mandatory</p>
    //       </>
    //     ),
    //     centered: true,
    //   })
    // }
    var text;
    if (status == 1) {
      text = "Save"
    }
    else {
      text = "Cascade"
    }
    if (status == 2) {
      // if (keyvaluesweightage - keyResultsValuesedit.keyresultweightage != 0 && availableweightagekr != 0) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{keyResultsValuesedit.keytitle}</p>
      //         <p className="fs-18"><b>Note : </b>You can only save the key result but  cannot be cascaded since the KR weightage should be equal to 100%</p>
      //       </>
      //     ),
      //     centered: true,
      //   });
      // }
      if (selectedMemValues.length == 0 && mydetails == undefined && assignedusers.length == 0) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{keyResultsValuesedit.keytitle}</p>
              <p className="fs-18"><b>Error :</b> Assignees Or CFT Is Mandatory </p>
            </>
          ),
          centered: true,
        })
      }
      else if (uomselectedOption !== "Logic") {
        if (usersweightage.some(user => user.usertarget == "")) {
          Modal.error({
            content: (
              <>
                <p className="fs-16 mb-3 keytitle_scroll">{keyResultsValuesedit.keytitle}</p>
                <p className="fs-18"><b>Error : </b>Weightage for the member is required</p>
              </>
            ),
            centered: true,
          })

        }
        // else if (keyvaluesweightage - keyResultsValuesedit.keyresultweightage != 0) {
        //   Modal.error({
        //     content: (
        //       <>
        //         <p className="fs-16 mb-3">{keyResultsValuesedit.keytitle}</p>
        //         <p className="fs-18"><b>Note : </b>You can only save the key result but cannot be cascaded since the KR weightage should be equal to 100%</p>
        //       </>
        //     ),
        //     centered: true,
        //   });
        // }
        else {
          Modal.confirm({
            title: 'Do you want to ' + text + ' this  keyresult ?',
            content: (
              <>
                <p className="fs-16 mb-3">{keyResultsValues.keytitle}</p>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>
            ),
            okText: text,
            cancelText: 'Back',
            okButtonProps: { className: 'C_btn' },
            cancelButtonProps: { className: 'outline_btn' },
            centered: true,
            async onOk() {
              try {
                const valuesassigned: keyassigned[] = dataSource.map(data => {
                  let target;
                  if (uomselectedOption === "Logic") {
                    target = 0;
                  } else {
                    target = parseFloat(data.usertarget);
                  }

                  return {
                    userid: String(data.key),
                    username: data.Name,
                    individualweightage: parseFloat(data.Assigned),
                    target: target
                  };
                });
                // if (uomselectedOption === "Logic") {
                //   alert("hello")
                //   setkeyresultvalues(prevState => ({
                //     ...prevState,
                //     target: "" 
                //   }));
                // }

                const updatekeyresult = await axios.put(`${BaseUrl}api/keyresult/update/${krid}`, {
                  keyvalues: keyResultsValuesedit, keysassigned: valuesassigned, keyresultstatus: status
                },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.accessToken}`
                    }
                  }
                )
                message.success({
                  content: (
                    <div className="success-message justify-center align-center p-1">
                      <img src="assets/images/check-circle.svg" alt="Success Icon" />
                      <span className="c_32C6A0 fs-16">
                        Keyresult Updated Successfully.
                      </span>
                    </div>
                  ),
                  duration: 3,
                  icon: null,
                });
                if (submitmodify == false) {
                  onClose();
                  console.log("wdwdwd" + selectedkrvalue);
                  // window.location.reload();
                  setTimeout(() => {
                    getassignedkeyforobjectives(selectedkrvalue, objid, localStorage.loginId);
                    //  window.location.reload();
                  }, 1000);
                }
                else {
                  toggleCustomVisibility()
                }

              } catch (error) {
                // console.log(error)
              }

              // console.log('Cascade action confirmed');
            },
            onCancel() {
              setClicked(false);
              setCasClicked(false);
              // console.log('Cascade action cancelled');
            },
          });
        }
      }
      else if (usersweightage.some(user => user.usertarget == "")) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3">{keyResultsValuesedit.keytitle}</p>
              <p className="fs-18"><b>Error : </b>Weightage for the member is required</p>
            </>
          ),
          centered: true,
        })
      }
      else {
        Modal.confirm({
          title: 'Do you want to ' + text + ' this keyresult ?',
          content: (
            <>
              <p className="fs-16 mb-3">{keyResultsValues.keytitle}</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          async onOk() {
            try {
              const valuesassigned: keyassigned[] = dataSource.map(data => {
                let target;
                if (uomselectedOption === "Logic") {
                  target = 0;
                } else {
                  target = parseFloat(data.usertarget);
                }

                return {
                  userid: String(data.key),
                  username: data.Name,
                  individualweightage: parseFloat(data.Assigned),
                  target: target
                };
              });
              // if (uomselectedOption === "Logic") {
              //   alert("hello")
              //   setkeyresultvalues(prevState => ({
              //     ...prevState,
              //     target: "" 
              //   }));
              // }

              const updatekeyresult = await axios.put(`${BaseUrl}api/keyresult/update/${krid}`, {
                keyvalues: keyResultsValuesedit, keysassigned: valuesassigned, keyresultstatus: status
              },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`
                  }
                }
              )
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">
                      Keyresult Updated Successfully.
                    </span>
                  </div>
                ),
                duration: 3,
                icon: null,
              });

              // window.location.reload();
              if (submitmodify == false) {
                onClose();
                // alert("2")
                setTimeout(() => {
                  getassignedkeyforobjectives(selectedkrvalue, objid, localStorage.loginid);
                  // window.location.reload();
                }, 1000);
              }
              else {
                toggleCustomVisibility()
              }

            } catch (error) {
              // console.log(error)
            }

            // console.log('Cascade action confirmed');
          },
          onCancel() {
            setClicked(false);
            setCasClicked(false);
            // console.log('Cascade action cancelled');
          },
        });
      }
    }
    else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
      Modal.confirm({
        title: 'Do you want to ' + text + ' this  keyresult?',
        content: (
          <>
            <p className="fs-16 mb-3">{keyResultsValues.keytitle}</p>
            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          </>
          // <>
          //   <p className="fs-16 mb-3">{keyResultsValues.keytitle}</p>
          //   {greaterWeightageUser.length === 0 ? (
          //     <>
          //       <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //     </>
          //   ) : greaterWeightageUser.length === 1 ? (
          //     <>
          //       <Row>
          //         <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
          //           <div className='text-center'>
          //             <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
          //             <p>{greaterWeightageUser[0].value}</p>
          //           </div>
          //         </Col>
          //         <Col xl={18} lg={18} md={18} sm={16} xs={24}>
          //           <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
          //           <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //         </Col>
          //       </Row>
          //     </>
          //   ) : (
          //     <>
          //       <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
          //       <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //     </>)}
          // </>
        ),
        okText: text,
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        async onOk() {
          try {
            const valuesassigned: keyassigned[] = dataSource.map(data => {
              let target;
              if (uomselectedOption === "Logic") {
                target = 0;
              } else {
                target = parseFloat(data.usertarget);
              }

              return {
                userid: String(data.key),
                username: data.Name,
                individualweightage: parseFloat(data.Assigned),
                target: target
              };
            });
            // if (uomselectedOption === "Logic") {
            //   alert("hello")
            //   setkeyresultvalues(prevState => ({
            //     ...prevState,
            //     target: "" 
            //   }));
            // }

            const updatekeyresult = await axios.put(`${BaseUrl}api/keyresult/update/${krid}`, {
              keyvalues: keyResultsValuesedit, keysassigned: valuesassigned, keyresultstatus: status
            },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.accessToken}`
                }
              }
            )
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Keyresult Updated Successfully.
                  </span>
                </div>
              ),
              duration: 3,
              icon: null,
            });

            // window.location.reload();
            if (submitmodify == false) {
              // alert("3");
              onClose();
              setTimeout(() => {
                getassignedkeyforobjectives(selectedkrvalue, objid, localStorage.loginId);
                getteamkeyresult(objid, localStorage.username);
                if(krcode=="1"){
                  objectivelist()
                }
                // window.location.reload();
              }, 1000);
            }
            else {
              toggleCustomVisibility();
            }

          } catch (error) {
            // console.log(error)
          }

          // console.log('Cascade action confirmed');
        },
        onCancel() {
          setClicked(false);
          setCasClicked(false);
          // console.log('Cascade action cancelled');
        },
      });

    }




  }
  const keyvaluesweightage = availableweightagekr + keyResultsValues.keyresultweightage;
  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>
  );
  const handlesubmitcustomweightage = (status: string) => {
    const usersweightage = dataSource.filter(user => user.key != localStorage.loginId)
    // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }
    // Disable the button
    setIsWeightageButton(true);
    if (keyResultsValuesedit.keytitle == "") {
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              Key result Name Is Mandatory
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      toggleCustomVisibility()
    }
    else {
      // if (usersweightage.some(user => Number.isNaN(user.Assigned))||usersweightage.some(user => user.Assigned=="")){
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{keyResultsValuesedit.keytitle}</p>
      //         <p className="fs-14"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // } else {
      //   Modal.confirm({
      //     title: 'Do you want to save the weightage this key result ?',
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{keyResultsValuesedit.keytitle}</p>
      //         <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
      //       </>
      //     ),
      //     okText: 'Save',
      //     cancelText: 'Back',
      //     okButtonProps: { className: 'C_btn' },
      //     cancelButtonProps: { className: 'outline_btn' },
      //     centered: true,
      //     onOk() {
      //       message.success({
      //         content: (
      //           <div className="success-message justify-center align-center p-1">
      //             <img src="assets/images/check-circle.svg" alt="Success Icon" />
      //             <span className="c_32C6A0 fs-16">
      //               Weightage Saved successfully.
      //             </span>
      //           </div>
      //         ),
      //         duration: 2,
      //         icon: null,
      //       });
      //       toggleCustomVisibility();
      //     },
      //     onCancel() {
      //       setCasClicked(false);
      //       console.log('Cascade action cancelled');
      //     },
      //   });
      // }
      if (keyResultsValuesedit.status == 1) {
        handleSubmit(1, true)
      }
      else {
        handleSubmit(2, true)
      }
    }

    if (OrgObjectives)
      setTimeout(() => {
        setIsWeightageButton(false);
      }, 2000);
  }
  const [textClicked, setTextClicked] = useState(false);

  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  interface User {
    id: string;
    name: string;
    email: string;
    profilePath: string;
    signoffStatus: number;
    designation: string;
    username: string;
  }

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
  // const [selectedOption, setSelectedOption] = useState<WeekOption>(
  // weekOptions[1]
  // ); 



  const CustomSelect: React.FC<CustomSelectProps> = ({
    onChange,
    selectedOption,
  }) => {
    console.log(selectedOption, 'setSelectedOption');

    const currentIndex = weekOptions.findIndex(
      (option) => option.value === selectedOption
    );

    const handleNext = () => {
      const nextIndex = (currentIndex + 1) % weekOptions.length;
      onChange(weekOptions[nextIndex].value);
      checkInTeamList(TMselectedOption, krid);
    };

    const handlePrev = () => {
      const prevIndex =
        (currentIndex - 1 + weekOptions.length) % weekOptions.length;
      onChange(weekOptions[prevIndex].value);
      checkInTeamList(TMselectedOption, krid);
    };

    const isPrevAvailable = currentIndex > 0;
    const isNextAvailable = currentIndex < weekOptions.length - 1;

    const buttonStyle = (isAvailable: boolean) => ({
      backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
      borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
      color: isAvailable ? "#1890ff" : "#bfbfbf",
    });



    return (
      <>
        <div className="">
          <Space direction="horizontal" className="z-1 mr-1 checkin_user_select">
            <Button
              onClick={handlePrev}
              style={buttonStyle(isPrevAvailable)}
              disabled={!isPrevAvailable}
              icon={<LeftOutlined />}
            />
            <Select
              className="weekoption_checkin"
              value={selectedOption}
              // style={{ width: '300px' }}
              dropdownRender={(menu) => (
                <div>
                  {weekOptions.map((option) => (
                    <div
                      key={option.value}
                      style={{
                        padding: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        checked={selectedOption === option.value}
                        onChange={() => onChange(option.value)}
                      >
                        <div className="justify-between">
                          <div>{option.label}</div>
                          <div className="ml-5 pl-1">{option.value}</div>
                        </div>
                      </Radio>
                    </div>
                  ))}
                </div>
              )}
              optionLabelProp="label"
            >
              {weekOptions.map((option) => {
                const label = (
                  <div className="justify-between align-center lineheight_noraml">
                    <div className="c_262626 fs-12 fw-700">
                      <span className="text-center ">{option.label}</span>
                      <span className="pl-1">{option.range}</span>
                    </div>
                    <div className="fs-12 c_8080">{option.value}</div>
                  </div>
                );

                return (
                  <Option key={option.value} value={option.value} label={label}>
                    {label}
                  </Option>
                );
              })}
            </Select>
            <Button
              onClick={handleNext}
              style={buttonStyle(isNextAvailable)}
              disabled={!isNextAvailable}
              icon={<RightOutlined />}
            />
          </Space>
        </div>
      </>
    );
  }

  const handleChange = (value: string) => {
    const selected = weekOptions.find((option) => option.value === value);
    if (selected) {
      setSelectedOption(value);
      setUserCheckInWeek(selected.range);
      console.log("selected.range" + selected.range)
      console.log("currentmonth", currentmonth);
      console.log("currentdata", currentdata);
      let parts = selected.range.split(' ');
      let startDateParts = parts[0].split('-'); // ["Aug", "01"]
      let endDateParts = parts[1].split('-');

      let startMonth = startDateParts[0].toLowerCase();
      let startDay = parseInt(startDateParts[1]);
      let endMonth = endDateParts[0].toLowerCase();
      let endDay = parseInt(endDateParts[1]);

      // Assuming the format of currentmonth and currentdata are correct
      let currentDate = new Date();
      let currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
      let currentDay = currentDate.getDate();

      let startDate = new Date(`${startMonth} ${startDay}, ${currentDate.getFullYear()}`);
      let endDate = new Date(`${endMonth} ${endDay}, ${currentDate.getFullYear()}`);
      let today = new Date(`${currentMonth} ${currentDay}, ${currentDate.getFullYear()}`);
      if (today >= startDate && today <= endDate) {
        setrestriction(true);
      } else {
        setrestriction(false);
      }


      // if(month==currentmonth.toLowerCase()||month2==currentmonth.toLocaleLowerCase()){
      //   // console.log("oksodjfoisdjf")
      //   let intdate1 = parseInt(date1);
      //   let intdate2 = parseInt(date2);
      //   if(currentdata>=intdate1&&currentdata<=intdate2){
      //     setrestriction(true);
      //   }
      //   else{
      //     setrestriction(false);
      //   }
      // }
      setCheckinCreate((prevState) => ({
        ...prevState,
        checkWeekCount: selected.range,
        checkInWeek: value
      }));
      weekWiseList(krid, value);
    }
  }
  const [TMselectedOption, setTMSelectedOption] = useState('');

  const handleChangeTeam = (value: string) => {
    const selected = weekOptions.find((option) => option.value === value);
    if (selected) {
      setSelectedTeamOption(value);
    }
  }


  // const TMoptions = [
  //   {
  //     value: "Vikram/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 1,
  //     name: "Vikram/Product/CITPL",
  //   },
  //   {
  //     value: "Divya/Product/CITPL",
  //     name: "Divya/Product/CITPL",
  //     imageUrl: "assets/images/Default_user.svg",
  //     status: 2,
  //   },
  //   {
  //     value: "Reshma/Product/CITPL",
  //     name: "Reshma/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 3,
  //   },
  // ];
  const handleTMPrev = () => {
    const currentIndex = TMoptions.findIndex(
      (option) => option.id === TMselectedOption
    );
    const prevIndex = (currentIndex - 1 + TMoptions.length) % TMoptions.length;
    setTMSelectedOption(TMoptions[prevIndex].id);
    checkInTeamList(TMoptions[prevIndex].id, krid);
  };

  const handleTMNext = () => {
    const currentIndex = TMoptions.findIndex(
      (option) => option.id === TMselectedOption
    );
    const nextIndex = (currentIndex + 1) % TMoptions.length;
    setTMSelectedOption(TMoptions[nextIndex].id);
    checkInTeamList(TMoptions[nextIndex].id, krid);
  };

  const isPrevAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) > 0;
  const isNextAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) <
    TMoptions.length - 1;

  const buttonStyle = (isAvailable: any) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });
  const handleOptionChange = (event: any) => {
    setTMSelectedOption(event.target.value);
    checkInTeamList(event.target.value, krid);
  };

  const checkindataSource = [
    {
      key: '1',
      member: {
        name: 'Vishva',
        department: 'Product',
        company: 'CITPL',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg' // Placeholder avatar
      },
      targetAchieved: 'Yes',
    },
    {
      key: '2',
      member: {
        name: 'Vikram',
        department: 'Product',
        company: 'CITPL',
        avatar: 'https://randomuser.me/api/portraits/men/2.jpg' // Placeholder avatar
      },
      targetAchieved: 'No',
    },
    {
      key: '3',
      member: {
        name: 'Vikram',
        department: 'Product',
        company: 'CITPL',
        avatar: 'https://randomuser.me/api/portraits/men/2.jpg' // Placeholder avatar
      },
      targetAchieved: '-',
    },
  ];

  // Define columns
  const checkincolumns = [
    {
      title: 'Members',
      dataIndex: 'member',
      key: 'member',
      render: (member: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={member.avatar} className="select_user" />
          <span style={{ marginLeft: 8 }}>{`${member.name}/${member.department}/${member.company}`}</span>
        </div>
      ),
    },
    {
      title: 'Target Achieved',
      dataIndex: 'targetAchieved',
      key: 'targetAchieved',
    },
  ];

  const handleChangeTargetValue = (value: string) => {
    // setTargetValue(value);
    setCheckinCreate((prevState) => ({
      ...prevState,
      target: parseInt(value),
    }));
  }
  const userLists = async () => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem("loginId")}/${'2024-2025'}/${'Q1'}`, {
      const response = await axios.get(BaseUrl + `api/users/userListBySupervisor/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTMoptions(response.data);
      setTMSelectedOption(response.data[0].id);
      checkInTeamList(response.data[0].id, krid);
    } catch (err) {
      // console.error("Error fetching user list:", err);
      // setTMoptions([]);
    }
  };

  const formatCurrency = (amount: any, type: any) => {
    switch (type) {
      case 'Thousands':
        return `₹ ${amount} T`;
      case 'Lakhs':
        return `₹ ${amount} L`;
      default:
        return `₹ ${amount} C`;
    }
  };


  return (
    <div>
      <Row onClick={openModalAndEditObjective} className={`p-0 fs-14 ${textClicked ? 'c_32B7D9' : ''}`}>
        {krtitle === "" ?
          (<>
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
            Edit
          </>) : (<>
            <p className={`fs-16 fw-600 m-0 p-0 cur-pointer twoline_text_overflow ${textClicked ? 'clickText_active' : 'clickText'}`} >
              {krtitle}
            </p>
          </>)}
        {/* <button className="border-0 bg-transparent fs-14 align-center" onClick={openModalAndEditObjective} > */}
        {/* <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
          Edit */}
        {/* </button> */}
      </Row>
      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Target
            </div>
          ) : (
            <>
              <div className="justify-between">
                {readOnly ? (
                  <div className="container_label">
                    <div className="wrapper_label align-center mb-1">
                      <span className="cup_org_bg align-center c_000">
                        <img
                          src="assets/images/crown.svg"
                          alt=""
                          className="pr-1"
                        />{obj_code}
                      </span>
                      <span className="triangle"></span>
                    </div>
                    <div className="second_kr_label">
                      <div className="wrapper_label align-center mb-1">
                        <span className="triangle_left"></span>
                        <span className="cup_org_bg align-center c_000">
                          <img src="assets/images/KRA-Icon.svg" alt="" />
                          {keyResultsValues.keyid}
                        </span>
                        <span className="triangle"></span>
                      </div>
                    </div>
                  </div>
                ) : ("Edit Key Result")
                }


                <div className="mr-5 cur-pointer align-center">
                  {keyResultsValues.status === 2 ? (
                    <>
                      <button className="Progressing_btn align-center border-0 p-1 mr-1">
                        <span className="sm_pro_cricle mr-1 fs-14"></span>Progressing
                      </button>
                    </>
                  ) : keyResultsValues.status === 1 ? (
                    <>
                      <button className="cascade_btn align-center border-0 p-1 mr-1">
                        <span className="sm_cricle mr-1 fs-14"></span>Yet To Cascade
                      </button>
                    </>
                  ) :
                    <>
                      <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                        <span className="sm_pro_cricle  mr-1 "></span>Revoked
                      </button>
                    </>
                  }

                  {readOnly ? (

                    <>
                      {permission_kredit || permission_krdelete ? (<Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                          {localStorage.loginId == keyResultsValues.createdby && userSignOff.signOffStatus === 0
                            &&
                            <Space>
                              <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                              <input type="hidden" />
                            </Space>
                          }
                        </a>
                      </Dropdown>) : (<></>)}

                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )
        }
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={open}
        className="objective-canvas mb-5 position-relative"
      >
        {/* </Drawer> */}
        <div>
          {readOnly ? (
            <>
              {ViewWeightage ? (
                <>
                  <div className="cw_table">
                    {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}

                    {/* <p className="fs-16 c_32B7D9 fs-14">Product Members (18) 0</p> */}
                    <Row justify={"space-between"} className="mb-2">
                      <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        className="position-relative"
                      >
                        <input
                          type="search"
                          placeholder="Search Member"
                          className="w-100 table_search_inputbox pl-2"
                          value={searchTextValue}
                          // onSearch={handleSearch}
                          onChange={(e) => setSearchTextValue(e.target.value)}
                        />
                        <img
                          src="assets/images/search.svg"
                          className="table_search_img"
                          alt=""
                        />
                      </Col>
                    </Row>
                    <Table
                      dataSource={custom_filteredData}
                      columns={columns}
                      pagination={{ pageSize: 10 }}
                    // dataSource={viewdata}
                    // columns={columns}
                    // pagination={{ pageSize: 5 }}
                    />
                    <button className="cancel-btn float-right" onClick={ViewWeightageBack}>
                      Back
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="obj_tab">
                    <Tabs
                      defaultActiveKey={tabActiveKey}
                      onChange={onTabChange}
                      className="p-0"
                    >
                      <TabPane tab="General" key="1" className="p-0">
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            Objective Name
                          </label>{" "}
                          <br />
                          <p className="fs-16">{objname}</p>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            Objective Description
                          </label>{" "}
                          <br />
                          <p className="fs-16 org_obj_name">{orgdescription}</p>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            Key Result Name
                          </label>{" "}
                          <br />
                          <p className="fs-16">
                            {keyResultsValues.keytitle}
                          </p>
                        </div>
                        {/* <div className="mb-3">
                    <label htmlFor="" className="fw-600 fs-14">
                      Key Result Weightage
                    </label>{" "}
                    <br />
                    <p className="fs-16">
                      {keyResultsValues.keyresultweightage}
                    </p>
                  </div> */}
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            Key Result Description
                          </label>{" "}
                          <br />
                          <p className="fs-16">
                            {keyResultsValues.description}
                          </p>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col xl={6} lg={6} md={10} sm={10} xs={12}>
                              <label htmlFor="" className="fw-600 fs-14">
                                Cycle
                              </label>
                              <p className="fs-16">{keyResultsValues.quarter} {year}</p>
                            </Col>
                            <Col xl={6} lg={6} md={10} sm={10} xs={12}>
                              <label htmlFor="" className="fw-600 fs-14">
                                Created By
                              </label>
                              <div className="align-center mt-1">
                                <img
                                  src={keyResultsValues.createdByEmpProfilePath ? FileUrl + keyResultsValues.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                                  onError={handleImageError}
                                  alt=""
                                  className="user"
                                />
                                <span className="pl-1">{keyResultsValues.createdbyempname}</span>
                              </div>
                            </Col>
                            {/* {keyResultsValues.uom === "Percentage" || keyResultsValues.uom === "Currency" ? (
                              <>
                                <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                  <label htmlFor="" className="fw-600 fs-14">
                                    Target
                                  </label>
                                  <p className="fs-16">{keyResultsValues.target}  {keyResultsValues.uom === "Percentage" ? "%" : keyResultsValues.amounttype} </p>

                                </Col>
                              </>

                            ) : (
                              keyResultsValues.uom === "Logic" ? (
                                <>
                                </>
                              ) : (
                                <>
                                  <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                    <label htmlFor="" className="fw-600 fs-14">
                                      Target for each assignee
                                    </label>
                                    <p className="fs-16">{keyResultsValues.target}</p>
                                    {keyResultsValuesedit.uom === "Percentage" ? <span className="suffix" style={{ left: "15%", top: "50px" }}>%</span> : keyResultsValuesedit.uom === "Currency" ?
                                      <span className="suffix" style={{ left: "40%", top: "50px" }}>{selectedCurrency}</span> : <span className="suffix"></span>}
                                  </Col>
                                </>
                              )
                            )
                            } */}
                          </Row>
                        </div>
                        <div className="mb-3">
                          <div className="justify-between mb-1">
                            {assignedusers.length > 0 ? (
                              <label htmlFor="" className="fw-600 fs-14">
                                Assigned Groups
                              </label>) : (
                              <label htmlFor="" className="fw-600 fs-14">
                                Assigned Members
                              </label>
                            )
                            }
                            <span
                              className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                              onClick={toggleViewWeightage}
                            >
                              View Target <ArrowRightOutlined />
                            </span>
                          </div>
                          {assignedusers.length > 0 ? (
                            assignedusers.map(option => (
                              <span className="">
                                <span className="am_view">
                                  <img
                                    src={option.image}
                                    onError={handleImageError}
                                    className="select_user"
                                    style={{ position: "relative", top: "5px" }}
                                    alt=""
                                  />
                                  <span className="pl-1">{option.label}</span>
                                </span>
                              </span>
                            )
                            )
                          ) :
                            (
                              assignedusersvalues.map(item => (
                                <span className="pl-1">
                                  <span className="am_view">
                                    <img
                                      src={item.profilePath ? FileUrl + item.profilePath : "assets/images/Default_user.svg"}//"assets/images/user.svg"
                                      onError={handleImageError}
                                      style={{ position: "relative", top: "5px" }}
                                      className="select_user"
                                      alt=""
                                    />
                                    <span className="pl-1">{item?.username}</span>
                                  </span>
                                </span>
                              )
                              )
                            )
                          }
                          {assignedusersvalues.length == 0 && assignedusers.length == 0 &&
                            <p> Assigness Not Available</p>
                          }

                        </div>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            CheckIn Frequency
                          </label>{" "}
                          <br />
                          <p className="fs-16">
                            {keyResultsValues.checkInFrequency === "" ? "-" : (
                              <>
                                {keyResultsValues.checkInFrequency === "Monthly" ? "Monthly (3 Check In) " :
                                  keyResultsValues.checkInFrequency === "Weekly" ? "Weekly (13 Check In)" : "Fortnightly(6 Check In)"}
                              </>)}
                          </p>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">Target UOM
                          </label>{""}
                          <br />
                          {uomType === "Percentage" ? keyResultsValues.target + " " + "% Percentage" :
                            (uomType === "Currency" ? "₹ " + keyResultsValues.target + "   " + keyResultsValues.amounttype : keyResultsValues.target)
                          }
                        </div>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-600 fs-14">
                            Remarks
                          </label>{" "}
                          <br />
                          <p className="fs-16">
                            {keyResultsValues.remarks}
                          </p>
                        </div>
                        <div className="mb-3">
                          {/* <button className="save-btn float-right"><input type="checkbox" name="" checked id="" className="custom-checkbox" />Sign Off</button> */}
                          {/* <button className="cancel-btn float-right">Sign Off</button> */}
                        </div>
                      </TabPane>
                      {keyResultsValues.status == 2 &&
                        <TabPane tab="Checkins" key="2" className="p-0">
                          <div>
                            {krUserCheckIn === true ? (
                              <>
                                <div>
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Key Result Name
                                  </label>
                                  <p className="fs-16">{keyResultsValues.keytitle}</p>
                                </div>
                                <div>
                                  <Row className="justify-between ">
                                    <div className="checkin_circle_progress">
                                      <p className="fw-600 fs-14 c_32B7D9 m-0">
                                        My Checkins
                                      </p>
                                    </div>
                                    {uomType !== "Custom" ? (
                                      <div className="align-center">
                                        <CustomSelect
                                          onChange={handleChange}
                                          selectedOption={selectedOption}
                                        />
                                        {(weekwiseFrequencyCount === 1 && viewCheckIn?.uomType !== "Custom" && restictionindates) &&
                                          <div className="p-0 m-0">
                                            <button className="checkin_edit" onClick={e => { setShowAchieved(true); setAchived(viewCheckIn?.achievedTarget || 0) }}>
                                              <img src="assets/images/checkin_edit.svg" alt="" className="pr-1" />
                                              Edit
                                            </button>
                                          </div>
                                        }
                                      </div>
                                    ) : (<></>)}
                                  </Row>
                                  <>
                                    {uomType !== "Custom" ? (
                                      <>
                                        {((weekwiseFrequencyCount === 0 && restictionindates) || showAchieved) ? (
                                          <>
                                            <Row>
                                              <Col
                                                xl={24}
                                                lg={24}
                                                md={24}
                                                sm={24}
                                                className="mt-1 custom_checkin"
                                              >
                                                <label htmlFor="" className="fw-600 fs-14 ">
                                                  Actual Achieved
                                                </label>
                                                {/* <span>{String(restictionindates)}</span> */}
                                                <Input
                                                  placeholder=""
                                                  className="canvas-objective-input mt-1"
                                                  name="target"
                                                  value={showAchieved ? achieved : checkin}
                                                  onChange={(e) => {
                                                    const inputVal = e.target.value;
                                                    const numericRegex = /^[0-9]*$/;
                                                    if (inputVal === '' || (parseInt(inputVal) <= parseInt(keyResultsValues.target) && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                                      setCheckin(e.target.value);
                                                      setAchived(Number(e.target.value));
                                                      handleChangeTargetValue(e.target.value);
                                                    }
                                                  }}
                                                  prefix={uomselectedOption === "Currency" ? "₹" : ""}
                                                  suffix={uomselectedOption === "Percentage" ? "%" : ""}
                                                />
                                                <p className="c_000 fs-16 mt-0 mb-0"><span className="c_B0B0B0 fw-700">Note :</span> This check-in can only be edited throughout this week.</p>
                                              </Col>
                                            </Row>
                                            <button
                                              className="cyan_button float-right mt-1 mb-2"
                                              onClick={showAchieved ? updateCheckin : checkincreate}
                                            >
                                              Submit
                                            </button>
                                          </>
                                        ) : (<></>)}
                                      </>
                                    ) : (
                                      <>
                                        {(weekwiseFrequencyCount === 0 || showAchieved) ? (
                                          <>
                                            <div>
                                              <label htmlFor="" className="fw-700 fs-14">Actual Achieved</label>
                                              <div className="checkin-mode m-1">
                                                <Radio.Group
                                                  value={checkinMode}
                                                  onChange={(e) => {
                                                    handleChangeCustom(e.target.value);
                                                  }}
                                                  className="checkin-toggle"
                                                >
                                                  <Radio.Button
                                                    value={1}
                                                    className="manual-checkin"
                                                    onClick={() => handleClick(1)}
                                                  >
                                                    Completed
                                                  </Radio.Button>
                                                  <Radio.Button
                                                    value={0}
                                                    className="automatic-checkin"
                                                    onClick={() => handleClick(0)}
                                                  >
                                                    Not Completed
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </div>
                                              <p className="c_000 fs-16 mt-0 mb-0"><span className="c_B0B0B0 fw-700">Note :</span> This check-in can only be edited throughout this week.</p>

                                              <Row className="justify-end">
                                                <button
                                                  className="cyan_button mt-1 mb-2"
                                                  onClick={showAchieved ? updateCheckin : checkincreate}
                                                >
                                                  Submit
                                                </button>
                                              </Row>
                                            </div>
                                          </>
                                        ) : (<></>)}
                                      </>
                                    )}
                                  </>
                                  {/* list */}
                                  {userFrequencyList.map((checkPerFrequency) => {
                                    const {
                                      achievedTarget,
                                      remainingTarget,
                                      completedTarget,
                                      uomType,
                                      amountType,
                                      checkInWeek,
                                      customTarget,
                                    } = checkPerFrequency;

                                    // Calculate percentage achieved
                                    const percentageAchieved = completedTarget + remainingTarget;
                                    const totalTarget = completedTarget + remainingTarget;
                                    const percentageNumeric = completedTarget + remainingTarget;

                                    const achievedPercentage = (checkPerFrequency.completedTarget / totalTarget) * 100;

                                    // console.log(
                                    //   "completedTarget" + completedTarget
                                    // );
                                    // console.log(
                                    //   "remainingTarget" + remainingTarget
                                    // );
                                    // console.log("achievedTarget"+achievedTarget)
                                    // console.log("checkPerFrequency.achievedTarget" +checkPerFrequency.achievedTarget)

                                    // Skip rendering if the check-in week doesn't match the selected option
                                    if (checkInWeek !== selectedOption)
                                      return (
                                        <>
                                          {uomType === "Custom" && (
                                            <Row className="align-center">
                                              <Col xl={10} lg={10} md={10} sm={24}>
                                                <p className="fw-700 c_000 m-0">
                                                  Actual Achieved
                                                </p>
                                              </Col>
                                              <Col xl={10} lg={10} md={10} sm={24}>
                                                {customTarget === 0
                                                  ? <><span className="c_32B7D9 fw-700">Not Completed</span></>
                                                  : <><span className="c_32B7D9 fw-700">Completed</span></>}
                                              </Col>
                                              <Col xl={4} lg={4} md={4} sm={24}>
                                                <button className="checkin_edit" onClick={e => setShowAchieved(true)}>
                                                  <img src="assets/images/checkin_edit.svg" alt="" className="pr-1" />
                                                  Edit
                                                </button>
                                              </Col>
                                            </Row>
                                          )}
                                        </>);
                                    return (
                                      <div
                                        key={checkInWeek}
                                        className="mt-5 checkin_circle_progress"
                                      >
                                        <p className="c_121212 fw-700 fs-14">
                                          Last Checkin - {"Week " + selectedOption.substring(0, selectedOption.indexOf('/'))}
                                        </p>

                                        <Row className="align-center">
                                          <Col xl={24}>
                                            {uomType === "Percentage" && (
                                              <>
                                                <p className="d-flex align-center c_8080 fw-300">
                                                  <img
                                                    src="assets/images/TotalTarget_circle.svg"
                                                    className="mr-1"
                                                    alt="Total Target"
                                                  />
                                                  Actual Achieved
                                                  <span className="c_000 fw-700 pl-1">
                                                    {completedTarget} %
                                                  </span>
                                                </p>

                                                <div className="progress-content">

                                                  <Progress
                                                    type="line"
                                                    percent={achievedPercentage}
                                                    strokeColor={"#32b7d9"}
                                                    trailColor={"#EAEAEA"}
                                                    showInfo={false}
                                                  />
                                                  <div className="progress-dots">
                                                    <Tooltip
                                                      title="0%"
                                                      placement="bottom"
                                                    >
                                                      <span
                                                        className="dot"
                                                        style={{ left: "0%" }}
                                                      ></span>
                                                    </Tooltip>
                                                    {achievedPercentage > 0 && (
                                                      <Tooltip
                                                        title={` ${checkPerFrequency.completedTarget}%`}
                                                        placement="bottom"
                                                      >
                                                        <span
                                                          className="dot"
                                                          style={{
                                                            // left: `calc(${checkPerFrequency.completedTarget}% - 4px)`,
                                                            left: `${achievedPercentage}%`,

                                                          }}
                                                        ></span>
                                                      </Tooltip>
                                                    )}
                                                    <Tooltip
                                                      title={`${checkPerFrequency.target}%`}
                                                      placement="bottom"
                                                    >
                                                      <span
                                                        className="dot"
                                                        style={{ left: "100%" }}
                                                      ></span>
                                                    </Tooltip>
                                                  </div>
                                                  <div className="justify-between fs-16 c_32B7D9">
                                                    <span>0%</span>
                                                    <span>{checkPerFrequency.target}%</span>
                                                  </div>

                                                  {/* <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                color: '#000',
                                              }}>
                                                {`${percentageAchieved}`}
                                              </div> */}
                                                </div>
                                              </>
                                            )}

                                            {/* {uomType === "Custom" && (
                                            <Row className="align-center">
                                              <Col xl={10} lg={10} md={10} sm={24}>
                                                <p className="fw-700 c_000 m-0">
                                                  Actual Achieved
                                                </p>
                                              </Col>
                                              <Col xl={10} lg={10} md={10} sm={24}>
                                                {customTarget === 0
                                                  ? <><span className="c_32B7D9 fw-700">Not Completed</span></>
                                                  : <><span className="c_32B7D9 fw-700">Completed</span></>}
                                              </Col>
                                              <Col xl={4} lg={4} md={4} sm={24}>
                                                <button className="checkin_edit" onClick={e => setShowAchieved(true)}>
                                                  <img src="assets/images/checkin_edit.svg" alt="" className="pr-1" />
                                                  Edit
                                                </button>
                                              </Col>
                                            </Row>
                                          )} */}
                                          </Col>
                                          <Col xl={12} lg={12} md={12} sm={24}>
                                            {uomType === "Numeric" && (
                                              <Progress
                                                strokeWidth={20}
                                                percent={achievedPercentage}
                                                strokeColor="#32b7d9"
                                                trailColor="#EAEAEA"
                                                type="circle"
                                                width={300}
                                                format={() => (
                                                  <div>
                                                    <p className="m-0 fs-16 fw-700 c_000">{checkPerFrequency.target}</p>
                                                    <p className="fs-14 c_8080 mb-0">Target Allotted</p>
                                                  </div>
                                                )}
                                              />
                                            )}
                                            {
                                              uomType === "Currency" && (
                                                <div>
                                                  <Progress
                                                    strokeWidth={20}
                                                    type="circle"
                                                    percent={achievedPercentage}
                                                    strokeColor="#32b7d9"
                                                    trailColor="#EAEAEA"
                                                    width={300}
                                                    format={() => (
                                                      <div>
                                                        <p className="m-0 fs-16 fw-700 c_000">{formatCurrency(percentageAchieved, amountType)}</p>
                                                        <p className="fs-14 c_8080 mb-0">Target Allotted</p>
                                                      </div>
                                                    )}
                                                  />
                                                </div>
                                              )
                                            }
                                          </Col>
                                          <Col xl={12} lg={12} md={12} sm={24}>
                                            {
                                              (uomType === "Numeric" || uomType === "Currency") && (
                                                <>
                                                  <p className="c_8080 fw-300 fs-14">
                                                    Actual Achieved on this checkin
                                                    <span className="c_000 fs-14 fw-700 pl-1">
                                                      {uomType === "Numeric"
                                                        && `${achievedTarget}`}
                                                      {uomType === "Currency"
                                                        && formatCurrency(
                                                          achievedTarget,
                                                          amountType
                                                        )}
                                                    </span>
                                                  </p>
                                                  <p className="d-flex align-center c_8080 fw-300 fs-14">
                                                    <img
                                                      src="assets/images/TotalTarget_circle2.svg"
                                                      className="mr-1"
                                                      alt="Total Target"
                                                    />
                                                    Actual
                                                    <span className="c_000 fw-700 pl-1">
                                                      {uomType === "Numeric"
                                                        ? `${completedTarget}`
                                                        : formatCurrency(
                                                          completedTarget,
                                                          amountType
                                                        )}
                                                    </span>
                                                  </p>
                                                  <p className="d-flex align-center c_8080 fw-300 fs-14">
                                                    <img
                                                      src="assets/images/TargetBalance_circle.svg"
                                                      className="mr-1"
                                                      alt="Target Balance"
                                                    />
                                                    Target Balance
                                                    <span className="c_000 fw-700 pl-1">
                                                      {uomType === "Numeric"
                                                        ? `${remainingTarget}`
                                                        : formatCurrency(
                                                          remainingTarget,
                                                          amountType
                                                        )}
                                                    </span>
                                                  </p>
                                                </>
                                              )
                                            }
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                  <Divider />
                                </div>
                              </>
                            ) : (<></>)}

                            {/* Team checkin */}
                            {(TMoptions.length > 0 && showTeamChekin) && (
                              <div>
                                {/* <div>
                                 <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                   Key Result Name 
                                 </label>
                                <p className="fs-16 mt-1">{keyResultsValues.keytitle}</p>
                              </div> */}
                                <div className="mb-1">
                                  <label
                                    htmlFor=""
                                    className="fw-600 fs-14 c_32B7D9"
                                  >
                                    Team Checkins
                                  </label>
                                </div>
                                <Row className="">
                                  <Col xl={12} lg={12} md={12} sm={24}>
                                    <Space
                                      direction="horizontal"
                                      className="z-1 mr-1 checkin_user_select"
                                    >
                                      <Button
                                        icon={<LeftOutlined />}
                                        onClick={handleTMPrev}
                                        style={buttonStyle(isPrevAvailable)}
                                        disabled={!isPrevAvailable}
                                      />
                                      <Select
                                        className="custom-select w-300"
                                        value={TMselectedOption}
                                        onChange={setTMSelectedOption}
                                      >
                                        {TMoptions.map((TMoption) => (
                                          <label
                                            key={TMoption.id}
                                            className="d-flex align-center"
                                          >
                                            <Radio
                                              value={TMoption.id}
                                              checked={
                                                TMselectedOption === TMoption.id
                                              }
                                              onChange={handleOptionChange}
                                            >
                                              <div className="align-center justify-between status_signoff_user ">
                                                <img
                                                  // src={TMoption.profilePath?FileUrl+TMoption.profilePath:"assets/images/Default_user.svg"}
                                                  src={"assets/images/Default_user.svg"}
                                                  alt={TMoption.name}
                                                  className="mr-1 img_28"
                                                />
                                                <span className="mr-1">
                                                  {TMoption.name}
                                                </span>
                                              </div>
                                            </Radio>
                                          </label>
                                        ))}
                                      </Select>
                                      <Button
                                        icon={<RightOutlined />}
                                        onClick={handleTMNext}
                                        style={buttonStyle(isNextAvailable)}
                                        disabled={!isNextAvailable}
                                      />
                                    </Space>
                                  </Col>
                                  <Col xl={12} lg={12} md={12} sm={24}>
                                    {uomType !== "Custom" ? (
                                      <div className="float-right">
                                        <CustomSelect
                                          onChange={handleChangeTeam}
                                          selectedOption={selectedTeamOption}
                                        />
                                      </div>) : (<></>)}
                                  </Col>
                                </Row>

                                {userFrequencyListTeam.map((checkPerFrequency: createCheckIn) => {
                                  const {
                                    achievedTarget,
                                    remainingTarget,
                                    completedTarget,
                                    uomType,
                                    amountType,
                                    checkInWeek,
                                    customTarget,
                                  } = checkPerFrequency;

                                  // Calculate percentage achieved
                                  const percentageAchieved = completedTarget + remainingTarget;
                                  const totalTarget = completedTarget + remainingTarget;
                                  const achievedPercentage = totalTarget > 0 ? (completedTarget / totalTarget) * 100 : 0;
                                  // console.log("completedTarget: " + completedTarget);
                                  // console.log("remainingTarget: " + remainingTarget);
                                  // console.log("achievedTarget: " + achievedTarget);
                                  // console.log("checkPerFrequency.achievedTarget: " + checkPerFrequency.achievedTarget);

                                  return (
                                    checkPerFrequency.checkInWeek === selectedTeamOption ? (
                                      <div className="mt-1 checkin_circle_progress">
                                        <p className="c_121212 fw-700 fs-14">
                                          Last Checkin - Week {checkPerFrequency.checkInWeek.split('/')[0]}
                                        </p>
                                        <Row className="align-center">
                                          <Col xl={24}>
                                            {uomType === "Percentage" && (
                                              <>
                                                <p className="d-flex align-center c_8080 fw-300">
                                                  <img
                                                    src="assets/images/TotalTarget_circle.svg"
                                                    className="mr-1"
                                                    alt="Total Target"
                                                  />
                                                  Actual Achieved
                                                  <span className="c_000 fw-700 pl-1">
                                                    {completedTarget} %
                                                  </span>
                                                </p>
                                                <div className="progress-content">
                                                  <Progress
                                                    type="line"
                                                    percent={achievedPercentage}
                                                    strokeColor={"#32b7d9"}
                                                    trailColor={"#EAEAEA"}
                                                    showInfo={false}
                                                  />
                                                  <div className="progress-dots">
                                                    <Tooltip
                                                      title="0%"
                                                      placement="bottom"
                                                    >
                                                      <span
                                                        className="dot"
                                                        style={{ left: "0%" }}
                                                      ></span>
                                                    </Tooltip>
                                                    {achievedPercentage > 0 && (
                                                      <Tooltip
                                                        title={` ${checkPerFrequency.completedTarget}%`}
                                                        placement="bottom"
                                                      >
                                                        <span
                                                          className="dot"
                                                          style={{
                                                            // left: `calc(${checkPerFrequency.completedTarget}% - 4px)`,
                                                            left: `${achievedPercentage}%`,

                                                          }}
                                                        ></span>
                                                      </Tooltip>
                                                    )}
                                                    <Tooltip
                                                      title={`${checkPerFrequency.target}%`}
                                                      placement="bottom"
                                                    >
                                                      <span
                                                        className="dot"
                                                        style={{ left: "100%" }}
                                                      ></span>
                                                    </Tooltip>
                                                  </div>
                                                  <div className="justify-between fs-16 c_32B7D9">
                                                    <span>0%</span>
                                                    <span>100%</span>
                                                  </div>
                                                  {/* <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                color: '#000',
                                              }}>
                                                {`${percentageAchieved}`}
                                              </div> */}
                                                </div>
                                              </>
                                            )}
                                            {uomType === "Custom" && (
                                              <Row className="align-center">
                                                <Col xl={10} lg={10} md={10} sm={24}>
                                                  <p className="fw-700 c_000 m-0">
                                                    Actual Achieved
                                                  </p>
                                                </Col>
                                                <Col xl={10} lg={10} md={10} sm={24}>
                                                  {customTarget === 0
                                                    ? <><span className="c_32B7D9 fw-700">Not Completed</span></>
                                                    : <><span className="c_32B7D9 fw-700">Completed</span></>}
                                                </Col>
                                              </Row>
                                            )}
                                          </Col>
                                          <Col xl={12} lg={12} md={12} sm={24}>
                                            {uomType === "Numeric" && (
                                              <Progress
                                                strokeWidth={20}
                                                percent={achievedPercentage}
                                                strokeColor="#32b7d9"
                                                trailColor="#EAEAEA"
                                                type="circle"
                                                width={300}
                                                format={() => (
                                                  <div>
                                                    <p className="m-0 fs-16 fw-700 c_000">{checkPerFrequency.target}</p>
                                                    <p className="fs-14 c_8080 mb-0">Target Allotted</p>
                                                  </div>
                                                )}
                                              />
                                            )}{uomType === "Currency" && (
                                              <div>
                                                <Progress
                                                  strokeWidth={20}
                                                  type="circle"
                                                  percent={achievedPercentage}
                                                  strokeColor="#32b7d9"
                                                  trailColor="#EAEAEA"
                                                  width={300}
                                                  format={() => (
                                                    <div>
                                                      <p className="m-0 fs-16 fw-700 c_000">{formatCurrency(percentageAchieved, amountType)}</p>
                                                      <p className="fs-14 c_8080 mb-0">Target Allotted</p>
                                                    </div>
                                                  )}
                                                />
                                              </div>
                                            )}
                                          </Col>
                                          <Col xl={12} lg={12} md={12} sm={24}>
                                            {
                                              (uomType === "Numeric" || uomType === "Currency") && (
                                                <>
                                                  {/* <p className="c_8080 fw-300 fs-14">
                                            Actual Achieved on this checkin 
                                            <span className="c_000 fs-14 fw-700 pl-1">
                                              {uomType === "Numeric"
                                                && `${achievedTarget}`}
                                                    {uomType === "Currency"
                                                && formatCurrency(
                                                    achievedTarget,
                                                    amountType
                                                  )}
                                            </span>
                                          </p> */}
                                                  <p className="d-flex align-center c_8080 fw-300 fs-14">
                                                    <img
                                                      src="assets/images/TotalTarget_circle2.svg"
                                                      className="mr-1"
                                                      alt="Total Target"
                                                    />
                                                    Actual
                                                    <span className="c_000 fw-700 pl-1">
                                                      {uomType === "Numeric"
                                                        ? `${completedTarget}`
                                                        : formatCurrency(
                                                          completedTarget,
                                                          amountType
                                                        )}
                                                    </span>
                                                  </p>
                                                  <p className="d-flex align-center c_8080 fw-300 fs-14">
                                                    <img
                                                      src="assets/images/TargetBalance_circle.svg"
                                                      className="mr-1"
                                                      alt="Target Balance"
                                                    />
                                                    Target Balance
                                                    <span className="c_000 fw-700 pl-1">
                                                      {uomType === "Numeric"
                                                        ? `${remainingTarget}`
                                                        : formatCurrency(
                                                          remainingTarget,
                                                          amountType
                                                        )}
                                                    </span>
                                                  </p>
                                                </>
                                              )
                                            }
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : (
                                      <>
                                        {uomType === "Custom" && (
                                          <Row className="align-center mt-5">
                                            <Col xl={10} lg={10} md={10} sm={24}>
                                              <p className="fw-700 c_000 m-0">
                                                Actual Achieved
                                              </p>
                                            </Col>
                                            <Col xl={10} lg={10} md={10} sm={24}>
                                              {customTarget === 0
                                                ? <><span className="c_32B7D9 fw-700">Not Completed</span></>
                                                : <><span className="c_32B7D9 fw-700">Completed</span></>}
                                            </Col>
                                          </Row>
                                        )}
                                      </>)
                                  )
                                })}
                                {/* <Row>
                              <Col xl={8}><label htmlFor="">Yes</label><span>1</span></Col>
                              <Col xl={8}><label htmlFor="">No</label><span>1</span></Col>
                              <Col xl={8}><label htmlFor="">Not Answered</label><span>1</span></Col>
                            </Row>
                             */}
                                <Divider />
                              </div>
                            )}
                          </div>
                        </TabPane>
                      }
                      <TabPane tab="Audit Trail" key="3" className="p-0">
                        <div>
                          <div className="mb-3 border_bottom">
                            <label htmlFor="" className="fw-600 fs-14">
                              Objective Name
                            </label>{" "}
                            <br />
                            {/* <p className="fs-16">{objname}</p> */}
                            <p className="fs-16">{keyResultsValues.keytitle}</p>
                          </div>
                          {/* <div className="border_bottom">
                                  <div className="align-center">
                                    <img src="assets/images/connect_b.svg" alt="" /><span className="pl-1 c_32B7D9 text_line fs-14">Connect</span>
                                  </div>
                                </div> */}
                          <div>
                            {audittrails.map((audits, index) => (
                              <div>
                                <ul className="Obj_list">
                                  {audits.status == 0 && (
                                    <li>
                                      <div>
                                        <div className="d-flex align-start">
                                          <div className="icon"></div>
                                          <div>
                                            <p className="fs-14 fw-700">KeyResult {audits.remark} </p>
                                            <p className="fs-12">{audits.createdat}</p>
                                          </div>
                                        </div>

                                      </div>
                                    </li>
                                  )
                                  }
                                  {audits.status == 2 && (
                                    <li className="mt-1">
                                      <div className="d-flex justify-between align-start">
                                        <div className="d-flex align-start">
                                          <div className="icon"></div>
                                          <div>
                                            <p className="fs-14 fw-700">Key Result {audits.modelname} Edited   </p>
                                            <p className="fs-12">{audits.createdat}</p>
                                            {
                                              objAudit[index] &&
                                              (
                                                <div>
                                                  <label htmlFor="" className="c_29ABE2 fs-12">New name</label>
                                                  <p className="fs-16 m-0">{audits.newdata}</p>
                                                  <label htmlFor="" className="c_aaa fs-12">Old name</label>
                                                  <p className="fs-16 m-0">{audits.olddata}</p>
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                        <div>
                                          {
                                            objAudit[index] ? (
                                              <UpOutlined onClick={() => handleObjAudit(index)} style={{ width: "10px" }} />
                                            ) : (
                                              <DownOutlined onClick={() => handleObjAudit(index)} style={{ width: "10px" }} />
                                            )
                                          }
                                        </div>
                                      </div>


                                    </li>
                                  )}
                                  {audits.status == 1 && (
                                    <li className="mt-1">
                                      <div>
                                        <div className="d-flex justify-between align-start">
                                          <div className="d-flex align-start">
                                            <div className="icon"></div>
                                            <div>
                                              <p className="fs-14 fw-700">{audits.modelname} </p>
                                              <p className="fs-12">{audits.createdat}</p>
                                            </div>
                                          </div>

                                          <div>
                                            {
                                              memberAudit[index] ? (
                                                <UpOutlined onClick={() => handleMebAudit(index)} style={{ width: "10px" }} />
                                              ) : (
                                                <DownOutlined onClick={() => handleMebAudit(index)} style={{ width: "10px" }} />
                                              )
                                            }
                                          </div>
                                        </div>
                                        {
                                          memberAudit[index] && (
                                            <div className="objective_overall d-flex">
                                              {/* <span className="every_am_view mr-1 cur-pointer"><span className="pl-1">Everyone</span></span> */}
                                              {/* <span className="connect_view  mr-1 cur-pointer p-2"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun </span><span className="error_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan </span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan</span><span className="success_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">priya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Riya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">DIvya Priya</span></span> */}
                                              {JSON.parse(audits.newdata).map((user: any, idx: number) => (
                                                <span key={idx} className="connect_view mr-1 cur-pointer p-2">
                                                  <img src={user.profilpath || "assets/images/Default_user.svg"} className="select_user" alt="" />
                                                  <span className="pl-1 nowrap">{user.username}</span>
                                                </span>
                                              ))}
                                            </div>
                                          )
                                        }

                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            ))
                            }
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Form>
                {isCustomVisible ? (
                  <div className="cw_table">
                    {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}
                    {/* <p className="fs-16 c_32B7D9 fs-14">Product Members (18)</p> */}
                    <Row justify={"space-between"} className="mb-2">
                      <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        className="position-relative"
                      >
                        <input
                          type="search"
                          placeholder="Search Member"
                          className="w-100 table_search_inputbox pl-2"
                          value={searchTextValue}
                          // onSearch={handleSearch}
                          onChange={(e) => setSearchTextValue(e.target.value)}
                        />
                        <img
                          src="assets/images/search.svg"
                          className="table_search_img"
                          alt=""
                        />
                      </Col>
                    </Row>
                    <Table
                      dataSource={custom_filteredData}
                      columns={columns}
                      pagination={{ pageSize: 10 }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="mb-3">
                      <Row>
                        <Col xl={17} lg={17} md={17} sm={17} xs={17}>
                          <div className="container_label">
                            <div className="wrapper_label align-center mb-1">
                              <span className="cup_org_bg align-center">
                                <img
                                  src="assets/images/crown.svg"
                                  alt=""
                                  className="pr-1"
                                />{obj_code}
                              </span>
                              <span className="triangle"></span>
                            </div>
                          </div>
                          <p className="fs-16">{objname}</p>
                        </Col>
                        <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                          <label htmlFor="" className="canvas-objective-title c_32B7D9">
                            cycle
                          </label>{" "}
                          <br />
                          <p className="fs-16">{keyResultsValuesedit.quarter} {year}</p>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <label htmlFor="" className="fw-600">Objective Description</label> <br />
                        <p className="org_obj_name">{orgdescription} </p>
                      </Col>
                    </Row>
                    <div className=" mb-3">
                      <div className="justify-between">
                        <label className="canvas-objective-title c_32B7D9">
                          {" "}
                          Key Result Name *
                        </label>
                        <span className="c-0050 fs-12 mt-1">
                          {keyResultsValuesedit.keytitle.trim() === ""
                            ? 0
                            : keyResultsValuesedit.keytitle.trim().split(/\s+/).join("").length}{" "}
                          / {maxWords.name_max_word_count}
                        </span>
                      </div>
                      <div className="objective-title-inner">
                        <TextArea
                          rows={3}
                          placeholder="Write here...."
                          className="canvas-objective-input w-100"
                          name="keytitle"
                          value={keyResultsValuesedit.keytitle}
                          onChange={(e) =>
                            handleTextChange(e, setorgobjectivetext, maxWords.name_max_word_count)
                          }
                        />
                        {errors && errors.find(error => error.path[0] === 'keytitle') && (
                          <p className="danger">{errors.find(error => error.path[0] === 'keytitle')?.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Row className="align-center">
                        <Col>
                          {/* <label htmlFor="" className="canvas-objective-title">
                            Key Result Weightage<sup className="c_32B7D9">*</sup>
                          </label> */}
                          {/* {
                            keyResultsValuesedit.status === 1 ? (
                              <>
                                <input className="canvas-objective-input w-90" name="keyresultweightage" value={keyResultsValuesedit.keyresultweightage}
                                  onChange={(e) => {
                                    const inputVal = e.target.value;
                                    const numericRegex = /^[0-9]*$/;
                                    if (inputVal === '' || (parseInt(inputVal) <= keyvaluesweightage && parseInt(inputVal) > 0 && numericRegex.test(inputVal))) {
                                      handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                                      
                                    }
                                  }} />
                                {errors && errors.find(error => error.path[0] === 'keyresultweightage') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'keyresultweightage')?.message}</p>
                                )}
                              </>
                            ) : (
                              <>
                                <br />
                                <p className="fs-16">
                                  {keyResultsValues.keyresultweightage}
                                </p>
                              </>
                            )
                          } */}
                        </Col>
                        {/* <Col className="mt-2">
                          <label htmlFor="" className="canvas-objective-title">
                            Available Weightage :
                          </label>
                          <span className="c_32B7D9">&nbsp;{keyvaluesweightage}%</span>
                        </Col> */}
                      </Row>
                    </div>
                    <div className="mb-3">
                      <div className="justify-between mb-1">
                        <label htmlFor="" className="canvas-objective-title c_32B7D9">
                          Key Result Description *
                          {/* <sup className="c_32B7D9">*</sup> */}
                        </label>
                        <span className="c-0050 fs-12 mt-1">
                          {keyResultsValuesedit.description.trim().split(/\s+/).join("").length} /{" "}
                          {maxWords.description_max_word_count}
                        </span>
                      </div>
                      <TextArea
                        id="objective"
                        rows={3}
                        placeholder="Write here...."
                        className={"canvas-objective-input"}
                        value={keyResultsValuesedit?.description}
                        onChange={(e) =>
                          handleTextChange(
                            e,
                            setorgobjectivetext,
                            maxWords.description_max_word_count
                          )
                        }
                        name="description"
                      />
                    </div>
                    {errors && errors.find(error => error.path[0] === 'description') && (
                      <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                    )}
                    <div className="assign-section mb-3">
                      <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                        Assign Members *
                      </label>
                      <span
                        className="float-right c_32B7D9 text_line fw-600 fs-12 cur-pointer"
                        onClick={handleSearchMember}
                      >
                        {isSearchMem ? "Search Group" : "Search Members"}
                      </span>
                      {isSearchMem ?
                        (<span className="float-right c_32B7D9 text_line fs-12 cur-pointer">
                          <Checkbox className="c_32B7D9 fs-14" onClick={handlecheckboxKRedit} id="includeMyselfCheckboxKRedit" checked={checkboxChecked3}>Include Myself</Checkbox>
                        </span>) : (<></>)}
                      <br />
                      <div className="mt-1">
                        {isSearchMem ? (
                          <Select
                            mode="multiple"
                            placeholder={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="assets/images/member.svg"
                                  alt="Placeholder"
                                  style={{ marginRight: "8px" }}
                                />
                                <span>Select Member</span>
                              </div>
                            }
                            onChange={handleMemSelectChange}
                            value={selectedMemValues}
                            className="w-100 form-control"
                            labelInValue
                            optionLabelProp="label"
                            dropdownRender={(menu) => <div>{menu}</div>}
                            notFoundContent={<CustomEmptyMemberText />}
                            // options={Memoptions}
                            // filterOption={(input, option) =>
                            //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            // }
                            filterOption={(input, option) =>
                              option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                          >
                            {Memoptions.map((option) => (
                              <Option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {renderOption(option)}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            mode="multiple"
                            placeholder={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="assets/images/people.svg"
                                  alt="Placeholder"
                                  style={{ marginRight: "8px" }}
                                />
                                <span>Select Group</span>
                              </div>
                            }
                            onChange={handleGrpSelectChange}
                            value={selectedGrpValues}
                            className="w-100 form-control"
                            labelInValue
                            optionLabelProp="label"
                            dropdownRender={(menu) => <div>{menu}</div>}
                            notFoundContent={<CustomEmptyGroupText />}
                            // options={GrpOptions}
                            // filterOption={(input, option) =>
                            //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            // }
                            filterOption={(input, option) =>
                              option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                          >
                            {GrpOptions.map((option) => (
                              <Option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {renderOption(option)}
                              </Option>
                            ))}
                          </Select>
                        )}
                        <div
                          className={
                            selectedMemValues
                              ? "assgin_selected_val"
                              : "" || selectedGrpValues
                                ? "assgin_selected_val"
                                : "" || MemberValues
                                  ? "assgin_selected_val"
                                  : ""
                                    || mydetails
                                    ? "assgin_selected_val"
                                    : ""
                            // selectedMemValues.length > 0
                            //   ? "assgin_selected_val"
                            //   : "" || selectedGrpValues.length > 0
                            //   ? "assgin_selected_val"
                            //   : ""
                          }
                        >
                          {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}

                          {MemberValues.map((value) => (
                            <Tag
                              key={value.value}
                              // closable
                              // onClose={() => handleMemTagClose(value.value)}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                color: "#000",
                                borderRadius: 15,
                                padding: "5px 10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={value.image}
                                onError={handleImageError}
                                alt=""
                                style={{ marginRight: "8px" }}
                                className="select_user"
                              />
                              {value.label}
                            </Tag>
                          ))}
                          {mydetails != undefined
                            &&
                            <Tag
                              key={mydetails?.value}
                              // closable
                              onClose={() => handleTagClose(mydetails?.value)}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                color: "#000",
                                borderRadius: 15,
                                padding: "5px 10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={mydetails?.image}
                                onError={handleImageError}
                                alt=""
                                style={{ marginRight: "8px" }}
                                className="select_user"
                              />
                              {mydetails?.label}
                            </Tag>
                          }
                          {selectedMemValues.map((value) => (
                            <Tag
                              key={value.value}
                              closable={value.value === localStorage.getItem("loginId") ? false : true}
                              onClose={() => handleMemTagClose(value.value)}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                color: "#000",
                                borderRadius: 15,
                                padding: "5px 10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={value.image}
                                onError={handleImageError}
                                alt=""
                                style={{ marginRight: "8px" }}
                                className="select_user"
                              />
                              {value.label}
                            </Tag>
                          ))}
                          {assignedusers.map((value) => (
                            <Tag
                              key={value.value}
                              closable
                              onClose={() => handleTagClose(value.value)}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                color: "#000",
                                borderRadius: 15,
                                padding: "5px 10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src="assets/images/Default_user.svg"
                                alt=""
                                style={{ marginRight: "8px" }}
                                className="select_user"
                              />
                              {value.label}
                            </Tag>

                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Row className="">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="justify-between align-center">
                            <label htmlFor="" className="fw-600 fs-14">
                              Weightage<sup className="c_32B7D9">*</sup>
                            </label>

                          </div>
                          <Input
                            name="weightage"
                            placeholder=""
                            className="canvas-objective-input mt-1"
                            value={keyResultsValuesedit.weightage}
                            suffix="%"
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === 'customized' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
                                handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                // console.log("inputvaluesdetails", inputVal)
                              }
                            }}
                          />
                          {errors && errors.find(error => error.path[0] === 'weightage') && (
                            <p className="danger">{errors.find(error => error.path[0] === 'weightage')?.message}</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row className="">
                        {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="justify-between align-center">
                            <label htmlFor="" className="fw-600 fs-14">
                              Weightage<sup className="c_32B7D9">*</sup>
                            </label>

                          </div>
                          <Input
                            name="weightage"
                            placeholder=""
                            className="canvas-objective-input mt-1"
                            value={keyResultsValuesedit.weightage}
                            suffix="%"
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === 'customized' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
                                handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                // console.log("inputvaluesdetails", inputVal)
                              }
                            }}
                          />
                          {errors && errors.find(error => error.path[0] === 'weightage') && (
                            <p className="danger">{errors.find(error => error.path[0] === 'weightage')?.message}</p>
                          )}
                        </Col> */}
                        {/* <Col xl={1} lg={1} md={1} sm={1} xs={24}></Col> */}
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                          <label htmlFor="" className="fw-600 c_32B7D9 fs-14">
                            Target UOM *
                          </label>{" "}
                          <br />
                          <Select
                            className="custom-select mt-1"
                            style={{ width: 200 }}
                            placeholder=""
                            onChange={handleUOMSelect}
                            value={keyResultsValuesedit.uom}
                            disabled={keyResultsValuesedit.status == 2 ? true : false}
                          >
                            <Option value="Percentage">
                              <Radio
                                value="Percentage"
                                checked={uomselectedOption === "Percentage"}
                                onChange={handleUOMRadio}
                              >
                                Percentage
                              </Radio>
                            </Option>
                            <Option value="Currency">
                              <Radio
                                value="Currency"
                                checked={uomselectedOption === "Currency"}
                                onChange={handleUOMRadio}
                              >
                                Currency (INR)
                              </Radio>
                            </Option>
                            {/* <Option value="Logic">
                              <Radio
                                value="Logic"
                                checked={keyResultsValuesedit.uom === "Logic"}
                                onChange={handleUOMRadio}
                              >
                                Logic
                              </Radio>
                            </Option> */}
                            <Option value="Numeric">
                              <Radio
                                value="Numeric"
                                checked={uomselectedOption === "Numeric"}
                                onChange={handleUOMRadio}
                              >
                                Numeric
                              </Radio>
                            </Option>
                            <Option value="Custom">
                              <Radio
                                value="Custom"
                                checked={uomselectedOption === "Custom"}
                                onChange={handleUOMRadio}
                              >
                                Custom
                              </Radio>
                            </Option>
                          </Select>
                        </Col>

                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                          <div className="row d-flex justify-between align-center">
                            {uomselectedOption === "Logic" ? "" : (<>
                              <label htmlFor="" className="fw-600 c_32B7D9">
                                Target for each assignee *
                              </label>
                            </>)}

                            <div className="justify-end">
                              <label htmlFor="" className="c_32B7D9 text_line fs-12 cur-pointer fw-700 " onClick={handleShowHideCustom}>
                                {krShowCustom ? "Show Custom" : "Hide Custom"}
                              </label>
                            </div>
                          </div>
                          {uomselectedOption === "Percentage"
                            // && keyResultsValuesedit.uom === "Percentage" 
                            && (
                              <>
                                <Input
                                  placeholder=""
                                  className="canvas-objective-input mt-1"
                                  value={targetValue}
                                  name="target"
                                  suffix="%"
                                  disabled={keyResultsValuesedit.status == 2 ? true : false}
                                  onChange={(e) => {
                                    const inputVal = e.target.value;
                                    const numericRegex = /^[0-9]*$/;
                                    if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                      setTargetValue(e.target.value)
                                      handleTextChange(e, setObjectiveText, maxWords.remark_max_word_count);
                                    }
                                  }}
                                />
                                {errors && errors.find(error => error.path[0] === 'target') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                                )}
                              </>
                            )}
                          {uomselectedOption === "Currency"
                            //&&  keyResultsValuesedit.uom === "Currency" 
                            && (
                              <>
                                <Row className="target_each_currency">
                                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Input
                                      placeholder=""
                                      className="uom-input"
                                      value={currencytargetValue}
                                      name="currencytarget"
                                      // onChange={(e) =>
                                      //   setTargetValue(e.target.value)
                                      // }
                                      onChange={(e) => {
                                        const inputVal = e.target.value;
                                        const numericRegex = /^[0-9]*$/;
                                        if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                          // handleTextChange(e, setObjectiveText, maxWords.remark_max_word_count);
                                          setCurrencyTargetValue(e.target.value)
                                          handleCurrency(e);
                                        }
                                      }}
                                      prefix={uomselectedOption === "Currency" ? "₹" : ""}
                                    // suffix={uomselectedOption === "Percentage" ? "%" : ""}
                                    />
                                    {errors && errors.find(error => error.path[0] === 'target') && (
                                      <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                                    )}
                                  </Col>
                                  <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                                  <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                                    <Select
                                      className="custom-select"
                                      style={{ width: 200 }}
                                      placeholder="Select currency"
                                      onChange={handleCurrencySelect}
                                      value={selectedCurrency}
                                    >
                                      <Option value="Thousands">
                                        <Radio
                                          value="Thousands"
                                          checked={selectedCurrency === "Thousands"}
                                        >
                                          Thousands
                                        </Radio>
                                      </Option>
                                      <Option value="Lakhs">
                                        <Radio
                                          value="Lakhs"
                                          checked={selectedCurrency === "Lakhs"}
                                        >
                                          Lakhs
                                        </Radio>
                                      </Option>
                                      <Option value="Crores">
                                        <Radio
                                          value="Crores"
                                          checked={selectedCurrency === "Crores"}
                                        >
                                          Crores &nbsp;&nbsp;&nbsp;
                                        </Radio>
                                      </Option>
                                      {/* <Option value="Lakhs">Lakhs</Option>
                                    <Option value="Crores">Crores</Option> */}
                                    </Select>
                                  </Col>
                                </Row>
                              </>
                            )}
                          {uomselectedOption === "Logic" && keyResultsValuesedit.uom === "Logic" && (
                            <div>
                              {/* <label htmlFor="" className="fw-600 fs-14">
                                    Target <sup className="c_32B7D9">*</sup>
                                    </label>
                                    <Input
                                    placeholder="Enter logic"
                                    className="canvas-objective-input mt-1"
                                    value={targetValue}
                                    onChange={(e) => setTargetValue(e.target.value)}
                                    /> */}
                            </div>
                          )}
                          {uomselectedOption === "Numeric"
                            //  && keyResultsValuesedit.uom === "Numeric" 
                            && (
                              <>

                                <Input
                                  placeholder="Enter numeric value"
                                  className="uom-input mt-1"
                                  value={numerictargetValue}
                                  name="numerictarget"
                                  // onChange={(e) => setTargetValue(e.target.value)}
                                  onChange={(e) => {
                                    const inputVal = e.target.value;
                                    const numericRegex = /^[0-9]*$/;
                                    if (inputVal === '' || (parseInt(inputVal) <= 1000000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                      // handleTextChange(e, setObjectiveText, maxWords.remark_max_word_count);
                                      setNumericTargetValue(e.target.value)
                                      handleNumeric(e);
                                    }
                                  }}
                                />
                                {errors && errors.find(error => error.path[0] === 'target') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                                )}
                              </>
                            )}
                          {uomselectedOption === "Custom"
                            //  && keyResultsValuesedit.uom === "Custom" 
                            && (
                              <>

                                <Input
                                  placeholder="Enter custom value"
                                  className="uom-input mt-1"
                                  value={customtargetValue}
                                  name="customtarget"
                                  onChange={(e) => {
                                    setCustomTargetValue(e.target.value)
                                    handleCustom(e);
                                  }}
                                />
                                {errors && errors.find(error => error.path[0] === 'target') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                                )}
                              </>
                            )}
                        </Col>
                        {/* <Col xl={9} lg={8} md={8} sm={8} xs={24}>
                          <span
                            className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                            onClick={toggleCustomVisibility}
                          >
                            Click here to Update Target <ArrowRightOutlined />
                          </span>
                        </Col> */}
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row className="">
                        <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                        </Col>
                        <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                          <span
                            className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                            onClick={toggleCustomVisibility}
                          >
                            Custom Weightage <ArrowRightOutlined />
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {krShowCustom ? "" : <>
                        <div
                          className={
                            selectedMemValues
                              ? "assgin_selected_val"
                              : "" || selectedGrpValues
                                ? "assgin_selected_val"
                                : "" || mydetails != undefined ? "assgin_selected_val" : " "
                          }
                        >
                          {isSearchMem && selectedMemValues.length > 0 || mydetails != undefined && assignedusers.length == 0 ?
                            <div className="justify-between align-center">
                              <span className="c_000 fs-14 fw-700 m-0 mb-1">{selectedMemValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)}&nbsp;{selectedMemValues.length > 1 ? "Members" : "Member"}</span>
                              {uomselectedOption != "Custom" ? (
                                <span className="fw-700 c-000">Total: {sumTarget} {uomselectedOption === "Percentage" ? " % " : (
                                  uomselectedOption === "Currency" ? (selectedCurrency === "Thousands" ? "K" : (selectedCurrency === "Lakhs" ? "L" : "Cr"))
                                    : (uomselectedOption === "Numeric" ? "" : " "))}
                                </span>
                              ) : (<></>)
                              }
                            </div>
                            : ''}
                          {mydetails != undefined
                            &&

                            <div className="d-flex justify-between align-center mb-1">
                              <div className="d-flex align-center">
                                <img
                                  src={mydetails?.image}
                                  // alt={mydetails?.label}
                                  alt=""
                                  style={{ marginRight: "8px" }}
                                  onError={handleImageError}
                                  className="select_user"
                                />
                                {mydetails?.label}
                              </div>

                              {/* <input type="text" className="target_input_box" /> */}
                              {uomselectedOption != "Custom" ? (
                                <Input
                                  placeholder=""
                                  className="target_input_box"
                                  name="usertarget"
                                  value={mydetails?.usertarget}
                                  onChange={(e) => {
                                    const inputVal = e.target.value;
                                    const numericRegex = /^[0-9]*$/;
                                    if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                      handleAssignWeightage(e, mydetails?.value);
                                      // setUserTarget(e.target.value);
                                    }
                                  }}
                                />
                              ) : (
                                <>
                                  {/* <Input
                            placeholder=""
                            className="target_input_box"
                            name="usertarget"
                            // value={value.usertarget}
                            onChange={(e) => {
                              handleAssignWeightage(e, value.value);
                            }}
                          /> */}
                                </>
                              )
                              }
                            </div>
                          }
                          {selectedMemValues.map((value, index) => (
                            <>
                              <div className="d-flex justify-between align-center mb-1">
                                <div className="d-flex align-center">
                                  <img
                                    src={value.image}
                                    onError={handleImageError}
                                    // alt={value.label}
                                    alt=""
                                    style={{ marginRight: "8px" }}
                                    className="select_user"
                                  />
                                  {value.label}</div>
                                {/* <input type="text" className="target_input_box" /> */}
                                {uomselectedOption != "Custom" ? (
                                  <Input
                                    placeholder=""
                                    className="target_input_box"
                                    name="usertarget"
                                    value={value.usertarget}
                                    onChange={(e) => {
                                      const inputVal = e.target.value;
                                      const numericRegex = /^[0-9]*$/;
                                      if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                        // usertarget(e.target.value)
                                        handleAssignWeightage(e, value.value);
                                      }
                                    }}
                                  />
                                ) : (<>
                                  {/* <Input
                                        placeholder=""
                                        className="target_input_box"
                                        name="usertarget"
                                        // value={value.usertarget}
                                        onChange={(e) => {
                                          handleAssignWeightage(e, value.value);
                                        }}
                                      /> */}
                                </>
                                )
                                }
                              </div>
                            </>
                          ))}
                          {isSearchMem && selectedMemValues.length === 0 && mydetails?.label == null ? (
                            <>
                              <p className="c_AAA text-center">No members added</p>
                            </>
                          ) :
                            (<></>
                            )}
                          {isSearchMem === false && selectedGrpValues.length === 0 && mydetails?.label == null ? (
                            <>
                              <p className="c_AAA text-center">No groups added</p>
                            </>
                          ) :
                            (
                              <>
                              </>
                            )}
                          {assignedusers.map((value) => (
                            <Tag
                              key={value.value}
                              closable
                              onClose={() => handleTagClose(value.value)}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                color: "#000",
                                borderRadius: 15,
                                padding: "5px 10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src="assets/images/Default_user.svg"
                                alt=""
                                style={{ marginRight: "8px" }}
                                className="select_user"
                              />
                              {value.label}
                            </Tag>

                          ))}
                        </div>
                      </>}
                    </div>
                    {(keyResultsValuesedit.quarter === "Q1" && localStorage.getItem('year') === '2024-2025') &&
                      <Row className="mb-1 mt-1">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <label htmlFor="" className="c_32B7D9 fw-700 fs-14 mb-1">Checkin Frequency</label>
                          <Select
                            className="custom-select mb-1"
                            defaultValue="Monthly"
                            onChange={handleselectedCheckin}
                            value={userFrequency}
                            style={{ width: 600 }}
                          >
                            <Option value="Monthly">
                              <Radio
                                value="Monthly"
                                checked={userFrequency === "Monthly"}
                              >
                                Monthly
                              </Radio>
                            </Option>
                            <Option value="Fortnightly">
                              <Radio
                                value="Fortnightly"
                                checked={userFrequency === "Fortnightly"}
                              >
                                Fortnightly
                              </Radio>
                            </Option>
                            <Option value="Weekly">
                              <Radio
                                value="Weekly"
                                checked={userFrequency === "Weekly"}
                              >
                                Weekly
                              </Radio>
                            </Option>

                          </Select>
                        </Col>
                      </Row>
                    }
                    <div className="remark-section mt-1 mb-3">
                      <div className="justify-between">
                        <label className="canvas-objective-title c_32B7D9">
                          Remarks (Optional)
                        </label>
                        <span className="c-0050 fs-12 mt-1">
                          {keyResultsValuesedit.remarks.trim().split(/\s+/).join("").length} /{" "}
                          {maxWords.remark_max_word_count}
                        </span>
                      </div>
                      <div className="objective-title-inner">
                        <TextArea
                          rows={3}
                          placeholder=""
                          className="canvas-objective-input w-100"
                          name="remarks"
                          value={keyResultsValuesedit.remarks}
                          onChange={(e) =>
                            handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="Submit-section">
                      <button className="cancel-btn" onClick={krdec_cancel}>
                        Cancel
                      </button>
                      <button className="cyan_button ml-2" >Save</button>
                    </div> */}
                  </>
                )}
                <div className="Submit-section">
                  {isCustomVisible ? (
                    <>
                      <button
                        className="cancel-btn"
                        onClick={toggleCustomVisibility}
                      >
                        Back
                      </button>
                      <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }} disabled={isWeightageButton}>Submit</button>
                    </>
                  ) : (
                    <>
                      {/* {keyResultsValuesedit.status === 1 && */}
                      <button className="cancel-btn" onClick={onClose}>Cancel</button>
                      {/* } */}
                      {keyResultsValuesedit.status != 2 &&
                        <button className="cyan_button mr-1" onClick={() => { handleSubmit(1, false) }}
                          disabled={isButtonDisabled}
                        // disabled={clicked}
                        >
                          {isCustomVisible ? "Back" : "Update"}
                        </button>
                      }
                      <button
                        className={
                          keyResultsValuesedit.keytitle === "" ||
                            cycleselectedOption === "" ||
                            keyResultsValuesedit.uom === ""
                            ? "cascade_button"
                            : "cyan_button"
                        }
                        onClick={() => { handleSubmit(2, false) }}
                        disabled={isButtonDisabled}
                      // disabled={casclicked}
                      >
                        {keyResultsValuesedit.status == 1 ? "Cascade" : "Update"}
                      </button>

                    </>
                  )}
                </div>
              </Form>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};
export default KRDescription;