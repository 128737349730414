import React, { useState, useEffect } from "react";
import { Tabs, Row, Button, Spin } from "antd";
//  import ObjectiveWeightageTable, {  objectiveColumns } from "./ObjectiveWeightageTable";
import ObjectiveWeightageTable from "./ObjectiveWeightageTable";
// import KeyResultWeightageReport, {KeyResultColumns} from "./keyResultWeightageReport";
import KeyResultWeightageReport from "./keyResultWeightageReport";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { BaseUrl } from "../../../App";
import UserReport from "./UserReport";
import LogReport from "./LogReport";
import AppraisalReport from "./AppraisalReport";
import EmployeeObjective from "./EmployeeObjective";
import EmployeeKeyResult from "./EmployeeKeyResult";

const { TabPane } = Tabs;

const onChange = (key: string) => {
  // console.log(key);
};

interface Report {
  id: string;
  reportId: string;
  title: string;
  users: number;
}



// const reportData: Report[] = [
//   {
//     id: "RP101",
//     title: "Objective Weightage Fulfillment Report",
//     users: 314,
//   },
//   {
//     id: "RP102",
//     title: "Sales Performance Report",
//     users: 210,
//   },
//   {
//     id: "RP103",
//     title: "Employee Engagement Report",
//     users: 120,
//   },
// ];


interface DataType {
  key: string;
  employeeId: string;
  name: string;
  companyName: string;
  divisionName: string;
  cycle: string;
  mailId: string;
  assignedObjectiveCount: number;
  availObjWeightage: number;
  assignedKeyResultCount: number;
  availKrWeightage: number;
  managerId: number;
  managerName: string;
  hrBP: string;
  reviewerName: string;
  fh:string;
  bh:string;
}


interface userpermission {
  id: string,
  userid: string,
  userempid: string,
  createdAt: Date,
  status: number,
}



const downloadXLS = async (data: any[], columns: any[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Report");

  worksheet.columns = columns.map((column) => ({
    header: column.title as string,
    key: column.key as string,
    width: 20,
  }));

  data.forEach((item) => {
    worksheet.addRow(item);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "report.xlsx");
};

const downloadPDF = (data: any[], columns: any[]) => {
  const doc = new jsPDF("landscape", "mm", "a4");
  doc.text("Report", 10, 10);
  (doc as any).autoTable({
    head: [columns.map((column) => column.title as string)],
    body: data.map((item) =>
      columns.map((column) => item[column.key as keyof typeof item])
    ),
    startY: 20,
    theme: "striped",
    headStyles: { fillColor: [22, 160, 133] },
    styles: { fontSize: 8 },
  });
  doc.save("report.pdf");
};

const ReportComponent: React.FC = () => {
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [reportTable, setReportTable] = useState(true);
  const [reportsData, setReportDate] = useState<Report[]>([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usersObjectiveReport, setUsersObjectiveReport] = useState<DataType[]>([]);
  const [userreportpermission, setuserreportpermission] = useState<userpermission>();

  const handleTitleClick = (report: Report) => {
    setSelectedReport(report);
    setReportTable(false);
  };

  const back = () => {
    setReportTable(true);
  };

  // const handleXLS = (report:Report) => {
  //   if (report) {
  //     if (report.reportId === "RP01") {
  //       downloadXLS(usersObjectiveReport, objectiveColumns);
  //     } else if (report.reportId === "RP02") {
  //       downloadXLS(usersObjectiveReport, KeyResultColumns);
  //     }
  //   }
  // };


  // const handlePDF = (report: Report) => {
  //   if (report !=null) {
  //     if (report.reportId === "RP01") {
  //       downloadPDF(usersObjectiveReport, objectiveColumns);
  //     } else if (report.reportId === "RP02") {
  //       downloadPDF(usersObjectiveReport, KeyResultColumns);
  //     }
  //   }else{
  //     console.log(1)
  //   }
  // };

  const userReportPermissionUsers = async () => {

    try {
      const apiUrl = BaseUrl + `api/users/userpermission/permission/${localStorage.username}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setuserreportpermission(response.data);
          console.log("response user report permis : " + response.data)
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }

  useEffect(() => {
    Reports();
    UserData();
    userObjectiveData();
    userReportPermissionUsers();
  }, [])


  const UserData = async () => {

    try {
      const apiUrl = BaseUrl + `api/users/getByHrId`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setUsers(response.data);
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }


  const Reports = async () => {

    try {
      const apiUrl = BaseUrl + `api/reports/getAll`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setReportDate(response.data);
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }

  //obj weitage

  const userObjectiveData = async () => {

    try {
      const apiUrl = BaseUrl + `api/reports/getUsersObjective`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setUsersObjectiveReport(response.data);
          setLoading(false);

        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
      setError(true);
      setLoading(false);
    }

  };

  const hasUsers = users.length > 0;
  const hasPermission = userreportpermission?.status === 0 && userreportpermission.userempid != null;
  const admin = localStorage.getItem('rolename') === "Admin";
  return (
    <div>
      {reportTable ? (
        <Tabs defaultActiveKey="1" onChange={onChange} className="objective-tab">
          <TabPane tab="Reports" key="1">
            <div className="non-absolute">
              <div>
                <Row>
                  {reportsData.length > 0 && reportsData.map((report, index) => (
                    (((hasUsers && !admin) || (hasPermission && !admin)) || (!hasUsers && !hasPermission && (report.reportId === "RP03" || report.reportId === "RP04")) || (admin && (report.reportId !== "RP03" && report.reportId !== "RP04"))) &&
                    (<>
                      <div key={index} className="report_card p-2 m-1">
                        <div>
                          <span className="c_32B7D9 bg_C2FBFF pl-1 pr-1 fs-12">
                            {report.reportId}
                          </span>
                        </div>
                        <p
                          className="twoline_text_overflow fs-16 fw-700"
                          onClick={() => handleTitleClick(report)}
                          style={{ cursor: "pointer" }}
                        >
                          {report.title}
                        </p>
                        <div className="align-center">
                          {(report.reportId !== "RP03" && report.reportId !== "RP04") &&
                            <>
                              <img src="assets/images/report_person.svg" alt="" />
                              <span className="c_D6D7D7 fs-12">
                                {users.length} Users
                              </span></>}
                        </div>
                        <div>
                          <p className="mb-1 c_8080 fs-12">Available as</p>
                          <img src="assets/images/text_line_xls.svg" alt="" />
                          <img src="assets/images/text_line_pdf.svg" alt="" className="pl-1" />
                          {/* <Button
                          icon={<img src="assets/images/text_line_xls.svg" alt="" />}
                          // onClick={() =>{ handleTitleClick(report),handleXLS}}
                          // onClick={() => handleXLS(report)}
                          className="border-0"
                        />
                        <Button
                          icon={<img src="assets/images/text_line_pdf.svg" alt="" />}
                          className="pl-2 border-0"
                          // onClick={handlePDF}
                          // onClick={() => handlePDF(report)}
                        /> */}
                        </div>
                      </div>
                    </>)
                  ))}
                </Row>

                {/* {loading && <Spin className='justify-center align-center page-loader-full' tip="Loading" size="large">
                       <div className="content" />
                        </Spin>} */}
              </div>
            </div>
            <div></div>

          </TabPane>
          {(hasUsers || hasPermission)
            &&
            (<>
              <TabPane tab="User Report" key="2">
                <div className="non-absolute">
                  <UserReport />
                </div>
              </TabPane>
              {admin &&
                <TabPane tab="Log Report" key="3">
                  <div className="non-absolute">
                    <LogReport />
                  </div>
                </TabPane>
              }
              <TabPane tab="Appraisal Report" key="4">
                <div className='non-absolute'>
                  <AppraisalReport />
                </div>
              </TabPane>
            </>)

          }
        </Tabs>
      ) : (
        <>
          {selectedReport && (
            <div className="">
              <div className="report_tab">
                <div className="d-flex align-center">
                  <div onClick={back}>
                    <img
                      src="assets/images/arrow-right.svg"
                      alt=""
                      className="cur-pointer"
                    />
                  </div>
                  <h2 className="fs-16 c_000 ml-1">{selectedReport.title}</h2>
                </div>
                <div className="report_table">
                  {selectedReport.reportId ===
                    "RP01" && (
                      <ObjectiveWeightageTable usersObjectiveReport={usersObjectiveReport} error={error} loading={loading} />
                    )}
                  {selectedReport.reportId === "RP02" && (
                    <KeyResultWeightageReport userskeyResultReport={usersObjectiveReport} error={error} loading={loading} />
                  )}
                  {selectedReport.reportId === "RP03" && (
                    <EmployeeObjective />
                  )}
                  {selectedReport.reportId === "RP04" && (
                    <EmployeeKeyResult />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}

    </div>
  );
};

export default ReportComponent;
