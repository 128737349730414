import React, { useState, useEffect } from "react";
import { Row, Col, Input, Menu, Divider, Dropdown, Space, message, Modal, Spin, Radio, Select } from "antd";
import { Flex, Rate } from "antd";
import type { SelectProps } from 'antd';
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { RadioChangeEvent } from "antd/lib/radio";

const { TextArea } = Input;

interface ObjectiveApparisal {
  objectiveid: string;
  orgobjectiveid: string;
  parentid: string;
  objectiveStatus: string;
  createdby: string;
  objectivename: string;
  objectivedescription: string;
  weightage: number;
  objid: string;
  year: string;
  quarter: string;
  comments: string;
  objectiveweightage: number;
  keyresultdetails: keyResultApparisal[];
  appraisalstatus: number;
  overallEmployeeRating: number;
  overallSupervisorRating: number;
  reviewRatings: number;
  reviewRemarks: string;
}
interface objapr {
  objectiveid: string;
  remarks: string;
  userid: string;
  objectivepercentage: number;
  objrefid: string
}
interface krapr {
  objectiveid: string;
  krid: string;
  krpercentage: number;
  userid: string;
  remarks: string;
  krrefid: string;
  objrefid: string;
  selfratingsobj: number;
  selfremarksobj: string;
  krActualPercent: number;
  customUomType : string;
  
}

interface keyResultApparisal {
  keyresulttitle: string;
  keyresultdescription: string;
  keyresultstatus: number;
  objectiveid: string;
  keyresultid: string;
  krid: string;
  objid: string;
  weightage: number;
  krpercentage: number;
  comments: string;
  targetValue: number;
  uomType: string;
  amountType: string;
  krActualPercent: number;
  customUomType: string;
}

interface maxWords {
  name_max_word_count: number;
  description_max_word_count: number;
  remark_max_word_count: number;
};

interface overallRating {
  selfStatus: number;
  overallSelfRating: number;
  supervisorStatus: number;
  overallSupervisorRating: number;
  reviewerStatus: number;
  reviewerRating: number;
  reviewerRemarks: string;
};
const { Option } = Select;

const MyAppraisal = () => {

  interface OptionType {
    value: string;
    label: string;
    icon: React.ReactNode;
  }

  const options: OptionType[] = [
    {
      value: 'completed',
      label: 'Completed',
      icon: <Radio checked />,
    },
    {
      value: 'notCompleted',
      label: 'Not Completed',
      icon: <Radio />,
    },
  ];

  // const myapprasisalData = {
  //   objectives: [
  //     {
  //       id: "OB2",
  //       name: "Optimize UI/UX for seamless interaction. fdvhfdjvbd vfdbvjfvf vnfjvjdfv fdvfjvfndvbfdnbn  vbhjdfvh Optimize UI/UX for seamless interaction.",
  //       progress: "10%",
  //       status: 3,
  //       userId: "Vikram/Product/CITPL",
  //       keyResults: [
  //         {
  //           id: "KR01",
  //           objid: "OB2",
  //           name: "Improve navigation flow",
  //           progress: "10%",
  //           status: 3,
  //           icon: "assets/images/sm_KRA_Icon.svg",
  //         },
  //         {
  //           id: "KR02",
  //           objid: "OB2",
  //           name: "123",
  //           progress: "10%",
  //           status: 3,
  //           icon: "assets/images/sm_KRA_Icon.svg",
  //         },
  //       ],
  //     },
  //     {
  //       id: "OB23",
  //       name: "Optimize UI/UX for seamless interaction. fdvhfdjvbd vfdbvjfvf vnfjvjdfv fdvfjvfndvbfdnbn  vbhjdfvh Optimize UI/UX for seamless interaction.",
  //       progress: "10%",
  //       status: 3,
  //       userId: "Vikram/Product/CITPL",
  //       keyResults: [
  //         {
  //           id: "KR02",
  //           objid: "OB23",
  //           name: "Improve navigation flow",
  //           progress: "10%",
  //           status: 3,
  //           icon: "assets/images/sm_KRA_Icon.svg",
  //         },
  //       ],
  //     },
  //   ],
  // };
  const [myapprasisalData, setmyappraisaldata] = useState<ObjectiveApparisal[]>([])
  const [overallEmployeeRating, setOverallEmployeeRating] = useState<number>(0);
  const [overallSupervisorRating, setOverallSupervisorRating] = useState<number>(0);
  const [ReviewerRatingCount, setReviewerRatingCount] = useState<number>(0);
  const [Reviewertext, setReviewerText] = useState('');
  const [userOverAllRating, setUserOverAllRating] = useState<overallRating>({
    selfStatus: 0,
    overallSelfRating: 0,
    supervisorStatus: 0,
    overallSupervisorRating: 0,
    reviewerStatus: 0,
    reviewerRating: 0,
    reviewerRemarks: "",
  });

  const [ratings, setRatings] = useState(
    myapprasisalData.map(() => 3)
  );
  const [text, setText] = useState(() => {
    if (myapprasisalData.length > 0) {
      return myapprasisalData.map((obj) => obj.keyresultdetails.map(() => ""));
    } else {
      return [[]];
    }
  });
  const [krText, setKrText] = useState<string[][]>([[]]);
  const [submitted, setSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [arrow, setArrow] = useState<boolean[]>([])
  const [percentValues, setPercentValues] = useState<string[][]>([[]])
  const [targetValues, setTargetValues] = useState<string[][]>([[]])
  const [objectiveRatings, setObjectiveRatings] = useState(
    myapprasisalData.map(() => "0%")
  );
  const [loading, setLoading] = useState(true);

  const maxCharacters = 500;

  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  });


  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Appraisal");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  }

  const showOverAllUserAllRating = async () => {
    try {
      const apiUrl = BaseUrl + `api/Appraisal/user/overall/rating`;

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('username') || '');
      formData.append("year", '2024-2025');
      formData.append("quarter", 'Q1');
      // formData.append("year", localStorage.getItem('year') || '');
      // formData.append("quarter", localStorage.getItem('Quarter') || '');

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserOverAllRating(response.data.data);
      });
    } catch (error) {

    }

  }

  useEffect(() => {
    const allRated = ratings.every((rating) => rating > 0);
    const allCommented = text.every((text) => text.length > 0);
    // setIsButtonDisabled(!(allRated && allCommented));
    gettingappraisalformyself();
    setIsButtonDisabled(!(allRated && allCommented));
    getWordCount();
    showOverAllUserAllRating();
  }, [ratings, text]);

  const handleTextChange = (index: any, event: any) => {
    const newText = [...text];
    newText[index] = event.target.value;
    const newObjectiveRatings = [...myapprasisalData];
    newObjectiveRatings[index] = {
      ...newObjectiveRatings[index],
      comments: event.target.value
    };
    if (newObjectiveRatings.every(userdata => userdata.comments != null && userdata.comments != "")) {
      // console.log("Sdfsdf")
      setIsButtonDisabled(false)
    }
    else {
      setIsButtonDisabled(true)
    }
    setmyappraisaldata(newObjectiveRatings)
  };
  const gettingappraisalformyself = async () => {
    try {
      const api = `${BaseUrl}api/Appraisal/gettingappraisallist/${localStorage.username}/${localStorage.getItem("Quarter")}/${localStorage.getItem("year")}`
      const resp = await axios.get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const objdata: ObjectiveApparisal[] = resp.data.data;
      // if(objdata.length != 0){
      // setOverallEmployeeRating(objdata[0].overallEmployeeRating);
      // setOverallSupervisorRating(objdata[0].overallSupervisorRating);
      // setReviewerRatingCount(objdata[0].reviewRatings);
      // setReviewerText(objdata[0].reviewRemarks);
      // }
      if (objdata.some(data => data.appraisalstatus == 2)) {
        setSubmitted(true)
      }
      var keydatas: boolean[] = [];
      var percentddatas: string[][] = objdata.map(data => data.keyresultdetails.map(() => ""));
      objdata.map(data => data.keyresultdetails.map(keydata => keydatas.push(false)))
      setArrow(keydatas);
      setKrText(percentddatas)
      setPercentValues(percentddatas);
      setmyappraisaldata(resp.data.data);
      if (objdata.every(userdata => userdata.comments != null && userdata.comments != "")) {
        setIsButtonDisabled(true)
        // console.log("afsdcd");
      }
      else {
        setIsButtonDisabled(true)
      }
      setLoading(false);
    } catch (error) {

    }

  }

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const updateappraisalremarks = async (status: number) => {
    const api = `${BaseUrl}api/Appraisal/updateremarks/${status}`;
    const AppraisalObjectiveDTO: objapr[] = [];
    const AppraisalKRDTO: krapr[] = [];

    myapprasisalData.forEach(data => {
      const aprobj: objapr = {
        objectiveid: data.objectiveid,
        objrefid: data.objid,
        objectivepercentage: data.objectiveweightage,
        remarks: data.comments,
        userid: localStorage.loginId
      };
      AppraisalObjectiveDTO.push(aprobj);

      data.keyresultdetails.forEach(krdata => {
        const objid = AppraisalObjectiveDTO.find(obj => obj.objectiveid == krdata.objectiveid)
        const objrefid = objid == undefined ? "" : objid.objrefid
        const objremarks = objid == undefined ? "" : objid.remarks
        const objratings = objid == undefined ? 0 : objid.objectivepercentage
        const krapr: krapr = {
          objectiveid: krdata.objectiveid,
          objrefid: objrefid,
          krid: krdata.keyresultid,
          krrefid: krdata.krid,
          selfratingsobj: objratings,
          selfremarksobj: objremarks,
          krpercentage: krdata.krpercentage,
          remarks: krdata.comments,
          userid: localStorage.loginId,
          krActualPercent: krdata.krActualPercent,
          customUomType : krdata.customUomType,
        };
        AppraisalKRDTO.push(krapr);
      });
    });

    const AppraisalDTO = {
      appraisalKR: AppraisalKRDTO,
      appraisalObjective: AppraisalObjectiveDTO,
      quarter: localStorage.Quarter,
      year: localStorage.year,
      userid: localStorage.loginId,
      selfStatus: status,
      supervisorStatus: 0,
    };

    try {
      const resp = await axios.post(api, AppraisalDTO, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      // message.success({
      //   content: (
      //     <div className="success-message justify-center align-center p-1">
      //       <img src="assets/images/check-circle.svg" alt="Success Icon" />
      //       <span className="c_32C6A0 fs-16">
      //       Form submitted successfully!
      //       </span>
      //     </div>
      //   ),
      //   duration: 3,
      //   icon: null,
      // });
      //message.success("Form submitted successfully!");
      gettingappraisalformyself();
      showOverAllUserAllRating();
    } catch (error) {
      // console.error(error);
      message.error("Failed to submit form");
    }
  };
  const handleKRTextChange = (objIndex: any, krIndex: any, event: any) => {
    const newKrTexts = [...krText];
    newKrTexts[objIndex][krIndex] = event.target.value;
    const newObjectiveRatings = [...myapprasisalData];
    const keyresultdetails = [...myapprasisalData[objIndex].keyresultdetails];

    // Update the specific keyresultdetails item
    keyresultdetails[krIndex] = {
      ...keyresultdetails[krIndex],
      comments: event.target.value
    };
    newObjectiveRatings[objIndex] = {
      ...newObjectiveRatings[objIndex],
      keyresultdetails: keyresultdetails
    };
    setmyappraisaldata(newObjectiveRatings);
    // setKrText(newKrTexts);
  };

  const handleArrow = (index: any) => {
    const newArrow = [...arrow];
    newArrow[index] = !newArrow[index];
    setArrow(newArrow);
  };

  const handlePercentChange = (
    objIndex: number,
    krIndex: number,
    krTargetPercentage: number,
    // event: any
  ) => {
    // console.log("::::::PRIN" ,event.target.value);

    const newPercentValues = [...percentValues];
    newPercentValues[objIndex][krIndex] = krTargetPercentage.toString();
    setPercentValues(newPercentValues);

    // Calculate the percentage based on filled inputs
    const filledCount = newPercentValues[objIndex].filter(
      (value) => value.trim() !== ""
    ).length;
    const totalCount = newPercentValues[objIndex].length;
    const percentage = (filledCount / totalCount);


    // Calculate the average percentage of KR inputs
    const sumOfKRValues = newPercentValues[objIndex].reduce((sum, value) => {
      return sum + (value.trim() === "" ? 0 : parseFloat(value));
    }, 0);



    const newObjectiveRatings = [...myapprasisalData];
    const keyresultdetails = [...myapprasisalData[objIndex].keyresultdetails];

    // Update the specific keyresultdetails item
    // keyresultdetails[krIndex] = {
    //   ...keyresultdetails[krIndex],
    //   krpercentage: event.target.value
    //  };
    let sumAverage = 0;
    keyresultdetails.map((krPercent) => {
      sumAverage += krPercent.krpercentage
      console.log(krPercent.krpercentage, "krpercentage");

    })
    console.log(sumAverage, "sumAverage");
    console.log((sumAverage / totalCount), "sumAverageDivideeee");

    newObjectiveRatings[objIndex] = {
      ...newObjectiveRatings[objIndex],
      objectiveweightage: sumAverage,
      keyresultdetails: keyresultdetails
    };
    setmyappraisaldata(newObjectiveRatings)
    // setObjectiveRatings(newObjectiveRatings);
  };

  const calculateAverageRating = () => {
    const totalPercentage = objectiveRatings.reduce(
      (sum, rating) => sum + parseInt(rating),
      0
    );
    return objectiveRatings.length > 0
      ? (totalPercentage / objectiveRatings.length).toFixed(0)
      : 0;
  };

  const handlePercentageAndTargetChange = (objIndex: number, krIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    // handlePercentChange(objIndex, krIndex, event);
    // handleTargetChange(objIndex, krIndex, event);
  }

  const handleSubmit = (status: number) => {
    let status_text="";
    if(status==1)
    {
      status_text="save";
    }else{
      status_text="submit";
    }
    Modal.confirm({
      title: `Do you want to ${status_text} Appraisal?`,
      content: (
        <>
          {/* <p className="fs-16 mb-3 keytitle_scroll">"{organizationObjective.objectivename}"</p> */}
          <p className="fs-14 note_alert"><b>Note : </b>Check all objective and KR Rating and description is done</p>
        </>
      ),
      okText: 'OK',
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                saved successfully.
              </span>
            </div>
          ),
          duration: 3,
          icon: null,
        });
        updateappraisalremarks(status)
        if (status == 2) {
          setSubmitted(true);
        }
        //onClose();
        // text === "Save" ? createOrganizationObjective("1") :
        //   createOrganizationObjective("3")
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);

        // setTimeout(() => {
        //   // window.location.reload();
        //   bhCreateOrganization();
        // }, 2000);

        // saveobj()
        // console.log('Cascade action confirmed');
      },
      onCancel() {
      },
    });



    
  };

  // useEffect(() => {
  //   if (ratings.length > 0 && text.length > 0) {
  //     setIsButtonDisabled(false);
  //   } else {
  //     setIsButtonDisabled(true);
  //   }
  // }, [ratings, text]);

  const index = 0;
  const appraisalItems = (
    <Menu className="SignOff_dropdown">
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/RequestPending.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1"> Appraisal Pending</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/Signoff_Completed.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Appraisal Completed</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img src="assets/images/NotRequested.svg" alt="" className="WH_16" />
          <span className="fs-14 pl-1"> Not Submitted</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const getSuffix = (kr: any) => {
    if (kr.uomType === "Currency") {
      if (kr.amountType === "T") {
        return "T";
      } else if (kr.amountType === "L") {
        return "L";
      }
    } else if (kr.uomType === "Percentage") {
      return "%";
    }
    return "";
  };
  const [uomCustomOption, setUOMCustomOption] = useState<string>("Completed");
  const handleUOMSelect = (event: string) => {
    setUOMCustomOption(event);
  }
  const handleUOMRadio = (objIndex: number, krIndex: number, e: RadioChangeEvent) => {
    const uomCustomOptionvalue = e.target.value;
    let inputValue = 0;
    if (uomCustomOptionvalue === "Completed") {
      inputValue = 100;
    } else {
      inputValue = 0;
    }

    // Update percentValues state
    const newPercentValues = [...percentValues];
    newPercentValues[objIndex][krIndex] = inputValue.toString();
    setPercentValues(newPercentValues);

    // Update myappraisaldata state
    // setmyappraisaldata(prevData => {
    //   return prevData.map((obj, index) => {
    //     if (index === objIndex) {
    //       const newKeyResultDetails = obj.keyresultdetails.map((kr, checkIndex) => {
    //         if (checkIndex === krIndex) {
    //           // const targetPercentage = inputValue;
    //           // kr.krpercentage = inputValue;
    //           if (uomCustomOptionvalue === "Completed") {
    //             kr.customUomType = "Completed";
    //             kr.krpercentage = 100;
    //           } else {
    //             kr.customUomType = "Not Completed";
    //             kr.krpercentage = 0;
    //           }
    //           return {
    //             ...kr,
    //             // krActualPercent: inputValue,
    //             // krpercentage: inputValue
    //           };
    //         }
    //         return kr;
    //       });

    //       const sumTarget = newKeyResultDetails.reduce((sum, kr) => sum + kr.krpercentage, 0);
    //       const objectiveweightage = parseFloat((sumTarget / newKeyResultDetails.length).toFixed(2));

    //       return {
    //         ...obj,
    //         keyresultdetails: newKeyResultDetails,
    //         objectiveweightage
    //       };
    //     }
    //     return obj;
    //   });
    // });
    setmyappraisaldata(prevData => {
      const updatedData = prevData.map((obj, index) => {
        if (index === objIndex) {
          const newKeyResultDetails = obj.keyresultdetails.map((kr, checkIndex) => {
            if (checkIndex === krIndex) {
              const updatedKR = {
                ...kr,
                customUomType: uomCustomOptionvalue,
                krpercentage: uomCustomOptionvalue === "Completed" ? 100 : 0,
              };
              console.log('Updated Key Result:', updatedKR);
              return updatedKR;
            }
            return kr;
          });

          const sumTarget = newKeyResultDetails.reduce((sum, kr) => sum + kr.krpercentage, 0);
          const objectiveweightage = parseFloat((sumTarget / newKeyResultDetails.length).toFixed(2));

          return {
            ...obj,
            keyresultdetails: newKeyResultDetails,
            objectiveweightage
          };
        }
        return obj;
      });

      console.log('Updated myAppraisalData:', updatedData);
      return updatedData;
    });
    // setUOMCustomOption(uomCustomOptionvalue);
  };
  const kr = {
    uomType: 'Currency',
    targetValue: 100,
  };

  // const handleTargetChange = (objIndex: number, krIndex: number, value: string) => {
  //   const event = {
  //     target: {
  //       value: value,
  //     },
  //   } as React.ChangeEvent<HTMLInputElement>;
  //   const inputValue = event.target.value;

  //   // Update percentValues state
  //   const newPercentValues = [...percentValues];
  //   newPercentValues[objIndex][krIndex] = inputValue;
  //   setPercentValues(newPercentValues);

  //   // Update myappraisaldata state
  //   setmyappraisaldata(prevData => {
  //     return prevData.map((obj, index) => {
  //       if (index === objIndex) {
  //         const newKeyResultDetails = obj.keyresultdetails.map((kr, checkIndex) => {
  //           if (checkIndex === krIndex) {
  //             let targetPercentage;
  //             if (kr.uomType === "Custom") {
  //               if (value === "Completed") {
  //                 kr.krpercentage = 100;
  //                 kr.customUomType = "Completed";
  //                 targetPercentage = 100;
  //               } else {
  //                 kr.krpercentage = 0;
  //                 kr.customUomType = "Not Completed";
  //                 targetPercentage = 0;
  //               }
  //             } else {
  //               targetPercentage = (parseFloat(inputValue) / kr.targetValue) * 100;
  //               kr.krpercentage = parseFloat(targetPercentage.toFixed(2));
  //             }

  //             return {
  //               ...kr,
  //               krActualPercent: parseFloat(inputValue),
  //               krpercentage: parseFloat(targetPercentage.toFixed(2)),
  //             };
  //           }
  //           return kr;
  //         });

  //         const sumTarget = newKeyResultDetails.reduce((sum, kr) => sum + kr.krpercentage, 0);
  //         const objectiveweightage = parseFloat((sumTarget / newKeyResultDetails.length).toFixed(2));

  //         return {
  //           ...obj,
  //           keyresultdetails: newKeyResultDetails,
  //           objectiveweightage,
  //         };
  //       }
  //       return obj;
  //     });
  //   });
  // };

  const handleTargetChange = (objIndex: number, krIndex: number, event: React.ChangeEvent<HTMLInputElement>,krtarget:number) => {
    const inputValue = event.target.value;

    // Update percentValues state
    const newPercentValues = [...percentValues];
    newPercentValues[objIndex][krIndex] = inputValue;
    setPercentValues(newPercentValues);

    // Update myappraisaldata state
    setmyappraisaldata(prevData => {
      return prevData.map((obj, index) => {
        if (index === objIndex) {
          const newKeyResultDetails = obj.keyresultdetails.map((kr, checkIndex) => {
            if (checkIndex === krIndex) {
              const targetPercentage = (parseFloat(inputValue) / kr.targetValue) * 100;
              kr.krpercentage = parseFloat(targetPercentage.toFixed(2));
              if (kr.uomType === "Custom") {
                if (uomCustomOption === "Completed") {
                  kr.krpercentage = 100;
                } else {
                  kr.krpercentage = 0;
                }
              }
              return {
                ...kr,
                krActualPercent: inputValue === ''  ? 0 : parseFloat(inputValue)<=krtarget?parseFloat(inputValue):0,
                krpercentage: isNaN(targetPercentage)  ? 0 :  parseFloat(targetPercentage.toFixed(2))
              };
            }
            return kr;
          });

          const sumTarget = newKeyResultDetails.reduce((sum, kr) => sum + kr.krpercentage, 0);
          const objectiveweightage = parseFloat((sumTarget / newKeyResultDetails.length).toFixed(2));

          return {
            ...obj,
            keyresultdetails: newKeyResultDetails,
            objectiveweightage
          };
        }
        return obj;
      });
    });
  };
  
  // myapprasisalData.every((restrict) => restrict.keyresultdetails.every(krRestrict) => krRestrict.krpercentage != null)

  const allCommentsPresent = myapprasisalData.every(userdata => userdata.comments != null && userdata.comments.trim() !== "" && userdata.keyresultdetails.every(krRestrict => krRestrict.krActualPercent != null)) === false ? true : false;
  // console.log("new_status",allCommentsPresent);
  // console.log("Appraisaldata", myapprasisalData.every(userdata => userdata.comments != null && userdata.comments.trim() !== ""&& userdata.keyresultdetails.every(krRestrict => krRestrict.krActualPercent != 0)));
  return (
    <div className="permission_btn">
      {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
        <div className="content" />
      </Spin>}
      <div className="justify-end align-center z-1">
        {!submitted && (
          <>
            <button className="save_as_draft" onClick={() => handleSubmit(1)}>Save as draft</button>
            <button
              className={` ${myapprasisalData.every(userdata => userdata.comments != null && userdata.comments.trim() !== ""
                && userdata.keyresultdetails.every(krRestrict => krRestrict.krActualPercent > 0)) === false  ? "cascade_button" : " cyan_button"
                }`}
              // disabled={allCommentsPresent}
              disabled={myapprasisalData.every(userdata => userdata.comments != null && userdata.comments.trim() !== ""
                && userdata.keyresultdetails.every(krRestrict => krRestrict.krActualPercent > 0)) === false}
              onClick={() => handleSubmit(2)}
            >
              Submit
            </button>
          </>
        )}
        <Dropdown
          overlay={appraisalItems}
          trigger={["click"]}
          className="info_sign_off"
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <div className="p-1 ">
                <img
                  src="assets/images/Non-categorized.svg"
                  alt=""
                  className="cur-pointer"
                />
              </div>
            </Space>
          </a>
        </Dropdown>
      </div>
      {myapprasisalData.length === 0 && (
        <p className="signoff_border c_8080 fs-14">
          Sign-off the objectives to start Appraisal Process
        </p>
      )}
      {!submitted ? (
        <>
          <div>
            {myapprasisalData.map((objective, index) => (
              objective.quarter == "Q1" && objective.year == "2024-2025" && (
                <div key={index} className="borderbottom_EAEAEA mt-2">
                  <div className="objective_overall">
                    <table>
                      <thead
                        className={` d-flex text-left ${!submitted ? "appraisal pb-2" : "appraisal pb-2 "
                          }`}
                      >
                        <tr className="d-flex">
                          <th
                            className={`${!submitted ? "" : "align-center appraisal pb-2 "
                              }`}
                          >
                            <img
                              src={
                                arrow[index]
                                  ? "assets/images/up_arrow_accord.svg"
                                  : "assets/images/down_arrow_accord.svg"
                              }
                              alt=""
                              className="cur-pointer"
                              onClick={() => handleArrow(index)}
                            />
                          </th>
                          <th>
                            <div key={objective.objectiveid} className="pl-2 pr-2">
                              {/* Objective Card */}
                              <div className="myobj-card d-flex flex-column justify-between">
                                <div>
                                  <div className="d-flex justify-between w-100">
                                    <div className="wrapper_label align-center mb-2 ">
                                      <span className="objid_success align-center">
                                        <img
                                          src="assets/images/White_crown.svg"
                                          alt=""
                                          className="pr-1"
                                        />
                                        {objective.objid}
                                      </span>
                                      <span className="sign_off_triangle"></span>
                                    </div>
                                    <div
                                      className={`obj-status-${objective.objectiveStatus}`}
                                    ></div>
                                  </div>
                                  <p className="twoline_text_overflow fs-14 text-start">
                                    {objective.objectivename}
                                  </p>
                                </div>
                                <div className="d-flex align-center justify-between">
                                  <div className="d-flex align-center">
                                    <>
                                      <img
                                        src="assets/images/Network_Icon.svg"
                                        alt=""
                                      />
                                      <span className="fw-700 c_000">
                                        {objective.weightage}
                                      </span>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="text-start"
                              style={{ width: "270px" }}
                            >
                              <label htmlFor="" className="fs-12 fw-700">
                                Objective Rating Percentage
                              </label>
                              <p>{objective.objectiveweightage}</p>
                            </div>
                          </th>
                          <th>
                            <div
                              className="justify-between"
                              style={{ width: "670px" }}
                            >
                              <label className="fs-12 fw-700 ">Comments * </label>
                              <span
                                style={{
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  color: "gray",
                                }}
                              >
                                {text[index]?.length || 0}/{maxWords.remark_max_word_count}
                              </span>
                            </div>
                            <div className="objective-title-inner">
                              <TextArea
                                rows={5}
                                key={index}
                                placeholder="Write here...."
                                className="canvas-objective-input w-100"
                                name="remarks"
                                value={objective.comments}
                                onChange={(event) =>
                                  handleTextChange(index, event)
                                }
                                maxLength={maxWords.remark_max_word_count}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>

                      {arrow[index] && (
                        <>
                          <div className="">
                            <div key={objective.objectiveid} className="">
                              {objective.keyresultdetails.map((kr, krIndex) => (
                                <tbody key={kr.keyresultid}>
                                  <tr>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="pl-2 pr-2">
                                      <div className="SignOff_Kr_card d-flex flex-column justify-between p-1 mt-2 mb-2">
                                        <Row className="d-flex justify-between align-center">
                                          <div className="d-flex">
                                            <div className="container_label">
                                              <div className="wrapper_label align-center mb-1">
                                                <span className="sign_cup_org_bg align-center">
                                                  <img
                                                    src="assets/images/sm_crown.svg"
                                                    alt=""
                                                    className="mr-1"
                                                  />
                                                  {objective.objid}
                                                </span>
                                                <span
                                                  className="triangle"
                                                  style={{
                                                    borderBottom:
                                                      "25px solid #c2fbff",
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                            <div className="second_kr_label">
                                              <div className="wrapper_label align-center mb-1">
                                                <span
                                                  className="triangle_left"
                                                  style={{
                                                    borderTop:
                                                      "25px solid #c2fbff",
                                                    borderLeft:
                                                      "28px solid transparent",
                                                  }}
                                                ></span>
                                                <span className="sign_cup_org_bg align-center">
                                                  <img
                                                    src="assets/images/sm_KRA_Icon.svg"
                                                    alt=""
                                                    className="mr-1"
                                                  />
                                                  {kr.krid}
                                                </span>
                                                <span
                                                  className="triangle"
                                                  style={{
                                                    borderBottom:
                                                      "25px solid #c2fbff",
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                            <div className="d-flex align-center">
                                              <div
                                                className={`obj-status-${kr.keyresultstatus}`}
                                              ></div>
                                              <div className="d-flex align-center">
                                                <>
                                                  <img
                                                    src="assets/images/Network_Icon.svg"
                                                    alt=""
                                                  />
                                                  <span className="c_8080">
                                                    {kr.weightage}
                                                  </span>
                                                </>
                                              </div>
                                            </div>
                                          </div>
                                        </Row>
                                        <div className="fs-14 twoline_text_overflow fw-600">
                                          {kr.keyresulttitle}
                                        </div>
                                        <div className="justify-between">
                                          {kr.uomType != "Custom" &&
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="c_8080 fs-10"
                                              >
                                                Target :
                                              </label>{" "}
                                              <span className="c_000 fs-10">
                                                {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                {kr.uomType === "Custom" && <></>}
                                              </span>
                                            </div>}
                                          {/* <div>
                                          <label
                                            htmlFor=""
                                            className="c_8080 fs-10"
                                          >
                                            Achieved :
                                          </label>{" "}
                                          <span className="c_32B7D9 fs-10">
                                            ₹ 5 L
                                          </span>
                                        </div>  */}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <Row style={{ width: "270px" }}>
                                        <Col xl={12}>
                                          <label
                                            htmlFor=""
                                            className="fs-12 fw-700"
                                          >
                                            Actual *
                                          </label>
                                          <br />
                                          {kr.uomType != "Custom" ?
                                            (<>
                                              <Input
                                                className="percentage_input"
                                                key={index}
                                                prefix={kr.uomType === "Currency" ? "₹" : ""}
                                                suffix={getSuffix(kr)}
                                                // onChange={(e) => handleTargetChange(index, krIndex, e,kr.targetValue)}
                                                onChange={(e) => {
                                                  const inputVal = e.target.value;
                                                  const numericRegex = /^[0-9]*$/;
                                                  if (inputVal === '' || (parseInt(inputVal) <= kr.targetValue && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                                    handleTargetChange(index, krIndex, e, kr.targetValue);
                                                  }
                                                }}
                                                value={kr.krActualPercent}
                                              />
                                            </>) : (
                                              <>
                                                <Select
                                                  className="custom-select mt-1"
                                                  // style={{ width: 200 }}
                                                  placeholder="Completed"
                                                  onChange={handleUOMSelect}
                                                  value={kr.customUomType}

                                                >
                                                  <Option value="Completed"  >
                                                    <Radio
                                                      value="Completed"
                                                      checked={kr.customUomType === "Completed"}
                                                      onChange={(event) => handleUOMRadio(index, krIndex, event)}
                                                    >
                                                      <div>
                                                        <div className="ml-1"> Completed</div>
                                                      </div>
                                                    </Radio>
                                                  </Option>
                                                  <Option value="Not Completed">
                                                    <Radio
                                                      value="Not Completed"
                                                      checked={kr.customUomType === "Not Completed"}
                                                      onChange={(event) => handleUOMRadio(index, krIndex, event)}
                                                    >
                                                      <div>
                                                        <div className="ml-1">Not Completed</div>
                                                      </div>
                                                    </Radio>
                                                  </Option>
                                                </Select>
                                              </>
                                            )}
                                        </Col>
                                        <Col xl={12}>
                                          <div >
                                            <label
                                              htmlFor=""
                                              className="fs-12 fw-700"
                                            >
                                              KR Percentage *
                                            </label>
                                            <br />
                                            <p>{kr.krpercentage}%</p>
                                            {/*  {kr.uomType != "Custom" ? (<><p>{kr.krpercentage}%</p></>) : (<>
                                              <p>{uomCustomOption === "Completed" && "100%"}</p>
                                              <p>{uomCustomOption === "Not Completed" && "0%"}</p>
                                            </>)} */}


                                            {/* <Input
                                        type="number"
                                        className="percentage_input"
                                        suffix="%"
                                        key={krIndex}
                                        value={kr.krpercentage}
                                        onChange={(e) => {
                                          const inputVal = e.target.value;
                                          const numericRegex = /^([1-9][0-9]?|100)?$/;
                                          if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal)) ) {
                                            // handlePercentChange(
                                            //       index,
                                            //       krIndex,
                                            //       kr.krpercentage);
                                            // handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                            // console.log("inputvaluesdetails", inputVal)
                                          }else{

                                          }
                                        }}
                                       
                                        
                                        min={1}
                                        max={100}
                                        maxLength={3}
                                      /> */}
                                            {/*  // onChange={(event) =>
                                        //   handlePercentChange(
                                        //     index,
                                        //     krIndex,
                                        //     event
                                        //   )} */}
                                          </div>
                                        </Col>
                                      </Row>

                                    </td>
                                    <td>
                                      <div
                                        className="objective-title-inner"
                                        style={{ width: "670px" }}
                                      >
                                        <TextArea
                                          rows={3}
                                          placeholder="Write your remarks (optional)"
                                          className="canvas-objective-input w-100"
                                          name="remarks"
                                          value={kr.comments}
                                          onChange={(event) =>
                                            handleKRTextChange(
                                              index,
                                              krIndex,
                                              event
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </table>
                  </div>
                </div>
              )
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="appraisal_rating mt-1 mb-2">
            <Row>
              <Col xl={4} lg={4} md={4} sm={4}>
                <p className="c_121212 fs-12 fw-700">Employee Rating</p>
                <span className="c_018BB9 fs-30">{userOverAllRating.overallSelfRating != 0 && userOverAllRating.selfStatus >= 1 ? userOverAllRating.overallSelfRating + "%" : "-"} </span>
                {/* <span className="c_018BB9 fs-30">{overallEmployeeRating != 0 ? overallEmployeeRating + "%" : "-"} </span> */}
                {/* <span className="c_018BB9 fs-30">{`${calculateAverageRating()} %`}</span> */}
              </Col>
              <Col xl={4} lg={4} md={4} sm={4}>
                <p className="c_121212 fs-12 fw-700">Manager Rating</p>
                <div className="mt-2">
                  <span className="fs-20 c_121212 fw-700 ml-1">{userOverAllRating.overallSupervisorRating != 0 && userOverAllRating.supervisorStatus === 2 ? userOverAllRating.overallSupervisorRating + "%" : "-"}</span>
                  {/* <span className="fs-20 c_121212 fw-700 ml-1">{overallSupervisorRating !=0 ? overallSupervisorRating + "%" : "-" }</span> */}
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4}>
                <p className="c_121212 fs-12 fw-700">Reviewer Rating</p>
                <div className="mt-2">
                  <span className="fs-20 c_121212 fw-700 ml-1">{userOverAllRating.reviewerRating != 0 && userOverAllRating.reviewerStatus === 2 ? userOverAllRating.reviewerRating + "%" : "-"}</span>
                  {/* <span className="fs-20 c_121212 fw-700 ml-1">{ReviewerRatingCount != 0 ? ReviewerRatingCount + "%" :"-"}</span> */}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <p className="c_121212 fs-12 fw-700">Reviewer Comment</p>
                <p className="fs-14 mt-3 ">{userOverAllRating.reviewerRemarks != "" && userOverAllRating.reviewerStatus === 2 ? userOverAllRating.reviewerRemarks : "-"}</p>
                {/* <p className="fs-14 mt-3 ">{Reviewertext != "" ? Reviewertext : "-"}</p> */}
              </Col>
            </Row>
          </div>
          <div className="rating_scroll">
            {myapprasisalData.map((objective, index) => (
              objective.quarter == "Q1" && objective.year == "2024-2025" && (
                <div key={index} className="borderbottom_EAEAEA mt-2">
                  <div className="objective_overall">
                    <table>
                      <thead
                        className={`d-flex ${!submitted
                          ? "align-center appraisal  pb-2"
                          : "appraisal  pb-2 "
                          }`}
                      >
                        <tr className="d-flex">
                          <th
                            className={`${!submitted ? "" : "align-center appraisal  pb-2 "
                              }`}
                          >
                            <img
                              src={
                                arrow[index]
                                  ? "assets/images/up_arrow_accord.svg"
                                  : "assets/images/down_arrow_accord.svg"
                              }
                              alt=""
                              className="cur-pointer"
                              onClick={() => handleArrow(index)}
                            />
                          </th>
                          <th>

                            <div key={objective.objectiveid} className=" pl-2 pr-2">
                              {/* Objective Card */}
                              <div className="myobj-card d-flex flex-column justify-between">
                                <div>
                                  <div className="d-flex justify-between w-100">
                                    <div className="wrapper_label align-center mb-2 ">
                                      <span className="objid_success align-center">
                                        <img
                                          src="assets/images/White_crown.svg"
                                          alt=""
                                          className="pr-1"
                                        />
                                        {objective.objid}
                                      </span>
                                      <span className="sign_off_triangle"></span>
                                    </div>
                                    <div
                                      className={`obj-status-${objective.objectiveStatus}`}
                                    ></div>
                                  </div>
                                  <p className="twoline_text_overflow fs-14">
                                    {objective.objectivename}
                                  </p>
                                </div>
                                <div className="d-flex align-center justify-between">
                                  <div className="d-flex align-center">
                                    <>
                                      <img
                                        src="assets/images/Network_Icon.svg"
                                        alt=""
                                      />
                                      <span className="fw-700 c_000">
                                        {objective.weightage == null ? 5 : objective.weightage}
                                      </span>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </th>
                          <th>
                            <div
                              className="text-start"
                              style={{ width: "270px" }}
                            >
                              <label htmlFor="" className="fs-12 fw-700 mb-5">
                                Objective Rating Percentage
                              </label>
                              <p className="fs-14 fw-300">
                                {objective.objectiveweightage}
                              </p>
                            </div>
                          </th>
                          <th className="text-start">
                            <div style={{ width: "670px" }}>
                              <label
                                htmlFor=""
                                className="fs-12 fw-700 text-start"
                              >
                                Comments
                              </label>
                              <p className="text-start fs-12 c_121212 fw-300">
                                {objective.comments == null ? "-" : objective.comments}
                              </p>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      {arrow[index] && (
                        <>
                          <div className="">
                            <div key={objective.objectiveid} className=" pl-2 pr-2">
                              {objective.keyresultdetails.map((kr, krIndex) => (
                                <tbody key={kr.krid}>
                                  <tr>
                                    <td></td>

                                    <td className="pl-2 pr-2">
                                      <div className="SignOff_Kr_card d-flex flex-column justify-between p-1 mt-2 mb-2">
                                        <Row className="d-flex justify-between align-center">
                                          <div className="d-flex">
                                            <div className="container_label">
                                              <div className="wrapper_label align-center mb-1">
                                                <span className="sign_cup_org_bg align-center">
                                                  <img
                                                    src="assets/images/sm_crown.svg"
                                                    alt=""
                                                    className="mr-1"
                                                  />
                                                  {objective.objid}
                                                </span>
                                                <span
                                                  className="triangle"
                                                  style={{
                                                    borderBottom:
                                                      "25px solid #c2fbff",
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                            <div className="second_kr_label">
                                              <div className="wrapper_label align-center mb-1">
                                                <span
                                                  className="triangle_left"
                                                  style={{
                                                    borderTop:
                                                      "25px solid #c2fbff",
                                                    borderLeft:
                                                      "28px solid transparent",
                                                  }}
                                                ></span>
                                                <span className="sign_cup_org_bg align-center">
                                                  <img
                                                    src="assets/images/sm_KRA_Icon.svg"
                                                    alt=""
                                                    className="mr-1"
                                                  />
                                                  {kr.krid}
                                                </span>
                                                <span
                                                  className="triangle"
                                                  style={{
                                                    borderBottom:
                                                      "25px solid #c2fbff",
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                            <div className="d-flex align-center">
                                              <div
                                                className={`obj-status-${kr.keyresultid}`}
                                              ></div>
                                              <div className="d-flex align-center">
                                                <>
                                                  <img
                                                    src="assets/images/Network_Icon.svg"
                                                    alt=""
                                                  />
                                                  <span className="c_8080">
                                                    {kr.weightage}
                                                  </span>
                                                </>
                                              </div>
                                            </div>
                                          </div>
                                        </Row>
                                        <div className="fs-14 twoline_text_overflow fw-600">
                                          {kr.keyresulttitle}
                                        </div>
                                        {kr.uomType != "Custom" &&
                                          (
                                            <>
                                              <div className="justify-between">
                                                <div>
                                                  <label
                                                    htmlFor=""
                                                    className="c_8080 fs-10"
                                                  >
                                                    Target :
                                                  </label>{" "}
                                                  <span className="c_000 fs-10">
                                                    {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                    {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                    {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                    {kr.uomType === "Custom" && <></>}
                                                  </span>
                                                </div>
                                                <div>
                                                  <label
                                                    htmlFor=""
                                                    className="c_8080 fs-10"
                                                  >
                                                    Achieved :
                                                  </label>{" "}
                                                  <span className="c_32B7D9 fs-10">
                                                    {kr.uomType === "Percentage" && <>{kr.krActualPercent} %</>}
                                                    {kr.uomType === "Currency" && <>₹ {kr.krActualPercent} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                    {kr.uomType === "Numeric" && <>{kr.krActualPercent}</>}
                                                    {kr.uomType === "Custom" && <></>}
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )

                                        }
                                        <div className="justify-between">
                                          {/* <div>
                                            <label
                                              htmlFor=""
                                              className="c_8080 fs-10"
                                            >
                                              Target :
                                            </label>{" "}
                                            <span className="c_000 fs-10">
                                              ₹ 10 L
                                            </span>
                                          </div>
                                          <div>
                                            <label
                                              htmlFor=""
                                              className="c_8080 fs-10"
                                            >
                                              Achieved :
                                            </label>{" "}
                                            <span className="c_32B7D9 fs-10">
                                              ₹ 5 L
                                            </span>
                                          </div> */}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <Row style={{ width: "270px" }}>
                                        <Col xl={12}>
                                          <label
                                            htmlFor=""
                                            className="fs-12 fw-700"
                                          >
                                            Actual
                                          </label>
                                          <br />
                                          {/* {kr.uomType != "Custom" && (
                                        <><p>{kr.krActualPercent}</p></>
                                      )} */}
                                          {kr.uomType != "Custom" ? (
                                            <><p>{kr.krActualPercent}</p></>
                                          ) : (
                                            <>
                                              <p>{kr.customUomType}</p>
                                            </>
                                          )}
                                        </Col>
                                        <Col xl={12}>
                                          <div >
                                            <label
                                              htmlFor=""
                                              className="fs-12 fw-700"
                                            >
                                              KR Completion
                                            </label>
                                            <p>
                                              {kr.krpercentage < 0 ? "-" : kr.krpercentage}
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                      {/* <div style={{ width: "270px" }}>
                                        <label
                                          htmlFor=""
                                          className="fs-12 fw-700"
                                        >
                                          KR Completion
                                        </label>
                                        <p>
                                          {kr.krpercentage==0?"-":kr.krpercentage}
                                        </p>
                                      </div> */}
                                    </td>
                                    <td>
                                      <div
                                        className="objective-title-inner"
                                        style={{ width: "670px" }}
                                      >
                                        <label
                                          htmlFor=""
                                          className="fs-12 fw-700"
                                        >
                                          Employee Remarks
                                        </label>
                                        <p>
                                          {kr.comments == null ? "-" : kr.comments}
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </table>
                  </div>
                </div>
              )
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MyAppraisal;
