import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Flex, Avatar, Card, Tooltip, Spin } from "antd";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
import {
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";

interface MemObj {
    id: number,
    name: string,
    profileImagePath: string,
    designation: string,
    team: string,
    reportImagePath: string,
    weightage: string,
}

type Members = {
    name: string,
    employeeid: string,
    divisonName?: string,
    weightage?: number,
    id: string,
    // name: string,
    // profileImagePath: string,
    designation?: string,
    team?: string,
    reportImagePath: string,
    // weightage: string,
    profilePath: string,
    username?: string,
    email?: string,
    supervisor_id?: string,
    rolename?: string,
}

interface MemProfileProps {
    memberObjData: Members | null;
    onClose: () => void;
    percent: number;
}


interface TeamProfileProps {
    user: MemObj | null;
    onClose: () => void;
    percent: number;
}

interface LeadMeasure {
    id: number;
    krId: string;
    objId: string;
    orgObjId: string;
    description: string;
}

interface ObjectiveData {
    id: string;
    objectivename: string;
    description: string;
    individualweightage: number;
    name: string;
    year: string;
    objectivesuser_list: userslist[];
    quarter: string
}
interface userslist {
    userId: string;
    weightage: string;
}

interface KrData {
    id: string;
    objId: string;
    krName: string;
    krDesc: string;
    createdBy: string;
    createdByPath: string;
    cascadedBy: string;
    cascadedByPath: string;
    cycle: string;
    leadMeasures: LeadMeasure[];
}


type kr = {
    id: string,
    objectiveid: string,
    keytitle: string,
    description: string,
    remarks: string,
    target: string,
    amounttype: string,
    uom: string,
    status: number,
    keyid: string,
    objectivecode: string,
    quarter: string,
    orgobjectiveid: string,
    objectivename: string,
    keyResultUsersListAssignedTo: string[],
    createdbyempname: string

}

const leadMeasures1: LeadMeasure[] = [
    {
        id: 1,
        krId: '1',
        objId: '1',
        orgObjId: '1',
        description: 'Lead measure description for KR 1',
    },
    {
        id: 2,
        krId: '1',
        objId: '1',
        orgObjId: '1',
        description: 'Another lead measure description for KR 1',
    },
];

// const objectives1: ObjectiveData[] = [
//     {
//         id: '1',
//         objName: 'OB1-1',
//         objDesc: 'Conduct a thorough assessment of existing infrastructure',
//         weightage: 10,
//     },
//     {
//         id: '2',
//         objName: 'Objective 2',
//         objDesc: 'Description 2',
//         weightage: 20,
//     },
// ];

// const krs1: KrData[] = [
//     {
//         id: '1',
//         objId: '1',
//         krName: 'KR1',
//         krDesc: 'Achieve a 20% reduction in average server response time.',
//         createdBy: 'User 1',
//         createdByPath: 'assets/images/user.svg',
//         cascadedBy: 'User 2',
//         cascadedByPath: 'assets/images/user.svg',
//         cycle: 'Cycle 1',
//         leadMeasures: leadMeasures,
//     },
//     {
//         id: '2',
//         objId: '1',
//         krName: 'KR2',
//         krDesc: 'Another KR description.',
//         createdBy: 'User 1',
//         createdByPath: 'assets/images/user.svg',
//         cascadedBy: 'User 2',
//         cascadedByPath: 'assets/images/user.svg',
//         cycle: 'Cycle 1',
//         leadMeasures: leadMeasures,
//     },
//     {
//         id: '3',
//         objId: '2',
//         krName: 'KR3',
//         krDesc: 'Yet another KR description.',
//         createdBy: 'User 1',
//         createdByPath: 'assets/images/user.svg',
//         cascadedBy: 'User 2',
//         cascadedByPath: 'assets/images/user.svg',
//         cycle: 'Cycle 1',
//         leadMeasures: leadMeasures,
//     },
// ];

interface ImagePaginationProps {
    current: number;
    total: number;
    onChange: (page: number) => void;
    // imagePath: string;
    krId: string;
    leadMeasure: LeadMeasure[];

}


const MemberProfile: React.FC<MemProfileProps> = ({ percent, memberObjData, onClose }) => {
    const [getAllLeadMeasure, setAllLeadMeasure] = useState<LeadMeasure[]>([]);
    const [krs, setObjectiveAssignedkr] = useState<kr[]>([]);
    const [objectives, setObjectives] = useState<ObjectiveData[]>([]);
    const [krVisible, setKrVisible] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [currentMeasureIndex, setCurrentMeasureIndex] = useState<{ [key: string]: number }>(
        Object.fromEntries(
            krs.map((kr) => [kr.id, 0])
        )
    );

    const handleKrVisible = (index: number) => {
        setKrVisible(krVisible === index ? null : index);
        setCurrentMeasureIndex(prevIndexes => {
            const newIndexState: { [key: string]: number } = {};
            krs.forEach(kr => {
                if (kr.objectiveid === objectives[index].id) {
                    newIndexState[kr.id] = 0; // Set the default index to 0 for the first lead measure
                }
            });
            return { ...prevIndexes, ...newIndexState };
        });
    };


    const handleImageError = (e: any) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = 'assets/images/Default_user.svg';
    };

    useEffect(() => {
        objectivelist();
        LeadMeasureData();
    }, []);

    const getQuarterFromLocalStorage = () => {
        return localStorage.getItem('Quarter');
    };
    const getyearFromLocalStorage = () => {
        return localStorage.getItem('year');
    };
    const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
    const [year, setyear] = useState(getyearFromLocalStorage())
    useEffect(() => {
        const handleStorageChange = () => {
            setQuarter(getQuarterFromLocalStorage());
            setyear(getyearFromLocalStorage());
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handlePageChange = (page: number, objectiveId: string) => {
        setCurrentMeasureIndex(prevState => ({
            ...prevState,
            [objectiveId]: page
        }));
    };

    // const handleNextMeasure = (krId: string) => {
    //     setCurrentMeasureIndex(prevIndexes => {
    //         const kr = krs.find(kr => kr.id === krId);
    //         if (kr) {
    //             const currentIndex = prevIndexes[krId] || 0;
    //             const nextIndex = (currentIndex === kr.leadMeasures.length - 1) ? 0 : currentIndex + 1;
    //             return {
    //                 ...prevIndexes,
    //                 [krId]: nextIndex,
    //             };
    //         }
    //         return prevIndexes;
    //     });
    // };

    // const handlePrevMeasure = (krId: string) => {
    //     setCurrentMeasureIndex(prevIndexes => {
    //         const kr = krs.find(kr => kr.id === krId);
    //         if (kr) {
    //             const currentIndex = prevIndexes[krId] || 0;
    //             const prevIndex = (currentIndex === 0) ? kr.leadMeasures.length - 1 : currentIndex - 1;
    //             return {
    //                 ...prevIndexes,
    //                 [krId]: prevIndex,
    //             };
    //         }
    //         return prevIndexes;
    //     });
    // };

    if (!memberObjData) {
        return <div>User data not available</div>;
    }

    const { name, designation, team, weightage, divisonName } = memberObjData;
    const profilePicture = memberObjData.profilePath ? FileUrl + memberObjData.profilePath : "assets/images/Default_user.svg";
    let assignedPercent = percent;
    let completedPercent = 0;
    let unassignedPercent = 100 - percent;

    if (percent >= 100) {
        assignedPercent = 100;
        completedPercent = percent - 100;
        unassignedPercent = 0;
    } else if (percent <= 0) {
        assignedPercent = 0;
        completedPercent = 0;
        unassignedPercent = 100;
    }

    let strokeColor: string;
    if (percent === 100) {
        strokeColor = "#32B7D9"; // Completed
        completedPercent = 100;
    } else if (percent < 100) {
        strokeColor = "#C2FBFF"; // Assigned
    } else {
        strokeColor = "#EFEFEE"; // Unassigned
    }



    const ImagePagination: React.FC<ImagePaginationProps> = ({ leadMeasure, current, total, krId, onChange }) => {
        const filteredContentData = leadMeasure.filter(data => data.krId === krId);
        const currentItem = filteredContentData[current - 1];
        const displayImage = (
            <div className="d-flex align-center">
                <Avatar
                    // src={profilePicture}
                    className="avatar"
                // onError={handleImageError}
                // onError={(e: any) => { 
                //     const target = e.target as HTMLImageElement; 
                //     target.onerror = null; 
                //     target.src = 'assets/images/Default_user.svg'; 
                //     return false; // Explicitly return false to satisfy the return type requirement
                // }}
                />
                <span className="user_name mx-1">{name}</span>
            </div>

        );
        const displayText = (
            <span>
                <strong>{current}</strong> / {Math.ceil(total / 1)}
            </span>
        );


        return (
            <div className="image-pagination w-100 team_profile">
                <div className="pagination-head mb-2 ">
                    <Row className="mt-1 ">
                        <button
                            className="next_pre_btn"
                            disabled={current === 1}
                            onClick={() => onChange(current - 1)}
                            style={{ marginRight: "8px", color: "#fff" }}
                        >
                            <LeftOutlined />
                        </button>
                        <div className="d-flex">
                            {/* <span>{displayImage}</span> */}
                            <span>{displayText}</span>
                        </div>
                        <button
                            className="next_pre_btn"
                            disabled={current === Math.ceil(total / 1)}
                            onClick={() => onChange(current + 1)}
                            style={{ marginLeft: "8px", color: "#fff" }}
                        >
                            <RightOutlined />
                        </button>
                        {/* <Pagination
                  current={current}
                  total={total}
                  pageSize={1}
                  onChange={onChange}
                  showSizeChanger={false}
                /> */}
                    </Row>
                </div>
                {/* <h4>Description</h4> */}
                <div className="pagination-content">
                    <Card>{currentItem?.description}</Card>
                </div>
            </div>

        );
    };



    const LeadMeasureData = async () => {

        try {
            const apiUrl = BaseUrl + `api/leadMeasure/listById/` + memberObjData.id;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    //console.log("lead measure data  : ", response.data)
                    setAllLeadMeasure(response.data);
                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }

    }


    const getassignedkeyforobjectives = async (objectiveid: string) => {
        try {
            const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getKeyResults/${objectiveid}/${memberObjData.id}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
            //console.log("assigned key result lists ", assignedkeyresults.data);
            const assignedkr: kr[] = assignedkeyresults.data
            setObjectiveAssignedkr(assignedkr);
            if (assignedkeyresults.data.length === 0) {
                setObjectiveAssignedkr([])
            }
        } catch (error) {

        }
    }



    const objectivelist = async () => {
        try {
            const response = await axios.get(
                BaseUrl + "api/objectives/list/memberlist/" + memberObjData.id,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.accessToken}`
                    },
                }
            );
            //console.log("objectives data : ", response.data);
            setObjectives(response.data);
            setLoading(false);
        } catch (error) {
            // console.log("error : ", error);
        }
    };



    return (
        <>
            <div>
                <Row className="d-flex mb-0" onClick={onClose}>
                    <img src="assets/images/mem_arrow_left.svg" alt="" className="cur-pointer" />
                    <p className="c_32B7D9 fs-16 cur-pointer m-0">Member Profile</p>
                </Row>
                <Row className="border_bottom align-center">
                    <Col xl={6} lg={8} md={12} sm={24} xs={24}>
                        <div className="d-flex">
                            <img
                                src={profilePicture}
                                onError={handleImageError}
                                className="member_profile"
                                alt=""
                            />
                            <div className="pl-3">
                                <p className="m-0 fs-20 fw-800">{name}</p>
                                <p className="mt-1 fs-14">{designation}</p>
                                <p className="m-0 fw-500 fs-14">{divisonName}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={18} lg={16} md={12} sm={20} xs={20}>
                        {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
                            <div className="content" />
                        </Spin>}
                        <Flex className="objective_overall mt-3">
                            {objectives.length > 0 ? (objectives.map((obj: ObjectiveData, index: number) => (
                                obj.quarter === quarter && obj.year === year && (
                                    <div key={index}>
                                        <div className={` ${krVisible === index ? "member_card_active" : "member_card"}`} onClick={() => { handleKrVisible(index); getassignedkeyforobjectives(obj.id) }}>
                                            <Flex justify="space-between" align="center">
                                                <div className="container_label">
                                                    <div className="wrapper_label align-center mb-1">
                                                        <span className={` ${krVisible === index ? "cup_org_bg_active align-center" : "cup_org_bg_active align-center"}`}>
                                                            <img src="assets/images/crown.svg" className="mr-1" alt="" />
                                                            {obj.name}
                                                        </span>
                                                        <span className={` ${krVisible === index ? "triangle_active" : "triangle_active"}`}></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    {/* <img src="assets/images/menu.svg" alt="" className="pl-2" /> */}
                                                </div>
                                            </Flex>
                                            <Row>
                                                <p className="fs-16 fw-500 member_objname">
                                                    {obj.objectivename}
                                                </p>
                                            </Row>
                                            <Row className="justify-center">
                                                <p className="fs-10 text-center">
                                                    {obj.objectivesuser_list.map((userlist, index) => {
                                                        if (userlist.userId === memberObjData.id) {
                                                            return <>
                                                                Weightage : <span className="fs-16 fw-600">{userlist.weightage} %</span>
                                                            </>;
                                                        } else {
                                                            // You can optionally render something else for non-matching IDs
                                                            return null; // or return an alternative JSX element
                                                        }


                                                    })}
                                                    {/* Weightage : <span className="fs-16 fw-600">{obj.individualweightage} %</span> */}
                                                </p>
                                            </Row>
                                        </div>
                                    </div>
                                )
                            ))) : (<>
                                <Row className=" w-100">
                                    {/* <p>No Objectives Are Assigned</p> */}
                                    <Card className="Asp_nc_card w-100">
                                        <Row className="text-center obj_textbox">
                                            <span className="c_AAA fs-14 fw-600">No Data available</span>
                                        </Row>

                                    </Card>
                                </Row>
                            </>)}
                        </Flex>


                    </Col>
                </Row>

                <Row>
                    {objectives.map((obj: ObjectiveData, index: number) => (
                        obj.quarter === quarter && obj.year === year && (
                            <div key={index}>
                                {krVisible === index && (
                                    <p>
                                        {krs.filter(kr => kr.objectiveid === obj.id).length} Key Results for
                                        <b> {obj.objectivename}</b>
                                    </p>
                                )}
                            </div>
                        )
                    ))}
                </Row>
                {objectives.map((obj: ObjectiveData, index: number) => (
                    obj.quarter === quarter && obj.year === year && (
                        <div key={index}>
                            {krVisible === index && krs.filter(kr => kr.objectiveid === obj.id).map((kr: kr, krIndex: number) => (
                                <div key={krIndex}>
                                    <Row className="card p-2 mt-2 mb-2">
                                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                            <Row>
                                                <div className="container_label">
                                                    <div className="wrapper_label align-center mb-1">
                                                        <span className="cup_org_bg align-center">
                                                            <img src="assets/images/KRA-Icon.svg" alt="" />
                                                            {kr.keyid}
                                                        </span>
                                                        <span className="triangle"></span>
                                                    </div>
                                                </div>
                                            </Row>

                                            <Row>
                                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                    <p className="fs-16 c-000 m-0 cur-pointer">
                                                        {kr.keytitle}
                                                    </p>
                                                    <Row className="align-center">
                                                        <Col
                                                            xl={12}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                            className="align-center mt-3"
                                                        >
                                                            <Tooltip key={index} placement="top" title={name} arrow={true}>
                                                                <span className="align-center">
                                                                    <span className="mr-1">Created by </span>
                                                                    <img
                                                                        // src={kr.profilePath}
                                                                        src={profilePicture}
                                                                        onError={handleImageError}
                                                                        alt=""
                                                                        className="kr_user"
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col
                                                            xl={12}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                            className="align-center mt-3"
                                                        >
                                                            <span className="mr-1">Cascaded to</span>
                                                            {krs && krs.length > 0 && krs.map((kr) => (
                                                                <React.Fragment key={kr.id}> {/* Assuming kr.key is a unique identifier */}
                                                                    {kr.keyResultUsersListAssignedTo.length > 2 ? (
                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: "#000", backgroundColor: "#fff" }}>
                                                                            {kr.keyResultUsersListAssignedTo.slice(0, kr.keyResultUsersListAssignedTo.length).map((user: any, index) => (
                                                                                <>
                                                                                    <Tooltip placement="top" title={user.username} arrow={true}>
                                                                                        <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} onError={handleImageError} />
                                                                                    </Tooltip>
                                                                                </>
                                                                            ))}
                                                                        </Avatar.Group>
                                                                    ) : (
                                                                        kr.keyResultUsersListAssignedTo.map((user: any, index) => (
                                                                            <Tooltip placement="top" title={user.username} arrow={true}>
                                                                                <img
                                                                                    src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                                                                    className="avatar"
                                                                                    key={index}
                                                                                    onError={handleImageError}
                                                                                />
                                                                            </Tooltip>

                                                                        ))
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                            {/* <Avatar.Group
                                                                maxCount={2}
                                                                // size="large"
                                                                maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                                                            >
                                                                <Avatar
                                                                    // src={kr.cascadedByPath}
                                                                    className="avatar"
                                                                />
                                                                <Avatar
                                                                    src="assets/images/Avatar2.png"
                                                                    className="avatar"
                                                                ></Avatar>
                                                            </Avatar.Group> */}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="pl-2">
                                                    <Row className="obj-progress">
                                                        <Progress className="" percent={0} />
                                                    </Row>
                                                    <Row className="ml-1">
                                                        <Col
                                                            xl={12}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                            className="align-center mt-2"
                                                        >
                                                            <img src="assets/images/crown.svg" alt="" />
                                                            <span className="ml-1">{obj.name}</span>
                                                        </Col>
                                                        <Col
                                                            xl={12}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                            className="align-center mt-2"
                                                        >
                                                            <img src="assets/images/date-picker1.svg" alt="" />
                                                            {/* <span className="ml-1">{kr.cycle}</span> */}
                                                            <span className="ml-1">{kr.quarter} {obj.year}</span>
                                                        </Col>
                                                        <Col
                                                            xl={12}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                            className="align-center mt-2"
                                                        >
                                                            <img src="assets/images/message-chat.svg" alt="" />
                                                            <span className="ml-1">Discussion</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={24}
                                            className="obj-pagination pl-2"
                                        >
                                            <Row className="justify-between">
                                                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                                    <p className="fw-600">Lead Measures</p>
                                                </Col>
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-2">
                                                    <div className=" mt-1">
                                                        {getAllLeadMeasure.filter(data => data.krId === kr.id).length > 0 ? (
                                                            <div className="lead_measure">
                                                                <div className="d-flex image-pagination">
                                                                    <ImagePagination
                                                                        current={currentMeasureIndex[kr.id] || 1}
                                                                        total={getAllLeadMeasure.filter(data => data.krId === kr.id).length}
                                                                        onChange={(page: number) => handlePageChange(page, kr.id)}
                                                                        krId={kr.id}
                                                                        leadMeasure={getAllLeadMeasure}
                                                                    />

                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <Row>
                                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                                    <Card className="text-center c_AAA fs-14 lead_measure">No Lead Measures created yet.</Card>
                                                                </Col>
                                                            </Row>
                                                        )
                                                        }
                                                    </div>
                                                </Col>
                                                {/* <Col xl={12} lg={12} md={12} sm={12} xs={24} className="mb-2">
                                                <div className="text-end mt-1">
                                                    <button
                                                        className={`next_pre_btn ${currentMeasureIndex[kr.id] === 0 ? 'disabled' : ''}`}
                                                        onClick={() => handlePrevMeasure(kr.id)}
                                                        disabled={currentMeasureIndex[kr.id] === 0}
                                                    >
                                                        <LeftOutlined />
                                                    </button>
                                                    <span> {currentMeasureIndex[kr.id] + 1} / {kr.leadMeasures.length}</span>
                                                    <button
                                                        className={`next_pre_btn ${currentMeasureIndex[kr.id] === (kr.leadMeasures.length - 1) ? 'disabled' : ''}`}
                                                        onClick={() => handleNextMeasure(kr.id)}
                                                        disabled={currentMeasureIndex[kr.id] === (kr.leadMeasures.length - 1)}
                                                    >
                                                        <RightOutlined />
                                                    </button>
                                                </div>
                                            </Col> */}
                                            </Row>
                                            {/* <Row>
                                                <p>{kr.leadMeasures.length > 0 ? kr.leadMeasures[currentMeasureIndex[kr.id]]?.description : 'No Lead Measure'}</p>
                                            </Row> */}
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    )
                ))}
            </div>
        </>
    );
};
export default MemberProfile;
