import React, { useState, ChangeEvent, useEffect, memo } from "react";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
  Modal,
  message
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { log } from "console";
import { on } from "events";
import { handleDelete, handleproceed } from "../../alerts/alert";
import { Flex } from "antd/lib";
import * as z from 'zod';
import { NotificationContext } from "../../context/NotificationContext";


// import OrgObjectives from "../../Pages/Objective/SubObjectiveTab/OrgObjectives"
const { Option } = Select;

interface StompClient {
  send(destination: string, headers?: any, body?: any): void;
  // Define other methods and properties of StompClient if needed
}

interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: number;
  ImageUrl: string;

}

interface OptionItem {
  value: string;
  label: string;
  image: string;
  // weightage: number;
}
interface OptionItems {
  value: string;
  label: string;
  image: string;
  username: { name: any; id: any }[];
  // weightage: number;
}
interface Objective {
  name: string;
  objective: string;
}
interface OptionQuarter {
  value: string;
  label: string;
}

interface BandList {
  band: string;
};

interface orgGroup {
  fieldName: string;
  id: string;
} 
  
interface bhCreateOrganization{
  bhCreateOrganization:()=>Promise<void>;
}

const CreateObj: React.FC<bhCreateOrganization> = ({bhCreateOrganization}) => {

  const stompClient:any = React.useContext(NotificationContext);  
  const checkTokenExpiration = () => {
    const token = localStorage.getItem('accessToken');
    return token;
  };
  const getToken = setInterval(checkTokenExpiration, 10000);
 // console.log(checkTokenExpiration());
  const token = checkTokenExpiration();
  const [messagescount, setmessagescount] = useState(0);

  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [cycleselectedOption, setCycleSelectedOption] = useState<string>("");
  const [quarterselectedOption, setQuarterSelectedOption] = useState<string>("");

  const [dropdown, setDropDown] = useState<string>();
  const [VisibilityselectedOption, setVisibilitySelectedOption] = useState<string>("Only Assigned");
  const [bandList, setBandList] = useState<BandList[]>([]);
  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isWeightageButton, setIsWeightageButton] = useState(false);
  const [orgHiddenID, setOrgHiddenID] = useState<string>("");
  const [orgGroupList, setOrgGroupList] = useState<orgGroup[]>([]);
  const [orgGroupselectedOption, setOrgGroupSelectedOption] = useState<string>("");


  // const handleSubmitButton = (value) => {
  //   // Perform your submission logic here

  //   // Set clicked to true to disable the button
  //   setClicked(true);
  // };

  const [quarteropt, setQuarterOpt] = useState([]);

  const handleCycleSelect = (value: string) => {
    setDropDown(value);
    setCycleSelectedOption(value);
    const yearvalue = value;
    const apiUrl = BaseUrl + `api/organization/quarters`;
    axios.post(apiUrl, {
      "year": yearvalue,
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        const QuarterList = response.data;
        setQuarterOpt(QuarterList);
        setQuarterSelectedOption(QuarterList[0]);

        // console.log(response.data);
      });
  };

  const handleCycleRadio = (e: RadioChangeEvent) => {
    setCycleSelectedOption(e.target.value);
  };

  const handleVisibilitySelect = (value: string) => {
    setVisibilitySelectedOption(value);
    setOrganizationObjective(prevState => ({
      ...prevState,
      band: value
    }));
  };

  const handleOrgGroupSelect = (value: string) => {
    setOrgGroupSelectedOption(value);
    setOrganizationObjective(prevState => ({
      ...prevState,
      organizationGroup: value
    }));
  }

  const handleVisibilityRadio = (e: RadioChangeEvent) => {
    setVisibilitySelectedOption(e.target.value);
  };

  const handlehandleOrgGroupRadio = (e: RadioChangeEvent) => {
    setOrgGroupSelectedOption(e.target.value);
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };



  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const getBandList = async () => {
    try {
      const Lists = await axios.get(BaseUrl + "api/users/bandList");
      setBandList(Lists.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrgGroupList = async () => {
    try {
      const orgLists = await axios.get(BaseUrl + "api/orgGroupMaster/getAll");
      setOrgGroupList(orgLists.data);
      setOrgGroupSelectedOption(orgLists.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  const [userAlert, setUserAlert] = useState<OptionItem[]>([]);
  const selectedMemberList: userStoreDetails[] = [];

  // const handleAssignWeightage = (e: any, index: number) => {
  //   console.log("values  ",e.target.value)
  //   setShowCustomWeightage((prevUserDetails) => (
  //     prevUserDetails.map((item, i) =>
  //       i === index ? { ...item, assignWeightage: e.target.value } : item
  //     )
  //   ));

  //   setOrganizationObjective({
  //     ...organizationObjective,
  //     individualWeightage: "customized"
  //   }); 

  //  };
  // const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, index: number, currentPage: number) => {
  //   const { value } = e.target;

  //   // Calculate the index of the item in the flat array
  //   const flatIndex = (currentPage - 1) * 10 + index;

  //   // Clone the array of customWeightage objects
  //   const newCustomWeightage = [...showCustomWeightage];

  //   // Update the assignWeightage for the specific item
  //   newCustomWeightage[flatIndex] = { ...newCustomWeightage[flatIndex], assignWeightage: parseInt(value) || 0 };

  //   // Update the state with the modified array
  //   setShowCustomWeightage(newCustomWeightage);
  // };

  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { name, value } = e.target;

    // Find the item in the array based on its id
    const updatedCustomWeightage = showCustomWeightage.map(item => {
      if (item.id === itemId) {
        // Update the assignWeightage for the specific item
        return { ...item, [name]: value };
      }
      return item;
    });
    setShowCustomWeightage(updatedCustomWeightage);
    setOrganizationObjective({
      ...organizationObjective,
      individualWeightage: "customized"
    });

  };
type orgobj = {
    "objectivename": "",
    "quarter": "",
    "year": "",
    "description": "",
    "remark": "",
    "uid": "",
    "organizationStatus": "",
    "individualWeightage": "",
    "band": "",
    "groupid":string[]
  
}
  const userList: string[] = [];  //user has higher available weightage 
  const [organizationObjective, setOrganizationObjective] = useState({
    "objectivename": "",
    "quarter": "",
    "year": "",
    "description": "",
    "remark": "",
    "uid": "",
    "organizationStatus": "",
    "individualWeightage": "",
    "band": "",
    "organizationGroup":"",
    "groupid":[] as string[] 
  })
  const orgObjectivesSchemacascade = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    // individualWeightage: z.string().min(1, { message: 'Individual weightage field is required' }),
    // quarter: z.string().min(1, { message: 'Year field is required' }),
    band: z.string().min(1, { message: 'Band field is required' }),
    year: z.string().min(1, { message: 'Required' }),
    // orgGroupselectedOption: z.string().min(1, { message: 'Required'}),
  });
  const orgobjectivesave = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    year: z.string().min(1, { message: 'Required' })
  });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState<number>(0);
  let weightagePopupCount: number = 0;
  const [customweightagedetails, setCustomWeightageDetails] = useState<customWeightage[]>([]);

  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < showCustomWeightage.length; index++) {
      if (showCustomWeightage[index].avaiableWeightage < showCustomWeightage[index].assignWeightage) {
        const popupUser = {
          value: showCustomWeightage[index].username,
          label: showCustomWeightage[index].name,
          image: showCustomWeightage[index].profilePath !== null ? showCustomWeightage[index].profilePath : "",
        }
        weightagePopupCount++;
        greaterWeightageUser.push(popupUser);
        // setShowErrorMessage(weightagePopupCount);
      }
    }
    return greaterWeightageUser;
  }
  
  type showCustomWeightageModel = {
    modelid : string,
    modelname : string,
    createdAt : string,
    status : number,
  }
  const [showObjectiveWeightage, setShowObjectiveWeightage] = useState<showCustomWeightageModel>();

  const assignWeightObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/weightagemasterobjective/${1}`;
      await axios.get( apiUrl , {
        headers : {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setShowObjectiveWeightage(response.data);
      })
    } catch (error) {
    }
  
  }

  const [showAlertWeightage, setShowAlertWeightage] = useState<boolean>(true);

  const handleSubmit = (status: string, submit: boolean) => {

    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }
    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = orgObjectivesSchemacascade.safeParse(organizationObjective);
    const validationsave = orgobjectivesave.safeParse(organizationObjective);

    if (status == "3") {
      if (validationResult.success) {

        // Data is valid, proceed with your logic
        setErrors(null);

        // display the pop up weightage less than available 
        const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
        if(showObjectiveWeightage?.status === 0){
          if (weightagePopupCount === 0 || showAlertWeightage === false) {
            addorgobjective("Cascade", submit)
          } else {
            setShowAlertWeightage(false);
            weightagePopupCount === 1 ?
              handleDelete(greaterWeightageUser[0]?.label, greaterWeightageUser[0]?.image, organizationObjective.objectivename, "Org - Objective")
              : handleproceed(greaterWeightageUser, organizationObjective.objectivename, "Org - Objective");
          }
        } else {
          if (weightagePopupCount === 0) {
            addorgobjective("Cascade", submit)
          } else {
            weightagePopupCount === 1 ?
              handleDelete(greaterWeightageUser[0]?.label, greaterWeightageUser[0]?.image, organizationObjective.objectivename, "Org - Objective")
              : handleproceed(greaterWeightageUser, organizationObjective.objectivename, "Org - Objective");
          }
        }


      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else if (status === "1") {
      if (validationsave.success) {
        setErrors(null);
        addorgobjective("Save", submit)
      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
        // console.log("validationsave", validationsave.error.errors)
      }
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };

  const [individualWeightage, setIndividualWeightage] = useState<number>();
  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (text: string, record: any) => (
        <span className="align-center">
          <img src={record.profilePath ? FileUrl + record.profilePath : "assets/images/Default_user.svg"} onError={handleImageError} alt="" className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    // {
    //   title: "Weightage Available",
    //   dataIndex: "avaiableWeightage",
    //   key: "avaiableWeightage",
    //   render: (text: string, record: any) => 
    //     <>
    //   {showObjectiveWeightage?.status === 0 ?
    //   <span >{record.avaiableWeightage < 0 ? (0) : (record.avaiableWeightage)} %</span> :
    //   <span className={record.avaiableWeightage < record.assignWeightage ? "c_FF4747" : ""}>{record.avaiableWeightage < 0 ? (0) : (record.avaiableWeightage)} %</span>
    // }
    // </>
    //   // c_FF4747
    // },
    {
      title: "Weightage Assigned",
      dataIndex: "assignWeightage",
      key: "assignWeightage",
      render: (text: number, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            key={index}
            // type="number" 
            name="assignWeightage"
            value={record.assignWeightage || ''}
            // onChange={(e) => handleAssignWeightage(e, index)
            // }
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                handleAssignWeightage(e, record.id);
              }
            }}
            className={showObjectiveWeightage?.status === 0 ? "assigned_bg" : record.avaiableWeightage < record.assignWeightage ? "assigned_bg_error" : "assigned_bg"}
          />
          <span className="suffix">%</span>
          {record.avaiableWeightage > record.assignWeightage ||
            parseInt(organizationObjective.individualWeightage) > record.avaiableWeightage && (<></>
              // <span className="error-message">Assigned weightage cannot exceed available weightage</span>
            )}
        </div>
      ),
    },
  ];

  interface customWeightage {
    "id": string,
    "name": string,
    "username": string,
    "avaiableWeightage": number,
    "assignWeightage": number,
    "profilePath": string
  }
  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);

  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);


  // const custom_filteredData = dataSource.filter((record: DataSourceType) =>
  const custom_filteredData = showCustomWeightage.filter((record: customWeightage) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );


  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    setSelectedMemValues(selectedMemValues);
    userOrganizationList(userid);

    setUserAlert(selectedMemValues);
    showCustomWeightage.map((user: customWeightage) => {
      if (user.avaiableWeightage < Number(organizationObjective.individualWeightage)) {
        userList.push(String(user.name) + ',');
      }
    });

  };

  const handleMemTagClose = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
    setShowCustomWeightage(updatedMemValues1);

    // const userAlert = selectedMemValues.filter(
    //   (item) => item.value !== valueToRemove
    // );
    setUserAlert(updatedMemValues);

    showCustomWeightage.map((user: customWeightage) => {
      if (Number(organizationObjective.individualWeightage) < user.assignWeightage) {
        userList.push(user.name + ',');
      }
    });

  };
  const handleMemTagCloseonmemberchange = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    setShowCustomWeightage(updatedMemValues1);
  };



  const handleTagClose = (valueToRemove: string) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      valueToRemove=== opt.value
    );
    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    setAssignedUsers(updatedGrpValues);
    setSelectedGrpValues(updatedGrpValues);
    handleGrpSelectChange(updatedGrpValues)
    const updatedMemValues1 = showCustomWeightage.filter(value => 
      assignedusers.every(obj => 
          obj.username.every(user => user.id !== value.id)
      )
  );

    // setassigneduserweightage(updatedMemValues1);
  };
  // const handleTagCloseonchangegrp = (valueToRemove: string) => {
  //    const updatedMemValues1 = showCustomWeightage.filter(
  //     (value) =>value.id!== valueToRemove

  //   );
  //   setShowCustomWeightage(updatedMemValues1);
  // };
  const handleTagCloseonchangegrp = (valueToRemove: any[]) => {
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => !valueToRemove.includes(value.id)
    );
    setShowCustomWeightage(updatedMemValues1);
  };
  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );


  const [Memoptions, setMemOptions] = useState<OptionItem[]>([]);

  Memoptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });


  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([]);

  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage hide and show

  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [isSearchMem, setisSearchMem] = useState(true);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    if (isSearchMem == false) {
      // handleTagCloseonchangegrp(assignedusers);
      setAssignedUsers([]);
      setShowCustomWeightage([]);
    }
    else {
      setSelectedMemValues([]);
      setShowCustomWeightage([]);
      setSelectedGrpValues([]);
    }
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
  };

  const [open, setOpen] = useState(false);
  const { TextArea } = Input;

  const showDrawer = () => {
    setOpen(true);
    setUserAlert(userAlert);
    GetUserAccess();
    groupuserslist();
    userslist();
    // getcycleselectedOption();
    getWordCount();
    getBandList();
    assignWeightObjective();
    getOrgGroupList();
    // setOrganizationObjective(prevState => ({
    //   ...prevState,
    //   year: cycleselectedOption,
    //   band: VisibilityselectedOption,
    //   quarter: quarterselectedOption
    // }));
  };

  const onClose = () => {
    setOpen(false);
    setYearOpt(yearopt);
    setCycleSelectedOption("");
    setQuarterSelectedOption("");
    setOrganizationObjective({
      "objectivename": "",
      "quarter": "",
      "year": "",
      "description": "",
      "remark": "",
      "uid": "",
      "organizationStatus": "",
      "individualWeightage": "",
      "band": VisibilityselectedOption,
      "groupid":[],
      "organizationGroup":orgGroupselectedOption
    });
    setSelectedMemValues([]);
    setSelectedGrpValues([]);
    setAssignedUsers([]);
    setShowCustomWeightage([]);
    setErrors(null);
    setOrgHiddenID("");
    setShowAlertWeightage(true);
  };
  // work count start


  const rolename = localStorage.getItem("rolename");
  let viewButton = true;
  if (rolename === "TM") {
    viewButton = false;
  }


  const [objectiveText, setObjectiveText] = useState();
  const [descriptionText, setDescriptionText] = useState();
  const [remarkText, setRemarkText] = useState();


  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };
  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  })

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Organisation Objectives	");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const [yearopt, setYearOpt] = useState<OptionQuarter[]>([]);

  const getcycleselectedOption = async () => {
    try {
      const list = await axios.get(BaseUrl + "api/organization/getCycle");
      const listdata: string[] = list.data;
      setYearOpt(
        listdata.map((quarter: string) => ({
          value: quarter,
          label: quarter,
        }))
      );
      // setCycleSelectedOption(listdata[0].split(' ')[0]);
    } catch (error) {
      // Handle the error
      // console.error('Error fetching data:', error);
    }
  }

  const handleQuaterSelect = (value: string) => {
    setQuarterSelectedOption(value);
  }

  const handleQuarterRadio = (e: RadioChangeEvent) => {
    setQuarterSelectedOption(e.target.value);
  };
  // const getQuarterOption = as
  // useEffect(() => {
  //   setOrganizationObjective(prevState => ({
  //     ...prevState,
  //     quarter: cycleselectedOption,
  //     band :VisibilityselectedOption
  //   }));
  // }, [cycleselectedOption, VisibilityselectedOption]);

  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    // Check if the input exceeds the maximum word count
    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      // Update the state based on the input name
      if (Object.keys(organizationObjective).includes(inputName)) {
        setOrganizationObjective({
          ...organizationObjective,
          [inputName]: newText
        });

        // Additional logic for handling specific inputs
        if (inputName === 'individualWeightage') {
          const updatedUserDetails = showCustomWeightage.map((item) => ({
            ...item,
            assignWeightage: parseFloat(newText),
          }));
          setShowCustomWeightage(updatedUserDetails);

        }
      };
    }
  }



  const addorgobjective = (text: string, submit: boolean) => {
    if (text != "Save") {
      if (selectedMemValues.length == 0 && assignedusers.length == 0) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">"{organizationObjective.objectivename}"</p>
              <p className="fs-18"><b>Note : </b>AssignMembers is Mandatory</p>
            </>
          ),
          centered: true,
        })
      }
      // else if (showCustomWeightage.some(user => Number.isNaN(user.assignWeightage)) || showCustomWeightage.some(user => user.assignWeightage == 0)) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      else {
        Modal.confirm({
          title: 'Do you want to ' + text + ' this objective ?',
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">"{organizationObjective.objectivename}"</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Objective Created successfully.
                  </span>
                </div>
              ),
              duration: 3,
              icon: null,
            });
            onClose();
            text === "Save" ? createOrganizationObjective("1") :
              createOrganizationObjective("3")
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
              
            setTimeout(() => {
              // window.location.reload();
              bhCreateOrganization();
            }, 2000);

            // saveobj()
            // console.log('Cascade action confirmed');
          },
          onCancel() {
            setCasClicked(false);
            // console.log('Cascade action cancelled');
          },
        });
      }
    }
    else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage()
      Modal.confirm({
        title: 'Do you want to ' + text + ' this objective ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
            {greaterWeightageUser.length === 0 ? (
              <>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>
            ) : greaterWeightageUser.length === 1 ? (
              <>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
                    <div className='text-center'>
                      <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
                      <p>{greaterWeightageUser[0].value}</p>
                    </div>
                  </Col>
                  <Col xl={18} lg={18} md={18} sm={16} xs={24}>
                    <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                    <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>)}
          </>
        ),
        okText: text,
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Objective Created successfully.
                </span>
              </div>
            ),
            duration: 3,
            icon: null,
          });
          text === "Save" ? createOrganizationObjective("1") :
            createOrganizationObjective("3")
          if (submit == false) {
            onClose();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            setTimeout(() => {
              // window.location.reload();
            bhCreateOrganization()
            }, 2000);
          }
          else {
            toggleCustomVisibility()
          }
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          // saveobj()
          // console.log('Cascade action confirmed');
        },
        onCancel() {
          setClicked(false);
          // console.log('Cascade action cancelled');
        },
      });
    }
  }


  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      //const users = response.data; // Assuming response.data is an array of user objects
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {

        const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? FileUrl + id.profilePath : "assets/images/Default_user.svg", // Assuming you have an image property for each user
          weightage: 0
        };
      });
      setMemOptions(newOptions);
      // setMemOptions((prevMemOptions) => [
      //   ...prevMemOptions,
      //   { value: response.data.id, label: response.data.name, image: "" },
      // ]);

    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };


  interface userStoreDetails {
    empId: String;
    userId: String;
    // weightage: number;
  }


  // create an orgnanization Objective..
  const [objectiveUsersList, setObjectiveUsersList] = useState<userStoreDetails[]>([]);
  const createOrganizationObjective = async (organizationStatus: string) => {
    // if (orgHiddenID == "") {
    //   alert("orgHiddenID" + orgHiddenID);
      try {
        const apiUrl = BaseUrl + `api/organization/createorgObjective`;
        const selectedMemberList: userStoreDetails[] = [];

        showCustomWeightage.map((member) => {
          const user = {
            empId: member.username,
            userId: member.id,
            profilePath: "assets/images/Default_user.svg",
            avaiableWeightage: member.avaiableWeightage,
            weightage: member.assignWeightage,
          }


          selectedMemberList.push(user);
        })

        // assignedusers.map((member) => {
        //   const user1 = {
        //     empId: member.label,
        //     userId: member.value,
        //     weightage: 0
        //   }
        //   selectedMemberList.push(user1);
        // })


        setObjectiveUsersList(selectedMemberList);

        await axios.post(apiUrl, {
          "orgObjective": organizationObjective,
          "orgObjectiveUsersList": selectedMemberList,
          "saveOrCascade": organizationStatus
        }, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        })
          .then((response) => {
            const Id = response.data.orgObjective.id;
            setOrgHiddenID(Id);
            onClose();
            sendMessage();
            //window.location.reload();
          });
      } catch (error) {
        // console.log("ERROR :::::::::" + error);
      }
    // } else {
    //   alert("orgHiddenIDelse" + orgHiddenID);
    //   const apiUrl = BaseUrl + `api/organization/update/${orgHiddenID}`
    //   const selectedMemberList: userStoreDetails[] = [];

    //   showCustomWeightage.map((member) => {
    //     const user = {
    //       empId: member.username,
    //       userId: member.id,
    //       profilePath: "assets/images/Default_user.svg",
    //       avaiableWeightage: member.avaiableWeightage,
    //       weightage: member.assignWeightage,
    //     }
    //     selectedMemberList.push(user);
    //   })
    //   setObjectiveUsersList(selectedMemberList);
    //   await axios.put(apiUrl, {
    //     "orgObjective": organizationObjective,
    //     "orgObjectiveUsersList": selectedMemberList,
    //     "saveOrCascade": organizationStatus
    //   }, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    //     }
    //   }).then((response) => {
    //   })
    // }
  }

  const [customWeightageList, setCustomWeightageList] = useState<customWeightage[]>([]);

  // selected users for the custom weightage
  const userOrganizationList = async (userid: string[]) => {
    try {

      const apiUrl = BaseUrl + `api/users/selected_users`;

      const userObjectIdList: string[] = [];

      selectedMemValues.map((member) => {
        if (member.value !== null) {
          userObjectIdList.push(member.value);
        }
      })
      assignedusers.map((grpmembers) => {
        userObjectIdList.push(grpmembers.value)
      })
      await axios.post(apiUrl, userid, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          const role = localStorage.getItem("rolename");
          // const assignWeightageList : number[] = [];
          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: customWeightage, index: number) => {
            const existingUser = showCustomWeightage.find(user => user.id === res.id);
            if(existingUser){
              addedCustomWeightage.push(existingUser);
            }else{
              const customWeights = {
                id: res.id,
                name: res.name,
                profilePath: res.profilePath,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: parseInt(organizationObjective.individualWeightage),
              }
             addedCustomWeightage.push(customWeights);             
            }
           
            

            addedCustomWeightage.map((test: customWeightage) => {
              if (Number(organizationObjective.individualWeightage) > test.avaiableWeightage) {
                userList.push(test.username);
              }
            })

            for (let index = 0; index < response.data.length; index++) {
              if (parseInt(organizationObjective.individualWeightage) > response.data.avaiableWeightage) {
                userList.push(String(response.data.name) + ',');
                break;
              }
            }
          })
          setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
      console.log("ERROR::::::::", error);
    }
  }
  interface group {
    id: string,
    groupname: string,
    assignedusers: string[]
  }
  const groupofusersitems: OptionItems[] = [];
  const groupoptions: OptionItem[] = [];
  const [usersgroups, setusergroups] = useState<String[]>([])
  // async error shown so comment the API
  const groupuserslist = async () => {
    try {
      const groupslist = await axios.get(BaseUrl + "api/group/users_group",
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`
          }
        });
      const groupusersdatas: group[] = groupslist.data;
      const newoptions = groupusersdatas.map((id) => ({
        value: id.id,
        label: id.groupname,
        image: "",
        weightage: 0,
        username: id.assignedusers.map((userString: any) => {
          return {
            name: userString.name,
            id: userString.userid,
          };
        }), // Assuming you have an image property for each user
      }));
      setGrpOptions(newoptions);
    } catch (error) {
      
    }
  
  }

  // =================Roles And Permission==============================
  /*** Role and permission section start **/
  const [permission_orgobjcreate, setPermission_orgobjcreate] = useState('');
  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission:any)=>{
        if(permission.menu==="Create"){
          setPermission_orgobjcreate(permission.org_objective);
          //setPermission_lmview(permission.lead_measures)
        }
        // if(permission.menu==="Edit"){
        //   setPermission_objedit(permission.parent_objective);
        //   setPermission_childobjedit(permission.child_objective);
        // }
        // if(permission.menu==="Delete"){
        //   setPermission_objdelete(permission.parent_objective);
        //   setPermission_childobjdelete(permission.parent_objective)
        // }
        
      })
  
      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // =================Roles And Permission==============================
  /*** Role and permission section ends **/

  const sendMessage = async () => {
    try {
      const chatMessage = { name: "org_obj_created", content: 1 };
      if (stompClient) {
        stompClient?.send("/app/chat", {}, JSON.stringify(chatMessage));
      } else {
        // console.error("stompClient is null. Unable to send message.");
      }
    } catch (error) {
      
    }
   
  };

  useEffect(() => {
    getcycleselectedOption();
    GetUserAccess();
    setOrganizationObjective(prevState => ({
      ...prevState,
      year: cycleselectedOption,
      band: VisibilityselectedOption,
      quarter: quarterselectedOption,
      organizationGroup:orgGroupselectedOption
    }));
  }, [cycleselectedOption, VisibilityselectedOption, customweightagedetails, quarterselectedOption,stompClient,orgGroupselectedOption]);

  const [Groupvalues, setselectedfroupvalues] = useState([])
  // const handleGrpSelectChange = (selected: OptionItem[]) => {
  //   const assigneduservalues = [] 
  //   selected.forEach((item) => {
  //     const existingGroupItem = grouofusersitems.find((groupItem) => groupItem.value === item.value);

  //     if (existingGroupItem) {
  //       existingGroupItem.username = [...existingGroupItem.username.map((jsonvalues) => JSON.parse(jsonvalues).name)];
  //     } 
  //   });
  //   const updatedSelectedGrpValues = [...grouofusersitems];
  //   console.log(updatedSelectedGrpValues);
  //   setSelectedGrpValues(updatedSelectedGrpValues);
  // };
  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const handlesubmitcustomweightage = (status: string) => {
    // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }
    // Disable the button
    setIsWeightageButton(true);
    if (organizationObjective.objectivename === "" || organizationObjective.quarter === "") {
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              Objective,ObjectiveName And Quarter Is Mandatory
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      toggleCustomVisibility()
    }
    else {
      // Modal.confirm({
      //   title: 'Do you want to save the weightage this objective ?',
      //   content: (
      //     <>
      //       <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
      //       <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
      //     </>
      //   ),
      //   okText: 'Save',
      //   cancelText: 'Back',
      //   okButtonProps: { className: 'C_btn' },
      //   cancelButtonProps: { className: 'outline_btn' },
      //   centered: true,
      //   onOk() {
      //     message.success({
      //       content: (
      //         <div className="success-message justify-center align-center p-1">
      //           <img src="assets/images/check-circle.svg" alt="Success Icon" />
      //           <span className="c_32C6A0 fs-16">
      //             Weightage Saved successfully.
      //           </span>
      //         </div>
      //       ),
      //       duration: 2,
      //       icon: null,
      //     });
      //     toggleCustomVisibility();
      //   },
      //   onCancel() {
      //     setCasClicked(false);
      //     console.log('Cascade action cancelled');
      //   },
      // });
      message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Weightage Saved successfully.
                  </span>
                </div>
              ),
              duration: 2,
              icon: null,
            });
            toggleCustomVisibility();
     // addorgobjective("Save", true)
    }

    setTimeout(() => {
      // Enable the button
      setIsWeightageButton(false);
    }, 2000);
  }
  const handleGrpSelectChange = (selected: OptionItems[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    // const filteredEditMemberDetails = editmemberdetails.filter(val =>
    //   !selectedUserIds.includes(val)
    // );
    const selectedgrpids = selectedGrpValues.map(user=>user.value)
    setOrganizationObjective({
      ...organizationObjective,
      groupid: selectedgrpids
    });
    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues)
    userOrganizationList(selectedUserIds);
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };


  function customweightageAndCreateModel() {
    toggleCustomVisibility();
  }

  // word count end
  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>
  );

  return (
    <div className="">
      {
        viewButton && permission_orgobjcreate ? (
          <button className="cyan_button" onClick={showDrawer}>
            + Create Org Objective
          </button>
        ) : <p className="add_obj_btn"> &nbsp;</p>
        // (<button className="cyan_button" onClick={showDrawer}>
        //     + Create Objective
        //   </button>) "
      }
      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Weightage
            </div>
          ) : (
            "New Organisation Objective"
          )
        }
        size="large"
        onClose={onClose}
        open={open}
        maskClosable={false}
        className="objective-canvas position-relative"
      >
        <Form className="mb-5">
          <div>
            {isCustomVisible ? (
              <div className="cw_table">
                {isSearchMem && selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                <Row justify={"space-between"} className="mb-2">
                  <Col xl={6} lg={5} md={6} sm={6} xs={24} className="mb-2">
                    {/* <span
                      className="c_32B7D9 fs-12 cur-pointer"
                      onClick={toggleCustomVisibility}
                    >
                      <ArrowLeftOutlined /> Custom Weightage
                    </span> */}
                  </Col>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="position-relative"
                  >
                    {selectedMemValues.length > 0 ? (<div>
                      <input
                        type="search"
                        placeholder="Search Member"
                        className="w-100 table_search_inputbox pl-2"
                        value={searchTextValue}
                        // onSearch={handleSearch}
                        onChange={(e) => setSearchTextValue(e.target.value)}
                      />
                      <img
                        src="assets/images/search.svg"
                        className="table_search_img"
                        alt=""
                      />
                    </div>) : (
                      <div>
                        <input
                          type="search"
                          placeholder="Search Group"
                          className="w-100 table_search_inputbox pl-2"
                          value={searchTextValue}
                          // onSearch={handleSearch}
                          onChange={(e) => setSearchTextValue(e.target.value)}
                        />
                        <img
                          src="assets/images/search.svg"
                          className="table_search_img"
                          alt=""
                        />
                      </div>)}

                  </Col>
                </Row>
                <Table
                  dataSource={custom_filteredData}
                  columns={columns}
                  scroll={{ x: true }}
                  pagination={{
                    pageSize: 10, // Example page size
                    //current: currentPage,
                    // onChange: handlePageChange
                  }}
                />
              </div>
            ) : (
              <div>
                <div className="title-section mb-3">
                  <div className="justify-between">
                    <label htmlFor="objective" className="fw-600">
                      Objective Name <sup className="c_32B7D9">*</sup>
                    </label>
                    <span className="c-0050 fs-12 mt-1">
                      {organizationObjective.objectivename.trim().split(/\s+/).join("").length} /{" "}

                      {maxWords.name_max_word_count}
                    </span>
                  </div>
                  <div className="objective-title-inner">
                    <TextArea
                      id="objective"
                      rows={3}
                      placeholder="Write here...."
                      className="canvas-objective-input"
                      value={organizationObjective.objectivename}
                      onChange={(e) =>
                        handleTextChange(
                          e,
                          setOrganizationObjective,
                          maxWords.name_max_word_count
                        )
                      } name="objectivename"
                    />
                    {errors && errors.find(error => error.path[0] === 'objectivename') && (
                      <p className="danger">{errors.find(error => error.path[0] === 'objectivename')?.message}</p>
                    )}
                  </div>
                </div>
                <div className="desc-section mb-3">
                  <div className="justify-between">
                    <label htmlFor="" className="fw-600">
                      Description (Optional)
                    </label>
                    <span className="c-0050 fs-12 mt-1">
                      {organizationObjective.description.trim().split(/\s+/).join("").length} /{" "}
                      {maxWords.description_max_word_count}
                    </span>

                  </div>
                  <div className="objective-title-inner">
                    <TextArea
                      rows={3}
                      placeholder="Write here...."
                      className="canvas-objective-input"
                      value={organizationObjective.description}
                      onChange={(e) =>
                        handleTextChange(
                          e,
                          setOrganizationObjective,
                          maxWords.description_max_word_count
                        )
                      }
                      name="description"
                    />
                    {errors && errors.find(error => error.path[0] === 'description') && (
                      <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <Row className="my_obj">
                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                      <label htmlFor="" className="fw-600">
                        Year
                        <sup className="c_32B7D9">*</sup>
                      </label>
                      <br />
                      <Select
                        className="custom-select mt-1"
                        style={{ width: 200 }}
                        placeholder="Select an option"
                        onChange={handleCycleSelect}
                        value={organizationObjective.year}
                      // name="quarter"
                      >
                        {yearopt.map((option) => (
                          <Option value={option.value.split(' ')[0]}>
                            <Radio
                              value={option.value.split(' ')[0]}
                              checked={cycleselectedOption === option.value.split(' ')[0]}
                              onChange={handleCycleRadio}
                            >
                              {option.label}
                            </Radio>
                          </Option>
                        ))}
                      </Select>
                      {errors && errors.find(error => error.path[0] === 'quarter') && (
                        <p className="danger">{errors.find(error => error.path[0] === 'quarter')?.message}</p>
                      )}
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                      <label htmlFor="" className="fw-600">
                        Quarter <sup className="c_32B7D9">*</sup>
                      </label>
                      <br />
                      <Select
                        className="custom-select mt-1"
                        style={{ width: 200 }}
                        placeholder="Select an option"
                        onChange={handleQuaterSelect}
                        value={organizationObjective.quarter}
                      >
                        {quarteropt.map((option) => (
                          <Option value={option}>
                            <Radio
                              value={option}
                              checked={quarterselectedOption === option}
                              onChange={handleQuarterRadio}
                            >
                              {option}
                            </Radio>
                          </Option>
                        ))}
                      </Select>
                    </Col>                    
                  </Row>
                  {errors && errors.find(error => error.path[0] === 'year') && (
                    <p className="danger">{errors.find(error => error.path[0] === 'year')?.message}</p>
                  )}
                </div>
                <div className="mb-3">
                  <Row className="my_obj">
                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                      <label htmlFor="" className="fw-600 mt-3">
                        Visibility Upto
                        {/* <sup className="c_32B7D9">*</sup> */}
                      </label>
                      <br />
                      <Select
                        className="custom-select mt-1"
                        style={{ width: 200 }}
                        placeholder="Select an option"
                        onChange={handleVisibilitySelect}
                        value={VisibilityselectedOption}
                      >
                        <Option value="Only Assigned">
                          <Radio
                            value="Only Assigned"
                            checked={
                              VisibilityselectedOption === "Only Assigned"
                            }
                            onChange={handleVisibilityRadio}
                          >
                            Only Assigned
                          </Radio>
                        </Option>
                        {bandList.map((option) => (
                          option.band !== null && (

                            <Option value={option.band}>
                              <Radio
                                value={option.band}
                                checked={VisibilityselectedOption === option.band}
                                onChange={handleVisibilityRadio}
                              >
                                Upto {option.band}
                              </Radio>
                            </Option>
                          )
                        ))}
                      </Select>
                      {errors && errors.find(error => error.path[0] === 'band') && (
                        <p className="danger">{errors.find(error => error.path[0] === 'band')?.message}</p>
                      )}
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                      <label htmlFor="" className="fw-600 mt-3">
                        Organisation Groups
                      </label>
                      <br />
                      <Select
                        className="custom-select mt-1"
                        style={{ width: 200 }}
                        placeholder="Select an option"
                        onChange={handleOrgGroupSelect}
                        value={orgGroupselectedOption}
                      >
                      {orgGroupList.map((option) => (
                        option.fieldName !== null && (
                          <Option value={option.id}>
                            <Radio
                              value={option.id}
                              checked={orgGroupselectedOption === option.id}
                              onChange={handlehandleOrgGroupRadio}
                            >
                              {option.fieldName}
                            </Radio>
                          </Option>
                        )
                      ))}
                      </Select>
                    </Col>
                  </Row>
                  {/* {errors && errors.find(error => error.path[0] === 'orgGroupselectedOption') && (
                    <p className="danger">{errors.find(error => error.path[0] === 'orgGroupselectedOption')?.message}</p>
                  )} */}
                </div>
                <div className="assign-section mb-3">
                  <label htmlFor="" className="fw-600">
                    Assign Members <sup className="c_32B7D9">*</sup>
                  </label>
                  <span
                    className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                    onClick={handleSearchMember}
                  >
                    {isSearchMem ? "Search Groups" : "Search Members"}
                  </span>
                  <br />
                  <div className="mt-1">
                    {isSearchMem ? (
                      <Select
                        mode="multiple"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src="assets/images/member.svg"
                              alt="Placeholder"
                              style={{ marginRight: "8px" }}
                            />
                            <span>Select Member</span>
                          </div>
                        }
                        onChange={handleMemSelectChange}
                        value={selectedMemValues}
                        className="w-100 form-control"
                        labelInValue
                        optionLabelProp="label"
                        dropdownRender={(menu) => <div>{menu}</div>}
                        notFoundContent={<CustomEmptyMemberText />}
                        // options={Memoptions}
                        // filterOption={(input, option) =>
                        //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        // }
                        filterOption={(input, option) =>
                          option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                      >
                        {Memoptions.map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                          >
                            {renderOption(option)}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        mode="multiple"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src="assets/images/people.svg"
                              alt="Placeholder"
                              style={{ marginRight: "8px" }}
                            />
                            <span>Select Group</span>
                          </div>
                        }
                        onChange={handleGrpSelectChange}
                        value={selectedGrpValues}
                        className="w-100 form-control"
                        labelInValue
                        optionLabelProp="label"
                        dropdownRender={(menu) => <div>{menu}</div>}
                        notFoundContent={<CustomEmptyGroupText />}
                        // options={GrpOptions}
                        // filterOption={(input, option) =>
                        //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        // }
                        filterOption={(input, option) =>
                          option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                      >
                        {GrpOptions.map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                          >
                            {renderOption(option)}
                          </Option>
                        ))}
                      </Select>
                    )}
                    <div
                      className={
                        selectedMemValues
                          ? "assgin_selected_val"
                          : "" && selectedGrpValues
                            ? "assgin_selected_val"
                            : ""
                      }
                    >
                      {isSearchMem && selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                      {selectedMemValues.map((value) => (
                        <Tag
                          key={value.value}
                          closable
                          onClose={() => handleMemTagClose(value.value)}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            color: "#000",
                            borderRadius: 15,
                            padding: "5px 10px",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >

                          <img
                            src={value.image}
                            onError={handleImageError}
                            alt=""
                            style={{ marginRight: "8px" }}
                            className="select_user"
                          />
                          {value.label}
                        </Tag>
                      ))}
                      {isSearchMem && selectedMemValues.length === 0 ? (
                        <>
                          <p className="c_AAA text-center">No members added</p>
                        </>
                      ) :
                        (<></>
                        )}
                      {isSearchMem === false && selectedGrpValues.length === 0 ? (
                        <>
                          <p className="c_AAA text-center">No groups added</p>
                        </>
                      ) :
                        (
                          <></>
                        )}
                      {assignedusers.map((value) => (
                          <Tag
                            key={value.value}
                            closable
                            onClose={() => handleTagClose(value.value)}
                            style={{
                              marginRight: "5px",
                              marginBottom: "5px",
                              color: "#000",
                              borderRadius: 15,
                              padding: "5px 10px",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="assets/images/Default_user.svg"
                              alt=""
                              style={{ marginRight: "8px" }}
                              className="select_user"
                            />
                            {value.label}
                          </Tag>
                        
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <Row className={isCustomVisible ? "d-none" : ""}>
                    {/* <Col xl={19} lg={19} md={18} sm={12} xs={24}> */}
                    {/* <Row className="align-center"> */}
                    {/* <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                          <label htmlFor="" className="fw-600">
                            Individual Weightage <sup className="c_32B7D9">*</sup>
                          </label>
                          <Input
                            placeholder=""
                            className="canvas-objective-input mt-1"
                            type="text"
                            value={organizationObjective.individualWeightage}
                            // onChange={
                            //   (e) => {
                            //     handleTextChange(e,
                            //       setIndividualWeightage,
                            //       maxWords.description
                            //     )
                            //   }
                            // }
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === 'customized' ||inputVal === ''|| (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                                // console.log("inputvaluesdetails",inputVal)
                            }
                            }}
                            name="individualWeightage"
                            suffix="%"

                          />
                          {errors && errors.find(error => error.path[0] === 'individualWeightage') && (
                            <p className="danger">{errors.find(error => error.path[0] === 'individualWeightage')?.message}</p>
                          )}
                        </Col>
                        <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                          <p className="pl-2 pt-2">
                            {showErrorMessage == 0 ?
                              "i.e, Weightage only be a Percentage" :
                              (<span className="c_EC174F">Weightage error. Please check Custom weightage</span>)}
                          </p>


                        </Col> */}
                    {/* </Row> */}
                    {/* </Col> */}
                    {/* <Col xl={5} lg={5} md={6} sm={12} xs={24}> */}
                    <span
                      className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                      onClick={customweightageAndCreateModel}
                    >
                      Custom Weightage <ArrowRightOutlined />
                    </span>
                    {/* </Col> */}
                  </Row>
                </div>

                <div className="remark-section mb-3">
                  <div className="justify-between">
                    <label className="canvas-objective-title">Remarks (Optional)</label>
                    <span className="c-0050 fs-12 mt-1">
                      {organizationObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                      {maxWords.remark_max_word_count}
                    </span>
                  </div>
                  <div className="objective-title-inner">
                    <TextArea
                      rows={3}
                      placeholder="Write here...."
                      className="canvas-objective-input w-100"
                      name="remark"
                      value={organizationObjective.remark}
                      onChange={(e) =>
                        handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="Submit-section">
              {isCustomVisible ? (
                <>
                  <button className="cancel-btn"onClick={toggleCustomVisibility}>Back</button>
                  <button className="cyan_button" >submit</button>
                </>
              ) : (
                <>
                  <button className="save-btn">Save</button>
                  <button className="cyan_button" onClick={addorgobjective}>Cascade</button>
                </>
              )}
            </div> */}
            {/* <div className="Submit-section"> */}
            {/* <button className="cancel-btn" onClick={toggleCustomVisibility}> {isCustomVisible ? "Back" : "Save"}</button> */}
            {/* <button className="cancel-btn" onClick={createOrganizationObjective}> {isCustomVisible ? "Back" : "Save"}</button>
              <button className="cyan_button" onClick={addorgobjective}> {isCustomVisible ? "Submit" : "Cascade"}</button>
            </div> */}

            {/* {0 < showErrorMessage ? <span className="c_FF4747">
              Disclaimer : <sup >*</sup> User has exceeded 100% weightage.
              </span> : null} */}
            <div className="Submit-section">
              {isCustomVisible ? (
                <>
                  <button
                    className="cancel-btn"
                    onClick={toggleCustomVisibility}
                  >
                    Back
                  </button>
                  <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }}
                    disabled={isWeightageButton}
                  >Submit</button>
                </>
              ) : (
                <>
                  <button className="save-btn" onClick={() => { handleSubmit("1", false) }}
                    disabled={isButtonDisabled}
                  >
                    {isCustomVisible ? "Back" : "Save"}
                  </button>
                  {/* <button className={ disabled = } onClick={() => addorgobjective("Cascade")} ? ("cascade_button") :(cyan_button)> */}
                  <button
                    className={
                      organizationObjective.objectivename === "" ||
                        cycleselectedOption === ""
                        // organizationObjective.individualWeightage === ""
                        // || 0 < showErrorMessage ||
                        ? "cascade_button"
                        : "cyan_button"
                    }
                    onClick={() => { handleSubmit("3", false); }}
                    disabled={isButtonDisabled}

                  // disabled={casclicked}
                  >
                    {/* {isCustomVisible ? "Submit" : "Cascade 112"} */}
                    {isCustomVisible ? "Submit" : "Cascade"}
                  </button>
                </>
              )}
            </div>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default CreateObj;
