import React, { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Col, Row, Button, Flex, Radio, Card, Avatar, MenuProps, Dropdown, Space, message ,Progress ,Tooltip} from "antd";
import CreateGroup from "../Modal/CreateGroup";
import { Link } from "react-router-dom";
import TeamProfile from "./TeamProfile";
import { BaseUrl, FileUrl } from "../../../App";
import { useEffect } from "react";
import axios from "axios";
import EditGroup from "../Modal/EditGroup";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;


interface User {
    id: number;
    name: string;
    profileImagePath: string;
    position: string;
    team: string;
    count: number;
}

interface UserCardProps {
    usersData: User[];
}

interface group {
    id: string,
    groupname: string,
    assignedusers: any[]
}


const MyTeam: React.FC<UserCardProps> = ({ usersData }) => {
    const [profileVisible, setProfileVisible] = React.useState(false);
    // const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [selectedUser, setSelectedUser] = useState<Members | null>(null);
    const [Memberoption, setMemberOption] = useState<Members[]>([]);
    // const [groupValue, setGroupValue] = React.useState("All");
    const [groupValue, setGroupValue] = useState<group>({
        id: '',
        groupname: '',
        assignedusers: []
    });
    const [allMembers, setAllMembers] = useState<group>({
        id: '',
        groupname: '',
        assignedusers: Memberoption,
    });
    const handleAllSelect = (e: group) => {
        console.log("working ")
        setGroupValue({
            id: '',
            groupname: '',
            assignedusers: Memberoption,
        });
    }
    const onGroupChange = (e: RadioChangeEvent) => {
        const group = e.target.value;
        if (!group || !group.groupname) {
            setSelectedMember(Memberoption);
        } else {
            // Memberoption.map(e=>console.log(" member id : ",e.id));
            // group.assignedusers.map((e:any) =>console.log("group member id : ",e.id));
            //  const selectedMembers = Memberoption.filter((option:any) => group.assignedusers.includes(option.id));
            if (Memberoption && Memberoption.length > 0) {
                const selectedMember = Memberoption.filter((option: any) => {
                    if (group.assignedusers && group.assignedusers.length > 0) {
                        return group.assignedusers.some((user: any) => user.id === option.id);
                    }
                    // return group.assignedusers.some((user: any) => user.id === option.id);
                    return false;
                });
                setSelectedMember(selectedMember);
            }
        }
        setGroupValue(group);
        // console.log("selected memebres : ", selectedMember);

    };
    const handleCardClick = (user: Members) => {
        setSelectedUser(user);
        setProfileVisible(true);
    };
    

    const [selectedMember, setSelectedMember] = useState<Members[]>([]);

    const handleImageError = (e: any) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = 'assets/images/Default_user.svg';
      };
// =================Roles And Permission==============================
const [permission_groupedit, setPermission_groupedit] = useState('');
const [permission_groupdelete, setPermission_groupdelete] = useState('');

// =================Roles And Permission==============================
      const GetUserAccess = async () => {
  
        try {
          const role = localStorage.getItem('rolename');
          const userid = localStorage.getItem('username');
          const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
          const data = response.data;
          const rolePermissionData = JSON.parse(data[0].rolepermission);
          rolePermissionData.map((permission:any)=>{
            
            if(permission.menu==="Edit"){
                setPermission_groupedit(permission.group)
            }
            if(permission.menu==="Delete"){
                setPermission_groupdelete(permission.group)
            }
          })
      
          // setRolePermissions(rolePermissionData);
        } catch (error) {
        //   console.error('Error fetching data:', error);
        }
      };
    const [intervalId, setIntervalId] = useState<any>(null);
    useEffect(() => {
        GroupList();
        MembersList();
        GetUserAccess();
    }, []);



    const [getGroups, setGroups] = useState<group[]>([]);

    const GroupList = async () => {

        try {
            const apiUrl = BaseUrl + `api/group/groupList`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
//                    console.log("group list  : ", response.data)
                    setGroups(response.data);

                    //   onClose();

                });
        } catch (error) {
            console.log("ERROR :::::::::" + error);
        }
    }


    const viewGroup = async (groupid: string) => {

        try {
            const apiUrl = BaseUrl + `api/group/` + groupid;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                   // console.log("view group  : ", response.data);

                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }
    };


    const handleDelete = () => {
        confirm({
            title: 'Are you sure you want to delete?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered:true,
            onOk() {
                deleteGroup();
                // Call your delete function here
                // For example: onDelete()
            },
            onCancel() {
                
                // console.log('Delete canceled');
            },
        });
    };

    


    const deleteGroup = async () => {

        try {
            const apiUrl = BaseUrl + `api/group/` + groupValue.id;

            const response = await axios.delete(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    //  console.log("delete message  : ", response.data)
                    message.success({
                        content: (
                            <div className="success-message justify-center align-center p-1">
                                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                                <span className="c_32C6A0 fs-16">
                                  Group Deleted successfully
                                </span>
                            </div>
                        ),
                        duration: 1,
                        icon: null,
                    });
                    GroupList();
                    MembersList();
                });
        } catch (error) {
            console.log("ERROR :::::::::" + error);
        }
    }




    interface Members {

        id: string,
        username: string,
        name: string,
        email: string,
        supervisor_id: string,
        rolename: string,
        profilePath: string,
        employeeid?: string,
        divisonName?: string,
        count?:string,
        designation?: string,
    }


    const MembersList = async () => {

        try {
            const apiUrl = BaseUrl + `api/users/userListBySupervisor/${localStorage.year}/${localStorage.Quarter}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    //console.log("member list  : ", response.data)
                    setMemberOption(response.data);
                    setSelectedMember(response.data);

                });
        } catch (error) {
            console.log("ERROR :::::::::" + error);
        }
    }
    const items: MenuProps["items"] = [
        permission_groupedit ? {
            label: (
                <button className="border-0 bg-transparent fs-14 align-center"
                    onClick={() => viewGroup(groupValue.id)}
                >
                    {/* Edit */}
                    <EditGroup groupValue={groupValue} GroupList={GroupList} MembersList={MembersList} />
                </button>
            ),
            key: "0",
        }:null,
        permission_groupdelete ? {
            label: (
                <button
                    className="border-0 bg-transparent c_FF4747 fs-14 align-center"
                    onClick={() => handleDelete()}
                >
                    <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
                    Delete
                </button>
            ),
            key: "1",
        }:null,
    ];
    const getQuarterFromLocalStorage = () => {
        return localStorage.getItem('Quarter');
      };
      const getyearFromLocalStorage = () => {
        return localStorage.getItem('year');
      };
    const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
    const [year, setyear] = useState(getyearFromLocalStorage())
    useEffect(() => {
        const handleStorageChange = () => {
          setQuarter(getQuarterFromLocalStorage());
          setyear(getyearFromLocalStorage());
         // setLoading(true);
         MembersList();
        };
    
        window.addEventListener('storage', handleStorageChange);
    
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, [year, quarter]);
    const capitalizeFirstLetter = (str: string): string => {
        return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };
    return (
        <>
            {!profileVisible ? <span className="showing_member">Showing <b>{selectedMember.length}</b> {selectedMember.length >= 2 ? "members" : "member"}</span> : null}
            {!profileVisible ?
                <Row gutter={16} className="grp_name">
                    <Col xl={4} lg={4} md={24} sm={24} xs={24}>
                        <Row justify={"space-between"} className="mb-3">
                            <Col>
                                <span className="fs-20">Groups</span>
                            </Col>
                            <Col className="align-center">
                                <CreateGroup GroupList={GroupList}/>
                            </Col>
                        </Row>
                        <Radio.Group
                            onChange={onGroupChange}
                            value={groupValue.id ? groupValue : "All"}
                            className="w-100 group_scroll"
                        >
                            <Flex vertical>
                                <div className="radio-card d-flex justify-between mb-3 align-center">
                                    <Radio
                                        value="All"
                                        checked={!groupValue || groupValue.id === ""}
                                        // checked={true}
                                        // defaultChecked={true}
                                        // className=""
                                        onChange={(e) => {
                                            if (e.target.value === "All") {
                                                setSelectedMember(Memberoption);
                                            }
                                            setGroupValue({ id: '', groupname: '', assignedusers: [] });
                                        }}
                                    >
                                        <div>
                                            <div className={` ${(!groupValue || !groupValue.groupname) ? 'c_32B7D9' : ''}`}>All</div>
                                        </div>
                                    </Radio>
                                </div>
                                {getGroups && getGroups.map(group => (
                                    <div key={group.id} className={`radio-card d-flex justify-between mb-3 ${groupValue === group ? 'group_bg_active' : ''}`}
                                     >
                                        <Radio value={group} className="">
                                            <div >
                                                <div className={groupValue === group ? 'c_32B7D9' : ''}>{group.groupname}</div>
                                            </div>
                                        </Radio>
                                        {(permission_groupedit || permission_groupdelete)?(<>
                                            {groupValue === group &&  (
                                            <Dropdown menu={{ items }} trigger={['click']}>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        <img src="assets/images/menu.svg" className=" float-right" alt="" />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        )}
                                        </>):(<></>)}
                                        
                                    </div>
                                ))}
                                {/* <Radio value={"Altec"} className="radio-card mb-3">
                                    Altec
                                </Radio>
                                <Radio value={"Budgie"} className="radio-card mb-3">
                                    Budgie
                                </Radio>
                                <Radio value={"GoalSync"} className="radio-card mb-3">
                                    GoalSync
                                </Radio>
                                <Radio value={"Trove"} className="radio-card mb-3">
                                    Trove
                                </Radio> */}
                            </Flex>
                        </Radio.Group>
                    </Col>
                    {/* <Col xl={20} lg={20} md={24} sm={24} xs={24} className="team_selected_member"> */}
                    <Col xl={20} lg={20} md={24} sm={24} xs={24}>
                        <Row gutter={[46, 16]} align="stretch">
                            {selectedMember.map((user) => (
                                <Col xl={6} lg={6} md={6} sm={24} xs={24} className="mb-3">
                                    <Card className="member-card cur-pointer" style={{ height: "100%" }} onClick={() => handleCardClick(user)} > {/*{onClick={() => handleCardClick(user)}} */}
                                        <div className="text-center">
                                            {/* <Tooltip title="3 done / 3 in progress / 4 to do">
                                            <Progress percent={60} strokeColor={'#c2fbff'} success={{ percent: 30, strokeColor: '#32b7d9' }} type="circle" />
                                            </Tooltip> */}
                                            <div className="cricle_progress">
                                            {/* <Progress percent={60} strokeColor={'#c2fbff'}  trailColor={'#fff'}  success={{ percent: 30, strokeColor: '#32b7d9' }} type="circle" ></Progress> */}
                                            <div className=" mx-auto">
                                                {/* <Avatar src={"assets/images/Avatar1.png"} className="member-avatar" /> */}
                                                <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="member-avatar" onError={handleImageError} />
                                            </div>
                                            </div>

                                            
                                            <p className="p-0 m-0 mb-1 mt-1 fs-16 fw-700">{capitalizeFirstLetter(user.name)}</p>
                                            <p className="p-0 m-0 mb-1 mt-1 fs-14">{user.username}</p>
                                            <p className="p-0 m-0 mb-1 mt-1 fs-14">{user.designation}</p>
                                            <p className="p-0 m-0 mb-1 mt-1 fs-14">{user.divisonName}</p>
                                            {/* <p className="p-0 m-0 mb-1 fs-14">{user.rolename}</p> */}
                                            {/* <p className="p-0 m-0 fs-14 pl-1">{groupValue.groupname}</p> */}
                                            <div className="d-flex justify-center">
                                                <img src="assets/images/crown.svg" alt="" />
                                                <p className="p-0 m-0 fs-14 pl-1">{user.count}</p> {/* the count will replaced here*/}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                :
                <TeamProfile percent={50} user={selectedUser} groupName={groupValue.groupname} onClose={() => setProfileVisible(false)} />
            }
        </>
    );
};

export default MyTeam;
