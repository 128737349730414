import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Row,
  Col,
  Input,
  List,
  Avatar,
  Radio,
  Divider,
  Button,
  Flex,
  Modal,
  Select,
  Tag,
  Dropdown,
  Space,
  MenuProps,
  Spin,
  Carousel,
  Tooltip,
  message
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import * as z from "zod";
import NewChat from "../Modal/NewChat";
import ManageRecognition from "../Modal/ManageRecognition";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
import type { CarouselRef } from 'antd/es/carousel';
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

interface FeedbackReply {
  objectiveid: string;
  feedback: string;
  feedbackid: string;
  type: number;
  userid: string[];
  createrid: string;
}

interface userprofile {
  userid: string,
  username: string,
  profilepath: string
}
interface feedbacklist {
  id: string;
  uid: string;
  feedback: string;
  feedbackid: string;
  type: number;
  userDetails: userprofile[];
  createrid: string;
  objectiveid: string;
  created_At: string;
  creatordetails: userprofile;
}
interface Feedbackstore {
  feedback: string,
  type: number,
  userid: string[],
  createrid: string,
  objectiveid: string,
}
type objectivedetails = {
  objectiveid: string,
  createdby: string,
  createdName: string,
  supervisorId: string,
  supervisorName: string,
  everyoneCount: number | undefined,
  allteamCount: number | undefined,
  // feedback: string;
  // type: number;
  // userid: string;
  // createrid: string;
}
interface Objective {
  primaryId: string;
  title: string;
  objectiveName: string;
  objectiveDescription: string;
  weightage: string;
  organizationStatus: string;
  subObjectiveList: any[];
  assigneUserWeightage: any[];
  objParentId: string;
  objectiveId: string;
  objectiveType: string;
  unreadmessage?: number;
  everyoneCount?: number;
  allteamCount?: number;
  creatorUserId: string;
  creatorUserName: string;
  supervisorUserId: string;
  supervisorUserName: string;
  quarter: string;
  year: string;
}
interface Group {
  id: string;
  groupName: string;
  groupMember: string[];
  groupMemberDetails: any[];
  status: string;
  subObjectiveList: any[];
  // assigneUserWeightage: any[];
  objParentId: string;
  objectiveId: string;
  objectiveType: string;
  unreadMessages?: number;
  createdBy: string;
}
interface ObjTeam {
  id: string;
  name: string;
  username: string;
  email: string;
  designation: string;
  payroll_Status: string;
  profilePath: string | null;
  supervisorId: string;
  /* here first logged in status set as cft status  */
  firstLoggedStatus: number;
}
interface createConverstion {
  "objectiveId": string,
  "type": number,
  "objectiveType": string,
  "userIds": string[],
  "conversation": string,
};
interface feedbackrequest {
  "objectiveid": string,
  "createdby": string,
  "userid": string,
  "type": string
}
interface getConverstion {
  id: string,
  user: string,
  userName: string,
  type: string,
  createdBy: string,
  createdByName: string,
  creatorProfile: null,
  conversation: string,
  created_at: string,
  objectiveId: string
}

interface fhUser {
  id: string,
  name: string,
  email: string,
  rolename: string,
  departmentname: string,
  profilePath: string,
  band: 0
}
// interface createConverstion {
//   "objectiveId": string,
//   "type": string,
//   "objectiveType": string,
//   "userIds": string[],
//   "conversation": string,
// }

// const data = [
//   {
//     id: 1,
//     label: "OB1",
//     description: "Optimize UI/UX for seamless interaction.",
//     unreadMessages: 3,
//   },
//   {
//     id: 2,
//     label: "OB2",
//     description: "Enhance performance metrics.",
//     unreadMessages: 5,
//   },
//   {
//     id: 3,
//     label: "OB3",
//     description: "Improve accessibility standards.",
//     unreadMessages: 1,
//   },
//   {
//     id: 4,
//     label: "OB4",
//     description: "Ensure cross-platform compatibility.",
//     unreadMessages: 2,
//   },
//   {
//     id: 5,
//     label: "OB5",
//     description: "Refactor legacy code.",
//     unreadMessages: 6,
//   },
//   {
//     id: 6,
//     label: "OB6",
//     description: "Implement CI/CD pipelines.",
//     unreadMessages: 4,
//   },
//   {
//     id: 7,
//     label: "OB7",
//     description: "Optimize database queries.",
//     unreadMessages: 3,
//   },
// ];
interface MemberRecognition {
  recognitionMessage: string;
  recognitionCreator: string;
  deleteStatus: boolean;
  currentTime: string;
  id: string;
  profilePath: string;
  userId: string;
}

// const achivementData = [
//   {
//     id: 1,
//     user: "Vihisbdvknsdkjvbskjvbjkkram",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis ",
//   },
//   {
//     id: 2,
//     user: "Vinoth",
//     description:
//       " hi , yiu i fail to focus on my goal  ",
//   },
//   {
//     id: 3,
//     user: "Vicky",
//     description: "dnvmds 3",
//   },
// ];
const users = [
  { id: 1, name: "Praburaju", product: "Product/CITPL", unreadMessages: 88 },
  { id: 2, name: "Vikram", product: "Product/CITPL", unreadMessages: 2 },
  { id: 3, name: "Vishva", product: "Product/CITPL", unreadMessages: 2 },
];

const messages = [
  {
    user: "Praburaju",
    product: "Product/CITPL",
    date: "03 Jan 2023, 12:00 PM",
    content:
      "That’s understandable. Developing a cohesive color scheme can be tricky. How about we schedule some time to brainstorm together? We can bounce ideas off each other and maybe even do a quick mood board to visualize different options.",
  },
  {
    user: "Nagamani",
    product: "Product/CITPL",
    date: "03 Jan 2023, 12:00 PM",
    content:
      "Great to hear! How’s it coming along? Any challenges you’ve encountered?",
  },
  {
    user: "Praburaju",
    product: "Product/CITPL",
    date: "03 Jan 2023, 12:00 PM",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
  },
];

const feedbacks = [
  {
    id: 1,
    content:
      "Great to hear! How’s it coming along? Any challenges you’ve encountered?",
    showReplyArea: false,
    replyContent: "",
    replied: false,
  },
  {
    id: 2,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];


const Conversation: React.FC = () => {
  const [objData, setObjData] = useState<Objective[]>([]);
  const [childObj, setchildObj] = useState<number | null>(null);
  const [current, setCurrent] = useState(0);
  const [conversation, setConversation] = useState<getConverstion[]>([]);
  const [conversationGroup, setConversationGroup] = useState<getConverstion[]>([]);
  const [CFT, setCFT] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [objective, setobjectiveid] = useState<objectivedetails>({ objectiveid: "", createdby: "", createdName: "", supervisorName: "", supervisorId: "", allteamCount: 0, everyoneCount: 0 });
  const [selectedUser, setSelectedUser] = useState<ObjTeam[]>([]);
  const [selectedObjUser, setSelectedObjUser] = useState<ObjTeam[]>([]);
  //const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [feedbackrequest, setfeedbackrequest] = useState<feedbackrequest>({
    objectiveid: "",
    createdby: "",
    userid: "",
    type: ""
  });
  const [login, setloginUser] = useState<string | null>(localStorage.loginId);
  const [loginsupervisor, setloginUserSupervisor] = useState<string | null>(localStorage.loginId);
  const [GroupId, setGroupId] = useState<string | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<string | number>();

  //const [selectedRadio, setSelectedRadio] = useState<string|number>(objective.createdby);
  const [checkuserid, setcheckuserid] = useState<boolean>(false);
  const [selecteduserprimaryid, setselectedusersprimaryid] = useState<string[]>([])
  const [currentFeedback, setCurrentFeedback] = useState(0);
  const [showReplyArea, setShowReplyArea] = useState(false);
  const [matchedFeedback, setmathcedfeedback] = useState<string>("")
  const [replied, setReplied] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [currentGivenFeedback, setCurrentGivenFeedback] = useState(0);
  const [showFBgiven, setShowFBgiven] = useState(false);
  const [replyFBContent, setReplyFBContent] = useState("");
  const [Givenfeedbacks, setGivenfeedbacks] = useState<FeedbackReply[]>([]);
  const [FeedbackReply, setfeedbackreply] = useState<FeedbackReply>(
    {
      objectiveid: "",
      feedback: "",
      feedbackid: "",
      type: 0,
      userid: [],
      createrid: ""
    }
  );
  const [feedbackcomment, setfeedbackcomment] = useState<FeedbackReply>(
    {
      objectiveid: "",
      feedback: "",
      feedbackid: "",
      type: 0,
      userid: [],
      createrid: ""
    }
  )
  const [FBcomment, setFBComment] = useState(false);
  const [orgObjExists, setOrgObjExists] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [feedback, setFeedback] = useState('');
  const [feedbacklist, setfeedbacklist] = useState<feedbacklist[]>([])
  const [feedbackreplied, setfeedbackrepliedlist] = useState<feedbacklist[]>([])
  const [feedbackcommentlist, setfeedbackcommentslist] = useState<feedbacklist[]>([]);
  const [objectiveId, setobjectiveId] = useState('');
  const [objectiveType, setobjectiveType] = useState('');
  const [chat, setChat] = useState('');
  const [allTeamMembers, setAllTeamMembers] = useState<string[]>([]);
  const [allMembers, setAllMembers] = useState<string[]>([]);
  const [chatMessage, setChatMessage] = useState<createConverstion>();
  const [chatType, setChatType] = useState<number>(0);
  const [conversationType, setConversationType] = useState<string>('');

  // const [feedbackstore, setfeedback] = useState<Feedbackstore>({
  //   feedback: "",
  //   type: 1,
  //   userid: "",
  //   createrid: "",
  // });
  /*** Chat modal variable Start ***/

  // const [objectiveId, setobjectiveId] = useState('');
  // const [objectiveType, setobjectiveType] = useState('');

  /*** Chat modal Group List Start ***/
  const [GroupData, setGroupData] = useState<Group[]>([]);
  // const [objectiveId, setobjectiveId] = useState('');
  // const [objectiveType, setobjectiveType] = useState('');


  /*** Chat modal variable Start ***/
  // const [feedbackstore,setfeedback] = useState<Feedbackstore>({feedback:"",type:1,userid:"",createrid:""});
  const [feedbackstore, setfeedback] = useState<Feedbackstore>({ feedback: "", type: 1, userid: [], createrid: "", objectiveid: "" });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [type, setType] = useState(1);
  const handlechange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (Object.keys(feedbackstore).includes(event.target.name)) {
      setfeedback({
        ...feedbackstore,
        [event.target.name]: event.target.value,
        type: 1,
        createrid: localStorage.loginId,
        userid: selecteduserprimaryid,
        objectiveid: objective.objectiveid

      });
    }
  };
  // const [Givenfeedbacks, setGivenfeedbacks] = useState([
  //   {
  //     id: 1,
  //     content: "Great to hear! How’s it coming along? Any challenges you’ve encountered?",
  //     showReplyArea: false,
  //     replyFBContent: "",
  //     replied: false,
  //   },
  //   {
  //     id: 2,
  //     content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     showReplyArea: false,
  //     replyFBContent: "",
  //     replied: false,
  //   },
  // ]);

  // Function to fetch data from the first API
  const fetchObjectiveList = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/cfr/objectiveList", {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });
      // if(response.data=="No value present")
      //   {
      setObjData(response.data.data);
      setLoading(false);
      // }else
      // {
      //   setLoading(false);
      // }
      return response.data.data;
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };

  // get group list API
  const GroupList = async (objectiveId: string) => {
    try {

      const response = await axios.get(`${BaseUrl}api/cfr/grouplist/${objectiveId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });
      setGroupData(response.data);
      return response.data;
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };





  const fetchConversation = async (primaryObjId: string, userId: string) => {
    try {
      const response = await axios.get(BaseUrl + `api/cfr/userconversation/${primaryObjId}/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      setConversation(response.data);
    } catch (err) {
      // console.log(err)
    }
  }

  const fetchGroupConversation = async (getgroupId: string) => {
    try {
      //http://localhost:8015/okr/api/cfr/groupconversation/6624a0957cdb1f4c40b568fe/6656f741661f972adbdb8111
      const response = await axios.get(BaseUrl + `api/cfr/groupconversation/${objectiveId}/${getgroupId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      setConversationGroup(response.data);
    } catch (err) {
      // console.log(err)
    }
  }

  // Function to fetch data from the second API
  const fetchObjectiveTeam = async (primaryId: string, ObjType: string) => {
    try {
      const secondApiUrl = `${BaseUrl}api/cfr/objectiveteam/${primaryId}/${ObjType}`;
      const response = await axios.get(secondApiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });

      const removeDuplicateUser = new Set(allTeamMembers);

      response.data.forEach((member: any) => {
        removeDuplicateUser.add(member.id);
      })
      setAllTeamMembers(Array.from(removeDuplicateUser));
      return response.data;
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };

  const fetchObjectiveUser = async (primaryId: string, ObjType: string) => {
    try {
      const secondApiUrl = `${BaseUrl}api/cfr/objectiveusers/${primaryId}/${ObjType}`;
      const response = await axios.get(secondApiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });

      const removeDuplicateUser = new Set(allTeamMembers);

      response.data.forEach((member: any) => {
        removeDuplicateUser.add(member.id);
        // console.log(member.email);
        fhuseroption.map((fhLogin) => {
          if (fhLogin.email === member.email) {
            // console.log("present in objective" , fhLogin.email);            
          }
        })

      })
      setAllMembers(Array.from(removeDuplicateUser));
      const filteredUsers = response.data.filter(
        (item: any) => item.id !== login && item.id !== loginsupervisor
      );
      // const filteredUsers = filteredUsers.filter(
      //   (item:any) => item.id !== loginsupervisor
      // );

      // const filteredUsers = response.data.filter(
      //   (item:any) => item.id !== login
      // );
      setSelectedObjUser(filteredUsers);
      return filteredUsers;
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };

  // Usage in your component
  const userLists = async () => {
    try {
      const objList = await fetchObjectiveList();
      setObjData(objList);
      setLoading(false);
      // Check if orgObj exists for each primaryId
      // const orgObjChecks: { [key: string]: boolean } = {};
      // for (const obj of objList) {
      //   try {
      //     await fetchObjectiveTeam(obj.primaryId, obj.objectiveType);
      //     orgObjChecks[obj.primaryId] = true;
      //   } catch {
      //     orgObjChecks[obj.primaryId] = false;
      //   }
      // }
      // setOrgObjExists(orgObjChecks);
    } catch (err) {
      // console.log(err);
      // Handle errors appropriately
    }
  };
  const [recognitions, setRecognitions] = useState<MemberRecognition[]>([]);
  const getAllRecognition = async () => {
    try {

      const apiUrl = BaseUrl + `api/recognition/assign/user/${localStorage.getItem("username")}`;
      await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then((response) => {
        setRecognitions(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
  const changeDateFormat = (dateString: string, fromFormat: string, toFormat: string): string => {
    return moment(dateString, fromFormat).format(toFormat);
  };
  const originalDateString = '2024-07-09'; // Example input date string
  const newDateString = changeDateFormat(originalDateString, 'YYYY-MM-DD', 'DD/MM/YYYY');


  const items: MenuProps["items"] = [
    {
      label: <ManageRecognition getAllRecognition={getAllRecognition} />,
      key: "0",
    },
  ];
  const [saveAndCascadeUser, setSaveAndCascadeUser] = useState<number>(0);
  const assignReporteeUsers = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      setSaveAndCascadeUser(response.data.length);
    } catch (err) {
      // console.log(err)
    }
  }

  const getAllMembersTeamConversation = async (type: string) => {

    try {
    const apiUrl = BaseUrl + `api/cfr/team/members`;

    let storeUserId: string[] = [];
    storeUserId.push(selectedUserId);

    const newMessage = {
      objectiveId: objectiveId,
      type: type === "individual" ? "1" : type === "All Team Members" ? "2" : "3",
      objectiveType: objectiveType,
      userIds: [],
      conversation: "",
    }


    await axios.post(apiUrl, newMessage, {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`
      }
    }).then((response) => {
      allTeamMembers.map((conversation: string) => {
        // fetchConversation(conversation);
      })
      setConversation(response.data);
    })

  } catch (error) {

  }
}

  useEffect(() => {
    // userLists();
    getAllRecognition();
    fetchObjectiveList();
    assignReporteeUsers();
    setloginUserSupervisor(objective.supervisorId);
    // fhuserslist();
  }, [selecteduserprimaryid, objective.supervisorId, selectedObjUser, objectiveId]);

  useEffect(() => {
    // setSelectedRadio(objective.supervisorId);
    handleRadioChange(objective.supervisorId, "individual");

    //  fetchConversation(objective.supervisorId);
    //objective.supervisorId
    setloginUserSupervisor(objective.supervisorId);
  }, [objective.supervisorId, selectedObjUser, objectiveId]);


  // useEffect(() => {
  //   if (selectedUserId) {
  //     // fetchConversation(selectedUserId);
  //   }
  // }, [selecteduserprimaryid]);

  const handlePrev = () => {
    if (current > 0) {
      setCurrent((prev) => prev - 1);
    }
  };
  const handleNext = () => {
    if (current < recognitions.length - 1) {
      setCurrent((prev) => prev + 1);
    }
  };
  const handleChildObj = (index: number) => {
    setchildObj(childObj === index ? null : index);
  };
  // const handleConversation = async (index: number,creatoruserid:string) => {
  //   const primaryId = objData[index].primaryId;
  //   setobjectiveid(
  //     {
  //     ...objective,
  //     objectiveid:objData[index].primaryId,
  //     createdby:objData[index].creatorUserId
  // })
  const handleCFT = async (index: number, objdata: Objective, creatoruserid: string, creatorUserName: string) => {
    //const primaryId = objData[index].title.substring(0,2) ==="OR" ? objData[index].primaryId : ;
    const primaryId = objdata.primaryId;
    // console.log("objective_data",objdata);
    // console.log("objective_data",index);
    fhuserslist();
    setobjectiveid(
      {
        ...objective,
        objectiveid: objdata.primaryId,
        createdby: objdata.creatorUserId,
        createdName: objdata.creatorUserName,
        supervisorId: objdata.supervisorUserId,
        supervisorName: objdata.supervisorUserName,
        everyoneCount: objdata.everyoneCount,
        allteamCount: objdata.allteamCount
      })
    //   const assignedkeyresultrequest={
    //     'userid':[localStorage.loginId],
    //     'objectiveid':objData[index].primaryId
    //   }
    //  gettingfeedbacklist(assignedkeyresultrequest);
    const ObjType = objdata.objectiveType;
    // if (orgObjExists[primaryId]) {
    try {
      const objTeam = await fetchObjectiveTeam(primaryId, ObjType);
      const objUserslist = await fetchObjectiveUser(primaryId, ObjType);
      setSelectedUser(objTeam);
      setSelectedObjUser(objUserslist);
      // console.log("Objective",primaryId);
      const data = await GroupList(primaryId);
      setGroupData(data);
      setobjectiveType(ObjType);
      setobjectiveId(primaryId);
    } catch (err) {
      // console.log(err);
    }
    // }

    //setConversation(conversation === index ? null : index);
    fetchConversation(primaryId, objdata.supervisorUserId);


    setCFT(CFT === index ? null : index);


  };

  const sendMessage = async (textMessage: string) => {

    const apiUrl = BaseUrl + 'api/cfr/createConversation';


    let storeUserId: string[] = [];
    storeUserId.push(selectedUserId);

    const newMessage = {
      objectiveId: objectiveId,
      type: conversationType === "individual" ? "1" : conversationType === "All Team Members" ? "2" : "3",
      objectiveType: objectiveType,
      userIds: conversationType === "individual" ? storeUserId : conversationType === "All Team Members" ? allTeamMembers : [],
      conversation: textMessage,
    }

    try {
      const response = await axios.post(apiUrl, newMessage, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      const addedConversation = response.data;
      setConversation([...conversation, ...addedConversation]);
      setChat(''); // Clear input field


      if (conversationType === "individual") {
        fetchConversation(objectiveId, selectedUserId);
      } else if (conversationType === "All Team Members") {
        getAllMembersTeamConversation(conversationType);
      } else if (conversationType === "Everyone") {
        getAllMembersTeamConversation(conversationType);
      }

    } catch (error) {
      // console.error('Error sending message:', error);
    }
  };


  const sendGroupMessage = async () => {
    if (!chat || !GroupId) return;
    const apiUrl = BaseUrl + 'api/cfr/createGroupConversation';
    const newMessage = {
      groupid: GroupId,
      //type: "4",
      // objectiveType: objectiveType, 
      // userIds: [selectedUserId], 
      message: chat,
    };

    try {
      const response = await axios.post(apiUrl, newMessage, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      const addedConversation = response.data;
      setConversation([...conversation, addedConversation]);
      setChat(''); // Clear input field
      fetchGroupConversation(GroupId);
      //fetchConversation(selectedUserId);
    } catch (error) {
      // console.error('Error sending message:', error);
    }

  };



  // const sendMessage = async () => {
  //   try {
  //     const userIds = selectedUser ? selectedUser.map(user => user.id) : [];
  //     const response = await axios.post(
  //       BaseUrl + "api/cfr/createConversation",
  //       {
  //         objectiveId: "6635b4a7b93b5d6dd6f75756", 
  //         type: "1",
  //         objectiveType: "org-obj",
  //         userIds: userIds,
  //         conversation: feedback,
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
  //         }
  //       }
  //     );

  const [permissionforobjectives, setpermission] = useState<boolean>(false);
  const [permissiongroupcreation, setgroupper] = useState<boolean>(false)
  const handleRadioChange = async (id: string, type: string) => {
    setConversationType(type);
    setSelectedUserId(id);
    setGroupId(id);
    if (selectedObjUser.some(data => data.id == id && data.supervisorId == localStorage.loginId) || type == "All Team Members" || selectedUser.some(userdata => userdata.id == id) || GroupData.some((userdata) => userdata.createdBy == localStorage.loginId && userdata.id == id)) {
      setpermission(true);
    }
    else {
      setpermission(false)
    }
    var useridlist: any[] = [];
    if (type == "individual") {
      useridlist = [id]
    }
    else if (type == "group") {
      useridlist = GroupData.flatMap(data => data.groupMember)
    }
    else if (type == "All Team Members") {
      useridlist = selectedUser.map(data => data.id);
    }
    var assignedkeyresultrequest: any = {
      "objectiveid": objective.objectiveid,
      // "userid": type == "All Team Members" ? selectedUser.map(data => data.id) : "group" ? GroupData.flatMap(data => data.groupMember) : [id],
      "userid": useridlist,
      "createdby": type != "individual" ? localStorage.loginId : id,
      "type": id == "individual" ? "individual" : "All Team Members"
    }

    //handleRadioChange(id,type);
    setSelectedRadio(id);
    if (id == objective.createdby || objective.supervisorId == id) {
      setcheckuserid(true)
      assignedkeyresultrequest = {
        "objectiveid": objective.objectiveid,
        "createdby": localStorage.loginId,
        "userid": [localStorage.loginId],
        "type": "All Team Members"
      }
    }
    else {
      setcheckuserid(false)
    }
    if (type == "individual") {
      setSelectedUserId(id);
      setType(1);

      if (id == objective.createdby || objective.supervisorId == id) {
        setcheckuserid(true)
        assignedkeyresultrequest = {
          "objectiveid": objective.objectiveid,
          "createdby": localStorage.loginId,
          "userid": [localStorage.loginId],
          "type": "All Team Members"
        }
      }
      else {
        setcheckuserid(false)
      }

      // fetchConversation(id);
    } else if (type == "group") {
      setcheckuserid(false)
      setType(4);
      setGroupId(id);
      fetchGroupConversation(id);
    }
    else if (type == "Everyone") {
      setType(3);
    }
    else if (id == "All Team Members") {
      setType(5)
    }
    else {


    }

    setfeedbackrequest(assignedkeyresultrequest)
    // fetchConversation(id);
    // setSelectedRadio(id);
    // switch (id) {
    //   case "Everyone":
    //     setType(3);
    //     break;
    //   case "All Team Members":
    //     setType(2);
    //     break;
    //   default:
    //     setType(1);
    //     break;
    // }
    // setSelectedRadio((prevSelectedRadio) => {
    //     return [id];
    // });
    const formdata = new FormData;
    setSelectedRadio(id);
    if (id == "All Team Members") {
      setselectedusersprimaryid(selectedUser.map(data => data.id))
      // setselectedusersprimaryid(allTeamMembers);

    }
    else if (type == "group") {
      setselectedusersprimaryid(GroupData.flatMap(data => data.groupMember))
    }
    else {
      setselectedusersprimaryid((previousdata) => {
        return [id]
      }
      )
    }


    gettingfeedbacklist(assignedkeyresultrequest);


  };
  const gettingfeedbacklist = async (assignedkeyresultrequest: any) => {
    try {
    const api = `${BaseUrl}api/FeedBack/feedbacklist`;
    await axios.post(api, assignedkeyresultrequest,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const filteredData = (response.data.data as feedbacklist[]).filter((item: feedbacklist) => item.type === 1);
        const replieddata = (response.data.data as feedbacklist[]).filter((item: feedbacklist) => item.type === 2);
        const comment = (response.data.data as feedbacklist[]).filter((item: feedbacklist) => item.type === 3);
        setfeedbacklist(filteredData);
        setfeedbackrepliedlist(replieddata);
        setfeedbackcommentslist(comment)
      })     
    } catch (error) {
      
    }
  }
  const handleFeedbackPrev = () => {
    if (currentFeedback > 0) {
      setCurrentFeedback(currentFeedback - 1);
    }
  };
  const handleFeedbackNext = () => {
    if (currentFeedback < feedbacklist.length - 1) {
      setCurrentFeedback(currentFeedback + 1);
    }
  };
  const handleGivenfeedbacksPrev = () => {
    if (currentGivenFeedback > 0) {
      setCurrentGivenFeedback(currentGivenFeedback - 1);
    }
  };
  const handleGivenfeedbacksNext = () => {
    if (currentGivenFeedback < Givenfeedbacks.length - 1) {
      setCurrentGivenFeedback(currentGivenFeedback + 1);
    }
  };
  const handleFBCancelClick = () => {
    setShowFBgiven(false);
    setFBComment(false);
    setReplyFBContent("");
    setfeedback(
      { feedback: "", type: 1, userid: [], createrid: "", objectiveid: "" }
    )
  };

  // const handleFBSubmitClick = () => {
  //   if (replyFBContent.trim()) {
  //     const updatedFeedbacks = [...Givenfeedbacks];
  //     updatedFeedbacks[currentGivenFeedback].replyFBContent = replyFBContent;
  //     updatedFeedbacks[currentGivenFeedback].replied = true;
  //     setGivenfeedbacks(updatedFeedbacks);
  //     setReplyFBContent('');
  //     setShowFBgiven(false);
  //   }
  // };

  const handlesubmit = async (
    type: number,
  ) => {

    if (type == 1) {
      const feedbackSchema = z.object({
        feedback: z.string().min(1, { message: "Feedback field is required" }),
      });
      const validationResult = feedbackSchema.safeParse(feedbackstore);
      if (validationResult.success) {
        setErrors(null);
        handleFBSubmitClick(type);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Feedback Created Successfully
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach((error) => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else if (type == 2) {
      const feedbackSchema = z.object({
        feedback: z.string().min(1, { message: "comment field is required" }),
      });
      const validationResult = feedbackSchema.safeParse(FeedbackReply);
      if (validationResult.success) {
        setErrors(null);
        handleFBSubmitClick(type);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Replied Successfully
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach((error) => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else if (type == 3) {
      const feedbackSchema = z.object({
        feedback: z.string().min(1, { message: "comment field is required" }),
      });
      const validationResult = feedbackSchema.safeParse(feedbackcomment);
      if (validationResult.success) {
        setErrors(null);
        handleFBSubmitClick(type);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Commented Successfully
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach((error) => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }

    setTimeout(function () {
      gettingfeedbacklist(feedbackrequest);
    }, 500);
    setFBComment(false);
    setShowFBgiven(false);
    setShowReplyArea(false);
  };
  const handleFBSubmitClick = async (
    type: number,
  ) => {
    // setfeedback(
    //   {
    //   ...feedbackstore,
    //   type:type,
    //   createrid:creator,
    //   userid:userid,
    //   }
    // )
    var feedback: any;
    if (type == 1) {
      feedback = feedbackstore
    }
    else if (type == 2) {
      feedback = FeedbackReply
    }
    else if (type == 3) {
      feedback = feedbackcomment
    }
    try {
      const api = `${BaseUrl}api/FeedBack/savefeedback`;

      axios.post(api, feedback, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setReplyFBContent("");
      setShowFBgiven(false);
      setFBComment(false);
      // handleRadioChange(feedback.userid,"feedbacklist")
      setFBComment(false);
      setfeedback(
        { feedback: "", type: 1, userid: [], createrid: "", objectiveid: "" }
      )
    } catch (error) { }
    if (replyFBContent.trim()) {
      const newFeedback = {
        id: "",
        feedback: "",
        feedbackid: "",
        type: type,
        userid: "",
        createrid: "",
      };


      // setCurrentGivenFeedback(updatedFeedbacks.length - 1);

      // setGivenfeedbacks(newFeedback);
      // Show the newly added feedback
    }

  };

  const handleAddFeedBack = () => {
    setShowFBgiven(true);
  };

  // Givenfeedbacks
  const handleReplyClick = () => {
    setShowReplyArea(true);
  };

  const handleCancelClick = () => {
    setShowReplyArea(false);
  };

  const handleSubmitClick = () => {
    setReplied(true);
    setShowReplyArea(false);
  };
  const handleComment = (creatorid: string, feedbackid: string) => {
    setfeedbackcomment(prevState => ({
      ...prevState,
      objectiveid: objective.objectiveid,
      feedbackid: feedbackid,
      type: 3,
      createrid: localStorage.loginId,
      userid: [creatorid]
    }))
    setFBComment(true);
  };
  const resetReply = () => {
    setShowReplyArea(false);
    setReplied(false);
    setReplyContent("");
  };
  const handleUserClick = (id: string, type: string) => {


    if (type === "individual") {
      fetchConversation(objectiveId, id);
    } else if (type === "All Team Members") {
      getAllMembersTeamConversation(type);
    } else if (type === "Everyone") {
      getAllMembersTeamConversation(type);
    }


    // // setSelectedUserId(id);
    // // setGroupId(id);
    // handleRadioChange(id,type);
    // setSelectedRadio(id);
    // if(type=="individual")
    // {
    //   setSelectedUserId(id);
    //   setType(1);
    // }else{
    //   setType(2);
    //   setGroupId(id);

    // }

    // fetchConversation(id);

    // setSelectedUserId(id);
    // handleRadioChange(id,false);
    // setSelectedRadio(id);
    // setType(1);
  };
  const renderConversation = () => {

    // switch (type) {
    //   case 1:
    //     return (
    //       <div className="border_EAEAEA p-1">
    //       <div className="conversation_scroll">
    //         <div className="">
    //           {conversation.map((msg, index) => (
    //             <div key={index} className="mt-3">
    //               {/* <div className="mt-2">
    //                   <Flex>
    //                     <img
    //                       src="assets/images/Default_user.svg"
    //                       className="kr_user"
    //                       alt=""
    //                     />
    //                     <div className="ml-1">
    //                       <div className="">
    //                         <span className="fw-600 fs-14">
    //                           Praburaju/Product/CITPL
    //                         </span>
    //                         <span className="m-0 c_8080 fs-12 pl-1">
    //                           03 Jan 2023, 12 :00 PM
    //                         </span>
    //                       </div>
    //                       <p className="m-0 fs-16 pt-1 mt-1 right_conversation_box ">
    //                         That's understandable. Developing a cohesive color
    //                         scheme can be tricky. How about we schedule some
    //                         time to brainstorm together? We can bounce ideas off
    //                         each other and maybe even do a quick mood board to
    //                         visualize different options.
    //                       </p>
    //                     </div>
    //                   </Flex>
    //                 </div> */}
    //                 <div className="mt-3">
    //                   <Flex>
    //                     <div className="ml-1 mr-1">
    //                       <div className="justify-end">
    //                         <span className="fw-600 fs-14">
    //                         {msg.userName}
    //                         </span>
    //                         <span className="m-0 c_8080 fs-12 pl-1"> {msg.created_at}</span>
    //                       </div>
    //                       <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
    //                       {msg.conversation}
    //                       </p>

    //                     </div>
    //                     {/* <img
    //                       src="assets/images/user.svg"
    //                       className="kr_user "
    //                       alt=""
    //                     /> */}
    //                   </Flex>
    //                 </div>
    //             </div>
    //           ))}
    //         </div>
    //         <div className="msg_sender">
    //           <input
    //             type="text"
    //             className="w-100 p-2"
    //             placeholder="Write here"
    //             value={chat}
    //             onChange={(e) => setChat(e.target.value)}
    //           />
    //           <img
    //             src="assets/images/send.svg"
    //             className="con_send_icon pr-1"
    //             alt=""
    //             onClick={sendMessage}
    //           />
    //         </div>
    //       </div>

    //     </div>
    //     );
    //   case 2:
    //     return <div>all team user</div>;
    //   case 3:
    //     return <div>every one</div>;
    //   default:
    //     return null;
    // }
  };
  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 3;
  const carouselRef = React.useRef<CarouselRef>(null);
  const handleCarouselPrev = () => {
    carouselRef.current?.prev();
  };

  const handleCarouselNext = () => {
    carouselRef.current?.next();
  };
  const handleChangeCarousel = (current: number) => {
    setCurrentSlide(current);
  };
  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const [fhuseroption, setfhusersoption] = useState<fhUser[]>([]);
  const fhuserslist = async () => {
    try {
      const fhusers = await axios.get(BaseUrl + "api/users/FHuserslist", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      setfhusersoption(fhusers.data);
    } catch (error) {
      // console.log('ERRor', error);      
    }
  }

  const loginId = localStorage.getItem('loginId');

  return (
    <>
      <div>
        <Row className="congrats_div p-2" key={recognitions[current]?.id}>
          <Col xl={2} lg={2} md={2} sm={24}>
            <img src="assets/images/congrats.svg" alt="" />
          </Col>
          <Col xl={18} lg={17} md={16} sm={24}>
            <div
              style={{ position: "relative" }}
            >
              <Carousel
                ref={carouselRef}
                afterChange={handleChangeCarousel}
                dots={false}
                autoplay
              >
                {recognitions.map((recognition, index) => (
                  <div key={index}>
                    <Row className="d-flex align-center">
                      <Col xl={8} lg={10} md={12} sm={24}>
                        <div className="d-flex align-center pl-1">
                          <img
                            src="assets/images/user.svg"
                            alt=""
                            className="img_36"
                          />
                          <Tooltip
                            placement="top"
                            title={`${recognition.userId}CIPTL `}
                            arrow={true}
                          >
                            <p className="pl-1 fs-14 fw-600 recognition_id m-0 mt-1">
                              {recognition.userId}CIPTL
                            </p>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col xl={16} lg={14} md={12} sm={24}>
                        <span className="fs-14">
                          {recognition.recognitionMessage}
                        </span>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Carousel>
            </div>
          </Col>
          {
            recognitions.length > 0 && (
              <>
                <Col xl={3} lg={4} md={5} sm={24} className="align-center">
                  <Button
                    icon={<LeftOutlined />}
                    onClick={handleCarouselPrev}
                    disabled={currentSlide === 0}
                    className={currentSlide === 0 ? "recognition_slide" : "recognition_currentslide"}
                  />
                  <span className="mx-2 fs-14">
                    <b>{currentSlide + 1}</b> / {recognitions.length}
                  </span>
                  <Button
                    icon={<RightOutlined />}
                    onClick={handleCarouselNext}
                    disabled={currentSlide === recognitions.length - 1}
                    className={currentSlide === recognitions.length - 1 ? "recognition_slide" : "recognition_currentslide"}
                  />
                </Col>
              </>

            )
          }

          <Col
            xl={1}
            lg={1}
            md={1}
            sm={24}
            className="align-center justify-center"
          >
            {saveAndCascadeUser !== 0 ? (
              <>
                <Dropdown menu={{ items }}>
                  <img
                    src="assets/images/menu.svg"
                    alt=""
                    className="float-right p-1 cur-pointer"
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>


        <div>
          {loading && <Spin className='d-flex justify-center align-center mt-5' tip="Loading" size="large">
            <div className="content" />
          </Spin>}
          {loading && (
            <Spin
              className="d-flex justify-center align-center mt-5"
              tip="Loading"
              size="large"
            >
              <div className="content" />
            </Spin>
          )}
        </div>
        <Row className="mt-2">
          <div className="objective_overall">
            <div className="d-flex">

              {objData.map((item, index) => (
                (item.quarter == quarter && item.year == year &&
                  <div className="d-flex mr-2" key={item.primaryId}>
                    <div
                      className={
                        CFT === index
                          ? "myobj_card_active"
                          : "myobj-card cur-pointer"
                      }
                    >
                      <div>
                        <div className="d-flex justify-between w-100">
                          <div className="wrapper_label align-center mb-2">
                            <span
                              className={
                                CFT === index
                                  ? " cup_org_bg_active align-center"
                                  : "cup_org_bg align-center"
                              }
                            >
                              <img
                                src="assets/images/crown.svg"
                                alt=""
                                className="pr-1"
                              />
                              {item.title}
                            </span>
                            <span
                              className={
                                CFT === index
                                  ? "triangle_active"
                                  : "triangle"
                              }
                            ></span>
                          </div>
                          <div>
                            <img
                              src="assets/images/cascade_status_img.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <p
                          className="threeline_text_overflow fs-14 cur-pointer"
                          onClick={() => handleCFT(index, item, item.creatorUserId, item.creatorUserName)}
                        >
                          {item.objectiveName}
                        </p>
                      </div>
                      <div className="d-flex align-center justify-between">
                        <div className="d-flex align-center">
                          <span
                            className="text_line c_32B7D9 fs-12 cur-pointer"
                            onClick={() => handleCFT(index, item, item.creatorUserId, item.creatorUserName)}
                          >
                            {item.unreadmessage}  Unread Messages
                          </span>
                        </div>
                        {item.subObjectiveList.length > 0 && (
                          <div
                            className="d-flex align-center"
                            onClick={() => handleChildObj(index)}
                          >
                            {childObj === index ? (
                              <img src="assets/images/layer_b.svg" alt="" />
                            ) : (
                              <img src="assets/images/layer_normal.svg" alt="" />
                            )}
                            <span
                              className={`fs-12 cur-pointer ${childObj === index ? "c_32B7D9 fw-700" : "c_000"
                                }`}
                            >
                              {item.subObjectiveList.length} Child-Obj
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        childObj === index ? "myobj_child_bg_active d-flex" : ""
                      }
                    >
                      {childObj === index &&
                        item.subObjectiveList.length > 0 && (
                          <>
                            {item.subObjectiveList.map((subItem, subIndex) => (
                              <>
                                <div className="myobj_child">
                                  <div key={subItem.subObjId}>
                                    <div className="d-flex justify-between w-100">
                                      <div className="wrapper_label align-center mb-2">
                                        <span className="cup_org_bg align-center">
                                          <img
                                            src="assets/images/crown.svg"
                                            alt=""
                                            className="pr-1"
                                          />
                                          {subItem.subObjId}
                                        </span>
                                        <span className="triangle"></span>
                                      </div>
                                      <div>
                                        <img
                                          src="assets/images/cascade_status_img.svg"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <p className="threeline_text_overflow fs-14" onClick={() => handleCFT(subIndex, subItem, subItem.creatorUserId, subItem.creatorUserName)}>
                                      {subItem.objectiveName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>

                        )}
                      {/* {childObj === index && item.subObjectiveList.length > 0 && (
                      <div className="myobj_child">
                        {item.subObjectiveList.map((subItem) => (
                          <div key={subItem.subObjId}>
                            <div className="d-flex justify-between w-100">
                              <div className="wrapper_label align-center mb-2">
                                <span className="cup_org_bg align-center">
                                  <img
                                    src="assets/images/crown.svg"
                                    alt=""
                                    className="pr-1"
                                  />
                                  {subItem.subObjId}
                                </span>
                                <span className="triangle"></span>
                              </div>
                              <div>
                                <img
                                  src="assets/images/cascade_status_img.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <p className="threeline_text_overflow fs-14">
                              {subItem.objectiveName}
                            </p>
                          </div>
                        ))}
                      </div>
                    )} */}
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </Row>
        {CFT !== null && selectedUser && (
          <>
            <Row className="mt-1 justify-between">
              <Col xl={5} lg={5} md={24} sm={24} className=" p-1">
                <div className="border_EAEAEA p-1">
                  <div className="conver_search">
                    <img src="assets/images/search.svg" alt="" />
                    <input type="search" name="" id="" placeholder="search" />
                  </div>
                  <div>
                    {objective.createdby !== localStorage.loginId || objective.createdby === localStorage.loginId ? (
                      <>
                        <p className="c_32B7D9 fs-14 fw-600">Assignor & Team</p>
                        {/* <div
                      className="d-flex justify-between cur-pointer"
                      onClick={() => handleRadioChange("Praburaju",true)}
                    >
                      <img
                        src="assets/images/Default_user.svg"
                        alt=""
                        className="img_36"
                      />
                      <p className="member_title">Praburaju/Product/CITPL</p>
                      <Radio
                        checked={selectedRadio === "Praburaju"}
                        onChange={() => handleRadioChange("Praburaju","individual")}
                      />
                    </div> */}
                        <div
                          className="d-flex justify-between cur-pointer"
                          onClick={() => { handleUserClick(objective.supervisorId, "individual"); handleRadioChange(objective.supervisorId, "individual") }
                          }
                        >
                          <img
                            src="assets/images/Default_user.svg"
                            alt=""
                            className="img_36"
                          />
                          {/* <p className="member_title ml-1">{capitalizeFirstLetter(objective.supervisorName)}</p> */}
                          <p className="member_title ml-1">{objective.supervisorName}</p>
                          <Radio
                            checked={selectedRadio === objective.supervisorId}
                            onChange={() => handleRadioChange(objective.supervisorId, "individual")}
                          />
                        </div>
                      </>
                    ) : null}
                    <Divider />
                    <div
                      className="d-flex justify-between cur-pointer"
                      onClick={() => handleUserClick("Everyone", "Everyone")}
                    >
                      <p>Everyone</p>
                      <p>
                        <span className="mr-2 notification_bg">{objective.everyoneCount}</span>
                        <Radio
                          checked={selectedRadio === "Everyone"}
                          onChange={() => handleRadioChange("Everyone", "Everyone")}
                        />
                      </p>
                    </div>

                    <>

                      <div
                        className="allteam_member cur-pointer"
                        onClick={() => handleUserClick("All Team Members", "All Team Members")}
                      >

                        <>
                          <p className="c_32B7D9 fs-14 fw-600">Team Members</p>
                          {/* {0 < saveAndCascadeUser ? (<> */}
                          <div className="d-flex justify-between">
                            <p>All Team Members</p>
                            <p>
                              <span className="mr-2 notification_bg">{objective.allteamCount}</span>
                              <Radio
                                checked={selectedRadio === "All Team Members"}
                                onChange={() =>
                                  handleRadioChange("All Team Members", "All Team Members")
                                }
                              />
                            </p>
                          </div>
                        </>
                        {/* </>) : (<></>)} */}
                        {selectedUser.length > 0 ? (
                          <div className="obj_member_list ">
                            <List
                              itemLayout="horizontal"
                              dataSource={selectedUser || []}
                              renderItem={(item: ObjTeam) => (
                                <List.Item onClick={(e) => { e.stopPropagation(); handleUserClick(item.id, "individual"); handleRadioChange(item.id, "individual"); }}>
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        src={
                                          item.profilePath || "assets/images/user.svg"
                                        }
                                      />
                                    } // Use default image if profilePath is null
                                    // title={`${capitalizeFirstLetter(item.name)}/${item.designation}/${item.payroll_Status}`}
                                    title={`${item.name}/${item.designation}/${item.payroll_Status}`}
                                    style={{ textTransform: 'capitalize' }}
                                    description={
                                      <Radio
                                        checked={selectedRadio === item.id}
                                        onChange={() => handleRadioChange(item.id, 'individual')}
                                      />
                                    }
                                  />
                                </List.Item>


                              )}
                            />
                          </div>
                        ) : null}
                        <div className="d-flex justify-between ">
                          <p>Objective Members</p>
                          <p>
                            {/*<span className="mr-2 notification_bg"></span>
                               <Radio
                                checked={selectedRadio === "All Team Members"}
                                onChange={() =>
                                  handleRadioChange("All Team Members", "All Team Members")
                                }
                              /> */}
                          </p>
                        </div>
                        <div className="obj_member_list ">

                          <List
                            itemLayout="horizontal"
                            dataSource={selectedObjUser || []}
                            renderItem={(item: ObjTeam) => (
                              <List.Item onClick={(e) => {
                                e.stopPropagation();
                                const localStorageValue = localStorage.getItem("loginId");
                                if (item.id !== localStorageValue || item.id !== objective.supervisorId) { handleUserClick(item.id, "individual") }
                              }}>
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        item.profilePath || "assets/images/user.svg"
                                      }
                                    />
                                  } // Use default image if profilePath is null
                                  // title={`${capitalizeFirstLetter(item.name)}/${item.designation}/${item.payroll_Status}`}

                                  title={`${item.name}/${item.designation}/${item.payroll_Status}`}
                                  style={{ textTransform: 'capitalize' }}
                                  description={

                                    fhuseroption.map((showCft) => (
                                      showCft.id === item.id ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                          {item.firstLoggedStatus === 1 && (
                                            <span style={{
                                              padding: '2px 5px',
                                              background: '#32B7D9',
                                              color: '#fff',
                                              borderRadius: '10px',
                                              fontSize: '10px',
                                              marginRight: '10%'
                                            }}>CFT</span>
                                          )}
                                          <Radio
                                            checked={selectedRadio === item.id}
                                            onChange={() => handleRadioChange(item.id, 'individual')}
                                          />
                                        </div>
                                        // {/* // <Radio
                                        // //   checked={selectedRadio === item.id}
                                        // //   onChange={() => handleRadioChange(item.id, 'individual')}
                                        // // /> */}
                                      ) : null
                                    ))
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                      </div>
                      <div className="chats mt-1">
                        <div className=" d-flex justify-between algin-center ">
                          <span className="fs-14 c_32B7D9">Chats</span>
                          {selectedUser.length > 0 ? (
                            <NewChat GroupList={GroupList} objectiveId={objectiveId} objectiveType={objectiveType} />
                          ) : null}
                        </div>
                        {GroupData.map(group => {
                          const loginId = localStorage.getItem('loginId');
                          const isUserMember = group.groupMember.some((userDetails) => userDetails === loginId) || (loginId === group.createdBy);
                          return isUserMember ? (
                            <div
                              key={group.id}
                              className="d-flex justify-between align-center mt-1 cur-pointer"
                              onClick={() => handleRadioChange(group.id, 'group')}
                            >
                              <span style={{ textTransform: 'capitalize' }}>
                                {group.groupName === '' ? group.groupMemberDetails.join(' & ') : group.groupName}
                              </span>
                              <Radio
                                checked={selectedRadio === group.id}
                                onChange={() => handleRadioChange(group.id, 'group')}
                                value={group.id}
                              />
                            </div>
                          ) : null
                        })}
                      </div>
                    </>
                  </div>
                </div>
              </Col>
              <Col xl={13} lg={13} md={24} sm={24} className=" p-1">
                {/* <div className="border_EAEAEA p-1">
                  <div className="conversation_scroll">
                    <div className="">
                      <div className="mt-2">
                        <Flex>
                          <img
                            src="assets/images/Default_user.svg"
                            className="kr_user"
                            alt=""
                          />
                          <div className="ml-1">
                            <div className="">
                              <span className="fw-600 fs-14">
                                Praburaju/Product/CITPL
                              </span>
                              <span className="m-0 c_8080 fs-12 pl-1">
                                03 Jan 2023, 12 :00 PM
                              </span>
                            </div>
                            <p className="m-0 fs-16 pt-1 mt-1 right_conversation_box ">
                              That's understandable. Developing a cohesive color
                              scheme can be tricky. How about we schedule some
                              time to brainstorm together? We can bounce ideas off
                              each other and maybe even do a quick mood board to
                              visualize different options.
                            </p>
                          </div>
                        </Flex>
                      </div>
                      <div className="mt-3">
                        <Flex>
                          <div className="ml-1 mr-1">
                            <div className="justify-end">
                              <span className="fw-600 fs-14">
                                name
                              </span>
                              <span className="m-0 c_8080 fs-12 pl-1">
                                03 Jan 2023, 12 :00 PM
                              </span>
                            </div>
                            <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
                              That's understandable. Developing a cohesive color
                              scheme can be tricky. How about we schedule some
                              time to brainstorm together? We can bounce ideas off
                              each other and maybe even do a quick mood board to
                              visualize different options.
                            </p>
                          </div>
                          
                        </Flex>
                      </div>
                      
                    </div>
                    <div className="msg_sender">
                      <input
                        type="text"
                        className="w-100 p-2"
                        placeholder="Write here"
                        value={feedbackstore.feedback}
                      />
                      <img
                        src="assets/images/send.svg"
                        className="con_send_icon pr-1"
                        alt=""
                      />
                    </div>
                  </div>

                </div> */}
                {/* { type === 1 && selectedUserId ?(
        <div className="border_EAEAEA p-1">
          <div className="conversation_scroll">
            <div className="">
              {conversation.map((msg, index) => (
                <div key={index} className="mt-3">
                  <div className="mt-2">
                    <div className="flex">
                      <img
                        src="assets/images/Default_user.svg"
                        className="kr_user"
                        alt=""
                      />
                      <div className="ml-1">
                        <div className="">
                          <span className="fw-600 fs-14">
                            {msg.userName}
                          </span>
                          <span className="m-0 c_8080 fs-12 pl-1">
                            {new Date(msg.created_at).toLocaleString()}
                          </span>
                        </div> */}

                {/* </div> */}
                {(conversationType === "individual" || conversationType === "All Team Members" || conversationType === "Everyone") ? (
                  <div className="border_EAEAEA p-1">
                    <div className="conversation_scroll">
                      <div className="">
                        {conversation.map((msg, index) => (
                          <div key={index} className="mt-3">
                            {msg.createdBy !== localStorage.loginId ? (
                              <>
                                <div className="mt-2">
                                  <div className="">
                                    <div className=" d-flex align-start">
                                      <img
                                        src="assets/images/Default_user.svg"
                                        className="kr_user"
                                        alt=""
                                      />
                                      <div className="ml-1">
                                        <span className="fw-600 fs-14">
                                          {msg.createdByName}
                                        </span>
                                        <span className="m-0 c_8080 fs-12 pl-1">
                                          {changeDateFormat(msg.created_at,'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY h.mm A')}
                                        </span>

                                        <p className="m-0 fs-16 pt-1 mt-1 right_conversation_box ">
                                          {msg.conversation}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (

                              <div className="mt-3">
                                <div className="ml-1 mr-1">
                                  <div className="justify-end">
                                    <span className="fw-600 fs-14">
                                      {msg.createdByName}
                                    </span>
                                    <span className="m-0 c_8080 fs-12 pl-1"> {changeDateFormat(msg.created_at,'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY h.mm A')}</span>
                                  </div>
                                  <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
                                    {msg.conversation}
                                  </p>
                                </div>
                              </div>)}
                          </div>
                        ))}
                      </div>

                    </div>
                    <div className="msg_sender">
                      <input
                        type="text"
                        className="w-100 p-2"
                        placeholder="Write here"
                        value={chat}
                        onChange={(e) => setChat(e.target.value)}
                      />
                      <img
                        src="assets/images/send.svg"
                        className="con_send_icon pr-1"
                        alt=""
                        onClick={() => sendMessage(chat)}
                      />
                    </div>
                  </div>
                  //     <div className="mt-3">
                  //       <div className="flex">
                  //         <div className="ml-1 mr-1">
                  //           <div className="justify-end">
                  //             <span className="fw-600 fs-14">
                  //               {msg.createdByName}
                  //             </span>
                  //             <span className="m-0 c_8080 fs-12 pl-1"> {new Date(msg.created_at).toLocaleString()}</span>
                  //           </div>
                  //           <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
                  //             {msg.conversation}
                  //           </p>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // ))}
                  // </div>
                  //   <div className="msg_sender">
                  //     <input
                  //       type="text"
                  //       className="w-100 p-2"
                  //       placeholder="Write here"
                  //       value={chat}
                  //       onChange={(e) => setChat(e.target.value)}
                  //     />
                  //     <img
                  //       src="assets/images/send.svg"
                  //       className="con_send_icon pr-1"
                  //       alt=""
                  //       onClick={sendMessage}
                  //     />
                  //   </div>
                  // </div>
                  // </div>
                ) : type === 4 && GroupId ? (

                  <div className="border_EAEAEA p-1">
                    <div className="conversation_scroll">
                      <div className="">

                        {conversationGroup.map((msg, index) => (
                          <div key={index} className="mt-3">
                            {/* {login} test check {msg.createdBy}*/}
                            {login !== msg.createdBy ? (
                              <div className="mt-2">
                                <div className="">
                                  <div className=" d-flex align-start">
                                    <img
                                      src="assets/images/Default_user.svg"
                                      className="kr_user"
                                      alt=""
                                    />
                                    <div className="ml-1">
                                      <div className="">
                                        <span className="fw-600 fs-14">
                                          {msg.createdByName}
                                        </span>
                                        <span className="m-0 c_8080 fs-12 pl-1">
                                          {changeDateFormat(msg.created_at,'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY h.mm A')}
                                        </span>
                                      </div>
                                      <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
                                        {msg.conversation}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-3">
                                <div className="ml-1 mr-1">
                                  <div className="justify-end">
                                    <span className="fw-600 fs-14">
                                      {msg.createdByName}
                                    </span>
                                    <span className="m-0 c_8080 fs-12 pl-1"> {changeDateFormat(msg.created_at,'YYYY-MM-DD HH:mm:ss', 'DD MMM YYYY h.mm A')}</span>
                                  </div>
                                  <p className="m-0 fs-16 pt-1 mt-1 left_conversation_box ">
                                    {msg.conversation}
                                  </p>
                                </div>

                              </div>
                            )}
                          </div>
                        ))}

                      </div>
                    </div>
                    <div className="msg_sender">
                      <input
                        type="text"
                        className="w-100 p-2"
                        placeholder="Write here"
                        value={chat}
                        onChange={(e) => setChat(e.target.value)}
                      />
                      <img
                        src="assets/images/send.svg"
                        className="con_send_icon pr-1"
                        alt=""
                        onClick={sendGroupMessage}
                      />
                    </div>
                  </div>
                  // <div>Group ID: {GroupId}</div>
                ) : (
                  <div>No User selected</div>
                )
                }
              </Col>
              {/* <Col xl={6} lg={6} md={24} sm={24} className="feedback_div p-1">
                {selectedRadio === "All Team Members" ? (
                  <>
                    <div className="border_EAEAEA p-1">
                      <div className="d-flex justify-between align-center">
                        <div className="d-flex justify-between align-center">
                          <img
                            src="assets/images/feedback_Received.svg"
                            alt="Feedbacks Received"
                          />
                          <span className="pl-1">Feedbacks Given</span>
                        </div>
                        <div className="cur-pointer">
                          <img
                            src="assets/images/feedback_add.svg"
                            alt="Add Feedback"
                            onClick={() => {
                              setShowFBgiven(true);
                            }}
                          />
                        </div>
                      </div>
                      {Givenfeedbacks.length > 0 && (
                        <div className="mt-1">
                          <Button
                            icon={<LeftOutlined />}
                            onClick={handleGivenfeedbacksPrev}
                            disabled={currentGivenFeedback === 0}
                          />
                          <span className="mx-2">
                            {currentGivenFeedback + 1} / {Givenfeedbacks.length}
                          </span>
                          <Button
                            icon={<RightOutlined />}
                            onClick={handleGivenfeedbacksNext}
                            disabled={
                              currentGivenFeedback === Givenfeedbacks.length - 1
                            }
                          />
                        </div>
                      )}
                      {Givenfeedbacks.length === 0 && !showFBgiven ? (
                        <div className="border_EAEAEA p-1 mt-1">
                          <p className="c_8080 fs-12 text-center">
                            No feedbacks
                          </p>
                        </div>
                      ) : (
                        <>
                          {Givenfeedbacks.length > 0 && (
                            <div>
                              <p className="bg_f5f5f5 p-2 br-4 fs-16">
                                {Givenfeedbacks[currentGivenFeedback].feedback}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {showFBgiven && (
                        <div>
                          <TextArea
                            id="description"
                            rows={3}
                            placeholder="Write here...."
                            className="canvas-objective-input"
                            name="feedback"
                            value={feedbackstore.feedback}
                            onChange={(e) => { handlechange(e, "sridharrajam", "laksmi") }}
                            onChange={(e) => {
                              handlechange(e, "sridharrajam", "laksmi");
                            }}
                          />
                          {errors &&
                            errors.find(
                              (error) => error.path[0] === "feedback"
                            ) && (
                              <p className="danger">
                                {
                                  errors.find(
                                    (error) => error.path[0] === "feedback"
                                  )?.message
                                }
                              </p>
                            )}
                          <Divider />
                          <div className="d-flex justify-end">
                            <button
                              className="cancel_red_btn"
                              onClick={handleFBCancelClick}
                            >
                              Cancel
                            </button>
                            <button
                              className="cyan_button"
                              onClick={() => { handlesubmit(2, "raja", "kumar", "1234564343") }}
                              onClick={() => {
                                handlesubmit(2, "raja", "kumar", "1234564343");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                      <div>
                        <span className="fs-12 fw-600">Replied</span>
                        <p>
                          Great to hear! How's it coming along? Any challenges
                          you've encountered?
                        </p>
                      </div>
                      <div>
                        <span
                          className=" c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                          onClick={handleComment}
                        >
                          Write comment
                        </span>
                        {FBcomment && (
                          <>
                            <div>
                              <span className=" fs-12 fw-600">Comment</span>
                              <TextArea
                                id="description"
                                rows={3}
                                placeholder="Write comment..."
                                className="canvas-objective-input"
                                name="description"
                                value={replyFBContent}
                                onChange={(e) =>
                                  setReplyFBContent(e.target.value)
                                }
                              />
                              <Divider />
                              <div className="d-flex justify-end">
                                <button
                                  className="cancel_red_btn"
                                  onClick={handleFBCancelClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="cyan_button"
                                  onClick={() => { handlesubmit(1, "sridhar", "rajan", "") }}
                                  onClick={() => {
                                    handlesubmit(1, "sridhar", "rajan", "");
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border_EAEAEA p-1">
                      <div className="d-flex justify-between align-center">
                        <div className="d-flex justify-between align-center">
                          <img
                            src="assets/images/feedback_Received.svg"
                            alt="Feedbacks Received"
                          />
                          <span className="pl-1">Feedbacks Received</span>
                        </div>
                        <div>
                          <Button
                            icon={<LeftOutlined />}
                            onClick={handleFeedbackPrev}
                            disabled={currentFeedback === 0}
                          />
                          <span className="mx-2">
                            {currentFeedback + 1} / {feedbacks.length}
                          </span>
                          <Button
                            icon={<RightOutlined />}
                            onClick={handleFeedbackNext}
                            disabled={currentFeedback === feedbacks.length - 1}
                          />
                        </div>
                      </div>
                      {feedbacks.length === 0 ? (
                        <div className="border_EAEAEA p-1 mt-1">
                          <p className="c_8080 fs-12 text-center">
                            No feedbacks
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="">
                            <p className="bg_f5f5f5 p-2 br-4 fs-16">
                              {feedbacks[currentFeedback].content}
                            </p>
                          </div>
                          {!replied && !showReplyArea && (
                            <p
                              className="c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                              onClick={handleReplyClick}
                            >
                              Reply Feedback
                            </p>
                          )}
                          {showReplyArea && (
                            <div>
                              <span className="fw-600 fs-12 c-000">
                                Write Reply
                              </span>
                              <TextArea
                                id="description"
                                rows={3}
                                placeholder="Write here...."
                                className="canvas-objective-input"
                                name="description"
                                value={replyContent}
                                onChange={(e) =>
                                  setReplyContent(e.target.value)
                                }
                              />
                              <Divider />
                              <div className="d-flex justify-end">
                                <button
                                  className="cancel_red_btn"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="cyan_button"
                                  onClick={handleSubmitClick}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          )}
                          {replied && (
                            <div>
                              <span className="fw-600 fs-12 c-000">
                                Replied
                              </span>
                              <p className="bg_f5f5f5 p-2 br-4 fs-16">
                                {replyContent}
                              </p>
                            </div>
                          )}
                        </>
                      )}

                      <div>
                        <span className="fw-600 fs-12 c-000">
                          Reply Comment
                        </span>
                        <p className="bg_f5f5f5 p-2 br-4 fs-16">
                          Great to hear! How's it coming along? Any challenges
                          you've encountered?
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Col> */}
              <Col xl={6} lg={6} md={24} sm={24} className="feedback_div p-1">
                {type != 3 && (
                  checkuserid == false ? (
                    <>
                      <div className="border_EAEAEA p-1">
                        <div className="d-flex justify-between align-center">
                          <div className="d-flex justify-between align-center">
                            <img
                              src="assets/images/feedback_Received.svg"
                              alt="Feedbacks Received"
                            />
                            <span className="pl-1">Feedbacks Given</span>
                          </div>
                          {feedbacklist.length > 0 && <div className=" mt-1">
                            <Button
                              icon={<LeftOutlined />}
                              onClick={handleFeedbackPrev}
                              disabled={currentFeedback === 0}
                              className="cur-pointer"
                            />
                            <span className="mx-2">
                              <b>{currentFeedback + 1} </b> / {feedbacklist.length}
                            </span>
                            <Button
                              icon={<RightOutlined />}
                              onClick={handleFeedbackNext}
                              disabled={currentFeedback === feedbacklist.length - 1}
                              className="cur-pointer"
                            />
                          </div>}

                          {objective.supervisorId != localStorage.username && permissionforobjectives &&
                            <div className="cur-pointer">
                              <img
                                src="assets/images/feedback_add.svg"
                                alt="Add Feedback"
                                onClick={() => {
                                  setShowFBgiven(true);
                                }}
                              />
                            </div>
                          }

                        </div>


                        {/* {feedbacklist.length > 0 && (
                        <div className="mt-1">
                          <Button
                            icon={<LeftOutlined />}
                            onClick={handleGivenfeedbacksPrev}
                            disabled={currentGivenFeedback === 0}
                          />
                          <span className="mx-2">
                            {currentGivenFeedback + 1} / {Givenfeedbacks.length}
                          </span>
                          <Button
                            icon={<RightOutlined />}
                            onClick={handleGivenfeedbacksNext}
                            disabled={
                              currentGivenFeedback === Givenfeedbacks.length - 1
                            }
                          />
                        </div>
                      )} */}
                        {feedbacklist.length === 0 ? (
                          <div className="border_EAEAEA p-1 mt-1">
                            <p className="c_8080 fs-12 text-center">
                              No feedbacks
                            </p>
                          </div>
                        ) : (
                          <>
                            {/* {feedbacklist[currentFeedback].userDetails.map(
                                data=>(
                                  <>
                                <Avatar
                                  src={
                                    data.profilepath || "assets/images/user.svg"
                                  }
                                />
                                <span>{data.username} </span>
                              </>))
                             } */}
                            {type != 1 && (<>
                              {feedbacklist[currentFeedback].userDetails.map(user => (
                                <>

                                  <div className="d-flex align-center mt-1">
                                    <img
                                      src={user.profilepath || "assets/images/user.svg"}
                                      className="select_user"
                                    />
                                    <span className="pl-1 c_8080 member_title">{user.username}</span>
                                  </div>
                                </>
                              ))}
                            </>)}
                            <span className="fs-10 c_8080 fw-900 mx-20 justify-end">{feedbacklist[currentFeedback].created_At}</span>
                            <p className="bg_f5f5f5 p-2 mt-0  br-4 fs-16">
                              {feedbacklist[currentFeedback].feedback}
                            </p>

                            {/* 
                           */}
                          </>
                        )}
                        {showFBgiven && (
                          <div>
                            <TextArea
                              id="description"
                              rows={3}
                              placeholder="Write here...."
                              className="canvas-objective-input"
                              name="feedback"
                              value={feedbackstore.feedback}
                              onChange={(e) => { handlechange(e) }}
                            />
                            {errors && errors.find(error => error.path[0] === 'feedback') && (
                              <p className="danger">{errors.find(error => error.path[0] === 'feedback')?.message}</p>
                            )}
                            <Divider />
                            <div className="d-flex justify-end">
                              <button
                                className="cancel_red_btn"
                                onClick={handleFBCancelClick}
                              >
                                Cancel
                              </button>
                              <button
                                className="cyan_button"
                                onClick={() => { handlesubmit(1) }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                        {/* {(() => {
                      const matchedFeedback = feedbackreplied.find(data => data.feedbackid === feedbacklist[currentFeedback].id);
                      return (
                        matchedFeedback!=undefined?(
                          <div>
                            <span className="fs-12 fw-600">Replied</span>
                            <br></br>
                              <Avatar
                                  src={
                                    matchedFeedback.creatordetails.profilepath || "assets/images/user.svg"
                                  }
                                />
                              <span>{matchedFeedback.creatordetails.username}</span>
                              <p className="bg_f5f5f5 p-2 br-4 fs-16">
                                  {matchedFeedback?.feedback}
                              </p>
                              <span className="fs-12 fw-900 mx-20">{feedbacklist[currentFeedback].created_At}</span>
                              <br></br>
                              <span
                                  className="c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                                  onClick={()=>{handleComment(matchedFeedback?.createrid ?? "",feedbacklist[currentFeedback].id)}}
                              >
                                  Write comment
                              </span>
                          </div>):(<></>)
                      );
                  })()} */}
                        {feedbackreplied.length > 0 && feedbacklist.length > 0 &&
                          (() => {
                            const matchedFeedback = feedbackreplied.filter(data => data.feedbackid == feedbacklist[currentFeedback].id && selecteduserprimaryid.includes(data.createrid));
                            return (
                              matchedFeedback.length > 0 ? (
                                <div> {/* Enclose all JSX elements within a single parent div */}
                                  <span className="fs-12 fw-600">Replied</span>
                                  {matchedFeedback.map((feedback, index) => (
                                    <div key={index}>
                                      {
                                        type != 1 && (
                                          <>
                                            <div className="d-flex align-center mt-1">
                                              <img
                                                src={feedback.creatordetails.profilepath || "assets/images/user.svg"}
                                                className="select_user"
                                              />
                                              <span className="ml-1 member_title">{feedback.creatordetails.username}</span>
                                            </div>
                                          </>
                                        )
                                      }
                                      <span className="fs-10 fw-900 mx-20 justify-end">{feedback?.created_At}</span>
                                      <p className="bg_f5f5f5 p-2 br-4 fs-16 mt-0 mb-0">
                                        {feedback?.feedback}
                                      </p>
                                      <br></br>

                                      {(() => {
                                        const matchedFeedback = feedbackcommentlist.filter(data =>
                                          data.feedbackid === feedbacklist[currentFeedback].id &&
                                          data.userDetails.some(user => user.userid == feedback.createrid));
                                        return (
                                          matchedFeedback.length > 0 ? (
                                            <div> {/* Enclose all JSX elements within a single parent div */}
                                              {/* <span className="fw-600 fs-12 c-000">Comment</span> */}
                                              {matchedFeedback.map((feedback, index) => (
                                                <div key={index}>
                                                  {type != 1 && (<>
                                                    <div className="d-flex align-center mt-1">
                                                      <img
                                                        src={feedback.creatordetails.profilepath || "assets/images/user.svg"}
                                                        className="select_user"
                                                      />
                                                      <span className="pl-1 c_8080">{feedback.creatordetails.username}</span>
                                                    </div>
                                                  </>)}

                                                  <span className="fs-10 fw-900 mx-20 justify-end">{feedback?.created_At}</span>
                                                  <p className="bg_f5f5f5 p-2 br-4 fs-16 mt-0">
                                                    {feedback?.feedback}
                                                  </p>
                                                  {/* <span
                                                    className="c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                                                    onClick={()=>{handleComment(feedback?.createrid ?? "",feedbacklist[currentFeedback].id)}}
                                                >
                                                    Write comment 12
                                                </span> */}
                                                  {(() => {
                                                    const matchedFeedback = feedbackcommentlist.filter(data =>
                                                      data.feedbackid === feedbacklist[currentFeedback].id &&
                                                      data.userDetails.some(user => user.userid == feedback.createrid));
                                                    return (
                                                      matchedFeedback.length > 0 ? (
                                                        <div> {/* Enclose all JSX elements within a single parent div */}
                                                          {/* <span className="fw-600 fs-12 c-000">Comment</span> */}
                                                          <span className="fs-10 fw-900 mx-20 c_8080 justify-end">{feedback?.created_At}</span>
                                                          {matchedFeedback.map((feedback, index) => (
                                                            <div key={index}>
                                                              <p className="bg_f5f5f5 p-2 mt-0 br-4 mb-0 fs-16">
                                                                {feedback?.feedback}
                                                              </p>
                                                              <br></br>
                                                            </div>
                                                          ))}
                                                        </div>
                                                      ) : (<>

                                                      </>)
                                                    );
                                                  })()}
                                                </div>
                                              ))}
                                            </div>
                                          ) : (<><span
                                            className="c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                                            onClick={() => { handleComment(feedback?.createrid ?? "", feedbacklist[currentFeedback].id) }}
                                          >
                                            Write comment
                                          </span></>)
                                        );
                                      })()}
                                    </div>
                                  ))}
                                </div>
                              ) : (<></>)
                            );
                          })()
                        }
                        {FBcomment && (
                          <>
                            <div>
                              <span className=" fs-12 fw-600">Comment </span>

                              <TextArea
                                id="description"
                                rows={3}
                                placeholder="Write comment..."
                                className="canvas-objective-input"
                                name="feedback"
                                value={feedbackcomment.feedback}
                                onChange={(e) =>
                                  setfeedbackcomment(prevState => ({
                                    ...prevState,
                                    feedback: e.target.value.toString(),
                                  }))

                                }
                              />
                              {errors && errors.find(error => error.path[0] === 'feedback') && (
                                <p className="danger">{errors.find(error => error.path[0] === 'feedback')?.message}</p>
                              )}
                              <Divider />
                              <div className="d-flex justify-end">
                                <button
                                  className="cancel_red_btn"
                                  onClick={handleFBCancelClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="cyan_button"
                                  onClick={() => { handlesubmit(3) }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </>
                        )}


                      </div>
                    </>) : (
                    <>
                      <div className="border_EAEAEA p-1">
                        <div className="d-flex justify-between align-center">
                          <div className="d-flex justify-between align-center">
                            <img
                              src="assets/images/feedback_Received.svg"
                              alt="Feedbacks Received"
                            />
                            <span className="pl-1">Feedbacks Received</span>
                          </div>
                          {feedbacklist.length > 0 && feedbacklist.some(feedback =>
                            feedback.creatordetails.userid != localStorage.loginId
                          ) && (
                              <>
                                <div>
                                  <Button
                                    icon={<LeftOutlined />}
                                    onClick={handleFeedbackPrev}
                                    disabled={currentFeedback === 0}
                                  />
                                  <span className="mx-2">
                                    {currentFeedback + 1} / {feedbacklist.length}
                                  </span>
                                  <Button
                                    icon={<RightOutlined />}
                                    onClick={handleFeedbackNext}
                                    disabled={currentFeedback === feedbacklist.length - 1}
                                  />
                                </div>
                              </>
                            )}

                        </div>
                        {feedbacklist.length === 0 ? (
                          <div className="border_EAEAEA p-1 mt-1">
                            <p className="c_8080 fs-12 text-center">
                              No feedbacks
                            </p>
                          </div>
                        ) : (
                          <>
                            {feedbacklist.length > 0 && feedbacklist.some(feedback =>
                              feedback.creatordetails.userid != localStorage.loginId
                            ) &&
                              // feedbacklist.map((data, index) => (
                              //     <div key={index}>
                              //         <p className="bg_f5f5f5 p-2 br-4 fs-16">
                              //             {data.feedback} 
                              //         </p>
                              //     </div>
                              //   ))

                              <div className="">
                                <p className="bg_f5f5f5 p-2 br-4 fs-16">
                                  {feedbacklist[currentFeedback].feedback}
                                </p>
                                {(() => {
                                  // const matchedFeedback = feedbackcommentlist.filter(data =>
                                  //   data.feedbackid === feedbacklist[currentFeedback].id &&
                                  //   data.userDetails.some(user => user.userid == localStorage.loginId));
                                  const matchedFeedback = feedbackreplied.filter(data => data.feedbackid == feedbacklist[currentFeedback].id && data.createrid == localStorage.loginId);
                                  return (
                                    matchedFeedback.length == 0 ? (
                                      <div> {/* Enclose all JSX elements within a single parent div */}
                                        {!replied && !showReplyArea && (
                                          <span
                                            className="c_32B7D9 fs-12 fw-600 text_line cur-pointer"
                                            onClick={handleReplyClick}
                                          >
                                            Reply Feedback
                                          </span>
                                        )}

                                      </div>
                                    ) : (<></>)
                                  );
                                })()}

                              </div>
                            }

                            {showReplyArea && (
                              <div>
                                <span className="fw-600 fs-12 c-000">
                                  Write Reply
                                </span>
                                <TextArea
                                  id="description"
                                  rows={3}
                                  placeholder="Write here...."
                                  className="canvas-objective-input"
                                  name="feedback"
                                  value={FeedbackReply.feedback}
                                  onChange={(e) =>
                                    setfeedbackreply(prevState => ({
                                      ...prevState,
                                      objectiveid: objective.objectiveid,
                                      feedback: e.target.value.toString(),
                                      feedbackid: feedbacklist[currentFeedback].id,
                                      type: 2,
                                      createrid: localStorage.loginId,
                                      userid: [feedbacklist[currentFeedback].createrid]
                                    }))
                                  }
                                />
                                {errors && errors.find(error => error.path[0] === 'feedback') && (
                                  <p className="danger">{errors.find(error => error.path[0] === 'feedback')?.message}</p>
                                )}
                                <Divider />
                                <div className="d-flex justify-end">
                                  <button
                                    className="cancel_red_btn"
                                    onClick={handleCancelClick}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="cyan_button"
                                    onClick={() => handlesubmit(2)}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            )}
                            {(() => {
                              const matchedFeedback = feedbackreplied.filter(data => data.feedbackid == feedbacklist[currentFeedback].id && data.createrid == localStorage.loginId);
                              return (
                                matchedFeedback.length > 0 ? (
                                  <div> {/* Enclose all JSX elements within a single parent div */}
                                    <span className="fw-600 fs-12 c-000">
                                      Replied
                                    </span>
                                    {matchedFeedback.map((feedback, index) => (
                                      <div key={index}>
                                        <span className="fs-10 fw-900 mx-20  justify-end">{feedback?.created_At}</span>
                                        <p className="bg_f5f5f5 p-2 br-4 fs-16 mt-0">
                                          {feedback?.feedback}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                ) : (<></>)
                              );
                            })()}
                          </>
                        )}

                        <div>
                          {(() => {
                            const matchedFeedback = feedbackcommentlist.filter(data =>
                              data.feedbackid === feedbacklist[currentFeedback].id &&
                              data.userDetails.some(user => user.userid == localStorage.loginId));
                            return (
                              matchedFeedback.length > 0 ? (
                                <div> {/* Enclose all JSX elements within a single parent div */}
                                  <span className="fw-600 fs-12 c-000">Reply Comment</span>
                                  {matchedFeedback.map((feedback, index) => (
                                    <div key={index}>
                                      <span className="fs-10 fw-900 mx-20 justify-end">{feedback?.created_At}</span>
                                      <p className="bg_f5f5f5 p-2 br-4 fs-16 mt-0 mb-0">
                                        {feedback?.feedback}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : (<></>)
                            );
                          })()}
                        </div>
                      </div>
                    </>)
                )
                }
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Conversation;
