import { jwtDecode } from 'jwt-decode';

const checkTokenExpiration = () => {
  const token = localStorage.getItem('accessToken');
  return token;
};

const isBearerTokenExpired = (): boolean => {
  const getToken = setInterval(checkTokenExpiration, 10000);
 
  const token = checkTokenExpiration(); // Replace 'bearerToken' with the actual key used in your local storage
  if (!token) {
    // Token is not found in local storage
    return true; // Treat as expired
  }
 
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    // console.error('Error decoding token:', error);
    return true; // Treat as expired to be safe
  }

};

// const overallCheck = () => {
//   const tokenExpired = isBearerTokenExpired();
//  console.log(tokenExpired);
//   if (tokenExpired) {
//     //console.log();
//     // Token is expired and not on the login page, redirect to login page
//     // You can use Link to navigate instead of history.push

    
//     //onLogout();
//   }
// };

export default isBearerTokenExpired;