import React from 'react';
import { Tabs,Row } from 'antd';
import Darwinbox_CKPL from './Darwinbox_CKPL';
import Budgie_HEPL from './Budgie_HEPL';
import Budgie_CITPL from './Budgie_CITPL';
import UserTablelist from './UserTablelist';
import Reporting_user from './Reporting_users';

const { TabPane } = Tabs;

const onChange = (key: string) => {
  // console.log(key);
};
const onClose = () => {
  window.history.back();
};

const UserList_tab: React.FC = () => (
  <Tabs defaultActiveKey="2" onChange={onChange}  className='objective-tab'>
     {/* <TabPane tab="Users" key="1">
      <UserTablelist />
    </TabPane> */}
    <TabPane tab="Darwinbox-CKPL" key="2">
      <div className="non-absolute">
      <Darwinbox_CKPL />
      </div>
    </TabPane>
    <TabPane tab="Budgie-HEPL" key="3">
    <div className="non-absolute">
    <Budgie_HEPL />
    </div>
    </TabPane>
    <TabPane tab="Budgie-CITPL" key="4">
    <div className="non-absolute">
      <Budgie_CITPL />
      </div>
    </TabPane>
    <TabPane tab="Reporting-users" key="5">
    <div className="non-absolute">
      <Reporting_user />
      </div>
    </TabPane>
  </Tabs>
);

export default UserList_tab;
