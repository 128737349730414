import React, { useState, useEffect } from "react";
import { Flex, Rate, Spin } from "antd";
import {
  Row,
  Select,
  Button,
  Space,
  Dropdown,
  Menu,
  Divider,
  Radio,
  Col,
  Input,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import Review from "./Review";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { log } from "console";
import { ValueType } from "exceljs";

const { Option } = Select;

const { TextArea } = Input;

const desc = [
  "Unsatisfactory Performance😔",
  "Needs Improvement ⬆️",
  "Meets Expectation 🤝",
  "Strong Performer 💪🏻",
  "Exceed Expectations 🎖️",
];

interface ReviewEditProps {
  userId: string;
  employeeRating: string;
  managerRating: string;
  employeeRatingCount: string;
  managerRatingCount: string;
  employeeId: string;
  supervisorId: string;
}

interface overallRating {
  selfStatus: number;
  overallSelfRating: number;
  supervisorStatus: number;
  overallSupervisorRating: number;
  reviewerStatus: number;
  reviewerRating: number;
  reviewerRemarks: string;
};

const ReviewEdit: React.FC<ReviewEditProps> = ({
  userId,
  employeeRating,
  employeeRatingCount,
  managerRatingCount,
  managerRating,
  employeeId,
  supervisorId
}) => {
  const [reviewAppraisalData, setReviewAppraisalData] = useState<ObjectiveApparisal[]>([]);
  const [TMoptions, setTMoptions] = useState<User[]>([]);
  const [TMselectedOption, setTMSelectedOption] = useState<string>('');
  const [overallEmployeeRating, setOverallEmployeeRating] = useState<number>(0);
  const [overallSupervisorRating, setOverallSupervisorRating] = useState<number>(0);
  const [ReviewerRatingCount, setReviewerRatingCount] = useState<number>(0); // State for reviewer rating input
  const [currentIndex, setCurrentIndex] = useState(0);
  const [text, setText] = useState('');
  const [overAllRating, setOverAllRating] = useState<overallRating>({
    selfStatus: 0,
    overallSelfRating: 0,
    supervisorStatus: 0,
    overallSupervisorRating: 0,
    reviewerStatus: 0,
    reviewerRating: ReviewerRatingCount,
    reviewerRemarks: text,
  });
  const [loading, setLoading] = useState(true);
  const appraisalItems = (
    <Menu className="SignOff_dropdown">
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/RequestPending.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1"> Appraisal Pending</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/Signoff_Completed.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Appraisal Completed</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img src="assets/images/NotRequested.svg" alt="" className="WH_16" />
          <span className="fs-14 pl-1"> Not Submitted</span>
        </div>
      </Menu.Item>
    </Menu>
  );


  const handleArrow = (index: any) => {
    const newArrow = [...arrow];
    newArrow[index] = !newArrow[index];
    setArrow(newArrow);
  };

  const handleTMPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); // Decrease index to select previous user
      setTMSelectedOption(TMoptions[currentIndex - 1].id); // Update selected user ID
    }
    editReviewAppraisal(TMoptions[currentIndex - 1].username);
    showOverAllUserAllRating(TMoptions[currentIndex - 1].username);
  };

  const handleTMNext = () => {
    if (currentIndex < TMoptions.length - 1) {
      setCurrentIndex(currentIndex + 1); // Increase index to select next user
      setTMSelectedOption(TMoptions[currentIndex + 1].id); // Update selected user ID
    }
    editReviewAppraisal(TMoptions[currentIndex + 1].username);
    showOverAllUserAllRating(TMoptions[currentIndex + 1].username);
  };

  const isPrevAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) > 0;
  const isNextAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) <
    TMoptions.length - 1;

  const buttonStyle = (isAvailable: any) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });

  const filteredObjectives = reviewAppraisalData.filter(
    (objective) => objective.createdby === TMselectedOption
  );

  const [Confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(!Confirm);
  };

  const handleOptionChange = (value: string) => {
    setTMSelectedOption(value);
    const newIndex = TMoptions.findIndex((user) => user.id === value);
    setCurrentIndex(newIndex);
    editReviewAppraisal(TMoptions[newIndex].username);
    showOverAllUserAllRating(TMoptions[newIndex].username);
  };

  const [teamAppraisal, setTeamAppraisal] = useState(false);


  interface ObjectiveApparisal {
    keyresultdetails: keyResultApparisal[];
    assigneeweightage: string;
    createdby: string;
    objectivedescription: string;
    objectiveid: string;
    objectivename: string;
    objectivestatus: string;
    objectiveweightage: string;
    objid: string;
    orgobjectiveid: string;
    parentid: string;
    quarter: string;
    weightage: string;
    year: string;
    comments: string;
    appraisalstatus: number;
    supervisorstatus: number;
    supervisorremarks: string;
    supervisoratings: number;
    reviewRatings: number;
    reviewRemarks: string;
    reviewStatus: number;
    overallEmployeeRating: number;
    overallSupervisorRating: number;
  }

  interface keyResultApparisal {
    keyresulttitle: string;
    keyresultdescription: string;
    keyresultstatus: string;
    objectiveid: string;
    keyresultid: string;
    objid: string;
    weightage: string;
    krpercentage: string;
    comments: string;
    appraisalstatus: string;
    krid: string;
    supervisorstatus: number;
    supervisoremarks: string;
    supervisoratings: number;
    targetValue: number;
    uomType: string;
    amountType: string;
    krActualPercent: number;
  }

  interface User {
    id: string;
    name: string;
    email: string;
    profilePath: string;
    signoffStatus: number;
    designation: string;
    username: string;
  }

  // const [text, setText] = useState(['']);
  

  const [submitted, setSubmitted] = useState(false); // State to track submission
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const [arrow, setArrow] = useState(
  //   teamAppraisalData.objectives.map(() => false)
  // );

  // const [objectiveRatings, setObjectiveRatings] = useState(
  //   teamAppraisalData.objectives.map(() => "0%")
  // );

  const [arrow, setArrow] = useState<boolean[]>([]);
  const [objectiveRatings, setObjectiveRatings] = useState<string[][]>([[]]);

  const maxCharacters = 250;
  const [backReview, setBackReview] = useState(true);
  const handleBackReview = () => {
    setBackReview(!backReview);
  };

  const handleRatingChange = (e: any) => {
    const value = e.target.value;
    setReviewerRatingCount(value);
    // console.log(value, "revieweerrr ");

  };
  const handleTextChange = (e: any) => {
    const value = e.target.value;
    setText(value);
    // console.log(value, "print ValueType....");

  };


  const handleSubmit = (reviewStatus: string) => {
    if (ReviewerRatingCount !== 0  && text != "" && reviewStatus === "2") {
      setSubmitted(true);
    }
    updateReviewApparisal(reviewStatus);
  };

  const userLists = async () => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${supervisorId}/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${supervisorId}/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTMoptions(response.data);
    } catch (err) {
      // console.error("Error fetching user list:", err);
      setTMoptions([]);
    }
  };



  useEffect(() => {
    if (ReviewerRatingCount !== 0 && text != "") {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    userLists();
  }, []);

  const editReviewAppraisal = async (employeeId: string) => {
    try {
      const response = await axios.get(BaseUrl + `api/Appraisal/gettingappraisallist/${employeeId}/${localStorage.getItem("Quarter")}/${localStorage.getItem("year")}`);
      setReviewAppraisalData(response.data.data);
      var keydatas: boolean[] = [];
      const objdata: ObjectiveApparisal[] = response.data.data;
      // if (objdata.length != 0) {
      //   setOverallEmployeeRating(objdata[0].overallEmployeeRating);
      //   setOverallSupervisorRating(objdata[0].overallSupervisorRating);
      //   setReviewerRatingCount(objdata[0].reviewRatings.toString());
      //   setText(objdata[0].reviewRemarks);
      // }
      if (objdata.some(data => data.reviewStatus == 2)) {
        setTeamAppraisal(true)
      }
      objdata.map(data => data.keyresultdetails.map(keydata => keydatas.push(false)))
      setArrow(keydatas);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  }

  const updateReviewApparisal = async (reviewStatus: string) => {

    try {
      const apiUrl = BaseUrl + `api/Appraisal/update/reviwer`;

      const formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("rating", ReviewerRatingCount.toString());
      formData.append("comment", text || '');
      formData.append("year", localStorage.getItem('year') || '');
      // formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("quarter", "Q1");
      formData.append("reviewerStatus", reviewStatus);

      await axios.post(apiUrl, formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          }
        }
      ).then((response) => {
        // console.log(response.data.data);
        editReviewAppraisal(TMoptions[currentIndex].username);
        setReviewerRatingCount(response.data.data.reviewerRating);
        setText(response.data.data.reviewerRemarks);
        showOverAllUserAllRating(TMoptions[currentIndex].username);
      })
    } catch (error) {

    }
  }

  const showOverAllUserAllRating = async (employeeId: string) => {
    try {
      const apiUrl = BaseUrl + `api/Appraisal/user/overall/rating`;

      const formData = new FormData();
      formData.append("userId", employeeId);
      formData.append("year",'2024-2025');
      formData.append("quarter",'Q1');
      // formData.append("year", localStorage.getItem('year') || '');
      // formData.append("quarter", localStorage.getItem('Quarter') || '');
  
  
      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setReviewerRatingCount(response.data.data.reviewerRating);
        setText(response.data.data.reviewerRemarks);
        setOverAllRating(response.data.data);
        // setOverAllRating({
        //   reviewerRating :  ReviewerRatingCount,
        //   reviewerRemarks : text,
        // })
      });
    } catch (error) {
      console.log("Error", error);      
    }   
  }

  useEffect(() => {
    if (TMoptions.length > 0) {
      setTMSelectedOption(userId);
      editReviewAppraisal(employeeId);
    }
    showOverAllUserAllRating(employeeId);
  }, [TMoptions]);

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  const allCommentsPresent=ReviewerRatingCount === 0 || text === "" ? true:false;

  return (
    <>
      {backReview ? (
        <>
          <div className="permission_btn team_appraisal">
            {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
              <div className="content" />
            </Spin>}
            <div className="justify-end align-center z-1">
              <Space
                direction="horizontal"
                className="z-1 mr-1 appraisal_user_select"
              >
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleTMPrev}
                  style={buttonStyle(isPrevAvailable)}
                  disabled={!isPrevAvailable}
                />
                <Select
                  className="custom-select w-300"
                  value={TMselectedOption}
                  onChange={handleOptionChange}
                >
                  {TMoptions.map((TMoption) => (
                    <label key={TMoption.id}>
                      <Radio
                        value={TMoption.id}
                        checked={TMselectedOption === TMoption.id}
                      // onChange={handleOptionChange}
                      >
                        <div className="align-center justify-between status_signoff_user">
                          <img
                            src={
                              TMoption.profilePath
                                ? FileUrl + TMoption.profilePath
                                : "assets/images/Default_user.svg"
                            }
                            alt={TMoption.name}
                            className="mr-1"
                          />
                          <span className="mr-1">{TMoption.name}/{TMoption.designation}</span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 2 ? (
                              <img
                                src="assets/images/Signoff_Completed.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 1 ? (
                              <img
                                src="assets/images/RequestPending.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 0 ? (
                              <img
                                src="assets/images/NotRequested.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </Radio>
                    </label>
                  ))}
                </Select>
                <Button
                  icon={<RightOutlined />}
                  onClick={handleTMNext}
                  style={buttonStyle(isNextAvailable)}
                  disabled={!isNextAvailable}
                />
              </Space>
              
              <button
                  className="save_as_draft p-2"
                  onClick={handleBackReview}
                >
                  Back to Main Page
                </button>
                
              {!teamAppraisal ? (
                <>
                  <button className="save_as_draft p-2" onClick={() => handleSubmit("1")}>Save as draft</button>
                  <button
                    className={` ${ReviewerRatingCount === 0 || text === "" ? "cascade_button" : " cyan_button"
                      }`}
                     disabled={allCommentsPresent}
                    onClick={() => handleSubmit("2")}
                  >
                    Submit
                  </button>
                </>
              ) : (
                null
              )}
              <Dropdown
                overlay={appraisalItems}
                trigger={["click"]}
                className="info_sign_off"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div className="p-1 ">
                      <img
                        src="assets/images/Non-categorized.svg"
                        alt=""
                        className="cur-pointer"
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            </div>
            {reviewAppraisalData.length === 0 && (
              <p className="signoff_border c_8080 fs-14">
                Sign-off the objectives to start Appraisal Process
              </p>
            )}

            {!teamAppraisal ? (
              <>
                <div>
                  <div className="appraisal_rating mt-1 mb-2">
                    <Row>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Employee Rating</p>
                        <span className="c_018BB9 fs-30">{overAllRating.overallSelfRating != 0 && overAllRating.selfStatus === 2 ? overAllRating.overallSelfRating + "%" : "-"} </span>
                        {/* <span className="c_018BB9 fs-30">{overallEmployeeRating != 0 ? overallEmployeeRating + "%" : "-"} </span> */}
                        {/* <span className="c_018BB9 fs-30">{`${calculateAverageRating()} %`}</span> */}
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Manager Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.overallSupervisorRating != 0 && overAllRating.supervisorStatus === 2 ? overAllRating.overallSupervisorRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{overallSupervisorRating !=0 ? overallSupervisorRating + "%" : "-" }</span> */}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">
                          Reviewer Rating *
                        </p>
                        {!submitted ? (
                          <>
                            <Input
                              type="number"
                              className="percentage_input w-50 bg_white"
                              suffix="%"
                              value={ReviewerRatingCount}
                              //onChange={handleRatingChange}
                              onChange={(e) => {
                                const inputVal = e.target.value;
                                const numericRegex = /^([1-9][0-9]?|100)?$/;
                                if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                  handleRatingChange(e);
                                  // handlePercentChange(
                                  //       index,
                                  //       krIndex,
                                  //       e);
                                  // handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                  // console.log("inputvaluesdetails", inputVal)
                                } else {

                                }
                              }}

                              maxLength={3}
                            />
                          </>
                        ) : (
                          <>
                            <p className="c_018BB9 fs-30 m-0 fw-700 ml-1">{ReviewerRatingCount} %</p>
                          </>
                        )}
                      </Col>
                      {/* {handleRatingChange} */}
                      <Col xl={12} lg={12} md={12} sm={12}>
                        {!submitted ? (
                          <>
                            <div className="justify-between">
                              <p className="fs-12 c_121212  fw-700">
                                Reviewer Comment *
                              </p>
                              <span
                                style={{
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  color: "gray",
                                }}
                              >
                                {/* {text.length}/{maxCharacters} */}
                              </span>
                            </div>
                            <TextArea
                              rows={2}
                              placeholder="Write here...."
                              className="canvas-objective-input w-100 bg_white  border_ccc"

                              value={text}
                              onChange={handleTextChange}
                              maxLength={maxCharacters}
                            />
                          </>
                        ) : (
                          <>
                            <p className="fs-12 c_121212  fw-700">
                              Reviewer Comment *
                            </p>
                            <p>{text}</p>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating_scroll">
                    {reviewAppraisalData.map((objective, index) => (
                      objective.quarter == "Q1" && objective.year == "2024-2025" && (
                        <div key={index} className="borderbottom_EAEAEA mt-2">
                          <div className="objective_overall">
                            <table>
                              <thead
                                className={`d-flex ${!submitted
                                  ? "align-center appraisal  pb-2"
                                  : "appraisal  pb-2 "
                                  }`}
                              >
                                <tr className="d-flex">
                                  <th
                                    className={`${!submitted
                                      ? ""
                                      : "align-center appraisal  pb-2 "
                                      }`}
                                  >
                                    <img
                                      src={
                                        arrow[index]
                                          ? "assets/images/up_arrow_accord.svg"
                                          : "assets/images/down_arrow_accord.svg"
                                      }
                                      alt=""
                                      className="cur-pointer"
                                      onClick={() => handleArrow(index)}
                                    />
                                  </th>
                                  <th>
                                    <div key={objective.objectiveid} className=" pl-2">
                                      {/* Objective Card */}
                                      <div className="myobj-card d-flex flex-column justify-between">
                                        <div>
                                          <div className="d-flex justify-between w-100">
                                            <div className="wrapper_label align-center mb-2 ">
                                              <span className="objid_success align-center">
                                                <img
                                                  src="assets/images/White_crown.svg"
                                                  alt=""
                                                  className="pr-1"
                                                />
                                                {objective.objid}
                                              </span>
                                              <span className="sign_off_triangle"></span>
                                            </div>
                                            <div
                                              className={`obj-status-${objective.objectivestatus}`}
                                            ></div>
                                          </div>
                                          <p className="twoline_text_overflow fs-14">
                                            {objective.objectivename}
                                          </p>
                                        </div>
                                        <div className="d-flex align-center justify-between">
                                          <div className="d-flex align-center">
                                            <>
                                              <img
                                                src="assets/images/Network_Icon.svg"
                                                alt=""
                                              />
                                              <span className="fw-700 c_000">
                                                {objective.weightage}
                                              </span>
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="mr-1 p-1"
                                    style={{ background: "#F5F5F5" }}
                                  >
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label
                                        htmlFor=""
                                        className="fs-12 fw-700 c_8080 mb-5"
                                      >
                                        Objective Percentage
                                      </label>
                                      <p className="fs-16 m-0 fw-300">{objective.objectiveweightage}</p>
                                    </div>
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label
                                        htmlFor=""
                                        className="fs-12 fw-700 c_8080 mb-5"
                                      >
                                        Employee Comment
                                      </label>
                                      <p className="fs-14 m-0 fs-14 fw-300">
                                        {/* These initiatives to be completed by Mar
                                      2024. Coordinate with concerned
                                      departments and get feedback */}
                                        {objective.comments}
                                      </p>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="text-start">
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="fs-12 c_8080 fw-700"
                                        >
                                          Objective Percentage
                                        </label>
                                        <p className="fs-16 m-0 fw-300">{objective.supervisoratings}</p>
                                      </div>
                                      <div className="mt-1">
                                        <label className="fs-12 fw-700 c_8080">
                                          Manager Comment
                                        </label>
                                        <p className="fs-14 fw-300 m-0">
                                          {/* These initiatives to be completed by Mar
                                        2024. Coordinate with concerned
                                        departments and get feedback */}
                                          {objective.supervisorremarks}
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              {arrow[index] && (
                                <>
                                  <div className="">
                                    <div
                                      key={objective.objectiveid}
                                      className=" pl-2 pr-2"
                                    >
                                      {objective.keyresultdetails.map((kr, krIndex) => (
                                        <tbody key={kr.keyresultid}>
                                          <tr className="d-flex mb-1">
                                            <td>&nbsp;</td>
                                            <td className=" pl-2">
                                              <div className="SignOff_Kr_card flex-column p-1">
                                                <Row className="d-flex justify-between align-center">
                                                  <div className="d-flex">
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_crown.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {objective.objid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span
                                                          className="triangle_left"
                                                          style={{
                                                            borderTop:
                                                              "25px solid #c2fbff",
                                                            borderLeft:
                                                              "28px solid transparent",
                                                          }}
                                                        ></span>
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_KRA_Icon.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {kr.krid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex align-center">
                                                      <div
                                                        className={`obj-status-${kr.keyresultstatus}`}
                                                      ></div>
                                                      <div className="d-flex align-center">
                                                        <>
                                                          <img
                                                            src="assets/images/Network_Icon.svg"
                                                            alt=""
                                                          />
                                                          <span className="c_8080">
                                                            {kr.weightage}
                                                          </span>
                                                        </>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <div className="fs-14 twoline_text_overflow fw-600">
                                                  {kr.keyresulttitle}
                                                </div>
                                                {/* <div className="justify-between">
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Target :</label> <span className="c_000 fs-10">{kr.targetValue}</span>
                                                  </div>
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Actual :</label> <span className="c_32B7D9 fs-10">{kr.krActualPercent}</span>
                                                  </div>
                                                </div> */}
                                                {kr.uomType != "Custom" &&
                                                  (
                                                    <>
                                                      <div className="justify-between">
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Target :
                                                          </label>{" "}
                                                          <span className="c_000 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Actual :
                                                          </label>{" "}
                                                          <span className="c_32B7D9 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.krActualPercent} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.krActualPercent} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.krActualPercent}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )

                                                }
                                              </div>
                                            </td>
                                            <td
                                              className="mr-1 p-1"
                                              style={{ background: "#F5F5F5" }}
                                            >
                                              <div style={{ width: "493px" }}>
                                                <label
                                                  htmlFor=""
                                                  className="fs-12 fw-700 c_8080"
                                                >
                                                  KR Percentage &nbsp;
                                                </label>
                                                <span className="fw-300 fs-12 c_121212">
                                                  {/* 100 % */}
                                                  {kr.krpercentage}
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fw-700  fs-12"
                                                >
                                                  Employee Remarks
                                                </label>
                                                <p className="c_121212 fs-14 m-0">
                                                  {/* You have worked very well */}
                                                  {kr.comments}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fs-12 fw-700"
                                                >
                                                  KR Percentage :
                                                </label>
                                                <span className="fw-300 fs-16">
                                                  {/* 100 % */}
                                                  {kr.supervisoratings}
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fs-12 fw-700"
                                                >
                                                  Manager Remarks
                                                </label>
                                                <p className="fs-14 c_8080 fs-14 m-0">
                                                  {/* You have worked very well */}
                                                  {kr.supervisoremarks}
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <div>vgb</div> */}
                <div>
                  <div className="appraisal_rating mt-1 mb-2">
                    <Row>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Employee Rating</p>
                        <span className="c_018BB9 fs-30">{overAllRating.overallSelfRating != 0 && overAllRating.selfStatus === 2 ? overAllRating.overallSelfRating + "%" : "-"} </span>
                        {/* <span className="c_018BB9 fs-30">{overallEmployeeRating != 0 ? overallEmployeeRating + "%" : "-"} </span> */}
                        {/* <span className="c_018BB9 fs-30">{`${calculateAverageRating()} %`}</span> */}
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Manager Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.overallSupervisorRating != 0 && overAllRating.supervisorStatus === 2 ? overAllRating.overallSupervisorRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{overallSupervisorRating !=0 ? overallSupervisorRating + "%" : "-" }</span> */}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.reviewerRating != 0 && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{ReviewerRatingCount != 0 ? ReviewerRatingCount + "%" :"-"}</span> */}
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Comment</p>
                        <p className="fs-14 mt-3 ">{overAllRating.reviewerRemarks != "" && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRemarks : "-"}</p>
                        {/* <p className="fs-14 mt-3 ">{Reviewertext != "" ? Reviewertext : "-"}</p> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating_scroll">
                    {reviewAppraisalData.map((objective, index) => (
                      objective.quarter == "Q1" && objective.year == "2024-2025" && (
                        <div key={index} className="borderbottom_EAEAEA mt-2">
                          <div className="objective_overall">
                            <table>
                              <thead
                                className={`d-flex ${!submitted
                                  ? "align-center appraisal  pb-2"
                                  : "appraisal  pb-2 "
                                  }`}
                              >
                                <tr className="d-flex">
                                  <th
                                    className={`${!submitted
                                      ? ""
                                      : "align-center appraisal  pb-2 "
                                      }`}
                                  >
                                    <img
                                      src={
                                        arrow[index]
                                          ? "assets/images/up_arrow_accord.svg"
                                          : "assets/images/down_arrow_accord.svg"
                                      }
                                      alt=""
                                      className="cur-pointer"
                                      onClick={() => handleArrow(index)}
                                    />
                                  </th>
                                  <th>
                                    <div key={objective.objectiveid} className=" pl-2">
                                      {/* Objective Card */}
                                      <div className="myobj-card d-flex flex-column justify-between">
                                        <div>
                                          <div className="d-flex justify-between w-100">
                                            <div className="wrapper_label align-center mb-2 ">
                                              <span className="objid_success align-center">
                                                <img
                                                  src="assets/images/White_crown.svg"
                                                  alt=""
                                                  className="pr-1"
                                                />
                                                {objective.objid}
                                              </span>
                                              <span className="sign_off_triangle"></span>
                                            </div>
                                            <div
                                              className={`obj-status-${objective.objectivestatus}`}
                                            ></div>
                                          </div>
                                          <p className="twoline_text_overflow fs-14">
                                            {objective.objectivename}
                                          </p>
                                        </div>
                                        <div className="d-flex align-center justify-between">
                                          <div className="d-flex align-center">
                                            <>
                                              <img
                                                src="assets/images/Network_Icon.svg"
                                                alt=""
                                              />
                                              <span className="fw-700 c_000">
                                                {objective.weightage}
                                              </span>
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="mr-1 p-1"
                                    style={{ background: "#F5F5F5" }}
                                  >
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label
                                        htmlFor=""
                                        className="fs-12 fw-700 c_8080 mb-5"
                                      >
                                        Objective Percentage
                                      </label>
                                      <p className="fs-16 m-0 fw-300">{objective.objectiveweightage}</p>
                                    </div>
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label
                                        htmlFor=""
                                        className="fs-12 fw-700 c_8080 mb-5"
                                      >
                                        Employee Comment
                                      </label>
                                      <p className="fs-14 m-0 fs-14 fw-300">
                                        {/* These initiatives to be completed by Mar
                                      2024. Coordinate with concerned
                                      departments and get feedback */}
                                        {objective.comments}
                                      </p>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="text-start">
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="fs-12 c_8080 fw-700"
                                        >
                                          Objective Percentage
                                        </label>
                                        <p className="fs-16 m-0 fw-300">{objective.supervisoratings}</p>
                                      </div>
                                      <div className="mt-1">
                                        <label className="fs-12 fw-700 c_8080">
                                          Manager Comment
                                        </label>
                                        <p className="fs-14 fw-300 m-0">
                                          {/* These initiatives to be completed by Mar
                                        2024. Coordinate with concerned
                                        departments and get feedback */}
                                          {objective.supervisorremarks}
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              {arrow[index] && (
                                <>
                                  <div className="">
                                    <div
                                      key={objective.objectiveid}
                                      className=" pl-2 pr-2"
                                    >
                                      {objective.keyresultdetails.map((kr, krIndex) => (
                                        <tbody key={kr.keyresultid}>
                                          <tr className="d-flex mb-1">
                                            <td>&nbsp;</td>
                                            <td className=" pl-2">
                                              <div className="SignOff_Kr_card flex-column p-1">
                                                <Row className="d-flex justify-between align-center">
                                                  <div className="d-flex">
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_crown.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {objective.objid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span
                                                          className="triangle_left"
                                                          style={{
                                                            borderTop:
                                                              "25px solid #c2fbff",
                                                            borderLeft:
                                                              "28px solid transparent",
                                                          }}
                                                        ></span>
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_KRA_Icon.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {kr.krid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex align-center">
                                                      <div
                                                        className={`obj-status-${kr.keyresultstatus}`}
                                                      ></div>
                                                      <div className="d-flex align-center">
                                                        <>
                                                          <img
                                                            src="assets/images/Network_Icon.svg"
                                                            alt=""
                                                          />
                                                          <span className="c_8080">
                                                            {kr.weightage}
                                                          </span>
                                                        </>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <div className="fs-14 twoline_text_overflow fw-600">
                                                  {kr.keyresulttitle}
                                                </div>
                                                {/* <div className="justify-between">
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Target :</label> <span className="c_000 fs-10">{kr.targetValue}</span>
                                                  </div>
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Actual :</label> <span className="c_32B7D9 fs-10">{kr.krActualPercent}</span>
                                                  </div>
                                                </div> */}
                                                {kr.uomType != "Custom" &&
                                                  (
                                                    <>
                                                      <div className="justify-between">
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Target :
                                                          </label>{" "}
                                                          <span className="c_000 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Actual :
                                                          </label>{" "}
                                                          <span className="c_32B7D9 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.krActualPercent} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.krActualPercent} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.krActualPercent}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )

                                                }
                                              </div>
                                            </td>
                                            <td
                                              className="mr-1 p-1"
                                              style={{ background: "#F5F5F5" }}
                                            >
                                              <div style={{ width: "493px" }}>
                                                <label
                                                  htmlFor=""
                                                  className="fs-12 fw-700 c_8080"
                                                >
                                                  KR Percentage &nbsp;
                                                </label>
                                                <span className="fw-300 fs-12 c_121212">
                                                  {/* 100 % */}
                                                  {kr.krpercentage}
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fw-700  fs-12"
                                                >
                                                  Employee Remarks
                                                </label>
                                                <p className="c_121212 fs-14 m-0">
                                                  {/* You have worked very well */}
                                                  {kr.comments}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fs-12 fw-700"
                                                >
                                                  KR Percentage :
                                                </label>
                                                <span className="fw-300 fs-16">
                                                  {/* 100 % */}
                                                  {kr.supervisoratings}
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="c_8080 fs-12 fw-700"
                                                >
                                                  Manager Remarks
                                                </label>
                                                <p className="fs-14 c_8080 fs-14 m-0">
                                                  {/* You have worked very well */}
                                                  {kr.supervisoremarks}
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Review />
        </>
      )}
    </>
  );
};

export default ReviewEdit;
