import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
  Modal,
  Flex,
  message,
  Dropdown,
  Space,
  Tabs
} from "antd";
import type { MenuProps } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import Base from "antd/es/typography/Base";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
const { Option } = Select;
const { TabPane } = Tabs;
const onChange = (key: string) => {

};

interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: number;
  ImageUrl: string;
}
interface Audittrails {
  id: string;
  modelname: string;
  newdata: string;
  olddata: string;
  status: number;
  createdat: string;
  toid: string[];
  oldtoidstring: string[];
  remark: string;
}
interface OptionItem {
  value: string;
  label: string;
  image: string;
  // name: string;
}

interface Username {
  name: any;
  id: any;
}

interface OptionItems {
  value: string;
  label: string;
  image: string;
  username: Username[];
}

interface Objectiveid {
  orgObjList: () => void;
  itemId: String | null;
  objectiveName: String | null;
  orgobjectivecode: string;
}

interface BandList {
  band: string;
};

interface orgGroup {
  fieldName: string;
  id: string;
}

const EditObj: React.FC<Objectiveid> = ({ orgObjList, itemId, objectiveName, orgobjectivecode }) => {

  const [open, setOpen] = useState(false);
  const [objAudit, setObjAudit] = useState<Boolean[]>([]);
  const [memberAudit, setMemberAudit] = useState<Boolean[]>([]);

  const handleObjAudit = (index: number) => {
    setObjAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };
  const handleMebAudit = (index: number) => {
    setMemberAudit((prevObjAudit) => {
      const newObjAudit = [...prevObjAudit];
      newObjAudit[index] = !newObjAudit[index];
      return newObjAudit;
    });
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('accessToken');
    return token;
  };
  const getToken = setInterval(checkTokenExpiration, 10000);
  const token = checkTokenExpiration();

  const showDrawer = () => {
    setOpen(true);
    // orgobjectiveslist();
    getWordCount();
    getcycleselectedOption();
    assignWeightObjective();
  };


  const onClose = () => {
    setOpen(false);
    setTextClicked(false);
    setSelectedMemValues([]);
    setMemberValues([])
  };
  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [cycleselectedOption, setCycleSelectedOption] = useState<string>();
  const [quarterselectedOption, setQuarterSelectedOption] = useState<string>("");

  const [VisibilityselectedOption, setVisibilitySelectedOption] = useState<string>();
  const [bandList, setBandList] = useState<BandList[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWeightageButton, setIsWeightageButton] = useState(false);
  const [orgGroupList, setOrgGroupList] = useState<orgGroup[]>([]);
  const [orgGroupselectedOption, setOrgGroupSelectedOption] = useState<string>("");


  const getBandList = async () => {
    try {
      const Lists = await axios.get(BaseUrl + "api/users/bandList");
      setBandList(Lists.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const getOrgGroupList = async () => {
    try {
      const orgLists = await axios.get(BaseUrl + "api/orgGroupMaster/getAll");
      setOrgGroupList(orgLists.data);
      setOrgGroupSelectedOption(orgLists.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCycleSelect = (value: string) => {
    setCycleSelectedOption(value);
    setOrganizationObjective((prevOrgObj) => ({
      ...prevOrgObj,
      year: value
    }));
    const yearvalue = value;
    const apiUrl = BaseUrl + `api/organization/quarters`;
    axios.post(apiUrl, {
      "year": yearvalue,
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setQuarterOpt(response.data);
        let quarter: number = new Date().getMonth();
        let changeQuarter = value === "2023-2024" ? "Q4" : "Q1";
        handleQuaterSelect(changeQuarter);
      });
  };

  const handleQuaterSelect = (value: string) => {
    setQuarterSelectedOption(value);

    setOrganizationObjective(prevState => ({
      ...prevState,
      quarter: value,
    }));
  }

  const handleQuarterRadio = (e: RadioChangeEvent) => {
    setQuarterSelectedOption(e.target.value);
  };

  const handleCycleRadio = (e: RadioChangeEvent) => {
    setCycleSelectedOption(e.target.value);
  };

  const handleVisibilitySelect = (value: string) => {
    setVisibilitySelectedOption(value);
    setOrganizationObjective({
      ...organizationObjective,
      band: value
    });
  };

  const handleOrgGroupSelect = (value: string) => {
    setOrgGroupSelectedOption(value);
    setOrganizationObjective(prevState => ({
      ...prevState,
      organizationGroup: value
    }));
  }

  const handleVisibilityRadio = (e: RadioChangeEvent) => {
    setVisibilitySelectedOption(e.target.value);
  };

  const handlehandleOrgGroupRadio = (e: RadioChangeEvent) => {
    setOrgGroupSelectedOption(e.target.value);
  }

  // const handleAssignWeightage = (e: any, index: number) => {
  //   setShowCustomWeightage((prevUserDetails) =>
  //     prevUserDetails.map((item, i) =>
  //       i === index ? { ...item, assignWeightage: e.target.value } : item
  //     )
  //   );
  //   setOrganizationObjective({
  //     ...organizationObjective,
  //     individualWeightage: "customized"
  //   });
  // };

  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { value } = e.target;

    // Find the item in the array based on its id
    const updatedCustomWeightage = showCustomWeightage.map(item => {
      if (item.id === itemId) {
        // Update the assignWeightage for the specific item
        return { ...item, assignWeightage: parseInt(value) || 0 };
      }
      return item;
    });
    setShowCustomWeightage(updatedCustomWeightage);

    setOrganizationObjective({
      ...organizationObjective,
      individualWeightage: "customized"
    });

  };

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const [individualWeightage, setIndividualWeightage] = useState<string>("");

  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (text: string, record: any) => (
        <span className="align-center">
          <img src={record.profilePath ? FileUrl + record.profilePath : "assets/images/Default_user.svg"} onError={handleImageError} alt="" className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    // {
    //   title: "Weightage Available",
    //   dataIndex: "Available",
    //   key: "Available",
    //   render: (text: string, record: any) => 
    //     <>
    //     { showObjectiveWeightage?.status === 0 ?
    //       <span>{record.avaiableWeightage < 0 ? (0) : (record.avaiableWeightage)} %</span> :
    //     <span className={record.avaiableWeightage < record.assignWeightage ? "c_FF4747" : ""}>{record.avaiableWeightage < 0 ? (0) : (record.avaiableWeightage)} %</span>
    //     }
    //     </>
    // },
    {
      title: "Weightage Assigned",
      dataIndex: "assignWeightage",
      key: "assignWeightage",
      render: (text: string, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            // type="number"
            name="assignWeightage"
            value={record.assignWeightage || ''}
            // onChange={(e) => handleAssignWeightage(e, index)}
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                handleAssignWeightage(e, record.id)
              }
            }}
            className={showObjectiveWeightage?.status === 0 ? "assigned_bg" : record.avaiableWeightage < record.assignWeightage ? "assigned_bg_error" : "assigned_bg"}
            readOnly={isCustomVisible ? (false) : (true)}
          />
          <span className="suffix">%</span>
        </div>
      ),
    },
  ];


  interface customWeightage {
    "id": string,
    "name": string,
    "username": string,
    "avaiableWeightage": number,
    "assignWeightage": number,
    "profilePath": string,
    "organizationstatus": string
  }

  interface group {
    id: string,
    groupname: string,
    assignedusers: string[],
  }

  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);

  const custom_filteredData = showCustomWeightage.filter((record: customWeightage) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
  const [Memoptions, setMemOptions] = useState<OptionItem[]>([]);
  const [MemberValues, setMemberValues] = useState<OptionItem[]>([]);
  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const [editmemberdetails, seteditedmemberdetails] = useState<string[]>([]);
  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedMemValues(selectedMemValues);
    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    userid.push(...MemberValues.map(Item => String(Item.value)));
    MemberValues.map(data => userid.push(data.value))
    // editmemberdetails.push(...userid);
    userOrganizationList(userid, organizationObjective.id, organizationObjective.individualWeightage);

  };
  const handleGrpSelectChange = (selected: OptionItems[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    const filteredEditMemberDetails = editmemberdetails.filter(val =>
      !selectedUserIds.includes(val)
    );
    seteditedmemberdetails(filteredEditMemberDetails)
    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues)
    editmemberdetails.push(...selectedUserIds);
    const selectedgrpids = selectedGrpValues.map(user => user.value)
    setOrganizationObjective({
      ...organizationObjective,
      groupid: selectedgrpids// Using another value
    });
    userOrganizationList(editmemberdetails, organizationObjective.id, organizationObjective.individualWeightage);
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };

  const handleMemTagClose = (valueToRemove: string) => {

    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );

    editmemberdetails.filter((value) =>
      value != valueToRemove
    );
    const updatedShowCustomWeightage = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    const userid: string[] = updatedShowCustomWeightage.map((user) => {
      return String(user.id)
    })
    seteditedmemberdetails(userid)
    setShowCustomWeightage(updatedShowCustomWeightage)
    setSelectedMemValues(updatedMemValues);

  };

  const handleMemTagOpen = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
  };

  // const handleGrpSelectChange = (selected: OptionItem[]) => {
  //   const selectedGrpValues = GrpOptions.filter((opt) =>
  //     selected.some((item) => item.value === opt.value)
  //   );
  //   setSelectedGrpValues(selectedGrpValues);
  // };
  // const handleGrpSelectChange = (selected: OptionItem[]) => {
  //   // Initialize an array to store user IDs
  //   let grpusers: any[] = [];

  //   // Filter out selected group values
  //   const GrpValues = GrpOptions.filter((opt) =>
  //     selected.some((item) => item.value === opt.value)
  //   );

  //   // Iterate over selected group values
  //   GrpValues.forEach((grp) => {
  //     if (grp.username) {
  //       // Map user details to new format
  //       const newUsers = grp.username.map((user) => ({
  //         label: user.name,
  //         value: user.id, 
  //       }));

  //       // Update grpusers with user IDs
  //       grpusers = grpusers.concat(grp.username.map((user) => user.id));

  //       // Set the assigned users state
  //       setAssignedUsers((prevUsers) => [...prevUsers, ...newUsers]);
  //     }
  //   });

  //   // Filter out deselected group values
  //   const deselectedGrpValues = GrpOptions.filter(
  //     (opt) => !GrpValues.some((grp) => grp.value === opt.value)
  //   );

  //   // Iterate over deselected group values
  //   deselectedGrpValues.forEach((grp) => {
  //     if (grp.username) {
  //       // Extract user IDs associated with deselected group
  //       const deselectedUsersIds = grp.username.map((user) => user.id);

  //       // Remove deselected users from assigned users state
  //       setAssignedUsers((prevUsers) =>
  //         prevUsers.filter((user) => !deselectedUsersIds.includes(user.value))
  //       );
  //     }
  //   });

  //   // Set the selected group values state
  //   setSelectedGrpValues(GrpValues);


  //   // Get user IDs from assigned users
  //   const userid: string[] = assignedusers.map((user) => user.value);

  //   // Call userOrganizationList function with group user IDs
  //   // userOrganizationList(grpusers);
  //   grpusers.forEach((grp)=>{
  //     editmemberdetails.push(grp)
  //   })
  //   userOrganizationList(editmemberdetails,organizationObjective.id,organizationObjective.individualWeightage);
  // };

  // const handleTagClose = (valueToRemove: string) => {
  //   const updatedGrpValues = selectedGrpValues.filter(
  //     (value) => value.value !== valueToRemove
  //   );
  //   setSelectedGrpValues(updatedGrpValues);
  // };
  const handleTagClose = (valueToRemove: string) => {
    // const updatedGrpValues = assignedusers.filter(
    //   (value) => value.value !== valueToRemove
    // );
    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    setAssignedUsers(updatedGrpValues);
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    const userid: string[] = updatedMemValues1.map((user) => {
      return String(user.id)
    })
    seteditedmemberdetails(userid)
    handleGrpSelectChange(updatedGrpValues)
    // setShowCustomWeightage(updatedMemValues1);
  };
  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image ? FileUrl + option.image : "assets/images/Default_user.svg"} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );



  // Memoptions.forEach((option) => {
  //   if (!option.image || option.image.trim() === "") {
  //     option.image = "assets/images/Default_user.svg";
  //   }
  // });

  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([]);


  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage hide and show

  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [isSearchMem, setisSearchMem] = useState<boolean>(false);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    setSelectedMemValues([]);
    setAssignedUsers([]);
    setSelectedGrpValues([]);
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
  };

  const { TextArea } = Input;


  // work count start


  const [organizationObjective, setOrganizationObjective] = useState({
    "id": "",
    "objectivename": "",
    "year": cycleselectedOption,
    "quarter": quarterselectedOption,
    "description": "",
    "remark": "",
    "uid": "",
    "organizationStatus": "",
    "individualWeightage": "",
    "band": VisibilityselectedOption,
    "createdByUserName": "",
    "createdByUserProfilePath": "",
    "restrictDelete": false,
    "groupid": [] as string[],
    "organizationGroup": orgGroupselectedOption,
  })

  const [objectiveText, setObjectiveText] = useState();
  const [descriptionText, setDescriptionText] = useState();
  const [remarkText, setRemarkText] = useState();

  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };
  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  })

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Organisation Objectives");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.log(error);
    }
  };

  // const handleTextChange = (
  //   event: ChangeEvent<HTMLTextAreaElement>,
  //   setText: Function,
  //   maxWords: number
  // ) => {
  //   const newText = event.target.value;
  //   const wordCount = newText.trim().split(/\s+/).join("").length;
  //   if (wordCount <= maxWords) {
  //     setText(newText);
  //   }
  // };
  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      if (Object.keys(organizationObjective).includes(event.target.name)) {
        setOrganizationObjective({
          ...organizationObjective,
          [event.target.name]: event.target.value
        });
      }
      if (event.target.name == "individualWeightage") {
        userOrganizationList(editmemberdetails, organizationObjective.id, event.target.value);
      }
    }

  };

  const addorgobjective = (text: string) => {
    if (text != "Save") {
      if (selectedMemValues.length == 0 && assignedusers.length == 0 && MemberValues.length == 0) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
              <p className="fs-18"><b>Note : </b>AssignMembers is Mandatory</p>
            </>
          ),
          centered: true,
        })
      }
      // else if (showCustomWeightage.some(user => Number.isNaN(user.assignWeightage)) || showCustomWeightage.some(user => user.assignWeightage == 0)) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      else {
        Modal.confirm({
          title: 'Do you want to ' + text + ' this objective ?',
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Objective Updated successfully.
                  </span>
                </div>
              ),
              duration: 1,
              icon: null,
            });
            text === "Save" ? updateObjectiveOrganization("1") :
              updateObjectiveOrganization("3")
            onClose();
            setTimeout(() => {
              orgObjList();
            }, 1000);
            // console.log('Cascade action confirmed');
          },
          onCancel() {
            setCasClicked(false);
            // console.log('Cascade action cancelled');
          },
        });
      }
    }
    else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
      Modal.confirm({
        title: 'Do you want to ' + text + ' this objective ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
            {greaterWeightageUser.length === 0 ? (
              <>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>
            ) : greaterWeightageUser.length === 1 ? (
              <>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
                    <div className='text-center'>
                      <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
                      <p>{greaterWeightageUser[0].value}</p>
                    </div>
                  </Col>
                  <Col xl={18} lg={18} md={18} sm={16} xs={24}>
                    <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                    <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>)}
          </>
        ),
        okText: text,
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Objective Updated successfully.
                </span>
              </div>
            ),
            duration: 1,
            icon: null,
          });

          text === "Save" ? updateObjectiveOrganization("1") :
            updateObjectiveOrganization("3")
          // saveobj()
          onClose();
          //  setTimeout(() => {
          orgObjList();
          // }, 1000);

          // console.log('Cascade action confirmed');
        },
        onCancel() {
          setClicked(false);
          // console.log('Cascade action cancelled');
        },
      });
    }
  }

  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath,
        };
      });
      setMemOptions(newOptions);
    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  // interface userStoreDetails {
  //   // id:string; //organizationbjectiveUserId
  //   empId: string;
  //   userId: string;
  // }

  interface userStoreDetails {
    assignedusername: string;
    userId: string;
    weightage: number;
  }


  interface OptionQuarter {
    value: string;
    label: string;
  }
  const [yearopt, setYearOpt] = useState<OptionQuarter[]>([]);
  const [quarteropt, setQuarterOpt] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [audittrails, setaudittrails] = useState<Audittrails[]>([]);
  const getcycleselectedOption = async () => {
    try {
      const list = await axios.get(BaseUrl + "api/organization/getCycle");
      const listdata: string[] = list.data;
      setYearOpt(
        listdata.map((quarter: string) => ({
          value: quarter,
          label: quarter,
        }))
      );
    } catch (error) {
      // Handle the error
      // console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    // userslist();
    groupuserslist();
    getOrgGroupList();
    // setOrganizationObjective(prevState => ({
    //   ...prevState,
    //   quarter: quarterselectedOption,
    // }));
    // groupuserslist();
    // // orgobjectiveslist();
    // getWordCount();
    // getcycleselectedOption();
    // assignWeightObjective();
  }, []);
  const [memberdetails, setmemberdetails] = useState<string[]>([]);
  const [customWeightageList, setCustomWeightageList] = useState<customWeightage[]>([]);

  const userOrganizationList = async (userid: string[], orgobjectiveid: string, indvidualweightage: string) => {


    try {

      const apiUrl = BaseUrl + `api/users/edit_objective_users`;

      const userObjectIdList: string[] = [];

      selectedMemValues.map((member) => {
        userObjectIdList.push(member.value);
      })

      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })

      await axios.post(apiUrl, {
        userlist: userid,
        objective: orgobjectiveid,
        obj_type: "org_obj"
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })
        .then((response) => {
          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const existingUser = showCustomWeightage.find(user => user.id === res.id);
            if (existingUser) {
              addedCustomWeightage.push(existingUser);
            } else {
              const customWeights = {
                id: res.id,
                name: res.name,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: res.assignWeightage,
                profilePath: res.profilePath,
                organizationstatus: res.objectivestatus//"assets/images/Default_user.svg"
              }
              addedCustomWeightage.push(customWeights);
            }

          })
          setShowCustomWeightage(addedCustomWeightage);
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }

  const editObjectiveOrganization = async (selectedMemValues: OptionItem[]) => {
    const apiUrl = BaseUrl + `api/organization/view/${itemId}`
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then((response) => {
      const showObjectiveOrganization = response.data.data;
      setOrganizationObjective({
        ...organizationObjective,
        "id": showObjectiveOrganization.orgObjective.id,
        "objectivename": showObjectiveOrganization.orgObjective.objectivename,
        "organizationStatus": showObjectiveOrganization.orgObjective.organizationStatus,
        "year": showObjectiveOrganization.orgObjective.year,
        "quarter": showObjectiveOrganization.orgObjective.quarter,
        "description": showObjectiveOrganization.orgObjective.description,
        "remark": showObjectiveOrganization.orgObjective.remark,
        "uid": "",
        "individualWeightage": showObjectiveOrganization.orgObjective.individualWeightage,
        "band": showObjectiveOrganization.orgObjective.band,
        "createdByUserName": showObjectiveOrganization.orgObjective.createdByUserName,
        "createdByUserProfilePath": showObjectiveOrganization.orgObjective.createdByUserProfilePath,
        "restrictDelete": showObjectiveOrganization.orgObjective.restrictDelete,
        "organizationGroup": showObjectiveOrganization.orgObjective.organizationGroup,
      });
      setVisibilitySelectedOption(showObjectiveOrganization.orgObjective.band);
      setCreatedBy(showObjectiveOrganization.orgObjective.createdBy);
      setQuarterSelectedOption(showObjectiveOrganization.orgObjective.quarter);
      setCycleSelectedOption(showObjectiveOrganization.orgObjective.year);
      setOrgGroupSelectedOption(showObjectiveOrganization.orgObjective.organizationGroup);
      const yearvalue = showObjectiveOrganization.orgObjective.year;
      const apiUrl1 = BaseUrl + `api/organization/quarters`;
      axios.post(apiUrl1, {
        "year": yearvalue,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })
        .then((response) => {
          setQuarterOpt(response.data);
        });
      if (showObjectiveOrganization.orgObjectiveUsersList !== 0) {
        setisSearchMem(true);
      }
      // handleMemSelectChange(selectedMemValues);

      const existingcustomWeightage: customWeightage[] = [];
      const existingMemberList: OptionItem[] = [];
      const userlist: string[] = [];
      showObjectiveOrganization.orgObjectiveUsersList.map((member: any) => {
        // setSelectedMemValues( prev => [
        //   ...selectedMemValues,
        //   {
        //   label: member.empId,
        //   value: member.userId,
        //   image: "",
        //   }
        // ])

        // setShowCustomWeightage(prev =>  [
        //   ...showCustomWeightage,
        const weightage = {
          "id": member.id,
          "name": member.name,
          "username": member.username,
          "avaiableWeightage": member.weightage,
          "assignWeightage": member.assignWeightage,
          "profilePath": member.profilePath,
          "organizationstatus": member.organizationStatus
        }
        // ])
        const user = {
          label: member.createdByUserName,
          value: member.userId,
          image: member.profilePath,
        }
        existingMemberList.push(user);
        existingcustomWeightage.push(weightage);
      })

      // seteditedmemberdetails(userlist);
      // after cascade user in Member and group should not removed
      // if (showObjectiveOrganization.orgObjective.organizationStatus === "1") {
      //   setSelectedMemValues(existingMemberList)

      // } else {
      //   setMemberValues(existingMemberList)

      //   existingMemberList.map((resp) => {
      //     setMemOptions(res => res.filter(notselect => notselect.value != resp.value));
      //     userlist.push(resp.value)
      //   })
      // }



      // setSelectedMemValues(existingMemberList);
      const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
      editmemberdetails.push(...userid);
      // userOrganizationList(userlist,showObjectiveOrganization.orgObjective.id);

      // show custom weightage List
      const apiUrl = BaseUrl + `api/users/edit_objective_users`;

      const userObjectIdList: string[] = [];

      selectedMemValues.map((member) => {
        userObjectIdList.push(member.value);
      })


      existingMemberList.map((member) => {
        userObjectIdList.push(member.value);
      })


      assignedusers.map((grpmembers) => {
        userObjectIdList.push(grpmembers.value)
      })

      //  const formData = new FormData();
      //  formData.append("userlist", userObjectIdList);
      //  formData.append("objective", showObjectiveOrganization.orgObjective.id )


      axios.post(apiUrl, {
        "userlist": userObjectIdList,
        "objective": showObjectiveOrganization.orgObjective.id,
        "obj_type": "org_obj"
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })
        .then((response) => {
          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const customWeights = {
              id: res.id,
              name: res.name,
              username: res.name,
              avaiableWeightage: res.avaiableWeightage,
              assignWeightage: res.assignWeightage,
              profilePath: res.profilePath,
              organizationstatus: res.objectivestatus
            }
            addedCustomWeightage.push(customWeights);
          })
          setShowCustomWeightage(addedCustomWeightage);
        })
      existingMemberList.map((resp) => {
        // setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
        userlist.push(resp.value)
      })
      if (showObjectiveOrganization.orgObjective.groupid.length > 0) {
        const assigneduserdetails1: OptionItems[] = GrpOptions.filter(option => {
          const match = showObjectiveOrganization.orgObjective.groupid.includes(option.value);
          // Log each option value and its match result
          return match;
        });
        setisSearchMem(false);
        setSelectedGrpValues(assigneduserdetails1);
        setAssignedUsers(assigneduserdetails1);
      }
      else {
        existingMemberList.forEach(data => console.log(data))
        console.log("details" + Memoptions)
        setSelectedMemValues((previousData) => {
          const newMembers =
            existingMemberList.filter((member) => Memoptions.some((memUser) => memUser.value === member.value)
            )
            ;
          return [...previousData, ...newMembers];
        });
        setMemberValues((previousData) => {
          const newMembers =
            existingMemberList.filter(
              (members) => Memoptions.every((memUser) => memUser.value !== members.value))
          return [...previousData, ...newMembers];
        });
      }
      const existingGroupList: OptionItems[] = [];
      setCycleSelectedOption(showObjectiveOrganization.orgObjective.quarter);
    })

    //userslist();


  }
  const getaudittrails = async () => {
    const datas: Boolean[] = [];
    try {
      if (orgobjectivecode != "") {
        const apiUrl = BaseUrl + `api/auditrailremarks/getaudittrails/${orgobjectivecode}`;
        await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }).then((response) => {
          response.data.data.forEach((element: any) => {
            datas.push(false);
          });
          setObjAudit(datas);
          setMemberAudit(datas);
          setaudittrails(response.data.data);
        })
      }
    } catch (error) {
      // console.log('Edit the Organization Objective', error);
      throw error;
    }
  }
  const orgObjectivesSchemacascade = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    // description: z.string().min(1, { message: 'Description field is required' }), 
    // individualWeightage: z.string().min(1, { message: 'Individual weightage field is required' }),
    quarter: z.string().min(1, { message: 'Year field is required' }),
    // orgGroupselectedOption: z.string().min(1, { message: 'Required'}),
    // band: z.string().min(1, { message: 'band field is required' }),
  });
  const orgobjectivesave = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
  });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [customweightagedetails, setCustomWeightageDetails] = useState<customWeightage[]>([]);

  let weightagePopupCount = 0;
  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < showCustomWeightage.length; index++) {
      if (showCustomWeightage[index].avaiableWeightage < showCustomWeightage[index].assignWeightage) {
        const popupUser = {
          value: showCustomWeightage[index].username,
          label: showCustomWeightage[index].name,
          image: showCustomWeightage[index].profilePath !== null ? showCustomWeightage[index].profilePath : "",
        }
        weightagePopupCount++;
        greaterWeightageUser.push(popupUser);
        // setShowErrorMessage(weightagePopupCount);
      }
    }
    return greaterWeightageUser;
  }

  type showCustomWeightageModel = {
    modelid: string,
    modelname: string,
    createdAt: string,
    status: number,
  }
  const [showObjectiveWeightage, setShowObjectiveWeightage] = useState<showCustomWeightageModel>();

  const assignWeightObjective = async () => {
    try {
      const apiUrl = BaseUrl + `api/objectives/weightagemasterobjective/${1}`;
      axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setShowObjectiveWeightage(response.data);
      })
    } catch (error) {

    }

  }

  const [showAlertWeightage, setShowAlertWeightage] = useState<boolean>(true);

  const handleSubmit = (status: string) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }
    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = orgObjectivesSchemacascade.safeParse(organizationObjective);
    const validationsave = orgobjectivesave.safeParse(organizationObjective);
    if (status == "3") {
      if (validationResult.success) {
        // Data is valid, proceed with your logic
        setErrors(null);

        // display the pop up weightage less than available 
        const greaterWeightageUser: OptionItem[] = userAssignedWeightage();


        if (showObjectiveWeightage?.status === 0) {
          if (weightagePopupCount === 0 || showAlertWeightage === false) {
            addorgobjective("Cascade")
          } else {
            setShowAlertWeightage(false);
            weightagePopupCount === 1 ?
              handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, organizationObjective.objectivename, "Objective") :
              handleproceed(greaterWeightageUser, organizationObjective.objectivename, "Objective");
          }
        } else {
          if (weightagePopupCount === 0) {
            addorgobjective("Cascade")
          } else {
            weightagePopupCount === 1 ?
              handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, organizationObjective.objectivename, "Objective") :
              handleproceed(greaterWeightageUser, organizationObjective.objectivename, "Objective");
          }
        }


      } else {
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic
        setErrors(null);
        addorgobjective("Save")
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };

  // get the list of the grou
  const groupuserslist = async () => {
    try {
      const groupslist = await axios.get(BaseUrl + "api/group/users_group",
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      const groupusersdatas: group[] = groupslist.data;
      const newoptions = groupusersdatas.map((id) => ({
        value: id.id,
        label: id.groupname,
        image: "",
        weightage: 0,
        username: id.assignedusers.map((userString: any) => {
          return {
            name: userString.name,
            id: userString.userid,
          };
        }), // Assuming you have an image property for each user
      }));
      setGrpOptions(newoptions);
    } catch (error) {

    }

  }



  // update the objective Oraganization
  const updateObjectiveOrganization = async (organizationStatus: string) => {

    const apiUrl = BaseUrl + `api/organization/update/${itemId}`

    const selectedMemberList: userStoreDetails[] = [];

    // selectedMemValues.map((member) => {
    //   const user = {
    //     empId: member.label,
    //     userId: member.value,
    //   }
    //   selectedMemberList.push(user);
    // })

    if (organizationStatus == "1") {
      showCustomWeightage.map((member) => {
        const user = {
          userId: member.id,
          assignedusername: member.name,
          weightage: member.assignWeightage,
          organizationStatus: "1",
        }
        selectedMemberList.push(user);
      })
    }
    else {
      showCustomWeightage.map((member) => {
        const user = {
          userId: member.id,
          assignedusername: member.name,
          weightage: member.assignWeightage,
          organizationStatus: "3"
        }
        selectedMemberList.push(user);
      })
    }


    if (selectedMemValues.length !== 0) {
      setisSearchMem(true);
    } else {
      setisSearchMem(false);
    }

    await axios.put(apiUrl, {
      "orgObjective": organizationObjective,
      "orgObjectiveUsersList": selectedMemberList,
      "saveOrCascade": organizationStatus
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {

      const showObjectiveOrganization = response.data.data;
      setOrganizationObjective({
        ...organizationObjective,
        "id": showObjectiveOrganization.orgObjective.id,
        "objectivename": showObjectiveOrganization.orgObjective.objectivename,
        "organizationStatus": showObjectiveOrganization.orgObjective.organizationStatus,
        "quarter": showObjectiveOrganization.orgObjective.quarter,
        "description": showObjectiveOrganization.orgObjective.description,
        "remark": showObjectiveOrganization.orgObjective.remark,
        "uid": "",
        "individualWeightage": showObjectiveOrganization.orgObjective.individualWeightage,
        "year": showObjectiveOrganization.orgObjective.year,
        "restrictDelete": showObjectiveOrganization.orgObjective.restrictDelete,
      })
      // setOrganizationObjective(showObjectiveOrganization.orgObjective);
      const existingcustomWeightage: customWeightage[] = [];
      const existingMemberList: OptionItem[] = [];
      showObjectiveOrganization.orgObjectiveUsersList.map((member: any) => {

        const weightage = {
          "id": member.id,
          "name": member.name,
          "username": member.username,
          "avaiableWeightage": member.weightage,
          "assignWeightage": 0 ? member.assignWeightage : showObjectiveOrganization.orgObjective.individualWeightage,
          "profilePath": member.profilePath,
          "organizationstatus": member.objectivestatus
        }
        const user = {
          label: member.createdByUserName,
          value: member.userId,
          image: member.profilePath,
        }
        existingMemberList.push(user);
        existingcustomWeightage.push(weightage);
      })


      // existingMemberList.filter((resp) => 
      //     Memoptions.some((memOpt : OptionItem) => memOpt.value !== resp.value)
      //     );
      // setMemOptions(Memoptions);

      // MemberValues.map((unremove: OptionItem) => {
      //   handleMemTagClose(unremove.label + '*');
      // })
      setMemberValues(existingMemberList);
      orgObjList();
      //window.location.reload();
    })
  }

  type getallassignedlist = {
    id: String,
    name: String,
    uid: number,
    year: String,
    enddate: String,
    quarter: String,
    description: String,
    remark: String,
    organizationStatus: String,
    createdBy: String,
    createdByEmpId: String,
    createdAt: String,
    updatedAt: String | null,
    startDate: String | null,
    objectiveName: String
  }

  const [deleteNextLevelObjective, setDeleteNextLevelObjective] = useState();
  const [allorgobjectivelist, setobjectivelist] = useState<getallassignedlist[]>([]);
  const orgobjectiveslist = async () => {
    try {
      const objectivelist = await axios.get(BaseUrl + "api/organization/all");
      setobjectivelist(objectivelist.data)
    } catch (error) {

    }

  }



  function editAndShowDraw() {
    editObjectiveOrganization(selectedMemValues);
    getaudittrails();
    showDrawer();
    getBandList();
    setTextClicked(true);
    userslist();
  }

  function customWeightAgeMemberGroup() {
    toggleCustomVisibility();
    // userOrganizationList();
  }

  // function closeDrawerAndEditModel() {
  //   onClose();
  //   updateObjectiveOrganization();
  // }

  // work count end

  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>
  );


  const [readOnly, setReadOnly] = useState(true);
  const handleClickEdit = () => {
    setReadOnly(false); // remove readonly
    // editkeyresults()
  };
  const krdec_cancel = () => {
    setReadOnly(true);
  };
  //declare const localStorage: Storage;
  const items: MenuProps["items"] = [
    {
      label: (
        <>

          <Row onClick={handleClickEdit} className="p-0">
            <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
            Edit
          </Row>

        </>
      ),
      key: "0",
    },
    // organizationObjective.organizationStatus === "1" ?
    organizationObjective.restrictDelete === false ?
      {
        label: (
          <Row
            className="c_FF4747 fs-14 p-0"
            // onClick={() => handleDelete(keyResultsValuesedit.keytitle)}
            onClick={() => handleDeleteObject(organizationObjective.objectivename)}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      }
      : null,
  ];

  const handleDeleteObject = (kr: any) => {
    Modal.confirm({
      title: "Do you want to delete this key result ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr}
            {/* “ Achieve a 20% reduction in average server response time. ” */}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteOrganizationObjective();
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
      },
      onCancel() {
        console.log("Delete action cancelled");
      },
    });
  };


  const deleteOrganizationObjective = async () => {

    const apiUrl = BaseUrl + `api/organization/delete/${itemId}`;

    await axios.delete(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`

      }
    }).then((response) => {
      setDeleteNextLevelObjective(response.data.status);

      // window.location.reload();
      //bhCreateOrganization()
    })
  }

  // Custom Weightage and View Weightage  hide and show
  const [ViewWeightage, setViewWeightage] = useState(false);

  const toggleViewWeightage = () => {
    // alert(1)
    setViewWeightage(!ViewWeightage);
  };
  const ViewWeightageBack = () => {
    setViewWeightage(false);
  }
  const handlesubmitcustomweightage = (status: string) => {
    // // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }

    // // Disable the button
    setIsWeightageButton(true);
    if (organizationObjective.objectivename == "" || organizationObjective.quarter == "") {
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              Organization Objective Name Is Mandatory
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      // toggleCustomVisibility()
    }
    else {
      Modal.confirm({
        title: 'Do you want to save the weightage this objective ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{organizationObjective.objectivename}</p>
            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          </>
        ),
        okText: 'Save',
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Weightage Saved successfully.
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });
          toggleCustomVisibility();
        },
        onCancel() {
          setCasClicked(false);
          console.log('Cascade action cancelled');
        },
      });
    }
    setTimeout(() => {
      // Enable the button
      setIsWeightageButton(false);
    }, 2000);
  }
  const [textClicked, setTextClicked] = useState(false);

  return (
    <div className="">
      <Row onClick={editAndShowDraw} className={`p-0 fs-14 ${textClicked ? 'c_32B7D9' : ''}`}>
        {/* <button className="border-0 bg-transparent fs-14 align-center p-0" onClick={editAndShowDraw}> */}
        {objectiveName === "" ?
          (
            <>
              <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
              Edit
            </>
          ) :
          <p className={`p-0 fs-16 threeline_text_overflow  ${textClicked ? 'clickText_active' : 'clickText'}`}  >
            {objectiveName}
          </p>
        }
      </Row>
      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Weightage
            </div>
          ) : (
            <>
              <div className="justify-between">
                {
                  readOnly && objectiveName !== "" ? (orgobjectivecode) : ("Edit Organization Objective")
                }
                <div className="mr-5 cur-pointer">
                  {readOnly && objectiveName !== "" && (localStorage.getItem("loginId") === createdBy) ? (
                    <>
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            <img src="assets/images/menu.svg" alt="" />
                          </Space>
                        </a>
                      </Dropdown>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )
        }
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={open}
        className="objective-canvas  position-relative"
      >
        <div>
          {readOnly && objectiveName !== "" ?
            (
              <>
                {ViewWeightage ? (
                  <>
                    <div className="cw_table">
                      {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}

                      {/* <p className="fs-16 c_32B7D9 fs-14">Product Members (18) 0</p> */}
                      <Row justify={"space-between"} className="mb-2">
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="position-relative"
                        >
                          <input
                            type="search"
                            placeholder="Search Member"
                            className="w-100 table_search_inputbox pl-2"
                            value={searchTextValue}
                            // onSearch={handleSearch}
                            onChange={(e) => setSearchTextValue(e.target.value)}
                          />
                          <img
                            src="assets/images/search.svg"
                            className="table_search_img"
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Table
                        dataSource={custom_filteredData}
                        columns={columns}
                        scroll={{ x: true }}
                        pagination={{ pageSize: 10 }}
                      />
                      <button className="cancel-btn float-right" onClick={ViewWeightageBack}>
                        Back
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="obj_tab">
                      <Tabs defaultActiveKey="1" onChange={onChange} className="p-0"  >
                        <TabPane tab="General" key="1" className="p-0">
                          <div>
                            <div className="mb-3">
                              <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                Objective Name
                              </label>{" "}
                              <br />
                              {/* <p className="fs-16">{objname}</p> */}
                              <p className="fs-16">{organizationObjective.objectivename}</p>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                Objective Description
                              </label>{" "}
                              <br />
                              <p className="fs-16">
                                {organizationObjective.description}
                              </p>
                            </div>
                            <div className="mb-3">
                              <Row>
                                <Col xl={8} lg={8} md={8} sm={12} xs={24} className="mt-1">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Cycle
                                  </label>
                                  {/* <p className="fs-16">{keyResultsValues.quarter} 2024 - 2025 </p> */}
                                  <p className="fs-16">{organizationObjective.quarter}   {organizationObjective.year}</p>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={12} xs={24} className="mt-1">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Created By
                                  </label>
                                  <div className="align-center mt-1">
                                    <img
                                      src={organizationObjective.createdByUserProfilePath ? FileUrl + organizationObjective.createdByUserProfilePath : "assets/images/Default_user.svg"}
                                      onError={handleImageError}
                                      alt=""
                                      className="user"
                                    />
                                    <span className="pl-1">{organizationObjective.createdByUserName}</span>
                                  </div>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={12} xs={24} className="mt-1">
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Visibility Upto
                                  </label>
                                  <p className="fs-14">{VisibilityselectedOption}</p>
                                </Col>

                              </Row>
                            </div>
                            <div className="mb-3">
                              <div className="justify-between mb-1">
                                {assignedusers.length > 0 ? (
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Assign Groups
                                  </label>
                                ) : (
                                  <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                    Assign Members
                                  </label>
                                )
                                }
                                <span
                                  className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                                  onClick={toggleViewWeightage}
                                >
                                  View Weightage <ArrowRightOutlined />
                                </span>
                              </div>
                              {assignedusers.length > 0 ? (
                                assignedusers.map(option => (
                                  <span className="">
                                    <span className="am_view">
                                      <img
                                        src={option.image}
                                        onError={handleImageError}
                                        className="select_user"
                                        style={{ position: "relative", top: "5px" }}
                                        alt=""
                                      />
                                      <span className="pl-1">{option.label}</span>
                                    </span>
                                  </span>
                                )
                                )
                              ) :
                                (
                                  selectedMemValues.map(option => (
                                    <span className="pl-1">
                                      <span className="align-center am_view">
                                        <img
                                          src={option.image ? FileUrl + option.image : "assets/images/Default_user.svg"}
                                          onError={handleImageError}
                                          className="select_user"
                                          style={{ position: "relative", top: "5px" }}
                                          alt=""
                                        />
                                        <span className="pl-1">{option.label}</span>
                                      </span>
                                    </span>
                                  )
                                  )
                                )
                              }
                              {selectedMemValues.length == 0 && assignedusers.length == 0 &&
                                <p> Assigness Not Available</p>
                              }
                            </div>
                            <div className="mb-3">
                              <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                                Remarks
                              </label>{" "}
                              <br />
                              <p className="fs-16">
                                {organizationObjective.remark}
                              </p>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tab="Audit Trail" key="2" className="p-0">
                          <div>
                            <div className="mb-3 border_bottom">
                              <label htmlFor="" className="fw-600 fs-14">
                                Orgobjective Name
                              </label>{" "}
                              <br />
                              {/* <p className="fs-16">{objname}</p> */}
                              <p className="fs-16">{organizationObjective.objectivename}</p>
                            </div>
                            {/* <div className="border_bottom">
                                  <div className="align-center">
                                    <img src="assets/images/connect_b.svg" alt="" /><span className="pl-1 c_32B7D9 text_line fs-14">Connect</span>
                                  </div>
                                </div> */}
                            <div>
                              {audittrails.map((audits, index) => (
                                <div>
                                  <ul className="Obj_list">
                                    {audits.status == 0 && (
                                      <li>
                                        <div>
                                          <div className="d-flex align-start">
                                            <div className="icon"></div>
                                            <div>
                                              <p className="fs-14 fw-700">Orgobjective {audits.remark} </p>
                                              <p className="fs-12">{audits.createdat}</p>
                                            </div>
                                          </div>

                                        </div>
                                      </li>
                                    )
                                    }
                                    {audits.status == 2 && (
                                      <li className="mt-1">
                                        <div className="d-flex justify-between align-start">
                                          <div className="d-flex align-start">
                                            <div className="icon"></div>
                                            <div>
                                              <p className="fs-14 fw-700">{audits.modelname == "Objectivename" ? "Organization Objective Name Edited" : "Organization " + audits.modelname + " Edited"}   </p>
                                              <p className="fs-12">{audits.createdat}</p>
                                              {
                                                objAudit[index] &&
                                                (
                                                  <div>
                                                    <label htmlFor="" className="c_29ABE2 fs-12">New name</label>
                                                    <p className="fs-16 m-0">{audits.newdata}</p>
                                                    <label htmlFor="" className="c_aaa fs-12">Old name</label>
                                                    <p className="fs-16 m-0">{audits.olddata}</p>
                                                  </div>
                                                )
                                              }
                                            </div>
                                          </div>
                                          <div>
                                            {
                                              objAudit[index] ? (
                                                <UpOutlined onClick={() => handleObjAudit(index)} style={{ width: "10px" }} />
                                              ) : (
                                                <DownOutlined onClick={() => handleObjAudit(index)} style={{ width: "10px" }} />
                                              )
                                            }
                                          </div>
                                        </div>


                                      </li>
                                    )}
                                    {audits.status == 1 && (
                                      <li className="mt-1">
                                        <div>
                                          <div className="d-flex justify-between align-start">
                                            <div className="d-flex align-start">
                                              <div className="icon"></div>
                                              <div>
                                                <p className="fs-14 fw-700">{audits.modelname} </p>
                                                <p className="fs-12">{audits.createdat}</p>
                                              </div>
                                            </div>

                                            <div>
                                              {
                                                memberAudit[index] ? (
                                                  <UpOutlined onClick={() => handleMebAudit(index)} style={{ width: "10px" }} />
                                                ) : (
                                                  <DownOutlined onClick={() => handleMebAudit(index)} style={{ width: "10px" }} />
                                                )
                                              }
                                            </div>
                                          </div>
                                          {
                                            memberAudit[index] && (
                                              <div className="objective_overall d-flex">
                                                {/* <span className="every_am_view mr-1 cur-pointer"><span className="pl-1">Everyone</span></span> */}
                                                {/* <span className="connect_view  mr-1 cur-pointer p-2"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun </span><span className="error_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan </span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Thomsan</span><span className="success_cricle ml-1"></span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">priya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Riya</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">Arjun</span></span>
                                          <span className="connect_view  mr-1 cur-pointer"><img src="assets/images/Default_user.svg" className="select_user" alt="" /><span className="pl-1 nowrap">DIvya Priya</span></span> */}
                                                {JSON.parse(audits.newdata).map((user: any, idx: number) => (
                                                  <span key={idx} className="connect_view mr-1 cur-pointer p-2">
                                                    <img src={user.profilpath || "assets/images/Default_user.svg"} className="select_user" alt="" />
                                                    <span className="pl-1 nowrap">{user.username}</span>
                                                  </span>
                                                ))}
                                              </div>
                                            )
                                          }

                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              ))
                              }
                            </div>
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>

                  </>
                )}

              </>
            )
            :
            (
              <>
                <Form className="mb-5">
                  <div>
                    {isCustomVisible ? (
                      <div className="cw_table">
                        <Row justify={"space-between"} className="mb-2">
                          {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}
                          <Col xl={6} lg={5} md={6} sm={6} xs={24} className="mb-2">
                            {/* <span
                          className="c_32B7D9 fs-12 cur-pointer"
                          onClick={toggleCustomVisibility}
                        >
                          <ArrowLeftOutlined /> Custom Weightage
                        </span> */}
                          </Col>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="position-relative"
                          >
                            <input
                              type="search"
                              placeholder="Search Member"
                              className="w-100 table_search_inputbox pl-2"
                              value={searchTextValue}
                              // onSearch={handleSearch}
                              onChange={(e) => setSearchTextValue(e.target.value)}
                            />
                            <img
                              src="assets/images/search.svg"
                              className="table_search_img"
                              alt=""
                            />
                          </Col>
                        </Row>
                        <Table
                          dataSource={custom_filteredData}
                          columns={columns}
                          scroll={{ x: true }}
                          pagination={{ pageSize: 10 }}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="title-section mb-3">
                          <div className="justify-between">
                            <label htmlFor="objective" className="fw-600">
                              Objective Name <sup className="c_32B7D9">*</sup>
                            </label>
                            <span className="c-0050 fs-12 mt-1">
                              {organizationObjective.objectivename.trim().split(/\s+/).join("").length} /{" "}
                              {maxWords.name_max_word_count}
                            </span>
                          </div>
                          <div className="objective-title-inner">
                            <TextArea
                              id="objective"
                              rows={3}
                              placeholder="Write here...."
                              className="canvas-objective-input"
                              value={organizationObjective.objectivename}
                              // disabled={organizationObjective.organizationStatus === "2"}
                              onChange={(e) =>
                                handleTextChange(
                                  e,
                                  setObjectiveText,
                                  maxWords.name_max_word_count
                                )
                              }
                              // onChange={(e) =>
                              //   handleTextChange(
                              //     e,
                              //     setOrganizationObjective({
                              //       ...organizationObjective,
                              //       objectiveName : e.target.value
                              //     }),
                              //   maxWords.objective
                              // )
                              // }
                              name="objectivename"
                            />
                            {errors && errors.find(error => error.path[0] === 'objectivename') && (
                              <p className="danger">{errors.find(error => error.path[0] === 'objectivename')?.message}</p>
                            )}
                          </div>
                        </div>
                        <div className="desc-section mb-3">
                          <div className="justify-between">
                            <label htmlFor="" className="fw-600">
                              Description (Optional)
                            </label>
                            <span className="c-0050 fs-12 mt-1">
                              {organizationObjective.description.trim().split(/\s+/).join("").length} /{" "}
                              {maxWords.description_max_word_count}
                            </span>
                          </div>
                          <div className="objective-title-inner">
                            <TextArea
                              rows={3}
                              placeholder="Write here...."
                              className="canvas-objective-input"
                              value={organizationObjective.description}
                              // disabled= {objectiveName !== ""}
                              onChange={(e) =>
                                handleTextChange(
                                  e,
                                  setObjectiveText,
                                  maxWords.description_max_word_count
                                )
                              }
                              name="description"
                            />
                            {errors && errors.find(error => error.path[0] === 'description') && (
                              <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Row className="my_obj">
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <label htmlFor="" className="fw-600">
                                Year
                                <sup className="c_32B7D9">*</sup>
                              </label>
                              <br />
                              <Select
                                className="custom-select mt-1"
                                style={{ width: 200 }}
                                placeholder="Select an option"
                                onChange={handleCycleSelect}
                                value={organizationObjective.year}
                              // disabled={organizationObjective.organizationStatus === "2"}
                              >
                                {yearopt.map((option) => (
                                  <Option value={option.value.split(' ')[0]}>
                                    <Radio
                                      value={option.value.split(' ')[0]}
                                      checked={cycleselectedOption === option.value.split(' ')[0]}
                                      onChange={handleCycleRadio}
                                    >
                                      {option.label}
                                    </Radio>
                                  </Option>
                                ))}
                              </Select>
                              {errors && errors.find(error => error.path[0] === 'individualWeightage') && (
                                <p className="danger">{errors.find(error => error.path[0] === 'individualWeightage')?.message}</p>
                              )}
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <label htmlFor="" className="fw-600">
                                Quarter <sup className="c_32B7D9">*</sup>
                              </label>
                              <br />
                              <Select
                                className="custom-select mt-1"
                                style={{ width: 200 }}
                                placeholder="Select an option"
                                onChange={handleQuaterSelect}
                                value={organizationObjective.quarter}
                              >
                                {quarteropt.map((option) => (
                                  <Option value={option}>
                                    <Radio
                                      value={option}
                                      checked={quarterselectedOption === option}
                                      onChange={handleQuarterRadio}
                                    >
                                      {option}
                                    </Radio>
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                          </Row>
                          {/* {errors && errors.find(error => error.path[0] === 'orgGroupselectedOption') && (
                              <p className="danger">{errors.find(error => error.path[0] === 'orgGroupselectedOption')?.message}</p>
                            )} */}
                        </div>
                        <div className="mb-3">
                          <Row className="my_obj">
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <label htmlFor="" className="fw-600 mt-3">
                                Visibility Upto<sup className="c_32B7D9">*</sup>
                              </label>
                              <br />
                              <Select
                                className="custom-select mt-1"
                                style={{ width: 200 }}
                                placeholder="Select an option"
                                onChange={handleVisibilitySelect}
                                value={VisibilityselectedOption}
                              >
                                <Option value="Only Assigned">
                                  <Radio
                                    value="Only Assigned"
                                    checked={
                                      VisibilityselectedOption === "Only Assigned"
                                    }
                                    onChange={handleVisibilityRadio}
                                  >
                                    Only Assigned
                                  </Radio>
                                </Option>
                                {bandList.map((option) => (
                                  option.band !== null && (
                                    <>
                                      <Option value={option.band}>
                                        <Radio
                                          value={option.band}
                                          checked={VisibilityselectedOption === option.band}
                                          onChange={handleVisibilityRadio}
                                        >
                                          Upto {option.band}
                                        </Radio>
                                      </Option>
                                    </>
                                  )
                                ))}
                              </Select>
                              {errors && errors.find(error => error.path[0] === 'band') && (
                                <p className="danger">{errors.find(error => error.path[0] === 'band')?.message}</p>
                              )}
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <label htmlFor="" className="fw-600 mt-3">
                                Organisation Groups
                              </label>
                              <br />
                              <Select
                                className="custom-select mt-1"
                                style={{ width: 200 }}
                                placeholder="Select an option"
                                onChange={handleOrgGroupSelect}
                                value={orgGroupselectedOption}
                              >
                                {orgGroupList.map((option) => (
                                  option.fieldName !== null && (
                                    <Option value={option.id}>
                                      <Radio
                                        value={option.id}
                                        checked={orgGroupselectedOption === option.id}
                                        onChange={handlehandleOrgGroupRadio}
                                      >
                                        {option.fieldName}
                                      </Radio>
                                    </Option>
                                  )
                                ))}
                              </Select>
                            </Col>
                          </Row>
                          {errors && errors.find(error => error.path[0] === 'year') && (
                            <p className="danger">{errors.find(error => error.path[0] === 'year')?.message}</p>
                          )}
                        </div>
                        <div className="assign-section mb-3">
                          <label htmlFor="" className="fw-600">
                            Assign Members<sup className="c_32B7D9">*</sup>
                          </label>
                          {Memoptions.length == 0 &&
                            <span className="danger ml-3">Assignees Not Available</span>
                          }
                          <span
                            className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                            onClick={handleSearchMember}
                          >
                            {isSearchMem ? "Search Groups" : "Search Members"}
                          </span>
                          <br />
                          <div className="mt-1">
                            {isSearchMem ? (
                              <Select
                                mode="multiple"
                                // disabled={objectiveName !== ""||Memoptions.length==0}
                                placeholder={
                                  <div
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <img
                                      src="assets/images/member.svg"
                                      alt="Placeholder"
                                      style={{ marginRight: "8px" }}
                                    />
                                    <span>Select Member</span>
                                  </div>
                                }
                                onChange={handleMemSelectChange}
                                value={selectedMemValues}
                                className="w-100 form-control"
                                labelInValue
                                optionLabelProp="label"
                                // disabled ={organizationObjective.organizationStatus === "2"}
                                dropdownRender={(menu) => <div>{menu}</div>}
                                notFoundContent={<CustomEmptyMemberText />}
                                // options={Memoptions}
                                // filterOption={(input, option) =>
                                //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                // }
                                filterOption={(input, option) =>
                                  option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                              >
                                {Memoptions.map((option) => (
                                  <Option
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}

                                  >
                                    {renderOption(option)}
                                  </Option>
                                ))}
                              </Select>
                            ) : (
                              <Select
                                // disabled={objectiveName !== ""||Memoptions.length==0}
                                // disabled={Memoptions.length==0}
                                mode="multiple"
                                placeholder={
                                  <div
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <img
                                      src="assets/images/people.svg"
                                      alt="Placeholder"
                                      style={{ marginRight: "8px" }}
                                    />
                                    <span>Select Group</span>
                                  </div>
                                }
                                onChange={handleGrpSelectChange}
                                value={selectedGrpValues}
                                className="w-100 form-control"
                                labelInValue
                                // disabled ={organizationObjective.organizationStatus === "2"}
                                optionLabelProp="label"
                                dropdownRender={(menu) => <div>{menu}</div>}
                                notFoundContent={<CustomEmptyGroupText />}
                                // options={GrpOptions}
                                // filterOption={(input, option) =>
                                //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                // }
                                filterOption={(input, option) =>
                                  option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                              >
                                {GrpOptions.map((option) => (
                                  <Option
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                  >
                                    {renderOption(option)}
                                  </Option>
                                ))}
                              </Select>
                            )}
                            <div
                              className={
                                selectedMemValues.length > 0
                                  ? "assgin_selected_val"
                                  : "" || selectedGrpValues.length > 0
                                    ? "assgin_selected_val"
                                    : "" || MemberValues.length > 0
                                      ? "assgin_selected_val"
                                      : ""
                              }
                            >
                              {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}
                              {/* {isSearchMem &&  selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({MemberValues.length +selectedMemValues.length})</p></Row> : '' } */}
                              <div className="d-flex flex-wrap">
                                {MemberValues.map((value) => (
                                  <Tag
                                    key={value.value}
                                    // closable
                                    // onClose={() => handleMemTagClose(value.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={value.image ? FileUrl + value.image : "assets/images/Default_user.svg"}
                                      alt=""
                                      onError={handleImageError}
                                      //  alt={value.label}
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {value.label}
                                  </Tag>
                                ))}
                                {selectedMemValues.map((value) => (
                                  <Tag
                                    key={value.value}
                                    closable
                                    onClose={() => handleMemTagClose(value.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={value.image ? FileUrl + value.image : "assets/images/Default_user.svg"}
                                      onError={handleImageError}
                                      alt=""
                                      // alt={value.label}
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {value.label}
                                  </Tag>
                                ))}
                                {assignedusers.map((value) => (
                                  <Tag
                                    key={value.value}
                                    closable
                                    onClose={() => handleTagClose(value.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src="assets/images/Default_user.svg"
                                      alt=""
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {value.label}
                                  </Tag>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Row className={isCustomVisible ? "d-none" : ""}>
                            {/* <Col xl={19} lg={19} md={18} sm={12} xs={24}> */}
                            {/* <Row className="align-center"> */}
                            {/* <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                                  <label htmlFor="" className="fw-600">
                                    Individual Weightage <sup className="c_32B7D9">*</sup>
                                  </label>
                                  <Input
                                    placeholder=""
                                    className="canvas-objective-input mt-1"
                                    // disabled= {objectiveName !== ""}
                                    value={organizationObjective.individualWeightage}
                                    // onChange={(e) => setOrganizationObjective({
                                    //   ...organizationObjective,
                                    //   individualWeightage: e.target.value
                                    // })}
                                    onChange={(e) => {
                                      const inputVal = e.target.value;
                                      const numericRegex = /^[0-9]*$/;
                                      if (inputVal === '' ||inputVal === 'customized'|| (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                        // setOrganizationObjective({
                                        //   ...organizationObjective,
                                        //   individualWeightage: e.target.value
                                        // });
                                        handleTextChange(e,
                                          setDescriptionText,
                                          maxWords.description_max_word_count
                                        )
                                      }
                                    }}
                                    suffix="%"
                                    name="individualWeightage"
                                  />
                                  {errors && errors.find(error => error.path[0] === 'individualWeightage') && (
                                    <p className="danger">{errors.find(error => error.path[0] === 'individualWeightage')?.message}</p>
                                  )}
                                </Col>
                                <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                                  <p className="pl-2 pt-2"  >
                                    i.e, Weightage only be a Percentage
                                  </p>
                                </Col> */}
                            {/* </Row> */}
                            {/* </Col> */}
                            {/* <Col xl={5} lg={5} md={6} sm={12} xs={24}> */}
                            <span
                              className="c_32B7D9 fs-14 cur-pointer text_line float-right"
                              onClick={customWeightAgeMemberGroup}

                            >
                              Custom Weightage <ArrowRightOutlined />
                            </span>
                            {/* </Col> */}
                          </Row>
                        </div>

                        <div className="remark-section mb-3">
                          <div className="justify-between">
                            <label className="canvas-objective-title">Remarks (Optional)</label>
                            <span className="c-0050 fs-12 mt-1">
                              {organizationObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                              {maxWords.remark_max_word_count}
                            </span>
                          </div>
                          <div className="objective-title-inner">
                            <TextArea
                              rows={3}
                              placeholder=""
                              className="canvas-objective-input w-100"
                              value={organizationObjective.remark}
                              // disabled={objectiveName !== ""}
                              onChange={(e) =>
                                handleTextChange(e,
                                  setDescriptionText,
                                  maxWords.remark_max_word_count
                                )
                              }
                              name="remark"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="Submit-section">
                      {
                        // objectiveName !== "" ? (
                        isCustomVisible ? (
                          <>
                            <button
                              className="cancel-btn"
                              onClick={toggleCustomVisibility}
                            >
                              Back
                            </button>
                            <button
                              // className="cyan_button"
                              onClick={() => { handlesubmitcustomweightage("1") }}
                              disabled={isWeightageButton}
                              className={
                                selectedMemValues.length === 0
                                  ? "cascade_button"
                                  : "cyan_button"
                              }>Submit</button>
                            {/* <button className="cyan_button">submit</button> */}
                          </>
                        ) : (
                          // objectiveName === "" ? ( 
                          <>
                            {organizationObjective.organizationStatus != "3" &&
                              <button className="save-btn" onClick={() => { handleSubmit("1") }}
                                disabled={isButtonDisabled}
                              // disabled={clicked}
                              >
                                Save
                              </button>
                            }
                            {organizationObjective.organizationStatus == "3" &&
                              <button className="cancel-btn" onClick={onClose}>Cancel</button>
                            }

                            <button
                              className={"cyan_button"}
                              onClick={() => { handleSubmit("3") }}
                              disabled={isButtonDisabled}
                            // disabled={casclicked}
                            >
                              {organizationObjective.organizationStatus == "1" ? "Cascade" : "Update"}
                            </button>

                          </>

                        )
                        // ) :   (<></>) 
                      }
                    </div>
                  </div>
                </Form>
              </>
            )}
        </div>

      </Drawer>
    </div>
  );
};

export default EditObj;
