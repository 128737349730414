import React from 'react';
import { useEffect,useState } from "react";
import { Tabs } from 'antd';
import axios from "axios";
import SignOff from './SignOff';
import overallCheck from "../../../overallCheck";
import { BaseUrl, FileUrl } from "../../../App";
import Appraisal from '../Appraisal/Appraisal';

const { TabPane } = Tabs;

const SignOffTab: React.FC = () => {
 // const [activeKey, setActiveKey] = useState<string>(() => localStorage.getItem('activeTab') || '1');
  const [activeKey, setActiveKey] = useState<string>('1');
  
  const onChange = (key: string) => {
    // console.log('Tab Changed to:', key);
    setActiveKey(key);
    localStorage.setItem('activeTab', key);
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    RefreshToken();
  }, []);
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)  
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  
  return (
  <div>

    <Tabs  activeKey={activeKey} onChange={onChange} className='objective-tab'>
      {/* <TabPane tab="Sign-off" key="1">
        <div className="non-absolute ">
          <SignOff />
        </div>
      </TabPane> */}
   
      
      <TabPane tab="Appraisal" key="1">
        <div className="non-absolute">
          <Appraisal />
        </div>
      </TabPane>

     
    </Tabs>

  </div>)

  };

export default SignOffTab;
