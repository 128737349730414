import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Radio,
  Modal,
  message,
  Checkbox,
  Tabs
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { on } from "events";
import MyObjective from "../Objective/SubObjectiveTab/MyObjective";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
import { get } from "http";
import ChildCreateObj from "./ChildCreateObj";
const { Option } = Select;
const { TabPane } = Tabs;


interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: number;
  ImageUrl: string;
}
interface RolePermission {
  id: string;
  role: string;
  parent_objective: boolean;
  key_result: boolean;
  child_objective: boolean;
  org_objective: boolean;
  lead_measures: boolean;
  menu: string;
  userId: string;
  assigned_members: boolean;
  group: boolean;
}
// type responsetypes{

// }
interface OptionItem {
  value: string;
  label: string;
  image: string;
  band: number;
}
type parentobjectives = {
  objectivelist: () => void;
  objectname: String,
  objectcode: String,
  orgQuartervalue: string,
  orgobjid: string,
  objid: string,
  header: string,
  button: string,
  year: string,
  startdate:string,
  enddate:string,
  objective_id: string | null,
  orgdescription: string,
  "objectivetype": string
}
interface group {
  id: string,
  groupname: string,
  assignedusers: string[],
  createdBy:string
}
interface customWeightage {
  "id": string,
  "name": string,
  "username": string,
  "avaiableWeightage": number,
  "assignWeightage": number,
  "cftstatus": number,
  "profilePath": string,
  "objectivestatus": string,

}

const AssignObj: React.FC<parentobjectives> = ({ objectivelist, objectcode, objectname, orgQuartervalue, orgobjid, objid, button, header, year, objective_id, orgdescription, objectivetype ,startdate,enddate}) => {
  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [cycleselectedOption, setCycleSelectedOption] = useState<string | null>(
    null
  );
  const [VisibilityselectedOption, setVisibilitySelectedOption] = useState<
    string | null
  >(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWeightageButton, setIsWeightageButton] = useState(false);

  // =================Roles And Permission==============================
  const [permission_parentobjview, setPermission_parentobjview] = useState('');
  const [permission_childobjcreate, setPermission_childobjcreate] = useState('');

  // =================Roles And Permission==============================

  const [ViewWeightage, setViewWeightage] = useState(false);

  const toggleViewWeightage = () => {
    // alert(1)
    setViewWeightage(!ViewWeightage);
  };
  const ViewWeightageBack = () => {
    setViewWeightage(false);
  }


  const handleCycleSelect = (value: string) => {
    setCycleSelectedOption(value);
  };

  const handleCycleRadio = (e: RadioChangeEvent) => {
    setCycleSelectedOption(e.target.value);
  };
  const [assignuserweightage, setassigneduserweightage] = useState<UserDetails[]>([]);
  const handleVisibilitySelect = (value: string) => {
    setVisibilitySelectedOption(value);
  };

  const handleVisibilityRadio = (e: RadioChangeEvent) => {
    setVisibilitySelectedOption(e.target.value);
  };
  const modifiedData: UserDetails[] = [];

  // const handleAssignWeightage = (e: any, index: number) => {
  //   setassigneduserweightage((prevUserDetails) =>
  //     assignuserweightage.map((item, i) =>
  //       i === index ? { ...item, assignWeightage: e.target.value } : item
  //     )
  //   );
  //   setObjectivevalue({
  //     ...objectivevalue,
  //     individualweightage: "customized"
  // });

  // };

  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { value } = e.target;
    // Find the item in the array based on its id
    const updatedCustomWeightage = showCustomWeightage.map(item => {
      if (item.id === itemId) {
        // Update the assignWeightage for the specific item
        return { ...item, assignWeightage: parseInt(value) || 0 };
      }
      return item;
    });
    setShowCustomWeightage(updatedCustomWeightage);
    setObjectivevalue({
      ...objectivevalue,
      individualweightage: "customized"
    });
  };

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "Name",
      render: (text: string, record: any) => (
        <span className="align-center">
          <img src={record.profilePath ? FileUrl + record.profilePath : "assets/images/Default_user.svg"} alt="" onError={handleImageError} className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    // {
    //   title: "Weightage Available",
    //   dataIndex: "avaiableWeightage",
    //   key: "Available",
    //   render: (text: string, record: any) => <span className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "c_FF4747" : ""}>{record.avaiableWeightage < 0 ? (0) : record.avaiableWeightage}%</span>,
    // },
    {
      title: "Assigned",
      dataIndex: "assignWeightage",
      key: "assignWeightage",
      render: (text: string, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            // type="number" 
            name="assignWeightage"
            value={record.assignWeightage || ''}
            // onChange={(e) => {handleAssignWeightage(e, index)}}
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                { handleAssignWeightage(e, record.id) }
              }
            }}
            disabled = {Memoptions.every(user => user.value !== record.id)} 
            className="assigned_bg" 
            // className={parseInt(record.avaiableWeightage) < record.assignWeightage ? "assigned_bg_error" : "assigned_bg"}
          />
          <span className="suffix">%</span>
        </div>
        // <span>{record.assignWeightage}%</span>
      ),
    },
  ];


  const custom_filteredData = assignuserweightage.filter((record: UserDetails) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );
  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string, band: number }[] = response.data;
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH' ? 'FH:' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? FileUrl + id.profilePath : "assets/images/Default_user.png",
          band: id.band
        };
      });
      setMemOptions(newOptions);
      if (objectivetype == "orgobjective" && orgobjband != null) {
        const bandfilter = newOptions.filter(memoptions => memoptions.band <= orgobjband);
        setMemOptions(bandfilter);
      }


    } catch (error) {
    }
  };

  // show some Json Object error
  const groupuserslist = async () => {
    const groupslist = await axios.get(BaseUrl + "api/group/users_group",
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
    const groupusersdatas: group[] = groupslist.data;
    const newoptions = groupusersdatas.map((id) => ({
      value: id.id,
      label: id.groupname,
      image: "",
      weightage: 0,
      username: id.assignedusers.map((userString: any) => {
        return {
          name: userString.name,
          id: userString.userid,
        };
      }), 
      createdBy:id.createdBy// Assuming you have an image property for each user
    }));
    setGrpOptions(newoptions);
  }
  const groupusersall = async () => {
    const groupslist = await axios.get(BaseUrl + "api/group/group",
      {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
    const groupusersdatas: group[] = groupslist.data;
    const newoptions = groupusersdatas.map((id) => ({
      value: id.id,
      label: id.groupname,
      image: "",
      weightage: 0,
      username: id.assignedusers.map((userString: any) => {
        return {
          name: userString.name,
          id: userString.userid,
        };
      }),
      createdBy:id.createdBy // Assuming you have an image property for each user
    }));
    setGrpallOptions(newoptions);
  }

  // useEffect(() => {

  //   groupuserslist();
  // }, []);
  interface UserDetails {
    "id": string | null;
    "name": string;
    "username": string;
    "avaiableWeightage": number;
    "index": string | null;
    "assignWeightage": number;
    "profilePath": string;
    // Add other properties as needed
  }

  interface objectiveusers {
    empId: String;
    userId: String;
    orgobjectiveid: String;
  }
  interface OptionItems {
    value: string;
    label: string;
    image: string;
    username: { name: any; id: any }[];
    createdBy:string

  }

  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [mydetails, setmydetails] = useState<OptionItem>();
  const [orgobjband, setorgband] = useState<number>(0);
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";
  const handlecheckbox = () => {
    const checkboxElement = document.getElementById('includeMyselfCheckboxObj') as HTMLInputElement;
    if (checkboxElement) {
      const includeMyself = checkboxElement.checked;
      setCheckboxChecked(true);
      if (includeMyself) {
        const localStorageOption: OptionItem = {
          value: localStorage.loginId,
          label: localStorage.name,
          image: profilePhoto,
          band: 0
        };
        const userid: string[] = [];

        selectedMemValues.forEach((user) => {
          userid.push(user.value)
        })

        assignedusers.forEach((user) => {
          userid.push(user.value)
        })
        userid.push(localStorageOption.value)
        setmydetails(localStorageOption);
        selectedmemdetails(userid);

      } else {
        setmydetails(undefined)
        handleTagClose(localStorage.loginId)
        handleMemTagClose(localStorage.loginId);
      }
    }
  }
  const [editmemberdetails, seteditedmemberdetails] = useState<string[]>([]);
  const userdetails_1: UserDetails[] = [];
  const selectedmemdetails = async (userid: string[]) => {
    const userObjectIdList: string[] = [];
    selectedMemValues.map((member) => {
      userObjectIdList.push(member.value);
    })
    assignedusers.map((grpmembers) => {
      userObjectIdList.push(grpmembers.value)
      if (checkboxChecked === true) {
        userObjectIdList.push(localStorage.loginId);
      }
    })

    try {
      const userdetails = await axios.post(BaseUrl + "api/users/selected_users", userid,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      userdetails_1.push(userdetails.data);

      // const modifiedData:UserDetails[] = 
      userdetails.data.map((item: UserDetails) => {
        const existingUser = assignuserweightage.find(user => user.id === item.id);
        if (existingUser) {
          modifiedData.push(existingUser);
        } else {
          const displayWeightage = {
            // ...item,
            id: item.id,
            name: "",
            username: item.name,
            avaiableWeightage: item.avaiableWeightage,
            index: "",
            assignWeightage: parseInt(objectivevalue.individualweightage),
            profilePath: item.profilePath//"assets/images/user.svg"
          }

          modifiedData.push(displayWeightage);
        }
      });
      setassigneduserweightage(modifiedData);
    }
    catch (e) {
    }
  }
  var localStorageOptionsss: OptionItem;
  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    // if (checkboxCheckededit) {
    //   localStorageOptionsss = {
    //     value: localStorage.loginId,
    //     label: localStorage.name,
    //     image: profilePhoto
    //   };
    // }
    setmydetails(localStorageOptionsss)
    const userid: string[] = selectedMemValues.map((item) => String(item.value));
    userid.push(...editmemberdetails)
    // if(parentObjective.organizationStatus!='1'){
    //   userid.push(...editmemberdetails)
    // }
    // if (mydetails) {
    //   userid.push(mydetails.value);
    // }
    // if (localStorageOptionsss != undefined) {
    //   userid.push(localStorageOptionsss.value)
    // }
    userOrganizationList(userid, parentObjective.individualweightage);
    // assigneduserslist(editmemberdetails)
    setSelectedMemValues(selectedMemValues);

  };

  const handleTagClose = (valueToRemove: string) => {
    // if(mydetails?.value==valueToRemove){
    //   const checkboxElement = document.getElementById('includeMyselfCheckbox') as HTMLInputElement;
    //   checkboxElement.removeAttribute('checked');
    //   setmydetails(undefined)
    //   setCheckboxChecked(false);
    // }    


    MemberValues.filter(
      (value) => value.value !== valueToRemove
    );


    // MemberValues.map((user) => {
    //   // return user.value !== localStorage.loginId;+

    //   if (user.value === localStorage.getItem('loginId')) {
    //     setCheckboxCheckededit(false);
    //   }
    // })
    // setSelectedMemValues(MemberValues);
    // handleMemTagClose(valueToRemove);

    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    setAssignedUsers(updatedGrpValues);
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    const userid: string[] = updatedMemValues1.map((user) => {
      return String(user.id)
    })
    seteditedmemberdetails(userid)
    handleGrpSelectChange(updatedGrpValues)
    // setShowCustomWeightage(updatedMemValues1);
  };

  const handleMemTagClose = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
    const updatedMemValues1 = showCustomWeightage.filter(
      (value) => value.id !== valueToRemove
    );
    setShowCustomWeightage(updatedMemValues1);

  };

  const handleFHusersTagClose = (valueToRemove: string) => {
    const updatedMemValues = fhuserslists.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedFhusers(updatedMemValues);
    const updatedMemValues1 = assignuserweightage.filter(
      (value) => value.id !== valueToRemove
    );
  };

  const handleGrpSelectChange = (selected: OptionItems[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    // const filteredEditMemberDetails = editmemberdetails.filter(val =>
    //   !selectedUserIds.includes(val)
    // );
    if (mydetails != undefined) {
      selectedUserIds.push(mydetails?.value)
    }

    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues);
    const selectedgrpids = selectedGrpValues.map(user => user.value)
    setObjectivevalue({
      ...objectivevalue,
      groupid: selectedgrpids// Using another value
    });

    selectedmemdetails(selectedUserIds);
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };

  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image ? FileUrl + option.image : "assets/images/Default_user.svg"} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );
  const renderOptiongrp = (option: OptionItems) => (
    <div>
      <img className="member_user" src={option.image ? FileUrl + option.image : "assets/images/Default_user.svg"} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );
  const [Memoptions, setMemOptions] = useState<OptionItem[]>([
  ]);


  const assignedwightageusers = (e: any, index: any) => {
    setassigneduserweightage((prevUserDetails) =>
      assignuserweightage.map((item, i) =>
        i === index ? { ...item, assigned: e.target.value } : item
      )
    );
  };
  // Memoptions.forEach((option) => {
  //   if (!option.image || option.image.trim() === "") {
  //     option.image = "assets/images/Default_user.svg";
  //   }
  // });
  
  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([]);
  const [GrpallOptions, setGrpallOptions] = useState<OptionItems[]>([]);
  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage hide and show

  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [isSearchMem, setisSearchMem] = useState(true);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    if (isSearchMem == false) {
      setAssignedUsers([]);
      setassigneduserweightage([]);
    }
    else {
      setSelectedMemValues([]);
      setassigneduserweightage([]);
      setSelectedGrpValues([]);
      setCheckboxChecked(false);
      setmydetails(undefined);
    }
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
    // selectedmemdetails();
  };

  const rolename = localStorage.getItem("rolename");
  let viewButton = true;
  if (rolename === "BH") {
    viewButton = false;
  }

  const [open, setOpen] = useState(false);
  const { TextArea } = Input;

  const showDrawer = () => {
    setOpen(true);
    if (objectivetype == "orgobjective") {
      editorgobjective()
    }
    else {
      editParentObjective();
    }
    userslist();
  };

  const onClose = () => {
    // console.log("assign objectivee");
    
    setOpen(false);
    //window.location.reload()
    setObjectivevalue({
      objectivename: "",
      description: "",
      remark: "",
      orgobjectiveid: orgobjid,
      parentid: objid,
      quarter: cycleselectedOption,
      assignedmembers: selectedMemValues,
      individualweightage: "",
      status: "",
      objectiveid: objective_id,
      groupid: []
    });
    setMemberValues([]);
    setSelectedMemValues([]);
    setSelectedGrpValues([]);
    setAssignedUsers([]);
    setSelectedFhusers([]);
    setErrors(null);
    //window.location.reload();
  };
  // work count start
  const [objectiveText, setObjectiveText] = useState();
  const [descriptionText, setDescriptionText] = useState();
  const [remarkText, setRemarkText] = useState();

  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };

  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  });
  const [tabClicked, setTabClicked] = useState(false);
  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Objective");
      setMaxWords(maxWords.data);
    } catch (error) {
    }
  }

  const [objectivevalue, setObjectivevalue] = useState({
    objectivename: "",
    description: "",
    remark: "",
    orgobjectiveid: orgobjid,
    parentid: objid,
    quarter: cycleselectedOption,
    assignedmembers: selectedMemValues,
    individualweightage: "",
    status: "",
    objectiveid: objective_id,
    groupid: [] as string[]
  });



  const GetUserAccess = async () => {

    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission) as RolePermission[];
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "Create") {
          setPermission_childobjcreate(permission.child_objective)
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
    }
  };
  const [MemberValues, setMemberValues] = useState<OptionItem[]>([]);
  useEffect(() => {
    // GetUserAccess();
    setObjectivevalue(prevState => ({
      ...prevState,
      quarter: cycleselectedOption,
    }));
    // handleCycleSelect(orgQuartervalue);
    // fhuserslist();
    // userslist();
    // groupuserslist();
    // getWordCount();
    // groupusersall();
    // if(objectivetype=="objective"){
    //   
    // }


  }, [cycleselectedOption]);

  useEffect(()=>{
    handleCycleSelect(orgQuartervalue);
    GetUserAccess();
    // userslist();
    groupuserslist();
    groupusersall();
  },[])

  useEffect(() => {
    if (tabClicked) {
      fhuserslist();
      getWordCount();
    }
  }, [tabClicked]);
 
  // const[tabclickedcasecade,setTabClickedcasecade]=useState(false);
  // useEffect(() => {
  //   if (tabclickedcasecade) {
  //     userslist();
  //     groupusersall();
  //     groupuserslist();
  //   }
  // }, [tabclickedcasecade]);



  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      if (objectivetype == "orgobjective") {
        if (Object.keys(orgobjectiveedit).includes(event.target.name)) {
          setorgobjectiveedit({
            ...orgobjectiveedit,
            [event.target.name]: event.target.value
          });
        }
      }
      else {
        if (Object.keys(parentObjective).includes(event.target.name)) {
          setParentObjective({
            ...parentObjective,
            [event.target.name]: event.target.value
          });
        }
      }

      // if (event.target.name == "individualweightage")
      //   userOrganizationList(editmemberdetails, event.target.value);
    }

  };

  const [fhuserslists, setSelectedFhusers] = useState<OptionItem[]>([]);
  const [fhuseroption, setfhusersoption] = useState<OptionItem[]>([]);
  // fhuseroption.forEach((option) => {
  //   if (!option.image || option.image.trim() === "") {
  //     option.image = "assets/images/Default_user.svg";
  //   }
  // });
  const handleMemFHChange = (selected: OptionItem[]) => {
    const fhmemvalues = fhuseroption.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );

    const membersdetails: string[] = []
    fhmemvalues.forEach((values) => {
      membersdetails.push(values.value)
    })
    setSelectedFhusers(fhmemvalues);
    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    // selectedmemdetails(userid);
  };
  const fhuserslist = async () => {
    try {
      const fhusers = await axios.get(BaseUrl + "api/users/FHuserslist", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });

      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string, band: number }[] = fhusers.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        return {
          value: id.id,
          label: id.rolename + ":" + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath, // Assuming you have an image property for each user
          weightage: 0,
          band: id.band
        };
      });
      setfhusersoption(newOptions);
    } catch (error) {
    }

  }
  const addorgobjective = (text: string, submit: boolean) => {
    const usersweightage = assignuserweightage.filter(user => user.id != localStorage.loginId)
    if (text != "Save") {
      if (fhuserslists.length == 0 && selectedMemValues.length == 0 && assignedusers.length == 0 && mydetails == undefined) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
              <p className="fs-18"><b>Note : </b>AssignMembers Or CFT is Mandatory</p>
            </>
          ),
          centered: true,
        })
      }
      // else if (usersweightage.some(user => Number.isNaN(user.assignWeightage))||usersweightage.some(user => user.assignWeightage==0)){
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      else {
        // if(usersweightage.some(user => Number.isNaN(user.assignWeightage))||usersweightage.some(user => user.assignWeightage==0)){
        //   Modal.error({
        //     content: (
        //       <>
        //         <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
        //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
        //       </>
        //     ),
        //     centered: true,
        //   })
        // }
        // if{
        Modal.confirm({
          title: 'Do you want to ' + text + ' this objective ?',
          content: (
            <>
              <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Objective Created successfully.
                  </span>
                </div>
              ),
              duration: 1,
              icon: null,
            });
            onClose();
            text === "Save" ? saveobj("1") :
              saveobj("2")
            // saveobj()
            setTimeout(() => {
              // window.location.reload();
              objectivelist();
            }, 1000);

          },
          onCancel() {
            setCasClicked(false);
          },
        });
      }
      // }
    }
    else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
      if (usersweightage.some(user => user.avaiableWeightage == 0)) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
              <p className="fs-18"><b>Note : </b>User Weightage Is Zero Please Check.</p>
            </>
          ),
          centered: true,
        })
      }
      else {
        Modal.confirm({
          title: 'Do you want to ' + text + ' this objective ?',
          content: (
            <>
              <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
              {greaterWeightageUser.length === 0 ? (
                <>
                  <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                </>
              ) : greaterWeightageUser.length === 1 ? (
                <>
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
                      <div className='text-center'>
                        <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
                        <p>{greaterWeightageUser[0].value}</p>
                      </div>
                    </Col>
                    <Col xl={18} lg={18} md={18} sm={16} xs={24}>
                      <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                      <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
                  <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
                </>)}
            </>
          ),
          okText: text,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Objective Created successfully.
                  </span>
                </div>
              ),
              duration: 1,
              icon: null,
            });

            text === "Save" ? saveobj("1") :
              saveobj("2")
            // saveobj()
            // window.location.reload();
            if (submit == false) {
              onClose();
              setTimeout(() => {
                objectivelist();
                // window.location.reload();
              }, 1000);
            }
            else {
              toggleCustomVisibility()
            }

          },
          onCancel() {
            setClicked(false);
          },
        });
      }
    }
  }
  // interface userStoreDetails {
  //   empId: String | null;
  //   userId: string | null;
  //   avaiableWeightage: number,
  //   weightage: string | null,
  //   index: String,
  //   ImageUrl: String
  //   // weightage: number;
  // }

  interface userStoreDetails {
    assignedusername: string;
    userId: string;
    weightage: number;
  }
  interface userStoreDetailssave {
    empId: string;
    userId: string | null;
    // weightage: number;
  }
  interface orgobjectiveedit {
    id: string,
    name: string,
    objectivename: string,
    uid: 0,
    year: string,
    startdate: string,
    enddate: string,
    quarter: string,
    description: string,
    remark: string,
    organizationStatus: string,
    createdBy: string,
    createdByEmpId: string,
    createdAt: string,
    updatedAt: string,
    individualWeightage: string,
    band: string,
    createdByUserName: string,
    createdByUserProfilePath: string,
    objCount: 0,
    objDetails: [],
  }



  const [objectiveusers, Setobjectiveuserlist] = useState<objectiveusers[]>([])
  const selectedMemberListsave: userStoreDetailssave[] = [];
  const selectedGroupList: objectiveusers[] = [];
  const ObjectivesSchemacascade = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
    // individualweightage: z.string().min(1, { message: 'Individual weightage field is required' }),
  });
  const objectivesave = z.object({
    objectivename: z.string().min(1, { message: 'Objective name field is required' }),
  });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [customweightagedetails, setCustomWeightageDetails] = useState<UserDetails[]>([]);
  const [orgobjectiveedit, setorgobjectiveedit] = useState({
    id: "",
    name: "",
    objectivename: "",
    uid: 0,
    year: "",
    startdate: "",
    enddate: "",
    quarter: "",
    description: "",
    remark: "",
    organizationStatus: "",
    createdBy: "",
    createdByEmpId: "",
    createdAt: "",
    updatedAt: "",
    individualWeightage: "",
    band: "",
    createdByUserName: "",
    createdByUserProfilePath: "",
    objCount: 0,
    objDetails: [],
    assigneeremarks: ""
  });
  const [parentObjective, setParentObjective] = useState({
    parentobjective: "",
    objectivename: "",
    description: "",
    cycleselectionoption: cycleselectedOption,
    individualweightage: "",
    orgObjId: "",
    objectiveid: "",
    revertStatus: 0,
    remark: "",
    status: "",
    organizationStatus: "",
    year: "",
    createdby: "",
    createdByEmpName: "",
    createdByEmpProfilePath: "",
    restrictDelete: false,
    groupid: [] as string[],
    assignee_remarks_value: ""
  })
  const [showCustomWeightage, setShowCustomWeightage] = useState<customWeightage[]>([]);


  let weightagePopupCount = 0;
  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < assignuserweightage.length; index++) {
      // if (parseInt(objectivevalue.individualweightage) > assignuserweightage[index].avaiableWeightage) {
      //   count++;
      // }
      if (assignuserweightage[index].avaiableWeightage < assignuserweightage[index].assignWeightage) {
        const popupUser = {
          value: assignuserweightage[index].username,
          label: assignuserweightage[index].name,
          image: assignuserweightage[index].profilePath !== null ? assignuserweightage[index].profilePath : "",
          band: 0
        }
        greaterWeightageUser.push(popupUser);
        weightagePopupCount++;
      }
    }
    return greaterWeightageUser;
  }
  const updateParentObjective = async (objectiveStatus: string) => {

    try {
      const apiUrl = BaseUrl + `api/objectives/update/${objective_id}`;


      const selectedMemberList: userStoreDetails[] = [];

      // fhuserslists.map((group, index) => {
      //   const fhusers = {
      //     assignedusername: group.label,
      //     userId: group.value,
      //     weightage: 0,
      //     cftstatus: 1,
      //     // empId : group.
      //   }
      //   selectedMemberList.push(fhusers);
      // })

      showCustomWeightage.map((member) => {
        const user = {
          userId: member.id,
          assignedusername: member.name,
          weightage: member.assignWeightage,
          cftstatus: member.cftstatus,
          objectiveStatus: member.objectivestatus
        }
        selectedMemberList.push(user);
      })
      if (selectedMemValues.length !== 0) {
        setisSearchMem(true);
      } else {
        setisSearchMem(false);
      }
      const objective = {
        ...parentObjective,
        createdByEmpName: null // Or null, depending on how you want to handle it
      };
      await axios.put(apiUrl, {
        objectivesdetails: objective,
        objectivesuserlist: selectedMemberList,
        saveOrCascade: parentObjective.organizationStatus
      },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      ).then((response) => {
        if (response.status == 200) {
          onClose();
          objectivelist();
        }

        const viewParentObjective = response.data.data;
        const viewOrgObjName = response.data.organizationObjective;
        setParentObjective({
          ...parentObjective,
          "parentobjective": viewOrgObjName,
          "objectivename": viewParentObjective.objectivesdetails.objectivename,
          "description": viewParentObjective.objectivesdetails.description,
          "individualweightage": viewParentObjective.objectivesdetails.individualweightage,
          "remark": viewParentObjective.objectivesdetails.remark,
          "status": viewParentObjective.objectivesdetails.organizationStatus,
          "organizationStatus": viewParentObjective.objectivesdetails.organizationStatus,
          "year": viewParentObjective.objectivesdetails.year,
          "createdby": viewParentObjective.objectivesdetails.createdBy,
          "restrictDelete": viewParentObjective.objectivesdetails.restrictDelete
        })

        if (viewParentObjective.objectivesuserlist !== 0) {
          setisSearchMem(true);
        }

        // setOrganizationName(response.data.organizationObjective);
        // setOrganizationDescription(response.data.organizationDescription);
        setCycleSelectedOption(viewParentObjective.objectivesdetails.quarter + ' ' + viewParentObjective.objectivesdetails.year);
        const userlist: string[] = [];
        var existingMemberList: OptionItem[] = [];
        const fhuserslist: OptionItem[] = [];
        const assignedforme: OptionItem[] = [];
        var user: any;
        var user1: any;
        var userme: any;
        viewParentObjective.objectivesuserlist.map((member: any) => {
          if (member.cftstatus == 0) {
            user = {
              label:
                "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? FileUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            user1 = ""
          }
          else if (member.cftstatus == 1) {

            user1 = {
              label: "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            user = "";
          }

          if (user !== "") {
            existingMemberList.push(user);
          }

          if (user1 !== "") {
            fhuserslist.push(user1);
          }
        });


        // seteditedmemberdetails(userlist);
        // setSelectedMemValues(existingMemberList);
        // window.location.reload();
      })
    } catch (error) {
    }
  }

  const handleSubmit = (status: string, submit: boolean) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }
    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = ObjectivesSchemacascade.safeParse(objectivevalue);
    const validationsave = objectivesave.safeParse(objectivevalue);
    if (status == "2") {
      if (validationResult.success) {
        addorgobjective("Cascade", submit);
        // // Data is valid, proceed with your logic

        // setErrors(null);

        // // display the pop up weightage less than available 
        // const greaterWeightageUser: OptionItem[] = userAssignedWeightage();

        // if (weightagePopupCount === 0) {
        //   addorgobjective("Cascade",submit)
        // } 
        // else {
        //   weightagePopupCount === 1 ?
        //     handleDelete(greaterWeightageUser[0]?.label, greaterWeightageUser[0]?.image, objectivevalue.objectivename, "Objective") :
        //     handleproceed(greaterWeightageUser, objectivevalue.objectivename, "Objective");
        // }
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        addorgobjective("Save", submit)
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };
  const handleSubmitupdate = (status: string, submit: boolean) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }
    // Disable the button
    setIsButtonDisabled(true);

    // Validate data against schema
    const validationResult = ObjectivesSchemacascade.safeParse(objectivevalue);
    const validationsave = objectivesave.safeParse(objectivevalue);
    if (status == "2") {
      if (validationResult.success) {
        // Data is valid, proceed with your logic

        setErrors(null);

        // display the pop up weightage less than available 
        const greaterWeightageUser: OptionItem[] = userAssignedWeightage();

        if (weightagePopupCount === 0) {
          addorgobjective("Cascade", submit)
        }
        else {
          weightagePopupCount === 1 ?
            handleDelete(greaterWeightageUser[0]?.label, greaterWeightageUser[0]?.image, objectivevalue.objectivename, "Objective") :
            handleproceed(greaterWeightageUser, objectivevalue.objectivename, "Objective");
        }
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        updateParentObjective("Save")
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };

  const saveobj = async (organizationStatus: string) => {

    // selectedMemValues.map((member) => {                  
    //   const user = {
    //     empId: member.label,
    //     userId: member.value,
    //     orgobjectiveid:orgobjid,
    //   }
    //   selectedMemberList.push(user);
    // })
    selectedGrpValues.map((group) => {
      const user1 = {
        empId: group.label,
        userId: group.value,
        orgobjectiveid: orgobjid,
      }
      selectedGroupList.push(user1);
    })

    fhuserslists.map((group, index) => {

      const fhusers = {
        empId: group.label,
        userId: group.value,
        weightage: 0,
        cftstatus: 1
      }

      selectedMemberListsave.push(fhusers);
    })

    assignuserweightage.map((member) => {
      const user = {
        empId: member.username,
        userId: member.id,
        avaiableWeightage: member.avaiableWeightage,
        weightage: member.assignWeightage,
        index: "",
        ImageUrl: ""
      }

      selectedMemberListsave.push(user);
    })




    Setobjectiveuserlist(selectedGroupList);
    try {
      await axios.post(BaseUrl + "api/objectives/createObjective", {
        "objectivesdetails": objectivevalue,
        "objectivesuserlist": selectedMemberListsave,
        "saveOrCascade": organizationStatus,
      },
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`
            // Replace 'yourAuthToken' with the actual authentication token
          }
        }
      ).then((response) => {
        onClose();
        // <MyObjective />
        // window.location.reload();
      })
    } catch (error) {

    }

  }
  let objectivebutton = "";
  let objectiveheader = "";
  if (localStorage.rolename === "FH") {
    objectivebutton = "+ Create Parent Objective"
    objectiveheader = "Create Parent Objective"
  }
  else if (localStorage.rolname === "BH") {
    objectivebutton = "+ create Parent Objective"
    objectiveheader = "Create Parent Objective"
  }
  else if (localStorage.rolename === "TM") {
    objectivebutton = "+ Create Child Objective"
    objectiveheader = "Create Child Objective"
  }

  function closeDrawerAndUserList() {
    toggleCustomVisibility();
  }

  // word count end
  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>
  );
  const userOrganizationList = async (userid: string[], individualweightage: string) => {
    try {

      const apiUrl = BaseUrl + `api/users/edit_objective_users`;
      const userObjectIdList: string[] = [];
      selectedMemValues.map((member) => {
        userObjectIdList.push(member.value);
      })


      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      var objtype = "obj"
      var id = objective_id;
      if (objectivetype == "orgobjective") {
        objtype = "org_obj";
        id = orgobjid;
      }


      const updatedata = {
        userlist: userid,
        objective: id,
        obj_type: objtype
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {

          const addedCustomWeightage: customWeightage[] = [];
          response.data.map((res: any) => {
            const existingUser = showCustomWeightage.find(user => user.id === res.id);
            if (existingUser) {
              addedCustomWeightage.push(existingUser);
            } else {
              const customWeights = {
                id: res.id,
                name: res.name,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: parseInt(res.assignWeightage),
                cftstatus: parseInt(res.cftstatus),
                profilePath: res.profilePath,
                objectivestatus: res.objectivestatus
              }
              addedCustomWeightage.push(customWeights);
            }

          })
          setShowCustomWeightage(addedCustomWeightage);

        })
    } catch (error) {
    }
  }

  const editParentObjective = async () => {
    try {

      const apiUrl = BaseUrl + `api/objectives/view/${objid}`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {

        const viewParentObjective = response.data.data;
        const viewOrgObjName = response.data.organizationObjective;
        setParentObjective({
          ...parentObjective,
          "parentobjective": viewOrgObjName,
          "objectivename": viewParentObjective.objectivesdetails.objectivename,
          "description": viewParentObjective.objectivesdetails.description,
          "individualweightage": viewParentObjective.objectivesdetails.individualweightage,
          "orgObjId": viewParentObjective.objectivesdetails.orgobjectiveid,
          "objectiveid": viewParentObjective.objectivesdetails.objectiveid,
          "revertStatus": viewParentObjective.objectivesdetails.revokestatus,
          "remark": viewParentObjective.objectivesdetails.remark,
          "status": viewParentObjective.objectivesdetails.organizationStatus,
          "organizationStatus": viewParentObjective.objectivesdetails.organizationStatus,
          "year": viewParentObjective.objectivesdetails.year,
          "createdby": viewParentObjective.objectivesdetails.createdBy,
          "createdByEmpName": viewParentObjective.objectivesdetails.createdByEmpName,
          "createdByEmpProfilePath": viewParentObjective.objectivesdetails.createdByEmpProfilePath,
          "restrictDelete": viewParentObjective.objectivesdetails.restrictDelete,
          "assignee_remarks_value": viewParentObjective.objectivesdetails.assignee_remarks_value
        })
        if (viewParentObjective.objectivesuserlist !== 0) {
          setisSearchMem(true);
        }

        // setOrganizationName(response.data.organizationObjective);
        // setOrganizationDescription(response.data.organizationDescription);
        setCycleSelectedOption(viewParentObjective.objectivesdetails.quarter + ' ' + viewParentObjective.objectivesdetails.year);
        const userlist: string[] = [];
        var existingMemberList: OptionItem[] = [];
        const fhuserslist: OptionItem[] = [];
        const assignedforme: OptionItem[] = [];
        var user: any;
        var user1: any;
        var userme: any;
        viewParentObjective.objectivesuserlist.map((member: any) => {

          if (member.cftstatus == 0) {
            user = {
              label:
                "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? FileUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
              cftstatus: member.cftstatus,
              objectivestatus: member.objectivestatus
            }
            user1 = ""
          }
          else if (member.cftstatus == 1) {

            user1 = {
              label: "(" + member.rolename + ")" + " " + member.assignedusername + " " + "-" + " " + "(" + member.departmentname + ")" + " " + "-" + " " + "(" + member.mail + ")",
              value: member.userId,
              image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
              cftstatus: member.cftstatus,
              objectivestatus: member.objectivestatus
            }
            user = "";
          }
          if (member.userId == localStorage.loginId) {
            userme = {
              label: member.assignedusername,
              value: member.userId,
              image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
              cftstatus: member.cftstatus
            }
            assignedforme.push(userme)
            setCheckboxChecked(true)
          }
          else {
            setCheckboxChecked(checkboxChecked);
          }

          if (user !== "") {
            existingMemberList.push(user);
          }

          if (user1 !== "") {
            fhuserslist.push(user1);
            existingMemberList.push(user1);
          }
        });

        // after cascading user in the member and group should not removed.
        // if (viewParentObjective.objectivesdetails.organizationStatus === "1") {
        //  setSelectedMemValues(existingMemberList);
        //   existingMemberList.map((user) => {
        //     userlist.push(user.value)
        //     // return user.value !== localStorage.loginId;+
        //     if (user.value === localStorage.getItem('loginId')) {
        //       setCheckboxChecked(true);
        //     }           
        //   })
        // } else {
        //   setMemberValues(existingMemberList);
        //   existingMemberList.map((resp) => {
        //     setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
        //     userlist.push(resp.value)
        //   })

        //   existingMemberList.map((user) => {
        //     if (user.value === localStorage.getItem('loginId')) {
        //       setCheckboxChecked(true);
        //     }
        //   })
        // }
        existingMemberList.map((resp) => {
          userlist.push(resp.value)
          editmemberdetails.push(resp.value);
          if (resp.value === localStorage.getItem('loginId')) {
            setCheckboxChecked(true);
          }
        })
        const notassignees: OptionItem[] = [];
        const assigneee: OptionItem[] = [];
        if (viewParentObjective.objectivesdetails.groupid.length > 0) {
          const assigneduserdetails1: OptionItems[] = GrpallOptions.filter(option => {
            // const match = viewParentObjective.objectivesdetails.groupid.every((grp:string) => grp == option.value);
            const match = viewParentObjective.objectivesdetails.groupid.includes(option.value);
            // Log each option value and its match result
            return match;
          });
          setisSearchMem(false);
          
          setSelectedGrpValues(assigneduserdetails1)
          setAssignedUsers(assigneduserdetails1)
        }
        else {
          const newMembers = existingMemberList.filter(
            (member) => member.value === viewParentObjective.objectivesdetails.createdBy
          );
          const withoutloginid = existingMemberList.filter(
            (member) => member.value !== viewParentObjective.objectivesdetails.createdBy
          );
          const assignedforme = existingMemberList.filter((members) => Memoptions.some((memUser) => memUser.value !== members.value))
          setSelectedMemValues((previousData) => {
            const newMembers = [
              // ...existingMemberList.filter(
              //   (member) => member.value === viewParentObjective.objectivesdetails.createdBy
              // ),
              ...existingMemberList.filter(
                (member) =>
                  // member.value !== viewParentObjective.objectivesdetails.createdBy &&
                  Memoptions.some((memUser) => memUser.value === member.value)
              ),
            ];
            return [...previousData, ...newMembers];
          });
          setMemberValues((previousData) => {
            const newMembers =
              existingMemberList.filter(
                (members) => Memoptions.every((memUser) => memUser.value !== members.value))
            return [...previousData, ...newMembers];
          });

        }
        setSelectedFhusers(fhuserslist);
        userOrganizationList(userlist, viewParentObjective.objectivesdetails.individualweightage)
      })
    } catch (error) {
      throw error;
    }

  }
  const editorgobjective = async () => {
    try {

      const apiUrl = BaseUrl + `api/organization/view/${orgobjid}`;
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {

        const viewParentObjective = response.data.data;
        const orgobj = viewParentObjective.orgObjective;
        setorgobjectiveedit({
          ...orgobj,
          id: orgobj.id,
          name: orgobj.name,
          objectivename: orgobj.objectivename,
          uid: orgobj.uid,
          year: orgobj.year,
          startdate: orgobj.startdate,
          enddate: orgobj.endate,
          quarter: orgobj.quarter,
          description: orgobj.description,
          remark: orgobj.remark,
          organizationStatus: orgobj.organizationStatus,
          createdBy: orgobj.createdBy,
          createdByEmpId: orgobj.createdByEmpId,
          createdAt: orgobj.createdAt,
          updatedAt: orgobj.updatedAt,
          individualWeightage: orgobj.individualWeightage,
          band: orgobj.band,
          createdByUserName: orgobj.createdByUserName,
          createdByUserProfilePath: orgobj.createdByUserProfilePath,
          objCount: orgobj.objCount,
          objDetails: orgobj.objDetails,
          assignee_remarks_value: orgobj.assigneeremarks
        })
        if (viewParentObjective.orgObjectiveUsersListList !== 0) {
          setisSearchMem(true);
        }

        // setOrganizationName(response.data.organizationObjective);
        // setOrganizationDescription(response.data.organizationDescription);
        setCycleSelectedOption(viewParentObjective.orgObjective.quarter + ' ' + viewParentObjective.orgObjective.year);
        const userlist: string[] = [];
        var existingMemberList: OptionItem[] = [];
        const fhuserslist: OptionItem[] = [];
        const assignedforme: OptionItem[] = [];
        var user: any;
        var user1: any;
        var userme: any;
        viewParentObjective.orgObjectiveUsersList.map((member: any) => {

          user = {
            label: member.createdByUserName,
            value: member.userId,
            image: member.profilePath ? BaseUrl + member.profilePath : "assets/images/Default_user.png",//"assets/images/member.svg",
            objectivestatus: member.objectivestatus
          }
          existingMemberList.push(user);
        });
        setorgband(orgobj.band_i);
        // after cascading user in the member and group should not removed.
        // if (viewParentObjective.objectivesdetails.organizationStatus === "1") {
        //  setSelectedMemValues(existingMemberList);
        //   existingMemberList.map((user) => {
        //     userlist.push(user.value)
        //     // return user.value !== localStorage.loginId;+
        //     if (user.value === localStorage.getItem('loginId')) {
        //       setCheckboxChecked(true);
        //     }           
        //   })
        // } else {
        //   setMemberValues(existingMemberList);
        //   existingMemberList.map((resp) => {
        //     setMemOptions(res => res.filter(notselect => notselect.value !== resp.value));
        //     userlist.push(resp.value)
        //   })

        //   existingMemberList.map((user) => {
        //     if (user.value === localStorage.getItem('loginId')) {
        //       setCheckboxChecked(true);
        //     }
        //   })
        // }
        existingMemberList.map((resp) => {
          userlist.push(resp.value)
          editmemberdetails.push(resp.value);
        })

        const notassignees: OptionItem[] = [];
        const assigneee: OptionItem[] = [];
        if (viewParentObjective.orgObjective.groupid.length > 0) {
          const assigneduserdetails1: OptionItems[] = GrpallOptions.filter(option => {
            // const match = viewParentObjective.objectivesdetails.groupid.every((grp:string) => grp == option.value);
            const match = viewParentObjective.orgObjective.groupid.includes(option.value);
            // Log each option value and its match result
            return match;
          });
          setisSearchMem(false);
          setSelectedGrpValues(assigneduserdetails1)
          setAssignedUsers(assigneduserdetails1)
        }
        else {
          const newMembers = existingMemberList.filter(
            (member) => member.value === viewParentObjective.orgObjective.createdBy
          );
          const withoutloginid = existingMemberList.filter(
            (member) => member.value !== viewParentObjective.orgObjective.createdBy
          );
          const assignedforme = existingMemberList.filter((members) => Memoptions.some((memUser) => memUser.value !== members.value))
          setSelectedMemValues((previousData) => {
            const newMembers =
              existingMemberList.filter((member) => Memoptions.some((memUser) => memUser.value === member.value)
              )
              ;
            return [...previousData, ...newMembers];
          });
          setMemberValues((previousData) => {
            const newMembers =
              existingMemberList.filter(
                (members) => Memoptions.every((memUser) => memUser.value !== members.value))
            return [...previousData, ...newMembers];
          });

        }
        userOrganizationList(userlist, viewParentObjective.orgObjective.individualWeightage)
      })
    } catch (error) {
      throw error;
    }
  }
  const updateObjectiveOrganization = async (organizationStatus: string) => {

    const apiUrl = BaseUrl + `api/organization/update/${orgobjid}`

    const selectedMemberList: userStoreDetails[] = [];

    // selectedMemValues.map((member) => {
    //   const user = {
    //     empId: member.label,
    //     userId: member.value,
    //   }
    //   selectedMemberList.push(user);
    // })


    showCustomWeightage.map((member) => {
      const user = {
        userId: member.id,
        assignedusername: member.name,
        weightage: member.assignWeightage,
        organizationStatus: member.objectivestatus
      }
      selectedMemberList.push(user);
    })

    if (selectedMemValues.length !== 0) {
      setisSearchMem(true);
    } else {
      setisSearchMem(false);
    }
    await axios.put(apiUrl, {
      "orgObjective": orgobjectiveedit,
      "orgObjectiveUsersList": selectedMemberList,
      "saveOrCascade": orgobjectiveedit.organizationStatus
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      objectivelist();
      //window.location.reload();
    })
  }

  const handlesubmitcustomweightage = (status: string) => {
    const usersweightage = assignuserweightage.filter(user => user.id != localStorage.loginId)
    // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }
    // Disable the button
    setIsWeightageButton(true);

    // if (objectivevalue.objectivename == "" || objectivevalue.quarter == "") {
    //   message.error({
    //     content: (
    //       <div className="c_FF4747 justify-center align-center p-1">
    //         {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
    //         <span className="c_FF4747 fs-16">
    //           Objective And ObjectiveName Name Is Mandatory
    //         </span>
    //       </div>
    //     ),
    //     duration: 1,
    //     icon: null,
    //   });
    //   toggleCustomVisibility()
    // }
    // else {
      //  if (usersweightage.some(user => user.avaiableWeightage==0)){
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{objectivevalue.objectivename}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // } else {
      //   Modal.confirm({
      //     title: 'Do you want to save the weightage this objective ?',
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{objectivevalue.objectivename}</p>
      //         <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
      //       </>
      //     ),
      //     okText: 'Save',
      //     cancelText: 'Back',
      //     okButtonProps: { className: 'C_btn' },
      //     cancelButtonProps: { className: 'outline_btn' },
      //     centered: true,
      //     onOk() {
      //       message.success({
      //         content: (
      //           <div className="success-message justify-center align-center p-1">
      //             <img src="assets/images/check-circle.svg" alt="Success Icon" />
      //             <span className="c_32C6A0 fs-16">
      //               Weightage Saved successfully.
      //             </span>
      //           </div>
      //         ),
      //         duration: 2,
      //         icon: null,
      //       });
      //       toggleCustomVisibility();
      //     },
      //     onCancel() {
      //       setCasClicked(false);
      //     },
      //   });
      // }

      message.success({
        content: (
          <div className="success-message justify-center align-center p-1">
            <img src="assets/images/check-circle.svg" alt="Success Icon" />
            <span className="c_32C6A0 fs-16">
              Weightage Saved successfully.
            </span>
          </div>
        ),
        duration: 2,
        icon: null,
      });
      toggleCustomVisibility();
      //  handleSubmit("Save",true)


    // }

    setTimeout(() => {
      // Enable the button
      setIsWeightageButton(false);
    }, 2000);
  }
  const onChange = (key: string) => {

  };

  return (

    <div className="">
      {
        viewButton && permission_childobjcreate ? (<Row>
          {button == "Edit" ? (<>

            <img src="assets/images/d_edit.svg" className="mr-1" alt="Edit" />
            <span onClick={showDrawer}>{button}</span>
          </>) :
            (
              <span className=" c-000 fs-12 c_32B7D9 fw-700" onClick={showDrawer}> {button}</span>
            )}
        </Row>) : (<span></span>)
      }
      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibility}>
              <ArrowLeftOutlined /> &nbsp;Custom Weightage
            </div>
          ) : (
            header
          )
        }
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={open}
        className="objective-canvas  assgin_obj"
      >
        {ViewWeightage ? (
          <>
            <div className="cw_table">
              {isSearchMem && selectedMemValues.length > 0 || MemberValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + MemberValues.length})</p></Row> : ''}

              {/* <p className="fs-16 c_32B7D9 fs-14">Product Members (18) 0</p> */}
              <Row justify={"space-between"} className="mb-2">
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="position-relative"
                >
                  <input
                    type="search"
                    placeholder="Search Member"
                    className="w-100 table_search_inputbox pl-2"
                    value={searchTextValue}
                    // onSearch={handleSearch}
                    onChange={(e) => setSearchTextValue(e.target.value)}
                  />
                  <img
                    src="assets/images/search.svg"
                    className="table_search_img"
                    alt=""
                  />
                </Col>
              </Row>
              <Table
                dataSource={showCustomWeightage}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 10 }}
              />
              <button className="cancel-btn float-right" onClick={ViewWeightageBack}>
                Back
              </button>
            </div>
          </>
        ) : (
          <>

            <Tabs defaultActiveKey="1" onChange={onChange} className='objectivetabs-container sub_tab p-0 mb-0'>
              <TabPane
                tab={
                  <span>Cascade Objective</span>
                }
                key="1"
                className="mb-0 position-relative"
              >
                <div>
                  <Form className="">
                    <div>
                      {isCustomVisible ? (
                        <div className="cw_table">
                          {isSearchMem && selectedMemValues.length > 0 && mydetails != undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + 1})</p></Row> : ''}
                          {isSearchMem && selectedMemValues.length > 0 && mydetails == undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                          {/* {isSearchMem && selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''} */}
                          <Row justify={"space-between"} className="mb-2">
                            <Col xl={6} lg={5} md={6} sm={6} xs={24} className="mb-2">
                              {/* <span
                              className="c_32B7D9 fs-12 cur-pointer"
                              onClick={toggleCustomVisibility}
                              >
                              <ArrowLeftOutlined /> Custom Weightage
                              </span> */}
                            </Col>
                            <Col
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                              className="position-relative"
                            >
                              <input
                                type="search"
                                placeholder="Search Member"
                                className="w-100 table_search_inputbox pl-2"
                                value={searchTextValue}
                                // onSearch={handleSearch}
                                onChange={(e) => setSearchTextValue(e.target.value)}
                              />
                              <img
                                src="assets/images/search.svg"
                                className="table_search_img"
                                alt=""
                              />
                            </Col>
                          </Row>
                          <Table
                            dataSource={showCustomWeightage}
                            columns={columns}
                            scroll={{ x: true }}
                            pagination={{ pageSize: 10 }}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="title-section ">
                            <Row>
                              <Col xl={16}>
                                <label htmlFor="" className="fw-600 c_32B7D9">Objective Name</label> <br />
                                <p className="fs-16 fw-300">{objectname}</p>
                              </Col>
                              <Col xl={8}>
                                <label htmlFor="" className="fw-600">Cycle</label> <br />
                                <p className="fs-16 fw-300">{orgQuartervalue} {year}</p>
                                <input type="hidden" value={`${orgobjid}`} />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={24}>
                                <label htmlFor="" className="fw-600 c_32B7D9">Objective Description</label> <br />
                                <p className="fs-16 fw-300 org_obj_name">{orgdescription}</p>
                              </Col>
                            </Row>
                            {objectivetype == "orgobjective" &&
                              <Row>
                                <Col xl={24}>
                                  <label htmlFor="" className="fw-600 c_32B7D9">Band</label> <br />
                                  <p className="fs-16 fw-300 org_obj_name">{orgobjectiveedit.band}</p>
                                </Col>
                              </Row>
                            }
                          </div>

                          <div className="assign-section mb-3">
                            <label htmlFor="" className="fw-600 c_32B7D9">
                              Assign Members<sup className="c_32B7D9">*</sup>
                            </label>

                            {Memoptions.length == 0 &&
                              <span className="danger ml-3">Assignees Not Available</span>
                            }
                            <span
                              className="float-right c_32B7D9 text_line fs-14 cur-pointer"
                              onClick={handleSearchMember}
                            >
                              {isSearchMem ? "Search Groups" : "Search Members"}
                            </span>
                            {/* {isSearchMem ? (<span className="float-right c_32B7D9 text_line fs-14 cur-pointer">
                              <Checkbox className="c_32B7D9 fs-14" onClick={handlecheckbox} id="includeMyselfCheckboxObj" checked={checkboxChecked}>Include Myself</Checkbox>
                          </span>) : (<></>)} */}

                            <br />
                            <div className="mt-1">
                              {isSearchMem ? (
                                <>
                                  <Select
                                    mode="multiple"
                                    placeholder={
                                      <div
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <img
                                          src="assets/images/member.svg"
                                          alt="Placeholder"
                                          style={{ marginRight: "8px" }}
                                        />
                                        <span>Select Member</span>
                                      </div>
                                    }
                                    onChange={handleMemSelectChange}
                                    value={selectedMemValues}
                                    className="w-100 form-control"
                                    labelInValue
                                    optionLabelProp="label"
                                    dropdownRender={(menu) => <div>{menu}</div>}
                                    notFoundContent={<CustomEmptyMemberText />} 
                                    disabled={Memoptions.length == 0}
                                    // options={Memoptions}
                                    // filterOption={(input, option) =>
                                    //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    // }
                                    filterOption={(input, option) =>
                                      option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                    }
                                  >
                                    {Memoptions.map((option) => (
                                      <Option
                                        key={option.value}
                                        value={option.value}
                                        label={option.label}
                                      >
                                        {renderOption(option)}
                                      </Option>
                                    ))}
                                  </Select>
                                </>
                              ) : (
                                <Select
                                  mode="multiple"
                                  placeholder={
                                    <div
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <img
                                        src="assets/images/people.svg"
                                        alt="Placeholder"
                                        style={{ marginRight: "8px" }}
                                      />
                                      <span>Select Group</span>
                                    </div>
                                  }
                                  onChange={handleGrpSelectChange}
                                  value={selectedGrpValues}
                                  className="w-100 form-control"
                                  labelInValue
                                  optionLabelProp="label"
                                  dropdownRender={(menu) => <div>{menu}</div>}
                                  notFoundContent={<CustomEmptyGroupText />}
                                  disabled={Memoptions.length == 0}
                                  // options={GrpOptions}
                                  // filterOption={(input, option) =>
                                  //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                  // }
                                  filterOption={(input, option) =>
                                    option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                  }

                                >
                                  {GrpOptions.map((option) => (
                                    <Option
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                    >
                                      {renderOptiongrp(option)}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                              <div
                                className={
                                  checkboxChecked
                                    ? "assgin_selected_val"
                                    : "" ||
                                      selectedMemValues
                                      ? "assgin_selected_val"
                                      : "" || selectedGrpValues
                                        ? "assgin_selected_val"
                                        : "" || mydetails
                                          ? "assgin_selected_val"
                                          : ""
                                }
                              >

                                {isSearchMem && selectedMemValues.length > 0 && mydetails != undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + 1})</p></Row> : ''}
                                {isSearchMem && selectedMemValues.length > 0 && mydetails == undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : ''}
                                {mydetails != undefined
                                  &&
                                  <Tag
                                    key={mydetails?.value}
                                    // closable
                                    onClose={() => handleTagClose(mydetails?.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={mydetails?.image}
                                      onError={handleImageError}
                                      alt=""
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {mydetails?.label}
                                  </Tag>
                                }

                                {selectedMemValues.map((value) => (
                                  <Tag
                                    key={value.value}
                                    closable
                                    onClose={() => handleMemTagClose(value.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={value.image ? FileUrl + value.image : "assets/images/Default_user.svg"}
                                      onError={handleImageError}
                                      alt=""
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {value.label}
                                  </Tag>
                                ))}
                                {MemberValues.map(option => (
                                  <span className="" key={option.label}>
                                    <span className="am_view">
                                      <img
                                        src={option.image}
                                        onError={handleImageError}
                                        className="select_user"
                                        style={{ position: "relative", top: "5px" }}
                                        alt=""
                                      />
                                      <span className="pl-1">{option.label}</span>
                                    </span>
                                  </span>
                                ))}
                                {isSearchMem && selectedMemValues.length === 0 && mydetails?.label == null && MemberValues.length === 0 ? (
                                  <>
                                    <p className="c_AAA text-center">No members added</p>
                                  </>
                                ) :
                                  (<></>
                                  )}
                                {isSearchMem === false && selectedGrpValues.length === 0 && mydetails?.label == null ? (
                                  <>
                                    <p className="c_AAA text-center">No groups added</p>
                                  </>
                                ) :
                                  (
                                    <>
                                    </>
                                  )}
                                {assignedusers.map((value) => (
                                  <Tag
                                    key={value.value}
                                    closable={value.createdBy === localStorage.loginId}
                                    onClose={() => handleTagClose(value.value)}
                                    style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src="assets/images/Default_user.svg"
                                      alt=""
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                    />
                                    {value.label}
                                  </Tag>

                                ))}
                                {/* {selectedGrpValues.map((value) => (
                                  <Tag
                                  key={value.value}
                                  closable
                                  onClose={() => handleTagClose(value.value)}
                                  style={{
                                      marginRight: "5px",
                                      marginBottom: "5px",
                                      color: "#000",
                                      borderRadius: 15,
                                      padding: "5px 10px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                  }}
                                  >
                                  <img
                                      src={value.image}
                                      alt={value.label}
                                      style={{ marginRight: "8px" }}
                                      className="select_user"
                                  />
                                  {value.label}
                                  </Tag>
                              ))} */}
                              </div>
                            </div>
                            {/* <span
                              className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                              onClick={toggleViewWeightage}>  View Weightage <ArrowRightOutlined />
                            </span> */}
                            <span
                              className="c_32B7D9 fs-14 cur-pointer text_line float-right"
                              onClick={closeDrawerAndUserList}
                            >
                              Custom Weightage <ArrowRightOutlined />
                            </span>
                          </div>


                          {/* <div className="mb-3"> */}
                          {/* <Row className={isCustomVisible ? "d-none" : ""}> */}
                          {/* <Col xl={19} lg={19} md={18} sm={12} xs={24}>
                              <Row className="align-center"> */}
                          {/* <Col xl={12} lg={12} md={10} sm={24} xs={24}>
                                  <label htmlFor="" className="fw-600">
                                      Individual Weightage<sup className="c_32B7D9">*</sup>
                                  </label>
                                  <Input
                                      placeholder=""
                                      // type="number"
                                      suffix="%"
                                      className="canvas-objective-input mt-1"
                                      value={objectivevalue.individualweightage}
                                      name="individualweightage"
                                      // onChange={(e) =>
                                      //   handleTextChange(e, setRemarkText, maxWords.remark)
                                      // }
                                      onChange={(e) => {
                                      const inputVal = e.target.value;
                                      const numericRegex = /^[0-9]*$/;
                                      if (inputVal === 'customized' ||inputVal === ''|| (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
                                          handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                                      }
                                  }}
                                  />
                                  {errors && errors.find(error => error.path[0] === 'individualweightage') && (
                                      <p className="danger">{errors.find(error => error.path[0] === 'individualweightage')?.message}</p>
                                  )}
                                  </Col>
                                  <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                                  <p className="pl-2 pt-2">
                                      i.e, Weightage only be a Percentage
                                  </p>
                                  </Col> */}
                          {/* </Row>
                              </Col> */}
                          {/* <Col xl={5} lg={5} md={6} sm={12} xs={24}> */}
                          {/* <span
                              className="c_32B7D9 fs-14 cur-pointer text_line float-right"
                              onClick={closeDrawerAndUserList}
                              >
                              Click here to Update Weightage <ArrowRightOutlined />
                              </span> */}
                          {/* </Col> */}
                          {/* </Row> */}
                          {/* </div> */}
                          {objectivetype != "orgobjective" &&
                            <div className="remark-section mb-3">
                              <label className="canvas-objective-title c_32B7D9">Remarks </label>
                              <p className="fs-16 fw-300">{parentObjective.remark == "" ? "No Remarks Given" : parentObjective.remark}</p>
                              {/* <span className="c-0050 fs-12 mt-1">
                                {parentObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                                {maxWords.remark_max_word_count}
                                </span> */}
                            </div>
                          }
                          {objectivetype != "orgobjective" ? (
                            <div className="remark-section mb-3">
                              <div className="justify-between">
                                <label className="canvas-objective-title c_32B7D9">Assignees Remarks (Optional)</label>
                                <span className="c-0050 fs-12 mt-1">
                                  {/* {parentObjective.assignee_remarks_value.trim().split(/\s+/).join("").length} /{" "}
                                  {maxWords.remark_max_word_count} */}
                                </span>
                              </div>
                              <div className="objective-title-inner">
                                <TextArea
                                  rows={3}
                                  placeholder="Write here...."
                                  className="canvas-objective-input w-100"
                                  name="assignee_remarks_value"
                                  value={parentObjective.assignee_remarks_value}
                                  onChange={(e) =>
                                    handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                                  }
                                />
                              </div>
                            </div>) : (
                            <>
                              <div className="remark-section mb-3">
                                <label className="canvas-objective-title c_32B7D9">Remarks</label>
                                <p className="fs-16 fw-300">{orgobjectiveedit.remark == "" ? "No Remarks Given" : orgobjectiveedit.remark}</p>
                                {/* <span className="c-0050 fs-12 mt-1">
                                {parentObjective.remark.trim().split(/\s+/).join("").length} /{" "}
                                {maxWords.remark_max_word_count}
                                </span> */}
                              </div>
                              {selectedMemValues.length > 0 ? (
                                <>
                                  <div className="remark-section mb-3">
                                    <div className="justify-between">
                                      <label className="canvas-objective-title c_32B7D9">Assignees Remarks (Optional)</label>
                                      <span className="c-0050 fs-12 mt-1">
                                        {/* {parentObjective.assignee_remarks_value.trim().split(/\s+/).join("").length} /{" "}
                                        {maxWords.remark_max_word_count} */}
                                      </span>
                                    </div>
                                    <div className="objective-title-inner">
                                      <TextArea
                                        rows={3}
                                        placeholder="Write here...."
                                        className="canvas-objective-input w-100"
                                        name="assigneeremarks"
                                        value={orgobjectiveedit.assigneeremarks}
                                        onChange={(e) =>
                                          handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (<></>)}
                            </>
                          )
                          }
                        </div>
                      )}
                      <div className="Submit-section mt-3">
                        {isCustomVisible ? (
                          <>
                            <button
                              className="cancel-btn"
                              onClick={toggleCustomVisibility}
                            >
                              Back
                            </button>
                            <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }} disabled={isWeightageButton}>Submit</button>
                          </>
                        ) : (
                          <>
                            <button className="cancel_red_btn" onClick={onClose}
                              disabled={isButtonDisabled}
                            // disabled={clicked} 
                            >
                              {isCustomVisible ? "Submit" : "Cancel"}
                            </button>
                            <button className={"cyan_button"} onClick={() => {
                              if (objectivetype == "orgobjective") {
                                updateObjectiveOrganization("save")
                              }
                              else {
                                updateParentObjective("save")
                              }
                            }}
                              disabled={isButtonDisabled}
                            // disabled={casclicked}
                            >{isCustomVisible ? "Submit" : "Save"}</button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </TabPane>
              <TabPane
               tab={<span onClick={() => setTabClicked(true)}>Create Child Objective</span>}
                key="2"
              >
                <ChildCreateObj
                  objectcode={objectcode}
                  objectname={objectname}
                  orgQuartervalue={orgQuartervalue}
                  orgobjid={orgobjid}
                  objid={objid}
                  objective_id={objective_id}
                  year={year}
                  startdate={startdate}
                  enddate={enddate}
                  objectivelist={objectivelist}
                  orgdescription={orgdescription}
                />
              </TabPane>
            </Tabs>

          </>)}




      </Drawer>
    </div>
  );
};

export default AssignObj;
