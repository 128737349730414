import React, { useState, useEffect } from "react";
import { Row, Table, Checkbox } from "antd";
import { BaseUrl } from "../../../App";
import axios from "axios";
import { CloseCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { record } from "zod";

const Char_Limitations: React.FC = () => {
  const [editMode, setEditMode] = useState<{ [id: string]: boolean }>({});
  const [permissionLMData, setPermissionLMData] = useState([]);

  const getPermissionData = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/inputCount/lists");
      setPermissionLMData(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getPermissionData();
  }, []);

  const handleEditClick = (id: string) => {
    setEditMode({ ...editMode, [id]: true });
  };

  const handleCancelClick = (id: string) => {
    setEditMode({ ...editMode, [id]: false });
    getPermissionData();
  };

  const handleUpdateClick = async (id: string) => {
    try {
      const formData = new FormData();
      const foundItem: any = permissionLMData.find((item: any) => item.id === id);

      if (foundItem) {
        formData.append("name_max_word_count", foundItem.name_max_word_count);
        formData.append("description_max_word_count", foundItem.description_max_word_count);
        formData.append("remark_max_word_count", foundItem.remark_max_word_count);
      }
      await axios.put(BaseUrl + "api/inputCount/update/" + id, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEditMode({ ...editMode, [id]: false });
      getPermissionData();
    } catch (error) {
      // console.log(error);
    }
  };

  const handleNameChange = (record: any, inputVal: string) => {
    const numericRegex = /^[0-9]*$/;
    if (inputVal === "" || (parseInt(inputVal) <= 1000 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
      setPermissionLMData((prevData: any) => {
        return prevData.map((item: any) => {
          if (item.id === record.id) {
            return {
              ...item,
              name_max_word_count: inputVal
            };
          }
          return item;
        });
      });
    }
  };

  const handleDescriptionChange = (record: any, inputVal: string) => {
    const numericRegex = /^[0-9]*$/;
    if (inputVal === "" || (parseInt(inputVal) <= 1000 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
      setPermissionLMData((prevData: any) => {
        return prevData.map((item: any) => {
          if (item.id === record.id) {
            return {
              ...item,
              description_max_word_count: inputVal
            };
          }
          return item;
        });
      });
    }
  };

  const handleRemarkChange = (record: any, inputVal: string) => {
    const numericRegex = /^[0-9]*$/;
    if (inputVal === "" || (parseInt(inputVal) <= 1000 && parseInt(inputVal) >= 0 && numericRegex.test(inputVal))) {
      setPermissionLMData((prevData: any) => {
        return prevData.map((item: any) => {
          if (item.id === record.id) {
            return {
              ...item,
              remark_max_word_count: inputVal
            };
          }
          return item;
        });
      });
    }
  };

  const permissionLMColumns = [
    {
      title: "Permissions",
      dataIndex: "moduleName",
      key: "module_name"
    },
    {
      title: "Name/Title",
      render: (record: any) => (
        <input
          type="text"
          className="permission_inputbox"
          value={record.name_max_word_count}
          onChange={(e) => handleNameChange(record, e.target.value)}
          disabled={!editMode[record.id]}
        />
      )
    },
    {
      title: "Description",
      render: (record: any) => (
        (record.moduleName === "Groups" || record.moduleName === "Lead Measure") ? null : (
          <>
            <input
              className="permission_inputbox"
              value={record.description_max_word_count}
              onChange={(e) => handleDescriptionChange(record, e.target.value)}
              disabled={!editMode[record.id]}
            />
          </>
        )
      )
    },
    {
      title: "Remarks (Optional)",
      render: (record: any) => (
        (record.moduleName === "Groups" || record.moduleName === "Lead Measure") ? null : (
          <>
            <input
              className="permission_inputbox"
              value={record.remark_max_word_count}
              onChange={(e) => handleRemarkChange(record, e.target.value)}
              disabled={!editMode[record.id]}
            />
          </>
        )
      )
    },
    {
      title: "Action",
      render: (record: any) => (
        <div className="d-flex">
          {editMode[record.id] ? (
            <>
              <CloseCircleOutlined onClick={() => handleCancelClick(record.id)} className="admin_cancel_btn cur-pointer" />
              <CheckCircleTwoTone onClick={() => handleUpdateClick(record.id)} className="admin_update_btn cur-pointer" />
            </>
          ) : (
            // <button onClick={() => handleEditClick(record.id)}>Edit</button>
            <img src="assets/images/message-edit.svg" alt="" onClick={() => handleEditClick(record.id)} className="cur-pointer" />

          )}
        </div>
      )
    }
  ];

  return (
    <>
      <div className="admin_panel">
        <Table
          className=""
          dataSource={permissionLMData}
          scroll={{ x: true }}
          columns={permissionLMColumns}
          pagination={false}
        />
      </div>
    </>
  );
};

export default Char_Limitations;
