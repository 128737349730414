import React, { useState } from 'react';
import { Form, Input, Button, message, Flex } from 'antd';
import{LogoutOutlined} from "@ant-design/icons";
import { BaseUrl, FileUrl } from "../../App";
import axios from "axios";
import { redirect } from 'react-router-dom';

interface FormValues {
  password: string;
  confirmPassword: string;
}

interface PasswordChangeFormProps {
  onLogout: () => void;
}

const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ onLogout }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: FormValues) => {
    setLoading(true);
    const { password } = values;

    try {
      // Password validation (at least 8 characters and containing special characters)
      const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])(?=.{6,})/;
      if (!regex.test(password)) {
        throw new Error('Password must contain at least 6 characters including special characters');
      }

      const apiUrl = BaseUrl + `api/users/firstTimeLoginChangePassword`;
      const formData = new FormData();
      formData.append('password', password);

      const response = await axios.put(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      
      if (response.status === 200) {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Password changed successfully
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        setTimeout(() => {
          localStorage.clear()
          window.location.href = "/";
        }, 1000);
      } else {
        message.error('Failed to change password');
      }
    } catch (error) {
      // console.error('Error:', error);
      message.error('Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='change_password_div'>
      <Flex className='align-center justify-between'>
      <h2 className="c_32B7D9 fs-20 fw-600"> Change Password</h2>
      <Button  className='logout_btn float-right br_5' type="primary" htmlType="submit" onClick={onLogout}>
           <LogoutOutlined  />
        </Button>
      </Flex>
    
      <Form  className='password-bg' layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])/,
              message: 'Password must contain at least 6 characters, including one number, both lower and uppercase letters, and special characters.',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your new password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button  className='C_btn float-right' type="primary" htmlType="submit" loading={loading}>
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordChangeForm;
