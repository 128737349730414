import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Tooltip } from "antd";
import { BaseUrl } from "../../App";
import axios from "axios";
interface SidebarProps {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}
const App: React.FC<SidebarProps> = (sidebarOpen, toggleSidebar) => {
  const location = useLocation();
  const { pathname } = location;
  const userRole: string | null = localStorage.getItem("rolename");
  const adminStatus: string | null = localStorage.getItem("adminstatus");
  const splitLocation = pathname.split("/");
  const [userData, setUserData] = useState([]);

  interface userpermission {
    id: string,
    userid: string,
    userempid: string,
    createdAt: Date,
    status: number,
  }

  const [userpermissions, setuserpermission] = useState(false);
  const [userreportpermission, setuserreportpermission] = useState<userpermission>();

  const UserData = async () => {

    try {
      const apiUrl = BaseUrl + `api/users/getByHrId`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setUserData(response.data);
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }
  const userdetails = async () => {

    try {
      const apiUrl = BaseUrl + `api/users/userpermission/permission/${localStorage.username}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setuserreportpermission(response.data);
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }

  useEffect(() => {
    UserData();
    userdetails();
  }, []);



  return (
    <div className={sidebarOpen ? 'sidebar open' : 'sidebar'}>
      {/* <img src="assets/images/GS-logo.svg" alt="" className="mt-2 mb-1" /> */}
      <img src="assets/images/GoalSync_sidebar.svg" alt="" className="mt-2 mb-1" />
      <div className="mt-4 sidebar_css">
        <ul>
          {userRole === "Admin" && (
            <>
              <Tooltip placement="top" title="UserList" arrow={true}>
                <Link to="/userlist">
                    <li className={`mt-2 ${splitLocation[1] === "userlist" ? "sidebar_active " : " "} `}>
                        <img src="assets/images/settings.svg" alt="" />
                    </li>
                </Link>
              </Tooltip>

              <Tooltip placement="right" title="Admin Panel" arrow={true}>
                <Link to="/admin_panel">
                  <li className={`mt-2 ${splitLocation[1] === "admin_panel" ? "sidebar_active" : " "} `}>
                      <img src="assets/images/Support_Icon.svg" alt="" />
                  </li>
                </Link>
              </Tooltip>

              {/* <li className={splitLocation[1] === "userlist" ? "sidebar_active " : " "}>
                <Link to="/userlist">
                  <img src="assets/images/Appraisal.svg" alt="" />
                </Link>
              </li> */}
            </>
          )}
          {/* <li className={`mt-2 ${splitLocation[1] === "appraisal" ? "sidebar_active" : ""}`}>
            <Link to="/appraisal">
              <img src="assets/images/Appraisal.svg" alt="" />
            </Link>
          </li> */}
          {userRole != "Admin" && (
            <>
              <Tooltip placement="right" title="Objectives" arrow={true}>
                 <Link to="/objective">
                <li className={`mt-2 ${splitLocation[1] === "objective" ? "sidebar_active " : " "}`}>
                    <img src="assets/images/crown.svg" alt="" />
                </li>
                </Link>

              </Tooltip>
              {/* {adminStatus === "1" && (
              <li className={`mt-2 ${splitLocation[1] === "userlist" ? "sidebar_active " : " "} `}>
              <Link to="/userlist">
                <img src="assets/images/L1 Menu 6.svg" alt="" />
              </Link>
              </li>
              )
              } */}
              {/* <li className={`mt-2 ${splitLocation[1] === "" ? "" : ""}`}>
                <img src="assets/images/Appraisal.svg" alt="" />
              </li> */}
              {/* <li className={`mt-2 ${splitLocation[1] === "" ? " " : " "} `}>
                <img src="assets/images/L1 Menu 5.svg" alt="" />
              </li> */}
              {/* <li className={`mt-2 ${splitLocation[1] === "" ? " " : " "} `}>
                <img src="assets/images/L1 Menu 6.svg" alt="" />
              </li> */}
            </>
          )}
          <Tooltip placement="right" title="CFR" arrow={true}>
          <Link to="/conversation">
            <li className={`mt-2 ${splitLocation[1] === "conversation" ? "sidebar_active " : " "}`}>
                <img src="assets/images/messages-sidebar.svg" alt="" />
            </li>
            </Link>
          </Tooltip>

          <Tooltip placement="right" title="Appraisal" arrow={true}>
          <Link to="/SignOff">
            <li className={`mt-2 ${splitLocation[1] === "SignOff" ? "sidebar_active " : " "}`}>
                <img src="assets/images/signoff_check.svg" alt="" />
            </li>
            </Link>
          </Tooltip>


          <Tooltip placement="right" title="Support" arrow={true}>
          <Link to="/support">
            <li className={`mt-2 ${splitLocation[1] === "support" ? "sidebar_active " : " "}`}>
                <img src="assets/images/message-question.svg" alt="" />
            </li>
            </Link>
          </Tooltip>
          {/* {(userData.length > 0 || (userreportpermission?.status == 0 && userreportpermission.userempid != null) ||localStorage.username=="1015182") && */}
            <Tooltip placement="right" title="Report" arrow={true}>
            <Link to="/report">
              <li className={`mt-2 ${splitLocation[1] === "report" ? "sidebar_active " : " "}`}>
                  <img src="assets/images/report-sidebar.svg" alt="" />
              </li>
              </Link>
            </Tooltip>
          {/* } */}
        </ul>
      </div>
    </div>
  );
};

export default App;
