import React, { useState,useEffect } from 'react';
import { Button, Divider, Radio, Table, Select, Modal } from 'antd';
import type { TableColumnsType } from 'antd';
import axios from "axios";
import { BaseUrl } from '../../../App';

interface DataType {
  key: React.Key;
  name: string;
  employeeid: string;
  email: string;
  status:number;
  company_id:number; // Add imageUrl property
}
interface userdata {
  employeeid:string,
  email:string,
  status:number,
  companyid:number
}
const { Option } = Select;

const App: React.FC = () => {
  const userdatas = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/ResourceManagement", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const userlist = response.data.data;
      setData(userlist)
    } catch (err) {
      // console.log(err)
    }
  }
  const [data, setData] = useState<DataType[]>([]);

  const handleRadioChange = (key: React.Key, columnName: keyof DataType) => {
    setData(prevData => {
      return prevData.map(item => {
        if (item.key === key) {
          return {
            ...item,
            selected: columnName,
          };
        }
        return item;
      });
    });
  };

  const handleSelectChange = (key: number, value: number,employeeId:string,email:string,company_id:number) => {
    // console.log("oneoneone",key)
    // console.log(`Select option changed for row ${key}, value ${value}`);
    setData(prevData => {
      const newData = [...prevData];
      newData[key] = { ...newData[key], status: value };
      return newData;
    });
    const userdetails:userdata={
      employeeid:employeeId,
      email:email,
      companyid:company_id,
      status:value
    }
    userstatus.push(userdetails)
  };
 
  const handleUpdate = () => {
    Modal.confirm({
      title: 'Do you want to Update?',
      okText: 'Save',
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        updatestatus();
      },
      onCancel() {
        // console.log('Action cancelled');
      },
    });
  }
  const updatestatus = async()=>{
    const api = `${BaseUrl}api/users/statusupdate`  
    try {
      const response = await axios.post(api,userstatus, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const userlist = response.data.data;
    } catch (err) {
      // console.log(err)
    }
  }
  const selectOptions = [
    {label:"Active", value: 1 ,class:'success'},
    {label:"InActive",value: 0,class:'danger' },
  ];
  const [userstatus,setstatus] = useState<userdata[]>([])
  useEffect(() => {
    userdatas();
  }, []);
  
  
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      render: (text: string, record: DataType) => (
        <div className='d-flex align-center'>
          {/* <img src={record.imageUrl} alt={text} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} /> */}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: 'Employee ID',
      dataIndex: 'employeeid',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Employee Email',
      dataIndex: 'email',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'companyname',
      dataIndex: 'company_id',
      render: (text) => {
        let companyName = '';
        if (text==1) {
          companyName = 'DarwinBox';
        } else if (text==2) {
          companyName = 'HEPL';
        }
        else if(text==3){
          companyName = 'CITPL'
        }
        return <span>{companyName}</span>;
      },
    },
    // {
    //   title: 'CITPL',
    //   dataIndex: 'CITPL',
    //   render: (CITPL: string, record: DataType) => (
    //     <Radio
    //       checked={record.selected === 'CITPL'}
    //       onChange={() => handleRadioChange(record.key, 'CITPL')}
    //     />
    //   ),
    // },
    // {
    //   title: 'CKPL',
    //   dataIndex: 'CKPL',
    //   render: (CKPL: string, record: DataType) => (
    //     <Radio
    //       checked={record.selected === 'CKPL'}
    //       onChange={() => handleRadioChange(record.key, 'CKPL')}
    //     />
    //   ),
    // },
    // {
    //   title: 'HEPL',
    //   dataIndex: 'HEPL',
    //   render: (HEPL: string, record: DataType) => (
    //     <Radio
    //       checked={record.selected === 'HEPL'}
    //       onChange={() => handleRadioChange(record.key, 'HEPL')}
    //     />
    //   ),
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: number, record: DataType,index:number) => (
        <Select
          value={text}
          onChange={(value) => handleSelectChange(index, value,record.employeeid,record.email,record.company_id)}
          placeholder="Select"
        >
          {selectOptions.map(option => (
            <Option key={option.value} value={option.value}>
              <div className="d-flex align-center">
                {option.label}
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <div className='resource_management'>
      <div className='justify-end'>
        <button className='cyan_button mb-1' onClick={handleUpdate}>Update changes</button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default App;
