import React, { useState, useEffect } from "react";
import { Flex, Modal, Rate, Spin } from "antd";
import {
  Row,
  Select,
  Button,
  Space,
  Dropdown,
  Menu,
  Divider,
  Radio,
  Col,
  Input,
  message
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import TeamAppraisal from "./TeamAppraisal";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import { set } from "zod";

const { Option } = Select;

const { TextArea } = Input;

const desc = [
  "Unsatisfactory Performance😔",
  "Needs Improvement ⬆️",
  "Meets Expectation 🤝",
  "Strong Performer 💪🏻",
  "Exceed Expectations 🎖️",
];

interface TeamApprasisalEditProps {
  userId: string;
  employeeRating: string;
  employeeRatingCount: string;
  employeeId: string;
}

interface objapr {
  objectiveid: string;
  remarks: string;
  userid: string;
  objectivepercentage: number;
  supervisorremark: string;
  supervisorretings: number;
  supervisorstatus: number;
  objrefid: string;
}
interface krapr {
  objectiveid: string;
  krid: string;
  krpercentage: number;
  userid: string;
  remarks: string;
  supervisorremark: string;
  supervisorretings: number;
  supervisorstatus: number;
  objrefid: string;
  selfratingsobj: number;
  selfremarksobj: string;
  krActualPercent: number;
}

interface overallRating {
  selfStatus: number;
  overallSelfRating: number;
  supervisorStatus: number;
  overallSupervisorRating: number;
  reviewerStatus: number;
  reviewerRating: number;
  reviewerRemarks: string;
};


const TeamApprasisalEdit: React.FC<TeamApprasisalEditProps> = ({
  userId,
  employeeRating,
  employeeRatingCount,
  employeeId
}) => {
  const [teamAppraisalData, setTeamAppraisalData] = useState<ObjectiveApparisal[]>([]);
  const [TMoptions, setTMoptions] = useState<User[]>([]);
  const [TMselectedOption, setTMSelectedOption] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [overAllRating, setOverAllRating] = useState<overallRating>({
    selfStatus: 0,
    overallSelfRating: 0,
    supervisorStatus: 0,
    overallSupervisorRating: 0,
    reviewerStatus: 0,
    reviewerRating: 0,
    reviewerRemarks: "",
  });
  const handleTMPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); // Decrease index to select previous user
      setTMSelectedOption(TMoptions[currentIndex - 1].id); // Update selected user ID
    }
    editAppraisal(TMoptions[currentIndex - 1].username);
    showOverAllUserAllRating(TMoptions[currentIndex - 1].username);
  };

  const handleTMNext = () => {
    if (currentIndex < TMoptions.length - 1) {
      setCurrentIndex(currentIndex + 1); // Increase index to select next user
      setTMSelectedOption(TMoptions[currentIndex + 1].id); // Update selected user ID
    }
    editAppraisal(TMoptions[currentIndex + 1].username);
    showOverAllUserAllRating(TMoptions[currentIndex + 1].username);
  };


  const isPrevAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) > 0;
  const isNextAvailable =
    TMoptions.findIndex((option) => option.id === TMselectedOption) <
    TMoptions.length - 1;

  const buttonStyle = (isAvailable: any) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });

  const filteredObjectives = teamAppraisalData.filter(
    (objective) => objective.createdby === TMselectedOption
  );

  const [Confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(!Confirm);
  };

  const handleOptionChange = (value: string) => {
    setTMSelectedOption(value);
    // console.log(value);
    const newIndex = TMoptions.findIndex((user) => user.id === value);
    setCurrentIndex(newIndex);
    editAppraisal(TMoptions[newIndex].username);
    showOverAllUserAllRating(TMoptions[newIndex].username);
  };

  const [teamAppraisal, setTeamAppraisal] = useState(false);

  const handleManagerSubmit = () => {
    setTeamAppraisal(!teamAppraisal);
  };

  const [backTeamAppraisal, setBackTeamAppraisal] = useState(true)
  const handleBackTeamAppraisal = (): void => {
    //console.log();
    setBackTeamAppraisal(!backTeamAppraisal)
  }
  interface ObjectiveApparisal {
    keyresultdetails: keyResultApparisal[];
    assigneeweightage: string;
    createdby: string;
    objectivedescription: string;
    objectiveid: string;
    objectivename: string;
    objectiveStatus: string;
    objectiveweightage: string;
    objid: string;
    orgobjectiveid: string;
    parentid: string;
    quarter: string;
    weightage: string;
    year: string;
    comments: string;
    appraisalstatus: number;
    supervisorstatus: number;
    supervisorremarks: string;
    supervisoratings: number;
    reviewRatings: number;
    reviewRemarks: string;
    reviewStatus: number;
    overallEmployeeRating: number;
    overallSupervisorRating: number;
  }

  interface keyResultApparisal {
    keyresulttitle: string;
    keyresultdescription: string;
    keyresultstatus: string;
    objectiveid: string;
    keyresultid: string;
    objid: string;
    weightage: string;
    krpercentage: string;
    comments: string;
    appraisalstatus: string;
    krid: string;
    supervisorstatus: number;
    supervisoremarks: string;
    supervisoratings: number;
    targetValue: number;
    uomType: string;
    amountType: string;
    krActualPercent: number;
  }

  interface User {
    id: string;
    name: string;
    email: string;
    profilePath: string;
    signoffStatus: number;
    designation: string;
    username: string;
  }

  const [ratings, setRatings] = useState(
    teamAppraisalData.map(() => 3)
  );
  const [text, setText] = useState([' ']);
  // const [krText, setKrText] = useState(
  //   teamAppraisalData.map((obj) => obj.keyresultdetails.map(() => ""))
  // );
  // const [submitted, setSubmitted] = useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const [arrow, setArrow] = useState(
  //   teamAppraisalData.map(() => false)
  // );
  // const [percentValues, setPercentValues] = useState(
  //   teamAppraisalData.map((obj) => obj.keyresultdetails.map(() => ""))
  // );
  const [objectiveRatings, setObjectiveRatings] = useState(
    teamAppraisalData.map(() => "0%")
  );
  const [overallEmployeeRating, setOverallEmployeeRating] = useState<number>(0);
  const [overallSupervisorRating, setOverallSupervisorRating] = useState<number>(0);
  const [ReviewerRatingCount, setReviewerRatingCount] = useState<number>(0);
  const [Reviewertext, setReviewerText] = useState('');
  const maxCharacters = 250;

  const [loading, setLoading] = useState(true);
  const [krText, setKrText] = useState<string[][]>([[]]);
  const [submitted, setSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [arrow, setArrow] = useState<boolean[]>([]);
  const [percentValues, setPercentValues] = useState<string[][]>([[]]);

  useEffect(() => {
    const allRated = ratings.every((rating) => rating > 0);
    const allCommented = text.every((text) => text.length > 0);
    setIsButtonDisabled(!(allRated && allCommented));
  }, [ratings, text]);

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleTextChange = (index: any, event: any) => {
    const newText = [...text];
    newText[index] = event.target.value;

    const newObjectiveRatings = [...teamAppraisalData];
    newObjectiveRatings[index] = {
      ...newObjectiveRatings[index],
      supervisorremarks: event.target.value
    };
    setTeamAppraisalData(newObjectiveRatings);
    if (newObjectiveRatings.every(userdata => userdata.supervisorremarks != null && userdata.supervisorremarks != "")) {
      // console.log("Sdfsdf")
      setIsButtonDisabled(false)
    }
    else {
      setIsButtonDisabled(true)
    }
  };
  // const countAlphabets = (str:any) => {
  //   str = String(str); 
  //   return (str.match(/[a-zA-Z]/g) || []).length;
  // };
  const handleKRTextChange = (objIndex: any, krIndex: any, event: any) => {
    // const newKrTexts = [...krText];
    // newKrTexts[objIndex][krIndex] = event.target.value;
    
    const newObjectiveRatings = [...teamAppraisalData];
    const keyresultdetails = [...teamAppraisalData[objIndex].keyresultdetails];
    // Update the specific keyresultdetails item
    keyresultdetails[krIndex] = {
      ...keyresultdetails[krIndex],
      supervisoremarks: event.target.value
    };
    newObjectiveRatings[objIndex] = {
      ...newObjectiveRatings[objIndex],
      keyresultdetails: keyresultdetails
    };
    console.log("sdfsdf",newObjectiveRatings);
    setTeamAppraisalData(newObjectiveRatings);
  };

  const handleArrow = (index: any) => {
    const newArrow = [...arrow];
    newArrow[index] = !newArrow[index];
    setArrow(newArrow);
  };

  // const handlePercentChange = (
  //   objIndex: number,
  //   krIndex: number,
  //   event: any
  // ) => {
  //   const newPercentValues = [...percentValues];
  //   newPercentValues[objIndex][krIndex] = event.target.value;
  //   setPercentValues(newPercentValues);

  //   // Calculate the percentage based on filled inputs
  //   const filledCount = newPercentValues[objIndex].filter(
  //     (value: any) => value.trim() !== ""
  //   ).length;
  //   const totalCount = newPercentValues[objIndex].length;
  //   const percentage = (filledCount / totalCount) * 100;

  //   // Calculate the average percentage of KR inputs
  //   const sumOfKRValues = newPercentValues[objIndex].reduce((sum: any, value: any) => {
  //     return sum + (value.trim() === "" ? 0 : parseFloat(value));
  //   }, 0);
  //   const averagePercentage = sumOfKRValues / totalCount;

  //   const newObjectiveRatings = [...teamAppraisalData];
  //   const keyresultdetails = [...teamAppraisalData[objIndex].keyresultdetails];

  //   // Update the specific keyresultdetails item
  //   keyresultdetails[krIndex] = {
  //     ...keyresultdetails[krIndex],
  //     supervisoratings: event.target.value
  //   };
  //   newObjectiveRatings[objIndex] = {
  //     ...newObjectiveRatings[objIndex],
  //     supervisorretings: averagePercentage,
  //     keyresultdetails:keyresultdetails
  //   };
  //   setTeamAppraisalData(newObjectiveRatings);
  // };

  // const handlePercentChange = (
  //   objIndex: number,
  //   krIndex: number,
  //   event: any,
  //   type:string
  // ) => {
  //   const newPercentValues = [...percentValues];
  //   console.log("sadsaddas"+newPercentValues);
  //   newPercentValues[objIndex][krIndex] = event.target.value;
    
  //   console.log("percentvalues"+percentValues);
  //   setPercentValues(newPercentValues);
  //   // Calculate the percentage based on filled inputs
  //   const filledCount = newPercentValues[objIndex].filter(
  //     (value) => value.trim() !== ""
  //   ).length;
  //   const totalCount = newPercentValues[objIndex].length;
  //   const percentage = (filledCount / totalCount) * 100;

  //   // Calculate the average percentage of KR inputs
  //   const sumOfKRValues = newPercentValues[objIndex].reduce((sum, value) => {
  //     return sum + (value.trim() === "" ? 0 : parseFloat(value));
  //   }, 0);
  //   const averagePercentage = sumOfKRValues / totalCount;

  //   const newObjectiveRatings = [...teamAppraisalData];
  //   const keyresultdetails = [...teamAppraisalData[objIndex].keyresultdetails];

  //   // Update the specific keyresultdetails item
  //   keyresultdetails[krIndex] = {
  //     ...keyresultdetails[krIndex],
  //     supervisoratings: event.target.value
  //   };
  //   newObjectiveRatings[objIndex] = {
  //     ...newObjectiveRatings[objIndex],
  //     supervisoratings: averagePercentage,
  //     keyresultdetails: keyresultdetails
  //   };
  //   setTeamAppraisalData(newObjectiveRatings)
  // };

  const handlePercentChange = (
    objIndex: number,
    krIndex: number,
    event: any,
    type:string
  ) => {
    const newPercentValues = [...percentValues];
    newPercentValues[objIndex][krIndex] = event.target.value;
    console.log("percentvalues"+percentValues);
    setPercentValues(newPercentValues);
    // Calculate the percentage based on filled inputs
    const filledCount = newPercentValues[objIndex].filter(
      (value) => value.trim() !== ""
    ).length;
    const totalCount = newPercentValues[objIndex].length;
    setTeamAppraisalData((prevData) => {
      return prevData.map((data, index) => {
        if (index === objIndex) {
          const newKeyResultDetails = data.keyresultdetails.map((kr, checkIndex) => {
            if (checkIndex === krIndex) {
              return {
                ...kr,
                supervisoratings: event.target.value,
              };
            }
            return kr;
          });
          const sumTarget = newKeyResultDetails.reduce((sum, kr) => {
            return sum + (kr.supervisoratings === "" ? 0 : parseFloat(kr.supervisoratings));
          },0);
          console.log(sumTarget,"sumtarget");
          const averagePercentage = sumTarget / totalCount;
          console.log(averagePercentage,"averagePercentage");
          return {
            ...data,
            keyresultdetails: newKeyResultDetails,
            supervisoratings: averagePercentage,
          };
        }
        return data;
      })
    });
  };
 

  const calculateAverageRating = () => {
    const totalPercentage = objectiveRatings.reduce(
      (sum, rating) => sum + parseInt(rating),
      0
    );
    return objectiveRatings.length > 0
      ? (totalPercentage / objectiveRatings.length).toFixed(0)
      : 0;
  };

  const handleSubmit = (status: number) => {
    let status_text="";
    if(status==1)
      {
        status_text="save";
      }else{
        status_text="submit";
      }
    Modal.confirm({
      title: `Do you want to ${status_text} Appraisal?`,
      content: (
        <>
          {/* <p className="fs-16 mb-3 keytitle_scroll">"{organizationObjective.objectivename}"</p> */}
          <p className="fs-14 note_alert"><b>Note : </b>Check all objective and KR Rating and description is done</p>
        </>
      ),
      okText: '',
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                saved successfully.
              </span>
            </div>
          ),
          duration: 3,
          icon: null,
        });
        updateteamappraisalremarks(status);

        //onClose();
        // text === "Save" ? createOrganizationObjective("1") :
        //   createOrganizationObjective("3")
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);

        // setTimeout(() => {
        //   // window.location.reload();
        //   bhCreateOrganization();
        // }, 2000);

        // saveobj()
        // console.log('Cascade action confirmed');
      },
      onCancel() {
        //setCasClicked(false);
        //console.log('Cascade action cancelled');
      },
    });
    // if (status === 2) {
    //   setSubmitted(true);
    // }

  };

  const updateteamappraisalremarks = async (status: number) => {
    const api = `${BaseUrl}api/Appraisal/updateremarks/${status}`;
    const AppraisalObjectiveDTO: objapr[] = [];
    const AppraisalKRDTO: krapr[] = [];

    teamAppraisalData.forEach(data => {
      const aprobj: objapr = {
        objectiveid: data.objectiveid,
        objectivepercentage: parseInt(data.objectiveweightage),
        remarks: data.comments,
        userid: TMoptions[currentIndex].id,
        supervisorremark: data.supervisorremarks,
        supervisorretings: data.supervisoratings,
        supervisorstatus: status,
        objrefid: data.objid,
      };
      AppraisalObjectiveDTO.push(aprobj);

      data.keyresultdetails.forEach(krdata => {
        const objid = AppraisalObjectiveDTO.find(obj => obj.objectiveid == krdata.objectiveid)
        const objrefid = objid == undefined ? "" : objid.objrefid
        const objremarks = objid == undefined ? "" : objid.remarks
        const objratings = objid == undefined ? 0 : objid.objectivepercentage
        const krapr: krapr = {
          objectiveid: krdata.objectiveid,
          krid: krdata.keyresultid,
          krpercentage: parseInt(krdata.krpercentage),
          remarks: krdata.comments,
          userid: TMoptions[currentIndex].id,
          supervisorremark: krdata.supervisoremarks,
          supervisorretings: krdata.supervisoratings,
          supervisorstatus: status,
          objrefid: objrefid,
          selfratingsobj: objratings,
          selfremarksobj: objremarks,
          krActualPercent: krdata.krActualPercent,
        };
        AppraisalKRDTO.push(krapr);
      });
    });

    const AppraisalDTO = {
      appraisalKR: AppraisalKRDTO,
      appraisalObjective: AppraisalObjectiveDTO,
      quarter: localStorage.Quarter,
      year: localStorage.year,
      userid: TMoptions[currentIndex].id,
      selfStatus: 2,
      supervisorStatus: status,
    };

    try {
      const resp = await axios.post(api, AppraisalDTO, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      //message.success("Form submitted successfully!");
      editAppraisal(TMoptions[currentIndex].username);
      showOverAllUserAllRating(TMoptions[currentIndex].username);
    } catch (error) {
      // console.error(error);
      message.error("Failed to submit form");
    }
  }

  const editAppraisal = async (employeeId: string) => {
    try {
      const response = await axios.get(BaseUrl + `api/Appraisal/gettingappraisallist/${employeeId}/${localStorage.getItem("Quarter")}/${localStorage.getItem("year")}`);
      setTeamAppraisalData(response.data.data);
      const objdata: ObjectiveApparisal[] = response.data.data;
      // if (objdata.length != 0) {
      //   setOverallEmployeeRating(objdata[0].overallEmployeeRating);
      //   setOverallSupervisorRating(objdata[0].overallSupervisorRating);
      //   setReviewerRatingCount(objdata[0].reviewRatings);
      //   setReviewerText(objdata[0].reviewRemarks);
      // }
      // if (objdata.map(data => data.supervisorstatus == 2)) {
      //   setTeamAppraisal(true)
      // }

      // objdata.map((viewApparaisal) => {
      //   if(viewApparaisal.createdby === TMselectedOption && overAllRating.supervisorStatus === 2) {
      //     setTeamAppraisal(true);
      //   }
      // })

      if (overAllRating.supervisorStatus === 2) {
        setTeamAppraisal(true);
      }
      var keydatas: boolean[] = [];
      var keydatas: boolean[] = [];
      var percentddatas: string[][] = objdata.map(data => data.keyresultdetails.map(() => ""));
      objdata.map(data => data.keyresultdetails.map(keydata => keydatas.push(false)))
      setArrow(keydatas);
      setKrText(percentddatas)
      setPercentValues(percentddatas);
      const newIndex = TMoptions.findIndex((user) => user.username === employeeId);
      setCurrentIndex(newIndex);
      setLoading(false);
      // console.log("index" ,newIndex);
    } catch (error) {
      // console.log(error);
    }
  }

  const showOverAllUserAllRating = async (employeeId: string) => {
    try {
      const apiUrl = BaseUrl + `api/Appraisal/user/overall/rating`;

      const formData = new FormData();
      formData.append("userId", employeeId);
      formData.append("year", '2024-2025');
      formData.append("quarter", 'Q1');
      // formData.append("year", localStorage.getItem('year') || '');
      // formData.append("quarter", localStorage.getItem('Quarter') || '');

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setOverAllRating(response.data.data);
      });
    } catch (error) {
      console.log("ERROR", error);

    }

  }

  const userLists = async () => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisor/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        const response = await axios.get(BaseUrl + `api/users/userListBySupervisor/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTMoptions(response.data);
    } catch (err) {
      // console.error("Error fetching user list:", err);
      setTMoptions([]);
    }
  };
  //const allCommentsPresent = teamAppraisalData.every(userdata => userdata.comments != null) === false ? true : false;
  //setIsButtonDisabled(allCommentsPresent);
  useEffect(() => {
    if (ratings.length > 0 && text.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    userLists();
    showOverAllUserAllRating(employeeId);
    // const allCommentsPresent = teamAppraisalData.every(userdata => userdata.comments != null) === false ? true : false;
    // console.log("get_issue",teamAppraisalData.every(userdata => userdata.comments != null));
    // setIsButtonDisabled(allCommentsPresent);
  }, []);


  useEffect(() => {
    if (TMoptions.length > 0) {
      setTMSelectedOption(userId);
      editAppraisal(employeeId);
    }
  }, [TMoptions]);

  useEffect(() => {
    const allRemarksPresent = teamAppraisalData.every(userdata => userdata.supervisorremarks != null && userdata.supervisorremarks.trim() !== "");
    setIsButtonDisabled(!allRemarksPresent);
    //console.log('Checking supervisorremarks:', teamAppraisalData, allRemarksPresent);
}, [teamAppraisalData]);
  // console.log("get_issue",teamAppraisalData.every(userdata => userdata.supervisorremarks != null));
  // const allCommentsPresent = teamAppraisalData.every(userdata => userdata.supervisorremarks != null) === false ? true : false;
  // setIsButtonDisabled(allCommentsPresent);
  const appraisalItems = (
    <Menu className="SignOff_dropdown">
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/RequestPending.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1"> Appraisal Pending</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/Signoff_Completed.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Appraisal Completed</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/NotRequested.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1"> Not Submitted</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const index = 0;


  return (
    <>
      {
        backTeamAppraisal ? (<>
          <div className="permission_btn team_appraisal">
          {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
            <div className="content" />
          </Spin>}
            <div className="justify-end align-center z-1">
              <Space direction="horizontal" className="z-1 mr-1 appraisal_user_select">
                {/* <Button
                  icon={<LeftOutlined />}
                  onClick={handleTMPrev}
                  style={buttonStyle(isPrevAvailable)}
                  disabled={!isPrevAvailable}
                /> */}
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleTMPrev}
                  style={buttonStyle(isPrevAvailable)}
                  disabled
                />
                <Select
                  className="custom-select w-300"
                  value={TMselectedOption}
                  onChange={handleOptionChange}
                  disabled
                >
                  {TMoptions.map((TMoption) => (
                    <label key={TMoption.id} className="d-flex align-center">
                      <Radio
                        value={TMoption.id}
                        checked={TMselectedOption === TMoption.id}
                      // onChange={handleOptionChange}
                      >
                        <div className="align-center justify-between status_signoff_user">
                          <img
                            src={
                              TMoption.profilePath
                                ? FileUrl + TMoption.profilePath
                                : "assets/images/Default_user.svg"
                            }
                            alt={TMoption.name}
                            className="mr-1 img_36"
                          />
                          <span className="mr-1 signoff_myteam_dd">{TMoption.name}/{TMoption.designation}</span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 2 ? (
                              <img
                                src="assets/images/Signoff_Completed.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 1 ? (
                              <img
                                src="assets/images/RequestPending.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {TMoption.signoffStatus == 0 ? (
                              <img
                                src="assets/images/NotRequested.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </Radio>
                    </label>
                  ))}
                </Select>
                {/* <Button
                  icon={<RightOutlined />}
                  onClick={handleTMNext}
                  style={buttonStyle(isNextAvailable)}
                  disabled={!isNextAvailable}
                /> */}
                <Button
                  icon={<RightOutlined />}
                  onClick={handleTMNext}
                  style={buttonStyle(isNextAvailable)}
                  disabled
                />
              </Space>
              <button className="save_as_draft p-1" onClick={handleBackTeamAppraisal}>Back to Main Page</button>
              {!teamAppraisal ? (
                <>
                  <button className="save_as_draft1 p-1" onClick={() => handleSubmit(1)}>Save as draft</button>
                  
                  <button
                    className={` ${isButtonDisabled ? "cascade_button" : " cyan_button"
                      }`}
                    disabled={isButtonDisabled}
                    onClick={() => handleSubmit(2)}
                  >
                    Submit
                  </button>
                </>
              ) : (
                null
              )}
               
              
              <Dropdown
                overlay={appraisalItems}
                trigger={["click"]}
                className="info_sign_off"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div className="p-1 ">
                      <img
                        src="assets/images/Non-categorized.svg"
                        alt=""
                        className="cur-pointer"
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            </div>
            {teamAppraisalData.length === 0 && (
              <p className="signoff_border c_8080 fs-14">
                Sign-off the objectives to start Appraisal Process
              </p>
            )}
            {!teamAppraisal ? (
              <>
                <div>
                  <div className="appraisal_rating mt-1 mb-2">
                    <Row>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Employee Rating</p>
                        <span className="c_018BB9 fs-30">{overAllRating.overallSelfRating != 0 && overAllRating.selfStatus === 2 ? overAllRating.overallSelfRating + "%" : "-"} </span>
                        {/* <span className="c_018BB9 fs-30">{overallEmployeeRating != 0 ? overallEmployeeRating + "%" : "-"} </span> */}
                        {/* <span className="c_018BB9 fs-30">{`${calculateAverageRating()} %`}</span> */}
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Manager Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.overallSupervisorRating != 0 && overAllRating.supervisorStatus === 2 ? overAllRating.overallSupervisorRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{overallSupervisorRating !=0 ? overallSupervisorRating + "%" : "-" }</span> */}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.reviewerRating != 0 && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{ReviewerRatingCount != 0 ? ReviewerRatingCount + "%" :"-"}</span> */}
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Comment</p>
                        <p className="fs-14 mt-3 ">{overAllRating.reviewerRemarks != "" && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRemarks : "-"}</p>
                        {/* <p className="fs-14 mt-3 ">{Reviewertext != "" ? Reviewertext : "-"}</p> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating_scroll">
                    {teamAppraisalData.map((objective, index) => (
                      objective.quarter == "Q1" && objective.year == "2024-2025" && (
                        <div key={index} className="borderbottom_EAEAEA mt-2">
                          <div className="objective_overall">
                            <table>
                              <thead
                                className={`d-flex ${!submitted
                                  ? "align-center appraisal  pb-2"
                                  : "appraisal  pb-2 "
                                  }`}
                              >
                                <tr className="d-flex">
                                  <th
                                    className={`${!submitted ? "" : "align-center appraisal  pb-2 "
                                      }`}
                                  >
                                    <img
                                      src={
                                        arrow[index]
                                          ? "assets/images/up_arrow_accord.svg"
                                          : "assets/images/down_arrow_accord.svg"
                                      }
                                      alt=""
                                      className="cur-pointer"
                                      onClick={() => handleArrow(index)}
                                    />
                                  </th>
                                  <th>
                                    <div key={objective.objectiveid} className=" pl-2 pr-2">
                                      {/* Objective Card */}
                                      <div className="myobj-card d-flex flex-column justify-between">
                                        <div>
                                          <div className="d-flex justify-between w-100">
                                            <div className="wrapper_label align-center mb-2 ">
                                              <span className="objid_success align-center">
                                                <img
                                                  src="assets/images/White_crown.svg"
                                                  alt=""
                                                  className="pr-1"
                                                />
                                                {objective.objid}
                                              </span>
                                              <span className="sign_off_triangle"></span>
                                            </div>
                                            <div
                                              className={`obj-status-${objective.objectiveStatus}`}
                                            ></div>
                                          </div>
                                          <p className="twoline_text_overflow fs-14">
                                            {objective.objectivename}
                                          </p>
                                        </div>
                                        <div className="d-flex align-center justify-between">
                                          <div className="d-flex align-center">
                                            <>
                                              <img
                                                src="assets/images/Network_Icon.svg"
                                                alt=""
                                              />
                                              <span className="fw-700 c_000">
                                                {objective.weightage}
                                              </span>
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <div
                                      className="text-start"
                                      style={{ width: "490px" }}
                                    >
                                      <label htmlFor="" className="fs-12 fw-700 c_8080 mb-5">
                                        Objective Percentage
                                      </label>
                                      <p className="fs-16 m-0 fw-300">
                                        {objective.objectiveweightage}
                                      </p>
                                    </div>
                                    <div
                                      className="text-start mt-1"
                                      style={{ width: "493px" }}
                                    >
                                      <label htmlFor="" className="fs-12 fw-700 c_8080 mb-5">
                                        Your Comment
                                      </label>
                                      <p className="fs-14 m-0 fs-14 fw-300">
                                        {/* These initiatives to be completed by Mar 2024. Coordinate with concerned departments and get feedback */}
                                        {objective.comments}
                                      </p>
                                    </div>
                                  </th>
                                  <th>
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <div>
                                        <label htmlFor="" className="fs-12 fw-700">
                                          Objective Percentage - Manager
                                        </label>
                                        <p className="fs-16 m-0 fw-300">  {objective.supervisoratings}</p>
                                      </div>
                                      <div className="justify-between mt-1">
                                        <label className="fs-12 fw-700 ">Comments *</label>
                                        <span
                                          style={{
                                            marginTop: "5px",
                                            fontSize: "12px",
                                            color: "gray",
                                          }}
                                        >
                                          {text[index]?.length || 0}/{maxCharacters}
                                        </span>
                                      </div>
                                      <div className="objective-title-inner">
                                        <TextArea
                                          rows={3}
                                          key={index}
                                          placeholder="Write here...."
                                          className="canvas-objective-input w-100"
                                          name="remarks"
                                          value={objective.supervisorremarks}
                                          onChange={(event) =>
                                            handleTextChange(index, event)
                                          }
                                          maxLength={maxCharacters}
                                        />
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              {arrow[index] && (
                                <>
                                  <div className="">
                                    <div key={objective.objectiveid} className=" pl-2 pr-2">
                                      {objective.keyresultdetails.map((kr, krIndex) => (
                                        <tbody key={kr.keyresultid}>
                                          <tr>
                                            <td></td>

                                            <td className=" pl-2 pr-2">
                                              <div className="SignOff_Kr_card d-flex flex-column justify-between p-1 mt-2 mb-2">
                                                <Row className="d-flex justify-between align-center">
                                                  <div className="d-flex">
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_crown.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {objective.objid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span
                                                          className="triangle_left"
                                                          style={{
                                                            borderTop:
                                                              "25px solid #c2fbff",
                                                            borderLeft:
                                                              "28px solid transparent",
                                                          }}
                                                        ></span>
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_KRA_Icon.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {kr.krid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex align-center">
                                                      <div
                                                        className={`obj-status-${kr.keyresultstatus}`}
                                                      ></div>
                                                      <div className="d-flex align-center">
                                                        <>
                                                          <img
                                                            src="assets/images/Network_Icon.svg"
                                                            alt=""
                                                          />
                                                          <span className="c_8080">
                                                            {kr.weightage}
                                                          </span>
                                                        </>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <div className="fs-14 twoline_text_overflow fw-600">
                                                  {kr.keyresulttitle}
                                                </div>
                                                {/* <div className="justify-between">
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Target :</label> <span className="c_000 fs-10">{kr.targetValue}</span>
                                                  </div>
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Achieved :</label> <span className="c_32B7D9 fs-10">{kr.krActualPercent}</span>
                                                  </div>
                                                </div> */}
                                                {kr.uomType != "Custom" &&
                                                  (
                                                    <>
                                                      <div className="justify-between">
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Target :
                                                          </label>{" "}
                                                          <span className="c_000 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Actual :
                                                          </label>{" "}
                                                          <span className="c_32B7D9 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.krActualPercent} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.krActualPercent} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.krActualPercent}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )

                                                }
                                              </div>
                                            </td>
                                            <td>
                                              <div style={{ width: "493px" }}>
                                                <label
                                                  htmlFor=""
                                                  className="fs-12 fw-700 c_8080  "
                                                >
                                                  KR Percentage &nbsp;
                                                </label>
                                                <span>{kr.krpercentage}</span>
                                              </div>
                                              <div>
                                                <label htmlFor="" className="c_8080 fs-12 fw-700">Employee Remarks</label>
                                                <p className="c_121212 fs-12 m-0">{kr.comments}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <label htmlFor="" className="fs-12 fw-700 c_000 mr-1">KR Percentage :</label>
                                              <Input
                                                type="text"
                                                className="percentage_input"
                                                suffix="%"
                                                key={krIndex}
                                                value={kr.supervisoratings}

                                                onChange={(e) => {
                                                  const inputVal = e.target.value;
                                                  const numericRegex = /^([1-9][0-9]?|100)?$/;
                                                  if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                                    handlePercentChange(
                                                      index,
                                                      krIndex,
                                                      e,
                                                      "percentage"
                                                    );
                                                    // handleTextChange(e, setRemarkText, maxWords.description_max_word_count);
                                                    // console.log("inputvaluesdetails", inputVal)
                                                  } else {

                                                  }
                                                }}


                                                min={1}
                                                max={100}
                                                maxLength={3}

                                              />
                                              {/*  onChange={(event) =>
                                                  handlePercentChange(
                                                    index,
                                                    krIndex,
                                                    event
                                                  )
                                                }
                                                maxLength={3} */}
                                              <div
                                                className="objective-title-inner mt-1"
                                                style={{ width: "493px" }}
                                              >
                                                <TextArea
                                                  rows={2}
                                                  placeholder="Write your remarks (optional)"
                                                  className="canvas-objective-input w-100"
                                                  name="remarks"
                                                  value={kr.supervisoremarks}
                                                  onChange={(event) =>
                                                    handleKRTextChange(
                                                      index,
                                                      krIndex,
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="appraisal_rating mt-1 mb-2">
                    <Row>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Employee Rating</p>
                        <span className="c_018BB9 fs-30">{overAllRating.overallSelfRating != 0 && overAllRating.selfStatus === 2 ? overAllRating.overallSelfRating + "%" : "-"} </span>
                        {/* <span className="c_018BB9 fs-30">{overallEmployeeRating != 0 ? overallEmployeeRating + "%" : "-"} </span> */}
                        {/* <span className="c_018BB9 fs-30">{`${calculateAverageRating()} %`}</span> */}
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Manager Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.overallSupervisorRating != 0 && overAllRating.supervisorStatus === 2 ? overAllRating.overallSupervisorRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{overallSupervisorRating !=0 ? overallSupervisorRating + "%" : "-" }</span> */}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={4}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Rating</p>
                        <div className="mt-2">
                          <span className="fs-20 c_121212 fw-700 ml-1">{overAllRating.reviewerRating != 0 && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRating + "%" : "-"}</span>
                          {/* <span className="fs-20 c_121212 fw-700 ml-1">{ReviewerRatingCount != 0 ? ReviewerRatingCount + "%" :"-"}</span> */}
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <p className="c_121212 fs-12 fw-700">Reviewer Comment</p>
                        <p className="fs-14 mt-3 ">{overAllRating.reviewerRemarks != "" && overAllRating.reviewerStatus === 2 ? overAllRating.reviewerRemarks : "-"}</p>
                        {/* <p className="fs-14 mt-3 ">{Reviewertext != "" ? Reviewertext : "-"}</p> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating_scroll">
                    {teamAppraisalData.map((objective, index) => (
                      objective.quarter == "Q1" && objective.year == "2024-2025" && (
                        <div key={index} className="borderbottom_EAEAEA mt-2">
                          <div className="objective_overall">
                            <table>
                              <thead
                                className={`d-flex ${!submitted
                                  ? "align-center appraisal  pb-2"
                                  : "appraisal  pb-2 "
                                  }`}
                              >
                                <tr className="d-flex">
                                  <th
                                    className={`${!submitted ? "" : "align-center appraisal  pb-2 "
                                      }`}
                                  >
                                    <img
                                      src={
                                        arrow[index]
                                          ? "assets/images/up_arrow_accord.svg"
                                          : "assets/images/down_arrow_accord.svg"
                                      }
                                      alt=""
                                      className="cur-pointer"
                                      onClick={() => handleArrow(index)}
                                    />
                                  </th>
                                  <th>
                                    <div key={objective.objectiveid} className=" pl-2">
                                      {/* Objective Card */}
                                      <div className="myobj-card d-flex flex-column justify-between">
                                        <div>
                                          <div className="d-flex justify-between w-100">
                                            <div className="wrapper_label align-center mb-2 ">
                                              <span className="objid_success align-center">
                                                <img
                                                  src="assets/images/White_crown.svg"
                                                  alt=""
                                                  className="pr-1"
                                                />
                                                {objective.objid}
                                              </span>
                                              <span className="sign_off_triangle"></span>
                                            </div>
                                            <div
                                              className={`obj-status-${objective.objectiveStatus}`}
                                            ></div>
                                          </div>
                                          <p className="twoline_text_overflow fs-14">
                                            {objective.objectivename}
                                          </p>
                                        </div>
                                        <div className="d-flex align-center justify-between">
                                          <div className="d-flex align-center">
                                            <>
                                              <img
                                                src="assets/images/Network_Icon.svg"
                                                alt=""
                                              />
                                              <span className="fw-700 c_000">
                                                {objective.weightage}
                                              </span>
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="mr-1 p-1" style={{ background: "#F5F5F5" }}>
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label htmlFor="" className="fs-12 fw-700 c_8080 mb-5">
                                        Objective Percentage
                                      </label>
                                      <p className="fs-16 m-0 fw-300">
                                        {/* 100 % */}
                                        {objective.objectiveweightage}
                                      </p>
                                    </div>
                                    <div
                                      className="text-start"
                                      style={{ width: "493px" }}
                                    >
                                      <label htmlFor="" className="fs-12 fw-700 c_8080 mb-5">
                                        Employee Comment
                                      </label>
                                      <p className="fs-14 m-0 fs-14 fw-300">
                                        {objective.comments}
                                        {/* These initiatives to be completed by Mar 2024. Coordinate with concerned departments and get feedback */}
                                      </p>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="text-start">
                                      <div>
                                        <label htmlFor="" className="fs-12 c_8080 fw-700">
                                          Objective Percentage - Manager
                                        </label>
                                        <p className="fs-16 m-0 fw-300">{objective.supervisoratings}</p>
                                      </div>
                                      <div className="mt-1">
                                        <label className="fs-12 fw-700 c_8080 ">Manager Comment</label>
                                        <p className="fs-14 fw-300 m-0">{objective.supervisorremarks}</p>
                                      </div>

                                    </div>


                                  </th>
                                </tr>
                              </thead>

                              {arrow[index] && (
                                <>
                                  <div className="">
                                    <div key={objective.objectiveid} className=" pl-2 pr-2">
                                      {objective.keyresultdetails.map((kr, krIndex) => (
                                        <tbody key={kr.keyresultid}>
                                          <tr className="d-flex">
                                            <td>&nbsp;</td>

                                            <td className=" pl-2">
                                              <div className="SignOff_Kr_card flex-column p-1">
                                                <Row className="d-flex justify-between align-center">
                                                  <div className="d-flex">
                                                    <div className="container_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_crown.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {objective.objid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="second_kr_label">
                                                      <div className="wrapper_label align-center mb-1">
                                                        <span
                                                          className="triangle_left"
                                                          style={{
                                                            borderTop:
                                                              "25px solid #c2fbff",
                                                            borderLeft:
                                                              "28px solid transparent",
                                                          }}
                                                        ></span>
                                                        <span className="sign_cup_org_bg align-center">
                                                          <img
                                                            src="assets/images/sm_KRA_Icon.svg"
                                                            alt=""
                                                            className="mr-1"
                                                          />
                                                          {kr.krid}
                                                        </span>
                                                        <span
                                                          className="triangle"
                                                          style={{
                                                            borderBottom:
                                                              "25px solid #c2fbff",
                                                          }}
                                                        ></span>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex align-center">
                                                      <div
                                                        className={`obj-status-${kr.keyresultstatus}`}
                                                      ></div>
                                                      <div className="d-flex align-center">
                                                        <>
                                                          <img
                                                            src="assets/images/Network_Icon.svg"
                                                            alt=""
                                                          />
                                                          <span className="c_8080">
                                                            {kr.weightage}
                                                          </span>
                                                        </>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Row>
                                                <div className="fs-14 twoline_text_overflow fw-600">
                                                  {kr.keyresulttitle}
                                                </div>
                                                {/* <div className="justify-between">
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Target :</label> <span className="c_000 fs-10">{kr.targetValue}</span>
                                                  </div>
                                                  <div>
                                                    <label htmlFor="" className="c_8080 fs-10">Achieved :</label> <span className="c_32B7D9 fs-10">{kr.krActualPercent}</span>
                                                  </div>
                                                </div> */}
                                                {kr.uomType != "Custom" &&
                                                  (
                                                    <>
                                                      <div className="justify-between">
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Target :
                                                          </label>{" "}
                                                          <span className="c_000 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.targetValue} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.targetValue} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.targetValue}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label
                                                            htmlFor=""
                                                            className="c_8080 fs-10"
                                                          >
                                                            Actual :
                                                          </label>{" "}
                                                          <span className="c_32B7D9 fs-10">
                                                            {kr.uomType === "Percentage" && <>{kr.krActualPercent} %</>}
                                                            {kr.uomType === "Currency" && <>₹ {kr.krActualPercent} {kr.amountType === "T" && "T"} {kr.amountType === "L" && "L"} </>}
                                                            {kr.uomType === "Numeric" && <>{kr.krActualPercent}</>}
                                                            {kr.uomType === "Custom" && <></>}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )

                                                }
                                              </div>
                                            </td>
                                            <td className="mr-1 p-1" style={{ background: "#F5F5F5" }}>
                                              <div style={{ width: "493px" }}>
                                                <label
                                                  htmlFor=""
                                                  className="fs-12 fw-700 c_8080"
                                                >
                                                  KR Percentage &nbsp;
                                                </label>
                                                <span className="fw-300 fs-12 c_121212">{kr.krpercentage}</span>
                                              </div>
                                              <div>
                                                <label htmlFor="" className="c_8080 fw-700  fs-12">Employee Remarks</label>
                                                <p className="c_121212 fs-14 m-0">{kr.comments}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <label htmlFor="" className="c_8080 fs-12 fw-700">KR Percentage :</label>
                                                <span className="fw-300 fs-16">{kr.supervisoratings} %</span>
                                              </div>
                                              <div>
                                                <label htmlFor="" className="c_8080 fs-12 fw-700">Manager Remarks</label>
                                                <p className="fs-14 c_000 fs-14 m-0">{kr.supervisoremarks}</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>) : (<>
          <TeamAppraisal />
        </>)
      }
    </>
  );
};

export default TeamApprasisalEdit;
