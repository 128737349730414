import React, { useState } from 'react';
import { Table, Button, Input, InputNumber, Space, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';

const { Option } = Select;

interface Employee {
  key: string;
  employeeID: string;
  employeeName: string;
  selfStatus: number;
  supervisorStatus: number;
  reviewerStatus: number;
  selfRating: number;
  supervisorRating: number;
  reviewerRating: number;
  reviewerRemark: string;
  overallRating: number;
}

const initialData: Employee[] = [
  {
    key: '1',
    employeeID: '001',
    employeeName: 'John Doe',
    selfStatus: 1,
    supervisorStatus: 0,
    reviewerStatus: 0,
    selfRating: 4,
    supervisorRating: 3,
    reviewerRating: 0,
    reviewerRemark: 'dahjk cdsjk',
    overallRating: 3,
  },
  {
    key: '2',
    employeeID: '002',
    employeeName: 'Divya',
    selfStatus: 1,
    supervisorStatus: 0,
    reviewerStatus: 0,
    selfRating: 4,
    supervisorRating: 3,
    reviewerRating: 0,
    reviewerRemark: 'frghdah dsj',
    overallRating:10,
  },
];

const InternalReport: React.FC = () => {
  const [data, setData] = useState<Employee[]>(initialData);
  const [editingKey, setEditingKey] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [editedRecord, setEditedRecord] = useState<Partial<Employee>>({});

  const isEditing = (record: Employee) => record.key === editingKey;

  const edit = (record: Employee) => {
    setEditingKey(record.key);
    setEditedRecord({ ...record });
  };

  const Update = () => {
    setData((prevData) =>
      prevData.map((item) => (item.key === editingKey ? { ...item, ...editedRecord } : item))
    );
    setEditingKey('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = initialData.filter((item) =>
      Object.values(item).some((val) => String(val).toLowerCase().includes(value))
    );
    setData(filteredData);
  };

  const handleInputChange = (value: any, key: keyof Employee) => {
    setEditedRecord((prev) => ({ ...prev, [key]: value }));
  };

  const columns: ColumnsType<Employee> = [
    { title: 'Employee ID', dataIndex: 'employeeID', key: 'employeeID' },
    { title: 'Employee Name', dataIndex: 'employeeName', key: 'employeeName' },
    {
      title: 'Self Status',
      dataIndex: 'selfStatus',
      key: 'selfStatus',
      render: (text, record) =>
        isEditing(record) ? (
          <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => handleInputChange(value, 'selfStatus')}>
            <Option value={1}>1</Option>
            <Option value={0}>0</Option>
          </Select>
        ) : (
          text
        ),
    },
    {
      title: 'Supervisor Status',
      dataIndex: 'supervisorStatus',
      key: 'supervisorStatus',
      render: (text, record) =>
        isEditing(record) ? (
          <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => handleInputChange(value, 'supervisorStatus')}>
            <Option value={1}>1</Option>
            <Option value={0}>0</Option>
          </Select>
        ) : (
          text
        ),
    },
    {
      title: 'Reviewer Status',
      dataIndex: 'reviewerStatus',
      key: 'reviewerStatus',
      render: (text, record) =>
        isEditing(record) ? (
          <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => handleInputChange(value, 'reviewerStatus')}>
            <Option value={1}>1</Option>
            <Option value={0}>0</Option>
          </Select>
        ) : (
          text
        ),
    },
    {
      title: 'Self Rating',
      dataIndex: 'selfRating',
      key: 'selfRating',
      render: (text, record) =>
        isEditing(record) ? (
          <InputNumber defaultValue={text} onChange={(value) => handleInputChange(value, 'selfRating')} />
        ) : (
          text
        ),
    },
    {
      title: 'Supervisor Rating',
      dataIndex: 'supervisorRating',
      key: 'supervisorRating',
      render: (text, record) =>
        isEditing(record) ? (
          <InputNumber defaultValue={text} onChange={(value) => handleInputChange(value, 'supervisorRating')} />
        ) : (
          text
        ),
    },
    {
      title: 'Reviewer Rating',
      dataIndex: 'reviewerRating',
      key: 'reviewerRating',
      render: (text, record) =>
        isEditing(record) ? (
          <InputNumber defaultValue={text} onChange={(value) => handleInputChange(value, 'reviewerRating')} />
        ) : (
          text
        ),
    },
    {
      title: 'Reviewer Remark',
      dataIndex: 'reviewerRemark',
      key: 'reviewerRemark',
      render: (text, record) =>
        isEditing(record) ? (
          <Input defaultValue={text} onChange={(e) => handleInputChange(e.target.value, 'reviewerRemark')} />
        ) : (
          text
        ),
    },
    {
      title: 'Overall Rating',
      dataIndex: 'overallRating',
      key: 'overallRating',
      render: (text, record) =>
        isEditing(record) ? (
          <InputNumber defaultValue={text} onChange={(value) => handleInputChange(value, 'overallRating')} />
        ) : (
          text
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={Update} style={{ marginRight: 8 }}>
              Update
            </Button>
            <Button onClick={() => setEditingKey('')}>Cancel</Button>
          </span>
        ) : (
          <Button disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </Space>
      <Table dataSource={data} columns={columns} />
    </div>
  );
};

export default InternalReport;
