import React, { useState,useEffect } from "react";
import {
  Input,
  Avatar,
  Modal,
  Select,
  Tag,
  Button,
  Form, message
} from "antd";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
const { Option } = Select;

interface NewChatProps {
  //onGroupCreated: (objectiveId: string) => void;
  GroupList: (objectiveId: string) => Promise<[]>;
  objectiveId: string;
  objectiveType: string;
}
//onGroupCreated

const NewChat: React.FC<NewChatProps> = ({GroupList,objectiveId, objectiveType }) => {
  const [isNewChatModal, setIsNewChatModal] = useState(false);
  const [chatName, setChatName] = useState("");


  const handleNewChat = () => {
    setIsNewChatModal(true);
    fetchObjectiveTeam();
  };

  const handleOk = () => {
    setIsNewChatModal(false);
  };

  const handleCancel = () => {
    setIsNewChatModal(false);
  };

  const handleClearAll = () => {
    setSelectedMemValues([]);
    setChatName("");
  };

  const handleCreate = async () => {
    // Add logic to create a new chat here
    const formData = new FormData();
    const userObjectIdList: any[] = [];
    selectedMemValues.forEach((member) => {
        if (member.value !== null) {
            userObjectIdList.push({ id: member.value, employeeId: member.label });
        }
    });

    userObjectIdList.forEach((obj, index) => {
        //Object.entries(obj).forEach(([key, value]) => {
            formData.append(`groupMember[${index}]`, String(obj.id));
        //});
    });
    formData.append("groupName", chatName);
    formData.append("objectiveId", objectiveId);
    formData.append("objectiveType", objectiveType);

    try {
      const apiUrl = BaseUrl + `api/cfr/createGroup`;

      const response = await axios.post(apiUrl, formData, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
      })
          .then((response) => {
              // setMemberOption(response.data);
              if (response.status === 200) {
                  message.success({
                      content: (
                          <div className="success-message justify-center align-center p-1">
                              <img src="assets/images/check-circle.svg" alt="Success Icon" />
                              <span className="c_32C6A0 fs-16">
                                 Group Created Successfully
                              </span>
                          </div>
                      ),
                      duration: 1,
                      icon: null,
                  });
                  // window.location.reload();
               
                  setTimeout(() => {
                      // window.location.reload();
                    }, 1000);
                  //empty the values 
                  setSelectedMemValues([]);
                  // setGroup({
                  //     groupname: "",
                  //     assignedusers: []
                  // });
                  setIsNewChatModal(false);
                  //onClose();
                  setTimeout(() => {
                 GroupList(objectiveId);
              }, 500);
              } else if (response.status === 226 || response.status === 500) {

                  message.error({
                      content: (
                          <div className="success-message justify-center align-center p-1 d-flex">
                              {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
                              <span className="c_FF4747 fs-16">
                                  Group Name Exist
                              </span>
                          </div>
                      ),
                      duration: 1,
                      icon: null,
                  });

              } else {
                  // console.log("something went wrong ");
              }

          });
  } catch (error) {
      // console.log("ERROR :::::::::" + error);
  }
   
    setIsNewChatModal(false);
  };

  const handleMemTagClose = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
  };

  interface OptionItem {
    value: string;
    label: string;
    image: string;
  }

  const renderOption = (option: OptionItem) => (
    <div>
      <Avatar size="small" src={option.image} style={{ marginRight: "8px" }} />
      {option.label}
    </div>
  );

  const [Memoptions, setMemOptions] = useState<OptionItem[]>([
    // { value: "value1", label: "renu", image: "assets/images/Avatar1.png" },
    // { value: "value2", label: "rose", image: "assets/images/user.svg" },
    // { value: "value3", label: "harini", image: "assets/images/Avatar1.png" },
    // { value: "value4", label: "tamil", image: "assets/images/user1.svg" },
    // { value: "value5", label: "vicky", image: "assets/images/Avatar1.png" },
    // { value: "value6", label: "priya", image: "assets/images/user2.svg" },
    // { value: "value7", label: "divya", image: "assets/images/Avatar1.png" },
  ]);

  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);


  const fetchObjectiveTeam = async () => {
    try {
      const secondApiUrl = `${BaseUrl}api/cfr/objectiveuserslist/${objectiveId}/${objectiveType}`;
      const response = await axios.get(secondApiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });
      const newMemOptions: OptionItem[] = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
        image: item.image || 'assets/images/Default_user.svg', // Provide a default image path if needed
      }));
      setMemOptions(newMemOptions);
      //return response.data;
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };
  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedMemValues(selectedMemValues);
  };
  useEffect(() => {
    // Call the function to fetch data on component mount or when primaryId changes
     // Replace with actual primaryId
    
  }, []);
  return (
    <>
      
      <Form>
      <img
        src="assets/images/add_user.svg"
        alt=""
        className="cur-pointer"
        onClick={handleNewChat}
      />
      <Modal
        title={<span className=" fs-16 fw-600">New Chat</span>}
        open={isNewChatModal}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <button key="clear" className="cancel_red_btn" onClick={handleClearAll}>
            Clear All
          </button>,
          <button key="create" className="cyan_button"  onClick={handleCreate}>
            Create
          </button>,
        ]}
      >
        
        <div className="assign-section mt-1 mb-3 conversation_newchat">
          <label htmlFor="" className="fw-600 c_32B7D9">
            Add Members *
          </label>
          <br />
          <div className="mt-1">
            <Select
              mode="multiple"
              placeholder={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="assets/images/member.svg"
                    alt="Placeholder"
                    style={{ marginRight: "8px" }}
                  />
                  <span>Select Member</span>
                </div>
              }
              onChange={handleMemSelectChange}
              value={selectedMemValues}
              className="w-100 form-control"
              labelInValue
              optionLabelProp="label"
              dropdownRender={(menu) => <div>{menu}</div>}
            >
              {Memoptions.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {renderOption(option)}
                </Option>
              ))}
            </Select>
          </div>
          <div
            className={selectedMemValues.length > 0 ? "assgin_selected_val" : ""}
          >
            {/* {selectedMemValues.length > 0 ? (
              <span className="c_32B7D9 text_line"></span>
            ) : (
              ""
            )}{" "} */}
            {selectedMemValues.map((value) => (
              <Tag
                key={value.value}
                closable
                onClose={() => handleMemTagClose(value.value)}
                style={{
                  marginRight: "5px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  color: "#000",
                  borderRadius: 15,
                  padding: "5px 10px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={value.image}
                  alt={value.label}
                  style={{ marginRight: "8px" }}
                  className="select_user"
                />
                {value.label}
              </Tag>
            ))}
          </div>
            <div className="mt-1">
            <label htmlFor="" className="fw-600 c_32B7D9 mt-2">
            Name (optional)
          </label>
          <Input
            type="text"
            name="chatName"
            placeholder="Write Name"
            className="mt-1"
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
          />
            </div>
        </div>
        
      </Modal>
      </Form>
    </>
  );
};

export default NewChat;
