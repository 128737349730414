import React, { useEffect, useState } from 'react';
import G6, { IGroup, IShape, ModelConfig, Item } from '@antv/g6';
// import 'antd/dist/antd.css'; // Import Ant Design CSS for tooltip styling
import { Tooltip } from 'antd'; // Import Tooltip component from Ant Design

interface NodeConfig extends ModelConfig {
  img: string;
  id: string;
}

const Explorer_Objectives: React.FC = () => {
  const [graph, setGraph] = useState<any>(null); // Define graph state


  useEffect(() => {
    const container = document.getElementById('obj_container');
    if (!container) return;

    G6.registerNode('treeNode', {
      draw(cfg: any, group: IGroup) {
        const size = 16; // Size of the circular node
        const img = cfg.img; // Image URL for the node

        // Determine fill color based on node state
        const fillColor = '#C6E5FF';
        const strokeColor = '#ccc';

        // Add circular background
        const circle = group.addShape('circle', {
          attrs: {
            x: 0,
            y: 0,
            r: size / 2,
            fill: fillColor, // Node background color
            stroke: strokeColor, // Node border color
            lineWidth: 2, // Node border width
          },
          className: 'node-circle', // Add className attribute
        });

        // Add clip-path for rounded image
        const clip = group.addShape('circle', {
          attrs: {
            x: 0,
            y: 0,
            r: size / 2,
            fill: '#fff', // White fill color to hide the circle
          },
          zIndex: -1, // Set the zIndex to send it to the back
        });

        // Add the image, clipped by the circle
        group.addShape('image', {
          attrs: {
            x: -size / 2,
            y: -size / 2,
            width: size,
            height: size,
            img,
            clip: clip.get('id'), // Set the clip attribute to the ID of the circle shape
          },
        });

        return circle; // Return the shape
      },
    });

    G6.registerEdge('smooth', {
      draw(cfg, group) {
        const { startPoint, endPoint, source, target } = cfg as { startPoint: { x: number; y: number }; endPoint: { x: number; y: number }; source: string; target: string };

        // Get source and target node models from graph data
        const sourceNode = graph.findById(source)?.getModel();
        const targetNode = graph.findById(target)?.getModel();

        // Determine stroke color based on node status
        let strokeColor;
        if (targetNode && targetNode.status === 0) {
          strokeColor = '#47E0FF';
        } else if (targetNode && targetNode.status === 1) {
          strokeColor = '#E05E7880';
        } else if (targetNode && targetNode.status === 2) {
          strokeColor = '#D68C2480';
        } else if (targetNode && targetNode.status === 3) {
          strokeColor = '#32C6A080';
        } else {
          strokeColor = '#ccc';
        }

        // if (sourceNode && sourceNode.status === 0) {
        //   strokeColor = '#47E0FF';
        // } else if (sourceNode && sourceNode.status === 1) {
        //   strokeColor = '#32C6A080';
        // } else if (sourceNode && sourceNode.status === 2) {
        //   strokeColor = '#FF5733';
        // } else if (sourceNode && sourceNode.status === 3) {
        //   strokeColor = '#FFD700';
        // } else {
        //   strokeColor = '#ccc';
        // }

        // if (sourceNode && sourceNode.status === 0 && targetNode && targetNode.status === 0) {
        //   strokeColor = '#47E0FF';
        // } else if (sourceNode && sourceNode.status === 1 && targetNode && targetNode.status === 1) {
        //   strokeColor = '#32C6A080';
        // } else if (sourceNode && sourceNode.status === 2 && targetNode && targetNode.status === 2) {
        //   strokeColor = '#FF5733';
        // } else if (sourceNode && sourceNode.status === 3 && targetNode && targetNode.status === 3) {
        //   strokeColor = '#FFD700';
        // } else {
        //   strokeColor = '#ccc';
        // }

        const hgap = Math.abs((endPoint?.x || 0) - (startPoint?.x || 0));

        const path = [
          ['M', startPoint?.x, startPoint?.y],
          [
            'C',
            (startPoint?.x || 0) + hgap / 4,
            startPoint?.y,
            (endPoint?.x || 0) - hgap / 2,
            endPoint?.y,
            endPoint?.x,
            endPoint?.y,
          ],
        ];

        const shape = group.addShape('path', {
          attrs: {
            path,
            stroke: strokeColor,
            lineWidth: 8, // Increase line width
          },
        });

        return shape;
      },
    });

    const graph = new G6.TreeGraph({
      container,
      width: container.offsetWidth,
      height: container.offsetHeight || 500,
      modes: {
        default: [
          { type: 'collapse-expand' },
          'drag-canvas',
          'zoom-canvas',
        ],
      },
      defaultNode: {
        type: 'treeNode',
      },
      defaultEdge: {
        type: 'smooth',
      },
      layout: {
        type: 'compactBox',
        direction: 'LR',
        getId: (d: any) => d.id,
        getHeight: () => 25,
        getWidth: () => 20,
        getVGap: () => 2,
        getHGap: () => 45,
      },
      // Set the renderer to 'svg'
      renderer: 'svg',
    });

    setGraph(graph);

    const tooltip = new G6.Tooltip({
      offsetX: -40,
      offsetY: -100,
      getContent: (e) => {
        const model = e?.item?.getModel();
        if (model) {
          return `<div class="d-flex justify-content-between custom-tooltip">
                    <div className="tool-img" style="min-width: 90px;">
                      <img src="${model.img}" alt="avatar" width="60" height="60"/>
                    </div>
                    <div className="tool-text">
                      <b>${model.name}</b><br/> 
                      <b>${model.id}</b><br/> 
                      <b>${model.position}</b>
                    </div>
                  </div>`;
        } else {
          return '';
        }
      },
      itemTypes: ['node'],
    });

    graph.addPlugin(tooltip);

    const data = {
      id: '200000004',
      name: 'Carlo',
      position: 'Head',
      img: 'https://bumbeishvili.github.io/avatars/avatars/portrait12.png',
      description: null,
      descriptionZh: null,
      depth: 3,
      subTypeCount: 9,
      status: 0,
      collapsed: false,
      children: [
        {
          id: '500000061',
          name: 'Person',
          position: 'TL',
          img: 'https://bumbeishvili.github.io/avatars/avatars/portrait85.png',
          description: null,
          descriptionZh: null,
          depth: 1,
          subTypeCount: 1,
          status: 0,
          collapsed: true,
          children: [
            {
              id: '707000085',
              name: 'FilmPerson',
              position: 'Developer',
              img: 'https://bumbeishvili.github.io/avatars/avatars/portrait16.png',
              description: null,
              descriptionZh: null,
              depth: 5,
              subTypeCount: 3,
              status: 2,
              collapsed: true,
              children: [
                {
                  id: '707000090',
                  name: 'FilmDirector',
                  position: 'Developer',
                  img: 'https://bumbeishvili.github.io/avatars/avatars/portrait17.png',
                  description: null,
                  descriptionZh: null,
                  depth: 0,
                  subTypeCount: 0,
                  status: 2,
                  collapsed: true,
                  children: [],
                },
                {
                  id: '707000091',
                  name: 'FilmWriter',
                  position: 'Developer',
                  img: 'https://bumbeishvili.github.io/avatars/avatars/portrait86.png',
                  description: null,
                  descriptionZh: null,
                  depth: 4,
                  subTypeCount: 0,
                  status: 3,
                  collapsed: true,
                  children: [],
                },
                {
                  id: '707000092',
                  name: 'FilmStar',
                  position: 'Developer',
                  img: 'https://bumbeishvili.github.io/avatars/avatars/portrait61.png',
                  description: null,
                  descriptionZh: null,
                  depth: 4,
                  subTypeCount: 0,
                  status: 1,
                  collapsed: true,
                  children: [],
                },
              ],
            },
            {
              id: '707000086',
              name: 'MusicPerson',

              img: 'https://bumbeishvili.github.io/avatars/avatars/portrait20.png',
              description: null,
              descriptionZh: null,
              depth: 17,
              subTypeCount: 2,
              status: 1,
              collapsed: true,
              children: [],
            },
          ],
        },
        {
          id: '200000005',
          name: 'Music',
          position: 'TL',
          img: 'https://bumbeishvili.github.io/avatars/avatars/portrait21.png',
          description: null,
          descriptionZh: null,
          depth: 3,
          subTypeCount: 2,
          status: 2,
          collapsed: true,
          children: [],
        },
        {
          id: '707000128',
          name: 'Film',
          position: 'TL',
          img: 'https://bumbeishvili.github.io/avatars/avatars/portrait23.png',
          description: null,
          descriptionZh: null,
          depth: 4,
          subTypeCount: 0,
          status: 0,
          collapsed: true,
          children: [
            {
              id: '7070032328',
              name: 'Comedy',
              position: 'Developer',
              img: 'https://bumbeishvili.github.io/avatars/avatars/portrait1.png',
              description: null,
              descriptionZh: null,
              depth: 4,
              operation: 'C',
              subTypeCount: 0,
              status: 2,
              collapsed: true,
              children: [],
            },
          ],
        },
        {
          id: '707000095',
          name: 'FilmGenre',
          position: 'Developer',
          img: 'https://bumbeishvili.github.io/avatars/avatars/portrait24.png',
          description: null,
          descriptionZh: null,
          depth: 4,
          subTypeCount: 0,
          status: 0,
          collapsed: true,
          children: [],
        },
        {
          id: '702000021',
          name: 'Organization',
          position: 'TL',
          img: 'https://bumbeishvili.github.io/avatars/avatars/portrait26.png',
          description: null,
          descriptionZh: null,
          depth: 47,
          subTypeCount: 1,
          status: 1,
          collapsed: true,
          children: [
            {
              id: '500000063',
              name: 'Company',
              position: 'Developer',
              img: 'https://bumbeishvili.github.io/avatars/avatars/portrait76.png',
              description: null,
              descriptionZh: null,
              depth: 4,
              subTypeCount: 2,
              status: 1,
              collapsed: true,
              children: [
                {
                  id: '707000093',
                  name: 'FilmCompany',
                  position: 'Developer',
                  img: 'https://bumbeishvili.github.io/avatars/avatars/portrait120.png',
                  description: null,
                  descriptionZh: null,
                  depth: 4,
                  subTypeCount: 0,
                  status: 3,
                  collapsed: true,
                  children: [],
                },
                {
                  id: '707000094',
                  name: 'MusicCompany',
                  position: 'Developer',
                  img: 'https://bumbeishvili.github.io/avatars/avatars/portrait118.png',
                  description: null,
                  descriptionZh: null,
                  depth: 2,
                  subTypeCount: 0,
                  status: 3,
                  collapsed: true,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    };

    let previousItem: Item | null = null;
    graph.on('node:click', (evt) => {
      const { item } = evt;
      const model = item?.getModel();
      if (model) {

        // Update the style of the previously clicked node
        if (previousItem) {
          const previousGroup = previousItem.getContainer();
          const previousCircle = previousGroup.findByClassName('node-circle');
          if (previousCircle) {
            previousCircle.attr({
              stroke: '#ccc', // Remove the stroke
              lineWidth: 8, // Set the line width to 0
            });
          }
        }


        const group = item?.getContainer();
        if (group) {
          const circle = group.findByClassName('node-circle');
          if (circle) {
            circle.attr({
              fill: '#000',
              stroke: '#47E0FF',
              lineWidth: 12,
            });
          }
        }

        previousItem = item;
      }
    });

    graph.data(data);
    graph.render();
    graph.fitView();

    const handleResize = () => {
      if (!graph || graph.get('destroyed')) return;
      if (!container || !container.offsetWidth || !container.offsetHeight) return;
      graph.changeSize(container.offsetWidth, container.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      graph.destroy();
    };
  }, []);
    
  const [translation, setTranslation] = useState({ x: 0, y: 0 });

  // Zoom in function
  const handleZoomIn = () => {
    if (graph) {
      const zoomRatio = 1; // You can adjust the zoom ratio as needed
      const currentZoom = graph.getZoom();
      graph.zoomTo(currentZoom + zoomRatio);
  
      // Update translation after zooming
      const newTranslation = { x: translation.x * (1 + zoomRatio), y: translation.y * (1 + zoomRatio) };
      setTranslation(newTranslation);
    } else {
    }
  };
  
  // Zoom out function
  const handleZoomOut = () => {
    if (graph) {
      const zoomRatio = 1; // You can adjust the zoom ratio as needed
      const currentZoom = graph.getZoom();
      graph.zoomTo(currentZoom - zoomRatio);
  
      // Update translation after zooming
      const newTranslation = { x: translation.x / (1 + zoomRatio), y: translation.y / (1 + zoomRatio) };
      setTranslation(newTranslation);
    } else {
    }
  };


const handleExpand = () => {
  const container = document.getElementById('hierarchy_container');
  if (!container) return;

  container.style.height = container.clientHeight === 200 ? '300px' : '200px';
};


  return (
    <div style={{ border:"1px solid #ddd",borderRadius:"5px" }}>
      <img src="assets/images/expand.png" alt=""  className='expand_img p-1 cur-pointer' onClick={handleExpand}/>
        <div id="obj_container"  style={{ width: '100%', height: '200px' }}>
      
        </div>
        <div className='zoom_fun p-1'>
          <img src="assets/images/zoomin.svg" alt="" className='mb-1  cur-pointer' onClick={handleZoomIn} />
          <img src="assets/images/zoomline.svg" alt="" className='' />
          <img src="assets/images/zoomout.svg" alt="" className='mt-1  cur-pointer' onClick={handleZoomOut} />
        </div>

    </div>
  );
};

export default Explorer_Objectives;
