import React from "react";
import { Tabs } from "antd";
import { useEffect,useState } from "react";
import axios from "axios";
import CheckIn from "./MyCheckIn";
import overallCheck from "../../../overallCheck";
import { BaseUrl, FileUrl } from "../../../App";
import TeamCheckIn from "./TeamCheckIn";

const { TabPane } = Tabs;

const CheckInsTab: React.FC = () => {
  //const onChange = (key: string) => {};
  const [activeKey, setActiveKey] = useState<string>('1');
  
  const onChange = (key: string) => {
    // console.log('Tab Changed to:', key);
    setActiveKey(key);
    localStorage.setItem('activeTab', key);
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    RefreshToken();
  }, []);
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)  
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }

  return (
    <div className="relative objective_tab">
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        className="objectivetabs-container sub_tab"
      >
        <TabPane
          tab={
            <div>
              <img src="assets/images/single_user.svg" alt="Crown" />
              <span>My Check In</span>
            </div>
          }
          key="1"
        >
          <div>
            <CheckIn />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div>
              <img src="assets/images/multi_user.svg" alt="CFT" />
              <span>Team Check In</span>
            </div>
          }
          key="2"
        >
          <TeamCheckIn />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CheckInsTab;
