import React, { useEffect } from "react";
import { BaseUrl } from "../../App";

const MicroLogin = () => {
    useEffect(() => {
        const hash = window.location.href.split('#').slice(1).join('#');
        const skr = hash.split('#').slice(1).join('#');
        window.location.href = BaseUrl +"api/auth/callback?"+skr;
    }, []);
          
    return (
        <div>
           {/* <p>hii</p> */}
        </div>
    );
};

export default MicroLogin;