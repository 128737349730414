import React, { useEffect, useState } from "react";
import { Input, Avatar, Modal, Select, Tag, Button, Drawer, message } from "antd";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";


const { Option } = Select;
const { TextArea } = Input;
interface getAllRecognition {
  getAllRecognition:()=>void;
}


const App: React.FC<getAllRecognition> = ({getAllRecognition}) => { 
  const [open, setOpen] = useState(false);
  const [isNewChatModal, setIsNewChatModal] = useState(false);
  const [chatName, setChatName] = useState("");
  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [recognitions, setRecognitions] = useState<MemberRecognition[]>([]);


  interface OptionItem {
    value: string;
    label: string;
    image: string;
  }

  interface MemberRecognition {
    recognitionMessage: string;
    recognitionCreator: string;
    deleteStatus: boolean;
    currentTime: string;
    id: string;
    profilePath: string;
    userId: string;
  }

  const showDrawer = () => {
    setOpen(true);
    userList();
    getAllRecognition_loginuser();
  };

  const onClose = () => {
    setOpen(false);
    setSelectedMemValues([]);
    setChatName("");
  };

  const handleNewChat = () => {
    setIsNewChatModal(true);
  };

  const handleOk = () => {
    setIsNewChatModal(false);
  };

  const handleCancel = () => {
    setIsNewChatModal(false);
  };

  const handleClearAll = () => {
    setSelectedMemValues([]);
    setChatName("");
  };

  const handleCreate = () => {
    createRecognitionAlert(chatName);
    // setSelectedMemValues([]);
    // setChatName("");
    // handleClearAll();
    setIsNewChatModal(false);
  };

  const handleMemTagClose = (valueToRemove: string) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
  };

  const renderOption = (option: OptionItem) => (
    <div>
      <Avatar size="small" src={option.image} style={{ marginRight: "8px" }} />
      {option.label}
    </div>
  );

  const [Memoptions, setMemOptions] = useState<OptionItem[]>([
    { value: "value1", label: "renu", image: "assets/images/Avatar1.png" },
    { value: "value2", label: "rose", image: "assets/images/user.svg" },
    { value: "value3", label: "harini", image: "assets/images/Avatar1.png" },
    { value: "value4", label: "tamil", image: "assets/images/user1.svg" },
    { value: "value5", label: "vicky", image: "assets/images/Avatar1.png" },
    { value: "value6", label: "priya", image: "assets/images/user2.svg" },
    { value: "value7", label: "divya", image: "assets/images/Avatar1.png" },
  ]);

  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedMemValues(selectedMemValues);
  };
  const handleDelete = (recognitionMessage: string, recognitionId  : string) => {
    deleteRecognitionAlert(recognitionMessage, recognitionId);
  };


  const userList = async () => {

    const apiUrl = BaseUrl + `api/users/userListBySupervisor/${localStorage.year}/${localStorage.Quarter}`;

    try {
      await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then((response) => {
        const memberList: OptionItem[] = [];
        response.data.map((user: any) => {
          const member = {
            value: user.id,
            label: user.name + "/" + user.designation + "/" + user.payrollStatus,
            image: user.profilePath != null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'
          }
          memberList.push(member);
        })
        setMemOptions(memberList);
      })
    } catch (error) {
      // console.log('Error', error);
    }
  }

  const createRecognition = async () => {
    try {
      const userIdList: string[] = [];
      selectedMemValues.map((user) => {
        userIdList.push(user.value);
      })
  
      const apiUrl = BaseUrl + `api/recognition/create`;
      await axios.post(apiUrl, {
        "userIdList": userIdList,
        "recognitionMessage": chatName,
        "recognitionCreator": localStorage.getItem("username")
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then((response) => {
      getAllRecognition_loginuser();
      // deleteRecognitionAlert(response.data.data.recognitionMessage, );
      })
    } catch (error) {
      
    }

   
  }

  const deleteRecognition = async (recognitionId: string) => {
    try {
      const apiUrl = BaseUrl + `api/recognition/delete/${recognitionId}`;
      await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then((response) => {
      })

    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  const getAllRecognition_loginuser = async () => {
    try {

      const apiUrl = BaseUrl + `api/recognition/list/${localStorage.getItem("username")}`;
      await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then((response) => {
        setRecognitions(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  const createRecognitionAlert = (recognitionMessage : string) => {
    if(selectedMemValues.length === 0) {
 
      message.error({
        content: (
          <div className="success-message justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
            <span className="c_32C6A0 fs-16">
              Please add some users.
            </span>
          </div>
        ),
        duration: 2,
        icon: null,
      });
    } else if(chatName === "") {
      message.error({
        content: (
          <div className="success-message justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
            <span className="c_32C6A0 fs-16">
              Please enter the message.
            </span>
          </div>
        ),
        duration: 2,
        icon: null,
      });
    } else {
    Modal.confirm({
      title: 'Are you want to create the recognition  ?',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">{recognitionMessage}</p>
        </>
      ),
      okText: 'Create',
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Recognition is Created Successfully
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });
          createRecognition();
          onClose();
          setTimeout(() => {
          getAllRecognition();
          },1000);
      },
      onCancel() {
      },
    });
  }
  }

  const deleteRecognitionAlert = (recognitionMessage : string, recognitionId : string) => {
  Modal.confirm({
    title: 'Do you want to delete this objective ?',
    content: (
      <>
        <p className="fs-16 mb-3 keytitle_scroll">{recognitionMessage}</p>
        <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
      </>
    ),
    okText: 'Delete',
    cancelText: 'Back',
    okButtonProps: { className: 'C_btn' },
    cancelButtonProps: { className: 'outline_btn' },
    centered: true,
    onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Recognition is Deleted Successfully
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        deleteRecognition(recognitionId);
        const updatedRecognitions = recognitions.filter((value, i) => value.id !== recognitionId);
        setRecognitions(updatedRecognitions);
      setTimeout(() => {
        getAllRecognition();
        },1000);
    },
    onCancel() {
    },
  });
}


  return (
    <>
      <div className="d-flex" onClick={showDrawer}>
        <img src="assets/images/category-management.svg" alt="" />
        <p className="member_title m-0 pl-1" style={{ width: "100px" }}>
          Manage Recognition
        </p>
      </div>
      <Drawer
        title="Manage Recognition"
        size="large"
        maskClosable={false}
        onClose={onClose}
        open={open}
        className="objective-canvas assgin_obj"
      >
        <p>New Recognition</p>
        <div className="assign-section mt-1 mb-3 conversation_newchat">
          <label htmlFor="" className="fw-600 c_32B7D9">
            Mention Members *
          </label>
          <br />
          <div className="mt-1">
            <Select
              mode="multiple"
              placeholder={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="assets/images/member.svg"
                    alt="Placeholder"
                    style={{ marginRight: "8px" }}
                  />
                  <span>Select Member</span>
                </div>
              }
              onChange={handleMemSelectChange}
              value={selectedMemValues}
              className="w-100 form-control"
              labelInValue
              optionLabelProp="label"
              dropdownRender={(menu) => <div>{menu}</div>}
            >
              {Memoptions.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {renderOption(option)}
                </Option>
              ))}
            </Select>
          </div>
          <div
            className={
              selectedMemValues.length > 0 ? "assgin_selected_val" : ""
            }
          >
            {selectedMemValues.map((value) => (
              <Tag
                key={value.value}
                closable
                onClose={() => handleMemTagClose(value.value)}
                style={{
                  marginRight: "5px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  color: "#000",
                  borderRadius: 15,
                  padding: "5px 10px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={value.image}
                  alt={value.label}
                  style={{ marginRight: "8px" }}
                  className="select_user"
                />
                {value.label}
              </Tag>
            ))}
          </div>
          <div className="mt-2">
            <div className="justify-between align-center">
              <label htmlFor="" className="fw-600 c_32B7D9 mt-2">
                Display Message
              </label>
              <span className="fs-12 c_8080">{chatName.length}/150</span>
            </div>
            <TextArea
              // id="description"
              rows={3}
              placeholder="Write here...."
              className="canvas-objective-input mt-1"
              name="description"
              value={chatName}
              onChange={(e) => {
                if (e.target.value.length <= 150) {
                  setChatName(e.target.value);
                }
              }}
            />
          </div>
          <div className="mt-2 justify-end">
            <button
              key="clear"
              className="cancel_red_btn"
              onClick={handleClearAll}
            >
              Clear All
            </button>
            <button key="create" className="cyan_button"  onClick={handleCreate}>
              Create
            </button>
          </div>
        </div>
        <div className="recognition-list">
          {recognitions.map((recognition, index) => (
            <div key={index} className="recognition-item">
              <div className="recognition-member justify-between">
                <span className="member">
                  <Avatar size="small" src={recognition.profilePath === "" ? FileUrl + recognition.profilePath : 'assets/images/Default_user.svg'} />
                  <span className="pl-1">{recognition.userId}</span>
                </span>
                <div>
                  <span className="mx-2 fs-12">{recognition.currentTime}</span>
                  <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1 cur-pointer" onClick={() => handleDelete(recognition.recognitionMessage, recognition.id)} />
                </div>
              </div>
              <div className="recognition-message p-1">
                <p>{recognition.recognitionMessage}</p>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default App;
