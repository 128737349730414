import React, { useEffect, useState } from "react";
import { Dropdown, Space, Modal, message, Flex, Spin, Avatar, Tooltip, MenuProps, Row, Col, Card, Input, Menu } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import CreateObj from "../../Modal/CreateObj";
import EditObj from "../../Modal/EditObj";
import axios from "axios";
import { BaseUrl } from "../../../../App";
import CreateParentObj from "../../Modal/CreateParentObj";
import { count, error } from "console";
import Objective from "../Objective";
import { FileUrl } from "../../../../App";
import { StopOutlined } from "@ant-design/icons";


const OrgObjectives: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };

  
  interface orgGroup {
    fieldName: string;
    id: string;
  } 

  // =================Roles And Permission==============================
  const [permission_orgobjview, setPermission_orgobjview] = useState('');
  const [permission_orgobjedit, setPermission_orgobjedit] = useState('');
  const [permission_orgobjcreate, setPermission_orgobjcreate] = useState('');
  const [permission_orgobjdelete, setPermission_orgobjdelete] = useState('');
  const [orgGroupList, setOrgGroupList] = useState<orgGroup[]>([]);
  const [orgGroupselectedOption, setOrgGroupSelectedOption] = useState<string>("");

  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);
      rolePermissionData.map((permission: any) => {
        if (permission.menu === "View") {
          setPermission_orgobjview(permission.org_objective);
        }
        if (permission.menu === "Edit") {
          setPermission_orgobjedit(permission.org_objective);
        }
        if (permission.menu === "Delete") {
          setPermission_orgobjdelete(permission.org_objective);
        }
        if (permission.menu === "Create") {
          setPermission_orgobjcreate(permission.org_objective);
        }
      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // =================Roles And Permission==============================
  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const style: React.CSSProperties = { background: "#fff" };
  const handleDelete = () => {
    Modal.confirm({
      title: "Do you want to delete this objective ?",
      content: (
        <>
          <p className="fs-16 mb-3 delete_objname">
            “{selectedOrganizationObjective?.objectiveName} ”
          </p>
          <p className="fs-14">
            <b>Note : </b>Deleting objective will lead to delete all
            corresponding objectives & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deleteOrganizationObjective();
        bhCreateOrganization();
        // console.log("Delete action confirmed");
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective deleted successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        bhCreateOrganization();
      },

      onCancel() {
        // console.log("Delete action cancelled");
      },
    });
  };

  const [deleteNextLevelObjective, setDeleteNextLevelObjective] = useState();
  const [selectedOrganizationObjective, setSelectedOrganizationObjective] = useState<bhList>();
  const [orgobjid, setorgobjid] = useState<String | null>(null);

  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";


  let disableDelete = false;
  if (selectedOrganizationObjective?.organizationStatus === "2") {
    disableDelete = true;
  }



  const [editable, setEditable] = useState(false);
  const [missionEditable, setMissionEditable] = useState(false);
  const [editSave, setEditSave] = useState(false);
  const [text, setText] = useState("");
  const [missiontext, setMissionText] = useState(
    "“We shall grow significantly better than the industry by fostering innovation and building preferred brands, through passionate and delighted employees”"
  );

  const handleEdit = () => {
    setEditable(true);
  };
  const handleEditSave = () => {
    setEditSave(true);
  };

  const handleMissionEdit = () => {
    setMissionEditable(true);
  };
  const handleMissionEditSave = () => {
    setMissionEditable(true);
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };
  const handleChangeMission = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMissionText(e.target.value);
  };
  const handleMissionSubmit = async () => {
    const apiUrl = BaseUrl + `api/about/missionUpdate/1`;
    const formData = new FormData();
    formData.append("ourMission", missiontext);
    await axios.put(apiUrl, formData,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
      })
    setMissionEditable(false);
    editAbout();
  }
  const handleSubmit = async () => {
    const apiUrl = BaseUrl + `api/about/update/1`;
    const formData = new FormData();
    formData.append("ourVision", text);
    await axios.put(apiUrl, formData,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
      })
    setEditable(false);
    editAbout();
  };

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const editAbout = async () => {
    const apiUrl = BaseUrl + `api/about/edit/1`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`

      }
    }).then((response) => {
      const values = response.data;
      if (values.ourVision === "") {
        setText("Write your Vision Statement here....");
      } else {
        setText(values.ourVision);
      }
      setMissionText(values.ourMission);
    })
  }
  const handleCancel = () => {
    setEditable(false);
    editAbout();
  };
  const handleMissionCancel = () => {
    setMissionEditable(false);
    // setMissionText(missiontext);
    editAbout();
  };
  type getallassignedlist = {
    id: string,
    name: string,
    uid: number,
    year: string,
    enddate: string
    quarter: string,
    description: string,
    remark: string,
    organizationStatus: string,
    createdBy: string,
    createdByEmpId: string,
    createdAt: string,
    updatedAt: string | null,
    startDate: string | null,
    objectivename: string,
    orgObjectiveUsersListList: string[],
  }
  type objectiveuser = {
    obj_name: "",
    obj_code: "",
    obj_desc: "",
    obj_quarter: ""

  }
  type objcetiveuselist = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    mapped_obj: objectiveuser[],
    mapped_obj_count: string,
    objid: string,
    description: string,
  }

  const [Orgobjectiveuserslist, setorgobjectiveuserlist] = useState<objcetiveuselist[]>([
    {
      objname: "",
      objcode: "",
      createdbyname: "",
      quartervalue: "",
      orgobjid: "",
      mapped_obj: [],
      mapped_obj_count: "",
      objid: "",
      description: "",
    }
  ]);

  const rolename = localStorage.getItem("rolename");
  let viewButton = true;
  if (rolename === "BH" || rolename === "FH") {
    viewButton = false;
  }



  const [allorgobjectivelist, setobjectivelist] = useState<getallassignedlist[]>([]);

  const orgobjectiveslist = async () => {
    const objectivelist = await axios.get(BaseUrl + "api/organization/all");
    setobjectivelist(objectivelist.data);
  }

  type bhList = {
    id: string,
    name: string,
    objectCode: string
    organizationStatus: string,
    description: string,
    assignedCount: number,
    objectiveName: string,
    orgObjectiveUsersList: assigndetails[],
    createdByUserName: string,
    restrictDelete: boolean,
    revokestatus: number,
    quarter: string,
    year: string
  }
  type assigndetails = {
    name: string,
    profilePath: string
    userId: string,
    weightage: number,
    availableweightage: number
  }
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [bhOrganizationList, setBhOrganizationList] = useState<bhList[]>([]);
  const bhCreateOrganization = async () => {  
    const roleId = localStorage.getItem("loginId");
    //const apiUrl = BaseUrl + `api/organization/bh/objectives/${roleId}`;
    const apiUrl = BaseUrl + `api/organization/getall`;

    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      if (response.status == 200) {
        setLoading(false)
      }
      setBhOrganizationList(response.data.data);

    }).catch((error) => {
      // console.log('Error :::::::::::::::::', error);

    })
    // console.log("count", bhOrganizationList)

  }

  const deleteOrganizationObjective = async () => {

    const apiUrl = BaseUrl + `api/organization/delete/${orgobjid}`;

    await axios.delete(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`

      }
    }).then((response) => {
      setDeleteNextLevelObjective(response.data.status);
      bhCreateOrganization()
    })
  }
  // const toggleVisibility = (index:number) => {
  //   setIsVisible((prevVisibility) =>
  //   prevVisibility.map((value, i) => (i === index ? !value : value))
  // );
  // }
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);
  const Orgobjectivelist = async () => {
    const token = localStorage.accessToken;
    const userString: string | null = localStorage.getItem("user");
    const userObject = userString ? JSON.parse(userString) : null;
    let useruniqueid = null;

    // Check if userObject is not null before accessing its properties
    if (userObject) {
      useruniqueid = localStorage.loginId;
    } else {
      // console.error("User information not found in localStorage");
    }
    setorgobjectiveuserlist([]);
    const formData = new FormData();
    formData.append("userid", useruniqueid);
    try {
      const response = await axios.post(
        BaseUrl + "api/organization/getassignedobjectivelist",
        formData
      );
      // console.log(response.data);
      setorgobjectiveuserlist((prevList) => [
        ...(response.data as any[]).map((item: any) => {
          isVisible.push(false);

          const mapped_obj = (item.objDetails as any[]).map((detailItem: any) => ({
            // Extract the specific values needed from each detailItem
            obj_name: detailItem.name,
            obj_code: detailItem.objectivecode,
            obj_desc: detailItem.description,
            obj_quarter: detailItem.quarter,
            // Add other properties as needed
          }));

          return {
            objname: item.objectivename,
            objcode: item.objectivecode,
            createdbyname: item.createdByUserName,
            status: "progressing",
            quartervalue: item.orgQuartervalue,
            orgobjid: item.orgObjectiveId,
            mapped_obj_count: item.objcount,
            mapped_obj: mapped_obj,
            objid: "",
            description: item.description,
          };
        }),
      ]);
      // setobjectiveuserlist(response.data.body); 

    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const getOrgGroupList = async () => {
    try {
      const orgLists = await axios.get(BaseUrl + "api/orgGroupMaster/getAll");
      setOrgGroupList(orgLists.data);
      setOrgGroupSelectedOption(orgLists.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // orgobjectiveslist();
    GetUserAccess();
    bhCreateOrganization();
    // Orgobjectivelist();
    editAbout();
    getOrgGroupList();
  }, []);

  const items: MenuProps["items"] = [

    permission_orgobjedit ?
      {
        label: (
          <EditObj orgObjList={bhCreateOrganization} itemId={orgobjid} objectiveName="" orgobjectivecode="" />
        ),
        key: "0",

      } : null,
    // selectedOrganizationObjective?.organizationStatus === "1" ?
    selectedOrganizationObjective?.restrictDelete === false && permission_orgobjdelete ?
      {
        label: (
          <Row
            className="c_FF4747 p-0"
            onClick={handleDelete}
          // disabled={disableDelete}
          >
            <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
            Delete
          </Row>
        ),
        key: "1",
      }
      : null
  ];



  const orgCascadeStatus = (id: string, objectivename: string) => {
    Modal.confirm({
      title: 'Do you want to Cascade this objective ?',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">{objectivename}</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
        </>
      ),
      okText: 'Cascade',
      cancelText: 'Cancel',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Objective Created successfully.
              </span>
            </div>
          ),
          duration: 2,
          icon: null,
        });
        try {
          const apiUrl = BaseUrl + `api/organization/orgStatusUpdate/${id}`;
          axios.put(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
          });
          bhCreateOrganization();
        } catch (error) {
          // console.log("ERROR :::::::::" + error);
        }
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
        // console.log('Cascade action confirmed');
      },
      onCancel() {
        // console.log('Cascade action cancelled');
      },
    });

  }

  type assignUserDetails = {
    name: string,
    username: string,
    email: string,
    designation: string,
    company_id: string,
    profilePath: string,
    objectivestatus: string,
  }

  const [userInformation, setUserInformation] = useState<assignUserDetails[]>([]);

  const userReporteeList = async (userReporteeList: assigndetails[], objectiveid: string | null, type: string) => {
    const userIdList: string[] = userReporteeList.map(reportee => reportee.userId);
    try {
      const apiUrl = BaseUrl + `api/users/assigned/reportees`;

      axios.post(apiUrl, {
        "reporteeUsers": userIdList,
        "objectiveid": objectiveid,
        "type": type
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        const result = response.data.data;
        const reportees: assignUserDetails[] = [];
        result.map((res: any) => {
          const addUser = {
            name: res.name,
            username: res.username,
            email: res.email,
            designation: res.designation,
            company_id: "HEPL",
            profilePath: res.profilePath,
            objectivestatus: res.objectivestatus
          }
          reportees.push(addUser);
        })
        setUserInformation(reportees);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  const [memberActive, setMemberActive] = useState(false);

  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const memberMenu = (
    <Menu className="member_count_div">
      {userInformation.map((user: assignUserDetails, index) => (
        <>
          <Menu.Item key={index}>
            <div className="d-flex align-center">
              <img src={user.profilePath !== null ? FileUrl + user.profilePath : 'assets/images/Default_user.svg'} alt="" className="user" />
              {/* <span className="member_title ml-1">{capitalizeFirstLetter(user.name)}/{user.designation}/{user.company_id}</span> */}
              <span className="member_title ml-1">{user.name}/{user.designation}/{user.company_id}</span>
              {user.objectivestatus == "1" || user.objectivestatus == "2" &&
                <img src="assets/images/single_tick.svg" alt="" />
              }
              {
                user.objectivestatus == "3" &&
                <img src="assets/images/tick_check.svg" alt="" />
              }
            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );

  const handleChangeOrgGroup = async (id: string) => {
    setOrgGroupSelectedOption(id);
    setSelectedFilter(id);
    console.log("id", id);
    const apiUrl = BaseUrl + `api/organization/getall`;
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      console.log("response", response.data.data);
      const filteredData = response.data.data.filter((item: any) =>
         item.organizationGroup === id
      // console.log(item.organizationGroup);
    );
      setBhOrganizationList(filteredData);
      console.log("filteredData", filteredData);
    }).catch((error) => {
      // console.log('Error :::::::::::::::::', error);
    })  
  }
        

  return (
    <div className={viewButton === false && permission_orgobjcreate ? "permission_btn" : " "}>
      {/* <Row className="" justify="end"> */}
      <div className="float-right mb-2"><CreateObj bhCreateOrganization={bhCreateOrganization} /></div>
      {/* </Row> */}
      <Row className="gutter-row  w-100" style={{ justifyContent: 'space-between' }}>
        <Col className="obj_card mb-2" xl={12} lg={12} md={12} sm={24} xs={24}>

          <Row justify="space-between">
            <p className="fs-24 m-0 c_47E0FF fw-600">
              Our <span className="c-000">Vision</span>
            </p>
            {localStorage.rolename === "BH" ? (
              <>
                {editable ? (
                  <img
                    src="assets/images/text_edit.svg"
                    onClick={handleEditSave}
                    alt=""
                  />
                ) : (
                  <img
                    src="assets/images/message-edit.svg"
                    onClick={handleEdit}
                    className="cur-pointer"
                    alt=""
                  />
                )}
              </>
            ) : (null)
            }
          </Row>
          {editable ? (
            <>
              <Row className="text-center obj_textbox justify-center">
                <Input.TextArea
                  value={text}
                  className="fs-16 mt-2 fs_italic canvas-objective-input text-center"
                  onChange={handleChange}
                  placeholder="“We shall grow significantly better than the industry by fostering innovation and building preferred brands, through passionate and delighted employees”"
                />
              </Row>
              <Row className="mt-2 justify-end">
                <button onClick={handleCancel} className="cancel-btn mr-2">
                  Cancel
                </button>
                <button onClick={handleSubmit} className="C_btn">
                  Submit
                </button>
              </Row>
            </>
          ) : (
            <>
              <p className="fs-16 mt-2 fs_italic m-0 vision_scroll text-center">{text ? `“${text}” ` : "Write your Vision Statement here...."}</p>
            </>
          )}
        </Col>
        <Col className="obj_card mb-2" xl={12} lg={12} md={12} sm={24} xs={24}>

          <Row justify="space-between">
            <p className="fs-24 m-0 c_47E0FF fw-600">
              Our <span className="c-000">Mission</span>
            </p>
            {localStorage.rolename === "BH" ? (
              <>
                {missionEditable ? (
                  <img
                    src="assets/images/text_edit.svg"
                    onClick={handleMissionEditSave}
                    alt=""
                  />
                ) : (
                  <img
                    src="assets/images/message-edit.svg"
                    onClick={handleMissionEdit}
                    className="cur-pointer"
                    alt=""
                  />
                )}
              </>
            ) : (null)
            }
          </Row>

          {missionEditable ? (
            <>
              <Row className="text-center obj_textbox justify-center">
                <Input.TextArea
                  value={missiontext}
                  className="fs-20 mt-2 fs_italic canvas-objective-input"
                  onChange={handleChangeMission}
                  placeholder="“We shall grow significantly better than the industry by fostering innovation and building preferred brands, through passionate and delighted employees”"
                />
              </Row>
              <Row className="mt-2 justify-end">
                <button onClick={handleMissionCancel} className="cancel-btn mr-2">
                  Cancel
                </button>
                <button onClick={handleMissionSubmit} className="C_btn">
                  Submit
                </button>
              </Row>
            </>
          ) : (
            <p className="fs-16 mt-2 fs_italic m-0 vision_scroll text-center">{missiontext ? `“${missiontext}” ` : "Write your Mission Statement here...."}</p>
          )}
          {/* <p className="fs-20 mt-2 fs_italic  m-0 vision_scroll">“{missiontext}“</p> */}

        </Col>
      </Row>
      <div className="d-flex mb-2">
        <div className="d-flex align-center mr-1">
          <img src="assets/images/filter-funnel.svg" alt="" />
          <span className="fs-12 pl-1">Filter By</span>
        </div>
        <span className={`${selectedFilter === 'All' ? 'org_obj_filter_btn_Active' : 'org_obj_filter_btn'}`}
        onClick={()=> {
          bhCreateOrganization();
          setSelectedFilter('All');
          }}>All</span>
        {orgGroupList.map((orgGroup) => (
          <span
            key={orgGroup.id} // Ensure each element has a unique key prop
            className={`${selectedFilter ===  orgGroup.id ? 'org_obj_filter_btn_Active' : 'org_obj_filter_btn'}`}
            onClick={() => handleChangeOrgGroup(orgGroup.id)}
          >
            {orgGroup.fieldName}
          </span>
        ))}
        {/* <span className="org_obj_filter_btn">All</span>
        <span className="org_obj_filter_btn">Sales</span>
        <span className="org_obj_filter_btn">Employees or People</span>
        <span className="org_obj_filter_btn">Learning and Innovation</span>
        <span className="org_obj_filter_btn">R&D</span> */}
      </div>
      {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
        <div className="content" />
      </Spin>}
      {/* <Flex className="org_obj_overall pb-2"> */}
      <div className="">

        {/* <Flex> */}
        <Row>
          {bhOrganizationList.length > 0 && bhOrganizationList.map((item) => (
            (item.quarter == quarter && item.year == year &&
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div className="org_obj_card mb-2">
                  <div style={style} className="p-1">
                    <Row className="justify-between">
                      <div className="align-center mb-1">
                        <span className="cup_org_bg align-center">
                          <img src="assets/images/cup.svg" alt="" /> {item.objectCode}
                        </span>
                        <span className="triangle"></span>
                      </div>
                      <div className="align-center mb-1">
                        {/* <Flex justify="space-between" align="center">
                  {item.organizationStatus === "1" && (item.orgObjectiveUsersList.every(user => (user.weightage == 0) && (user.availableweightage >= user.weightage)) && (<>
                    <img src="assets/images/Version_control.svg" alt="" />
                    <span className="c_32B7D9 ml-1 fw-300" onClick={() => orgCascadeStatus(item.id,item.objectiveName)}>Cascade</span>
                   </> ))}
                   </Flex> */}
                        {item.organizationStatus === "1" ? (
                          <>
                            <button className="cascade_btn align-center border-0 p-1 mr-1">
                              <span className="sm_cricle mr-1"></span>
                              Yet to Cascade
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="Progressing_btn align-center border-0 p-1 mr-1">
                              <span className="sm_pro_cricle mr-1"></span>
                              Progressing
                            </button>
                          </>
                        )}

                        {/* <button className="cascade_btn align-center border-0 p-1 mr-1">
                      <span className="sm_cricle mr-1"></span>
                      {item.organizationStatus === "1" ? "Yet to Cascade" : "Progressing"}
                    </button> */}

                        {(permission_orgobjedit || (permission_orgobjdelete)) ? (<>
                          <Dropdown menu={{ items }} trigger={["click"]} >
                            <a onClick={(e) => {
                              e.preventDefault();
                              setorgobjid(e.currentTarget.getAttribute('data-value'))
                            }}
                              href="#hgj" data-value={item.id}>
                              {localStorage.name === item.createdByUserName ? (
                                <Space onClick={(e) => setSelectedOrganizationObjective(item)}>
                                  <img src="assets/images/menu.svg" alt="" />
                                  <input type="hidden" />
                                </Space>
                              ) : (<></>)}
                            </a>

                          </Dropdown>
                        </>) : (<></>)}

                      </div>
                    </Row>
                    <Flex>
                      {(permission_orgobjview) ? (
                        <p className="fs-16 c-000 m-0 cur-pointer  mb-2" onClick={showDrawer}>
                          {/* call the api in edit organization file */}

                          <EditObj orgObjList={bhCreateOrganization} itemId={item.id} objectiveName={item.objectiveName} orgobjectivecode={item.objectCode} />
                        </p>
                      ) : (
                        <>
                          <p className="fs-16 c-000 cur-pointer twoine_text_overflow mb-2" >{item.objectiveName}</p>
                        </>
                      )
                      }
                    </Flex>
                    <Flex>
                      <p className="fs-16  m-0 threeline_text_overflow mb-2">
                        {item.description}
                      </p>
                    </Flex>
                    <Row className="justify-between">
                      <div className="align-center">
                        <span className="mr-1 fs-14">Created by</span>
                        <Tooltip placement="top" title={item.createdByUserName} arrow={true}>
                          <img
                            // src="assets/images/user.svg"
                            // src={`${BaseUrl}${profile}`}
                            onError={handleImageError}
                            src={profilePhoto}
                            className="user cur-pointer"
                            alt=""
                          />
                        </Tooltip>
                      </div>
                      {/* <div className="align-center mt-2 mb-2">
                  <span className="mr-1 fs-14">Assigned to </span>
                  <Avatar.Group
                maxCount={2}
                // size="large"
                maxStyle={{ color: "#000", backgroundColor: "#fff" }}
              >
                <Avatar src="assets/images/Avatar1.png" className="avatar" />
                <Avatar
                  src="assets/images/Avatar2.png"
                  className="avatar"
                ></Avatar>
                <Tooltip title="Ant User" placement="top" className="avatar">
                  <Avatar
                    style={{ backgroundColor: "#fff" }}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
                <Avatar
                  className="avatar"
                  style={{ backgroundColor: "#fff" }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
                </div> */}
                      <div className="align-center mt-2 mb-2">
                        <Dropdown overlay={memberMenu} trigger={['click']}>
                          <a className="ant-dropdown-link" onClick={() => userReporteeList(item.orgObjectiveUsersList, item.id, "orgobjective")}>
                            <Space>
                              <div className="align-center">
                                {
                                  memberActive ?
                                    <img src="assets/images/memcount_active.svg" alt="" />
                                    : <img src="assets/images/membercount.svg" alt="" />
                                }
                                <span className="fw-700 c-000 fs-14 ml-1">
                                  {item.orgObjectiveUsersList.length}
                                  <span className="ml-1">
                                    {item.orgObjectiveUsersList.length > 1 ? "Members" : "Member"}
                                  </span>
                                </span>
                              </div>
                            </Space>
                          </a>
                        </Dropdown>
                        {/* <span className="mr-1 fs-14">Assigned to</span> */}
                        {/* {item.orgObjectiveUsersList.length > 2 ? (
                      <>
                        <Avatar.Group
                          maxCount={2}
                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        >
                          {item.orgObjectiveUsersList.slice(0, item.orgObjectiveUsersList.length).map((user: any, index) => (
                            <>
                             <Tooltip placement="top" title={user.name} arrow={true}>
                              <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar cur-pointer" alt="" onError={handleImageError} />
                              </Tooltip>
                            </>
                          ))}
                        </Avatar.Group>
                      </>
                    ) : (
                      item.orgObjectiveUsersList.map((user: any, index) => (
                        <>
                         <Tooltip placement="top" title={user.name} arrow={true}>
                          <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar cur-pointer" alt="" onError={handleImageError} />
                         </Tooltip>
                        </>
                      ))
                    )
                    } */}
                      </div>
                    </Row>
                    <Flex justify="center" align="center" className="cur-pointer">
                      {item.organizationStatus === "1" && (item.orgObjectiveUsersList.every(user => (user.weightage == 0) && (user.availableweightage >= user.weightage)) && (item.orgObjectiveUsersList.length != 0) && (<>
                        <img src="assets/images/Version_control.svg" alt="" />
                        <span className="c_32B7D9 ml-1 fw-300" onClick={() => orgCascadeStatus(item.id, item.objectiveName)}>Cascade</span>
                      </>))}
                    </Flex>
                  </div>
                </div></Col>)
          ))}
        </Row>
        {/*  */}
        <Row>
          {localStorage.rolename === "FH" &&
            Orgobjectiveuserslist.map((item, index) => (
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div
                  key={index}
                  className="org_obj_card mb-1"
                >
                  <div className="p-1">
                    <Row className="justify-between">
                      <div className="container_label">
                        <div className="wrapper_label align-center mb-1">
                          <span className="cup_org_bg align-center">
                            <img
                              src="assets/images/crown.svg"
                              alt=""
                              className="pr-1"
                            />{" "}
                            {item.objcode}
                          </span>
                          <span className="triangle"></span>
                        </div>
                      </div>
                      <div className="align-center mb-1">
                        <button className="Progressing_btn align-center border-0 p-1 mr-1">
                          <span className="sm_pro_cricle mr-1 "></span>Progressing
                        </button>
                        <img
                          src="assets/images/menu.svg"
                          alt=""
                          className="pl-2"
                        />
                      </div>
                    </Row>
                    <Flex>
                      <p className="fs-16 org_obj_name">{item.objname}</p>
                    </Flex>
                    <Row className="justify-between">
                      {/* <div className="align-center mt-2 mb-2">
                      <span className="mr-1 fs-14">Created by </span>
                      <img
                        src="assets/images/user.svg"
                        className="user"
                        alt=""
                      />
                    </div> */}
                      <div className="align-center mt-2 mb-2">
                        <span className="mr-1 fs-14">Assigned to</span>
                        <Avatar.Group
                          maxCount={2}
                          // size="large"
                          maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                        >
                          <Avatar
                            src="assets/images/Avatar1.png"
                            className="avatar"
                          />
                          <Avatar
                            src="assets/images/Avatar2.png"
                            className="avatar"
                          ></Avatar>
                          <Tooltip
                            title="Ant User"
                            placement="top"
                            className="avatar"
                          >
                            <Avatar
                              style={{ backgroundColor: "#fff" }}
                              icon={<UserOutlined />}
                            />
                          </Tooltip>
                          <Avatar
                            className="avatar"
                            style={{ backgroundColor: "#fff" }}
                            icon={<AntDesignOutlined />}
                          />
                        </Avatar.Group>
                      </div>
                    </Row>
                  </div>
                  <Flex className="p-2" justify="space-between" align="center">
                    <Flex className="cur-pointer">
                      <img src="assets/images/Network.svg" alt="" />
                      <span className="p-1 c-000 fs-12">Network</span>
                    </Flex>
                    <Flex>
                      <img src="assets/images/message-chat.svg" alt="" />
                      <span className="p-1 c-000 fs-12">Discussion</span>
                    </Flex>
                    {/* <Flex onClick={()=>toggleVisibility(index)}  className="cur-pointer">
                      <img src="assets/images/layer.svg" alt="" />
                      <span className="p-1 c-000 fs-12">{item.mapped_obj_count}</span>
                    </Flex> */}
                    <Flex className="cur-pointer">
                      <CreateParentObj
                        objectivelist={bhCreateOrganization}
                        objectcode={item.objcode}
                        objectname={item.objname}
                        orgQuartervalue={item.quartervalue}
                        orgobjid={item.orgobjid}
                        objid={item.objid}
                        year={item.orgobjid}
                        objective_id={null}
                        orgdescription={item.description}
                        button="+ Create Parent Objective"
                        header="Create Parent Objective" />
                    </Flex>
                  </Flex>
                  <Flex align="center" className={isVisible[index] ? "objective_bg_active" : ""}>
                    {isVisible[index] && (item.mapped_obj.map((objectives, values) =>
                      <Flex>

                        <div className="subobjective_box">
                          <Flex className="p-2" justify="space-between" align="center">
                            <Flex align="center">
                              <img src="assets/images/crown.svg" alt="" />
                              <span className="fs-14 pl-1 c-000">{objectives.obj_name}</span>
                            </Flex>
                            <div>
                              <span className="progress_btn">
                                {" "}
                                <img
                                  src="assets/images/progress-Idicator.svg"
                                  alt=""
                                />{" "}
                                Progressing
                              </span>
                              <img
                                src="assets/images/menu.svg"
                                alt=""
                                className="pl-2"
                              />
                            </div>
                          </Flex>
                          <Flex className="p-2 fs-16 c-000">
                            {objectives.obj_desc}
                          </Flex>
                          <Flex className="p-2" justify="space-between" align="center">
                            <img
                              src="assets/images/user.svg"
                              // src={profilePhoto}
                              className="user" alt="" />
                            <Avatar.Group
                              maxCount={2}
                              // size="large"
                              maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                            >
                              <Avatar
                                src="assets/images/Avatar1.png"
                                className="avatar"
                              />
                              <Avatar
                                src="assets/images/Avatar2.png"
                                className="avatar"
                              ></Avatar>
                              <Tooltip
                                title="Ant User"
                                placement="top"
                                className="avatar"
                              >
                                <Avatar
                                  style={{ backgroundColor: "#fff" }}
                                  icon={<UserOutlined />}
                                />
                              </Tooltip>
                              <Avatar
                                className="avatar"
                                style={{ backgroundColor: "#fff" }}
                                icon={<AntDesignOutlined />}
                              />
                            </Avatar.Group>
                          </Flex>
                          <Flex className="p-2" justify="space-between" align="center">
                            <Flex className="cur-pointer">
                              <img src="assets/images/Network.svg" alt="" />
                              <span className="p-1 c-000 fs-12">Network</span>
                            </Flex>
                            <Flex>
                              <img src="assets/images/message-chat.svg" alt="" />
                              <span className="p-1 c-000 fs-12">Discussion</span>
                            </Flex>
                          </Flex>
                        </div>

                      </Flex>
                    )
                    )}
                  </Flex>
                </div>
              </Col>
            ))
          }
        </Row>
        {/* </Flex> */}
      </div>

      {/* </Flex> */}
      {/* <Row className="gutter-row mt-2" >
      {allorgobjectivelist.map(item=>(
        <Col className="org_obj_card mb-2" xl={8} lg={8} md={12} sm={24} xs={24}>
          <div style={style}>
            <Row className="justify-between">
              <div className="align-center mb-1">
                <span className="cup_org_bg align-center">
                  <img src="assets/images/cup.svg" alt="" /> {item.name}
                </span>
                <span className="triangle"></span>
              </div>
              <div className="align-center mb-1">
                <button className="cascade_btn align-center border-0 p-1 mr-1">
                  <span className="sm_cricle mr-1"></span>Yet to Cascade
                </button>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <a onClick={(e) =>{ e.preventDefault();setorgobjid(e.currentTarget.getAttribute('data-value'))}} href="#hgj" data-value={item.id.toString()}>
                    <Space>
                      <img src="assets/images/menu.svg" alt="" />
                      <input type="hidden" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </Row>
            <Flex>
              <p className="fs-20">
               {item.objectiveName}
              </p>
            </Flex>
            <Flex>
              <p className="fs-16">
                {item.description}
              </p>
            </Flex>
            <Row className="justify-between">
              <div className="align-center mt-2 mb-2">
                <span className="mr-1 fs-14">Created by </span>
                <img src="assets/images/user.svg" className="user" alt="" />
              </div>
              <div className="align-center mt-2 mb-2">
                <span className="mr-1 fs-14">Assigned to</span>
                <Avatar.Group
                  maxCount={2}
                  // size="large"
                  maxStyle={{ color: "#000", backgroundColor: "#fff" }}
                >
                  <Avatar src="assets/images/Avatar1.png" className="avatar" />
                  <Avatar
                    src="assets/images/Avatar2.png"
                    className="avatar"
                  ></Avatar>
                  <Tooltip title="Ant User" placement="top" className="avatar">
                    <Avatar
                      style={{ backgroundColor: "#fff" }}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                  <Avatar
                    className="avatar"
                    style={{ backgroundColor: "#fff" }}
                    icon={<AntDesignOutlined />}
                  />
                </Avatar.Group>
              </div>
            </Row>
            <Row className="justify-center">
              <button className="c_47E0FF border-0 bg-transparent fs-14">
                + Add Objective
              </button>
            </Row>
          </div>
        </Col>
    ))}
     </Row> */}
      {bhOrganizationList.length === 0 &&
        <Card className="obj_nc_card">
          <Row className="text-center obj_textbox">
            <span className="c_AAA fs-14 fw-600">
              Organisation objectives not Created yet.
            </span>
          </Row>
        </Card>
      }
      <br />
    </div>
  );
};

export default OrgObjectives;
