import React, { useEffect, useState } from "react";
import { Row, Dropdown, Space, Progress, Tooltip, Col, Modal, message,Menu } from "antd";
import type { MenuProps } from "antd";
import { BaseUrl } from "../../../App";
import axios from "axios";
import { boolean } from "zod";
import KRDescription from "../Modal/KRDescription";

interface KRCheckIn {
  id: string;
  objectiveId: string;
  keyResultId: string;
  userId: string;
  target: number;
  achievedTarget: number;
  remainingTarget: number;
  uomType: string;
  checkInStatus: number;
  checkInWeek: string;
  checkWeekCount: string;
  krCascadeStatus: number;
  amountType: string;
  frequencyType: string;
  completedTarget: number;
}

interface KeyResult {
  krId: string;
  krName: string;
  objId: string;
  krTitle: string;
  progress: number | null;
  frequency: "Weekly" | "Fortnightly" | "Monthly";
  krCheckInList: KRCheckIn[];
}

interface Objective {
  objId: string;
  objectiveName: string;
  objTitle: string;
  krCheckIn: KeyResult[];
}
type krdescription = {
  objname: string,
  objid: string,
  krid: string,
  krtitle: string
}

const MyCheckIn: React.FC = () => {
  const [openObjectives, setOpenObjectives] = useState<{
    [key: string]: boolean;
  }>({});
  const [checkinWeekList, setCheckinWeekList] = useState<string[]>([]);
  const [objectivesData, setObjectivesData] = useState<Objective[]>([]);
  const [keyresultid, setkeyresultids] = useState<krdescription>({
    objname: "",
    objid: "",
    krid: "",
    krtitle: ""
  });
  const onClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const krshowDrawer = (objective: any) => {
    setOpen(true)
    setkeyresultids({
      ...keyresultid,
      krid: objective.id,
      krtitle: objective.keytitle,
      objname: "",
      objid: ""
    })

  };

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
 
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  const toggleKeyResults = (objectiveId: string) => {
    console.log("Toggling key results for:", objectiveId);
    setOpenObjectives((prev) => {
      const updatedState = {
        ...prev,
        [objectiveId]: !prev[objectiveId],
      };
      console.log("Updated openObjectives state:", updatedState);
      return updatedState;
    });
  };

  const showCheckinDateList = async () => {
    try {
      const today = new Date();
      const formData = new FormData();
      formData.append("currentMonth", today.getMonth().toString());
      formData.append("year", today.getFullYear().toString());
      formData.append("frequency", "Weekly");

      const apiUrl = `${BaseUrl}api/checkin/date/list`;

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setCheckinWeekList(response.data.data);
    } catch (error) {
      console.error("Error fetching check-in dates:", error);
    }
  };

  const showObjectivesAndKeyResults = async () => {
    try {
      const userId = localStorage.getItem("loginId");

      let today: Date = new Date();

      const formData = new FormData();
      formData.append("currentMonth", today.getMonth().toString());
      formData.append("year", today.getFullYear().toString());
      formData.append("userId", userId || '');
      formData.append("quarter", localStorage.Quarter)
      formData.append("filteryear", localStorage.year)

      const apiUrl = `${BaseUrl}api/checkin/overall/view`;
      const response = await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setObjectivesData(response.data.data);
    } catch (error) {
      console.error("Error fetching objectives and key results:", error);
    }
  };
  const deletekeyresult = async (kr: krdescription) => {

    const apiUrl = `${BaseUrl}api/keyresult/delete/${kr.krid}`;

    await axios.put(apiUrl, {
    }).then((response) => {
    })
    //window.location.reload();
  }
  const handleDeleteKR = (kr: krdescription) => {
    Modal.confirm({
      title: "Do you want to delete this keyresult ?",
      content: (
        <>
          <p className="fs-16 fs-16 mb-3">

            {kr?.krtitle}
            {/* “ Achieve a 20% reduction in average server response time. ” */}
          </p>
          <p className="fs-16 fs-14">
            <b>Note : </b>Deleting Keyresult will lead to delete all
            corresponding leadMeasure & key results permanently.
          </p>
        </>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { className: "C_btn" },
      cancelButtonProps: { className: "outline_btn" },
      centered: true,
      onOk() {
        deletekeyresult(kr);
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                KeyResult deleted successfully.
              </span>
            </div>
          ),
          duration: 1,
          icon: null,
        });
        // window.location.reload();
        onClose()
      },
      onCancel() {
      },
    });
  };
  const items1 = [
    // {

    //    label:(
    //     <Row
    //     // className="c_FF4747 fs-14 p-0"
    //   >
    //      <KRDescription open={open} setOpen={setOpen} onClose={onClose} krid={descriptionvalues?.krid || ''} objname={descriptionvalues?.objname || ''} objid={descriptionvalues?.objid || ''} availableweightagekr={availableweightagekr} year={objectivekeys.year} /> 
    //      </Row>),
    //      key: '0',
    // },

    {
      label: (
        // <button className="border-0 bg-transparent fs-14 align-center">
        <KRDescription objectivesdetailsgets={showObjectivesAndKeyResults}  getassignedkeyforobjectives={showObjectivesAndKeyResults} getteamkeyresult={showObjectivesAndKeyResults} objectivelist={showObjectivesAndKeyResults} krid={keyresultid?.krid} krtitle={""} objname={""} objid={""} obj_code={""} availableweightagekr={0} year={""} orgdescription={""} objectivetype={"objective"} objective={""} krcode={""} checkInFrequency={""} uomType={""} objquarter = {""} tabActiveKey="1" selectedkrvalue=""/>
        // <KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(keycard, keystatusIndex) }} getassignedkeyforobjectives={() => getassignedkeyforobjectives(objectivekeys.obj_id, localStorage.loginid)} getteamkeyresult={() => getteamkeyresult(objectivekeys.obj_id, localStorage.username)} objectivelist={objectivelist} krid={keyresultid?.krid} krtitle={""} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} obj_code={objectivekeys.obj_code} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} krcode="" checkInFrequency="" uomType="" objquarter = {objectivekeys.obj_quarter}/>
        // </button>
      ),
      key: "0",
    },
    {
      label: (
        <Row
          className="c_FF4747 fs-14 p-0"
          onClick={() => { handleDeleteKR(keyresultid) }}
        >
          <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
          Delete
        </Row>
      ),
      key: '1',
    },

  ];
  const menu = (
    <Menu>
      {items1.map(item => (
        
          (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          )
      ))}
    </Menu>
  );
  useEffect(() => {
    showCheckinDateList();
    showObjectivesAndKeyResults();
  }, []);

  const renderWeeks = (frequencyType: string, keyResult: KeyResult) => {
    const frequencyMap: { [key: string]: number } = {
      Weekly: 1.0,
      Fortnightly: 2.5,
      Monthly: 4.5,
    };
  
    const step = frequencyMap[frequencyType];
    const cells = [];
  
    // Ensure the loop stops correctly without going out of bounds
    for (let i = 0; i < checkinWeekList.length; i += step) {
      let iconSrc = "assets/images/grey_circle.svg";
      let isVideo = false;
      let weekRange = checkinWeekList[i];
      
      // Determine if the current step is within the bounds of the list
      const isLastStep = i + step > checkinWeekList.length;
  
      // Find if there is any check-in within this week range
      var uomtype;
      const hasCheckIn = keyResult.krCheckInList.some((kr) => {
        var checkInWeek=kr.checkInWeek;

        if(checkInWeek!="Custom"){
          checkInWeek=kr.checkInWeek.split("/")[0]
        }
        else{
          uomtype=checkInWeek;
        }
        return checkInWeek === (i / step + 1).toString();
      });
  
      if (hasCheckIn&&uomtype!="Custom"){
        const checkInStatus = keyResult.krCheckInList.find(
          (kr) => kr.checkInWeek.split("/")[0] === (i / step + 1).toString()
        )?.checkInStatus;
  
        if (checkInStatus === 1) {
          iconSrc = "assets/images/tick_circle.svg";
        } else if (checkInStatus === 3) {
          iconSrc = "assets/images/BlinkLoader.mp4";
          isVideo = true;
        }
      }
      else if(uomtype=="Custom"){
        iconSrc = "assets/images/tick_circle.svg";
      }
  
      cells.push(
        <td key={i} colSpan={step} className="week-cell">
          <div className="float-right">
            {isVideo ? (
              <video src={iconSrc} autoPlay loop muted width="32" height="32" />
            ) : (
              <img src={iconSrc} alt="check-in status" />
            )}
          </div>
        </td>
      );
  
      // Handle the case where the final weeks might not fit exactly into the step size
      if (isLastStep && checkinWeekList.length % step !== 0) {
        break;
      }
    }
  
    return cells;
  };
  
  const getCurrentWeekIndex = () => {
    const today = new Date();
    const currentWeek = Math.ceil((today.getDate() - 1) / 7);
    return currentWeek - 1;
  };
  const renderKeyResults = (keyResults: KeyResult[], objTitle: string) => {
    return keyResults.map((kr) => {
      var completedTarget = kr.krCheckInList.reduce((acc, cur) => acc + cur.achievedTarget, 0);
        kr.krCheckInList.map((convertValue) => {
          if((convertValue.uomType === "Numeric" || convertValue.uomType === "Currency") && convertValue.target !== 0.0 ) {
            completedTarget = Math.round((completedTarget/convertValue.target)* 100);            
          }
        })
      return (
        <tr key={kr.krId} className="key-result-row">
          <td>
            <div className="mykr_weightage_card d-flex flex-column justify-between p-1 mt-2 mb-2">
              <Row className="d-flex justify-between align-center">
                <div className="d-flex">
                  <div className="container_label">
                    <div className="wrapper_label align-center mb-1">
                      <span className="cup_org_bg align-center">
                        <img src="assets/images/crown_kr_sm.svg" alt="" />
                        <span className="fs-10 ml-1">{objTitle}</span>
                      </span>
                      <span className="triangle"></span>
                    </div>
                  </div>
                  <div className="second_kr_label">
                    <div className="wrapper_label align-center mb-1">
                      <span className="triangle_left"></span>
                      <span className="cup_org_bg align-center">
                        <img src="assets/images/kr_sm.svg" alt="" />
                        <span className="fs-10 ml-1">{kr.krTitle}</span>
                      </span>
                      <span className="triangle"></span>
                    </div>
                  </div>
                </div>
                <div className="fs-10 ml-7"><img src="assets/images/checkin_Status.svg" alt="" /></div>

                {/* <div className="d-flex align-center">
                <KRDescription objectivesdetailsgets={showObjectivesAndKeyResults} getassignedkeyforobjectives={showObjectivesAndKeyResults} getteamkeyresult={showObjectivesAndKeyResults} objectivelist={showObjectivesAndKeyResults} krid={kr.krId} krtitle={""} objname={objTitle} objid={""} obj_code={""} availableweightagekr={0} year={""} orgdescription={""} objectivetype={"objective"} objective={""} krcode={""} checkInFrequency={""} uomType={""} objquarter = {""}/>
                </div> */}
              </Row>
              <div className="fs-12 obj_kr_desc fw-600 text-start">
                <span>{kr.krName}</span>
              </div>
              <Row className="mt-1 mb-1 align-center">
                <Col xl={21} lg={21} md={22} sm={22}>
                  <div className="custom-progress-bar">
                    <div className="progress-content">
                      <Progress
                        type="line"
                        percent={completedTarget}
                        strokeColor="#00CFFF"
                        trailColor="#E0E0E0"
                        showInfo={false}
                      />
                      <div className="progress-dots">
                        <Tooltip title="0%" placement="bottom">
                          <span className="dot" style={{ left: "0%" }}></span>
                        </Tooltip>
                        {completedTarget > 0 && completedTarget < 100 && (
                          <Tooltip
                            title={`${completedTarget.toFixed(2)}%`}
                            placement="bottom"
                          >
                            <span
                              className="dot"
                              style={{
                                left: `calc(${completedTarget.toFixed(2)}% - 4px)`,
                              }}
                            ></span>
                          </Tooltip>
                        )}
                        <Tooltip title= {100} placement="bottom">
                          <span className="dot" style={{ left: "100%" }}></span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={2} sm={2}>
                  <span className="progress-percentage fs-14">
                    {isNaN(completedTarget)?0:Math.round(completedTarget * 100) / 100}%
                  </span>
                  {/* <Dropdown overlay={menu} trigger={['click']}>
                                                      <a onClick={(e) => setKrValues(e, objective)} href="#hgj"
                                                      >
                                                        {localStorage.loginId == objective.createdBy && userSignOff.signOffStatus === 0
                                                          // || (objective.keyResultUsersListAssignedTo.map((krSignOff) => krSignOff.keyid === objective.id && krSignOff.signOffStatus === 0) && userSignOff.signOffStatus === 0) 
                                                          &&
                                                          <Space>
                                                            <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                                                            <input type="hidden" />
                                                          </Space>
                                                        }
                                                      </a>
                                                    </Dropdown> */}
                </Col>
                
              </Row>
            </div>
          </td>
          {renderWeeks(kr.frequency, kr)}
  
        </tr>
      );
    });
  };

  const items: MenuProps["items"] = [
    // {
    //   label: (
    //     <Row className="c_FF4747 fs-14 p-0">
    //       <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
    //       Delete
    //     </Row>
    //   ),
    //   key: "0",
    // },
    // {
    //   label: (
    //     <Row className="c_FF4747 fs-14 p-0">
    //       <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
    //       Delete
    //     </Row>
    //   ),
    //   key: "1",
    // },
    // {
    //   type: "divider",
    // },
    // {
    //   label: "3rd menu item（disabled）",
    //   key: "3",
    //   disabled: true,
    // },
  ];

  return (
    <div className="checkin">
      <div className="table-container">
        <table className="objective-table">
          <thead>
            <tr>
              <th style={{ width: "368px" }}></th>
              {checkinWeekList.map((week, index) => (
                <th
                  key={index}
                  style={{
                    color:
                      index === getCurrentWeekIndex() ? "#32B7D9" : "",
                  }}
                >
                  <span className={`fs-10 fw-700 ${ index === getCurrentWeekIndex() ? 'c_32B7D9' : 'c_888'}`}
                 style={{
                  position: "relative",
                  display: "inline-block",
                  paddingTop: "10px" // Adjust to provide space for the pseudo-element
                    }}> Week {index + 1}
                  <span
                    style={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: "0",
                      right: "0",
                      height: "4px",
                      backgroundColor: index === getCurrentWeekIndex() ? "#32B7D9" : "transparent",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                      zIndex: -1
                    }}
                    ></span>
                  </span>{" "}
                  <br />{" "}
                  <span
                    className={`fs-10 fw-300 ${
                      getCurrentWeekIndex() === index ? "c_32B7D9" : "c_8080"
                    }`}
                  >
                    {week}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {objectivesData && objectivesData.map((objective: any) => (
              <>
                {objective.krCheckIn.length > 0 &&  objective.year==year&& objective.quarter==quarter&& (
                  <>
                    <React.Fragment key={objective.objId}>
                      <tr className="objective-row">
                        <td colSpan={13} className="text-start">
                          <div className="d-flex align-center">
                            <div className="container_label">
                              <div className="wrapper_label align-center ">
                                <span className="cup_org_bg align-center c_000">
                                  <img
                                    src="assets/images/crown.svg"
                                    alt=""
                                    className="pr-1"
                                  />
                                  <span className="fs-14">
                                    {objective.objTitle}
                                  </span>
                                </span>
                                <span className="triangle"></span>
                              </div>
                            </div>
                            <div className="fs-16 fw-700">
                              {objective.objectiveName}
                            </div>
                          </div>
                        </td>
                        <td
                          className="text-end"
                          onClick={() => toggleKeyResults(objective.objId)}
                        >
                          {openObjectives[objective.objId] ? (
                            <>
                              <img
                                src="assets/images/arrow-circle-down.svg"
                                className="cur-pointer"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src="assets/images/arrow-circle-up.svg"
                                className="cur-pointer"
                              />
                            </>
                          )}
                        </td>
                      </tr>
                      {openObjectives[objective.objId] &&
                        renderKeyResults(
                          objective.krCheckIn,
                          objective.objTitle
                        )}
                    </React.Fragment>
                  </>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyCheckIn;
