import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, message,Spin, Button  } from "antd";
import "./LoginPage.css";
import { BaseUrl } from "../../App";
import axios from "axios";
import { Link } from 'react-router-dom';

const onFinish = (values: any) => {
  // console.log("Success:", values);
};

const onFinishFailed = (errorInfo: any) => {
  // console.log("Failed:", errorInfo);
};

interface LoginPageProps {
  onLogin: (useremail: string, password: string) => void;
}

const ForgetPassword: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [authData, setAuthData] = useState({ useremail: "", password: "" });
  const [errors, setErrors] = useState({ useremail: "", password: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [loading, setLoading] = useState(false); 

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const users = [
    { useremail: "user" },
    { useremail: "user2@hepl.com" },
    { useremail: "admin@cavininfo.com" },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAuthData((prevAuthData) => ({ ...prevAuthData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleLogin = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/forgetPassword`;
      const formData = new FormData();

      // console.log("email ", authData.useremail);
      formData.append("email", authData.useremail);
      setLoading(true);
     // "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      const response = await axios.post(apiUrl,formData, {
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => {
          // console.log(response.data);
          // console.log("Password recovery email sent successfully.");
          setSuccessMessageVisible(true);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 2000);
           setAuthData({ useremail: "" ,password: "" });
           setLoading(false);
        }).catch((errors)=>{
          if(errors.response.status === 404){
            setErrors({ ...errors, useremail: "Sorry! Your email is not linked with the portal." });
              return;
          }else{
            setErrors({ ...errors, useremail: "Sorry! Something Went Wrong" });
          }
          // console.error("Error occurred during password recovery:", errors.response.data);
        })
    } catch (error) {
         
      // console.error("Error occurred during password recovery:", error);
    }

   
  };

  return (<>
    
    <Row className="login_bg">
      <Col
        xl={16}
        lg={15}
        md={12}
        sm={24}
        xs={24}
        className="d-sm-none left-bg"
      >
        {/* <img src="assets/images/login-left.svg" className="" alt="" /> */}
      </Col>
      <Col
        xl={8}
        lg={9}
        md={12}
        sm={24}
        xs={24}
        className="right-bg-container"
      >
        <div className="right-bg" >
          <div className="">
            <div className="text-center">
              {successMessageVisible && (
                <div className="mb-5">
                  <Row className="success-message email_msg justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">
                      Email sent successfully.
                    </span>
                  </Row>
                </div>
              )}
              {/* <Spin spinning={loading}></Spin> */}
              <span className="c_47E0FF fs-48 fw-700 mb-2 text-center"> Forget Password</span>
              <p className="c-000 font_ReadexPro fw-300 fs-20 text-center">
                Please enter your email.
              </p>
            </div>
            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="m-5 p-5 pm-0 mm-0"
            >
              <div className="">
                <div>
                  <label htmlFor="" className="fw-600 fs-14">Email</label>
                  <Input
                    type="text"
                    name="useremail"
                    placeholder="abc@gmail.com"
                    value={authData.useremail}
                    onChange={handleInputChange}
                    className="mt-1"
                    required
                  />
                  <Row className="justify-end cur-pointer">
                    {/* <p className="c_47E0FF text_line">Back to Sign In</p> */}
                    <p className="c_47E0FF text_line"> <Link className="c_47E0FF text_line" to="/">Back to Sign In</Link></p>
                    
                  </Row>
                  {errors.useremail && (
                    <p className="m-0 text-center mt-1" style={{ color: "red" }}>
                      {errors.useremail}
                    </p>
                  )}
                </div>

              </div>

              <Form.Item className="text-center mt-5">
                <Button className="Confirm_button w-80" onClick={handleLogin} loading={loading}>
                  Confirm
                </Button>
              </Form.Item>
            </Form>
            <div className="text-center">
              <p className="Copyright text-center fs-12">
                Copyright © 2024 CITPL. All right reserved.
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    </>
  );
   
};

export default ForgetPassword;
