import React, { useEffect, useState } from "react";
import {
  Row,
  Select,
  Button,
  Space,
  Dropdown,
  Menu,
  Divider,
  Radio,
  Col,
  Input,
  Table,
  Spin,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import ReviewEdit from "./ReviewEdit";

interface DataType {
  key: string;
  userId: string;
  avatar: string;
  employeeRatingCount: number | null | undefined;
  employeeRating: string | null | undefined;
  managerRating: string | null | undefined;
  reviewerRating: string | null | undefined;
  employeeStatus: number | null | undefined;
  managerStatus: number | null | undefined;
  reviewerStatus: number | null | undefined;
  action: number | null | undefined;
  employeeid: string;
}
interface ApiResponse {
  divisonName: string;
  id: string;
  username: string;
  name: string;
  email: string;
  supervisor_id: string;
  rolename: string;
  profilePath: string | null;
  employeeid: string;
  designation: string;
  payrollStatus: string;
  signoffStatus: number;
  self_status?: number | null | undefined;
  supervisor_status?: number | null | undefined;
  reviewer_status?: number | null | undefined;
  selfRating?: string | null | undefined;
  supervisorRating?: string | null | undefined;
  reviewerRating?: string | null | undefined;
}
interface User {
  id: string;
  name: string;
  email: string;
  profilePath: string;
  signoffStatus: number;
  designation: string;
  reportee: any;
  self_status: number | null | undefined;
  supervisor_status: number | null | undefined;
  reviewer_status: number | null | undefined;
  status: number;
}

const Review: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<DataType[]>();
  const [selected, setEditData] = useState<DataType | null>(null);
  const handleEdit = (record: DataType) => {
    setEditData(record);
    <ReviewEdit
      userId={selected?.userId || ""}
      employeeRating={selected?.employeeRating || "NOT SUBMITTED"}
      managerRating={selected?.managerRating || "NOT SUBMITTED"}
      employeeRatingCount={selected?.employeeRating || ""}
      managerRatingCount={selected?.managerRating || ""}
      employeeId={record?.employeeid || ""}
      supervisorId={ManagerselectedOption || ""}
    />

    setEdit(!edit);
  };
  const handleView = (record: DataType) => {
    setEditData(record);
    setEdit(!edit);
  };
  //const [signoffdropdown, setSignOffDropDown] = useState<User[]>([]);
  const columns = [
    {
      title: "Team Members",
      dataIndex: "userId",
      key: "userId",
      render: (text: any, record: any) => (
        <Space>
          {/* <img src={record.avatar} alt="avatar" style={{ width: 30, borderRadius: '50%' }} /> */}
          <img
            src={record.avatar}
            alt="avatar"
            style={{ width: 30, borderRadius: "50%" }}
          />
          <span>{record.userId}</span>
        </Space>
      ),
    },
    {
      title: "Employee Rating",
      dataIndex: "employeeRating",
      key: "employeeRating",
      render: (text: any, record: any) => (
        <span>
          {record.employeeStatus <= 1 ? (
            <>
              <span className="emp_not_Submitted">NOT SUBMITTED</span>{" "}
            </>
          ) : (
            <>
              <p className="c_000 fs-14">
                {record.employeeRating}
              </p>
            </>
          )}
          {/* {record.employeeRating.length === 0 ? (
            <>
              <span className="emp_not_Submitted">NOT SUBMITTED</span>{" "}
            </>
          ) : (
            <>
                <p className="fs-14 c_000 m-0">75 % </p>
            </>
          )} */}
        </span>
      ),
    },
    {
      title: "Manager Rating",
      dataIndex: "managerRating",
      key: "managerRating",
      render: (text: any, record: any) => (
        <span
          className={
            record.employeeStatus === 2 && record.managerStatus <= 1
              ? "manager_not_submitted"
              : ""
          }
        >
          {record.employeeStatus === 2 && record.managerStatus <= 1 ? (
            "Not Submitted"
          ) : (
            <>
              {record.managerStatus === 2 ? (
                <>{record.managerRating} </>
              ) : (
                <>
                  <p className="c_000 fs-14">
                    -
                  </p>
                </>
              )}
            </>
          )}
        </span>
        // <span
        //   className={
        //     record.managerRating === "NOT SUBMITTED"
        //       ? "manager_not_submitted"
        //       : ""
        //   }
        // >
        //   {record.managerRating.length === 0 ? (
        //     " - "
        //   ) : (
        //     <>
        //       {record.managerRating === "NOT SUBMITTED" ? (
        //         <>{record.managerRating} </>
        //       ) : (

        //             <p className="fs-14 c_000 m-0">50 %
        //             </p>

        //       )}
        //     </>
        //   )}
        // </span>
      ),
    },
    {
      title: "Reviewer Rating",
      dataIndex: "reviewerRating",
      key: "reviewerRating",
      render: (text: any, record: any) => (

        <span
          className={
            record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus <= 1
              ? "appraisal_pending"
              : ""
          }
        >
          {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus <= 1 ? (
            "Appraisal Pending"
          ) : (
            <>
              {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus === 2 ? (
                <>{record.reviewerRating} </>
              ) : (
                <>
                  <p className="c_000 fs-14">
                    -
                  </p>
                </>
              )}
            </>
          )}
        </span>
        // <span
        //   className={
        //     record.reviewerRating === "APPRAISAL PENDING"
        //       ? "appraisal_pending"
        //       : ""
        //   }
        // >
        //   {record.reviewerRating.length === 0 ? (
        //     " - "
        //   ) : (
        //     <>
        //       {record.reviewerRating === "APPRAISAL PENDING" ? (
        //         <>{record.reviewerRating} </>
        //       ) : (
        //         <>
        //             <p className="fs-14 c_000 m-0">60 %
        //             </p>

        //         </>
        //       )}
        //     </>
        //   )}
        // </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus <= 1 && (
            <>
              <div onClick={() => handleEdit(record)}>
                <img
                  src="assets/images/edit.svg"
                  alt=""
                  className="cur-pointer"
                />
              </div>
            </>
          )}
          {record.employeeStatus === 2 && record.managerStatus === 2 && record.reviewerStatus === 2 && (
            <>
              <img
                src="assets/images/view.svg"
                alt=""
                onClick={() => handleView(record)}
                className="cur-pointer"
              />
            </>
          )}
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      userId: "Anup Nandhan / Product / CITPL",
      avatar: "url-to-avatar1",
      employeeRatingCount: "50",
      employeeRating: "Exceed Expectations 🎖️",
      managerRatingCount: "5",
      managerRating: "Exceeds Expectation 🎖️",
      reviewerRating: "APPRAISAL PENDING",
      action: "APPRAISAL PENDING",
    },
    {
      key: "2",
      userId: "divya / Product / CITPL",
      avatar: "url-to-avatar2",
      employeeRatingCount: "20",
      employeeRating: "Strong Performer 💪🏻",
      managerRating: "NOT SUBMITTED",
      reviewerRating: "",
      action: "",
    },
    {
      key: "3",
      userId: "Vennila / Product / CITPL",
      avatar: "url-to-avatar3",
      employeeRatingCount: "1",
      employeeRating: "Unsatisfactory Performance😔",
      managerRatingCount: "75",
      managerRating: "5 - Exceeds Expectation",
      reviewerRating: "5 - Exceeds Expectation",
      action: "",
    },
    {
      key: "4",
      userId: "Harish / Product / CITPL",
      avatar: "url-to-avatar4",
      employeeRatingCount: "",
      employeeRating: "",
      managerRating: "",
      reviewerRating: "",
      action: "",
    },
  ];

  const appraisalItems = (
    <Menu className="SignOff_dropdown">
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/RequestPending.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1"> Appraisal Pending</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/Signoff_Completed.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Appraisal Completed</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/NotSubmitted_Manager.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Not Submitted by Manager</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item>
        <div className="d-flex align-center w-100">
          <img
            src="assets/images/NotRequested.svg"
            alt=""
            className="WH_16"
          />
          <span className="fs-14 pl-1">Not Submitted by Employee</span>
        </div>
      </Menu.Item>
    </Menu>
  );


  const [ManagerselectedOption, setManagerSelectedOption] = useState<string>('');
  const [Manageroptions, setManageroptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const userLists = async () => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem('loginId')}/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${localStorage.getItem('loginId')}/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      //setManageroptions(response.data);
      const result = response.data.filter((item: User) => item.reportee.length !== 0);
      setManageroptions(result);
      setLoading(false);
    } catch (err) {
      // console.error("Error fetching user list:", err);
      setManageroptions([]);
    }
  };

  const revieweruserLists = async (UserId: string) => {
    try {
      // const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${UserId}/${localStorage.getItem('year')}/${localStorage.getItem('Quarter')}`, {
        const response = await axios.get(BaseUrl + `api/users/userListBySupervisorreviewer/${UserId}/${'2024-2025'}/${'Q1'}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const transformedData = transformData(response.data);
      setSelectedData(transformedData);
    } catch (err) {
      // console.error("Error fetching user list:", err);
    }
  };

  const transformData = (data: ApiResponse[]): DataType[] => {
    return data.map((item, index) => ({
      key: (index + 1).toString(),
      userId: `${item.name} / ${item.divisonName.split('-').slice(0, 2).join(' ')} / ${item.payrollStatus}`,
      avatar: item.profilePath
        ? FileUrl + item.profilePath
        : "assets/images/Default_user.svg",
      employeeRatingCount: item.self_status,
      employeeRating: item.selfRating,
      managerRating: item.supervisorRating,
      reviewerRating: item.reviewerRating,
      employeeStatus: item.self_status,
      managerStatus: item.supervisor_status,
      reviewerStatus: item.reviewer_status,
      action: item.self_status,
      employeeid: item.employeeid,
    }));
  };
  // const Manageroptions = [
  //   {
  //     value: "Vikram/Product/CITPL",
  //     name: "Vikram/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 1,
  //   },
  //   {
  //     value: "Divya/Product/CITPL",
  //     name: "Divya/Product/CITPL",
  //     imageUrl: "assets/images/Default_user.svg",
  //     status: 2,
  //   },
  //   {
  //     value: "Reshma/Product/CITPL",
  //     name: "Reshma/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 3,
  //   },
  //   {
  //     value: "Vicky/Product/CITPL",
  //     name: "Vicky/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 4,
  //   },
  // ];

  const handleTMPrev = () => {
    const currentIndex = Manageroptions.findIndex(
      (option) => option.id === ManagerselectedOption
    );
    const prevIndex =
      (currentIndex - 1 + Manageroptions.length) % Manageroptions.length;
    setManagerSelectedOption(Manageroptions[prevIndex].id);
    revieweruserLists(Manageroptions[prevIndex].id);
  };

  const handleManagerNext = () => {
    const currentIndex = Manageroptions.findIndex(
      (option) => option.id === ManagerselectedOption
    );
    const nextIndex = (currentIndex + 1) % Manageroptions.length;
    setManagerSelectedOption(Manageroptions[nextIndex].id);
    revieweruserLists(Manageroptions[nextIndex].id);
  };

  const isPrevAvailable =
    Manageroptions.findIndex(
      (option) => option.id === ManagerselectedOption
    ) > 0;
  const isNextAvailable =
    Manageroptions.findIndex(
      (option) => option.id === ManagerselectedOption
    ) <
    Manageroptions.length - 1;

  const buttonStyle = (isAvailable: any) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  //console.log("selectedManagerOption",ManagerselectedOption);
  useEffect(() => {
    userLists();
  }, []);
  useEffect(() => {
    if (Manageroptions.length > 0) {
      setManagerSelectedOption(Manageroptions[0].id);
      revieweruserLists(Manageroptions[0].id);
      //UserSignOffData(Manageroptions[0].id);
      //setManagerSelectedOption(Manageroptions[0].);
    }
  }, [Manageroptions]);

  useEffect(() => {

    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
      userLists();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleOptionChange = (value: string) => {
    //setManagerSelectedOption(event.target.value);
    setManagerSelectedOption(value);
    revieweruserLists(value);

  };

  // useEffect(() => {

  // }, [selectedData]);
  //console.log("ManagerSelectedoption",ManagerselectedOption);
  return (
    <>
      {!edit ? (
        <>
          <div className="permission_btn ">
            <div className="justify-end align-center z-1">
              <label htmlFor="" className="fs-12 fw-700 mr-1">Manager :</label>
              <Space direction="horizontal" className="z-1 mr-1 appraisal_user_select">
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleTMPrev}
                  style={buttonStyle(isPrevAvailable)}
                  disabled={!isPrevAvailable}
                />
                <Select
                  className="custom-select w-300"
                  value={ManagerselectedOption}
                  onChange={handleOptionChange}
                >
                  {Manageroptions.map((Manageroption) => (
                    <label key={Manageroption.id} className="d-flex align-center">
                      <Radio
                        value={Manageroption.id}
                        checked={ManagerselectedOption === Manageroption.id}
                      //onChange={handleOptionChange}
                      >
                        <div className="align-center justify-between status_signoff_user">
                          <img
                            src={Manageroption.profilePath
                              ? FileUrl + Manageroption.profilePath
                              : "assets/images/Default_user.svg"}
                            alt={Manageroption.name}
                            className="mr-1 img_36"
                          />
                          <span className="mr-1 signoff_myteam_dd">{Manageroption.name} /{Manageroption.designation}</span>
                          <span className="status_signoff">
                            {Manageroption.self_status == 2 && Manageroption.supervisor_status == 2 && Manageroption.reviewer_status == 2 ? (
                              <img
                                src="assets/images/Signoff_Completed.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {Manageroption.self_status == 2 && Manageroption.supervisor_status == 2 && (Manageroption.reviewer_status == 1 || Manageroption.reviewer_status == 0) ? (
                              <img
                                src="assets/images/RequestPending.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {(Manageroption.self_status == 0 || Manageroption.self_status == 1) && (Manageroption.supervisor_status == 0 || Manageroption.supervisor_status == 1) && Manageroption.reviewer_status == 0 ? (
                              <img
                                src="assets/images/NotRequested.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="status_signoff">
                            {Manageroption.self_status == 2 && (Manageroption.supervisor_status == 0 || Manageroption.supervisor_status == 1) && (Manageroption.reviewer_status == 0 || Manageroption.reviewer_status == 1) ? (
                              <img
                                src="assets/images/NotSubmitted_Manager.svg"
                                alt=""
                                className="WH_16"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </Radio>
                    </label>
                  ))}
                </Select>
                <Button
                  icon={<RightOutlined />}
                  onClick={handleManagerNext}
                  style={buttonStyle(isNextAvailable)}
                  disabled={!isNextAvailable}
                />
              </Space>
              <div className=" appraisal_search z-1">
                <img
                  src="assets/images/search.svg"
                  alt=""
                  className="appraisal_search"
                />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search Team Members"
                />
              </div>
              <Dropdown
                overlay={appraisalItems}
                trigger={["click"]}
                className="info_sign_off"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div className="p-1 ">
                      <img
                        src="assets/images/Non-categorized.svg"
                        alt=""
                        className="cur-pointer"
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div
              className="appraisal team_appraisal_table"
              style={{ textAlign: "center" }}
            >
            {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
              <div className="content" />
              </Spin>}
              <Table
                columns={columns}
                dataSource={selectedData}
                pagination={false}
                className=""
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <ReviewEdit
            userId={selected?.userId || ""}
            employeeRating={selected?.employeeRating || "NOT SUBMITTED"}
            managerRating={selected?.managerRating || "NOT SUBMITTED"}
            employeeRatingCount={selected?.employeeRating || ""}
            managerRatingCount={selected?.managerRating || ""}
            employeeId={selected?.employeeid || ""}
            supervisorId={ManagerselectedOption || ""}
          />
        </>
      )}
    </>
  );
};

export default Review;
