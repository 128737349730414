import React, { useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  Row,
  Select,
  Button,
  Space,
  Dropdown,
  Menu,
  Divider,
  Radio,
  Col,
  Input,
  Tag,
  Table,
  Spin
  , message
  , Tooltip
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import axios from "axios";
import { BaseUrl, FileUrl } from "../../../App";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

// Define a type for the statuses
type StatusType =
  | "Pending with Reviewer"
  | "Not Initiated"
  | "Completed"
  | "Pending With Manager"
  | "Not Exist"; 

interface EmployeeData {
  key: string;
  status: StatusType;
  employeeId: string;
  employeeName: string;
  employeeRating: string;
  managerName: string;
  managerRating: string;
  reviewerName: string;
  reviewerRating: string;
  hrBP: string;
  reviewerId: string;
  managerId: string;
  appraisalReleaseStatus: number;
  profilePath: string;
  managerProfilePath: string;
  reviewerProfilePath: string;

}



//   {
//     key: "1",
//     status: "Pending with Reviewer",
//     employeeId: "123",
//     employeeName: "Abishek K",
//     employeeRating: "25 %",
//     managerName: "Santhosh",
//     managerRating: "25 %",
//     reviewerName: "Tamilarasan",
//     reviewerRating:"-"
//   },
//   {
//     key: "2",
//     status: "Not Initiated",
//     employeeId: "123",
//     employeeName: "Anup",
//     employeeRating: "-",
//     managerName: "Santhosh",
//     managerRating: "-",
//     reviewerName: "Tamilarasan",
//      reviewerRating:"-"
//   },
//   {
//     key: "3",
//     status: "Completed",
//     employeeId: "123",
//     employeeName: "Hari",
//     employeeRating: "65 %",
//     managerName: "Bala Kumar",
//     managerRating: "65 % ",
//     reviewerName: "Tamilarasan",
//      reviewerRating:"-"
//   },
//   {
//     key: "4",
//     status: "Pending With Manager",
//     employeeId: "123",
//     employeeName: "Ashokumar",
//     employeeRating: "30 %",
//     managerName: "Bala Kumar",
//     managerRating: "30 %",
//     reviewerName: "Tamilarasan",
//      reviewerRating:"-"
//   },
// ];

const statusColors: Record<StatusType, string> = {
  "Pending with Reviewer": "appraisal_pending",
  "Not Initiated": "emp_not_Submitted",
  Completed: "appraisal_submitted",
  "Pending With Manager": "manager_not_submitted",
  "Not Exist":"emp_not_Submitted"
  
};

const columns: ColumnsType<EmployeeData> = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (status: StatusType) => (
      <p className={statusColors[status]}>{status}</p>
    ),
  },
  {
    title: "Employee ID",
    dataIndex: "employeeId",
    key: "employeeId",
  },
  {
    title: "Employee Name",
    dataIndex: "employeeName",
    key: "employeeName",
  },
  {
    title: "Employee Rating",
    dataIndex: "employeeRating",
    key: "employeeRating",
  },
  {
    title: "Manager Name",
    dataIndex: "managerName",
    key: "managerName",
  },
  {
    title: "Manager Rating",
    dataIndex: "managerRating",
    key: "managerRating",
  },
  {
    title: "Reviewer Name",
    dataIndex: "reviewerName",
    key: "reviewerName",
  },
  {
    title: "Reviewer Rating",
    dataIndex: "reviewerRating",
    key: "reviewerRating",
  },
  {
    title: "HRBP",
    dataIndex: "hrBp",
    key: "hrBp",
  },
];

const ApraisalReport: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [appraisalReport, setAppraisalReport] = useState<EmployeeData[]>([]);
  const [loading, setLoading] = useState(true);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: EmployeeData) => ({
      disabled: record.appraisalReleaseStatus === 1 || record.status !== "Completed",
      title: record.appraisalReleaseStatus === 1 ? "Appraisal Released" : "",
    }),
    renderCell: (checked: boolean, record: EmployeeData, index: number, originNode: React.ReactNode) => {
      return record.appraisalReleaseStatus === 1 ? (
        <Tooltip title="Appraisal Released">
          <span>{originNode}</span>
        </Tooltip>
      ) : record.status !== "Completed" ? (
        <Tooltip title="Not Completed">
          <span>{originNode}</span>
        </Tooltip>
      ) : (
        originNode
      )
    }
  };
  //   manageroptions
  const [ManagerselectedOption, setManagerSelectedOption] = useState("Everyone");
  const [filteredManageroptions, setfilteredManageroptions] = useState<EmployeeData[]>([]);

  const uniqueMembers = new Set<string>();
  const Manageroptions: EmployeeData[] = [];

  appraisalReport.forEach(option => {
    if (option.managerId !== '-' && !uniqueMembers.has(option.managerId)) {
      uniqueMembers.add(option.managerId);
      Manageroptions.push(option);
      // setfilteredManageroptions(Manageroptions);
    }
  });



  // const Manageroptions = [
  //   {
  //     value: "Everyone",
  //     name: "Everyone",
  //   },
  //   {
  //     value: "Vikram/Product/CITPL",
  //     name: "Vikram/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 1,
  //   },
  //   {
  //     value: "Divya/Product/CITPL",
  //     name: "Divya/Product/CITPL",
  //     imageUrl: "assets/images/Default_user.svg",
  //     status: 2,
  //   },
  //   {
  //     value: "Reshma/Product/CITPL",
  //     name: "Reshma/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     status: 3,
  //   },
  // ];

  const appraisalReportsData = async () => {

    try {
      const apiUrl = BaseUrl + `api/reports/getAppraisalReports`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setAppraisalReport(response.data);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error({
        content: (
          <div className="success-message justify-center align-center p-1 d-flex">
            Something Went Wrong
          </div>
        ),
        duration: 5,
        icon: null,
      });
      // console.log("ERROR :::::::::" + error);
    }

  };

  useEffect(() => {
    appraisalReportsData();
  }, [])



  const handleTMPrev = () => {
    const currentIndex = filteredManageroptions.findIndex(
      (option) => option.managerId === ManagerselectedOption
    );
    const prevIndex =
      (currentIndex - 1 + filteredManageroptions.length) % filteredManageroptions.length;
    setManagerSelectedOption(filteredManageroptions[prevIndex].managerId);
  };
  const handleManagerNext = () => {
    const currentIndex = filteredManageroptions.findIndex(
      (option) => option.managerId === ManagerselectedOption
    );
    const nextIndex = (currentIndex + 1) % filteredManageroptions.length;
    setManagerSelectedOption(filteredManageroptions[nextIndex].managerId);
  };
  const isPrevAvailable =
    filteredManageroptions.findIndex(
      (option) => option.managerId === ManagerselectedOption
    ) > 0;
  const isNextAvailable =
    filteredManageroptions.findIndex(
      (option) => option.managerId === ManagerselectedOption
    ) <
    filteredManageroptions.length - 1;

  // Revieweroptions  
  const [ReviewerselectedOption, setReviewerSelectedOption] = useState("Everyone");
  const uniqueReviewers = new Set<string>();
  const reviewersList: EmployeeData[] = [];

  appraisalReport.forEach(option => {
    if (option.reviewerId !== '-' && !uniqueReviewers.has(option.reviewerId)) {
      uniqueReviewers.add(option.reviewerId);
      reviewersList.push(option);
    }
    // console.log("reiiwers : ", reviewersList)
  });
  // const Revieweroptions = [
  //   {
  //     value: "Everyone",
  //     name: "Everyone",
  //   },
  //   {
  //     value: "Tamilarasan/Product/CITPL",
  //     name: "Tamilarasan/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     //   status: 1,
  //   },
  //   {
  //     value: "Vishva/Product/CITPL",
  //     name: "Vishva/Product/CITPL",
  //     imageUrl: "assets/images/Default_user.svg",
  //     //   status: 2,
  //   },
  //   {
  //     value: "Nagamani/Product/CITPL",
  //     name: "Nagamani/Product/CITPL",
  //     imageUrl: "assets/images/user.svg",
  //     //   status: 3,
  //   },
  // ];
  const handleReviewerPrev = () => {
    const currentIndex = reviewersList.findIndex(
      (option) => option.reviewerId === ReviewerselectedOption
    );
    const prevIndex =
      (currentIndex - 1 + reviewersList.length) % reviewersList.length;
    setReviewerSelectedOption(reviewersList[prevIndex].reviewerId);
  };
  const handleReviewerNext = () => {
    const currentIndex = reviewersList.findIndex(
      (option) => option.reviewerId === ReviewerselectedOption
    );
    const nextIndex = (currentIndex + 1) % reviewersList.length;
    setReviewerSelectedOption(reviewersList[nextIndex].reviewerId);
  };
  const isReviewPrevAvailable =
    reviewersList.findIndex(
      (option) => option.reviewerId === ReviewerselectedOption
    ) > 0;
  const isReviewNextAvailable =
    reviewersList.findIndex(
      (option) => option.reviewerId === ReviewerselectedOption
    ) <
    reviewersList.length - 1;

  const buttonStyle = (isAvailable: any) => ({
    backgroundColor: isAvailable ? "#e6f7ff" : "#f5f5f5",
    borderColor: isAvailable ? "#91d5ff" : "#d9d9d9",
    color: isAvailable ? "#1890ff" : "#bfbfbf",
  });

  const handleOptionChange = (event: any) => {
    setManagerSelectedOption(event.target.value);
  };
  const handleReviewerOptionChange = (event: any) => {
    const selectedValue = event.target.value;
    setReviewerSelectedOption(selectedValue);
    const filteredOptions = Manageroptions.filter(option => option.reviewerId === selectedValue);
    setfilteredManageroptions(filteredOptions);

  };

  const getFilteredData = () => {
    let filteredData = appraisalReport;

    if (ReviewerselectedOption !== "Everyone") {
      filteredData = filteredData.filter(
        (record) => record.reviewerId === ReviewerselectedOption
      );
    }

    if (ManagerselectedOption !== "Everyone") {
      filteredData = filteredData.filter(
        (record) => record.managerId === ManagerselectedOption
      );
    }

    return filteredData;
  };

  const [searchText, setSearchText] = useState("");

  const filteredData = getFilteredData().filter((record: EmployeeData) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };


  const updateAppraisalStatus = (userIds: string[]) => {
    setAppraisalReport(prevData =>
      prevData.map(user =>
        userIds.includes(user.key)
          ? { ...user, appraisalReleaseStatus: 1 }
          : user
      )
    );
  };


  const releaseAppraisalUpdate = async () => {

    if (selectedRowKeys.length > 0) {

      try {
        const userIds = selectedRowKeys.map(key => key.toString());
        const apiUrl = BaseUrl + `api/signOff/updateAppraisalRelease`;
        const response = await axios.put(apiUrl, null, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }, params: {
            userIds: userIds
          }, paramsSerializer: (params: any) => {
            return Object.keys(params)
              .map(key => params[key].map((value: string) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&'))
              .join('&');
          }
        })
          .then((response) => {


            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    Appraisal Released Sucessfully.
                  </span>
                </div>
              ),
              duration: 2,
              icon: null,
            });

            updateAppraisalStatus(userIds);
            setSelectedRowKeys([]);

          });
      } catch (error) {

        // console.log("ERROR :::::::::" + error);
      }
    } else {

      message.error({
        content: (
          <div className="success-message justify-center align-center d-flex">
            select user
          </div>
        ),
        duration: 2,
        icon: null,
      });

    }
  }



  const handleDownloadClick = () => {
    downloadXLS();
  };
  const downloadXLS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    worksheet.columns = columns.map((column) => ({
      header: column.title as string,
      key: column.key as string, // Explicitly cast key to string
      width: 20,
    }));

    filteredData.forEach((data) => {
      worksheet.addRow(data);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "AppraisalReport.xlsx");
  };


  return (
    <>
      <Row className="mb-2 appraisal_report">
        <Col xl={19} lg={24} md={24} sm={24}>
          <Row className="apparisal_report">
            <Col xl={9} lg={9} md={24} sm={24}>
              <label htmlFor="" className="fs-12 fw-700 mr-1">
                Reviewer :
              </label>
              <Space
                direction="horizontal"
                className="z-1 mr-1 appraisal_user_select"
              >
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleReviewerPrev}
                  style={buttonStyle(isReviewPrevAvailable)}
                  disabled={!isReviewPrevAvailable}
                />
                <Select
                  className="custom-select w-300"
                  value={ReviewerselectedOption}
                  onChange={setReviewerSelectedOption}
                >
                  <Select.Option value="Everyone">Everyone</Select.Option>
                  {reviewersList.map((Revieweroption) => (
                    <label
                      key={Revieweroption.reviewerId}
                      className="d-flex align-center"
                    >
                      <Radio
                        value={Revieweroption.reviewerId}
                        checked={
                          ReviewerselectedOption === Revieweroption.reviewerId
                        }
                        onChange={handleReviewerOptionChange}
                      >
                        <div className="align-center justify-between status_signoff_user">
                          {Revieweroption &&
                            Revieweroption.reviewerId !== "Everyone" && (
                              <img
                                src={Revieweroption.reviewerProfilePath ? FileUrl + Revieweroption.reviewerProfilePath : "assets/images/Default_user.svg"}
                                // src={"assets/images/Default_user.svg"}
                                alt={Revieweroption.reviewerName}
                                className="mr-1 img_36"
                              />
                            )}
                          <span className="mr-1 member_title">
                            {Revieweroption.reviewerName}
                          </span>
                        </div>
                      </Radio>
                    </label>
                  ))}
                </Select>
                <Button
                  icon={<RightOutlined />}
                  onClick={handleReviewerNext}
                  style={buttonStyle(isReviewNextAvailable)}
                  disabled={!isReviewNextAvailable}
                />
              </Space>
            </Col>
            <Col xl={9} lg={9} md={24} sm={24}>
              <label htmlFor="" className="fs-12 fw-700 mr-1">
                Manager :
              </label>
              <Space
                direction="horizontal"
                className="z-1 mr-1 appraisal_user_select"
              >
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleTMPrev}
                  style={buttonStyle(isPrevAvailable)}
                  disabled={!isPrevAvailable}
                />
                <Select
                  className="custom-select w-300"
                  value={ManagerselectedOption}
                  onChange={setManagerSelectedOption}
                >
                  <Select.Option value="Everyone">Everyone</Select.Option>
                  {filteredManageroptions.map((Manageroption) => (
                    <label
                      key={Manageroption.managerId}
                      className="d-flex align-center"
                    >
                      <Radio
                        value={Manageroption.managerId}
                        checked={ManagerselectedOption === Manageroption.managerId}
                        onChange={handleOptionChange}
                      >
                        <div className="align-center justify-between status_signoff_user">
                          {Manageroption &&
                            Manageroption.managerId !== "Everyone" && (
                              <img
                                src={Manageroption.managerProfilePath ? FileUrl + Manageroption.managerProfilePath : "assets/images/Default_user.svg"}
                                // src={"assets/images/Default_user.svg"}
                                alt={Manageroption.managerName}
                                className="mr-1 img_36"
                              />
                            )}
                          <span className="mr-1 member_title">
                            {Manageroption.managerName}
                          </span>
                        </div>
                      </Radio>
                    </label>
                  ))}
                </Select>
                <Button
                  icon={<RightOutlined />}
                  onClick={handleManagerNext}
                  style={buttonStyle(isNextAvailable)}
                  disabled={!isNextAvailable}
                />
              </Space>
            </Col>
            <Col xl={6} lg={6} md={24} sm={24}>
              <div className=" appraisal_search z-1">
                <img
                  src="assets/images/search.svg"
                  alt=""
                  className="appraisal_search"
                />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search Employee"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={5} lg={24} md={24} sm={24} className="float-right">
          <button className="save_as_draft" onClick={handleDownloadClick}>Download to Excel</button>
          <button className="cyan_button" onClick={releaseAppraisalUpdate}>Release Appraisal</button>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={filteredData}
          rowSelection={rowSelection}
          pagination={{ pageSize: 15 }}
          className="custom-table"
          rowKey="key"
        />
      </Spin>
    </>
  );
};


export default ApraisalReport;
