import React, { useEffect, useState, useRef } from 'react';
import G6, { IGroup, IShape, ModelConfig, Item } from '@antv/g6';
import { Spin, Row, Col, Flex, Card, Progress, Avatar, Tooltip } from 'antd';
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

interface Members {
  id: string,
  username: string,
  employeeid?: string,
  divisonName?: string,
  designation?: string,
  profilePath: string,
  count?: string,
}

interface ObjectiveData {
  id: string;
  objectivename: string;
  description: string;
  individualweightage: number;
  name: string;
  year: string;
  objectivesuser_list: userslist[];
  revertstatus: number;
  status:number;
}
interface userslist {
  userId: string;
  weightage: string;
}

interface LeadMeasure {
  id: number;
  krId: string;
  objId: string;
  orgObjId: string;
  description: string;
}
interface KrData {
  id: string;
  objId: string;
  krName: string;
  krDesc: string;
  createdBy: string;
  createdByPath: string;
  cascadedBy: string;
  cascadedByPath: string;
  cycle: string;
  leadMeasures: LeadMeasure[];
}

type kr = {
  id: string,
  objectiveid: string,
  keytitle: string,
  description: string,
  remarks: string,
  target: string,
  amounttype: string,
  uom: string,
  status: number,
  keyid: string,
  objectivecode: string,
  quarter: string,
  orgobjectiveid: string,
  objectivename: string,
  keyResultUsersListAssignedTo: string[],
  createdbyempname: string,
  createdByEmpProfilePath: string,
  keyresultweightage:number
}

interface ImagePaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  // imagePath: string;
  krId: string;
  leadMeasure: LeadMeasure[];

}
const leadMeasures: LeadMeasure[] = [
  {
    id: 1,
    krId: '1',
    objId: '1',
    orgObjId: '1',
    description: 'Lead measure description for KR 1',
  },
  {
    id: 2,
    krId: '1',
    objId: '1',
    orgObjId: '1',
    description: 'Another lead measure description for KR 1',
  },
];
const krs: KrData[] = [
  {
    id: '1',
    objId: '1',
    krName: 'KR1',
    krDesc: 'Achieve a 20% reduction in average server response time.',
    createdBy: 'User 1',
    createdByPath: 'assets/images/user.svg',
    cascadedBy: 'User 2',
    cascadedByPath: 'assets/images/user.svg',
    cycle: 'Cycle 1',
    leadMeasures: leadMeasures,
  },
  {
    id: '2',
    objId: '1',
    krName: 'KR2',
    krDesc: 'Another KR description.',
    createdBy: 'User 1',
    createdByPath: 'assets/images/user.svg',
    cascadedBy: 'User 2',
    cascadedByPath: 'assets/images/user.svg',
    cycle: 'Cycle 1',
    leadMeasures: leadMeasures,
  },
  {
    id: '3',
    objId: '2',
    krName: 'KR3',
    krDesc: 'Yet another KR description.',
    createdBy: 'User 1',
    createdByPath: 'assets/images/user.svg',
    cascadedBy: 'User 2',
    cascadedByPath: 'assets/images/user.svg',
    cycle: 'Cycle 1',
    leadMeasures: leadMeasures,
  },
];


const Hierarchy: React.FC = () => {
  const [loading, setLoading] = useState(true); // Define loading state
  const [graph, setGraph] = useState<any>(null); // Define graph state
  const [userData, setUserData] = useState([]);
  const [containerHeight, setContainerHeight] = useState(300); // Initial height
  const [graphHeight, setGraphHeight] = useState(300); // Initial height
  const [graphZoom, setGraphZoom] = useState(30); // Initial height
  // const [selectedEmployee, setSelectedEmployee] = useState<any>(null); // Step 1: State to hold clicked node data
  const [profileVisible, setProfileVisible] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [objectives, setObjectives] = useState<ObjectiveData[]>([]);
  const [krVisible, setKrVisible] = useState<number | null>(null);
  const [krs, setObjectiveAssignedkr] = useState<kr[]>([]);
  const [getAllLeadMeasure, setAllLeadMeasure] = useState<LeadMeasure[]>([]);

  const [currentMeasureIndex, setCurrentMeasureIndex] = useState<{ [key: string]: number }>(
    Object.fromEntries(
      krs.map((kr) => [kr.id, 0])
    )
  );
  const handleKrVisible = (index: number) => {
    setKrVisible(krVisible === index ? null : index);
    setCurrentMeasureIndex(prevIndexes => {
      const newIndexState: { [key: string]: number } = {};
      krs.forEach(kr => {
        if (kr.objectiveid === objectives[index].id) {
          newIndexState[kr.id] = 0; // Set the default index to 0 for the first lead measure
        }
      });
      return { ...prevIndexes, ...newIndexState };
    });
  };

  const handlePrevMeasure = (krId: string) => {
    setCurrentMeasureIndex(prevIndexes => {
      const kr = krs.find(kr => kr.id === krId);
      if (kr) {
        const currentIndex = prevIndexes[krId] || 0;
        const prevIndex = (currentIndex === 0) ? getAllLeadMeasure.length - 1 : currentIndex - 1;
        return {
          ...prevIndexes,
          [krId]: prevIndex,
        };
      }
      return prevIndexes;
    });
  };
  const handleNextMeasure = (krId: string, current: number) => {
    setCurrentMeasureIndex(prevIndexes => {
      const kr = krs.find(kr => kr.id === krId);
      if (kr) {
        const currentIndex = prevIndexes[krId] || 0;
        const nextIndex = (currentIndex === current - 1) ? 0 : currentIndex + 1;
        return {
          ...prevIndexes,
          [krId]: nextIndex,
        };
      }
      return prevIndexes;
    });
  };
  // const profilePath = localStorage.getItem("profilePath");

  let shouldlog = useRef(true);
  useEffect(() => {
    if (shouldlog.current) {
      shouldlog.current = false;
      userHierarchy();
      handleZoomOut();
      // objectivelist();
    }
    return () => console.log("function cleaned up");
  }, []);

  const userHierarchy = async () => {
    const apiUrl = BaseUrl + `api/users/hierarchy`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      
      // setUserData(response.data);
      tryhierarchy(response.data);
      
    } catch (error) {
      // console.log("users hir error:", error);
    }
  };

  const tryhierarchy = (user: any) => {
    const container = document.getElementById('hierarchy_container');
    if (!container) return;

    const imageExists = async (url: string) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch (error) {
        return false;
      }
    };


    // G6.registerNode('treeNode', {
    //   draw(cfg: any, group: IGroup) {
    //     const size = 16; // Size of the circular node
    //     const img = cfg.img; // Image URL for the node

    //     // Determine fill color based on node state
    //     const fillColor = '#C6E5FF';
    //     const strokeColor = '#ccc';

    //     // Add circular background
    //     const circle = group.addShape('circle', {
    //       attrs: {
    //         x: 0,
    //         y: 0,
    //         r: size / 2,
    //         // Node background color
    //         fill: fillColor,
    //         // Node border color
    //         stroke: strokeColor,
    //         // Node border width
    //         lineWidth: 10,
    //       },
    //       className: 'node-circle', // Add className attribute
    //     });

    //     // Add clip-path for rounded image
    //     const clip = group.addShape('circle', {
    //       attrs: {
    //         x: 0,
    //         y: 0,
    //         r: size / 2,
    //         fill: '#fff', // White fill color to hide the circle
    //       },
    //       zIndex: -1, // Set the zIndex to send it to the back
    //     });

    //     // Add the image, clipped by the circle
    //     const imageShape =    group.addShape('image', {
    //       attrs: {
    //         x: -size / 2,
    //         y: -size / 2,
    //         width: size,
    //         height: size,
    //         // img :FileUrl+user.profilePath,
    //         // img:profilePath?BaseUrl+profilePath : "assets/images/Default_user.svg",
    //         img: img ? FileUrl + img : "assets/images/Default_user.svg",
    //          onError: "assets/images/Default_user.svg",
    //         clip: clip.get('id'), // Set the clip attribute to the ID of the circle shape
    //       },
    //     });
    // const image = new Image();
    // image.src = img ? FileUrl + img : "assets/images/Default_user.svg";

    // // Handle the 'error' event
    // image.onerror = () => {
    //     imageShape.attr('img', 'assets/images/Default_user.svg'); // Set the default image source
    // };
    //     console.log();
    //     return circle; // Return the shape
    //   },
    // });

    G6.registerNode('treeNode', {
      draw(cfg: any, group: IGroup) {
        const size = 16; // Size of the circular node
        const img = cfg.img; // Image URL for the node

        // Determine fill color based on node state
        const fillColor = '#C6E5FF';
        const strokeColor = '#ccc';

        // Add circular background
        const circle = group.addShape('circle', {
          attrs: {
            x: 0,
            y: 0,
            r: size / 2,
            // Node background color
            fill: fillColor,
            // Node border color
            stroke: strokeColor,
            // Node border width
            lineWidth: 10,
          },
          className: 'node-circle', // Add className attribute
        });

        // Add clip-path for rounded image
        const clip = group.addShape('circle', {
          attrs: {
            x: 0,
            y: 0,
            r: size / 2,
            fill: '#fff', // White fill color to hide the circle
          },
          zIndex: -1, // Set the zIndex to send it to the back
        });

        // Create the image shape
        const imageShape = group.addShape('image', {
          attrs: {
            x: -size / 2,
            y: -size / 2,
            width: size,
            height: size,
            img: img ? FileUrl + img : "assets/images/Default_user.svg",
            clip: clip.get('id'), // Set the clip attribute to the ID of the circle shape
          },
        });

        // Create an Image object to handle image loading errors
        const image = new Image();
        image.src = img ? FileUrl + img : "assets/images/Default_user.svg";

        // Handle the 'error' event
        image.onerror = () => {
          imageShape.attr('img', 'assets/images/Default_user.svg'); // Set the default image source
        };

        return circle; // Return the shape
      },
    });




    G6.registerEdge('smooth', {
      draw(cfg, group) {
        const { startPoint, endPoint } = cfg;
        const hgap = Math.abs((endPoint?.x || 0) - (startPoint?.x || 0));

        const path = [
          ['M', startPoint?.x, startPoint?.y],
          [
            'C',
            (startPoint?.x || 0) + hgap / 4,
            startPoint?.y,
            (endPoint?.x || 0) - hgap / 2,
            endPoint?.y,
            endPoint?.x,
            endPoint?.y,
          ],
        ];

        const shape = group.addShape('path', {
          attrs: {
            path,
            stroke: '#ccc',
            lineWidth: 5, // Increase line width
          },
        });

        return shape;
      },
    });

    const graph = new G6.TreeGraph({
      container,
      width: container.offsetWidth,
      height: container.offsetHeight || 500,
      modes: {
        default: [
          { type: 'collapse-expand' },
          'drag-canvas',
          'zoom-canvas',
        ],
      },
      defaultNode: {
        type: 'treeNode',
      },
      defaultEdge: {
        type: 'smooth',
      },
      layout: {
        type: 'compactBox',
        direction: 'LR',
        getId: (d: any) => d.id,
        getHeight: () => 25,
        getWidth: () => 20,
        getVGap: () => 3,
        getHGap: () => 30,
        center: [0, 0],
      },
      // Set the renderer to 'svg'
      renderer: 'svg',
    });

    const tooltip = new G6.Tooltip({
      offsetX: -40,
      offsetY: -100,
      getContent: (e) => {
        const model = e?.item?.getModel();
        if (model) {
          // <div className="tool-img" style="min-width: 90px;">
          // <img src=${model.img ? FileUrl + model.img : "assets/images/Default_user.svg"} alt="avatar" width="60" height="60" />
          // </div>
          return `<div class="text-center custom-tooltip">
                    <div className="tool-text">
                      <b className="fs-18">${model.userName}</b><br/> 
                      ${model.employeeId}<br/> 
                      ${model.designation}<br/>
                      ${model.divisonName}
                    </div>
                  </div>`;
        } else {
          return '';
        }
      },
      itemTypes: ['node'],
    });

    setGraph(graph);
    graph.data(user);
    graph.render();
    graph.fitView();
    graph.zoom(0.2);
    graph.addPlugin(tooltip);

    setLoading(false);

    // const data = {
    //     id: '200000004',
    //     tooltip: 'Thing',
    //     img: 'https://bumbeishvili.github.io/avatars/avatars/portrait12.png', // Image URL for the root node
    //     description: null,
    //     descriptionZh: null,
    //     depth: 3,
    //     subTypeCount: 9,
    //     status: 0,
    //     collapsed:false ,
    //     children: [
    //       {
    //         id: '500000061',
    //         tooltip: 'Person',
    //         img: 'https://bumbeishvili.github.io/avatars/avatars/portrait85.png', // Image URL for the person node
    //         description: null,
    //         descriptionZh: null,
    //         depth: 1,
    //         subTypeCount: 1,
    //         status: 0,
    //         collapsed:true ,
    //         children: [
    //           {
    //             id: '707000085',
    //             tooltip: 'FilmPerson',
    //             img: 'https://bumbeishvili.github.io/avatars/avatars/portrait16.png', // Image URL for the film person node
    //             description: null,
    //             descriptionZh: null,
    //             depth: 5,
    //             subTypeCount: 3,
    //             status: 1,
    //             collapsed:true ,
    //             children: [
    //               {
    //                 id: '707000090',
    //                 tooltip: 'FilmDirector',
    //                 img: 'https://bumbeishvili.github.io/avatars/avatars/portrait17.png', // Image URL for the film director node
    //                 description: null,
    //                 descriptionZh: null,
    //                 depth: 0,
    //                 subTypeCount: 0,
    //                 status: 1,
    //                 collapsed:true ,
    //                 children: [],
    //               },
    //               {
    //                 id: '707000091',
    //                 tooltip: 'FilmWriter',
    //                 img: 'https://bumbeishvili.github.io/avatars/avatars/portrait86.png', // Image URL for the film writer node
    //                 description: null,
    //                 descriptionZh: null,
    //                 depth: 4,
    //                 subTypeCount: 0,
    //                 status: 1,
    //                 collapsed:true ,
    //                 children: [],
    //               },
    //               {
    //                 id: '707000092',
    //                 tooltip: 'FilmStar',
    //                 img: 'https://bumbeishvili.github.io/avatars/avatars/portrait61.png', // Image URL for the film star node
    //                 description: null,
    //                 descriptionZh: null,
    //                 depth: 4,
    //                 subTypeCount: 0,
    //                 status: 1,
    //                 collapsed:true ,
    //                 children: [],
    //               },
    //             ],
    //           },
    //           {
    //             id: '707000086',
    //             tooltip: 'MusicPerson',
    //             img: 'https://bumbeishvili.github.io/avatars/avatars/portrait20.png', // Image URL for the music person node
    //             description: null,
    //             descriptionZh: null,
    //             depth: 17,
    //             subTypeCount: 2,
    //             status: 1,
    //             collapsed:true ,
    //             children: [],
    //           },
    //         ],
    //       },
    //       {
    //         id: '200000005',
    //         tooltip: 'Music',
    //         img: 'https://bumbeishvili.github.io/avatars/avatars/portrait21.png', // Image URL for the music node
    //         description: null,
    //         descriptionZh: null,
    //         depth: 3,
    //         subTypeCount: 2,
    //         status: 1,
    //         collapsed:true ,
    //         children: [],
    //       },
    //       {
    //         id: '707000128',
    //         tooltip: 'Film',
    //         img: 'https://bumbeishvili.github.io/avatars/avatars/portrait23.png', // Image URL for the film node
    //         description: null,
    //         descriptionZh: null,
    //         depth: 4,
    //         subTypeCount: 0,
    //         status: 1,
    //         collapsed:true ,
    //         children: [],
    //       },
    //       {
    //         id: '707000095',
    //         tooltip: 'FilmGenre',
    //         img: 'https://bumbeishvili.github.io/avatars/avatars/portrait24.png', // Image URL for the genre node
    //         description: null,
    //         descriptionZh: null,
    //         depth: 4,
    //         subTypeCount: 0,
    //         status: 1,
    //         collapsed:true ,
    //         children: [],
    //       },
    //       {
    //         id: '702000021',
    //         tooltip: 'Organization',
    //         img: 'https://bumbeishvili.github.io/avatars/avatars/portrait26.png', // Image URL for the organization node
    //         description: null,
    //         descriptionZh: null,
    //         depth: 47,
    //         subTypeCount: 1,
    //         status: 0,
    //         collapsed:true ,
    //         children: [],
    //       },
    //     ],
    // };


    // Initialize previousItem variable to keep track of the previously clicked node

    let previousItem: Item | null = null;
    // Add event listener for node click
    graph.on('node:click', (evt) => {
      const { item } = evt;
      const model = item?.getModel();
      if (model) {
        setSelectedUser(model);

        // Update the style of the previously clicked node
        if (previousItem) {
          const previousGroup = previousItem.getContainer();
          const previousCircle = previousGroup.findByClassName('node-circle');
          if (previousCircle) {
            previousCircle.attr({
              stroke: '#ccc', // Remove the stroke
              lineWidth: 10, // Set the line width to 0
            });
          }
        }
        // Assuming you have a paper object defined somewhere in your code
        // let paper: any;
        const group = item?.getContainer();
        if (group) {
          const circle = group.findByClassName('node-circle');
          if (circle) {
            circle.attr({
              fill: '#000',
              stroke: 'l(90) 0:#47E0FF 1:#5F89FB',
              lineWidth: 10,
            });
          }
        }
        previousItem = item;
      }
    });

    const handleResize = () => {
      if (!graph || graph.get('destroyed')) return;
      if (!container || !container.offsetWidth || !container.offsetHeight) return;
      graph.changeSize(container.offsetWidth, container.offsetHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      graph.destroy();
    };
  }

  const handleExpand = () => {
    if (!graph) return;

    if (containerHeight === 300) {
      // Expand the graph
      setContainerHeight(600);
      setGraphHeight(600);
      setGraphZoom(30)

      // Set the graph's height
      if (graph) {
        graph.changeSize(graph.get('width'), 600);
      }
    } else {
      // Restore default height
      setContainerHeight(300);
      setGraphHeight(300);
      setGraphZoom(30)

      // Set the graph's height
      if (graph) {
        graph.changeSize(graph.get('width'), 300);
      }
    }

    // Expand all collapsed nodes
    // graph.getNodes().forEach((node: any) => {
    //   if (node.getModel().collapsed) {
    //     graph.updateItem(node, { collapsed: false });
    //   }
    // });
  };

  const handleZoomIn = () => {
    if (!graph) return;
    // Zoom in the graph
    graph.zoom(1.1);
  };

  const handleZoomOut = () => {
    if (!graph) return;
    // Zoom out the graph
    graph.zoom(0.9);
  };

  const handleCardClick = (selectedUser: Members | null) => {
    if (selectedUser !== null) {
      setSelectedUser(selectedUser);
      setProfileVisible(true);
      // alert(12)
      objectivelist(selectedUser.id);
      setKrVisible(null); // Set krVisible to false
    }
    else {
      // console.log("selected user 123 else", selectedUser)
    }
  };

  const handlePageChange = (page: number, objectiveId: string) => {
    setCurrentMeasureIndex(prevState => ({
      ...prevState,
      [objectiveId]: page
    }));
  };

  const LeadMeasureData = async () => {

    try {
      const apiUrl = BaseUrl + `api/leadMeasure/listById/` + selectedUser.id;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          setAllLeadMeasure(response.data);
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }

  }
  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };

  const ImagePagination: React.FC<ImagePaginationProps> = ({ leadMeasure, current, total, krId, onChange }) => {
    const filteredContentData = leadMeasure.filter(data => data.krId === krId);
    const currentItem = filteredContentData[current - 1];

    const displayText = (
      <span>
        <strong>{current}</strong> / {Math.ceil(total / 1)}
      </span>
    );

    return (
      <div className="image-pagination  team_profile">
        <div className="pagination-head mb-2 ">
          <Row className="mt-1 ">
            <button
              className="next_pre_btn"
              disabled={current === 1}
              onClick={() => onChange(current - 1)}
              style={{ marginRight: "8px", color: "#fff" }}
            >
              <LeftOutlined />
            </button>
            <div className="d-flex">
              {/* <span>{displayImage}</span> */}
              <span>{displayText}</span>
            </div>
            <button
              className="next_pre_btn"
              disabled={current === Math.ceil(total / 1)}
              onClick={() => onChange(current + 1)}
              style={{ marginLeft: "8px", color: "#fff" }}
            >
              <RightOutlined />
            </button>
          </Row>
        </div>
        {/* <h4>Description</h4> */}
        <div className="pagination-content">
          <Card>{currentItem?.description}</Card>
        </div>
      </div>

    );
  };
  const getassignedkeyforobjectives = async (objectiveid: string) => {
    try {
      const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getKeyResultsheir/${objectiveid}/${selectedUser.id}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      const assignedkr: kr[] = assignedkeyresults.data
      setObjectiveAssignedkr(assignedkr);
      if (assignedkeyresults.data.length === 0) {
        setObjectiveAssignedkr([])
      }
    } catch (error) {
      
    }
  
  }

  const objectivelist = async (userId: string) => {
    try {
      const response = await axios.get(
        BaseUrl + 'api/objectives/list/objectivesheirById/' + userId,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          },
        }
      );
      setObjectives(response.data);
    } catch (error) {
      // console.log('Error fetching objectives:', error);
    }
  };

  return (
    <>
      <div style={{ border: "1px solid #ddd", borderRadius: "5px" }}>
        {/* {loading && <Spin className='justify-center align-center mt-5' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />} */}
        {loading && <Spin className='justify-center align-center mt-5' />}
        <img src="assets/images/expand.png" alt="" className='expand_img p-1 cur-pointer' onClick={handleExpand} />
        <div id="hierarchy_container" style={{ width: '100%', height: `${containerHeight}px` }} className='cur-pointer' onClick={() => handleCardClick(selectedUser)}>

        </div>
        <div className='zoom_fun p-1'>
          <img src="assets/images/zoomin.svg" alt="" className='mb-1  cur-pointer' onClick={handleZoomIn} />
          <img src="assets/images/zoomline.svg" alt="" className='' />
          <img src="assets/images/zoomout.svg" alt="" className='mt-1  cur-pointer' onClick={handleZoomOut} />
        </div>
      </div>

      <Flex className='mt-3'>
        {selectedUser && (
          <Card className="employee_card" >
            <div className="text-center">
              {/* <Tooltip title="3 done / 3 in progress / 4 to do">
              <Progress percent={60} strokeColor={'#c2fbff'} success={{ percent: 30, strokeColor: '#32b7d9' }} type="circle" />
              </Tooltip> */}
              <div className="cricle_progress">
                <Progress percent={60} strokeColor={'#c2fbff'} trailColor={'#fff'} success={{ percent: 30, strokeColor: '#32b7d9' }} type="circle" ></Progress>
                <div className=" mx-auto">
                  {/* <Avatar src={"assets/images/Avatar1.png"} className="member-avatar" /> */}
                  <img src={selectedUser.img ? FileUrl + selectedUser.img : "assets/images/Default_user.svg"} className="member-avatar" onError={handleImageError} />
                </div>
              </div>


              <p className="p-0 m-0 mb-1 mt-1 fs-16 fw-700">{selectedUser.userName}</p>
              <p className="p-0 m-0 mb-1 mt-1 fs-14">{selectedUser.employeeId}</p>
              <Tooltip placement="top" title={selectedUser.designation} arrow={true}>
                <p className="p-0 m-0 mb-1 mt-1 fs-14 tooltip_name">{selectedUser.designation}</p>
              </Tooltip>
              <Tooltip placement="top" title={selectedUser.divisonName} arrow={true}>
                <p className="p-0 m-0 mb-1 mt-1 fs-14 tooltip_name">{selectedUser.divisonName}</p>
              </Tooltip>
              {/* <p className="p-0 m-0 mb-1 mt-1 fs-14 tooltip_name">{selectedUser.divisonName}</p> */}
              <div className="d-flex justify-center">
                <img src="assets/images/crown.svg" alt="" />
                <p className="p-0 m-0 fs-14 pl-1">{selectedUser.count}</p> {/* the count will replaced here*/}
              </div>
            </div>
          </Card>
        )}
        <div className="objective_overall mt-3">
          <Flex >
            {objectives.length > 0 ? (objectives.map((obj: ObjectiveData, index: number) => (

              <div key={index}>
                <div className={` ${krVisible === index ? "member_card_active" : "member_card"}`} onClick={() => { handleKrVisible(index); getassignedkeyforobjectives(obj.id); LeadMeasureData() }}>
                  <Flex justify="space-between" align="center">
                    <div className="container_label">
                      <div className="wrapper_label align-center mb-1">
                        <span className={` ${krVisible === index ? "cup_org_bg_active align-center" : "cup_org_bg_active align-center"}`}>
                          <img src="assets/images/crown.svg" className="mr-1" alt="" />
                          {obj.name}
                        </span>
                        <span className={` ${krVisible === index ? "triangle_active" : "triangle_active"}`}></span>
                      </div>
                    </div>
                    <div>
                      {/* <img src="assets/images/menu.svg" alt="" className="pl-2" /> */}
                    </div>
                  </Flex>
                  <Row>
                    <p className="fs-16 fw-500 member_objname">
                      {obj.objectivename}
                    </p>
                  </Row>
                  <Row className="justify-center">
                    <p className="fs-10 text-center">
                      {obj.objectivesuser_list.map((userlist, index) => {
                        if (userlist.userId === selectedUser.id) {
                          return <>
                            Weightage : <span className="fs-16 fw-600">{userlist.weightage} %</span>
                          </>;
                        } else {
                          // You can optionally render something else for non-matching IDs
                          return null; // or return an alternative JSX element
                        }
                      })}
                    </p>
                  </Row>
                </div>
              </div>
            ))) : (<>
              {/* <p>No Objectives Are Assigned</p> */}
              <Card className="Asp_nc_card w-100">
                <Row className="text-center obj_textbox">
                  <span className="c_AAA fs-14 fw-600">Assigned objectives not created yet.</span>
                </Row>
              </Card></>)}
          </Flex>
        </div>

      </Flex>
      <Row>
        {objectives.map((obj: ObjectiveData, index: number) => (
          <div key={index}>
            {krVisible === index && (
              <p>
                {krs.filter(kr => kr.objectiveid === obj.id).length} Key Results for
                <b> {obj.objectivename}</b>
              </p>
            )}
          </div>
        ))}
      </Row>

      {objectives.map((obj: ObjectiveData, index: number) => (
        <div key={index}>
          {krVisible === index && krs.filter(kr => kr.objectiveid === obj.id).map((kr: kr, krIndex: number) => (
            <div key={krIndex}>
              <Row className="card p-2 mt-2 mb-2">
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Row>
                    <div className="container_label">
                      <div className="wrapper_label align-center mb-1">
                        <span className="cup_org_bg align-center">
                          <img src="assets/images/KRA-Icon.svg" alt="" />
                          {kr.keyid}
                        </span>
                        <span className="triangle"></span>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <p className="fs-16 c-000 m-0 cur-pointer">
                        {kr.keytitle}
                      </p>
                      <Row className="align-center">
                        <Col
                          xl={12}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="align-center mt-3"
                        >
                          <span className="mr-1">Created by</span>
                          <Tooltip placement="top" title={kr.createdbyempname} arrow={true}>
                            <img
                              src={kr.createdByEmpProfilePath ? FileUrl + kr.createdByEmpProfilePath : "assets/images/Default_user.svg"}
                              onError={handleImageError}
                              alt=""
                              className="kr_user"
                            />
                          </Tooltip>
                        </Col>
                        <Col
                          xl={12}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="align-center mt-3"
                        >
                          <span className="mr-1">Cascaded to</span>

                          {/* {krs && krs.length > 0 && krs.map((kr) => ( */}
                          <React.Fragment key={kr.id}> {/* Assuming kr.key is a unique identifier */}
                            {kr.keyResultUsersListAssignedTo.length > 2 ? (
                              <Avatar.Group
                                maxCount={2}
                                maxStyle={{ color: "#000", backgroundColor: "#fff" }}>
                                {kr.keyResultUsersListAssignedTo.slice(0, kr.keyResultUsersListAssignedTo.length).map((user: any, index) => (
                                  <>
                                    <Tooltip placement="top" title={user.username} arrow={true}>
                                      <img src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"} className="avatar" key={index} onError={handleImageError} />
                                    </Tooltip>
                                  </>
                                ))}
                              </Avatar.Group>
                            ) : (
                              kr.keyResultUsersListAssignedTo.map((user: any, index) => (
                                <Tooltip placement="top" title={user.username} arrow={true}>
                                  <img
                                    src={user.profilePath ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                                    className="avatar"
                                    key={index}
                                    onError={handleImageError}
                                  />
                                </Tooltip>
                              ))
                            )}
                          </React.Fragment>


                        </Col>
                      </Row>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24} className="pl-2">
                      <Row className="obj-progress">
                        <Progress className="" percent={0} />
                      </Row>
                      <Row className="ml-1">
                        <Col
                          xl={12}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="align-center mt-2"
                        >
                          <img src="assets/images/crown.svg" alt="" />
                          <span className="ml-1">{obj.name}</span>
                        </Col>
                        <Col
                          xl={12}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="align-center mt-2"
                        >
                          <img src="assets/images/date-picker1.svg" alt="" />
                          {/* <span className="ml-1">{kr.cycle}</span> */}
                          <span className="ml-1">{kr.quarter} {obj.year}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                  <Col xl={12}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}>
                        </Col>
                  
                  <Col xl={12}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}>
                    <Row className="mt-2 m-0 p-0 justify-between align-center">
                      <Col xl={12}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}>
                        {obj.revertstatus !== 1 ? (

                          kr.status == 2 && getAllLeadMeasure.filter(data => data.krId === obj.id).length == 0 ?
                            (<>
                              <button className="cascade_btn align-center border-0 p-1 mr-1">
                                <span className="sm_cricle mr-1 fs-14"></span>Progressing
                              </button>
                            </>) :
                            getAllLeadMeasure.filter(data => data.krId === obj.id).length > 0 ?
                              (<>
                                <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                                  <span className="sm_pro_cricle  mr-1 fs-14"></span>In Progress
                                </button>
                              </>
                              ) :
                              (<>
                                <button className="cascade_btn  align-center border-0 p-1 mr-1">
                                  <span className="sm_cricle  mr-1 fs-14"></span>Yet To Cascade
                                </button>
                              </>
                              )


                        ) : (
                          <button className="Progressing_btn  align-center border-0 p-1 mr-1">
                            <span className="sm_pro_cricle  mr-1 "></span>Revoked
                          </button>
                        )}
                      </Col>
                      <Col xl={12}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}>
                        <label className="fs-14">KR Weightage: &nbsp;</label>
                        <span className="c_32B7D9 fs-14">{kr.keyresultweightage}%</span>

                        {/* {objectivesassignedkr.some((data)=>data.status==3)&&
                                  <span className="danger mt-1">D</span>
                                  }
                                  {objective.deletedstatus==1&&
                                  <span className="danger mt-1">D</span>
                                  } */}
                      </Col>
                    </Row>
                  </Col>
                  </Row>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  className="obj-pagination pl-2"
                >
                  <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <p className="fw-600">Lead Measures</p>
                  </Col>
                  <Row className="justify-between">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-2">
                      <div className="">
                        {getAllLeadMeasure.filter(data => data.krId === kr.id).length > 0 ? (
                          <div className="lead_measure">
                            <div className="image-pagination">
                              <ImagePagination
                                current={currentMeasureIndex[kr.id] || 1}
                                total={getAllLeadMeasure.filter(data => data.krId === kr.id).length}
                                onChange={(page: number) => handlePageChange(page, kr.id)}
                                krId={kr.id}
                                leadMeasure={getAllLeadMeasure}
                              />

                            </div>
                          </div>
                        ) : (
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <Card className="text-center c_AAA fs-14 lead_measure">No Lead Measures created yet.</Card>
                            </Col>
                          </Row>
                        )
                        }

                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ))}
    </>

  );
};

export default Hierarchy;
