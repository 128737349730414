import React, { useEffect, useState } from "react";
import { Checkbox, Progress, Input, Button, Tooltip, Modal, message, Spin } from "antd";
import { BaseUrl, FileUrl } from "../../../../App";
import axios from "axios";
import { number, set, string } from "zod";
import { truncate } from "fs";
import { log } from "console";
interface UserData {
  name: string;
  allocated: number;
}

interface ObjData {
  primaryId: string;
  title: string;
  objectiveName: string;
  objectiveDescription: string;
  weightage: string;
  organizationStatus: string;
  subObjectiveList: subObjective[];
  assigneUserWeightage: userWeightage[];
  objsubid: string;
  objParentId: string;
  userId: string;
  quarter: string;
  year: string;
  creatorUserId: string;
}

interface reviewObjective {
  primaryId: string;
  objectiveId: string;
  objectiveName: string;
  objectiveDescription: string;
  weightage: string;
  organizationStatus: string;
}

interface subObjective {
  subObjId: string;
  parentId: string;
  title: string;
  objectiveName: string;
  objectiveDescription: string;
  weightage: string;
  organizationStatus: string;
  userId: string;
  assigneUserWeightage: userWeightage[];
  creatorUserId: string;
}

interface customWeightage {
  "id": string,
  "name": string,
  "username": string,
  "avaiableWeightage": number,
  "assignWeightage": number,
  "profilePath": string
}
interface weightage {
  "userid": string,
  "weightage": number,
  "objectiveid": string
}
interface userWeightage {
  objectiveId: string,
  userId: string,
  weightage: number,
  title: string
}
interface OptionItem {
  value: string;
  label: string;
  image: string;
}
const userData: UserData[] = [
  { name: "John", allocated: 40 },
  { name: "Alice", allocated: 100 },
  { name: "Alice", allocated: 120 },
];

interface setReviewCascade {
  setReviewCascade: React.Dispatch<React.SetStateAction<boolean>>;
}
const MyObjWeightage: React.FC<setReviewCascade> = ({ setReviewCascade }) => {
  const [showInputs, setShowInputs] = useState<
    { userIndex: string; objIndex: string, isParent: boolean; }[]
  >([]);
  //   const [showInputs, setShowInputs] = useState<
  //   { userIndex: string; objIndex: string }[]
  // >([]);
  const [loading, setLoading] = useState(true);
  const [weightages, setWeightages] = useState<
    Record<string, Record<string, number>>
  >({});
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  // const [shownParentObj, setShownParentObj] = useState<string | null>(null);
  const [shownParentObj, setShownParentObj] = useState<subObjective[]>([]);
  const [renderedParentObjs, setRenderedParentObjs] = useState<subObjective[]>([]);
  const [showChildColumnIndex, setShowChildColumnIndex] = useState<
    number | null
  >(null);

  const [showReviewObjective, setShowReviewObjective] = useState<ObjData[]>([
    {
      primaryId: "",
      title: "",
      objectiveName: "",
      objectiveDescription: "",
      weightage: "",
      organizationStatus: "",
      subObjectiveList: [],
      assigneUserWeightage: [],
      objsubid: "",
      objParentId: "",
      userId: "",
      quarter: "",
      year: "",
      creatorUserId: ""
    }
  ]);
  const [objectiveUser, setObjectiveUser] = useState<userWeightage[]>([]);
  const [showObjectiveUser, setShowObjectiveUser] = useState<userWeightage[]>([]);
  const [data, setData] = useState<userWeightage[]>([]);
  const [goBackCount, setGoBackCount] = useState(0);
  const [usersList, setUsersList] = useState<customWeightage[]>([]);
  const [MyusersList, setMyUsersList] = useState<customWeightage[]>([]);
  const [assignUserWeightage, setAssignUserWeightage] = useState<weightage[]>([]);

  const toggleInput = (userIndex: number, objIndex: number, objectiveId: string, titleId: string, isParent: boolean, userId: string, value: number) => {
    // setShowInputs((prevShowInputs) => {
    //   // If input already exists, remove it, otherwise add it
    //   const exists = prevShowInputs.some(
    //     (input) => input.userIndex === userId && input.objIndex === objectiveId && input.isParent === isParent
    //   );
    //   if (exists) {
    //     return prevShowInputs.filter(
    //       (input) => !(input.userIndex === userId && input.objIndex === objectiveId && input.isParent === isParent)
    //     );
    //   }
    //    else {
    //     return [...prevShowInputs, { userIndex, objIndex, isParent }];
    //   }
    // });
    setShowInputs((prevShowInputs) => {
      // Ensure userId and objectiveId are strings
      const userIdStr = String(userId);
      const objectiveIdStr = String(objectiveId);

      // Check if the input already exists
      const exists = prevShowInputs.some(
        (input) => input.userIndex === userIdStr && input.objIndex === objectiveIdStr && input.isParent === isParent
      );

      if (exists) {
        // Remove the existing input
        return prevShowInputs.filter(
          (input) => !(input.userIndex === userIdStr && input.objIndex === objectiveIdStr && input.isParent === isParent)
        );
      } else {
        // Add the new input
        return [...prevShowInputs, { userIndex: userIdStr, objIndex: objectiveIdStr, isParent }];
      }
    });


    let assignWeightageUser: string = "";
    saveAndCascadeUser.forEach((user, index) => {
      if (index === userIndex) {
        assignWeightageUser = user;
      }
    })
    const selectedUser: userWeightage = {
      objectiveId: objectiveId,
      userId: userId,
      weightage: 5,
      title: titleId
    }
    setGoBackCount(1);
    setShowReviewObjective((prevWeightages) =>
      prevWeightages.map((data) => {
        if (data.primaryId === objectiveId) {
          return { ...data, assigneUserWeightage: [...data.assigneUserWeightage, selectedUser] };
        }
        data.subObjectiveList.map((subObj) => {
          if (subObj.subObjId === objectiveId) {
            return { ...subObj, assigneUserWeightage: [...subObj.assigneUserWeightage, selectedUser] };
          }
        })
        return data;
      })
    );
    let values: string = value.toString();
    if (!values || values === "null") {
      values = "0";
    }
    setUsersList(prevUsersList => {
      return prevUsersList.map(user => {
        if (user.id === userId) {
          const newWeightage = user.assignWeightage + parseInt(values, 10);
          return {
            ...user,
            assignWeightage: newWeightage,
            avaiableWeightage: 100 - newWeightage
          };
        }
        return user;
      });
    });
  };

  const [userweightage, setuserweightage] = useState<weightage[]>([])
  const [getuserweightage, setgetuserweightage] = useState<userWeightage[]>([])
  const assign_remove = (
    userIndex: number,
    objIndex: number,
    objectiveId: string,
    userId: string,
    titleId: string,
    isParent: boolean) => {
    // setShowInputs((prevShowInputs) =>
    //   prevShowInputs.filter(
    //     (input) =>
    //       !(input.userIndex === userIndex && input.objIndex === objIndex)
    //   )
    // );
    const selectedUser: userWeightage = {
      objectiveId: objectiveId,
      userId: userId,
      weightage: 0,
      title: titleId,
    }
    //
    const objectivelist = showReviewObjective
      .map(data => {
        // Filter the inner assigneUserWeightage array
        // const filteredAssigneUserWeightage = data.assigneUserWeightage.filter(
        //   item => item.objectiveId !== objectiveId && item.userId !== userId
        // );
        // Return a new object with the filtered assigneUserWeightage array
        setgetuserweightage(data.assigneUserWeightage);
        return {
          ...data,
          assigneUserWeightage: data.assigneUserWeightage
        };

      });
      const objective = showReviewObjective.find(data => data.primaryId === objectiveId);
      let lengthAssignObj = 0;
      let ObjectiveName= '';
    if (objective) {
      lengthAssignObj=objective.assigneUserWeightage.length;
      ObjectiveName=objective.objectiveName;
      
    }
    if (lengthAssignObj <= 1 ) {
      Modal.error({
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">"{ObjectiveName}"</p>
            <p className="fs-18"><b>Note : </b>AssignMembers is Mandatory</p>
          </>
        ),
        centered: true,
      })
    }else{
    removeObjectiveWeightage(objectiveId, userId, userIndex, objIndex, isParent);
  }
      //console.log("remove_objective_status",lengthAssignObj);
    // setShowReviewObjective(objectivelist)
    setShowInputs((prevShowInputs) =>
      prevShowInputs.map((input) => {
        if (input.userIndex == userId && input.objIndex == objectiveId) {
          return {
            ...input,
            isParent: isParent // or whatever value you want to set for isParent
          };
        }
        return input; // Return unchanged input if it doesn't match
      })
    );
    // showReviewObjective.map((reviewUser) => {
    //   reviewUser.assigneUserWeightage.map((userWeighatage) => {
    //     if (userWeighatage.objectiveId === objectiveId && userWeighatage.userId === userId) {
    //       // userWeighatage.weightage = uniqueObjectives[0].weightage;
    //       // userWeighatage.weightage = ;
    //     }
    //   })
    // })

    setObjectiveUser([]);
    setGoBackCount(1);

    const userdatass = userweightage.filter(data => data.objectiveid != objectiveId && data.userid == userId)
    setuserweightage(userdatass);
    const weightage = userdatass?.map(userdata => userdata.weightage).reduce((total: number, weight: number) => total + weight, 0) ?? 0;
    setUsersList(prevUsersList => {
      return prevUsersList.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            assignWeightage: weightage,
            avaiableWeightage: 100 - weightage
          };
        }
        return user;
      });
    });
  };

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   userIndex: number,
  //   objIndex: number,
  //   objectiveId: string,
  //   userId: string,
  //   title: string,
  //   // userWeightage: userWeightage[],
  //   isParent: boolean,
  // ) => {
  //   const { value } = e.target;
  //   const selectedUser: userWeightage = {
  //     objectiveId: objectiveId,
  //     userId: userId,
  //     weightage: parseInt(e.target.value),
  //     title: title,
  //   }
  //   objectiveUser.push(selectedUser);
  //   let values: string = e.target.value;
  //   if (e.target.value === null || e.target.value === "") {
  //     values = "0";
  //   }
  //   setUsersList(prevUsersList => {
  //     return prevUsersList.map(user => {
  //       if (user.id === userId) {
  //         console.log(userId);
  //         return {
  //           ...user,
  //           avaiableWeightage: user.avaiableWeightage-parseInt(values)
  //         };
  //       }
  //       return user;
  //     });
  //   });
  //   showReviewObjective.map((reviewUser) => {
  //     // parent org or obj user weightage
  //     reviewUser.assigneUserWeightage.map((userWeighatage) => {
  //       if (userWeighatage.objectiveId === objectiveId && userWeighatage.userId === userId) {
  //         userWeighatage.weightage = Number(value);
  //       }
  //     })
  //     // each sub obj user weightage
  //     reviewUser.subObjectiveList.map((showSubObjWeightage) => {
  //       showSubObjWeightage.assigneUserWeightage.map((subObjWeightage) => {
  //         if (subObjWeightage.objectiveId === objectiveId && subObjWeightage.userId === userId) {
  //           subObjWeightage.weightage = Number(value);
  //           console.log("subObjWeightage", subObjWeightage);
  //         }
  //       })
  //     })
  //   })
  //   setShowReviewObjective(showReviewObjective);
  //   setWeightages((prevWeightages) => ({
  //     ...prevWeightages,
  //     [userIndex]: {
  //       ...prevWeightages[userIndex],
  //       [objIndex]: Number(value),
  //     },
  //   }));

  //   setGoBackCount(1);
  // };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | number,
    userIndex: number,
    objIndex: number,
    objectiveId: string,
    userId: string,
    title: string,
    // userWeightage: userWeightage[],
    isParent: boolean,
  ) => {
    var value: number;
    if (typeof e === 'number') {
      value = e;
    } else {
      value = parseInt(e.target.value, 10);
      if (isNaN(value)) {
        value = 0;
      }
    }
    // console.log(value);
    const selectedUser: userWeightage = {
      objectiveId,
      userId,
      weightage: value,
      title,
    };
    for (let i = userweightage.length - 1; i >= 0; i--) {
      if (userweightage[i].userid === userId && userweightage[i].objectiveid === objectiveId) {
        userweightage.splice(i, 1);
      }
    }
    for (let i = assignUserWeightage.length - 1; i >= 0; i--) {
      if (assignUserWeightage[i].userid === userId && assignUserWeightage[i].objectiveid === objectiveId) {
        assignUserWeightage.splice(i, 1);
      }
    }
    // setuserweightage(objectivedetails);
    const userdata: weightage = {
      objectiveid: objectiveId,
      userid: userId,
      weightage: value,
    }
    userweightage.push(userdata);
    objectiveUser.push(selectedUser);

    let values: string = value.toString();
    if (!values || values === "null") {
      values = "0";
    }



    // var data:number;
    // usersList.map((user) => {
    //   if(user.id === userId) {
    //     assignUserWeightage.map((assignUser) => {
    //     if (assignUser.id === user.id) {
    //       data = value;
    //       console.log("data", assignUser.assignWeightage);
    //     }
    //   });
    //   console.log("nothithihtih"+value);
    //   user.assignWeightage = data;
    //   return user;
    //  }
    // });

    // setUsersList(usersList);

    // setAssignUserWeightage(prevAssignUserWeightage => {
    //   return prevAssignUserWeightage.map(user => {
    //     if (user.id === userId) {
    //       return {
    //         ...user,
    //         assignWeightage: user.assignWeightage,
    //       };
    //     }
    //     return user;
    //   });
    // });
    // setUsersList(prevUsersList => {
    //   return prevUsersList.map(user => {
    //     if (user.id === userId) {
    //       const newWeightage = user.assignWeightage + parseInt(values, 10);
    //       console.log(`userId: ${userId}, value: ${values}, currentWeightage: ${user.avaiableWeightage}, newWeightage: ${newWeightage}`);
    //       console.log("user", user.assignWeightage);
    //       console.log("user", user.assignWeightage + values);
    //       return {
    //         ...user,
    //         assignWeightage: newWeightage,
    //         // assignWeightage: user.assignWeightage - parseInt(values, 10),
    //       };
    //     }
    //     return user;
    //   });
    // });

    setObjectiveUser((prevObjectiveUser) => {
      const updatedObjectiveUser = prevObjectiveUser.filter(
        (user) => !(user.userId === userId && user.objectiveId === objectiveId)
      );
      return [...updatedObjectiveUser, selectedUser];
    });
    setShowReviewObjective((prevShowReviewObjective) => {
      return prevShowReviewObjective.map((reviewUser) => {
        const updatedAssigneUserWeightage = reviewUser.assigneUserWeightage.map((userWeighatage) => {
          if (userWeighatage.objectiveId === objectiveId && userWeighatage.userId === userId) {
            var weigh: number = 0;
            if (showInputs.every(userdata => userdata.userIndex == userId && userdata.objIndex == objectiveId && userdata.isParent == isParent) == true) {
              weigh = value;
            }
            else {
              weigh = userWeighatage.weightage + value;
            }
            return { ...userWeighatage, weightage: value };
          }
          return userWeighatage;
        });
        const updatedSubObjectiveList = reviewUser.subObjectiveList.map((showSubObjWeightage) => {
          const updatedSubObjWeightage = showSubObjWeightage.assigneUserWeightage.map((subObjWeightage) => {
            if (subObjWeightage.objectiveId === objectiveId && subObjWeightage.userId === userId) {
              subObjWeightage.weightage = value;
              return { ...subObjWeightage };
            }
            return subObjWeightage;
          });
          return { ...showSubObjWeightage, assigneUserWeightage: updatedSubObjWeightage };
        });
        return {
          ...reviewUser,
          assigneUserWeightage: updatedAssigneUserWeightage,
          subObjectiveList: updatedSubObjectiveList,
        };
      });
    });
    setUsersList(prevUsersList =>
      prevUsersList.map(user => {
        var totalWeightage: number = 0
        const values = userweightage.filter(data => data.userid == user.id)
        userweightage.forEach(data => console.log(data))

        const weightage = values?.map(userdata => userdata.weightage).reduce((total: number, weight: number) => total + weight, 0) ?? 0;
        if (weightage) {
          totalWeightage = weightage;
        }
        if (user.id === userId) {
          const assignUser = assignUserWeightage.find(au => au.userid === user.id);
          if (assignUser) {
            user.assignWeightage = totalWeightage;
            user.avaiableWeightage = 100 - totalWeightage;
          }
        }
        return user;
      })
    );
    setWeightages((prevWeightages) => ({
      ...prevWeightages,
      [userIndex]: {
        ...prevWeightages[userIndex],
        [objIndex]: value,
      },
    }));
    setGoBackCount(1);
  };
  const handleCascadeSelected = (objectiveStatus: number) => {
    if (objectiveStatus === 0) {
      cascadeAll();
    } else {
      saveAndReviewObjective("3");
    }
    const selectedData = userData.filter((_, index) =>
      selectedRows.includes(index)
    );
  };

  const onCheck = (e: any, userIndex: number) => {
    if (e.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, userIndex]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== userIndex)
      );
    }
  };
  // const [layerActive, setLayerActive] = useState(false);
  const [layerActive, setLayerActive] = useState<number | null>(null);

  const toggleParentObj = (parentObj: subObjective[], columnIndex: number, objAssignor: ObjData, index: number) => {
    // Check if primaryId === parentObjectiveId
    const isPrimaryEqualToParent = parentObj.some(subobjective => objAssignor.primaryId === subobjective.parentId);
    // Set layerActive based on the condition    
    // setLayerActive(!layerActive);        
    // setLayerActive(prevLayerActive => !prevLayerActive);
    setLayerActive(prevIndex => (prevIndex === index ? null : index));

    // setLayerActive(isPrimaryEqualToParent); 

    // Set shownParentObj and showChildColumnIndex    
    if (isPrimaryEqualToParent) {
      // If primaryId matches parentObjectiveId, set shownParentObj to parentObj    
      setShownParentObj(parentObj);
      // Set showChildColumnIndex to the current columnIndex
      setShowChildColumnIndex(columnIndex);
    } else {
      // If primaryId does not match parentObjectiveId, reset shownParentObj and showChildColumnIndex
      setShownParentObj([]);
      setShowChildColumnIndex(null);
    }
  };


  const renderChildObjects = (childObjective: subObjective[]) => {
    // if (parentObj !== null) {
    //   // Find all subobjects with the specified parent object
    //   // const subObjects = objData.filter((obj) => obj.parentobj === parentObj);
    //   const subObjects  = showReviewObjective.filter((obj) => obj.primaryId === )

    //   // Update renderedParentObjs with both parent and subobject names
    //   setRenderedParentObjs((prevRenderedParentObjs) => [
    //     ...prevRenderedParentObjs,
    //     parentObj,
    //     ...subObjects.map((obj) => obj.objname),
    //   ]);
    // }
    setRenderedParentObjs(childObjective);
  };
  const goback = () => {
    if (goBackCount === 0) {
      setReviewCascade(true);
    } else {
      Modal.confirm({
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">"Do you want to save the changes?"</p>
          </>
        ),
        okText: "Save",
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          { selectedRows.length === 0 ? (handleCascadeSelected(1)) : handleCascadeSelected(2) }
        },
        onCancel() {
          setReviewCascade(true);
        },
      });
    }
  };


  const [reviewAndCascadeId, setReviewAndCascadeId] = useState<string[]>([])
  const [subObjreviewAndCascade, setSubObjReviewAndCascade] = useState<string[]>([])
  const [subObjUserAssign, setSubObjUserAssign] = useState<subObjective[]>([])
  const showReviewAndCascadeObjectives = async () => {
    try {
    const apiUrl = BaseUrl + `api/reviewcascade/view/objective`;
    axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
    ).then((response) => {
      const weightageusers: weightage[] = [];
      setShowReviewObjective(response.data.data);
      const inputs: { userIndex: string; objIndex: string, isParent: boolean; }[] = [];
      response.data.data.map((userWeightage: ObjData, objindex: number) => {
        // setShowObobjindexjectiveUser(userWeightage.assigneUserWeightage);
        userWeightage.assigneUserWeightage.map((userWeightage, userindex: number) => {
          const userdetails = {
            userid: userWeightage.userId,
            weightage: userWeightage.weightage,
            objectiveid: userWeightage.objectiveId
          }
          weightageusers.push(userdetails);
          const showinputs = {
            userIndex: userWeightage.userId,
            objIndex: userWeightage.objectiveId,
            isParent: true
          }
          // showInputs.forEach((input) => {console.log("input",input)});
          inputs.push(showinputs);
          // setShowInputs(showInputs);
        });

        if (userWeightage.primaryId !== null) {
          reviewAndCascadeId.push(userWeightage.primaryId);
        }
        userWeightage.subObjectiveList.map((subObjective) => {
          reviewAndCascadeId.push(subObjective.subObjId);
          subObjreviewAndCascade.push(subObjective.subObjId);

          subObjective.assigneUserWeightage.map((subObjUser) => {
            const userdetails = {
              userid: subObjUser.userId,
              weightage: subObjUser.weightage,
              objectiveid: subObjUser.objectiveId
            }
            const showinputs = {
              userIndex: subObjUser.userId,
              objIndex: subObjUser.objectiveId,
              isParent: false
            }
            inputs.push(showinputs);
            weightageusers.push(userdetails);
          })
        })


        // setSubObjUserAssign(userWeightage.subObjectiveList);
      })
      setShowInputs(inputs)
      setuserweightage(weightageusers);
      setAssignUserWeightage(weightageusers);
      setLoading(false);

    })
  } catch (error) {
      console.log("Error", error);     
  }
  }


  const [saveAndCascadeUser, setSaveAndCascadeUser] = useState<string[]>([]);
  const userLists = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const userIdList = response.data;
      const IdList: string[] = [];
      userIdList.map((user: any) => {
        IdList.push(user.id);
      });
      IdList.push(localStorage.getItem("loginId") ?? "");
      weightageUserList(IdList);
      setSaveAndCascadeUser(IdList);
    } catch (err) {
      // console.log(err)
    }
  }



  const weightageUserList = async (userid: string[]) => {
    try {
      const apiUrl = BaseUrl + `api/users/selected_users`;
      await axios.post(apiUrl, userid, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          const addedCustomWeightage: customWeightage[] = [];
          const myaddedCustomWeightage: customWeightage[] = [];
          response.data.map((res: customWeightage, index: number) => {
            if (res.id === localStorage.loginId) {
              const customWeights = {
                id: res.id,
                name: res.name,
                profilePath: res.profilePath,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: res.assignWeightage,
              }
              myaddedCustomWeightage.push(customWeights);
            } else {
              const customWeights = {
                id: res.id,
                name: res.name,
                profilePath: res.profilePath,
                username: res.name,
                avaiableWeightage: res.avaiableWeightage,
                assignWeightage: res.assignWeightage,
              }
              addedCustomWeightage.push(customWeights);
            }
          })
          setUsersList(addedCustomWeightage);
          setMyUsersList(myaddedCustomWeightage);
          // setAssignUserWeightage(addedCustomWeightage);
        })
    } catch (err) {
      // console.log(err)
    }
  }

  const [zeroWeightageCascade, setZeroWeightageCascade] = useState<number>(0);
  const assignWeightObjective = async () => {
    const apiUrl = BaseUrl + `api/objectives/weightagemasterobjective/${3}`;
    await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then((response) => {
      setZeroWeightageCascade(response.data.status);
    })
  }

  const saveAndReviewObjective = async (status: string) => {
    const apiUrl = BaseUrl + `api/reviewcascade/update/weightage`
    try {
      await axios.post(apiUrl, {
        "status": status,
        "assignedUserWeightageList": objectiveUser,
        "cascadeIds": reviewAndCascadeId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {

        if (zeroWeightageCascade === 0) {
          let checkZeroWeightage: userWeightage = {
            objectiveId: '',
            userId: '',
            weightage: 0,
            title: '',
          };
          showReviewObjective.map((reviewUser) => {
            reviewUser.assigneUserWeightage.map((userWeighatage) => {
              if (userWeighatage.weightage === 0) {
                checkZeroWeightage = userWeighatage;
              }
            })
          })
          // if (checkZeroWeightage !== null) {
          // deleteOrgObjective(checkZeroWeightage);
          // }
        }

      })
      setReviewCascade(true);
    } catch (error) {
      // console.log("Error", error);
    }
  }
  const cascadeAllOrgAndObjList = async () => {
    try {

      const apiUrl = BaseUrl + `api/reviewcascade/all/objectives`;


      await axios.post(apiUrl, {
        "orgIds": reviewAndCascadeId,
        "objIds": subObjreviewAndCascade
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        setReviewCascade(true);
      })
    } catch (error) {
      // console.log('Error', error);
    }
  }

  const cascadeAll = () => {
    Modal.confirm({
      title: 'All you sure want to cascade All ? ',
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">"Please check  user weightage"</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
        </>
      ),
      okText: 'Cascade All',
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
                Cascade All objective successfully.
              </span>
            </div>
          ),
          duration: 3,
          icon: null,
        });
        if (goBackCount === 0) {
          cascadeAllOrgAndObjList()
        } else {
          saveAndReviewObjective("3");
        }
        // console.log('Cascade action confirmed');
      },
    });
  }

  // const deleteOrgObjective = (userWeighatage: userWeightage) => {
  //   if (userWeighatage.weightage === 0) {
  //     Modal.confirm({
  //       title: 'Please check ' + userWeighatage.title + ' user weightage ?',
  //       content: (
  //         <>
  //           {/* <p className="fs-16 mb-3 keytitle_scroll">"{organizationObjective.objectivename}"</p> */}
  //           <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
  //         </>
  //       ),
  //       okText: 'Ok',
  //       // cancelText: '',
  //       okButtonProps: { className: 'C_btn' },
  //       cancelButtonProps: { style: { display: "none" } },
  //       centered: true,
  //       onOk() {
  //       },
  //     });
  //   }




  const removeObjectiveWeightage = async (objectiveId: string, userId: string, userIndex: number, objIndex: number, isParent: boolean) => {
    const apiUrl = BaseUrl + `api/reviewcascade/remove/weightage`;
    try {
      const formData = new FormData();
      formData.append("objectiveId", objectiveId);
      formData.append("userId", userId);
      await axios.post(apiUrl, formData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
        // setTimeout(() => {
        //   showReviewAndCascadeObjectives();
        // }, 2000);
        const objIndexStr: string = objIndex.toString();
        showInputs.some((input) => {
          if (input.userIndex === objIndexStr && input.objIndex === objIndexStr) {
          }
        })


      })
    } catch (error) {
      // console.log('Error', error);
    }
  }

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year, setyear] = useState(getyearFromLocalStorage())
  useEffect(() => {
    const handleStorageChange = () => {
      setQuarter(getQuarterFromLocalStorage());
      setyear(getyearFromLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [showInputs]);


  const addObjectiveCFT = async (oabjectiveId: string, userId: string) => {
    const apiUrl = BaseUrl + `api/reviewcascade/add/objuser`;
    var weightage = "0";
    const title = "test";
    const formData = new FormData();
    formData.append("objectiveId", oabjectiveId);
    formData.append("userId", userId);
    formData.append("weightage", weightage);
    formData.append("title", title);
    try {
      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
        setTimeout(() => {
          showReviewAndCascadeObjectives();
        }, 2000);
      })
    } catch (error) {
      // console.log('Error', error);
    }
  }

  const ObjectiveMyselt = async (oabjectiveId: string, userId: string) => {
    const apiUrl = BaseUrl + `api/reviewcascade/add/myselfobjuser`;
    var weightage = "0";
    const title = "test";
    const formData = new FormData();
    formData.append("objectiveId", oabjectiveId);
    formData.append("userId", userId);
    formData.append("weightage", weightage);
    formData.append("title", title);
    try {
      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
        setTimeout(() => {
          showReviewAndCascadeObjectives();
        }, 2000);
      })
    } catch (error) {
      // console.log('Error', error);
    }
  }

  const [fhuseroption, setfhusersoption] = useState<OptionItem[]>([]);
  const fhuserslist = async () => {
    try {
      const fhusers = await axios.get(BaseUrl + "api/users/FHuserslist", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = fhusers.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        return {
          value: id.id,
          label: id.name,
          image: id.profilePath, // Assuming you have an image property for each user
          weightage: 0
        };
      });
      setfhusersoption(newOptions);
    } catch (error) {
      // console.error(error)
    }
  }
  const assignWeightageExistObjective = async (selectedUser: userWeightage) => {
    const apiUrl = BaseUrl + `api/reviewcascade/assignee/user`;
    try {

      await axios.post(apiUrl, selectedUser, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then((response) => {
        // console.log(response.data.data);
      })
    } catch (error) {
      // console.log("ERROR", error);
    }
  }

  useEffect(() => {
    userLists();
    fhuserslist();
    showReviewAndCascadeObjectives();
    //userRequestSignOffStatus();
  }, [year, quarter]);

  const [subObjAssignWeightage, setSubObjAssignWeightage] = useState<userWeightage>();
  const [downUpIcon, setDownUpIcon] = useState(false)
  const handleDownicon = () => {
    setDownUpIcon(downUpIcon)
  }

  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const [arrowMyself, setArrowMyself] = useState(true);
  const [arrowTM, setArrowTM] = useState(false);
  const [arrowCFT, setArrowCFT] = useState(false);

  const handleArrowMyself = () => {
    setArrowMyself(!arrowMyself);
  };
  const handleArrowTM = () => {
    setArrowTM(!arrowTM);
  };
  const handleArrowCFT = () => {
    setArrowCFT(!arrowCFT);
  };

  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  return (
    <>
      {/* (userSignOff.signOffStatus === 0 && ( */}
      <div className="d-flex justify-end mx-3 align-center add_obj_btn">
        <span className="goback-btn" onClick={goback}>
          Go back
        </span>

        {/* {selectedRows.length === 0 && (
          <Button onClick={() => handleCascadeSelected("Save")} className="ml-3 cyan_btn ">
            Save Changes
          </Button>
        )}
        {selectedRows.length > 0 && (
          <Button onClick={() => handleCascadeSelected("Cascade")} className="ml-3 cyan_btn ">
            Cascade Selected ({selectedRows.length})
          </Button>
        )} */}

        {userSignOff.signOffStatus === 0 && 
        zeroWeightageCascade === 0 ? (
          <Button onClick={() => handleCascadeSelected(0)} className="ml-3 cyan_btn ">
            Cascade All
          </Button>) : (
          <Button onClick={() => handleCascadeSelected(1)} className="ml-3 cyan_btn ">
            Cascade All
          </Button>
        )}
        {/* ) : (<></>) */}
        {/* ) */}
        

      </div>
      <div className={loading ? "mt-5 " : "m-0 "}>
        {loading && <Spin className='justify-center align-center mt-5' tip="Loading" size="large">
        </Spin>}
        <div className="table-container myobjweightage">
          <table className="myobjweightage-table">
            <thead>
              <tr>
                <th></th>
                {/* {getParentAndChild(showReviewObjective :  objData[]).map((obj, index) => ( */}
                {showReviewObjective.length !== 0 && showReviewObjective.map((obj, index) => (
                  <React.Fragment key={index}>
                    <th className={showChildColumnIndex !== null ? "p-0 pl-2" : ""}>
                      {obj.primaryId !== null && obj.quarter == quarter && obj.year == year ? (
                        <div className={`myobj-card d-flex flex-column justify-between ${layerActive && showChildColumnIndex === index ? "br_31b5d6" : ""}`}>
                          <div>
                            <div className="d-flex justify-between w-100">
                              <div className="wrapper_label align-center mb-2 ">
                                <span className="cup_org_bg align-center">
                                  <img
                                    src={obj.title.substring(0, 2) === "OR" ? "assets/images/cup.svg" : "assets/images/crown.svg"}
                                    alt=""
                                    className="pr-1"
                                  />{" "}
                                  {obj.title}
                                </span>
                                <span className="triangle"></span>
                              </div>
                              <div
                                className={`obj-status-reviewstatus${obj.organizationStatus}`}
                              ></div>
                            </div>
                            <p className="org_obj_name">{obj.objectiveName}</p>
                          </div>
                          <div className="d-flex align-center justify-between">
                            <div className="d-flex align-center">
                              {obj.weightage ? (
                                <>
                                  <img src="assets/images/Network_Icon.svg" alt="" />
                                  <span>{obj.weightage} %</span>
                                </>
                              ) : (<></>)}
                            </div>
                            {
                              obj.subObjectiveList.length > 0 && (
                                <div
                                  className={obj.subObjectiveList.length > 0 ? "d-flex align-center cur-pointer" : "d-flex align-center cur-not-allowed"}
                                  // onClick={() => toggleParentObj(index , obj.subObjectiveList, obj.subObjectiveList.length)}
                                  onClick={() => toggleParentObj(obj.subObjectiveList, index, obj, index)}
                                >

                                  <img
                                    src={
                                      layerActive && showChildColumnIndex === index
                                        ? "assets/images/layer_b.svg"
                                        : "assets/images/layer_normal.svg"
                                    }
                                    alt=""
                                  // className={layerActive[index] ? "" : ""}
                                  />
                                  <span
                                    className={`fs-14 fw-700 ${layerActive && showChildColumnIndex === index ? "c_32B7D9 text_line" : ""
                                      }`}
                                  >
                                    {obj.subObjectiveList.length} Child-Obj
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      ) : null}
                    </th>
                    {/* {showChildColumnIndex === index && ( */}

                    {layerActive === index && (
                      <>
                        {showChildColumnIndex === index && (
                          <th>
                          <div
                            className={`myobj_Childobj ${showChildColumnIndex !== null ? "p-0 d-flex" : ""}`}
                          >
                            {obj.subObjectiveList.length !== 0 && obj.subObjectiveList.map((subobjective) => (
                              <>
                                {subobjective.subObjId && obj.quarter== quarter && obj.year == year ? (
                                  <th key={subobjective.subObjId} className={`d-flex ${showChildColumnIndex !== null ? "p-0" : ""}`}>
                                    <img src="assets/images/connect_obj.svg" alt="" />
                                    <div className="myobj_weightage_subobj">
                                      <div>
                                        <div className="d-flex justify-between w-100">
                                          <div className="wrapper_label align-center mb-2 ">
                                            <span className="cup_org_bg align-center">
                                              <img
                                                src="assets/images/crown.svg"
                                                alt=""
                                                className="pr-1"
                                              />{" "}
                                              {subobjective.title}
                                            </span>
                                            <span className="triangle"></span>
                                          </div>
                                          <div
                                            className={`obj-status-${subobjective.organizationStatus}`}
                                          ></div>
                                        </div>
                                        <p className="org_obj_name">{subobjective.objectiveName}</p>
                                      </div>
                                    </div>
                                  </th>
                                ) : null}
                              </>
                            ))}
                          </div>
                          </th>
                        )}
                      </>
                    )}

                    {/* </> : ""} */}

                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="flex-row">
                <td className="br-none">
                  <div className=" align-center">
                    <img
                      src={
                        arrowMyself
                          ? "assets/images/up_arrow_accord.svg"
                          : "assets/images/down_arrow_accord.svg"
                      }
                      alt=""
                      className="cur-pointer pr-2"
                      onClick={handleArrowMyself}
                    />
                    <span className="c_32B7D9 fs-16 fw-700">Myself</span>

                  </div>
                  {/* {arrow && <p>divya</p>} */}
                </td>
              </tr>
              {arrowMyself &&
                MyusersList.map((userlist, userIndex) => (
                  <tr key={userIndex}>
                    <td>
                      <div className="d-flex custom-tooltip">
                        <Checkbox checked={true}
                          onChange={(e) => onCheck(e, userIndex)}
                        ></Checkbox>
                        <div className="fs-14 ml-2">
                          <div className="d-flex align-center mb-2">
                            <img
                              src={userlist.profilePath != null ? FileUrl + userlist.profilePath : "assets/images/Default_user.svg"}
                              alt="user"
                              className="user"
                            />
                            {/* <span className="fw-400 ml-2">{capitalizeFirstLetter(userlist.name)}</span> */}
                            <span className="fw-400 ml-2">{userlist.name}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    {showReviewObjective.length !== 0 && showReviewObjective.map((obj, objIndex) => {
                      const userDetails = obj.assigneUserWeightage.find(details =>
                        userlist.id == details.userId && details.objectiveId == obj.primaryId
                      );
                      return (
                        <React.Fragment key={objIndex}>
                          <td>
                            {obj.primaryId !== null && obj.quarter == quarter && obj.year == year ? (
                              <div className="d-flex justify-center">
                                {obj.assigneUserWeightage.some(
                                  (input) =>
                                    input.objectiveId == obj.primaryId
                                    && input.userId === userlist.id
                                ) ? (
                                  <>
                                    {obj.creatorUserId === localStorage.loginId ? (
                                      <Tooltip title="Assign">
                                        <img
                                          src="assets/images/ep_remove.svg"
                                          className="cur-pointer margin-top-10"
                                          alt=""
                                          onClick={() =>
                                            assign_remove(userIndex, objIndex, obj.primaryId, userlist.id, obj.title, true)
                                          }
                                        />
                                        <span
                                          onClick={() => assign_remove(userIndex, objIndex, obj.primaryId, userlist.id, obj.title, true)}
                                          className="unassign-btn"
                                        >
                                          Unassign
                                        </span>
                                      </Tooltip>
                                    ) : (<span className="zn-2">N/A</span>)}
                                  </>
                                ) : (
                                  <>
                                    {obj.creatorUserId === localStorage.loginId ? (
                                      <Tooltip title="Assign">
                                        <span
                                          onClick={() => ObjectiveMyselt(obj.primaryId, userlist.id)}
                                          className="assign-btn"
                                        >
                                          Assign
                                        </span>
                                      </Tooltip>) : (<span className="zn-2">N/A</span>)
                                    }
                                  </>
                                )}
                              </div>
                            ) : null}
                          </td>
                          {layerActive ? (
                            <>
                              {showChildColumnIndex === objIndex && (
                                <td>
                                  {obj.quarter == quarter && obj.year == year ? (
                                    <div className="justify-evenly">
                                      {obj.subObjectiveList.map((subobj, subObjIndex) => (
                                        <>
                                          {subobj.assigneUserWeightage.some(
                                            (input) =>
                                              subobj.subObjId == input.objectiveId
                                              && input.userId === userlist.id
                                          )
                                            ? (
                                              <>
                                                {subobj.creatorUserId === localStorage.loginId ? (
                                                  <Tooltip title="Assign">
                                                    <img
                                                      src="assets/images/ep_remove.svg"
                                                      className="cur-pointer margin-top-10"
                                                      alt=""
                                                      onClick={() =>
                                                        assign_remove(userIndex, subObjIndex, subobj.subObjId, userlist.id, subobj.title, true)
                                                      }
                                                    />
                                                    <span
                                                      onClick={() => assign_remove(userIndex, subObjIndex, subobj.subObjId, userlist.id, subobj.title, true)}
                                                      className="unassign-btn zn-2"
                                                    >Unassign
                                                    </span>
                                                  </Tooltip>
                                                ) : (<span className="zn-2">N/A</span>)}
                                              </>
                                            ) : (
                                              <>
                                                {subobj.creatorUserId === localStorage.loginId ? (
                                                  <Tooltip title="Assign">
                                                    <span
                                                      onClick={() => ObjectiveMyselt(subobj.subObjId, userlist.id)}
                                                      className="assign-btn zn-2"
                                                    >
                                                      Assign
                                                    </span>
                                                  </Tooltip>
                                                ) : (<span className="zn-2">N/A</span>)}
                                              </>
                                            )}
                                        </>
                                      ))}
                                    </div>
                                  ) : null}
                                </td>
                              )}
                            </>) : (<></>)}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                ))}
              <tr className="c_32B7D9 fs-16 fw-700 ">
                <td className="br-none">
                  <div className="align-center">
                    <img
                      src={
                        arrowTM
                          ? "assets/images/up_arrow_accord.svg"
                          : "assets/images/down_arrow_accord.svg"
                      }
                      alt=""
                      className="cur-pointer pr-2"
                      onClick={handleArrowTM}
                    />
                    <span className="c_32B7D9 fs-16 fw-700">
                      Team Members
                    </span>
                  </div>
                </td>
              </tr>
              {
                arrowTM &&
                usersList.map((user, userIndex) => (
                  <tr key={userIndex}>
                    <td>
                      <div className="d-flex custom-tooltip">
                        <Checkbox checked={true}
                          onChange={(e) => onCheck(e, userIndex)}
                        ></Checkbox>
                        <div className="fs-14 ml-2">
                          <div className="d-flex align-center mb-2">
                            <img
                              src={user.profilePath != null ? FileUrl + user.profilePath : "assets/images/Default_user.svg"}
                              alt="user"
                              className="user"
                            />
                            <span className="fw-400 ml-2">{user.name}</span>
                          </div>
                          <div className="d-flex allocted-text">
                            <span>
                              Allocated
                              <span className="fw-700 ml-1">{user.assignWeightage}%</span>
                            </span>
                            <div className="divider-blue"></div>
                            <span>
                              {100 - user.avaiableWeightage < 100 ? "Pending" : "Exceeding"}
                              <span className="fw-700 ml-1">{Math.abs(user.avaiableWeightage)}%</span>
                            </span>
                          </div>
                          <Progress
                            className="myobj-progress"
                            percent={100 - user.avaiableWeightage < 100 ? 100 - user.avaiableWeightage : 100}
                            strokeColor={
                              user.avaiableWeightage < 0
                                ? "#E05E78"
                                : user.avaiableWeightage === 0
                                  ? "#32C6A0"
                                  : "#32B7D9"
                            }
                          />
                        </div>
                      </div>
                    </td>
                    {/* {getParentAndChild(objData).map((obj, objIndex) => ( */}

                    {showReviewObjective.length !== 0 && showReviewObjective.map((obj, objIndex) => {
                      const userDetails = obj.assigneUserWeightage.find(details =>
                        user.id === details.userId && details.objectiveId === obj.primaryId
                      );
                      const weightageValue = userDetails?.weightage != null ? userDetails.weightage : 5;
                      return (
                        <React.Fragment key={objIndex}>
                          <td>
                            {obj.primaryId !== null && obj.quarter == quarter && obj.year == year ? (
                              <div className="d-flex justify-center">
                                {showInputs.some(
                                  (input) =>
                                    input.userIndex === user.id &&
                                    input.objIndex === obj.primaryId &&
                                    input.isParent === true
                                ) ? (
                                  <div className="d-flex">
                                    {/* <input value={user.assignWeightage}>
                                    </input> */}
                                    <input
                                      className="assigned_bg position-unset"
                                      name="weightage"
                                      value={weightageValue}
                                      onChange={(e) =>
                                        handleInputChange(e, userIndex, objIndex, obj.primaryId, user.id, obj.title, true)
                                      }
                                      onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputElement = e.target as HTMLInputElement;
                                        const inputValue = inputElement.value + String.fromCharCode(e.charCode);
                                        if (!pattern.test(String.fromCharCode(e.charCode)) || parseInt(inputValue) > 100) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    <span className="suffix-myobjWeight">%</span>
                                    <img
                                      src="assets/images/ep_remove.svg"
                                      className="cur-pointer"
                                      alt=""
                                      onClick={() =>
                                        assign_remove(userIndex, objIndex, obj.primaryId, user.id, obj.title, false)
                                      }
                                    />
                                  </div>
                                ) : (
                                  <Tooltip title="Assign">
                                    <span
                                      onClick={() => {
                                        toggleInput(userIndex, objIndex, obj.primaryId, obj.title, true, user.id, 5);
                                        handleInputChange(5, userIndex, objIndex, obj.primaryId, user.id, obj.title, true);
                                      }}
                                      className="assign-btn"
                                    >
                                      Assign
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            ) : null}
                          </td>

                          {layerActive ? (
                            <>
                              {showChildColumnIndex === objIndex && (
                                <td>
                                  {obj.quarter == quarter && obj.year == year ? (
                                  <div className="justify-evenly">
                                    {obj.subObjectiveList.map((subobj, subObjIndex) => {
                                      const subobjUserDetails = subobj.assigneUserWeightage.find(details =>
                                        user.id === details.userId && details.objectiveId === subobj.subObjId
                                      );
                                      // const subweightageValue = subobjUserDetails?.weightage != null ? subobjUserDetails.weightage : 5;
                                      // const subweightageValue = subobjUserDetails?.weightage ?? 5;
                                      return (
                                        <React.Fragment key={subObjIndex}>
                                          {showInputs.some(
                                            (input) =>
                                              input.userIndex === user.id &&
                                              input.objIndex === subobj.subObjId &&
                                              input.isParent === false
                                          ) ? (
                                            <div className=" d-flex">
                                              <Input
                                                className="assigned_bg position-unset"
                                                value={subobjUserDetails?.weightage ?? 5}
                                                name="subweightage"
                                                onChange={(e) => {
                                                  // console.log('Input change:', e.target.value); // Debugging log
                                                  handleInputChange(e, userIndex, subObjIndex, subobj.subObjId, user.id, subobj.title, false)
                                                }}
                                                onKeyPress={(e) => {
                                                  const pattern = /[0-9]/;
                                                  const inputElement = e.target as HTMLInputElement;
                                                  const inputValue = inputElement.value + String.fromCharCode(e.charCode);
                                                  if (!pattern.test(String.fromCharCode(e.charCode)) || parseInt(inputValue) > 100) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                              <span className="suffix-myobj">%</span>
                                              <img
                                                src="assets/images/ep_remove.svg"
                                                className="cur-pointer"
                                                alt=""
                                                onClick={() =>
                                                  assign_remove(userIndex, subObjIndex, subobj.subObjId, user.id, subobj.title, true)
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <Tooltip title="Assign">
                                              <span
                                                onClick={() => {
                                                  toggleInput(userIndex, subObjIndex, subobj.subObjId, subobj.title, false, user.id, 5);
                                                  handleInputChange(5, userIndex, subObjIndex, subobj.subObjId, user.id, subobj.title, false);
                                                }}
                                                className="assign-btn zn-2"
                                              >
                                                Assign
                                              </span>
                                            </Tooltip>
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                  ):null} 
                                </td>
                              )}
                            </>
                          ) : null}
                        </React.Fragment>
                      );
                    })}

                  </tr>
                ))}
              {/* CFT */}
              <tr className="c_32B7D9 fs-16 fw-700">
                <td className="remove_cft_border">
                  <div className="align-center">
                    <img
                      src={
                        arrowCFT
                          ? "assets/images/up_arrow_accord.svg"
                          : "assets/images/down_arrow_accord.svg"
                      }
                      alt=""
                      className="cur-pointer pr-2"
                      onClick={handleArrowCFT}
                    />
                    <span className="c_32B7D9 fs-16 fw-700">
                      CFT
                    </span>
                  </div>
                </td>
              </tr>
              {arrowCFT && fhuseroption.map((user, userIndex) => (
                <tr key={userIndex}>
                  <td>
                    <div className="d-flex custom-tooltip">
                      <Checkbox checked={true}
                        onChange={(e) => onCheck(e, userIndex)}
                      ></Checkbox>
                      <div className="fs-14 ml-2">
                        <div className="d-flex align-center mb-2">
                          <img
                            src={user.image != null ? FileUrl + user.image : "assets/images/Default_user.svg"}
                            alt="user"
                            className="user"
                          />
                          <span className="fw-400 ml-2">{user.label}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* {getParentAndChild(objData).map((obj, objIndex) => ( */}
                  {showReviewObjective.length !== 0 && showReviewObjective.map((obj, objIndex) => {
                    const userDetails = obj.assigneUserWeightage.find(details =>
                      user.value == details.userId && details.objectiveId == obj.primaryId
                      // && details.weightage != 0
                    );
                    return (
                      <React.Fragment key={objIndex}>
                        {/* {obj.assigneUserWeightage.map((assignUser) => ( obj.primaryId === assignUser.objectiveId  && user.id === assignUser.userId &&  */}
                        <td>
                          {obj.primaryId !== null ? (
                            <div className="d-flex justify-center">
                              {obj.assigneUserWeightage.some(
                                (input) =>
                                  input.objectiveId == obj.primaryId
                                  && input.userId === user.value
                              )
                                // || showInputs.some(
                                //   (input) =>
                                //     input.userIndex === userIndex &&
                                //     input.objIndex === objIndex &&
                                //     input.isParent === true
                                // ) || obj.weightage !== "0"
                                ? (

                                  <Tooltip title="Assign">
                                    <img
                                      src="assets/images/ep_remove.svg"
                                      className="cur-pointer margin-top-10"
                                      alt=""
                                      onClick={() =>
                                        assign_remove(userIndex, objIndex, obj.primaryId, user.value, obj.title, true)
                                      }
                                    />
                                    <span
                                      // onClick={() => toggleInput(user.id, obj.primaryId, obj.primaryId, obj.objectiveId)}
                                      onClick={() => toggleInput(userIndex, objIndex, obj.primaryId, obj.title, true, user.value, 5)}
                                      className="unassign-btn"
                                    >
                                      Unassign
                                    </span>
                                  </Tooltip>





                                ) : (
                                  <Tooltip title="Assign">
                                    <span
                                      // onClick={() => toggleInput(user.id, obj.primaryId, obj.primaryId, obj.objectiveId)}
                                      //onClick={() => toggleInput(userIndex, objIndex, obj.primaryId, obj.title, true)}
                                      onClick={() => addObjectiveCFT(obj.primaryId, user.value)}
                                      className="assign-btn"
                                    >
                                      Assign

                                    </span>
                                  </Tooltip>
                                )}
                            </div>
                          ) : null}
                        </td>
                        {/* ))} */}
                        {/* {showChildColumnIndex === objIndex && ( */}
                        {layerActive ? (
                          <>
                            {showChildColumnIndex === objIndex && (
                              <td>
                                <div className="justify-evenly">
                                  {obj.subObjectiveList.map((subobj, subObjIndex) => (
                                    <>
                                      {subobj.assigneUserWeightage.some(
                                        (input) =>
                                          subobj.subObjId == input.objectiveId
                                          && input.userId === user.value
                                        // input.userIndex === userIndex &&
                                        // input.objIndex === subObjIndex
                                      )
                                        // || showInputs.some(
                                        //   (input) =>
                                        //     input.userIndex === userIndex &&
                                        //     input.objIndex === subObjIndex &&
                                        //     input.isParent === true
                                        // )
                                        ? (
                                          <Tooltip title="Assign">
                                            <img
                                              src="assets/images/ep_remove.svg"
                                              className="cur-pointer margin-top-10"
                                              alt=""
                                              onClick={() =>
                                                assign_remove(userIndex, objIndex, obj.primaryId, user.value, obj.title, true)
                                              }
                                            />
                                            <span
                                              onClick={() => toggleInput(userIndex, subObjIndex, subobj.subObjId, subobj.title, false, user.value, 5)}
                                              className="unassign-btn zn-2"
                                            >Unassign
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="Assign">
                                            <span
                                              onClick={() => addObjectiveCFT(subobj.subObjId, user.value)}
                                              // onClick={() => toggleInput(userIndex, subObjIndex, subobj.subObjId, subobj.title, false)}
                                              className="assign-btn zn-2"
                                            >
                                              Assign
                                            </span>
                                          </Tooltip>
                                        )}
                                    </>
                                  ))}
                                </div>
                              </td>
                            )}
                          </>) : (<></>)}
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyObjWeightage; 
