import React, { useState, useEffect } from "react";
import { Checkbox, Progress, Input, Button, Tooltip, Row, Col, MenuProps, Dropdown, Space, Menu ,Modal,message } from "antd";
import AddKR from "../../Modal/AddKR";
import { BaseUrl, FileUrl } from "../../../../App";
import axios from "axios";
import KRDescription from "../../Modal/KRDescription";

interface UserData {
  name: string;
  allocated: number;
}

interface ObjData {
  id: number;
  objname: string;
  desc: string;
  objValue: string;
  status: string;
  parentobj: string | null;
}
interface assigneeslist {
  krid: string
  totalweightage: number
  userid: string
  weightage: number
}
type kr = {
  "keyresulttitle": string,
  "keyresultdescription": string,
  "keyresultstatus": number,
  "objectiveid": string,
  "keyresultid": string,
  "objid": string,
  "krid": string
}
interface reviewkr {
  "objectiveid": string,
  "orgobjectiveid": string,
  "parentid": string,
  "objectivestatus": string,
  "createdby": string,
  "objectivename": string,
  "objectivedescription": string,
  "weightage": string,
  "objid": string,
  "keyresultdetails": kr[],
  "year": string,
  "quarter": string,
}

type krlist = {
  id: string,
  objectiveid: string,
  keytitle: string,
  description: string,
  remarks: string,
  target: string,
  amounttype: string,
  uom: string,
  status: number,
  keyid: string,
  objectivecode: string,
  quarter: string,
  orgobjectiveid: string,
  objectivename: string,
  keyResultUsersListAssignedTo: keyResultUsers[],
  createdbyempname: string,
  createdByEmpProfilePath: string,
  keyresultweightage: number,
  deletedstatus: number,
  deletedtime: String,
  updatedAt: string
  deleteddate: string,
  createdBy: string,
}
type keyResultUsers = {
  availableweightage: number;
  createdAt: string;
  id: string;
  individualweightage: number;
  keyid: string;
  keyuid: string;
  profilePath: string | null;
  status: number;
  target: number;
  updateAt: string | null;
  userempid: string | null;
  userid: string;
  username: string;
};

interface customWeightage {
  "id": string,
  "name": string,
  "username": string,
  "avaiableWeightage": number,
  "assignWeightage": number,
  "profilePath": string
}

interface userWeightage {
  krid: string,
  userid: string,
  weightage: number,
}

interface userdetails {
  "id": string
  "name": string,
  "email": string,
  "rolename": string,
  "departmentname": string,
  "profilePath": null
}
const userData: UserData[] = [
  { name: "John", allocated: 40 },
  { name: "Alice", allocated: 100 },
];

const objData: ObjData[] = [
  {
    id: 1,
    objname: "obj1",
    desc: "obj 1 desc",
    objValue: "23%",
    status: "Active",
    parentobj: null,
  },
  {
    id: 2,
    objname: "obj2",
    desc: "obj 2 desc",
    objValue: "43%",
    status: "Inprogress",
    parentobj: null,
  },
  {
    id: 3,
    objname: "obj3",
    desc: "obj 3 desc",
    objValue: "67%",
    status: "Inactive",
    parentobj: null,
  },
  {
    id: 4,
    objname: "sub-1",
    desc: "sub obj 1 desc",
    objValue: "67%",
    status: "Inactive",
    parentobj: "obj1",
  },
  {
    id: 5,
    objname: "sub-2",
    desc: "sub obj 2 desc",
    objValue: "67%",
    status: "Inactive",
    parentobj: "obj2",
  },
  {
    id: 6,
    objname: "sub-3",
    desc: "sub obj 3 desc",
    objValue: "67%",
    status: "Inactive",
    parentobj: "obj3",
  },
];

const getParentAndChild = (objData: ObjData[]) => {
  const parentAndChild: ObjData[] = [];
  objData.forEach((parent) => {
    parentAndChild.push(parent);
    objData.forEach((child) => {
      if (child.parentobj === parent.objname) {
        parentAndChild.push(child);
      }
    });
  });
  return parentAndChild;
};

interface setReviewKR {
  setReviewKR: React.Dispatch<React.SetStateAction<boolean>>;
  objid:string|null;
}

const ReviewALLKRWeightage: React.FC<setReviewKR> = ({ setReviewKR ,objid}) => {
  const [objdata, setobjdata] = useState<reviewkr[]>([]);
  const [showInputs, setShowInputs] = useState<{ userIndex: number; objIndex: number;krindex:number}[]>([]);
  const [weightages, setWeightages] = useState<Record<string, Record<string, number>>>({});
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [shownParentObj, setShownParentObj] = useState<string | null>(null);
  const [renderedParentObjs, setRenderedParentObjs] = useState<string[]>([]);
  const [showChildColumnIndex, setShowChildColumnIndex] = useState<number | null>(null);
  const [layerActive, setLayerActive] = useState<boolean[]>(new Array(objData.length).fill(false)); // Initialize with an array of false values
  const [assignessweightage, setassigneesweightae] = useState<assigneeslist[]>([]);
  const [objectiveUser, setObjectiveUser] = useState<userWeightage[]>([]);
  const [goKrBackCount , setGoKrBackCount] = useState(0);
  const toggleInput = (userIndex: number, objIndex: number,krindex:number) => {
    // alert(userid)
    // alert(objkeyresultid)
    // handleInputChange(e, user.id ,index, obj.keyresultid)}
    // handleInputChange(e, user.id ,index, obj.keyresultid)}
    setShowInputs((prevShowInputs) => [...prevShowInputs, { userIndex, objIndex,krindex }]);
    setGoKrBackCount(1);
  };

  const assign_remove = (userIndex: number, objIndex: number,krindex:number) => {
    setShowInputs((prevShowInputs) =>
      prevShowInputs.filter(
        (input) => !(input.userIndex === userIndex && input.objIndex === objIndex && input.krindex===krindex)
      )
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>|number,
    userid: string,
    keyIndex: number,
    krid: string
  ) => {
    var value:any;
    var selectedUser;
    var assignuserweightage:assigneeslist;
    if (typeof e == 'number') {
       value = e;
       selectedUser = {
          krid: krid,
          userid: userid,
          weightage: value
      };
      assignuserweightage = {
        krid: krid,
        userid: userid,
        weightage: value,
        totalweightage:0
    };
  } else {
       value = e.target.value;
       selectedUser = {
          krid: krid,
          userid: userid,
          weightage: parseInt(value, 10)
      };
      assignuserweightage = {
        krid: krid,
        userid: userid,
        weightage: value,
        totalweightage:0
    };
  }
  
  assignessweightage.push(assignuserweightage)
  setassigneesweightae((prevWeightages) =>
    prevWeightages.map((data) => {
      if (data.userid === userid && data.krid === krid) {
        return { ...data, weightage: value };
      }
      // console.log(`Updating weightage for user ${data.userid} and krid ${data.krid}`);
      return data;
    })
  );
    // console.log("selectedUser",selectedUser);
    objectiveUser.push(selectedUser);
    
    
    setGoKrBackCount(1);
  };

  const handleCascadeSelected = () => {
    const selectedData = userData.filter((_, index) => selectedRows.includes(index));
    // console.log("Number of selected rows:", selectedRows.length);
    // console.log("Selected rows:", selectedData);
  };

  const onCheck = (e: any, userIndex: number) => {
    if (e.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, userIndex]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== userIndex)
      );
    }
  };

  const toggleParentObj = (parentObj: string | null, columnIndex: number) => {
    setLayerActive((prevState) => {
      const newState = [...prevState];
      newState[columnIndex] = !newState[columnIndex];
      return newState;
    });

    if (parentObj === shownParentObj) {
      setShownParentObj(null);
      setShowChildColumnIndex(null); // Hide the new column
    } else {
      setShownParentObj(parentObj);
      setShowChildColumnIndex(columnIndex); // Record the index of the clicked column
      // renderChildObjects(parentObj);
    }
  };

  const renderChildObjects = (parentObj: string | null) => {
    if (parentObj !== null) {
      // Find all subobjects with the specified parent object
      const subObjects = objData.filter((obj) => obj.parentobj === parentObj);
      // Update renderedParentObjs with both parent and subobject names
      setRenderedParentObjs((prevRenderedParentObjs) => [
        ...prevRenderedParentObjs,
        parentObj,
        ...subObjects.map((obj) => obj.objname),
      ]);
    }
  };

  const goback = () => {
    if(goKrBackCount === 0){
      setReviewKR(true);
    }else{
    Modal.confirm({
      content: (
        <>
          <p className="fs-16 mb-3 keytitle_scroll">"Do you want to save the changes?"</p>
        </>
      ),
      okText: "Save",
      cancelText: 'Back',
      okButtonProps: { className: 'C_btn' },
      cancelButtonProps: { className: 'outline_btn' },
      centered: true,
      onOk() {
        message.success({
          content: (
            <div className="success-message justify-center align-center p-1">
              <img src="assets/images/check-circle.svg" alt="Success Icon" />
              <span className="c_32C6A0 fs-16">
              Weightage Assigned successfully.
              </span>
            </div>
          ),
          duration: 3,
          icon: null,
        });  
        {selectedRows.length === 0 ? (saveorupdatekr(1)) : saveorupdatekr(2)}
      },
      onCancel() {
        setReviewKR(true);
      },
    });
  }
  };
  const [IdList, setuserid] = useState<string[]>([]);
  const [saveAndCascadeUser, setSaveAndCascadeUser] = useState<string[]>([]);
  const userLists = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const userIdList = response.data;

      userIdList.map((user: any) => {
        IdList.push(user.id);
      });
      weightageUserList(IdList);
      setSaveAndCascadeUser(IdList);
    } catch (err) {
      // console.log(err)
    }
  }
  const reviewkrweightage = async () => {
    try {
      // 
        //const objid=localStorage.getItem("kr_obj");
      const resp = await axios.get(BaseUrl + `api/reviewcascade/view/cascadeanereivewallkr/${localStorage.getItem("loginId")}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const objkeyresults = resp.data.data;
      const keyresultIds: string[] = [];
      // const keyresultIds:string[] = objkeyresults.map((data:kr) =>{ data.keyresultid});

      // console.log("details", objkeyresults)
      setobjdata(resp.data.data);
      objectivesdetailsgets(resp.data.data)
      setassigneesweightae(resp.data.assigneesdetails)
      const repdata = resp.data.data;

    } catch (err) {
      // console.log(err)
    }
  }
  const [usersList, setUsersList] = useState<customWeightage[]>([]);

  const weightageUserList = async (userid: string[]) => {
    try {
      const apiUrl = BaseUrl + `api/keyresult/weighatgeassined`;
      const assignedkeyresultrequest = {
        objectiveid:objid,
        userid:userid,
      }
      await axios.post(apiUrl, assignedkeyresultrequest, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        },

      })
        .then((response) => {
          const addedCustomWeightage: customWeightage[] = [];
          response.data.body.map((res: any, index: number) => {
            const customWeights = {
              id: res.id,
              name: res.username,
              profilePath: res.profilePath,
              username: res.name,
              avaiableWeightage: res.availableweightage,
              assignWeightage: res.assignedweightage,
            }
            addedCustomWeightage.push(customWeights);
          })
          setUsersList(addedCustomWeightage);
        })
    } catch (err) {
      // console.log(err)
    }
  }
  const saveorupdatekr = async (status: number) => {
    try {
      const apiUrl = BaseUrl + `api/reviewcascade/update/saveorupdatereviewkr/${status}`;
      await axios.post(apiUrl, objectiveUser, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        },

      })
        .then((response) => {
          reviewkrweightage();
          if(status == 1){
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                  Weightage Assigned successfully.
                  </span>
                </div>
              ),
              duration: 3,
              icon: null,
            });
          }else{
            
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                  Keyresult Cascade successfully.
                  </span>
                </div>
              ),
              duration: 3,
              icon: null,
            });
          }
        })
    } catch (err:unknown) {
      if (err instanceof Error && (err as any).response && (err as any).response.status === 422) {
        message.error({
            content: (
                <div>
                    {/* <img src="assets/images/check-circle.svg" alt="Error Icon" /> */}
                    <span>Target Is Not Available</span>
                </div>
            ),
            duration: 3,
            icon: null,
        });
        reviewkrweightage();
    } else {
        // console.error(err);
    }
    }
  }

  const deleteweightage = async (krid:string,userid:string) => {
      try {
        const apiUrl = BaseUrl + `api/reviewcascade/delete/removekrweightage`;
        const formdata = new FormData();
        formdata.append('krid',krid);
        formdata.append('userid',userid)
        await axios.post(apiUrl, formdata, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          },

        })
          .then((response) => {
            reviewkrweightage();
          })
      } catch (err) {
      // console.log(err)
    }
  }

  useEffect(() => {
    // console.log('test');
    if(objid===null||objid===""){
    objid=localStorage.getItem("kr_obj");
    }else{
      localStorage.setItem('kr_obj',objid);
    }
    userLists();
    reviewkrweightage();
  }, [showInputs]);
  

  const [objectivesassignedkr, setobjectiveassignedkr] = useState<krlist[]>([]);
  const [objectivesteamdkr, setobjectiveteamedkr] = useState<krlist[]>([]);

  const objectivesdetailsgets = (objectives: reviewkr) => {
    getassignedkeyforobjectives(objectives?.objectiveid, localStorage.getItem("loginId"))
    getteamkeyresult(objectives?.objectiveid, localStorage.getItem("username"))
  }


const getassignedkeyforobjectives = async (objectives: string, userId: string | null) => {
  const assignedkeyresults = await axios.get(`${BaseUrl}api/keyresult/getkeyresult/${objectives}/${userId}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }
  });
  const assignedkr: krlist[] = assignedkeyresults.data.data;
  assignedkr.map((item: krlist) => {
    const updatedDate = item.updatedAt ? item.updatedAt.split('T')[0] : "";
    const assignedkrforobj: krlist = {
      id: item.id,
      objectiveid: item.objectiveid,
      keytitle: item.keytitle,
      description: item.description,
      remarks: item.remarks,
      target: item.target,
      amounttype: item.amounttype,
      uom: item.uom,
      status: item.status,
      keyid: item.keyid,
      objectivecode: item.objectivecode,
      quarter: item.quarter,
      orgobjectiveid: item.orgobjectiveid,
      objectivename: item.objectivename,
      keyResultUsersListAssignedTo: item.keyResultUsersListAssignedTo,
      createdbyempname: item.createdbyempname,
      createdBy: item.createdBy,
      createdByEmpProfilePath: item.createdByEmpProfilePath,
      keyresultweightage: item.keyresultweightage,
      deletedstatus: item.deletedstatus,
      deleteddate: item.deleteddate,
      updatedAt: item.updatedAt,
      deletedtime: item.deletedtime
    }
    const againID = assignedkrforobj.keyResultUsersListAssignedTo;
    const assignedUsers: keyResultUsers[] = [];
    const loginID = localStorage.getItem("loginId");
    const filteredUsers = againID.filter(user => user.id !== loginID);
    // console.log("filteredUsers", filteredUsers);

  });
  setobjectiveassignedkr(assignedkr);

  if (assignedkeyresults.data.length === 0) {
    setobjectiveassignedkr([])
  }
}
// team keyResult API
const getteamkeyresult = async (objectivesId: string, userId: string | null) => {
  const myteamkeyresults = await axios.get(`${BaseUrl}api/keyresult/getTeamkeyResults/${objectivesId}/${userId}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }
  });
  // console.log(myteamkeyresults.data);
  const assignedteamkr: krlist[] = myteamkeyresults.data;
  assignedteamkr.map((item: krlist) => {
    const teamkrforobj: krlist = {
      id: item.id,
      objectiveid: item.objectiveid,
      keytitle: item.keytitle,
      description: item.description,
      remarks: item.remarks,
      target: item.target,
      amounttype: item.amounttype,
      uom: item.uom,
      status: item.status,
      keyid: item.keyid,
      objectivecode: item.objectivecode,
      quarter: item.quarter,
      orgobjectiveid: item.orgobjectiveid,
      objectivename: item.objectivename,
      keyResultUsersListAssignedTo: item.keyResultUsersListAssignedTo,
      createdbyempname: item.createdbyempname,
      createdBy: item.createdBy,
      createdByEmpProfilePath: item.createdByEmpProfilePath,
      keyresultweightage: item.keyresultweightage,
      deletedstatus: item.deletedstatus,
      deleteddate: item.deleteddate,
      updatedAt: item.updatedAt,
      deletedtime: item.deletedtime
    }
  });
  setobjectiveteamedkr(assignedteamkr);
}

type krdescription = {
  objname: string,
  objid: string,
  krid: string,
  krtitle: string,
  year: string,
  objdesc: string
}
const [keyresultid, setkeyresultids] = useState<krdescription>({
  objname: "",
  objid: "",
  krid: "",
  krtitle: "",
  year: "",
  objdesc: ""
});

const setKrValues = (e: any, objective: any ,obj :any) => {
  // console.log("objective", objective);
  e.preventDefault();
  setkeyresultids({
    ...keyresultid,
    krid: obj.keyresultid,
    objname: objective.objectivename,
    objid: objective.objid,
    year: objective.year,
    objdesc: objective.objectivedescription
  })
};

const items = [
  {
    label: (
        <KRDescription
          objectivelist={() => { }}
          objectivesdetailsgets={() => { }}
          getassignedkeyforobjectives={getassignedkeyforobjectives}
          getteamkeyresult={getteamkeyresult}
          krid={keyresultid?.krid}
          krtitle={""}
          objname={keyresultid?.objname}
          objid={keyresultid?.objid}
          availableweightagekr={50}
          year={keyresultid?.year}
          orgdescription={keyresultid?.objdesc}
          objectivetype="Static Type"
          objective="Static Key Card"
          obj_code={keyresultid.objid}
          krcode=""
          checkInFrequency=""
          uomType=""
          objquarter= ""
          tabActiveKey = "1"
          selectedkrvalue=""
        />
      // <KRDescription objectivesdetailsgets={() => { objectivesdetailsgets(objData) }} getassignedkeyforobjectives={getassignedkeyforobjectives} getteamkeyresult={getteamkeyresult} objectivelist={objectivelist} krid={keyresultid?.krid} krtitle={""} objname={objectivekeys.obj_name} objid={objectivekeys.obj_id} availableweightagekr={availableweightagekr} year={objectivekeys.year} orgdescription={objectivekeys.obj_desc} objectivetype={objectivekeys.objtype} objective={keycard} />
    ),
    key: "0",
  },
  {
    label: (
      <Row
        className="c_FF4747 fs-14 p-0"
      // onClick={() => { handleDeleteKR(keyresultid) }}
      >
        <img src="assets/images/d_trash.svg" alt="Delete" className="mr-1" />{" "}
        Delete
      </Row>
    ),
    key: '1',
  },

];

const menu = (
  <Menu>
    {items.map(item => (
      (item.key == '0') || (item.key == '1') ?
        (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        ) : null
    ))}
  </Menu>
);

return (
  <>
    <div className="d-flex justify-end mx-3 align-center add_obj_btn">
      <span className="goback-btn" onClick={goback}>
        Go back
      </span>

      {/* {selectedRows.length === 0 && (
        <Button onClick={() => { saveorupdatekr(1) }} className="ml-3 cyan_btn">
          Save Changes
        </Button>
      )} */}
      {/* {selectedRows.length > 0 && ( */}
        <Button onClick={() => { saveorupdatekr(2) }} className="ml-3 cyan_btn">
          Cascade All
        </Button>
      {/* )} */}
    </div>
    <div className="table-container myobjweightage">
      {objdata.map((objectives, index) => (
        <table className="myobjweightage-table">
          <thead>
            <tr>
              <th>
                <div className="kr-card d-flex flex-column justify-between">
                  <div>
                    <div className="d-flex justify-between w-100">
                      <div className="wrapper_label align-center mb-2 ">
                        <span className="cup_org_bg align-center">
                          <img src="assets/images/crown.svg" alt="" className="pr-1" />{objectives.objid}
                        </span>
                        <span className="triangle"></span>
                      </div>
                      <div><img src="assets/images/cascade_status_img.svg" alt="" /></div>
                    </div>
                    <p className="org_obj_name">{objectives.objectivename}</p>
                  </div>
                  <div className="d-flex align-center justify-between">
                    <div className="d-flex align-center">
                      <img src="assets/images/Network_Icon.svg" alt="" />
                      <span>{objectives.weightage}</span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-between align-center">
                  <p>{objectives.keyresultdetails.length} Key Result</p>
                  {/* <button className="cyan_button">hbhj</button> */}
                  <span>
                    <AddKR
                      objectivelist={reviewkrweightage}
                      objectivesdetailsgets={() => { }}
                      krassignforme={() => { }}
                      orgobjectivelist={()=>{}}
                      objdesc={objectives.objectivedescription}
                      objquarter={objectives.quarter}
                      objname={objectives.objectivename}
                      objid={objectives.objectiveid}
                      year={objectives.year}
                      availableweightagekr={50}
                      orgdescription="Static Organizational Description"
                      objectivetype="Static Type"
                      objective="Static Key Card"
                      obj_code={objectives.objid}
                      selectedkrvalue=""
                    />
                  </span>
                </div>
              </th>
              {usersList.map((user, userIndex) => (
                <th key={userIndex}>
                  <div className="d-flex custom-tooltip">
                    {/* <Checkbox onChange={(e) => onCheck(e, userIndex)}></Checkbox> */}
                    <div className="fs-14 ml-2">
                      <div className="d-flex align-center mb-2">
                        <img src="assets/images/Default_user.svg" alt="user" className="user" />
                        <span className="fw-400 ml-2">{user.name}</span>
                      </div>
                      <div className="d-flex allocted-text">
                        <span>
                          Allocated
                          {
                          <span className="fw-700 ml-1">{user.assignWeightage}%</span>
                          }
                        </span>
                        <div className="divider-blue"></div>
                        <span>
                          {user.avaiableWeightage >0 ? "Pending" : "Exceeding"}
                          <span className="fw-700 ml-1">{Math.abs(user.avaiableWeightage)}%</span>
                        </span>
                      </div>
                      <Progress
                        className="myobj-progress"
                        percent={user.avaiableWeightage}
                        strokeColor={
                          user.avaiableWeightage > 100
                            ? "#E05E78"
                            : user.avaiableWeightage === 100
                              ? "#32C6A0"
                              : "#32B7D9"
                        }
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {objectives.keyresultdetails.map((obj, index2) => (
              <tr key={index}>
                <td>
                  <div className="mykr_weightage_card d-flex flex-column justify-between p-1 mt-2 mb-2 ">
                    <Row className="d-flex justify-between align-center">
                      <div className="d-flex">
                        <div className="container_label">
                          <div className="wrapper_label align-center mb-1">
                            <span className="cup_org_bg align-center">
                              <img src="assets/images/crown.svg" alt="" />
                              {/* objectivekeys.obj_code */}
                              {objectives.objid}
                            </span>
                            <span className="triangle"></span>
                          </div>
                        </div>
                        <div className="second_kr_label">
                          <div className="wrapper_label align-center mb-1">
                            <span className="triangle_left"></span>
                            <span className="cup_org_bg align-center">
                              <img src="assets/images/KRA-Icon.svg" alt="" />
                              {/* objective.keyid */}
                              {obj.krid}
                            </span>
                            <span className="triangle"></span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-center">
                        <img src="assets/images/progress_status_img.svg" className="pr-1" alt="" />
                        <Dropdown overlay={menu} trigger={['click']}>
                          <a onClick={(e) => setKrValues(e, objectives,obj)} href="#hgj"
                          >
                            {/* {localStorage.loginId == obj.createdBy && */}
                            <Space>
                              <img src="assets/images/menu.svg" alt="" className="ml-1 cur-pointer" />
                              <input type="hidden" />
                            </Space>
                            {/* }   */}
                          </a>
                        </Dropdown>
                      </div>
                    </Row>
                    <div className="fs-14 obj_kr_desc fw-600">
                      {/* objective.description */}
                      {obj.keyresulttitle}
                    </div>
                  </div>

                </td>
                {usersList.map((user, userIndex) => {
                  const matchingDetails = assignessweightage.find(details =>
                    user.id === details.userid && details.krid === obj.keyresultid 
                    // && details.weightage != 0
                  );
                  return (
                    <td key={userIndex}>
                      <div className="d-flex justify-center">
                        {matchingDetails || showInputs.some((input) => input.userIndex === userIndex && input.objIndex === index
                        &&input.krindex==index2) ? (
                          <div className=" d-flex">
                            <input
                              className="assigned_myobj_bg"
                              onChange={(e) => handleInputChange(e, user.id ,index, obj.keyresultid)}
                              value={matchingDetails?.weightage}
                              defaultValue={matchingDetails?.weightage==null?matchingDetails?.weightage:5}
                              onKeyPress={(e) => {
                                const pattern = /[0-9]/;
                                const inputElement = e.target as HTMLInputElement;
                                const inputValue = inputElement.value + String.fromCharCode(e.charCode);
                                if (!pattern.test(String.fromCharCode(e.charCode)) || parseInt(inputValue) > 999) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <span className="suffix-myobjWeight">%</span>
                            <img
                              src="assets/images/ep_remove.svg"
                              className="cur-pointer"
                              alt=""
                              onClick={() => {
                                deleteweightage(obj.keyresultid, user.id);
                                setShowInputs((prevShowInputs) =>
                                  prevShowInputs.filter(
                                    (input) => !(input.userIndex === userIndex && input.objIndex === index && input.krindex === index2)
                                  )
                                );
                                setObjectiveUser((prevShowInputs) =>
                                  prevShowInputs.filter(
                                    (input) => !(input.userid == user.id && input.krid==obj.keyresultid)
                                  )
                                );
                                setGoKrBackCount(1);
                              }}
                            />
                          </div>
                        ) : (
                          <Tooltip title="Assign">
                            <span
                               onClick={() => {
                                toggleInput(userIndex, index, index2);
                                handleInputChange(5, user.id, index, obj.keyresultid);
                              }}
                              className="assign-btn"
                            >
                              Assign
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  );
                })}

              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  </>
);
};

export default ReviewALLKRWeightage;
