import React, { useState, useEffect ,createContext} from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthLayout from './Layouts/AuthLayout';
import MainLayout from './Layouts/MainLayout';
import LoginPage from './Views/Auth/LoginPage';
import Objective from './Views/Pages/Objective/Objectivetab';
import CreateObj from './Views/Pages/Modal/CreateObj';
import UserList_tab from './Views/Pages/UserList/UserList_tab';
import CusWeightage from './Views/Pages/Modal/CusWeightage';
import ForgetPassword from './Views/Auth/ForgetPassword';
import ConfirmPassword from './Views/Auth/ConfirmPassword';
import AdminPanel from './Views/Pages/Admin/AdminPanel';
import Support from './Views/Pages/Support/Support';
import ConversationTab from './Views/Pages/Conversation/ConversationTab';
import ChangePassword from './Views/Auth/Change_password';
import Report from "./Views/Pages/Report/Report";
import MicroLogin from './Views/Auth/microLogin';
import axios from 'axios';
import { log } from 'console';
import { NotificationContext } from './Views/context/NotificationContext';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';


import Assigneesreportweightage from './Views/Pages/Report/Assingneesuserweightage';
import MyObjWeightage from './Views/Pages/Objective/SubObjectiveTab/MyObjWeightage';
import SignOffTab from './Views/Pages/SignOff/SignOffTab';
import InternalReport from "./Views/Pages/Report/InternalReport";

const App: React.FC = () => {
  const initialAuthenticatedState = localStorage.getItem('authenticated') === 'true';
  const [authenticated, setAuthenticated] = useState(initialAuthenticatedState);
  const firstLoggedStatus = localStorage.getItem('firstLoggedStatus');

  const adminStatus = localStorage.getItem('adminstatus');
  const roleName = localStorage.getItem('rolename');
  

  const [stompClient, setStompClient] = useState('');
  const [messagescount, setmessagescount] = useState(0); 

  const createNotification = async () => {
        try {
            const socket = new SockJS('https://goalsynctest.ckdigital.in/okr/ws');
            const client:any = Stomp.over(socket);
            // client.connect({}, () => {
            //   client.subscribe('/topic/messages', (messagedata:any) => {
            //     const receivedMessage = JSON.parse(messagedata.body);
            //     setmessagescount(receivedMessage.content);
            //   });
            // });
            setStompClient(client);
            return () => {
              client.disconnect();
            };
        
      
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      };

      useEffect(() => {
        createNotification();
      },[]);

  useEffect(() => {
    localStorage.setItem('authenticated', authenticated.toString());
    // alert(authenticated)
  }, [authenticated]);

  const handleLogin = (username: string, password: string) => {
    // alert(localStorage.getItem('firstLoggedStatus'));
    setAuthenticated(true);
    // if(firstLoggedStatus == "1"){
    //   alert(localStorage.getItem('firstLoggedStatus'));
    //   setAuthenticated(true);
    // }else{
    //   // alert(localStorage.getItem('firstLoggedStatus'));
    //   setAuthenticated(false);
    //   // alert( firstLoggedStatus)
    // }
  };

  const handleLogout = async() => {
    setAuthenticated(false);
    // alert(12)
    localStorage.removeItem('authenticated');
    
    try {
     await axios.get(BaseUrl + "api/auth/signout");
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
    window.location.href = "/";
    // window.location.reload();
  };
  const routes = [
    {
      path: '/',
      element: authenticated
          ? firstLoggedStatus === '0'
              ? <Navigate to="/change_password" />
              : <Navigate to="/objective" />
          : <AuthLayout component={<LoginPage onLogin={handleLogin} />} />
  },
    // { path: '/', element: authenticated && firstLoggedStatus === '1'? <Navigate to="/objective" /> :<AuthLayout component={<LoginPage onLogin={handleLogin} />} />},
    { path: '/forgetPassword', element: authenticated ? <Navigate to="/objective" /> : <AuthLayout component={<ForgetPassword onLogin={handleLogin} />} /> },
    { path: '/confirmPassword', element: authenticated ? <Navigate to="/objective" /> : <AuthLayout component={<ConfirmPassword/>} /> },
    { path: '/objective', element: authenticated && firstLoggedStatus === '1' ? <MainLayout component={<Objective />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    // { path: '/appraisal', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<CreateObj />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/userlist', element: authenticated && firstLoggedStatus  === '1' && (roleName === "BH"|| roleName === "Admin") ? <MainLayout component={<UserList_tab />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/CusWeightage', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<CusWeightage />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/admin_panel', element: authenticated && firstLoggedStatus  === '1' && (roleName === "BH"|| roleName === "Admin") ? <MainLayout component={<AdminPanel />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/support', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<Support />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/change_password', element: <ChangePassword onLogout={handleLogout} />},
    // { path: '/report', element: <Report onLogout={handleLogout} />},
    { path: '/assigneesweightage', element: <Assigneesreportweightage/>},
    { path: '/report', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<Report />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/myobjweightage', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<MyObjWeightage setReviewCascade={()=>{}} />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/conversation', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<ConversationTab />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/SignOff', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<SignOffTab />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    { path: '/InternalReport', element: authenticated && firstLoggedStatus  === '1' ? <MainLayout component={<InternalReport />} onLogout={handleLogout} /> : <Navigate to="/" /> },
    {path: '/microLogin', element:authenticated === false && <AuthLayout component={<MicroLogin/>} />}
  ];
  return (
    <NotificationContext.Provider value={stompClient}>
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
    </NotificationContext.Provider>

  );
};



export const BaseUrl = 'https://goalsync-dev-backend.citpl.info/';
export const FileUrl = 'https://goalsync-dev-backend.citpl.info/';

// export const BaseUrl = 'https://testing_demo.cavinkare.in/okr/';
// export const FileUrl = 'https://testing_demo.cavinkare.in/okr/';

// export const BaseUrl='http://localhost:8015/okr/'; 
// export const FileUrl='http://localhost:8015/okr/';  


// const profilePath=localStorage.getItem("profilePath");
// // export const profilePhoto=BaseUrl+profilePath ;
// export const profilePhoto = (profilePath && profilePath !== "null") ? (BaseUrl + profilePath) : "assets/images/Default_user.svg";
// check Live Url
export default App;
// chnages test