import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Select,
  Tag,
  Input,
  Form,
  Table,
  Checkbox,
  Radio, message,
  Modal
} from "antd";
import axios from "axios";
import { ArrowRightOutlined, ArrowLeftOutlined,NumberOutlined, PercentageOutlined, CodeOutlined,DollarOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import { BaseUrl } from "../../../App";
import { FileUrl } from "../../../App";
import { Await } from "react-router-dom";
import * as z from 'zod';
import { handleDelete, handleproceed } from "../../alerts/alert";
import Item from "antd/es/list/Item";
const { Option } = Select;

interface DataSourceType {
  key: React.Key;
  Name: string;
  Available: string;
  Assigned: string;
  usertarget: string;
  ImageUrl: string;
}

interface OptionItem {
  value: string;
  label: string;
  image: string;
}
interface OptionItems {
  value: string;
  label: string;
  image: string;
  username: { name: any; id: any }[];

}
interface CustomPaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
}
const handleChange = (value: string) => {
  // console.log(`selected ${value}`);
};
interface objtype {
  objectivelist: () => void;
  orgobjectivelist: () => void;
  objectivesdetailsgets: (selectkrvalue: string, objid: String, userid: String) => void;
  krassignforme: (objid: String, userid: String) => void;
  objname: string;
  objquarter: string;
  objdesc: string;
  objid: string;
  year: string;
  availableweightagekr: number;
  orgdescription: string;
  objectivetype: string;
  objective: any;
  obj_code: string;
  selectedkrvalue:string;
}

/***   For reload KR  Start ***/
type kr = {
  "keyresulttitle": string,
  "keyresultdescription": string,
  "keyresultstatus": number,
  "objectiveid": string,
  "keyresultid": string,
  "objid": string,
  "krid": string
}
interface reviewkr {
  "objectiveid": string,
  "orgobjectiveid": string,
  "parentid": string,
  "objectivestatus": string,
  "createdby": string,
  "objectivename": string,
  "objectivedescription": string,
  "weightage": string,
  "objid": string,
  "keyresultdetails": kr[],
  "year": string,
  "quarter": string,
}
interface assigneeslist {
  krid: string
  totalweightage: number
  userid: string
  weightage: number
}
/*** For reload KR Ends ***/
const MyObjective: React.FC<objtype> = ({ objectivelist,orgobjectivelist,krassignforme, objectivesdetailsgets, objdesc, objquarter, objname, objid, obj_code, year, availableweightagekr, orgdescription, objectivetype, objective,selectedkrvalue }) => {
  // =================Roles And Permission==============================
  const [permission_krcreate, setPermission_krcreate] = useState('');
  // =================Roles And Permission==============================


  const [searchTextValue, setSearchTextValue] = useState<string>("");
  const [uomselectedOption, setUOMSelectedOption] = useState<string>("Percentage");
  const [targetValue, setTargetValue] = useState("100");
  const [currencytargetValue, setCurrencyTargetValue] = useState("");
  const [numerictargetValue, setNumericTargetValue] = useState("");
  const [customtargetValue, setCustomTargetValue] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("Thousands");
  const [selectedCheckin, setSelectedCheckin] = useState<string>("Monthly");
  const [selectedValues, setSelectedValues] = useState<OptionItem[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isCustomVisible, setIsCustomVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWeightageButton, setIsWeightageButton] = useState(false);
  const [krShowCustom, setkrShowCustom] = useState(true);
  const [userFrequency, setUserFrequency] = useState<string>("Monthly");

  const handleShowHideCustom = () => {
    setkrShowCustom(!krShowCustom)
    if (uomselectedOption === "Percentage") {
      const updatedSelectedMemValues = selectedMemValues.map((data) => ({
        ...data,
        usertarget: targetValue,
      }));
      setSelectedMemValues(updatedSelectedMemValues);
    } else if (uomselectedOption === "Currency") {
      const updatedSelectedMemValues = selectedMemValues.map((data) => ({
        ...data,
        usertarget: currencytargetValue,
      }));
      setSelectedMemValues(updatedSelectedMemValues);
    } else {
      const updatedSelectedMemValues = selectedMemValues.map((data) => ({
        ...data,
        usertarget: numerictargetValue,
      }));
      setSelectedMemValues(updatedSelectedMemValues);
    }
  }


  const [keyvalues, setKeyResultValues] = useState<keyresult>({
    keytitle: "",
    description: "",
    remarks: "",
    weightage: "",
    uom: uomselectedOption,
    target: "100",
    amounttype: selectedCurrency,
    objectiveid: "",
    quarter: "",
    keyresultweightage: "",
    groupid: [],
    checkInFrequency: userFrequency,
  });

  const handleCurrencySelect = (value: string) => {
    setSelectedCurrency(value);
  };

  const handleselectedCheckin = (value: string) => {
    setUserFrequency(value);
  }
  const handleUOMSelect = (value: any) => {
    setUOMSelectedOption(value);
    let icon;
    switch (value) {
      case 'Percentage':
        icon = <img src='assets/images/target_uom_percentage.svg' className="target_uom_bg" />;
        break;
      case 'Currency':
        icon = <img src='assets/images/target_uom-rupee.svg' className="target_uom_bg" />;
        break;
      case 'Numeric':
        icon =<img src='assets/images/target_uom_hashtag.svg' className="target_uom_bg" />;
        break;
      case 'Custom':
        icon =<img src='assets/images/target_uom_custom.svg' className="target_uom_bg" />;
        break;
      default:
        icon = null;
    }
    setSelectedIcon(icon);
  
    const selectedOption = document.querySelector(`.custom-select .ant-select-item-option[value="${value}"]`);
    if (selectedOption) {
      if (value === "Percentage") {
        selectedOption.classList.remove("image-behind-text");
        selectedOption.classList.add("text-behind-image");
      } else {
        selectedOption.classList.remove("text-behind-image");
        selectedOption.classList.add("image-behind-text");
      }
    }
    // setKeyResultValues({
    //   ...keyvalues,
    //   target: ""
    // })    
    if (value === "Currency") {
      currencytargetValue === "" ? setCurrencyTargetValue("") : setCurrencyTargetValue(currencytargetValue);
      setKeyResultValues((prevState) => ({
        ...prevState,
        target: currencytargetValue,
      }));
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: currencytargetValue,
        }))
      );
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: currencytargetValue,
        }))
      );
    } else if (value === "Numeric") {
      numerictargetValue === "" ? setNumericTargetValue("") : setNumericTargetValue(numerictargetValue);
      setKeyResultValues((prevState) => ({
        ...prevState,
        target: numerictargetValue,
      }));
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: numerictargetValue,
        }))
      );
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: numerictargetValue,
        }))
      );
    } else if (value === "Custom") {
      customtargetValue === "" ? setCustomTargetValue("") : setCustomTargetValue(customtargetValue);
      setKeyResultValues((prevState) => ({
        ...prevState,
        target: customtargetValue,
      }));
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          userTarget: customtargetValue,
        }))
      );
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: customtargetValue,
        }))
      );
    } else {
      targetValue === "" ? setTargetValue("100") : setTargetValue(targetValue);
      setKeyResultValues((prevState) => ({
        ...prevState,
        target: targetValue,
      }));
      setdatasource(
        dataSource.map((item) => ({
          ...item,
          target: targetValue,
        }))
      );
      setSelectedMemValues(
        selectedMemValues.map((item) => ({
          ...item,
          userTarget: targetValue,
        }))
      );
    }
  };

  const handleUOMRadio = (e: any) => {
    handleUOMSelect(e.target.value);
  };

  // const handleUOMRadio = (e: RadioChangeEvent) => {
  //   setUOMSelectedOption(e.target.value);
  // };

  const renderOptionContent = (value: any, label: any, icon: any) => {
    return (
      <div className={`d-flex align-center justify-between w-200 ${uomselectedOption === value ? 'selected-option' : 'option'}`}>
        <span className="ml-1">{label}</span>
        {icon && <span style={{ marginLeft: '10px' ,float:"right"}}>{icon}</span>}
      </div>
    );
  };
  const [selectedIcon, setSelectedIcon] = useState<any>(<img src='assets/images/target_uom_percentage.svg' className="target_uom_bg"></img>);
  const renderSelectedItem = () => {
    return (
      <div className="selected-option align-center">
        {selectedIcon} <span className="ml-1">{uomselectedOption}</span>
      </div>
    );
  };
  const handleCheckinSelect = (value: any) => {
    setUserFrequency(value);
    let frequency;
    switch (value) {
      case 'Monthly':
        frequency = "3 Check In";
        break;
      case 'Fortnightly':
        frequency = "6 Check In";
        break;
      case 'Weekly':
        frequency ="13 Check In";
        break;
      default:
        frequency = null;
    }
    setSelectedCheckinFrequency(frequency);
  };
 
  const renderOptionCheckinContent = (value: any, label: any, frequency: any) => {
    return (
      <div className={`d-flex align-center justify-between w-200 ${userFrequency === value ? 'selected-option' : 'option'}`}>
        <span className="ml-1">{label}</span>
        {frequency && <span style={{ marginLeft: '10px' }}>{frequency}</span>}
      </div>
    );
  };
  const [selectedCheckinFrequency, setSelectedCheckinFrequency] = useState<any>("4 Check In");

  const renderSelectedCheckinItem = () => {
    return (
      <div className="selected-option align-center">
       <span className="ml-1">{userFrequency}</span> <span className="pl-1 fw-700">({selectedCheckinFrequency} )</span>
      </div>
    );
  };

  const objectid = objid;
  const [dataSource, setdatasource] = useState<DataSourceType[]>([]);
  const [validation, setmaxvalidation] = useState<Boolean[]>([])
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const name = e.target.name as keyof DataSourceType;
    const value = e.target.value;
    const updatedDataSource = [...dataSource];
    updatedDataSource.forEach((item, i) => {
      if (i === index && Object.keys(item).includes(name)) {
        updatedDataSource[i][name] = value;
      }

    });

    if (name == "Assigned") {
      // if(value>dataSource[index].Available){
      //   validation[index]=true;
      // }
      // setKeyResultValues({
      //   ...keyvalues,
      //   weightage: "customized"
      // });

    }
    if (name == "usertarget") {
      if (parseInt(keyvalues.target) < parseInt(value)) {
        validation[index] = true;
      }
      else {
        validation[index] = false;
      }

    }

    setdatasource(updatedDataSource);
  }
  const handleAssignWeightage = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { name, value } = e.target;

    const updatedSelectedMemValues = selectedMemValues.map(item => {
      if (item.value === itemId) {
        return { ...item, [name]: Number(value) };
      }
      return item;
    });

     let temTarget: number = 0;
     temTarget = updatedSelectedMemValues
    .map((user: any) => Number(user.usertarget))
    .reduce((acc: number, value: number) => acc + value, 0); 
    setSumTarget(temTarget);
    setSelectedMemValues(updatedSelectedMemValues);

    // Update the assignWeightage for the specific item
    const updatedDataSource = dataSource.map(item => {
      if (item.key === itemId) {
        return { ...item, [name]: value };
      }
      return item;
    });


    //  const updatedValidation = [...validation];
    //   if (name === "Assigned") {
    //     // Add your Assigned validation logic here
    //   } else if (name === "target") {
    //     // Validate the "target" value
    //     if (parseInt(keyvalues.target) < parseInt(value)) {
    //       updatedValidation[itemId] = true;
    //     } else {
    //       updatedValidation[itemId] = false;
    //     }
    //   }
    // Update the state with the modified array
    setdatasource(updatedDataSource);

  };
  // const capitalizeFirstLetter = (str: string): string => {
  //   return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (text: string, record: any, index: number) => (
        <span className="align-center">
          <img src={record.ImageUrl ? FileUrl + record.ImageUrl : "assets/images/Default_user.svg"} onError={handleImageError} alt="" className="table_user" />
          <span className="pl-1">{text}</span>
        </span>
      ),
    },
    // {
    //   title: "Weightage Available",
    //   dataIndex: "Available",
    //   key: "Available",
    //   render: (text: string, record: any) =>
    //     <span className={parseInt(record.Available) < record.Assigned ? "c_FF4747" : ""}>{parseInt(record.Available) < 0 ? (0) : (record.Available)} %</span>,
    // },
    {
      title: "Weightage Assigned",
      dataIndex: "Assigned",
      key: "Assigned",
      render: (text: string, record: any, index: number) => (
        <div className="input-with-suffix">
          <input
            // type="text" 
            // className="target_bg" 
            className="assigned_bg"
            value={record.Assigned}
            name="Assigned"
            // onChange={(e)=>{ handleInputChange(e,index)}}
            onChange={(e) => {
              const inputVal = e.target.value;
              const numericRegex = /^[0-9]*$/;
              if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                // handleInputChange(e, index);
                handleAssignWeightage(e, record.key);
              }
            }}
            disabled={record.key == localStorage.loginId && objectivetype != "orgobjective"}
          />
          <span className="suffix">%</span>
        </div>
      ),
    },
  ];

  if (uomselectedOption !== 'Custom' && keyvalues.uom !== 'Custom') {
    columns.push({
      title: "Target",
      dataIndex: "usertarget",
      key: "usertarget",
      render: (text: string, record: any, index: number) => (
        <div>
          <div className="input-with-suffix">
            <input
              // type="number" 
              className={`target_bg ${keyvalues.uom === "Currency" ? "" : ""}`}
              name="usertarget"
              value={record.usertarget}
              // onChange={(e)=>{ handleInputChange(e,index)}}
              onChange={(e) => {
                const inputVal = e.target.value;
                const numericRegex = /^[0-9]*$/;
                if (keyvalues.uom === "Percentage") {
                  if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                    // handleInputChange(e, index);
                    handleAssignWeightage(e, record.key);
                  }
                } else if (keyvalues.uom === "Custom") {
                  handleAssignWeightage(e, record.key);
                } else {
                  if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                    // handleInputChange(e, index);
                    handleAssignWeightage(e, record.key);
                  }
                }
              }}
            />
            {/* <span className="suffix">%</span> */}
            {keyvalues.uom === "Percentage" ? <span className="suffix">%</span> : keyvalues.uom === "Currency" ?
              <span className="suffix " style={{ left: "30%" }}>{selectedCurrency}</span> : <span className="suffix"></span>}
          </div>
          {validation[index] == true &&
            <div>
              <span className="danger">must be lesser than given target</span>
            </div>
          }

        </div>
      ),
    })
  } else {
    columns.filter(value => value.title !== 'Target')
  }
  const profilePath = localStorage.getItem("profilePath");
  const profilePhoto = (profilePath && profilePath !== "null") ? (FileUrl + profilePath) : "assets/images/Default_user.svg";

  const custom_filteredData = dataSource.filter((record: DataSourceType) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchTextValue.toLowerCase())
    )
  );

  const handleSelectChange = (selected: OptionItem[]) => {
    const updatedValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    setSelectedValues(updatedValues);
  };
  const handleTagClose = (valueToRemove: string) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      valueToRemove === opt.value
    );
    const updatedGrpValues = assignedusers.filter(value => value.value !== valueToRemove);
    if (mydetails?.value == valueToRemove) {
      const checkboxElement = document.getElementById('includeMyselfCheckboxAdd') as HTMLInputElement;

      // checkboxElement.removeAttribute('checked');
      setmydetails(undefined)
      setCheckboxChecked2(false);
    }
    setAssignedUsers(updatedGrpValues);
    setSelectedGrpValues(updatedGrpValues);
    handleGrpSelectChange(updatedGrpValues)
    const updatedMemValues1 = dataSource.filter(value =>
      assignedusers.every(obj =>
        obj.username.every(user => user.id !== value.key)
      )
    );

    // setassigneduserweightage(updatedMemValues1);
  };

  const handleTagClosegrp = (valueToRemove: string) => {
    const updatedGrpValues = assignedusers.filter(
      (value) => value.value !== valueToRemove
    );
    setAssignedUsers(updatedGrpValues);
    const updatedMemValues1 = dataSource.filter(
      (value) => value.key !== valueToRemove
    );
    setdatasource(updatedMemValues1);
  };

  var krschema = z.object({
    keytitle: z.string().min(1, { message: 'Title field is required' }),
    // keyresultweightage: z.string().min(1, { message: 'keyresult Weightage field is required' }),
    // description: z.string().min(1, { message: 'description field is required' }), 
    // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
    // band: z.string().min(1, { message: 'band field is required' }),
  });
  if (uomselectedOption !== "Logic") {
    krschema = z.object({
      keytitle: z.string().min(1, { message: 'Title field is required' }),
      // keyresultweightage: z.string().min(1, { message: 'keyresult Weightage field is required' }),
      target: z.string().min(1, { message: 'Target field is required' })
      // description: z.string().min(1, { message: 'description field is required' }), 
      // weightage: z.string().min(1, { message: 'Individual weightage field is required' }),
      // band: z.string().min(1, { message: 'band field is required' }),
    });
  }

  const krschemasave =
    z.object({
      keytitle: z.string().min(1, { message: 'Title field is required' }),
      // keyresultweightage: z.string().min(1, { message: 'keyresultweightage field is required' }),
      // band: z.string().min(1, { message: 'band field is required' }),
    });
  const [errors, setErrors] = useState<z.ZodIssue[] | null>(null);
  const [statusset, setstatus] = useState<string>("");
  const [clicked, setClicked] = useState(false);
  const [casclicked, setCasClicked] = useState(false);
  const [customweightagedetails, setCustomWeightageDetails] = useState<DataSourceType[]>([]);


  let weightagePopupCount = 0;
  function userAssignedWeightage(): OptionItem[] {
    const greaterWeightageUser: OptionItem[] = [];
    for (let index = 0; index < dataSource.length; index++) {
      if (parseInt(dataSource[index].Assigned) > parseInt(dataSource[index].Available)) {
        const popupUser = {
          value: dataSource[index].Name,
          label: dataSource[index].Name,
          image: dataSource[index].ImageUrl !== null ? dataSource[index].ImageUrl : "",
        }
        weightagePopupCount++;
        greaterWeightageUser.push(popupUser);
      }
    }
    return greaterWeightageUser;
  }

  const handleSubmit = (status: number) => {
    // Prevent double-clicking
    if (isButtonDisabled) {
      return;
    }

    // Disable the button
    setIsButtonDisabled(true);
    // Validate data against schema
    const validationResult = krschema.safeParse(keyvalues);
    const validationsave = krschemasave.safeParse(keyvalues);
    if (status == 2) {
      if (validationResult.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        savekeyresult(status);

        // display the pop up weightage less than available 
        // const greaterWeightageUser: OptionItem[] = userAssignedWeightage();


        // if (weightagePopupCount === 0) {
        //   savekeyresult(status)
        // } else {
        //   weightagePopupCount === 1 ? handleDelete(greaterWeightageUser[0].label, greaterWeightageUser[0].image, keyvalues.keytitle, "Key result") :
        //     handleproceed(greaterWeightageUser, keyvalues.keytitle, "Key result");
        // }
      } else {
        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationResult.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationResult.error.errors);
      }
    }
    else {
      if (validationsave.success) {
        // Data is valid, proceed with your logic

        setErrors(null);
        savekeyresult(status)
      } else {

        // Data is invalid, update errors state
        const fieldErrors: { [key: string]: string } = {};
        validationsave.error.errors.forEach(error => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(validationsave.error.errors);
      }
    }
    setTimeout(() => {
      // Enable the button
      setIsButtonDisabled(false);
    }, 2000);
  };
  const renderOption = (option: OptionItem) => (
    <div>
      <img className="member_user" src={option.image} style={{ marginRight: "8px" }} onError={handleImageError} />
      {option.label}
    </div>
  );
  interface OptionItem {
    value: string;
    label: string;
    image: string;
    usertarget?: string;
  }
  type UserData = {
    id: string;
    username: string;
    assignedweightage: number;
  }
  interface group {
    id: string,
    groupname: string,
    assignedusers: string[]
  }
  const [Memoptions, setMemOptions] = useState<OptionItem[]>([
  ]);
  const userslist = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/users/user_list", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      //const users = response.data; // Assuming response.data is an array of user objects
      const users: { id: string, name: string, email: string, departmentname: string, rolename: string, profilePath: string }[] = response.data;
      // Map over the array of users to create options
      const newOptions = users.map((id) => {
        const rolename = id.rolename === 'FH:' ? 'FH' : ' ';
        return {
          value: id.id,
          label: rolename + " " + id.name + " " + "-" + " " + "(" + id.departmentname + ")" + " " + "-" + " " + "(" + id.email + ")",
          image: id.profilePath ? FileUrl + id.profilePath : "assets/images/Default_user.svg", // Assuming you have an image property for each user
        };
      });
      setMemOptions(newOptions);
      // setMemOptions((prevMemOptions) => [
      //   ...prevMemOptions,
      //   { value: response.data.id, label: response.data.name, image: "" },
      // ]);

    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  Memoptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      // If image is missing or empty, set a default image
      option.image = "assets/images/Default_user.svg";
    }
  });
  // objective hide and show
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  // Custom Weightage hide and show


  const [selectedMemValues, setSelectedMemValues] = useState<OptionItem[]>([]);
  const [selectedGrpValues, setSelectedGrpValues] = useState<OptionItems[]>([]);
  const [sumTarget, setSumTarget] = useState(0);
  let storeTarget: number = 0;
  const handleMemSelectChange = (selected: OptionItem[]) => {
    const selectedMemValues = Memoptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const userid: string[] = selectedMemValues.filter((item) => item.value).map((item) => String(item.value));
    if (mydetails) {
      userid.push(mydetails.value);
    }
    setSelectedMemValues(selectedMemValues);
    assigneduserkeywightage(userid);
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(keyvalues.target);
    setSumTarget(storeTarget);
  };
  const assignedkeyweightage = () => {
    const allValues = [...selectedMemValues, ...assignedusers];
    const userid = allValues.map(item => item.value);

  }
  // const assigneduserkeywightage = async (userid: string[]) => {
  //   const formdata = new FormData();
  //   const objectiveid: String = objid;
  //   userid.forEach(id => {
  //     formdata.append("userid", id);
  //   });
  //   formdata.append("objectiveid", objid);
  //   const userdetails = await axios.post(BaseUrl + "api/keyresult/weighatgeassined", { userid, objectiveid },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${localStorage.accessToken}`
  //       },
  //     },
  //   )
  //   const assigneduserdetails = userdetails.data.body;
  //   const responsevalues: DataSourceType[] = assigneduserdetails.map((item: any) => ({
  //     key: item.id,
  //     Name: item.username,
  //     Available: item.assignedweightage,
  //     target: keyvalues.target == "" ? "100" : keyvalues.target,
  //     Assigned: keyvalues.weightage,
  //     ImageUrl: item.profilePath //"assets/images/user.svg"

  //   }));
  //   // assigneduserdetails.array.forEach(() => {
  //   //   validation.push(false)
  //   // });

  //   setdatasource(responsevalues);
  // }
  const assigneduserkeywightage = async (userid: string[]) => {
    const formdata = new FormData();
    const objectiveid: String = objid;
    userid.forEach(id => {
      formdata.append("userid", id);
    });
    formdata.append("objectiveid", objid);

    try {
      const userdetails = await axios.post(
        BaseUrl + "api/keyresult/weighatgeassined",
        { userid, objectiveid },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.accessToken}`
          },
        }
      );


      const assigneduserdetails = userdetails.data.body;
      // Merge new member details with existing dataSource
      const updatedDataSource = dataSource.filter(item => assigneduserdetails.some((user: any) => user.id === item.key));//[...dataSource];
      assigneduserdetails.forEach((item: any) => {
        const existingItemIndex = updatedDataSource.findIndex(existingItem => existingItem.key === item.id);
        if (existingItemIndex !== -1) {
          updatedDataSource[existingItemIndex] = {
            ...updatedDataSource[existingItemIndex],
            Available: item.assignedweightage,
            ImageUrl: item.profilePath
          };
        } else {
          updatedDataSource.push({
            key: item.id,
            Name: item.username,
            Available: item.assignedweightage,
            usertarget: keyvalues.target == "" ? "100" : keyvalues.target,
            Assigned: keyvalues.weightage,
            ImageUrl: item.profilePath
          });
        }
      });
      setdatasource(updatedDataSource);
    } catch (error) {
      // console.error("Error fetching assigned user details:", error);
    }
  };

  const toggleCustomVisibility = () => {
    setIsCustomVisible(!isCustomVisible);
    assignedkeyweightage();
  };
  const toggleCustomVisibilitys = () => {
    setIsCustomVisible(!isCustomVisible);
  };
  const handleMemTagClose = (valueToRemove: string | undefined) => {
    const updatedMemValues = selectedMemValues.filter(
      (value) => value.value !== valueToRemove
    );
    const updatedMemValues1 = dataSource.filter(
      (value) => value.key !== valueToRemove
    );
    setSelectedMemValues(updatedMemValues);
    setdatasource(updatedMemValues1);
    let length = updatedMemValues.length;
    if (uomselectedOption === "Percentage") {
      storeTarget = (length) * Number(targetValue);
      setSumTarget(storeTarget);
    } else if (uomselectedOption === "Currency") {
      storeTarget = (length) * Number(currencytargetValue);
      setSumTarget(storeTarget);
    } else {
      storeTarget = (length) * Number(numerictargetValue);
      setSumTarget(storeTarget);
    }
  };
  const [assignedusers, setAssignedUsers] = useState<OptionItems[]>([]);
  const handleGrpSelectChange = (selected: OptionItems[]) => {
    const selectedGrpValues = GrpOptions.filter((opt) =>
      selected.some((item) => item.value === opt.value)
    );
    const selectedUserIds = selectedGrpValues.flatMap(user => user.username.map(userdetails => userdetails.id));
    // const filteredEditMemberDetails = editmemberdetails.filter(val =>
    //   !selectedUserIds.includes(val)
    // );  
    if (mydetails != undefined) {
      selectedUserIds.push(mydetails?.value)
    }
    const selectedgrpids = selectedGrpValues.map(user => user.value)
    setKeyResultValues({
      ...keyvalues,
      groupid: selectedgrpids// Using another value
    });
    // selectedUserIds.push(mydetails?.value)

    setSelectedGrpValues(selectedGrpValues);
    setAssignedUsers(selectedGrpValues)
    assigneduserkeywightage(selectedUserIds);
    // const userid: string[] = selectedGrpValues.filter((item) => item.value).map((item) => String(item.value));
  };

  Memoptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });

  const [GrpOptions, setGrpOptions] = useState<OptionItems[]>([
  ]);

  GrpOptions.forEach((option) => {
    if (!option.image || option.image.trim() === "") {
      option.image = "assets/images/Default_user.svg";
    }
  });
  // Custom Weightage and View Weightage  hide and show
  const [ViewWeightage, setViewWeightage] = useState(false);
  const [isSearchMem, setisSearchMem] = useState(true);
  const handleSearchMember = () => {
    setisSearchMem(!isSearchMem);
    if (isSearchMem == false) {
      // handleTagCloseonchangegrp(assignedusers);
      setAssignedUsers([]);
      setdatasource([]);
    }
    else {
      setSelectedMemValues([]);
      setdatasource([]);
      setSelectedGrpValues([]);
      setCheckboxChecked2(false);
      setmydetails(undefined);
    }
  };
  // pagination
  const itemsPerPage: number = 1;
  // Your content array, replace this with your actual data
  const contentData: string[] = [
    "Track progress on infrastructure upgrade projects to ensure timely completion.",
    "Item 2",
    "Item 3",
  ];
  const groupuserslist = async () => {
    try {
      const groupslist = await axios.get(BaseUrl + "api/group/users_group",
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`
          }
        });
      const groupusersdatas: group[] = groupslist.data;
      const newoptions = groupusersdatas.map((id) => ({
        value: id.id,
        label: id.groupname,
        image: "",
        weightage: 0,
        username: id.assignedusers.map((userString: any) => {
          return {
            name: userString.name,
            id: userString.userid,
          };
        }), // Assuming you have an image property for each user
      }));
      setGrpOptions(newoptions);
    } catch (error) {
      
    }
   
  }

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const { TextArea } = Input;

  const showDrawer = () => {
    setOpen(true);
    setKeyResultValues(prevState => ({
      ...prevState,
      objectiveid: objid,
      amounttype: selectedCurrency,
      uom: uomselectedOption,
      quarter: objquarter,
      target: keyvalues.target,
    }));
  };

  const onClose = () => {
    setOpen(false);
    // window.location.reload();
    setKeyResultValues({
      keytitle: "",
      description: "",
      remarks: "",
      weightage: "",
      uom: uomselectedOption,
      target: targetValue,
      amounttype: selectedCurrency,
      objectiveid: "",
      quarter: "",
      keyresultweightage: "",
      groupid: [],
      checkInFrequency: userFrequency,
    });
    setSelectedMemValues([]);
    setSelectedGrpValues([]);
    setAssignedUsers([]);
    setmydetails(undefined);
    setCheckboxChecked2(false);
    setdatasource([]);
    setErrors(null);
    //window.location.reload();
  };

  const [objectiveText, setObjectiveText] = useState(" ");
  const [descriptionText, setDescriptionText] = useState(
    "Lorem ipsum dolor sit amet"
  );
  const [remarkText, setRemarkText] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
  );

  interface maxWords {
    name_max_word_count: number;
    description_max_word_count: number;
    remark_max_word_count: number;
  };

  const [maxWords, setMaxWords] = useState<maxWords>({
    name_max_word_count: 0,
    description_max_word_count: 0,
    remark_max_word_count: 0,
  });

  const getWordCount = async () => {
    try {
      const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Key Result");
      setMaxWords(maxWords.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  }

  const handleNumeric = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setNumericTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      usertarget: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    const updatedSelectedMemValues = selectedMemValues.map((data) => ({
      ...data,
      usertarget: event?.target.value,
    }));
    setSelectedMemValues(updatedSelectedMemValues);
    setKeyResultValues((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleCurrency = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setCurrencyTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      usertarget: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    const updatedSelectedMemValues = selectedMemValues.map((data) => ({
      ...data,
      usertarget: event?.target.value,
    }));
    setSelectedMemValues(updatedSelectedMemValues);
    setKeyResultValues((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleCustom = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    setCustomTargetValue(event.target.value);
    const updatedUserDetails = dataSource.map((item) => ({
      ...item,
      usertarget: event.target.value,
    }));
    setdatasource(updatedUserDetails);
    setKeyResultValues((keyvalues) => ({
      ...keyvalues,
      target: event.target.value,
    }));
    let length = selectedMemValues.length;
    storeTarget = (length) * Number(event.target.value);
    setSumTarget(storeTarget);
  }

  const handleTextChange = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
    setText: Function,
    maxWords: number
  ) => {
    const newText = event.target.value;
    const inputName = event.target.name;

    // Check if the input exceeds the maximum word count
    if (newText.trim().split(/\s+/).join("").length <= maxWords) {
      const wordCount = newText.trim().split(/\s+/).join("").length;
      if (wordCount <= maxWords) {
        setText(newText);
      }
      if (Object.keys(keyvalues).includes(event.target.name)) {
        setKeyResultValues({
          ...keyvalues,
          [event.target.name]: event.target.value
        });
        if (event.target.name === 'weightage') {
          const updatedUserDetails = dataSource.map((item) => ({
            ...item,
            Assigned: event.target.value,
          }));
          setdatasource(updatedUserDetails);
        }
        if (event.target.name === 'target' && keyvalues.uom === 'Percentage') {
          setTargetValue(event.target.value);

          const updatedSelectedMemValues = selectedMemValues.map((items) => ({
            ...items,
            usertarget: event.target.value,
          }));
          setSelectedMemValues(updatedSelectedMemValues);

          const updatedUserDetails = dataSource.map((item) => ({
            ...item,
            usertarget: event.target.value,
          }));
          setdatasource(updatedUserDetails);

          let length = selectedMemValues.length;
          storeTarget = (length) * Number(event.target.value);
          setSumTarget(storeTarget);
          // setdatasource(prevState => ({
          //   ...prevState,
          //   usertarget: event.target.value,
          // }));
        }
        // if (event.target.name === 'currencytarget') {
        //   alert(event.target.value);
        //   setCurrencyTargetValue(event.target.value);
        //   const updatedUserDetails = dataSource.map((item) => ({
        //     ...item,
        //     target: event.target.value,
        //   }));
        //   setdatasource(updatedUserDetails);
        // } 
        // if (event.target.name === 'numerictarget' && uomselectedOption === 'Numeric') {
        //   setNumericTargetValue(event.target.value);
        //   const updatedUserDetails = dataSource.map((item) => ({
        //     ...item,
        //     target: event.target.value,
        //   }));
        //   setdatasource(updatedUserDetails);
        // } 

        // if (event.target.name === 'target') {
        //   const updatedUserDetails = dataSource.map((item) => ({
        //     ...item,
        //     target: event.target.value,
        //   }));
        //   console.log('Updated User Details:', updatedUserDetails);
        //   setdatasource(updatedUserDetails);
        // }
      }
    }

  };
  type keyresult = {
    objectiveid: string;
    keytitle: string;
    description: string;
    remarks: string;
    weightage: string;
    uom: string,
    target: string,
    amounttype: string,
    quarter: string,
    keyresultweightage: string,
    groupid: string[],
    checkInFrequency: string,
  }

  type keyassigned = {
    userid: string,
    username: string,
    individualweightage: number,
    target: number,
  }
  const [objdata, setobjdata] = useState<reviewkr[]>([]);
  const [assignessweightage, setassigneesweightae] = useState<assigneeslist[]>([]);
  // const [keysassigned,setkeyassigned] = useState<keyassigned[]>([])


  // const assigningvalueskeyresult = async()=>{
  //   // setKeyResultValues(prevState => ({
  //   //   ...prevState,
  //   //   Uom:uomselectedOption, 
  //   //   target:targetValue,
  //   //   amounttype:selectedCurrency,
  //   //   objectiveid:objid
  //   // }));
  //    savekeyresult();
  // }

  const savekeyresult = async (status: number) => {
    const usersweightage = dataSource.filter(user => user.key != localStorage.loginId)
    const valuesassigned: keyassigned[] = dataSource.map(data => ({
      userid: String(data.key),
      username: data.Name,
      individualweightage: parseFloat(data.Assigned),
      target: parseFloat(data.usertarget)
    }));

    if (status == 1) {
      var operation = "Save"
    }
    else {
      operation = "Cascade"
    }
    if (status == 2) {
      if (selectedMemValues.length == 0 && mydetails == undefined && assignedusers.length==0) {
        Modal.error({
          content: (
            <>
              <p className="fs-16 mb-3 keytitle_scroll">{keyvalues.keytitle}</p>
              <p className="fs-18"><b>Error :</b> Assignees Or CFT Is Mandatory </p>
            </>
          ),
          centered: true,
        })
      }
      else if (uomselectedOption !== "Logic") {
        if (usersweightage.some(user => user.usertarget == "")) {
          Modal.error({
            content: (
              <>
                <p className="fs-16 mb-3 keytitle_scroll">{keyvalues.keytitle}</p>
                <p className="fs-18"><b>Error : </b>Weightage for the member is required</p>
              </>
            ),
            centered: true,
          })
        }
        // else if (parseInt(keyvalues.keyresultweightage) - availableweightagekr != 0) {
        //   Modal.error({
        //     content: (
        //       <>
        //         <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
        //         <p className="fs-18"><b>Note : </b>You can only save the key result but Cannot  be cascaded since the KR weightage should be equal to 100%</p>
        //       </>
        //     ),
        //     centered: true,
        //   });
        // }
        else {
          Modal.confirm({
            title: 'Do you want to ' + operation + ' this keyresult ?',
            content: (
              <>
                <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
                <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
              </>
            ),
            okText: operation,
            cancelText: 'Back',
            okButtonProps: { className: 'C_btn' },
            cancelButtonProps: { className: 'outline_btn' },
            centered: true,
            async onOk() {
              message.success({
                content: (
                  <div className="success-message justify-center align-center p-1">
                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                    <span className="c_32C6A0 fs-16">
                      KeyResult Created successfully.
                    </span>
                  </div>
                ),
                duration: 5,
                icon: null,
              });

              try {

                const response = await axios.post(BaseUrl + "api/keyresult/createkeyresult", {
                  keyvalues, keysassigned: valuesassigned, keyresultstatus: status
                },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.accessToken}`
                    }
                  }

                )
                onClose();
                orgobjectivelist();
                objectivelist();
                objectivesdetailsgets(selectedkrvalue,objective,localStorage.username);
                krassignforme(objective,localStorage.loginId);
              } catch (error) {
                // console.log(error)
              }
            },
            onCancel() {
              setCasClicked(false);
              // console.log('Cascade action cancelled');
            },
          });
        }
      }
      // else if (usersweightage.some(usersweightage.some(user => user.Assigned == ""))) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3 keytitle_scroll">{keyvalues.keytitle}</p>
      //         <p className="fs-18"><b>Error : </b>Weightage for the member is required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }

      // else if (parseInt(keyvalues.keyresultweightage) - availableweightagekr != 0) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
      //         <p className="fs-18"><b>Note : </b>You can only save the key result but Cannot be cascaded since the KR weightage should be equal to 100%</p>
      //       </>
      //     ),
      //     centered: true,
      //   });
      // }
      else {

        Modal.confirm({
          title: 'Do you want to ' + operation + ' this keyresult ?',
          content: (
            <>
              <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
              <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
            </>
          ),
          okText: operation,
          cancelText: 'Back',
          okButtonProps: { className: 'C_btn' },
          cancelButtonProps: { className: 'outline_btn' },
          centered: true,
          async onOk() {
            message.success({
              content: (
                <div className="success-message justify-center align-center p-1">
                  <img src="assets/images/check-circle.svg" alt="Success Icon" />
                  <span className="c_32C6A0 fs-16">
                    KeyResult Created successfully.
                  </span>
                </div>
              ),
              duration: 5,
              icon: null,
            });

            try {

              const response = await axios.post(BaseUrl + "api/keyresult/createkeyresult", {
                keyvalues, keysassigned: valuesassigned, keyresultstatus: status
              },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.accessToken}`
                  }
                }

              )
              onClose();
              objectivelist();
              orgobjectivelist();
              objectivesdetailsgets(selectedkrvalue,objective,localStorage.loginId);
              krassignforme(objective,localStorage.loginId);

              // window.location.reload();
            } catch (error) {
              // console.log(error)
            }
          },
          onCancel() {
            setCasClicked(false);
            // console.log('Cascade action cancelled');
          },
        });

      }

    }
    else {
      const greaterWeightageUser: OptionItem[] = userAssignedWeightage();
      // if (usersweightage.some(user => user.Available == "0")) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Zero Please Check.</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // }
      // else {
      Modal.confirm({
        title: 'Do you want to ' + operation + ' this Key Result ?',
        content: (
          <>
          <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
          <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          </>
          // <>
          //   <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
          //   {greaterWeightageUser.length === 0 ? (
          //     <>
          //       <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //     </>
          //   ) : greaterWeightageUser.length === 1 ? (
          //     <>
          //       <Row>
          //         <Col xl={6} lg={6} md={6} sm={8} xs={24} className="align-center">
          //           <div className='text-center'>
          //             <img src={greaterWeightageUser[0].image !== "" ? FileUrl + greaterWeightageUser[0].image : "assets/images/Default_user.svg"} className='D_user text-center' alt="" />
          //             <p>{greaterWeightageUser[0].value}</p>
          //           </div>
          //         </Col>
          //         <Col xl={18} lg={18} md={18} sm={16} xs={24}>
          //           <p>"User has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
          //           <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //         </Col>
          //       </Row>
          //     </>
          //   ) : (
          //     <>
          //       <p>"Users has exceeded 100% weightage, Please adjust the weightage accordingly. Please ensure the weightage should be lesser than or equal to 100% weightage "</p>
          //       <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          //     </>)}
          // </>
        ),
        okText: operation,
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        async onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  KeyResult Created successfully.
                </span>
              </div>
            ),
            duration: 1,
            icon: null,
          });

          try {

            const response = await axios.post(BaseUrl + "api/keyresult/createkeyresult", {
              keyvalues, keysassigned: valuesassigned, keyresultstatus: status
            },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.accessToken}`
                }
              }

            )
            objectivelist();
            orgobjectivelist();
            objectivesdetailsgets(selectedkrvalue,objective,localStorage.loginId);
            krassignforme(objective,localStorage.loginId);
            onClose();

            // window.location.reload();
          } catch (error) {
            // console.log(error)
          }
        },
        onCancel() {
          setClicked(false);
          // console.log('Cascade action cancelled');
        },
      });
    }
    // }

    // }
  }

  const GetUserAccess = async () => {

    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl + 'api/menupermission/rolepermissionlist?role=' + role);
      const data = response.data;
      const rolePermissionData = JSON.parse(data[0].rolepermission);

      rolePermissionData.map((permission: any) => {
        // if(permission.menu==="View"){
        //   setPermission_krview(permission.key_result);
        // }
        if (permission.menu === "Create") {
          setPermission_krcreate(permission.key_result);
        }

      })

      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    GetUserAccess();

    getWordCount();
    userslist();
    groupuserslist();
    //userRequestSignOffStatus();
    setKeyResultValues(prevState => ({
      ...prevState,
      objectiveid: objid,
      amounttype: selectedCurrency,
      uom: uomselectedOption,
      quarter: objquarter,
      year:year,
      target: keyvalues.target,
      checkInFrequency:userFrequency
    }));
  }, [objectid, selectedCurrency, objquarter, statusset, uomselectedOption ,userFrequency, year])
  const [mydetails, setmydetails] = useState<OptionItem>();
  const [checkboxChecked2, setCheckboxChecked2] = useState(false);
  const handlesubmitcustomweightage = (status: string) => {
    const usersweightage = dataSource.filter(user => user.key != localStorage.loginId)
    // Prevent double-clicking
    if (isWeightageButton) {
      return;
    }

    // Disable the button
    setIsWeightageButton(true);

    if (keyvalues.keytitle == "" || keyvalues.quarter == "") {
      message.error({
        content: (
          <div className="c_FF4747 justify-center align-center p-1">
            {/* <img src="assets/images/check-circle.svg" alt="Danger Icon" /> */}
            <span className="c_FF4747 fs-16">
              keytitile name is mandatory
            </span>
          </div>
        ),
        duration: 1,
        icon: null,
      });
      toggleCustomVisibility()
    }
    else {
      // if (usersweightage.some(user => user.Available == "0")) {
      //   Modal.error({
      //     content: (
      //       <>
      //         <p className="fs-16 mb-3">{keyvalues.keytitle}</p>
      //         <p className="fs-18"><b>Note : </b>User Weightage Is Required</p>
      //       </>
      //     ),
      //     centered: true,
      //   })
      // } else {
      Modal.confirm({
        title: 'Do you want to save the weightage this key result ?',
        content: (
          <>
            <p className="fs-16 mb-3 keytitle_scroll">{keyvalues.keytitle}</p>
            <p className="fs-14"><b>Note : </b>Post cascading, you can modify the description, update remarks, add members, and customize weightage</p>
          </>
        ),
        okText: 'Save',
        cancelText: 'Back',
        okButtonProps: { className: 'C_btn' },
        cancelButtonProps: { className: 'outline_btn' },
        centered: true,
        onOk() {
          message.success({
            content: (
              <div className="success-message justify-center align-center p-1">
                <img src="assets/images/check-circle.svg" alt="Success Icon" />
                <span className="c_32C6A0 fs-16">
                  Weightage Saved successfully.
                </span>
              </div>
            ),
            duration: 2,
            icon: null,
          });
          toggleCustomVisibility();
        },
        onCancel() {
          setCasClicked(false);
          // console.log('Cascade action cancelled');
        },
      });
      // }
    }
    setTimeout(() => {
      // Enable the button
      setIsWeightageButton(false);
    }, 2000);
  }
  const handlecheckbox = () => {
    const checkboxElement = document.getElementById('includeMyselfCheckboxKR') as HTMLInputElement;
    if (checkboxElement) {
      const includeMyself = checkboxElement.checked;
      setCheckboxChecked2(true);
      if (includeMyself) {
        const localStorageOption: OptionItem = {
          value: localStorage.loginId,
          label: localStorage.name,
          image: profilePhoto
        };
        const userid: string[] = [];
        // setSelectedMemValues([...selectedMemValues, localStorageOption]);
        selectedMemValues.forEach((user) => {
          userid.push(user.value)
        })

        assignedusers.forEach((user) => {
          userid.push(user.value)
        })
        userid.push(localStorageOption.value)

        // const userid: string[] = [String(localStorageOption.value)];
        setmydetails(localStorageOption)
        assigneduserkeywightage(userid);

      } else {
        setmydetails(undefined)
        handleMemTagClose(localStorage.loginId);
        handleTagClose(localStorage.loginId);
        setCheckboxChecked2(false);
      }
    }
  }

  const CustomEmptyMemberText = () => (
    <div style={{ textAlign: 'center' }}>
      No Data
    </div>
  );
  const CustomEmptyGroupText = () => (
    <div style={{ textAlign: 'center' }}>
      No Group Data
    </div>
  );

  const handleImageError = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = 'assets/images/Default_user.svg';
  };


  interface signOffResponse {
    Id: string;
    signOffStatus: number;
    userId: string;
    userUId: number;
    empId: string;
    year: string;
    quarter: string;
  }
  const [userSignOff, setUserSignOff] = useState<signOffResponse>({
    Id: '',
    signOffStatus: 0,
    userId: '',
    userUId: 0,
    empId: '',
    year: '',
    quarter: '',
  });

  const userRequestSignOffStatus = async () => {

    try {

      const formData = new FormData();
      formData.append("userId", localStorage.getItem('loginId') || '');
      formData.append("quarter", localStorage.getItem('Quarter') || '');
      formData.append("year", localStorage.getItem('year') || '');


      const apiUrl = BaseUrl + `api/signOff/confirm/status`;

      await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }).then((response) => {
        // console.log(response.data.data);
        setUserSignOff(response.data.data);
      })
    } catch (error) {
      // console.log('ERROR', error);
    }
  }

  return (
    <div className="">
      {permission_krcreate 
      // && userSignOff.signOffStatus === 0 
      ?
        (
          <Row>
            <button className="cyan_button" onClick={showDrawer}>
              + Add Key Result
            </button>
          </Row>

        ) : ""}

      <Drawer
        title={
          isCustomVisible ? (
            <div className="cur-pointer" onClick={toggleCustomVisibilitys}>
              <ArrowLeftOutlined /> &nbsp;Custom Target
            </div>
          ) : (
            <>
              Add Key Result
            </>
          )
        }
        size="large"
        onClose={onClose}
        maskClosable={false}
        open={open}
        className="objective-canvas"
      >
        <Form className="mb-5">
          {isCustomVisible ? (
            <>
              <div className="cw_table">
                {isSearchMem && selectedMemValues.length > 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)})</p></Row> : ''}


                {/* {isSearchMem && selectedMemValues.length > 0 && mydetails!=undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length+1})</p></Row> : '' }
                      {isSearchMem && selectedMemValues.length > 0 && mydetails==undefined ? <Row><p className="c_32B7D9 m-0 mb-1">Members ({selectedMemValues.length})</p></Row> : '' } */}
                <Row justify={"space-between"} className="mb-2">
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="position-relative"
                  >
                    <input
                      type="search"
                      placeholder="Search Member"
                      className="w-100 table_search_inputbox pl-2"
                      value={searchTextValue}
                      // onSearch={handleSearch}
                      onChange={(e) => setSearchTextValue(e.target.value)}
                    />
                    <img
                      src="assets/images/search.svg"
                      className="table_search_img"
                      alt=""
                    />
                  </Col>
                </Row>
                <Table
                  dataSource={custom_filteredData}
                  columns={columns}
                  scroll={{ x: true }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
              <div className="Submit-section">
                <button className="border-btn" onClick={toggleCustomVisibility}>Back</button>
                <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }}>submit</button>
              </div>
            </>
          ) : (<>

            {/* <div className=" mb-3">
            <Row>
            <Col xl={16} lg={16} md={16} sm={16} xs={24}>
            <label htmlFor="" className="fw-600 form-contol-border">
                  Objective Description
                </label>
              <input
                placeholder="Write here...."
                className="canvas-objective-input w-100"
                name="keytitle"
                value={objdesc}
                onChange={(e) =>
                  handleTextChange(e, setObjectiveText, maxWords.remark_max_word_count)
                }
                readOnly
              />
              </Col>
              </Row>
              </div> */}
            <div className="mb-3">
              <Row>
                <Col xl={16} lg={16} md={16} sm={16} xs={24}>
                  <div className="container_label">
                    <div className="wrapper_label align-center mb-1">
                      <span className="cup_org_bg align-center">
                        <img
                          src="assets/images/crown.svg"
                          alt=""
                          className="pr-1"
                        />{obj_code}
                        {/* {item.objcode} */}
                      </span>
                      <span className="triangle"></span>
                    </div>
                  </div>
                  <p className="fs-16 c-000 m-0 mt-1">{objname}</p>
                  {/* <input className="canvas-objective-input w-90" value={objname} /> */}
                </Col>
                <Col xl={7} lg={7} md={7} sm={7} xs={24}>
                  <label htmlFor="" className="fw-600 c_32B7D9">
                    Cycle
                  </label>
                  <br />
                  <p className="fs-16 fw-300">{objquarter + " " + year}</p>
                </Col>

              </Row>
              {/* <Row>
                <Col xl={24}>
                  <label htmlFor="" className="fw-600">Objective Description</label> <br />
                  <p className="fs-16 fw-300 org_obj_name">{orgdescription}</p>
                </Col>
              </Row> */}
            </div>
            {/* <div className="mb-3">
              <Row className="align-center">
                <Col>
                  <label htmlFor="" className="canvas-objective-title">
                    Key Result Weightage<sup className="c_32B7D9">*</sup>
                  </label>
                  <input className="canvas-objective-input w-90" name="keyresultweightage" value={keyvalues.keyresultweightage}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      const numericRegex = /^[0-9]*$/;
                      if (inputVal === '' || (parseInt(inputVal) <= availableweightagekr && parseInt(inputVal) > 0 && numericRegex.test(inputVal))) {
                        handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                        
                      }
                    }} />
                  {errors && errors.find(error => error.path[0] === 'keyresultweightage') && (
                    <p className="danger">{errors.find(error => error.path[0] === 'keyresultweightage')?.message}</p>
                  )}
                </Col>
                <Col className="mt-2">
                  <label htmlFor="" className="canvas-objective-title">
                    Available Weightage :
                  </label>
                  <span className="c_32B7D9">&nbsp;{availableweightagekr}%</span>
                </Col>
              </Row>
            </div> */}

            <div className=" mb-3">
              <div className="justify-between">
                <label className="canvas-objective-title c_32B7D9">
                  {" "}
                  Key Result Name *
                </label>
                <span className="c-0050 fs-12 mt-1">
                  {keyvalues.keytitle.trim() === ""
                    ? 0
                    : keyvalues.keytitle.trim().split(/\s+/).join("").length}{" "}
                  / {maxWords.name_max_word_count}
                </span>
              </div>
              <div className="objective-title-inner">
                <TextArea
                  rows={3}
                  placeholder="Write here...."
                  className="canvas-objective-input w-100"
                  name="keytitle"
                  value={keyvalues.keytitle}
                  onChange={(e) =>
                    handleTextChange(e, setObjectiveText, maxWords.name_max_word_count)
                  }
                />
                {errors && errors.find(error => error.path[0] === 'keytitle') && (
                  <p className="danger">{errors.find(error => error.path[0] === 'keytitle')?.message}</p>
                )}
              </div>
            </div>
            <div className=" mb-3">
              <div className="justify-between">
                <label className="canvas-objective-title c_32B7D9">
                  {" "}
                  Key Result Description *
                  {/* <sup className="c_32B7D9">*</sup> */}
                </label>
                <span className="c-0050 fs-12 mt-1">
                  {keyvalues.description.trim() === ""
                    ? 0
                    : keyvalues.description.trim().split(/\s+/).join("").length}{" "}
                  / {maxWords.description_max_word_count}
                </span>
              </div>
              <div className="objective-title-inner">
                <TextArea
                  rows={3}
                  placeholder="Write here...."
                  className="canvas-objective-input w-100"
                  value={keyvalues.description}
                  name="description"
                  onChange={(e) =>
                    handleTextChange(e, setDescriptionText, maxWords.description_max_word_count)
                  }
                />
                {errors && errors.find(error => error.path[0] === 'description') && (
                  <p className="danger">{errors.find(error => error.path[0] === 'description')?.message}</p>
                )}
              </div>
            </div>
            <div className="assign-section mb-3">
              <label htmlFor="" className="fw-600 fs-14 c_32B7D9">
                Assign Members *
              </label>
              {Memoptions.length == 0 &&
                <span className="danger ml-3">Assignees Not Available</span>
              }
              <span
                className="float-right c_32B7D9 text_line fw-600 fs-12 cur-pointer"
                onClick={handleSearchMember}
              >
                {isSearchMem ? "Search Group" : "Search Members"}
              </span>
              {isSearchMem ?
                (<span className="float-right c_32B7D9 text_line fs-12 cur-pointer">
                  <Checkbox className="c_000 fs-12" onClick={handlecheckbox} id="includeMyselfCheckboxKR" checked={checkboxChecked2}>Include Myself</Checkbox>
                </span>) : (<></>)}
              <br />
              <div className="mt-1">
                {isSearchMem ? (
                  <Select
                    mode="multiple"
                    placeholder={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="assets/images/member.svg"
                          alt="Placeholder"
                          style={{ marginRight: "8px" }}
                        />
                        <span className="fs-12">Select Member</span>
                      </div>
                    }
                    onChange={handleMemSelectChange}
                    value={selectedMemValues}
                    className="w-100 form-control"
                    labelInValue
                    optionLabelProp="label"
                    dropdownRender={(menu) => <div>{menu}</div>}
                    notFoundContent={<CustomEmptyMemberText />}
                    // options={Memoptions}
                    // filterOption={(input, option) =>
                    //   option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    // }
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    disabled={Memoptions.length == 0}
                  >
                    {Memoptions.map((option) => (
                      <Option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      >
                        {renderOption(option)}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    mode="multiple"
                    placeholder={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="assets/images/people.svg"
                          alt="Placeholder"
                          style={{ marginRight: "8px" }}
                        />
                        <span className="fs-12">Select Group</span>
                      </div>
                    }
                    onChange={handleGrpSelectChange}
                    value={assignedusers}
                    className="w-100 form-control"
                    labelInValue
                    optionLabelProp="label"
                    dropdownRender={(menu) => <div>{menu}</div>}
                    notFoundContent={<CustomEmptyGroupText />}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false

                    }
                    disabled={Memoptions.length == 0}

                  >
                    {GrpOptions.map((option) => (
                      <Option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      >
                        {renderOption(option)}
                      </Option>
                    ))}
                  </Select>
                )}
                <div
                  className={
                    checkboxChecked2
                      ? "assgin_selected_val"
                      : "" ||
                        selectedMemValues
                        ? "assgin_selected_val"
                        : "" || selectedGrpValues
                          ? "assgin_selected_val"
                          : "" || mydetails != undefined ? "assgin_selected_val" : " "
                  }
                >
                  {isSearchMem && selectedMemValues.length > 0 || mydetails != undefined && assignedusers.length == 0 ? <Row><p className="c_32B7D9 m-0 mb-1">Members({selectedMemValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)})</p></Row> : ''}
                  {mydetails != undefined
                    &&
                    <Tag
                      key={mydetails?.value}
                      // closable
                      onClose={() => handleTagClose(mydetails?.value)}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        color: "#000",
                        borderRadius: 15,
                        padding: "5px 10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={mydetails?.image}
                        // alt={mydetails?.label}
                        alt=""
                        style={{ marginRight: "8px" }}
                        onError={handleImageError}
                        className="select_user"
                      />
                      {mydetails?.label}
                    </Tag>
                  }


                  {selectedMemValues.map((value) => (
                    <Tag
                      key={value.value}
                      closable
                      onClose={() => handleMemTagClose(value.value)}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        color: "#000",
                        borderRadius: 15,
                        padding: "5px 10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={value.image}
                        onError={handleImageError}
                        // alt={value.label}
                        alt=""
                        style={{ marginRight: "8px" }}
                        className="select_user"
                      />
                      {value.label}
                    </Tag>
                  ))}
                  {isSearchMem && selectedMemValues.length === 0 && mydetails?.label == null ? (
                    <>
                      <p className="c_AAA text-center">No members added</p>
                    </>
                  ) :
                    (<></>
                    )}
                  {isSearchMem === false && selectedGrpValues.length === 0 && mydetails?.label == null ? (
                    <>
                      <p className="c_AAA text-center">No groups added</p>
                    </>
                  ) :
                    (
                      <>
                      </>
                    )}
                  {assignedusers.map((value) => (
                    <Tag
                      key={value.value}
                      closable
                      onClose={() => handleTagClose(value.value)}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        color: "#000",
                        borderRadius: 15,
                        padding: "5px 10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/Default_user.svg"
                        alt=""
                        style={{ marginRight: "8px" }}
                        className="select_user"
                      />
                      {value.label}
                    </Tag>

                  ))}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Row className="">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="justify-between align-center">
                    <label htmlFor="" className="fw-600">
                      Weightage <sup className="c_32B7D9">*</sup>
                    </label>
                  </div>
                  <Input
                    // type="number"
                    name="weightage"
                    placeholder=""
                    className="canvas-objective-input mt-1"
                    value={keyvalues.weightage}
                    suffix="%"
                    max={100}
                    // onChange={(e) =>
                    //   handleTextChange(e, setObjectiveText, maxWords.remark)
                    // }
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      const numericRegex = /^[0-9]*$/;
                      if (inputVal === 'Weightage' || inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) > 0 && numericRegex.test(inputVal))) {
                        handleTextChange(e, setRemarkText, maxWords.remark_max_word_count);
                        // console.log("inputvaluesdetails", inputVal)
                      }
                    }}
                  />
                  {errors && errors.find(error => error.path[0] === 'weightage') && (
                    <p className="danger">{errors.find(error => error.path[0] === 'weightage')?.message}</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row className="">
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <label htmlFor="" className="fw-600 c_32B7D9">
                    Target UOM *
                  </label>{" "}
                  <br />
                  <Select
                    className="custom-select mt-1"
                    style={{ width: 200 }}
                    placeholder="Percentage"
                    onChange={handleUOMSelect}
                    value={uomselectedOption}
                    dropdownRender ={(menu)=> (           
                    <div>{React.cloneElement(menu, { className: 'custom-menu'})}
                    </div> )}
                     optionLabelProp="label" >
                      <Option value="Percentage" label={renderSelectedItem()}>
                        <Radio
                          value="Percentage"
                          checked={uomselectedOption === "Percentage"}
                          onChange={() => handleUOMSelect("Percentage")}
                        >
                          {renderOptionContent("Percentage", "Percentage",<img src="assets/images/target_uom_percentage.svg" alt="" className="float-right" />)}
                        </Radio>
                      </Option>
                      <Option value="Currency" label={renderSelectedItem()}>
                        <Radio
                          value="Currency"
                          checked={uomselectedOption === "Currency"}
                          onChange={() => handleUOMSelect("Currency")}
                        >
                          {renderOptionContent("Currency", "Currency (INR)",<img src="assets/images/target_uom-rupee.svg" alt="" />)}
                        </Radio>
                      </Option>
                      <Option value="Numeric" label={renderSelectedItem()}>
                        <Radio
                          value="Numeric"
                          checked={uomselectedOption === "Numeric"}
                          onChange={() => handleUOMSelect("Numeric")}
                        >
                          {renderOptionContent("Numeric", "Numeric", <img src="assets/images/target_uom_hashtag.svg" alt="" />)}
                        </Radio>
                      </Option>
                      <Option value="Custom" label={renderSelectedItem()}>
                        <Radio
                          value="Custom"
                          checked={uomselectedOption === "Custom"}
                          onChange={() => handleUOMSelect("Custom")}
                        >
                          {renderOptionContent("Custom", "Custom",<img src="assets/images/target_uom_custom.svg" alt="" />)}
                        </Radio>
                      </Option>
                  </Select>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <div className="row d-flex justify-between align-center">
                    {uomselectedOption === "Logic" ? "" : (<>
                      <label htmlFor="" className="fw-600 c_32B7D9">
                        Target for each assignee *
                      </label>
                    </>)}

                    <div className="justify-end">
                      <label htmlFor="" className="c_32B7D9 text_line fs-12 cur-pointer fw-700 " onClick={handleShowHideCustom}>
                        {krShowCustom ? "Show Custom" : "Hide Custom"}
                      </label>
                    </div>
                  </div>

                  {uomselectedOption === "Percentage" && (
                    <>
                      <Input
                        placeholder=""
                        className="canvas-objective-input mt-1"
                        name="target"
                        value={targetValue}
                        suffix="%"
                        // onChange={(e) =>
                        //   handleTextChange(e, setObjectiveText, maxWords.remark)
                        // }
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericRegex = /^[0-9]*$/;
                          if (inputVal === '' || (parseInt(inputVal) <= 100 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                            setTargetValue(e.target.value)
                            handleTextChange(e, setObjectiveText, maxWords.remark_max_word_count)
                          }
                        }}
                      />
                      {errors && errors.find(error => error.path[0] === 'target') && (
                        <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                      )}
                    </>
                  )}

                  {uomselectedOption === "Currency" && (
                    <>
                      <Row className="target_each_currency">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Input
                            placeholder=""
                            className="uom-input"
                            value={currencytargetValue}
                            name="currencytarget"
                            // onChange={(e) => setTargetValue(e.target.value)}
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                setCurrencyTargetValue(e.target.value)
                                handleCurrency(e);
                              }
                            }}
                            prefix="₹"
                          />
                          {errors && errors.find(error => error.path[0] === 'target') && (
                            <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                          )}
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                        <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                          <Select
                            className="custom-select"
                            style={{ width: 200 }}
                            placeholder="Select currency"
                            onChange={handleCurrencySelect}
                            value={selectedCurrency}
                          >
                            <Option value="Thousands">
                              <Radio
                                value="Thousands"
                                checked={selectedCurrency === "Thousands"}
                              >
                                Thousands
                              </Radio>
                            </Option>
                            <Option value="Lakhs">
                              <Radio
                                value="Lakhs"
                                checked={selectedCurrency === "Lakhs"}
                              >
                                Lakhs
                              </Radio>
                            </Option>
                            <Option value="Crores">
                              <Radio
                                value="Crores"
                                checked={selectedCurrency === "Crores"}
                              >
                                Crores &nbsp;&nbsp;&nbsp;
                              </Radio>
                            </Option>
                          </Select>
                        </Col>
                      </Row>
                    </>
                  )}
                  {uomselectedOption === "Logic" && (
                    <div>
                      {/* <label htmlFor="" className="fw-600">
                            Target <sup className="c_32B7D9">*</sup>
                          </label>
                          <Input
                            placeholder="Enter logic"
                            className="canvas-objective-input mt-1"
                            value={targetValue}
                            onChange={(e) => setTargetValue(e.target.value)}
                          /> */}
                    </div>
                  )}
                  {uomselectedOption === "Numeric" && (
                    <>
                      {/* <label htmlFor="" className="fw-600">
                          Target for each assignee <sup className="c_32B7D9">*</sup>
                        </label> */}
                      <Input
                        placeholder="Enter numeric value"
                        className="uom-input mt-1"
                        value={numerictargetValue}
                        name="numerictarget"
                        // onChange={(e) => setTargetValue(e.target.value)}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const numericRegex = /^[0-9]*$/;
                          if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                            setNumericTargetValue(e.target.value)
                            handleNumeric(e);
                          }
                        }}
                      />
                      {errors && errors.find(error => error.path[0] === 'target') && (
                        <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                      )}
                    </>
                  )}
                  {uomselectedOption === "Custom" && (
                    <>
                      {/* <label htmlFor="" className="fw-600">
                          Target for each assignee <sup className="c_32B7D9">*</sup>
                        </label> */}
                      <Input
                        placeholder="Enter custom value"
                        className="uom-input mt-1"
                        value={customtargetValue}
                        name="customtarget"
                        onChange={(e) => {
                          setCustomTargetValue(e.target.value)
                          handleCustom(e);
                        }}
                      />
                      {errors && errors.find(error => error.path[0] === 'target') && (
                        <p className="danger">{errors.find(error => error.path[0] === 'target')?.message}</p>
                      )}
                    </>
                  )}



                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row className="">
                <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                </Col>
                <Col xl={12} lg={12} md={14} sm={24} xs={24}>
                  <span
                    className="c_32B7D9 fs-14 cur-pointer float-right text_line"
                    onClick={toggleCustomVisibility}
                  >
                    Custom Weightage <ArrowRightOutlined />
                  </span>
                </Col>
              </Row>
            </div>
            <div>
              {krShowCustom ? "" : <>
                <div
                  className={
                    checkboxChecked2
                      ? "assgin_selected_val"
                      : "" ||
                        selectedMemValues
                        ? "assgin_selected_val"
                        : "" || selectedGrpValues
                          ? "assgin_selected_val"
                          : "" || mydetails != undefined ? "assgin_selected_val" : " "
                  }
                >
                  {isSearchMem && selectedMemValues.length > 0 || mydetails != undefined && assignedusers.length == 0 ?
                    <div className="justify-between align-center">
                      <span className="c_000 fs-14 fw-700 m-0 mb-1">{selectedMemValues.length + ((typeof mydetails !== 'undefined') ? 1 : 0)}&nbsp;{selectedMemValues.length > 1 ? "Members" : "Member"}</span>
                      {uomselectedOption != "Custom" ? (
                        <span className="fw-700 c-000">Total: {sumTarget} {uomselectedOption === "Percentage" ? " % " : (
                          uomselectedOption === "Currency" ? (selectedCurrency === "Thousands" ? "K" : (selectedCurrency === "Lakhs" ? "L" : "Cr"))
                            : (uomselectedOption === "Numeric" ? "" : " "))}
                        </span>
                      ) : (<></>)
                      }
                    </div>
                    : ''}
                  {mydetails != undefined
                    &&
                    
                      <div className="d-flex justify-between align-center mb-1">
                      <div className="d-flex align-center">
                      <img
                        src={mydetails?.image}
                        // alt={mydetails?.label}
                        alt=""
                        style={{ marginRight: "8px" }}
                        onError={handleImageError}
                        className="select_user"
                      />
                      {mydetails?.label}
                      </div>
                      
                        {/* <input type="text" className="target_input_box" /> */}
                        {uomselectedOption != "Custom" ? (
                          <Input
                            placeholder=""
                            className="target_input_box"
                            name="usertarget"
                            value={mydetails?.usertarget}
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                handleAssignWeightage(e, mydetails?.value);
                                // setUserTarget(e.target.value);
                              }
                            }}
                          />
                        ) : (
                          <>
                            {/* <Input
                            placeholder=""
                            className="target_input_box"
                            name="usertarget"
                            // value={value.usertarget}
                            onChange={(e) => {
                              handleAssignWeightage(e, value.value);
                            }}
                          /> */}
                          </>
                        )
                        }
                      </div>
                  }
                  {selectedMemValues.map((value, index) => (
                    <>
                      <div className="d-flex justify-between align-center mb-1">
                        <div className="d-flex align-center">
                          <img
                            src={value.image}
                            onError={handleImageError}
                            // alt={value.label}
                            alt=""
                            style={{ marginRight: "8px" }}
                            className="select_user"
                          />
                          {value.label}</div>
                        {/* <input type="text" className="target_input_box" /> */}
                        {uomselectedOption != "Custom" ? (
                          <Input
                            placeholder=""
                            className="target_input_box"
                            name="usertarget"
                            value={value.usertarget}
                            onChange={(e) => {
                              const inputVal = e.target.value;
                              const numericRegex = /^[0-9]*$/;
                              if (inputVal === '' || (parseInt(inputVal) <= 100000 && parseInt(inputVal) >= 1 && numericRegex.test(inputVal))) {
                                handleAssignWeightage(e, value.value);
                                // setUserTarget(e.target.value);
                              }
                            }}
                          />
                        ) : (
                          <>
                            {/* <Input
                            placeholder=""
                            className="target_input_box"
                            name="usertarget"
                            // value={value.usertarget}
                            onChange={(e) => {
                              handleAssignWeightage(e, value.value);
                            }}
                          /> */}
                          </>
                        )
                        }
                      </div>
                    </>
                  ))}
                  {isSearchMem && selectedMemValues.length === 0 && mydetails?.label == null ? (
                    <>
                      <p className="c_AAA text-center">No members added</p>
                    </>
                  ) :
                    (<></>
                    )}
                  {isSearchMem === false && selectedGrpValues.length === 0 && mydetails?.label == null ? (
                    <>
                      <p className="c_AAA text-center">No groups added</p>
                    </>
                  ) :
                    (
                      <>
                      </>
                    )}
                  {assignedusers.map((value) => (
                    <Tag
                      key={value.value}
                      closable
                      onClose={() => handleTagClose(value.value)}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        color: "#000",
                        borderRadius: 15,
                        padding: "5px 10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="assets/images/Default_user.svg"
                        alt=""
                        style={{ marginRight: "8px" }}
                        className="select_user"
                      />
                      {value.label}
                    </Tag>

                  ))}
                </div>
              </>}
            </div>
              {(keyvalues.quarter === "Q1" && localStorage.getItem("year") === "2024-2025") ? (<></>) : (
                <Row className="mb-1 mt-1">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <label htmlFor="" className="c_32B7D9 fw-700 fs-14 mb-1">Checkin Frequency</label>
                    <Select
                      className="custom-select mb-1"
                      defaultValue="Monthly"
                      onChange={handleselectedCheckin}
                      value={userFrequency}
                      style={{ width: 600 }}
                      dropdownRender ={(menu)=> (           
                        <div>{React.cloneElement(menu, { className: 'custom-menu'})}
                        </div> )}
                         optionLabelProp="label" 
                    >
                      <Option value="Monthly" label={renderSelectedCheckinItem()}>
                        <Radio
                          value="Monthly"
                          checked={userFrequency === "Monthly"}
                          onChange={() => handleCheckinSelect("Monthly")}
                        >
                      {renderOptionCheckinContent("Monthly", "Monthly","(3)")}
                          {/* Monthly */}
                        </Radio>
                      </Option>
                      <Option value="Fortnightly" label={renderSelectedCheckinItem()}>
                        <Radio
                          value="Fortnightly"
                          checked={userFrequency === "Fortnightly"}
                          onChange={() => handleCheckinSelect("Fortnightly")}
                        >
                        {renderOptionCheckinContent("Fortnightly", "Fortnightly","(6)")}
                        </Radio>
                      </Option>
                      <Option value="Weekly" label={renderSelectedCheckinItem()}>
                        <Radio
                          value="Weekly"
                          checked={userFrequency === "Weekly"}
                          onChange={() => handleCheckinSelect("Weekly")}

                        >
                        {renderOptionCheckinContent("Weekly", "Weekly","(13)")}
                        </Radio>
                      </Option>

                    </Select>
                  </Col>
                </Row>
              )}
            <div className="remark-section mt-1 mb-3">
              <div className="justify-between">
                <label className="canvas-objective-title c_32B7D9">
                  Remarks (Optional){" "}
                </label>
                <span className="c-0050 fs-12 mt-1">
                  {keyvalues.remarks === "" ? 0 : keyvalues.remarks.trim().split(/\s+/).join("").length}{" "}
                  / {maxWords.remark_max_word_count}
                </span>
              </div>
              <div className="objective-title-inner">
                <TextArea
                  rows={3}
                  placeholder="Write here...."
                  className="canvas-objective-input w-100"
                  value={keyvalues.remarks}
                  name="remarks"
                  onChange={(e) =>
                    handleTextChange(e, setRemarkText, maxWords.remark_max_word_count)
                  }
                />
              </div>
            </div>
            <div className="Submit-section">
              {isCustomVisible ? (
                <>
                  <button
                    className="cancel-btn"
                    onClick={toggleCustomVisibility}
                  >
                    Back
                  </button>
                  <button className="cyan_button" onClick={() => { handlesubmitcustomweightage("1") }} disabled={isWeightageButton} >Submit</button>
                </>
              ) : (
                <>
                  <button
                    className="cancel-btn"
                    onClick={toggleCustomVisibility}
                  >
                    Cancel
                  </button>
                  <button className="cyan_button mr-1" onClick={() => { handleSubmit(1) }}
                  //  disabled={clicked}
                  >
                    {isCustomVisible ? "Submit" : "Save"}
                  </button>
                  <button onClick={() => { handleSubmit(2) }}
                    disabled={isButtonDisabled}
                    // disabled={casclicked} 
                    className={
                      keyvalues.keytitle === ""
                        // keyvalues.description === "" ||  
                        // keyvalues.target === ""
                        ? "cascade_button"
                        : "cyan_button"
                    }>{isCustomVisible ? "Submit" : "Cascade"}</button>
                </>
              )}
            </div>

          </>)}
        </Form>
      </Drawer>
    </div>
  );
};

export default MyObjective;
