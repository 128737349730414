import React, { useState,useEffect} from 'react';
import { Input, Table ,Row,Col,Spin } from 'antd';
import axios from "axios";
import { BaseUrl } from "../../../App";
import { ColumnType } from 'antd/es/table';


interface DataSourceType {
  key: React.Key;
  EmployeeID: string;
  Name: string;
  Designation: string;
  Email: string;
  Grade: string;
  // BusinessUnitHead: string;
  PayrollStatus: string;
  ManagerName: string;
  HRBPName: string;
}

const Budgie_HEPL: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [data, setData] = useState<DataSourceType[]>([]);
  const button = document.querySelector('.cyan_button')as HTMLButtonElement;

  const fetchData = async () => {

    try {

      const response = await axios.get(BaseUrl +'api/users/get_heplusers');
       setData(response.data); // Assuming the response contains an array of data
      // console.log(response.data);

    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // const dataSource: DataSourceType[] = [
    // {
    //   key: '1',
    //   EmployeeID: '1',
    //   Name: 'Mike',
    //   Designation: 32,
    //   Email: 'user@gmail.com',
    //   ContactNo: '110',
    //   BusinessUnitHead: 'lorem',
    //   PayrollStatus: 'IT',
    //   ManagerName: 'vicky',
    //   HRBPName: 'leela',
    // },
    // Add more data as needed
  // ];
 
  const columns: ColumnType<DataSourceType>[] = [
    { title: 'Employee ID', dataIndex: 'empId', key: 'empId' ,
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.EmployeeID || '').toLowerCase();
      const nameB = (b.EmployeeID || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }
  },
    { title: 'Name', dataIndex: 'username', key: 'username',
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.Name || '').toLowerCase();
      const nameB = (b.Name || '').toLowerCase();
      return nameA.localeCompare(nameB);
    } 
  },
    { title: 'Designation', dataIndex: 'designation', key: 'designation',
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.Designation || '').toLowerCase();
      const nameB = (b.Designation || '').toLowerCase();
      return nameA.localeCompare(nameB);
    } 
   },
    { title: 'Email', dataIndex: 'email', key: 'email',
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.Email || '').toLowerCase();
      const nameB = (b.Email || '').toLowerCase();
      return nameA.localeCompare(nameB);
    } 
   },
    { title: 'Grade', dataIndex: 'grade', key: 'grade' ,
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.Grade || '').toLowerCase();
      const nameB = (b.Grade || '').toLowerCase();
      return nameA.localeCompare(nameB);
    } 
  },
    { title: 'Payroll Status', dataIndex: 'payrollStatus', key: 'payrollStatus',
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.PayrollStatus || '').toLowerCase();
      const nameB = (b.PayrollStatus || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }  },
    { title: 'Manager Name', dataIndex: 'supervisorID', key: 'supervisorID',sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.ManagerName || '').toLowerCase();
      const nameB = (b.ManagerName || '').toLowerCase();
      return nameA.localeCompare(nameB);
    } 
   },
    { title: 'HRBP Name', dataIndex: 'hrID', key: 'hrID',
    sortDirections: ['descend', 'ascend'], 
    sorter: (a, b) => {
      const nameA = (a.HRBPName || '').toLowerCase();
      const nameB = (b.HRBPName
         || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }  },
    // Add more columns as needed
  ];
 
  const handleSearch = (value: string) => {
    setSearchTextValue(value);
  };
 
  // const filteredData = dataSource.filter((record: DataSourceType) =>
  //   Object.values(record).some((value) =>
  //     String(value).toLowerCase().includes(searchTextValue.toLowerCase())
  //   )
  // );

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);

  }, []);


  async function handleClick() {    
    // alert("test");
    setIsLoading(true);

    try {

    
      const apiUrl = BaseUrl + `api/users/get_bugiehepl_userlist`;
      const formData=new FormData();   

     const response= await axios.get(apiUrl,{
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
        
          
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }
 
    setIsLoading(false);


}
if (button) {
   button.addEventListener('click', handleClick);
} else {
  // console.error('Button element not found');
}
const filteredData = searchText ? data.filter((record: any) =>
Object.values(record).some((value) =>
  String(value).toLowerCase().includes(searchText.toLowerCase())
)
) : data;
 
  return (
    <div>
        <Row justify={"end"} className='mb-2'>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
        <button className="cyan_button mb-1"   onClick={handleClick} >
        HEPL-Sync
          </button>
        </Col>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
          <input
            type="search"
            placeholder="Search Member"
            className="w-100 table_search_inputbox pl-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <img src="assets/images/search.svg" className="table_search_img" alt=""></img>
        </Col>
      </Row>
        {isLoading ? <Spin  tip="Loading..." > </Spin>  :  <Table dataSource={filteredData}  columns={columns} scroll={{ x: true }}pagination={{ pageSize: 10 }} />}
          

     

      
    </div>
  );
};
 
export default Budgie_HEPL;
