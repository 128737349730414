import React, { useState } from "react";
import { Drawer, Select, Input, Form, Tag, Avatar, message } from "antd";
// import { Option } from "antd/lib/mentions";
import { BaseUrl, FileUrl } from "../../../App";
import { useEffect } from "react";
import axios from "axios";
import MyTeam from "../Members/MyTeam";

const { Option } = Select;

interface OptionItem {
    value: string;
    label: string;
    image: string;
}



const Memoptions1: OptionItem[] = [
    { value: "value1", label: "renu", image: "assets/images/Avatar1.png" },
    { value: "value2", label: "rose", image: "assets/images/user.svg" },
    { value: "value3", label: "harini", image: "" },
    { value: "value4", label: "tamil", image: "assets/images/user1.svg" },
    { value: "value5", label: "vicky", image: "assets/images/Avatar1.png" },
    { value: "value6", label: "priya", image: "assets/images/user2.svg" },
    { value: "value7", label: "divya", image: "assets/images/Avatar1.png" },
];
interface GroupList{
    GroupList: () => Promise<void>;

}

const CreateGroup: React.FC<GroupList>= ({GroupList}) => {
      // =================Roles And Permission==============================
const [permission_groupcreate, setPermission_groupcreate] = useState('');

// =================Roles And Permission==============================

    const [selectedMemValues, setSelectedMemValues] = useState<Members[]>([]);

    const handleMemSelectChange = (selected: any[]) => {

        //     const selectedIds = selected.map(item => item.value);
        //     const selectedMembers = Memberoption.filter(option => selectedIds.includes(option.id));
        // //     const selectedMemValues = Memberoption.filter((member) =>
        // //   selected.some((option) => option.id === member.id)
        // // );
        //     // setSelectedMemValues(selectedMemValues);
        //       setSelectedMemValues(prevSelectedMemValues => [...prevSelectedMemValues, ...selectedMembers]);


        // Extract ids of the newly selected members
        const selectedIds = selected.map(item => item.value);

        // Filter out the selected members that are already present in selectedMemValues
        const newSelectedMembers = selectedIds.filter(id => !selectedMemValues.some(member => member.id === id));


        // Find the member objects corresponding to the newly selected ids
        const selectedMembers = Memberoption.filter(option => selectedIds.includes(option.id));
 
        // Update selectedMemValues state by merging the existing state with the newly selected members
        setSelectedMemValues(selectedMembers);
        // Find the member objects corresponding to the newly selected ids
        // const selectedMembers = Memberoption.filter(option => newSelectedMembers.includes(option.id));

        // // Update selectedMemValues state by merging the existing state with the newly selected members
        // setSelectedMemValues(prevSelectedMemValues => [...prevSelectedMemValues, ...selectedMembers]);

    };

    const handleMemTagClose = (valueToRemove: string) => {
        const updatedMemValues = selectedMemValues.filter(
            (value) => value.id !== valueToRemove
        );
        setSelectedMemValues(updatedMemValues);
    };

    const renderOption = (option: Members) => (
        <div>
            <Avatar size="small" src={"assets/images/Avatar1.png"} style={{ marginRight: "8px" }} />
            {option.name}
        </div>
    );

    // Memoptions.forEach((option) => {
    //     if (!option.image || option.image.trim() === "") {
    //         option.image = "assets/images/Default_user.svg";
    //     }
    // });

    const [isSearchMem, setisSearchMem] = useState(false);
    const handleSearchMember = () => {
        setisSearchMem(!isSearchMem);
    };

    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };

    interface Members {

        id: string,
        username: string,
        name: string,
        email: string,
        supervisor_id: string,
        employeeid: string,
        profilePath: string,
    }

    interface maxWords {
        name_max_word_count: number;
        description_max_word_count: number;
        remark_max_word_count: number;
    };
    const [maxWords, setMaxWords] = useState<maxWords>({
        name_max_word_count: 0,
        description_max_word_count: 0,
        remark_max_word_count: 0,
    })

    const getWordCount = async () => {
        try {
            const maxWords = await axios.get(BaseUrl + "api/inputCount/getWordCount/Groups");
            setMaxWords(maxWords.data);
        } catch (error) {
            // console.log(error);
        }
    };

    const GetUserAccess = async () => {
  
        try {
          const role = localStorage.getItem('rolename');
          const userid = localStorage.getItem('username');
          const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
          const data = response.data;
          const rolePermissionData = JSON.parse(data[0].rolepermission);
          rolePermissionData.map((permission:any)=>{
            
            if(permission.menu==="Create"){
                setPermission_groupcreate(permission.group)
            }
          })
      
          // setRolePermissions(rolePermissionData);
        } catch (error) {
        //   console.error('Error fetching data:', error);
        }
      };
    useEffect(() => {
        GetUserAccess();
        MembersList();
        getWordCount();
    }, [])

    const [Memberoption, setMemberOption] = useState<Members[]>([]);

    const MembersList = async () => {

        try {
            const apiUrl = BaseUrl + `api/users/userListBySupervisor/${localStorage.year}/${localStorage.Quarter}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    setMemberOption(response.data);

                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }
    }

    interface group {
        groupName: string,
        asssignMember: string[],
    }


    const [group, setGroup] = useState({
        groupname: "",
        assignedusers: []
    });

    // const handlegroupInputChange = (event: any) => {
    //     const { name, value } = event.target;

    //     setGroup({
    //         ...group,
    //         [name]: value
    //     });
    // };

    const handlegroupInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setGroup: Function,
        maxWords: number
    ) => {
        const newText = event.target.value;
        const inputName = event.target.name;
        // Check if the input exceeds the maximum word count
        if (newText.trim().split(/\s+/).join("").length <= maxWords) {
            // Update the state based on the input name
            if (Object.keys(group).includes(inputName)) {
                setGroup({
                    ...group,
                    [inputName]: newText
                });
            }
        }
    }

    const createGroup = async () => {

        const formData = new FormData();
        const userObjectIdList: any[] = [];

        selectedMemValues.forEach((member) => {
            if (member.id !== null) {
                userObjectIdList.push({ id: member.id, employeeId: member.employeeid });
            }
        });

        userObjectIdList.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                formData.append(`assignedusers[${index}].${key}`, String(value));
            });
        });


        formData.append("groupname", group.groupname);

        if (userObjectIdList.length === 0 || group.groupname === "") {
            message.error({
                content: (
                    <div className="success-message justify-center align-center p-1 d-flex">
                        {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
                        <span className="c_FF4747 fs-16">
                            {group.groupname === "" ?
                                ("Please Enter the group name") : ("No members is Added")}
                        </span>
                    </div>
                ),
                duration: 2,
                icon: null,
            });
            return;
        }

        try {
            const apiUrl = BaseUrl + `api/group/create_group`;

            const response = await axios.post(apiUrl, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    // setMemberOption(response.data);
                    if (response.status === 200) {
                        message.success({
                            content: (
                                <div className="success-message justify-center align-center p-1">
                                    <img src="assets/images/check-circle.svg" alt="Success Icon" />
                                    <span className="c_32C6A0 fs-16">
                                       Group Created Successfully
                                    </span>
                                </div>
                            ),
                            duration: 2,
                            icon: null,
                        });
                        // window.location.reload();
                     
                        setTimeout(() => {
                            // window.location.reload();
                          }, 1000);
                        //empty the values 
                        setSelectedMemValues([]);
                        setGroup({
                            groupname: "",
                            assignedusers: []
                        });
                        onClose();
                        setTimeout(() => {
                        GroupList();
                    }, 1000);
                    } else if (response.status === 226 || response.status === 500) {

                        message.error({
                            content: (
                                <div className="success-message justify-center align-center p-1 d-flex">
                                    {/* <img src="assets/images/check-circle.svg" alt="Success Icon" /> */}
                                    <span className="c_FF4747 fs-16">
                                        Group Name Exist
                                    </span>
                                </div>
                            ),
                            duration: 1,
                            icon: null,
                        });

                    } else {
                        // console.log("something went wrong ");
                    }

                });
        } catch (error) {
            // console.log("ERROR :::::::::" + error);
        }


    }

    const handleImageError = (e:any)=>{
        const target = e.target as HTMLImageElement; 
        target.onerror = null; 
        target.src = 'assets/images/Default_user.svg'; 
    };



    return (
        <div className="">
            {permission_groupcreate?( 
            <span className="c_32B7D9 fs-12 align-center fw-600 cur-pointer" onClick={() => setOpen(true)}>
                + Create Group
            </span>
             ):''} 
           
            <Drawer title={"Create Group"} size="large"  maskClosable={false} onClose={onClose} open={open} className="objective-canvas position-relative" >
                <Form className="mb-5">
                    <div className="title-section mb-3">
                        <div className="justify-between">
                            <label htmlFor="" className="fw-600">
                                Group Name
                            </label>
                            <span className="c-0050 fs-12 mt-1">
                                {group.groupname.trim().split(/\s+/).join("").length} /{" "}
                                {maxWords.name_max_word_count}
                            </span>
                        </div>
                        <Input
                            type="text"
                            name="groupname"
                            value={group.groupname}
                            // onChange={handlegroupInputChange}
                            onChange={(e) =>
                                handlegroupInputChange(
                                    e,
                                    setGroup,
                                    maxWords.name_max_word_count
                                )
                            }
                            className="canvas-objective-input mt-1"
                        />
                    </div>
                    <div className="assign-section mb-3">
                        <label htmlFor="" className="fw-600">
                            Assign Members
                        </label>
                        <br />
                        <div className="mt-1">
                            <Select
                                mode="multiple"
                                placeholder={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                            src="assets/images/member.svg"
                                            alt="Placeholder"
                                            style={{ marginRight: "8px" }}
                                        />
                                        <span>Select Member</span>
                                    </div>
                                }
                                onChange={handleMemSelectChange}
                                value={selectedMemValues.map(item => ({ label: item.name, value: item.id }))}
                                className="w-100 form-control"
                                labelInValue
                                optionLabelProp="label"
                                dropdownRender={(menu) => <div>{menu}</div>}
                                filterOption={(input, option) =>
                                    option && typeof option.label === 'string' ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                            >
                                {Memberoption.map(option => (
                                    <Option
                                        key={option.id}
                                        value={option.id}
                                        label={option.name}
                                    >
                                        <div>
                                            <img className="member_user" src={option.profilePath ? FileUrl + option.profilePath : "assets/images/Default_user.svg"}
                                                style={{ marginRight: "8px" }} onError={handleImageError} />
                                            {option.name}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            className={
                                selectedMemValues.length > 0
                                    ? "assgin_selected_val add-members"
                                    : ""
                            }
                        >
                            <span className={selectedMemValues.length > 0 ? "c_32B7D9 fs-12 fw-600" : "d-none"}>Members &#40;{selectedMemValues.length}&#41;</span><br />
                            {selectedMemValues.map((value) => (
                                <Tag
                                    key={value.id}
                                    closable
                                    onClose={() => handleMemTagClose(value.id)}
                                    style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        color: "#000",
                                        borderRadius: 15,
                                        padding: "5px 10px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                    className="mt-1"
                                >
                                    <img
                                        // src={"assets/images/Avatar1.png"}
                                        src={value.profilePath ? FileUrl + value.profilePath : "assets/images/Default_user.svg"}
                                        alt=""
                                        onError={handleImageError}
                                        style={{ marginRight: "8px" }}
                                        className="select_user"
                                    />
                                    {value.name}
                                </Tag>
                            ))}
                        </div>
                    </div>

                    <div className="Submit-section">
                        <button className="cancel-btn" onClick={onClose}>Back </button>
                        <button className="cyan_button" onClick={createGroup}>Create</button>
                    </div>
                </Form>
            </Drawer>
        </div>
    )
}

export default CreateGroup;
