import React,{ useState, useEffect, ChangeEvent } from 'react';
import { Tabs } from 'antd';
import Conversation from '../Conversation/Conversation';
import overallCheck from "../../../overallCheck";
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";

const { TabPane } = Tabs;

interface Objective {
  primaryId: string;
  objectiveName: string;
  objectiveDescription: string;
  weightage: string;
  organizationStatus: string;
  subObjectiveList: any[];
  assigneUserWeightage: any[];
  objParentId: string;
  objectiveId: string;
  objectiveType: string;
  unreadmessage?: number;
  everyoneCount?: number;
  allteamCount?: number;
  creatorUserId: string;
  creatorUserName: string;
  supervisorUserId: string;
  supervisorUserName: string;
}
const ConversationTab: React.FC = () => {

  // const onChange = (key: string) => {
  // };
  const [activeKey, setActiveKey] = useState<string>(() => localStorage.getItem('activeTab') || '1');
  const [objData, setObjData] = useState<Objective[]>([]);

  const fetchObjectiveList = async () => {
    try {
      const response = await axios.get(BaseUrl + "api/cfr/objectiveList", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });
      // if(response.data=="No value present")
      //   {
      setObjData(response.data.data);
    // }else
    // {
    //   setObjData([]);
    // }
      //return response.data.data;
      // setObjData( response.data.data);
    } catch (err) {
      // console.log(err);
      throw err;
    }
  };
  
  const onChange = (key: string) => {
    // console.log('Tab Changed to:', key); // Debugging line
    setActiveKey(key);
    localStorage.setItem('activeTab', key);
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    RefreshToken();
    fetchObjectiveList();
  }, []);


  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)  
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  return (
  <div>

    <Tabs defaultActiveKey="1" onChange={onChange} className='objective-tab'>
      <TabPane  tab={`Conversation (${objData.length})`}  key="1">
        <div className="non-absolute ">
          <Conversation />
        </div>
      </TabPane>
    </Tabs>

  </div>)

  };

export default ConversationTab;
