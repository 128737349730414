import React, { useState,useEffect} from 'react';
import { Input, Table ,Row ,Col,Spin } from 'antd';
import axios from "axios";
import { BaseUrl } from "../../../App";
interface DataSourceType {
  key: React.Key;
  EmployeeID: string;
  Name: string;
  Designation: number;
  Email: string;
  Grade: string;
  // BusinessUnitHead: string;
  PayrollStatus: string;
  ManagerName: string;
  HRBPName: string;
}

const Budgie_CITPL: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTextValue, setSearchTextValue] = useState<string>("");
  const [data, setData] = useState<DataSourceType[]>([]);
  const button = document.querySelector('.cyan_button')as HTMLButtonElement;


  const fetchData = async () => {

    try {

      const response = await axios.get(BaseUrl +'api/users/get_citplusers');
       setData(response.data); // Assuming the response contains an array of data
      // console.log(response.data);

    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // const dataSource: DataSourceType[] = [
  //   // {
  //   //   key: '1',
  //   //   EmployeeID: '1',
  //   //   Name: 'Mike',
  //   //   Designation: 32,
  //   //   Email: 'user@gmail.com',
  //   //   ContactNo: '110',
  //   //   BusinessUnitHead: 'lorem',
  //   //   PayrollStatus: 'IT',
  //   //   ManagerName: 'vicky',
  //   //   HRBPName: 'leela',
  //   // },
  //   // Add more data as needed
  // ];
 
  const columns = [
    { title: 'Employee ID', dataIndex: 'empId', key: 'empId' },
    { title: 'Name', dataIndex: 'username', key: 'username' },
    { title: 'Designation', dataIndex: 'designation', key: 'designation' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Grade', dataIndex: 'grade', key: 'grade' },
    { title: 'Payroll Status', dataIndex: 'payrollStatus', key: 'payrollStatus' },
    { title: 'Manager Name', dataIndex: 'supervisorID', key: 'supervisorID' },
    { title: 'HRBP Name', dataIndex: 'hrID', key: 'hrID' },
    // Add more columns as needed
  ];
 
  const handleSearch = (value: string) => {
    setSearchTextValue(value);
  };
 
  // const filteredData = dataSource.filter((record: DataSourceType) =>
  //   Object.values(record).some((value) =>
  //     String(value).toLowerCase().includes(searchTextValue.toLowerCase())
  //   )
  // );
 
  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);

  }, []);

  async function handleClick() {    
    // alert("test");
    setIsLoading(true);

    try {

    
      const apiUrl = BaseUrl + `api/users/get_bugiecitpl_userlist`;
      const formData=new FormData();   

     const response= await axios.get(apiUrl,{
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
        
          
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }
 
    setIsLoading(false);


}
if (button) {
   button.addEventListener('click', handleClick);
} else {
  // console.error('Button element not found');
}
const filteredData = searchText ? data.filter((record: any) =>
Object.values(record).some((value) =>
  String(value).toLowerCase().includes(searchText.toLowerCase())
)
) : data;

  return (
    <div>
        <Row justify={"end"} className='mb-2'>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
        <button className="cyan_button mb-1"   onClick={handleClick} >
        CITPL-Sync
          </button>
        </Col>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
          <input
            type="search"
            placeholder="Search Member"
            className="w-100 table_search_inputbox pl-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <img src="assets/images/search.svg" className="table_search_img" alt=""></img>
        </Col>
      </Row>
        {isLoading ? <Spin  tip="Loading..." > </Spin>  :  <Table dataSource={filteredData}  columns={columns} scroll={{ x: true }}pagination={{ pageSize: 10 }}  />}
    </div>
  );
};
 
export default Budgie_CITPL;
