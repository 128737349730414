import React, { useState, useEffect } from 'react'
import { Row, Table, Checkbox, Col, Spin, Select, message } from "antd";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { BaseUrl, FileUrl } from "../../../App";
import axios from "axios";




interface user {
    employeeID: number;
    name: string;
    portal: string;
    cycle: string;
    mailID: string;
    managerName: string;
    reviewerName: string;
    hrBP: string;
    createdOBJs: string;
    assignedOBJs: string;
    objectiveCompletedStatus: string,
    ObjectiveCascadeStatus: string,
    objWeightage: number;
    createdKRs: string;
    savedKrCount: number;
    cascadedKrCount: number;
    keyResultName: string;
    allocatedKrWeightage: number;
    signOffStatus: string;

}


const UserReport: React.FC = () => {


    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [userReport, setUserReport] = useState([]);

    const dataSource = [
        {
            employeeId: 123,
            name: "John Doe",
            portal: "Portal A",
            cycle: "2024",
            mailID: "john.doe@example.com",
            managerName: "Manager A",
            reviewerName: "Reviewer A",
            hrBP: "HR BP A",
            createdOBJs: 5,
            assignedOBJs: 3,
            objWeightage: 80,
            createdKRs: 8,
            assignedKRs: 6,
            krWeightage: 75
        }, {
            employeeId: 456,
            name: "Jane Smith",
            portal: "Portal B",
            cycle: "2024",
            mailID: "jane.smith@example.com",
            managerName: "Manager B",
            reviewerName: "Reviewer B",
            hrBP: "HR BP B",
            createdOBJs: 7,
            assignedOBJs: 4,
            objWeightage: 85,
            createdKRs: 6,
            assignedKRs: 5,
            krWeightage: 70
        }

    ];


    const handleDownloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('User Report');
        const columns = userReportTable.map(column => column.title); // Assuming permissionLMColumns is an array of column objects
        const data: any[] = userReport; // Assuming dataSource is an array of data objects
        worksheet.columns = columns.map(column => ({ header: column, key: column }));
        const keyName = userReportTable.map(column => column.key);
        data.forEach(item => {
            const rowData = keyName.map((column) => item[column]);
            worksheet.addRow(rowData);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'User Report.xlsx');
    };




    useEffect(() => {
        UserReportData();

    }, []);


    const UserReportData = async () => {

        try {
            const apiUrl = BaseUrl + `api/users/getUserReport`;
            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then((response) => {
                    setUserReport(response.data);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            message.error({
                content: (
                    <div className="success-message justify-center align-center p-1 d-flex">
                        Something Went Wrong
                    </div>
                ),
                duration: 5,
                icon: null,
            });
            // console.log("ERROR :::::::::" + error);
        }

    }

    const filteredData = searchText ? userReport.filter((record: any) =>
        Object.values(record).some((value) =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        )
    ) : userReport;

    const userReportTable = [
        {
            title: "EmployeeID",
            dataIndex: "employeeId",
            key: "employeeId"
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Portal",
            dataIndex: "portal",
            key: "portal"
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: "Division Name",
            dataIndex: "divisionName",
            key: "divisionName",
        }
        ,
        {
            title: "CYCLE",
            dataIndex: "cycle",
            key: "cycle"
        },
        {
            title: "Mail ID",
            dataIndex: "mailId",
            key: "mailId"
        },
        {
            title: "Manager ID",
            dataIndex: "managerId",
            key: "managerId"
        },
        {
            title: "Manager Name",
            dataIndex: "managerName",
            key: "managerName"
        },
        {
            title: "Reviewer Name",
            dataIndex: "reviewerName",
            key: "reviewerName"
        },
        {
            title: "HR BP",
            dataIndex: "hrBP",
            key: "hrBP"
        },
        {
            title: "Assigned OBJ's",
            dataIndex: "objectiveName",
            key: "objectiveName"
        },
        {
            title: "OBJ completed status",
            dataIndex: "objectiveCompletedStatus",
            key: "objectiveCompletedStatus"
        },
        {
            title: "OBJ cascaded status",
            dataIndex: "objectiveCascadeStatus",
            key: "objectiveCascadeStatus"
        },
        {
            title: "OBJ Weightage",
            dataIndex: "allocatedObjWeightage",
            key: "allocatedObjWeightage"
        },
        {
            title: "Assigned KR's",
            dataIndex: "assignedKeyResultCount",
            key: "assignedKeyResultCount"
        },
        {
            title: "Saved KR's",
            dataIndex: "savedKrCount",
            key: "savedKrCount"
        },
        {
            title: "Casecaded KR's",
            dataIndex: "cascadedKrCount",
            key: "cascadedKrCount"
        },
        {
            title: "KR Weightage",
            dataIndex: "allocatedKrWeightage",
            key: "allocatedKrWeightage"
        },
        {
            title: "Sign Off Status",
            dataIndex: "signOffStatus",
            key: "signOffStatus"
        }
    ];



    return (
        <div className='mt-2'>

            <div style={{ textAlign: 'right' }} className='m-2'>
                <Row justify={"end"} className='mb-2'>
                    {/* <Col xl={2} lg={4} md={6} sm={12} xs={24} className='mr-2'>
                        <Select
                            defaultValue="lucy"
                            style={{ width: 120 }}
                            // onChange={handleChange}
                            options={[
                                { value: 1, label: 'CKPL' },
                                { value: 2, label: 'Canvins' },
                                { value: 3, label: 'HEPL' },
                                // { value: 'disabled', label: 'Disabled', disabled: true },
                            ]}
                        />
                    </Col> */}
                    <Col xl={4} lg={6} md={6} sm={12} xs={24}>
                        <input
                            type="search"
                            placeholder="Search Member"
                            className="w-100 table_search_inputbox pl-2"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <img src="assets/images/search.svg" className="table_search_img" alt=""></img>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={24}>
                        <button className='cyan_button' onClick={handleDownloadExcel}>Download to Excel</button>
                    </Col>
                </Row>
            </div>
            <Spin spinning={loading}>
                <Table
                    className=""
                    dataSource={filteredData}
                    scroll={{ x: true }}
                    columns={userReportTable}
                    pagination={{ pageSize: 15 }}
                />
            </Spin>
        </div>
    );
}


export default UserReport;
