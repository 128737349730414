import React from 'react';
import { useEffect,useState } from "react";
import { Tabs } from 'antd';
import Objective from './Objective';
import Bookmarks from './Bookmarks';
import Explorer from './Explorer';
import Groups from './Groups';
import Members from './Members';
import axios from "axios";
import { BaseUrl } from "../../../App";
import overallCheck from "../../../overallCheck";
import CheckInTab from "../CheckIn/CheckInsTab";

const { TabPane } = Tabs;




const Objectivetab: React.FC = () => {


  // =================Roles And Permission==============================
  /*** Role and permission section start **/
  const [permission_hnetwork, setPermission_hnetwork] = useState('');
  const [permission_objnetwork, setPermission_objnetwork] = useState('');
  const [activeKey, setActiveKey] = useState<string>(() => localStorage.getItem('activeTab') || '1');

  const GetUserAccess = async () => {
    try {
      const role = localStorage.getItem('rolename');
      const userid = localStorage.getItem('username');
      const response = await axios.get(BaseUrl +'api/menupermission/rolepermissionlist?role='+role);
      const data = response.data;
      setPermission_hnetwork(data[0].hnetwork);
      setPermission_objnetwork(data[0].objnetwork);
        // if(permission.menu==="Edit"){
        //   setPermission_objedit(permission.parent_objective);
        //   setPermission_childobjedit(permission.child_objective);
        // }
        // if(permission.menu==="Delete"){
        //   setPermission_objdelete(permission.parent_objective);
        //   setPermission_childobjdelete(permission.parent_objective)
        // }
        
      //})
  
      // setRolePermissions(rolePermissionData);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // =================Roles And Permission==============================
  const onChange = (key: string) => {
    // console.log('Tab Changed to:', key); // Debugging line
    setActiveKey(key);
    localStorage.setItem('activeTab', key);
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    GetUserAccess();
    RefreshToken();
    overallCheck();
    const id = setInterval(overallCheck, 10000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);
  const RefreshToken = async () => {

    try {
      const apiUrl = BaseUrl + `api/auth/refreshtoken`;
      const userid = localStorage.getItem('username');
      // assignedusers.map((grpmembers) => {
      //   userObjectIdList.push(grpmembers.value)
      // })
      const updatedata = {
        refreshToken: localStorage.getItem('refreshtoken')
      }
      await axios.post(apiUrl, updatedata, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
          //response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          clearInterval(intervalId)  
        })
    } catch (error) {
      // console.log("ERROR::::::::", error);
    }
  }
  return (
  <div>

    <Tabs  activeKey={activeKey} onChange={onChange} className='objective-tab'>
      <TabPane tab="Objective" key="1">
        <div className="non-absolute ">
          <Objective />
        </div>
      </TabPane>
   
      
      <TabPane tab="Members" key="2">
        <div className="non-absolute">
          <Members />
        </div>
      </TabPane>

      {permission_hnetwork || permission_objnetwork ?(<TabPane tab="Explorer" key="3">
      <div className="non-absolute">
      <Explorer />
      </div>
    </TabPane>):(<></>)}

    <TabPane tab="Check Ins" key="4">
        <div className="non-absolute">
          <CheckInTab />
        </div>
      </TabPane>
      {/* <TabPane tab="Explorer" key="2">
      <Explorer />
    </TabPane>
    <TabPane tab="Members" key="3">
      <Members/>
    </TabPane> */}
      {/* <TabPane tab="Groups" key="4">
      <Groups />
    </TabPane> */}
    </Tabs>

  </div>)

  };

export default Objectivetab;
