import React, { useState, useEffect } from 'react';
import { Input, Table, Row, Col, Spin, Space } from 'antd';
import axios from "axios";
import { BaseUrl } from "../../../App";
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';


interface DataSourceType {
  key: React.Key;
  empId: string;
  name: string;
  division: string;
  email: string;
  band: string;
  grade: string;
  business_unit: string;
  BusinessUnitHead: string;
  Department: string;
  direct_manager_name: string;
  FunctionalHeadName: string;
  NoticePeriodName: string;
  HRBPName: string;
  HOD: string;
  Role: string;
  SeperationStatus: string;
}
interface column {
  title: string;
  dataIndex: string;
  key: string;
  defaultSortOrder: string;

}

const YourComponent: React.FC = () => {


  const [searchTextValue, setSearchTextValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DataSourceType[]>([]);
  const button = document.querySelector('.cyan_button') as HTMLButtonElement;

  /* Search in table Starts */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  // const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  const handleSearch = (searchText: string) => {
    // Perform any necessary logic with the search value here
    setSearchText(searchText); // Update state with the search text
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };







  const fetchData = async () => {
    try {
      const response = await axios.get(BaseUrl + 'api/users/get_darwingbox');
      setData(response.data); // Assuming the response contains an array of data
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  //   const columns: ({
  //     title: string;
  //     dataIndex: string;
  //     key: string;
  // } | {
  //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => JSX.Element;
  //     filterIcon: (filtered: boolean) => JSX.Element;
  //     onFilter: (value: string, record: any) => any;
  //     render: (text: string) => string | JSX.Element;
  //     title: string;
  //     dataIndex: string;
  //     key: string;
  // })[]


  // const dataSource: DataSourceType[] = [
  //   {
  //     EmployeeID: '1',
  //     Name: 'Mike',
  //     Division: 32,
  //     Email: 'user@gmail.com',
  //     BusinessUnit: '110',
  //     BusinessUnitHead: 'lorem',
  //     Department: 'IT',
  //     ManagerName: 'vicky',
  //     FunctionalHeadName: 'leela',
  //     NoticePeriodName: 'Downing Street',
  //     HRBPName: 'Downing Street',
  //     HOD: 'Reena',
  //     Role: 'dsdsdsad',
  //     SeperationStatus: '10 Downing Street',
  //     key: '1',
  //   },
  //   {
  //     EmployeeID: '2',
  //     Name: 'Mike',
  //     Division: 32,
  //     Email: 'user@gmail.com',
  //     BusinessUnit: '110',
  //     BusinessUnitHead: 'lorem',
  //     Department: 'IT',
  //     ManagerName: 'vicky',
  //     FunctionalHeadName: 'leela',
  //     NoticePeriodName: 'Downing Street',
  //     HRBPName: 'Downing Street',
  //     HOD: 'Reena',
  //     Role: '',
  //     SeperationStatus: '10 Downing Street',
  //     key: '2',
  //   },
  // ];

  const columns: ColumnType<DataSourceType>[] = [
    {
      title: 'Employee ID', dataIndex: 'empId', key: 'empId', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.empId || '').toLowerCase();
        const nameB = (b.empId || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Name', dataIndex: 'name', key: 'name', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.name || '').toLowerCase();
        const nameB = (b.name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Division', dataIndex: 'division', key: 'division', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.email || '').toLowerCase();
        const nameB = (b.email || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Email', dataIndex: 'email', key: 'email', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.email || '').toLowerCase();
        const nameB = (b.email || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Band', dataIndex: 'band', key: 'band', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.band || '').toLowerCase();
        const nameB = (b.band || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Grade', dataIndex: 'grade', key: 'grade', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.grade || '').toLowerCase();
        const nameB = (b.grade || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Business Unit', dataIndex: 'business_unit', key: 'business_unit', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.business_unit || '').toLowerCase();
        const nameB = (b.business_unit || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Business Unit Head', dataIndex: 'BusinessUnitHead', key: 'BusinessUnitHead', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.BusinessUnitHead || '').toLowerCase();
        const nameB = (b.BusinessUnitHead || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Department', dataIndex: 'department', key: 'department', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.BusinessUnitHead || '').toLowerCase();
        const nameB = (b.BusinessUnitHead || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Manager Name', dataIndex: 'direct_manager_name', key: 'direct_manager_name', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Functional Head Name', dataIndex: 'functional_head_name', key: 'functional_head_name', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Notice Period Name', dataIndex: 'notice_period_assigned', key: 'notice_period_assigned', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },

    {
      title: 'HRBP Name', dataIndex: 'hrbp_name', key: 'hrbp_name', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'HOD', dataIndex: 'hod', key: 'hod', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Role', dataIndex: 'role', key: 'role', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Seperation Status', dataIndex: 'separation_status', key: 'separation_status', sortDirections: ['descend', 'ascend'], sorter: (a, b) => {
        const nameA = (a.direct_manager_name || '').toLowerCase();
        const nameB = (b.direct_manager_name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      }
    },
  ];

  // const handleSearch = (value: string) => {
  //   setSearchTextValue(value);
  // };






  // const filteredData = fetchData.filter((record: DataSourceType) =>
  //   Object.values(record).some((value) =>
  //     String(value).toLowerCase().includes(searchTextValue.toLowerCase())
  //   )
  // );

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, []);


  async function handleClick() {
    setIsLoading(true);
    try {
      const apiUrl = BaseUrl + `api/users/darwingbox_user_list`;
      const formData = new FormData();

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      })
        .then((response) => {
        });
    } catch (error) {
      // console.log("ERROR :::::::::" + error);
    }
    setIsLoading(false);
    fetchData();
    //window.location.reload();
  }

  const filteredData = searchText ? data.filter((record: any) =>
    Object.values(record).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  ) : data;
  // if (button) {
  //    button.addEventListener('click', handleClick);
  // } else {
  //   console.error('Button element not found');
  // }
  return (
    <div>
      <Row justify={"end"} className='mb-2'>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
          <button className="cyan_button mb-1" onClick={handleClick}  >
            Darwinbox Sync
          </button>

        </Col>
        <Col xl={3} lg={3} md={3} sm={6} xs={24}>
          <input
            type="search"
            placeholder="Search Member"
            className="w-100 table_search_inputbox pl-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <img src="assets/images/search.svg" className="table_search_img" alt=""></img>
        </Col>
      </Row>
      
         {/* <Row justify={"end"} className='mb-2'>

      
            <Col xl={3} lg={3} md={3} sm={6} xs={24}>
            <Input.Search
                placeholder="Search"
                className="form-control float-right"
                value={searchTextValue}
                onSearch={(value) => handleSearch(value)} // Pass a function that accepts the search value
                onChange={(e) => setSearchTextValue(e.target.value)}
                // onSearch={(value) => handleSearch(value)}
                // onChange={(e) => setSearchTextValue(e.target.value)}
            />
            </Col>
        </Row>  */}
        {isLoading ? <Spin  tip="Loading..." > </Spin>  :  <Table sortDirections={["descend", "ascend"]} dataSource={filteredData} scroll={{ x: true }} columns={columns} />}
      
    </div>
  );
};

export default YourComponent;
