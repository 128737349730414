import React from 'react';
import { Tabs,Row } from 'antd';
import User_Settings from './User_Settings';
import Char_Limitations from './Char_Limitations';

const { TabPane } = Tabs;

const onChange = (key: string) => {
  // console.log(key);
};

const onClose = () => {
  window.history.back();
};

const AdminPanel: React.FC = () => (
  <>
  <Row className="d-flex admin_panel_title">
    <img src="assets/images/mem_arrow_left.svg" alt=""  onClick={onClose}/>
    <p className="c_32B7D9 fs-16 cur-pointer" onClick={onClose}>Admin Panel</p>
  </Row>
  <div>

    <Tabs defaultActiveKey="1" onChange={onChange} className='admin-tab'>
      <TabPane tab="User Settings" key="1">
        <div className="">
          <User_Settings />
        </div>
      </TabPane>
      <TabPane tab="Character Limitations" key="2">
        <div className="">
        <Char_Limitations />
        </div>
      </TabPane>
    </Tabs>

  </div>
  </>
);

export default AdminPanel;
