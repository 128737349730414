import React, {useEffect,useState,MouseEventHandler } from 'react';
import { Tabs } from 'antd';
import MyObjective from '../Objective/SubObjectiveTab/MyObjective';
import OrgObjectives from '../Objective/SubObjectiveTab/OrgObjectives';
import CFT from '../Objective/SubObjectiveTab/CFT';
import AspObjectives from '../Objective/SubObjectiveTab/AspObjectives';
import CreateObj from '../Modal/CreateObj';
import AddObj from '../Modal/AddObj';
import FH_OrgObj from "./SubObjectiveTab/FH_OrgObj";
import overallCheck from "../../../overallCheck";
import axios from "axios";
import { BaseUrl } from "../../../App";
import { FileUrl } from "../../../App";
const { TabPane } = Tabs;

const Objective: React.FC = () => {
  const rolename = localStorage.getItem("rolename");

  let viewButton = true;

  if(rolename === "BH") {
    viewButton = false;
  } 

  const [showAddObjButton, setShowAddObjButton] = useState(viewButton);
  const [showOrgObjButton, setShowOrgObjButton] = useState(false);
  const [reloadCFT, setReloadCFT] = useState(false); 
  const [reloadObj, setReloadObj] = useState(false);
  const [reviewstatus,setreviewstatus]  = useState<boolean>()
  const [activeKeyObj, setActiveKeyObj] = useState<string>(() => localStorage.getItem('activeObjTab') || '1');
  const [reviewdata,setreviewdatasize] = useState<Number>(0);
  const [isReview, setIsReview] = useState(localStorage.getItem('review') == "true");


  type objcetiveuselists = {
    objname: string,
    objcode: string,
    createdbyname: string,
    quartervalue: string,
    orgobjid: string,
    organizationStatus: string
    mapped_obj: [],
    mapped_obj_count: string,
    objid: string,
    description: string,
    objectivesassignedusers: [],
    // userprofile: string[],
    createdby: string,
    createdByProfilePath: string,
    cftstatus: number,
    year: string;
    revertstatus: number | null,
    parentrevokestatus: number | null,
    objectiveid: string | null,
    parentid: string | null,
    restrictDelete: boolean,
    revokedorgobjstatus: number | undefined,
    revokedbyuserid: string,
    keyResultcount: number
  }
  interface orgobjectivelists {
    id: string;
    objectCode: string;
    objectiveName: string;
    description: string;
    createdBy: string;
    assignedCount: number;
    quarter: string,
    year: string,
    status: number;
    organizationStatus: string;
    createdByUserName: string;
    createdByUserProfilePath: string;
    restrictDelete: boolean;
    orgObjectiveUsersList: [];
    revokestatus: number;
    objectiveslist: [];
  }
  const onChange = (key: string) => {
    setActiveKeyObj(key);
    localStorage.setItem('activeObjTab', key);
    if (key === '1') {
      setShowAddObjButton(viewButton);
      setShowOrgObjButton(false);
      setReloadObj(true);
    } else if (key === '2') {
      setShowAddObjButton(false);
      setShowOrgObjButton(false);
      setReloadCFT(true);
      setReloadObj(false);
    } else {
      setShowAddObjButton(false);
      setShowOrgObjButton(true);
      setReloadObj(false);
    }
    RefreshToken();
    const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
  };

  const showReviewAndCascadeObjectives = async () => {

    const apiUrl = BaseUrl + `api/reviewcascade/view/objective`;

    axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
    ).then((response) => {
      setreviewdatasize(response.data.data.length);


    })
  }
  const [myObjCount, setMyObjCount] = useState(0);
  const [cftCount, setCftCount] = useState(0);
  const [orgCount, setOrgCount] = useState(0);
  const overAllOrgCount = async () => {
    try {

    const apiUrl = BaseUrl + `api/objectives/count/${localStorage.Quarter}/${localStorage.year}`;

    axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
    ).then((response) => {
      console.log("pqasdfghjkdfghjsedrftgyhu");
      
      setMyObjCount(response.data.Myobjective);
      setCftCount(response.data.CFT);
      setOrgCount(response.data.Organisation);

    })         
  } catch (error) {
      console.log("ERROR" ,error );
      
  }
  }

  const getQuarterFromLocalStorage = () => {
    return localStorage.getItem('Quarter');
  };
  const getyearFromLocalStorage = () => {
    return localStorage.getItem('year');
  };
  const [intervalId, setIntervalId] = useState<any>(null);
  const [quarter, setQuarter] = useState(getQuarterFromLocalStorage());
  const [year,setyear] = useState(getyearFromLocalStorage())
  useEffect(() => { 
    const handleStorageChange = () => {
        setQuarter(getQuarterFromLocalStorage());
        setyear(getyearFromLocalStorage());
        setIsReview(localStorage.getItem('review') === "true");
        getObjectiveCount();
    };
//   useEffect(() => {
//     const handleStorageChange = () => {
//         setQuarter(getQuarterFromLocalStorage());
//         setyear(getyearFromLocalStorage());
//         setIsReview(localStorage.getItem('review') === "true");
//     };

    // window.addEventListener('storage', handleStorageChange);

//     // Cleanup event listener on component unmount
//     return () => {
//         window.removeEventListener('storage', handleStorageChange);
//     };
// }, []);


window.addEventListener('storage', handleStorageChange);

return () => {
  window.removeEventListener('storage', handleStorageChange);
};
  })
  useEffect(() => {
    //bhCreateOrganization();
   //orgobjectiveslist(); 
   setreviewstatus(localStorage.review);
  //  showReviewAndCascadeObjectives();
  //  countLists();
  //  orgCountList();
   RefreshToken();
   overallCheck();
   getObjectiveCount();
   overAllOrgCount();
   const id = setInterval(overallCheck, 10000);
   setIntervalId(id);
   return () => clearInterval(id);
 }, []);
 const [objectiveuserslist, setobjectivesuserlist] = useState<objcetiveuselists[]>([]);
//  const [orgUserList, setOrgUserList] = useState<orgobjectivelists[]>([]);
const [objectiveCount, setObjectiveCount] = useState<number>(0);
 const RefreshToken = async () => {


  try {
    const apiUrl = BaseUrl + `api/auth/refreshtoken`;
    const userid = localStorage.getItem('username');
    // assignedusers.map((grpmembers) => {
    //   userObjectIdList.push(grpmembers.value)
    // })
    const updatedata = {
      refreshToken: localStorage.getItem('refreshtoken')
    }
    await axios.post(apiUrl, updatedata, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      }
    })
      .then((response) => {
        //response.data;
        localStorage.setItem("accessToken", response.data.accessToken);
        clearInterval(intervalId)
      })
  } catch (error) {
    // console.log("ERROR::::::::", error);
  }
}

  
  // const countLists = async () => {
  //   if(localStorage.getItem("rolename") !== "BH") {
  //   try {
  //     const apiUrl = BaseUrl + "api/objectives/list/objectivesusers";
  //     const response = await axios.get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
  //       }
  //     });
  //     setobjectivesuserlist(response.data);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  //   }
  // }

  // const orgCountList = async () => {
  //   if(localStorage.getItem("rolename") !== "BH") {
  //   try {
  //     const apiUrl = BaseUrl + "api/organization/review/getallforfh";
  //     const response = await axios.get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
  //       }
  //     });
  //     const filteredData = response.data.data.filter((user: any) => {
  //       return user.organizationStatus != '1';
  //   });
  //     setOrgUserList(filteredData);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  //   }
  // }

  const getObjectiveCount = async () => {
    if (localStorage.getItem("rolename") !== "BH") {
      const quarter = localStorage.getItem("Quarter")
      const year = localStorage.getItem("year")
      try {
        const apiUrl = `${BaseUrl}api/objectives/objectiveUsers/count/${localStorage.Quarter}/${localStorage.year}`;
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        });
        setObjectiveCount(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    }
  }
  const [tabClicked, setTabClicked] = useState(true);

  const handleTabClick = () => {
      setTabClicked(true);
  };

  
  return (
    <div className='relative objective_tab'>
      {/* {showAddObjButton && <div className='position-absolute r-0 z-1 xl_display_block sm_display_none'><AddObj /></div>} */}
      {/* {showOrgObjButton && <div className='position-absolute r-0 z-1 xl_display_block sm_display_none'><CreateObj /></div>} */}
      {/* {viewButton? "" :<div className='position-absolute r-0 z-1 xl_display_block sm_display_none'><CreateObj /></div>} */}
      

      { viewButton ? (<Tabs 
      // defaultActiveKey="1" 
      activeKey={activeKeyObj}
      onChange={onChange} 
      className='objectivetabs-container sub_tab'>
      
        <TabPane
          tab={
            <div>
              <img src="assets/images/crown.svg" alt="Crown" />
              <span>{isReview}</span>
              {/* <span>My Objectives ({isReview? (objectiveuserslist.length + orgUserList.length):(reviewdata.toString())})</span> */}
              <span>My Objectives ({myObjCount})
                {/* ({objectiveuserslist.filter(data => data.quartervalue == quarter && data.year == year).length + orgUserList.filter(data => data.quarter == quarter && data.year == year).length}) */}
                </span>
            </div>
          }
          key="1"
        >
          <div>
            <MyObjective reloadObj={reloadObj} Quarter={"Q1"} />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div >
              <img src="assets/images/people.svg" alt="CFT" />
              <span>CFT Objectives({cftCount})</span>
            </div>
          }
          key="2"
        >
          {/* <CFT tabClicked={tabClicked}  /> */}
          <CFT reloadObjectivesList={() => setReloadCFT(false)} />
        </TabPane>
        <TabPane
          tab={
            <div>
              <img src="assets/images/cup.svg" alt="Cup" />
              <span>Organisation Objectives({orgCount})</span>
            </div>
          }
          key="3"
        >
         <FH_OrgObj /> 
        </TabPane>
        {/* <TabPane
          tab={
            <div>
              <img src="assets/images/ranking-stars.svg" alt="Ranking Stars" />
              <span>Aspirational Objectives</span>
            </div>
          }
          key="3"
        >
          <AspObjectives />
        </TabPane> */}
      </Tabs>) : (
        <div>
         <div className='align-center mb-2 mt-2'>
         <img src="assets/images/org_obj_cup_bh.svg" alt="Crown" />
         <span className='fs-16 c_32B7D9'>Organisation Objectives</span>
       </div>
        <div>
        
        <OrgObjectives />
      </div>
      </div>
      )}
    </div>
  );
};

export default Objective;
